import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { VictoryAxis, VictoryLabel, VictoryChart, VictoryLine, VictoryScatter, VictoryArea, VictoryCursorContainer } from "victory";
import { interpolateY, findMinMaxY } from "../../../utils/chartsUtils";
import { getRangeDecimals, printPriceFromString, printPriceFromNumber, printPriceShortFormat } from "../../../utils/utils.js";
import { avgUnderlyingTokenPriceSelector } from "../../../store/selectors/tokensSelectors";
import { marginAccountDataSelector } from "../../../store/selectors/marginSelectors";

let chartContainer = null;
let chartContainerHeight = 0;
let xValue = "";
let yValue = "";
let yValuePrinted = "";

let quadrantHeight = 0;
export let chartWidth = 0;
class PositionsChart extends React.Component {
  constructor(props) {
    super();

    this.state = {
      mouseX: null,
      mouseY: null,
      minY: null,
      maxY: null
    };

    this.toShortPriceFormat = this.toShortPriceFormat.bind(this);
    this.leaveHover = this.leaveHover.bind(this);
    this.displayDeviationArea = this.displayDeviationArea.bind(this);
  }

  componentDidMount() {
    chartContainer = document.querySelector(".VictoryContainer > svg");
    if (chartContainer) {
      if (this.props.tabs === "add" || this.props.hasLP) {
        chartContainer.setAttribute("viewBox", "0 0 800 300");
      } else {
        chartContainer.setAttribute("viewBox", "0 0 800 400");
      }
      chartContainerHeight = chartContainer.getBoundingClientRect().height;
      const hoverContainer = chartContainer;

      hoverContainer.addEventListener("mouseleave", this.leaveHover);
      const { minY, maxY } = findMinMaxY(this.props.series1);
      this.initMinMaxProfitLabels(minY, maxY);
    }
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (prevProps.chartX !== this.props.chartX) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (chartContainer && chartWidth === 0) {
      const quadrant = chartContainer.children[chartContainer.children.length - 6];
      const { width, height } = quadrant.getBoundingClientRect();
      quadrantHeight = height;
      width !== 0 && (chartWidth = width);
    }
    const minProfitLabelRight = document.querySelector(".minProfitLabelRight > .minMaxProfitText");
    const maxProfitLabelRight = document.querySelector(".maxProfitLabelRight > .minMaxProfitText");
    const minProfitLabelLeft = document.querySelector(".minProfitLabelLeft > .minMaxProfitText");
    const maxProfitLabelLeft = document.querySelector(".maxProfitLabelLeft > .minMaxProfitText");

    minProfitLabelRight && (minProfitLabelRight.parentElement.style.display = "block");
    maxProfitLabelRight && (maxProfitLabelRight.parentElement.style.display = "block");
    minProfitLabelLeft && (minProfitLabelLeft.parentElement.style.display = "block");
    maxProfitLabelLeft && (maxProfitLabelLeft.parentElement.style.display = "block");

    if (prevProps.series1 !== this.props.series1 && this.props.series1.length > 0) {
      const { minY, maxY } = findMinMaxY(this.props.series1);
      const labelsWereAdded = !!minProfitLabelRight || !!maxProfitLabelRight || !!minProfitLabelLeft || !!maxProfitLabelLeft;
      if (labelsWereAdded) {
        let maxProfitLabelDirection = "maxProfitLabelLeft";
        let minProfitLabelDirection = "minProfitLabelRight";
        if (this.props.series1[0] && this.props.series1[0].y === maxY) {
          maxProfitLabelDirection = "maxProfitLabelRight";
          minProfitLabelDirection = "minProfitLabelLeft";
        }

        minProfitLabelRight && (minProfitLabelRight.textContent = printPriceShortFormat(minY));
        maxProfitLabelRight && (maxProfitLabelRight.textContent = printPriceShortFormat(maxY));
        minProfitLabelLeft && (minProfitLabelLeft.textContent = printPriceShortFormat(minY));
        maxProfitLabelLeft && (maxProfitLabelLeft.textContent = printPriceShortFormat(maxY));

        minProfitLabelRight && (minProfitLabelRight.parentElement.className = minProfitLabelDirection);
        maxProfitLabelRight && (maxProfitLabelRight.parentElement.className = maxProfitLabelDirection);
        minProfitLabelLeft && (minProfitLabelLeft.parentElement.className = minProfitLabelDirection);
        maxProfitLabelLeft && (maxProfitLabelLeft.parentElement.className = maxProfitLabelDirection);
        this.setState({ minY, maxY });
      } else {
        this.initMinMaxProfitLabels(minY, maxY);
      }
    }
    if (chartContainer) {
      const { top: chartContainterTop } = chartContainer.getBoundingClientRect();

      const { top: maxLineTop } = document.querySelectorAll(".VictoryContainer > svg > g")[0].getBoundingClientRect();
      const maxProfilLabelTop = parseInt(maxLineTop - chartContainterTop) - 13;
      maxProfitLabelRight && (maxProfitLabelRight.parentElement.style.top = maxProfilLabelTop + "px");
      maxProfitLabelLeft && (maxProfitLabelLeft.parentElement.style.top = maxProfilLabelTop + "px");

      const { top: minLineTop } = document.querySelectorAll(".VictoryContainer > svg > g")[1].getBoundingClientRect();
      const minProfilLabelTop = parseInt(minLineTop - chartContainterTop) - 13;
      minProfitLabelRight && (minProfitLabelRight.parentElement.style.top = minProfilLabelTop + "px");
      minProfitLabelLeft && (minProfitLabelLeft.parentElement.style.top = minProfilLabelTop + "px");
    }
  }

  componentWillUnmount() {
    if (chartContainer) {
      const hoverContainer = chartContainer;
      hoverContainer.removeEventListener("mouseleave", this.leaveHover);
    }
  }

  initMinMaxProfitLabels = (minY, maxY) => {
    if (chartContainer) {
      const { height: svgHeight } = chartContainer.getBoundingClientRect();
      const { height: gHeight } = chartContainer.querySelector("g").getBoundingClientRect();

      let maxProfitLabelDirection = "maxProfitLabelLeft";
      let minProfitLabelDirection = "minProfitLabelRight";
      if (this.props.series1[0] && this.props.series1[0].y === maxY) {
        maxProfitLabelDirection = "maxProfitLabelRight";
        minProfitLabelDirection = "minProfitLabelLeft";
      }

      const minProfitLabel = document.createElement("div");
      minProfitLabel.className = minProfitLabelDirection;
      chartContainer.parentElement.appendChild(minProfitLabel);
      const minProfitText = document.createElement("span");
      minProfitText.textContent = printPriceShortFormat(minY);
      minProfitText.className = "minMaxProfitText";
      minProfitLabel.appendChild(minProfitText);
      chartContainer.parentElement.appendChild(minProfitLabel);

      const maxProfitLabel = document.createElement("div");
      maxProfitLabel.className = maxProfitLabelDirection;
      const maxProfitText = document.createElement("span");
      maxProfitText.textContent = printPriceShortFormat(maxY);
      maxProfitText.className = "minMaxProfitText";
      maxProfitLabel.appendChild(maxProfitText);
      chartContainer.parentElement.appendChild(maxProfitLabel);
      this.setState({ minY, maxY });
    }
  };

  leaveHover = e => {
    if (this.props.disabled) return;
    this.props.deselectXCallback();
  };

  greeksShouldBeResetToDefault = (mouseX, mouseY) => {
    const { top: wrapperTop, bottom: wrapperBottom, left: wrapperLeft, right: wrapperRight } = chartContainer.getBoundingClientRect();
    const { top: innerTop, bottom: innerBottom, left: innerLeft, right: innerRight } = chartContainer.children[2].getBoundingClientRect();

    return (
      (mouseY > wrapperTop && mouseY < innerTop) ||
      (mouseX > innerRight && mouseX < wrapperRight) ||
      (mouseY > innerBottom && mouseY < wrapperBottom) ||
      (mouseX > wrapperLeft && mouseX < innerLeft)
    );
  };

  toShortPriceFormat = balance => {
    if (isNaN(balance)) {
      return "";
    }

    let absBalance = Math.abs(balance);
    let short = absBalance;
    let unit = "";
    if (absBalance >= 1000000000) {
      short = absBalance / 1000000000;
      unit = "B";
    } else if (absBalance >= 1000000) {
      short = absBalance / 1000000;
      unit = "M";
    } else if (absBalance >= 1000) {
      short = absBalance / 1000;
      unit = "k";
    }

    // if negative
    if (balance < 0) {
      return "-$" + short + unit;
    }
    return "$" + short + unit;
  };

  displayDeviationArea = (lowerX, upperX, deviations) => {
    if (this.props.isTradeVolumeMode || deviations > this.props.deviations) {
      return null;
    }

    return (
      <VictoryArea
        style={{
          data: {
            fill: "#bcbcbc", // "#0abae7",
            fillOpacity: 0.02,
            strokeWidth: 0
          }
        }}
        data={[
          { x: lowerX, y: -(10 ** 12), y0: 10 ** 12 },
          { x: upperX, y: -(10 ** 12), y0: 10 ** 12 }
        ]}
      />
    );
  };

  displayDeviationLine = (lineX, maxDomainY, labelText, deviations) => {
    if (this.props.isTradeVolumeMode || deviations > this.props.deviations) {
      return null;
    }

    return (
      <VictoryLine
        style={{
          data: {
            stroke: "#bcbcbc", // "#0abae7",
            fillOpacity: 0.02,
            strokeWidth: 0
          },
          labels: {
            fontSize: 13,
            fill: "gray"
          }
        }}
        data={
          !this.props.series1 || this.props.series1.length === 0
            ? []
            : [
                { x: lineX, y: this.props.minDomainY },
                { x: lineX, y: maxDomainY }
              ]
        }
        labels={["", labelText]}
      />
    );
  };

  displayAccountLiquidationLine = (lineX, maxDomainY, labelText, left) => {
    return (
      <VictoryLine
        name="accountLiquidationLine"
        className="priorityLabels"
        style={{
          data: {
            stroke: "#c43a31",
            strokeWidth: 0.0
          },
          labels: {
            fontSize: 13,
            fill: "white",
            verticalAnchor: left ? "end" : "start"
            // verticalAnchor: left ? "start" : "end"
          }
        }}
        data={
          !this.props.series1 || this.props.series1.length === 0
            ? []
            : [
                { x: lineX, y: this.props.minDomainY },
                { x: lineX, y: maxDomainY }
              ]
        }
        labels={["", labelText]}
      />
    );
  };

  displayLPPositionLiquidationLine = (lineX, maxDomainY, labelText, left) => {
    return (
      <VictoryLine
        style={{
          data: {
            stroke: "#c43a31",
            strokeWidth: 2.0,
            strokeDasharray: "5,5"
          },
          labels: {
            fontSize: 13,
            fill: "white"
          }
        }}
        data={
          !this.props.series1 || this.props.series1.length === 0
            ? []
            : [
                { x: lineX, y: this.props.minDomainY },
                { x: lineX, y: maxDomainY }
              ]
        }
        labels={["", labelText]}
        labelComponent={<VictoryLabel dy={this.props.tabs === "add" || this.props.hasLP ? 195 : 290} dx={left ? 5 : -60} textAnchor="start" />}
      />
    );
  };

  displayLiquidationArea = (lowerX, upperX, isLPLiquidation) => {
    return (
      <VictoryArea
        style={{
          data: {
            fill: "#c43a31",
            fillOpacity: isLPLiquidation ? 0.1 : 0.4,
            strokeWidth: 0.2
          }
        }}
        data={[
          { x: lowerX, y: -(10 ** 12), y0: 10 ** 12 },
          { x: upperX, y: -(10 ** 12), y0: 10 ** 12 }
        ]}
      />
    );
  };

  render() {
    const xAxisVol = this.props.isTradeVolumeMode;
    const { minY, maxY } = findMinMaxY(this.props.series1);
    const maxDomainY = this.props.minDomainY === 0 && this.props.maxDomainY === 0 ? 1 : Math.max(this.props.maxDomainY, maxY);
    const minDomainY = this.props.minDomainY === 0 && this.props.maxDomainY === 0 ? 1 : Math.min(this.props.minDomainY, minY);

    const currentPrice = xAxisVol ? Number(this.props.currentPriceInVol) : parseFloat(this.props.underlyingTokenPrice.normalized);
    // calculate standard deviation
    const deviations = this.props.deviations;
    const stdDev = (this.props.maxDomainX / currentPrice - 1) / deviations;
    const stdDevMin1 = currentPrice - currentPrice / (1 + stdDev);
    const stdDevMin2 = currentPrice - currentPrice / (1 + 2 * stdDev);
    const stdDevMin3 = currentPrice - currentPrice / (1 + 3 * stdDev);
    const stdDevMin4 = currentPrice - currentPrice / (1 + 4 * stdDev);
    const stdDevMin5 = currentPrice - currentPrice / (1 + 5 * stdDev);
    const stdDevMax1 = (this.props.maxDomainX - currentPrice) / deviations;

    const labelCurrentPrice = xAxisVol ? currentPrice.toFixed(1) + "%" : printPriceFromString(currentPrice.toFixed(2));

    // account liquidation
    const upperLiqPrice = this.props.liqPrices && this.props.liqPrices.upperLiqPrice ? Math.round(this.props.liqPrices.upperLiqPrice) : 10 ** 18;
    const lowerLiqPrice = this.props.liqPrices && this.props.liqPrices.lowerLiqPrice ? Math.round(this.props.liqPrices.lowerLiqPrice) : 0;

    // lp position liquidation
    const callLPLiqPrice = this.props.lpLiqPrices && this.props.lpLiqPrices.callLiqPrice ? Math.round(this.props.lpLiqPrices.callLiqPrice) : 10 ** 18;
    const putLPLiqPrice = this.props.lpLiqPrices && this.props.lpLiqPrices.putLiqPrice ? Math.round(this.props.lpLiqPrices.putLiqPrice) : 0;

    const victoryChartHeight = this.props.tabs === "add" || this.props.hasLP ? 300 : 400;

    // const yRange = Math.abs(this.props.maxDomainY) + Math.abs(this.props.minDomainY);
    const yRange = Math.abs(maxDomainY) + Math.abs(minDomainY);

    // eslint-disable-next-line react/no-multi-comp
    const CustomCursorLabel = props => {
      if (this.props.disabled) return;

      const labelYMultiplier = this.props.tabs === "add" || this.props.hasLP ? 200 : 300;

      const x = Number(props.datum.x.toFixed(xAxisVol ? 1 : 0));
      this.props.selectXCallback(x);
      const series1Y = interpolateY(this.props.series1, x);

      xValue = printPriceFromString(props.datum.x.toFixed(0));
      yValue = Number(series1Y.toFixed(2));
      yValuePrinted = printPriceFromString(series1Y.toFixed(2));

      let labelY = null;
      if (yValue > 0) {
        labelY = (Math.abs(maxDomainY) - yValue) / yRange;
      } else {
        labelY = (Math.abs(maxDomainY) + Math.abs(yValue)) / yRange;
      }
      labelY = labelY * labelYMultiplier;
      return (
        <VictoryLabel
          className="priorityLabels"
          backgroundStyle={[
            { fill: "#303336", opacity: 1.0, width: 120 },
            { fill: "#303336", opacity: 1.0, width: 120 }
          ]}
          style={{ fill: "white" }}
          backgroundPadding={{ left: 10, right: 10, top: 5, bottom: 5 }}
          {...props}
          x={props.x}
          y={67 + labelY}
          text={["ETH price: $" + props.datum.x.toFixed(0), "P/L: " + yValuePrinted]}
        />
      );
    };

    const width = this.props.tabs === "add" || this.props.hasLP ? 820 : 800;

    const minDomainX = Math.min(upperLiqPrice, this.props.minDomainX);
    const maxDomainX = Math.max(lowerLiqPrice, this.props.maxDomainX);

    return (
      <>
        <VictoryChart
          //theme={chartTheme}
          width={width}
          height={victoryChartHeight}
          style={{ fill: "white" }}
          minDomain={{ x: minDomainX, y: minDomainY }}
          maxDomain={{ x: maxDomainX, y: maxDomainY }}
          containerComponent={
            <VictoryCursorContainer
              cursorDimension="x"
              size={5}
              cursorLabelComponent={<CustomCursorLabel />}
              cursorLabel={({ datum }) => `${Math.round(datum.x, 2)}`}
            />
          }
          labels={["", labelCurrentPrice]}
        >
          {/* max pnl green widget */}
          <VictoryLine
            style={{
              data: {
                stroke: "#8FCE51",
                strokeWidth: 0.5,
                strokeDasharray: "1 5"
              },
              labels: {
                fontSize: 13
              }
            }}
            data={this.props.series1.map(s => {
              return { x: s.x, y: this.state.maxY ? this.state.maxY : 0 };
            })}
            labelComponent={<VictoryLabel />}
          />

          {/* max pnl red widget */}
          <VictoryLine
            style={{
              data: {
                stroke: "#EE1B4D",
                strokeWidth: 0.5,
                strokeDasharray: "1 5"
              },
              labels: {
                fontSize: 13
              }
            }}
            data={this.props.series1.map(s => {
              return { x: s.x, y: this.state.minY ? this.state.minY : 0 };
            })}
            labelComponent={<VictoryLabel />}
          />

          {this.displayDeviationArea(currentPrice - stdDevMin1, currentPrice + 1 * stdDevMax1, 1)}
          {this.displayDeviationArea(currentPrice - stdDevMin2, currentPrice + 2 * stdDevMax1, 2)}
          {this.displayDeviationArea(currentPrice - stdDevMin3, currentPrice + 3 * stdDevMax1, 3)}
          {this.displayDeviationArea(currentPrice - stdDevMin4, currentPrice + 4 * stdDevMax1, 4)}
          {this.displayDeviationArea(currentPrice - stdDevMin5, currentPrice + 5 * stdDevMax1, 5)}

          {this.displayDeviationLine(currentPrice - stdDevMin5, maxDomainY, "-5σ", 5)}
          {this.displayDeviationLine(currentPrice - stdDevMin4, maxDomainY, "-4σ", 4)}
          {this.displayDeviationLine(currentPrice - stdDevMin3, maxDomainY, "-3σ", 3)}
          {this.displayDeviationLine(currentPrice - stdDevMin2, maxDomainY, "-2σ", 2)}
          {this.displayDeviationLine(currentPrice - stdDevMin1, maxDomainY, "-1σ", 1)}
          {this.displayDeviationLine(currentPrice + 1 * stdDevMax1, maxDomainY, "+1σ", 1)}
          {this.displayDeviationLine(currentPrice + 2 * stdDevMax1, maxDomainY, "+2σ", 2)}
          {this.displayDeviationLine(currentPrice + 3 * stdDevMax1, maxDomainY, "+3σ", 3)}
          {this.displayDeviationLine(currentPrice + 4 * stdDevMax1, maxDomainY, "+4σ", 4)}
          {this.displayDeviationLine(currentPrice + 5 * stdDevMax1, maxDomainY, "+5σ", 5)}

          <VictoryAxis
            dependentAxis
            style={{
              axis: {
                stroke: this.props.disabled ? "gray" : "white",
                strokeWidth: 1
              },
              axisLabel: {
                fontSize: 16,
                stroke: this.props.disabled ? "gray" : "white"
              },
              tickLabels: {
                fill: this.props.disabled ? "gray" : "white",
                stroke: this.props.disabled ? "gray" : "white",
                fontSize: 16,
                fontWeight: 275,
                lineHeight: 29,
                fontFamily: "Poppins"
              }
            }}
            // label="Profit/Loss [USD]"
            tickFormat={t => `${this.toShortPriceFormat(t)}`}
          />

          <VictoryAxis
            style={{
              axis: {
                stroke: this.props.disabled ? "gray" : "white",
                strokeWidth: 1
              },
              axisLabel: {
                fontSize: 16,
                stroke: this.props.disabled ? "gray" : "white"
              },
              tickLabels: {
                fill: this.props.disabled ? "gray" : "white",
                stroke: this.props.disabled ? "gray" : "white",
                fontSize: 16,
                fontWeight: 275,
                lineHeight: 29,
                fontFamily: "Poppins"
              }
            }}
            tickFormat={t => `${(!xAxisVol ? "$" : "") + t + (xAxisVol ? "%" : "")}`}
          />

          {/* series 2 line */}
          <VictoryLine
            style={{
              data: { stroke: "#0abae7", strokeWidth: 2.5 } //  // orange "#F8AC5B"
            }}
            data={this.props.series2}
          />

          {/* series 1 line */}
          <VictoryLine
            style={{
              data: { stroke: "#8fce51", strokeWidth: 2.5 }
            }}
            data={this.props.series1}
          />

          {/* price line */}
          <VictoryLine
            style={{
              data: {
                stroke: "#006992", //"#c43a31" red,
                strokeWidth: 2
              },
              labels: {
                fontSize: 13,
                fill: "white"
              }
            }}
            data={
              !this.props.series1 || this.props.series1.length === 0
                ? []
                : [
                    { x: currentPrice, y: this.props.minDomainY },
                    { x: currentPrice, y: maxDomainY }
                  ]
            }
            labels={["", labelCurrentPrice]}
          />

          {/* max pnl line */}
          <VictoryLine
            style={{
              data: {
                stroke: "#8FCE51",
                strokeWidth: 0.5,
                strokeDasharray: "1 5"
              },
              labels: {
                fontSize: 13
              }
            }}
            data={this.props.series1.map(s => {
              return { x: s.x, y: this.state.maxY ? this.state.maxY : 0 };
            })}
            labelComponent={<VictoryLabel />}
          />

          {/* min pnl line */}
          <VictoryLine
            style={{
              data: {
                stroke: "#EE1B4D",
                strokeWidth: 0.5,
                strokeDasharray: "1 5"
              },
              labels: {
                fontSize: 13
              }
            }}
            data={this.props.series1.map(s => {
              return { x: s.x, y: this.state.minY ? this.state.minY : 0 };
            })}
            labelComponent={<VictoryLabel />}
          />

          {/* account liquidation */}
          {!xAxisVol &&
            upperLiqPrice < this.props.maxDomainX &&
            this.displayAccountLiquidationLine(upperLiqPrice, maxDomainY, printPriceFromNumber(upperLiqPrice), true)}
          {!xAxisVol &&
            lowerLiqPrice > this.props.minDomainX &&
            this.displayAccountLiquidationLine(lowerLiqPrice, maxDomainY, printPriceFromNumber(lowerLiqPrice), false)}

          {!xAxisVol && upperLiqPrice < this.props.maxDomainX && this.displayLiquidationArea(upperLiqPrice, 10 ** 12, false)}
          {!xAxisVol && lowerLiqPrice > this.props.minDomainX && this.displayLiquidationArea(0, lowerLiqPrice, false)}

          {/* lp position liquidation */}
          {!xAxisVol &&
            callLPLiqPrice < this.props.maxDomainX &&
            this.displayLPPositionLiquidationLine(callLPLiqPrice, maxDomainY, printPriceFromNumber(callLPLiqPrice), true)}
          {!xAxisVol &&
            putLPLiqPrice > this.props.minDomainX &&
            this.displayLPPositionLiquidationLine(putLPLiqPrice, maxDomainY, printPriceFromNumber(putLPLiqPrice), false)}

          {xAxisVol ? (
            <VictoryArea
              style={{
                data: {
                  fill: "#0abae7",
                  fillOpacity: 0.1,
                  strokeWidth: 0.2
                }
              }}
              data={[
                { x: this.props.minPriceInVol, y: -(10 ** 12), y0: 10 ** 12 },
                { x: this.props.maxPriceInVol, y: -(10 ** 12), y0: 10 ** 12 }
              ]}
            />
          ) : null}

          {xAxisVol ? (
            <VictoryLine
              style={{
                data: {
                  stroke: "#0abae7",
                  fillOpacity: 0.1,
                  strokeWidth: 0.2
                },
                labels: {
                  fontSize: 13
                }
              }}
              data={
                !this.props.series1 || this.props.series1.length === 0
                  ? []
                  : [
                      { x: this.props.minPriceInVol, y: this.props.minDomainY },
                      { x: this.props.minPriceInVol, y: maxDomainY }
                    ]
              }
              labels={["", this.props.minPriceInVol.toFixed(getRangeDecimals(this.props.minPriceInVol)) + "%"]}
            />
          ) : null}

          {xAxisVol ? (
            <VictoryLine
              style={{
                data: {
                  stroke: "#0abae7",
                  fillOpacity: 0.1,
                  strokeWidth: 0.2
                },
                labels: {
                  fontSize: 13
                }
              }}
              data={
                !this.props.series1 || this.props.series1.length === 0
                  ? []
                  : [
                      { x: this.props.maxPriceInVol, y: this.props.minDomainY },
                      { x: this.props.maxPriceInVol, y: maxDomainY }
                    ]
              }
              labels={["", this.props.maxPriceInVol.toFixed(getRangeDecimals(this.props.maxPriceInVol)) + "%"]}
            />
          ) : null}

          {/* price line dot */}
          <VictoryScatter
            style={{ data: { fill: "#006992" } }}
            size={3}
            data={
              !this.props.series1 || this.props.series1.length === 0
                ? []
                : [{ x: currentPrice, y: interpolateY(xAxisVol ? this.props.series2 : this.props.series1, currentPrice) }]
            }
          />
        </VictoryChart>
      </>
    );
  }
}

PositionsChart.propTypes = {
  tabs: PropTypes.string,
  hasLP: PropTypes.bool,
  disabled: PropTypes.bool,
  isTradeVolumeMode: PropTypes.bool,
  isXUnderlying: PropTypes.bool,
  underlyingTokenPrice: PropTypes.object,
  currentPriceInVol: PropTypes.object,
  minPriceInVol: PropTypes.object,
  maxPriceInVol: PropTypes.object,
  minDomainX: PropTypes.object,
  maxDomainX: PropTypes.object,
  minDomainY: PropTypes.object,
  maxDomainY: PropTypes.object,
  series1: PropTypes.array,
  series2: PropTypes.array,
  selectXCallback: PropTypes.func,
  deselectXCallback: PropTypes.func,
  marginAccountData: PropTypes.object,
  liqPrices: PropTypes.object,
  lpLiqPrices: PropTypes.object,
  deviations: PropTypes.object
};

function mapStateToProps(state) {
  return {
    underlyingTokenPrice: avgUnderlyingTokenPriceSelector(state),
    marginAccountData: marginAccountDataSelector(state)
  };
}

export default connect(mapStateToProps)(PositionsChart);
