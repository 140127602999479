import { loadingWeb3, web3Loaded, web3Unloaded, web3AccountLoaded, web3AccountUnloaded, currentTimestampLoaded } from "../actions/actions.js";
import { handleNewError } from "./errorInteractions";
import { setIntervalAsync } from "set-interval-async/fixed";
import { clearIntervalAsync } from "set-interval-async";
import Web3 from "web3";
import { init } from "./rootInteractions";
import { ARBITRUM_SEPOLIA_NETWORK_ID, BASE_SEPOLIA_NETWORK_ID, SEPOLIA_NETWORK_ID, BASE_MAINNET_NETWORK_ID } from "../../config.js";
let currentTimestampUpdateInterval;
const FIFTEEN_SECONDS = 15000;

export const loadWeb3 = (web3, networkId, dispatch) => {
  if (web3) {
    dispatch(loadingWeb3());
    web3.eth.handleRevert = true;

    const isArbitrum = networkId == ARBITRUM_SEPOLIA_NETWORK_ID;
    const isSepolia = networkId == SEPOLIA_NETWORK_ID;
    const isBaseSepolia = networkId == BASE_SEPOLIA_NETWORK_ID;
    const isBase = networkId == BASE_MAINNET_NETWORK_ID;

    // web3s are defaulting to web3
    let web3read = web3;
    let web3Events = web3;
    let web3Subs = web3;
    if (isArbitrum) {
      // web3read = new Web3("https://wider-icy-film.arbitrum-sepolia.quiknode.pro/75e9e2fe02e527394ea37f48fb854ea7343f57ed/");
      // web3read = new Web3("https://arbitrum-sepolia.core.chainstack.com/2b730c29520dfd9e8de3f9e8d1b53284");
      // web3read = new Web3("https://sepolia-rollup.arbitrum.io/rpc");
      web3read = new Web3("https://sepolia-rollup.arbitrum.io/rpc"); //""https://arbitrum-sepolia.core.chainstack.com/2b730c29520dfd9e8de3f9e8d1b53284");
      web3Events = new Web3("https://arbitrum-sepolia.core.chainstack.com/2b730c29520dfd9e8de3f9e8d1b53284");
      web3Subs = new Web3("https://arbitrum-sepolia.core.chainstack.com/2b730c29520dfd9e8de3f9e8d1b53284");
    }

    if (isSepolia) {
      web3read = new Web3("https://few-crimson-shadow.ethereum-sepolia.quiknode.pro/48d2bb37c2f0befff1b37cdda371573e0f1220cd/");
      // web3Events = new Web3("https://arbitrum-sepolia.core.chainstack.com/2b730c29520dfd9e8de3f9e8d1b53284");
      // web3Subs = new Web3("https://arbitrum-sepolia.core.chainstack.com/2b730c29520dfd9e8de3f9e8d1b53284");
    }

    if (isBaseSepolia) {
      console.log("isBaseSepolia true, using quicknode for web3read");
      // web3read = new Web3("https://api.developer.coinbase.com/rpc/v1/base-sepolia/i43B6Glwz5rnrWjW2HdkRJsX1_zjubfP"); // pretty slow
      web3read = new Web3("https://autumn-young-putty.base-sepolia.quiknode.pro/4fcd2083a2d3c16a4888cccef6ef5edc18c8906d/");
    }

    if (isBase) {
      console.log("isBase true, using quicknode for web3read");
      web3read = new Web3("https://soft-compatible-glitter.base-mainnet.quiknode.pro/d6cad689c754eb7629bc30acb08f091b6577a499/");
    }

    dispatch(web3Loaded(web3, web3read, web3Events, web3Subs));
    return { web3, web3read, web3Events, web3Subs };
  } else {
    handleNewError({}, "Unable to load web3!", 1, dispatch, true);
  }
};

export const loadWeb3Account = async (web3, dispatch) => {
  try {
    const accounts = await web3.eth.getAccounts();
    dispatch(web3AccountLoaded(accounts[0]));
    return accounts[0];
  } catch (e) {
    handleNewError({}, "Unable to load account. Please login to your wallet.", 1, dispatch, true);
  }
};

export const unloadWeb3Account = async dispatch => {
  try {
    dispatch(web3Unloaded());
    dispatch(web3AccountUnloaded());
  } catch (e) {
    handleNewError({}, "Unable to unload web3 account. ", 1, dispatch, true);
  }
};

export const subscribeToAccountChange = async (provider, gammaContractsRegistry, uniswapAdapter, dispatch) => {
  window.ethereum.on("accountsChanged", async accounts => {
    // replace web3 on account change
    const web3 = new Web3(provider);
    const networkId = await web3.eth.net.getId();
    const { web3read, web3Events, web3Subs } = loadWeb3(web3, networkId, dispatch);
    dispatch(web3AccountLoaded(accounts[0]));
    init(web3, web3read, web3Events, web3Subs, networkId, accounts[0], dispatch);
  });
};

// todo: v1 this should be turned off..
export const subscribeToBlockchainTimestampChange = async (web3, dispatch) => {
  let block = await web3.eth.getBlock("latest");
  dispatch(currentTimestampLoaded(block.timestamp));
  currentTimestampUpdateInterval = setIntervalAsync(async () => {
    console.log("RPC method: getBlock latest");
    let block = await web3.eth.getBlock("latest");
    dispatch(currentTimestampLoaded(block.timestamp));
  }, FIFTEEN_SECONDS);
};

export const unsubscribeToBlockchainTimestampChange = async (web3, dispatch) => {
  if (currentTimestampUpdateInterval) {
    await clearIntervalAsync(currentTimestampUpdateInterval);
  }
};

export const subscribeToChainChange = () => {
  window.ethereum.on("chainChanged", chainId => {
    window.location.reload();
  });
};
