import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import MySwitch from "./MySwitch.jsx";

const CogPositionsModal = ({ cogModalPositionTop, cogModalPositionRight, isChecked, checkHandler, isDefault, resetSwitches, setCogPositionsModalOpen }) => {
  const resetFiltersImgRef = useRef();

  useEffect(() => {
    document.onkeydown = onKeyPress;

    return () => {
      document.onkeydown = null;
    };
  }, []);

  const onKeyPress = e => {
    if (e && e.key === "Escape") {
      setCogPositionsModalOpen(false);
      document.querySelector("body").classList.toggle("scrollDisabled");
    }
  };

  const onResetFiltersMouseEnter = e => {
    resetFiltersImgRef.current.classList.add("resetImgHover");
  };

  const onResetFiltersMouseLeave = e => {
    resetFiltersImgRef.current.classList.remove("resetImgHover");
  };

  return createPortal(
    <div
      className="modalSwitchesFrame"
      style={{
        top: cogModalPositionTop + 80,
        right: cogModalPositionRight
      }}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <Container>
        {window.location.href.includes("trade") ? (
          <>
            <Row>
              <Col>
                <MySwitch id="createdAt" name="createdAt" disabled={false} checked={isChecked.createdAt} onChange={checkHandler} />
                <span className="popoverLabels">Opened</span>
              </Col>
              <Col>
                <MySwitch id="apy" name="apy" disabled={false} checked={isChecked.apy} onChange={checkHandler} />
                <span className="popoverLabels">APY</span>
              </Col>
            </Row>

            <Row>
              <Col>
                <MySwitch id="timeToExp" name="timeToExp" disabled={false} checked={isChecked.timeToExp} onChange={checkHandler} />
                <span className="popoverLabels">Time to exp</span>
              </Col>
              <Col>
                <MySwitch id="totalDelta" name="totalDelta" disabled={false} checked={isChecked.totalDelta} onChange={checkHandler} />
                <span className="popoverLabels">&Delta; DELTA</span>
              </Col>
            </Row>

            <Row>
              <Col>
                <MySwitch id="pnl" name="pnl" disabled={false} checked={isChecked.pnl} onChange={checkHandler} />
                <span className="popoverLabels">P/L</span>
              </Col>
              <Col>
                <MySwitch id="totalGamma" name="totalGamma" disabled={false} checked={isChecked.totalGamma} onChange={checkHandler} />
                <span className="popoverLabels">&gamma; GAMMA</span>
              </Col>
            </Row>

            <Row>
              <Col>
                <MySwitch id="roi" name="roi" disabled={false} checked={isChecked.roi} onChange={checkHandler} />
                <span className="popoverLabels">ROI</span>
              </Col>
              <Col>
                <MySwitch id="totalTheta" name="totalTheta" disabled={false} checked={isChecked.totalTheta} onChange={checkHandler} />
                <span className="popoverLabels">&Theta; THETA</span>
              </Col>
            </Row>

            <Row>
              <Col>
                <MySwitch id="apr" name="apr" disabled={false} checked={isChecked.apr} onChange={checkHandler} />
                <span className="popoverLabels">APR</span>
              </Col>
              <Col>
                <MySwitch id="totalVega" name="totalVega" disabled={false} checked={isChecked.totalVega} onChange={checkHandler} />
                <span className="popoverLabels">V VEGA</span>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col>
                <MySwitch id="createdAt" name="createdAt" disabled={false} checked={isChecked.createdAt} onChange={checkHandler} />
                <span className="popoverLabels">Opened</span>
              </Col>
              <Col>
                <MySwitch id="apr" name="apr" disabled={false} checked={isChecked.apr} onChange={checkHandler} />
                <span className="popoverLabels">APR</span>
              </Col>
            </Row>

            <Row>
              <Col>
                <MySwitch id="timeToExp" name="timeToExp" disabled={false} checked={isChecked.timeToExp} onChange={checkHandler} />
                <span className="popoverLabels">Time to exp</span>
              </Col>
              <Col>
                <MySwitch id="apy" name="apy" disabled={false} checked={isChecked.apy} onChange={checkHandler} />
                <span className="popoverLabels">APY</span>
              </Col>
            </Row>

            <Row>
              <Col>
                <MySwitch id="health" name="health" disabled={false} checked={isChecked.health} onChange={checkHandler} />
                <span className="popoverLabels">LP HEALTH</span>
              </Col>
              <Col>
                <MySwitch id="totalDelta" name="totalDelta" disabled={false} checked={isChecked.totalDelta} onChange={checkHandler} />
                <span className="popoverLabels">&Delta; DELTA</span>
              </Col>
            </Row>

            <Row>
              <Col>
                <MySwitch id="status" name="status" disabled={false} checked={isChecked.status} onChange={checkHandler} />
                <span className="popoverLabels">LP STATUS</span>
              </Col>
              <Col>
                <MySwitch id="totalGamma" name="totalGamma" disabled={false} checked={isChecked.totalGamma} onChange={checkHandler} />
                <span className="popoverLabels">&gamma; GAMMA</span>
              </Col>
            </Row>

            <Row>
              <Col>
                <MySwitch id="pnl" name="pnl" disabled={false} checked={isChecked.pnl} onChange={checkHandler} />
                <span className="popoverLabels">P/L</span>
              </Col>
              <Col>
                <MySwitch id="totalTheta" name="totalTheta" disabled={false} checked={isChecked.totalTheta} onChange={checkHandler} />
                <span className="popoverLabels">&Theta; THETA</span>
              </Col>
            </Row>

            <Row>
              <Col>
                <MySwitch id="roi" name="roi" disabled={false} checked={isChecked.roi} onChange={checkHandler} />
                <span className="popoverLabels">ROI</span>
              </Col>
              <Col>
                <MySwitch id="totalVega" name="totalVega" disabled={false} checked={isChecked.totalVega} onChange={checkHandler} />
                <span className="popoverLabels">V VEGA</span>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <div className="resetFlex">
            <div
              className={`imgAndResetFiltersSpan${isDefault ? " imgAndResetFiltersSpanDisabled" : ""}`}
              onClick={() => !isDefault && resetSwitches()}
              onMouseEnter={onResetFiltersMouseEnter}
              onMouseLeave={onResetFiltersMouseLeave}
            >
              <img ref={resetFiltersImgRef} className={`resetImg${isDefault ? " resetImgDisabled" : ""}`} />
              <span className={`resetFiltersText${isDefault ? " resetFiltersTextDisabled" : ""}`}>Reset Columns</span>
            </div>
          </div>
        </Row>
      </Container>
    </div>,
    document.getElementById("root")
  );
};

export default CogPositionsModal;
