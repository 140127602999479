// network ids used elsewhere
export const GANACHE_NETWORK_ID = 1337;
export const SEPOLIA_NETWORK_ID = 11155111;
export const ARBITRUM_SEPOLIA_NETWORK_ID = 421614;
export const BASE_SEPOLIA_NETWORK_ID = 84532;
export const BASE_MAINNET_NETWORK_ID = 8453;

export const SEPOLIA_FIRST_BLOCK = 5564000;
export const ARBITRUM_SEPOLIA_FIRST_BLOCK = 5500000;
export const BASE_SEPOLIA_FIRST_BLOCK = 13930136;
export const BASE_MAINNET_FIRST_BLOCK = 17814904; // todo: update this after deployment (last block on 2024-07-31)
