import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { web3AccountLoadedSelector } from "../../store/selectors/web3Selectors";

class ConnectPanel extends React.Component {
  constructor() {
    super();
    this.state = {
      willRender: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ willRender: true });
    }, 500);
  }

  render() {
    return this.state.willRender && !this.props.web3AccountLoaded ? (
      <Row>
        <Col className="mb-1" style={{ textAlign: "center", color: "#ffffff", opacity: 0.8, marginTop: "20px" }} md="12">
          {this.props.shortMessage ? "Please connect." : "Please connect using web3 wallet."}
        </Col>
      </Row>
    ) : null;
  }
}

ConnectPanel.propTypes = {
  shortMessage: PropTypes.bool,
  web3AccountLoaded: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    web3AccountLoaded: web3AccountLoadedSelector(state)
  };
}

export default connect(mapStateToProps)(ConnectPanel);
