/* eslint-disable react/no-multi-comp */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactTable from "react-table";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import {
  allLPTradesSelector,
  liquidationTradesLoadedSelector,
  liquidationTradesSelector,
  lpTradesLoadedSelector,
  lpTradesSelector,
  openedLPPositionsSelector,
  tradesSelector
} from "../../store/selectors/positionsManagerSelectors";
import { marketsLoadedSelector, marketsSelector } from "../../store/selectors/marketsSelectors";
import { lpPositionTokensLoadedSelector } from "../../store/selectors/tokensSelectors";
import { currentTimestampSelector, web3AccountLoadedSelector } from "../../store/selectors/web3Selectors";
import ConnectPanel from "./ConnectPanel.jsx";
import { checkTradesAndLPTrades } from "./utilities/TradePanels/CheckUtility";
import { printEmptyTable, printLoadingIcon } from "./utilities/TradePanels/PrintUtility.jsx";
import { cursorRangeCheck, formatValue, getUnderlyingPriceAtExpiredNumber, printPriceFromString, toMonthName } from "../../utils/utils.js";
import { FilterContext } from "../../layouts/market/MainRouter.jsx";
import MyTooltip2 from "./MyTooltip2.jsx";
import { getDx } from "../../utils/MarketHelper";
import { getValueFooter } from "./utilities/TradePanels/FooterUtility";
import { settlementDataSelector } from "../../store/selectors/balancesSelectors";
import FiltersHistory from "./FiltersHistory";

const EMPTY_TABLE_TEXT = "You haven't made any LP trades.";
const CARD_TITLE = "TOTAL HISTORY";

const TOOLTIPS_TEXT = {
  poolHistoryToken: "Token name.",
  poolHistoryType: "Buy when you bought the tokens, sell when you sold them.",
  poolHistorySize: "Size of the trade in options.",
  poolHistoryPrice: "The price per token at the time of trade.",
  poolHistoryFees: "Borrow interest paid on a trade close.",
  poolHistoryCollateral: "The collateral locked at the time of trade.",
  poolHistoryValue: "Proceeds in $ USD from the trade. ",
  poolHistoryTimestamp: "Date and time of trade."
};

const PoolHistoryPanel = () => {
  !localStorage.getItem("isPhpDisplayed") && localStorage.setItem("isPhpDisplayed", true);

  const [panelIsDisplayed, setPanelIsDisplayed] = useState(() => {
    return localStorage.getItem("isPhpDisplayed") === "true";
  });

  const [tooltipIsVisible, setTooltipIsVisible] = useState(false);
  const [tooltipTarget, setTooltipTarget] = useState("");
  const [tooltipContent, setTooltipContent] = useState("");

  const [expirationTimesSearchParam, setExpirationTimesSearchParam] = useState("");
  const [expirationTimesSelected, setExpirationTimesSelected] = useState(new Map());

  const markets = useSelector(marketsSelector);
  const marketsLoaded = useSelector(marketsLoadedSelector);
  const web3AccountLoaded = useSelector(web3AccountLoadedSelector);
  const trades = useSelector(tradesSelector);
  const lpTrades = useSelector(lpTradesSelector);
  const allLPTrades = useSelector(allLPTradesSelector);
  const lpTradesLoaded = useSelector(lpTradesLoadedSelector);
  const liquidationTrades = useSelector(liquidationTradesSelector);
  const liquidationTradesLoaded = useSelector(liquidationTradesLoadedSelector);
  const lpPositionTokensLoaded = useSelector(lpPositionTokensLoadedSelector);
  const openedLPPositions = useSelector(openedLPPositionsSelector);
  const settlements = useSelector(settlementDataSelector);
  const currentTimestamp = useSelector(currentTimestampSelector);

  const tableWrapper = useRef(null);

  useEffect(() => {
    if (openedLPPositions && openedLPPositions.hasOwnProperty("length")) {
      initializeFilterOptions();
    }
  }, [openedLPPositions]);

  const initializeFilterOptions = () => {
    let initialExpirationTimesSearchParam = expirationTimesSearchParam;
    let newExpirationTimesSelected = new Map(expirationTimesSelected);
    let last5expirationsInSeconds = [];

    const marketIds = [[...new Set(trades.map(trade => trade.marketId))], [...new Set(lpTrades.map(lpTrade => lpTrade.marketId))]].flat();
    const expirationsInSeconds = [...new Set(markets.filter(market => marketIds.includes(market.marketId)).map(market => market.expirationTime))];
    expirationsInSeconds.sort((a, b) => Number(a) - Number(b));

    if (newExpirationTimesSelected.size === 0) {
      let closestExpirationDiff = Infinity;
      let closestExpiration = null;
      for (let i = 0; i < expirationsInSeconds.length; i++) {
        last5expirationsInSeconds.push(expirationsInSeconds[i]);
        const diff = currentTimestamp - expirationsInSeconds[i];
        if (diff < closestExpirationDiff) {
          closestExpirationDiff = diff;
          closestExpiration = expirationsInSeconds[i];
        }
      }

      if (closestExpiration) {
        !initialExpirationTimesSearchParam && (initialExpirationTimesSearchParam = "All Expirations");
        newExpirationTimesSelected.set("All Expirations", true);
        newExpirationTimesSelected.set("Last expiration", false);
        newExpirationTimesSelected.set("Liquidations", false);
        last5expirationsInSeconds.sort(function(a, b) {
          return b - a;
        });
        if (last5expirationsInSeconds.length > 5) {
          last5expirationsInSeconds = last5expirationsInSeconds.slice(0, 5);
        }
      } else {
        !initialExpirationTimesSearchParam && (initialExpirationTimesSearchParam = "All Expirations");
        newExpirationTimesSelected.set("All Expirations", true);
        newExpirationTimesSelected.set("Last expiration", false);
        newExpirationTimesSelected.set("Liquidations", false);
      }
    }

    const allExpirationsMap = new Map();

    expirationsInSeconds.forEach(expirationInSeconds => {
      const expirationDate = new Date(expirationInSeconds * 1000);

      const day = expirationDate.getDate();

      const monthNumber = expirationDate.getMonth();
      const month = toMonthName(monthNumber + 1)
        .slice(0, 3)
        .toUpperCase();
      const year = expirationDate.getFullYear();

      const formattedExpirationDate = `${day} ${month} ${String(year).slice(2)}`;

      allExpirationsMap.set(expirationInSeconds, formattedExpirationDate);
    });

    let allExpirationsSorted = [];
    for (let [expInSec, _] of allExpirationsMap) {
      allExpirationsSorted.push(expInSec);
    }
    allExpirationsSorted.forEach(expInSeconds => {
      const selected = newExpirationTimesSelected.get(allExpirationsMap.get(expInSeconds));
      newExpirationTimesSelected.set(allExpirationsMap.get(expInSeconds), selected);
    });
    setExpirationTimesSearchParam(initialExpirationTimesSearchParam);
    setExpirationTimesSelected(newExpirationTimesSelected);
  };

  const tradesAreLoaded = () => {
    if (trades === null || !lpTradesLoaded || !liquidationTradesLoaded || !marketsLoaded || !lpPositionTokensLoaded || !allLPTrades) {
      return false;
    }

    return checkTradesAndLPTrades(trades, lpTrades);
  };

  const areTradesLoaded = tradesAreLoaded();

  const isTradeAfterSettlement = (trade, market) => {
    const tradeTime = parseFloat(trade.timestamp);
    if (market.isSettled) {
      const settlement = settlements.find(s => s.expirationTime == market.expirationTime);
      if (settlement) {
        const settlementTime = parseFloat(settlement.settlementTime);
        if (tradeTime >= settlementTime) {
          return true;
        }
      }
    }

    return false;
  };

  const getTableData = () => {
    if (web3AccountLoaded && areTradesLoaded) {
      let sortableTrades = [...trades, ...lpTrades];
      sortableTrades.sort((a, b) => {
        return parseInt(b.timestamp) - parseInt(a.timestamp);
      });

      // filter out very small trades (dust)
      sortableTrades = sortableTrades.filter(trade => trade.size > 1000000 / 10 ** 18 || trade.type === "lp");

      // TODO: v2 it would be cleaner and testable if this mapping was done through selector
      const mappedTrades = sortableTrades.map((trade, index) => {
        const tokenName = trade.shortName;
        if (tokenName !== "...") {
          const isShort = trade.type === "short";
          const isLong = trade.type === "long";
          const isLongOrShort = trade.type === "short" || trade.type === "long";
          const isLP = trade.type === "lp";
          const isCloseLP = isLP && !trade.isBuy;
          const isDecrease = isCloseLP && trade.size === 0;
          const isIncreaseOrDecrease = isLP && trade.size === 0 && !trade.isPenaltyOrReward;
          const isPenaltyOrReward = isLP && trade.size === 0 && trade.isPenaltyOrReward;
          const baseName = trade.tokenPair.baseTokenSymbol;

          const market = markets.find(ex => ex.marketId === trade.marketId);
          const collName = market.isCall ? trade.tokenPair.underlyingTokenSymbol : trade.tokenPair.baseTokenSymbol;
          const isSettled = market.isSettled;

          // token
          let shortName = trade.shortName;

          const marketIsExpired = isSettled || Number(market.expirationTime) < currentTimestamp;
          const tradeTime = parseFloat(trade.timestamp);
          const tradeAfterSettlement = isTradeAfterSettlement(trade, market);
          let underlyingPriceAtExpired = 0;
          if (tradeAfterSettlement) {
            underlyingPriceAtExpired = getUnderlyingPriceAtExpiredNumber(market, settlements);
          }

          // if market is settled or expired, check if trade event is after settlement/expiration, add expired tag if so
          if (tradeAfterSettlement) {
            shortName += " (EXPIRED $" + underlyingPriceAtExpired.toFixed(2) + ")";
          } else if (marketIsExpired) {
            const expirationTime = parseFloat(market.expirationTime);
            if (tradeTime >= expirationTime) {
              shortName += " (EXPIRED)";
            }
          }

          // type
          const type = getType(trade, market);

          // price
          let tradeBasePrice = parseFloat(trade.basePrice);
          let displayTradeBasePrice = tradeBasePrice;
          if (tradeAfterSettlement) {
            if (market.isFuture) {
              displayTradeBasePrice = underlyingPriceAtExpired;
            } else {
              displayTradeBasePrice = market.isCall
                ? Math.max(0, underlyingPriceAtExpired - market.strikePrice)
                : Math.max(0, market.strikePrice - underlyingPriceAtExpired);
            }
          }
          const priceStr = isLongOrShort ? displayTradeBasePrice : "";

          // fees
          let feesArr = "";
          let fees = -trade.interest; // todo: add settlement fees, now we don't have them
          if (fees < -0.005) {
            feesArr = [fees, ""];
          }

          // collateral
          let collateralArr = "";
          if (isShort) {
            collateralArr = [(trade.isBuy ? 1 : -1) * trade.size * (market.isCall ? 1 : market.strikePrice), collName];
          } else if (isLP && !isPenaltyOrReward) {
            collateralArr = [(trade.isBuy ? -1 : 1) * trade.size * trade.collPrice, collName];
          }

          // size
          let size = trade.size;
          if (isLP && openedLPPositions) {
            if (trade.size > 0) {
              // add or remove, can be from liquidation
              if (trade.isBuy) {
                // if trade event is buy, then it has the lower and upper, unless it's from liquidation, then we need to find it
                let lower = trade.lower;
                let upper = trade.upper;

                // if from liquidation, it doesn't have upper and lower set, so we find it in all trades
                if (lower == undefined && upper == undefined) {
                  let buyTrade = allLPTrades.find(
                    t =>
                      t.isBuy === true && t.positionId === trade.positionId && t.marketId === trade.marketId && t.lower !== undefined && t.upper !== undefined
                  );
                  lower = buyTrade.lower;
                  upper = buyTrade.upper;
                }
                size = getDx(trade.size, 1.0001 ** lower, 1.0001 ** upper);
                const actualTradeBasePrice = tradeBasePrice * trade.size;
                tradeBasePrice = actualTradeBasePrice / size; // adjust tradeBasePrice to size
              } else {
                // if trade event is sell, we need to find matching buy, because sell doesn't hold lower and upper
                // look in all trades because maybe open lp position was through liquidation
                let matchingBuyTrade = allLPTrades.find(
                  t => t.isBuy && t.positionId === trade.positionId && t.marketId === trade.marketId && t.lower !== undefined && t.upper !== undefined
                );
                if (matchingBuyTrade) {
                  size = getDx(matchingBuyTrade.size, 1.0001 ** matchingBuyTrade.lower, 1.0001 ** matchingBuyTrade.upper);
                  const actualTradeBasePrice = tradeBasePrice * trade.size;
                  tradeBasePrice = actualTradeBasePrice / size; // adjust tradeBasePrice to size
                }
              }
            } else {
              // increase or decrease
              size = trade.basePrice;
              tradeBasePrice = 1;
            }
          }
          const sizeStr = isIncreaseOrDecrease || isPenaltyOrReward ? 0 : size;

          // proceeds (opposite of cost)
          let proceeds = -size * tradeBasePrice;

          if ((isLongOrShort && !trade.isBuy) || isCloseLP || isDecrease) {
            proceeds *= -1;
          }
          proceeds -= trade.interest;
          const proceedsArr = [proceeds, baseName];

          // liquidated
          const liquidated = trade.isLiquidation || isPenaltyOrReward;
          const { liquidatorColor, liquidatorImg } = isPenaltyOrReward ? getLPPenaltyOrRewardColorAndImage(type) : getLiquidatorColorAndImage(trade);

          return {
            id: trade,
            token: [shortName, liquidated, liquidatorColor, liquidatorImg],
            type: type,
            size: sizeStr,
            price: priceStr,
            collateral: collateralArr,
            fees: feesArr,
            value: proceedsArr,
            expirationTime: parseInt(market.expirationTime),
            timestamp: [trade.readableTimestamp, trade.timestamp]
          };
        }
      });

      // account liquidation trades (penalties and rewards)
      const mappedLiquidationTrades = liquidationTrades.map((trade, index) => {
        const { liquidatorColor, liquidatorImg } = getLiquidatorColorAndImage(trade);
        // NOTE: contract event returns 110%, so we divide by 11 or 22 (10% or 5%) to get the penalty/reward part
        // todo: this might not be correct for all cases of liquidation, smart contract needs to be updated
        const userPart = trade.isBuy ? 1 / 22 : -1 / 11;
        let proceeds = parseFloat(trade.baseAmount) * userPart;
        const proceedsArr = [proceeds, "USD"];

        return {
          id: trade,
          token: ["Account Liquidation", true, liquidatorColor, liquidatorImg],
          type: trade.isBuy ? "reward" : "penalty",
          size: 0,
          price: "",
          collateral: "",
          fees: "",
          value: proceedsArr,
          expirationTime: parseInt(0),
          timestamp: [trade.readableTimestamp, trade.timestamp]
        };
      });

      // mapping can introduce undefined elements, so we filter them out
      const mappedFilteredTrades = mappedTrades.filter(trade => trade !== undefined);
      const tradesFinal = [...mappedLiquidationTrades, ...mappedFilteredTrades];
      const tradesFinalSorted = tradesFinal.sort((a, b) => {
        return b.timestamp[1] - a.timestamp[1];
      });

      return tradesFinalSorted;
    }

    return [];
  };

  const getType = (trade, market) => {
    let type = trade.isBuy ? "buy" : "sell";

    if (trade.type === "lp") {
      if (trade.size > 0) {
        type = trade.isBuy ? "add" : trade.liquidator === null ? "remove" : "liquidate";
      } else {
        if (trade.isPenaltyOrReward) {
          type = trade.isBuy ? "penalty" : "reward";
        } else {
          type = trade.isBuy ? "increase" : "decrease";
        }
      }
    }

    // if trade is after settlement, overide type to settle
    if (isTradeAfterSettlement(trade, market)) {
      type = "settle";
    }

    return type;
  };

  const getLPPenaltyOrRewardColorAndImage = type => {
    const liquidatorColor = type === "penalty" ? "#ee1b41" : "#8fce51";
    const liquidatorImg = type === "penalty" ? "red" : "green";

    return { liquidatorColor, liquidatorImg };
  };

  const getLiquidatorColorAndImage = trade => {
    let liquidatorColor;
    let liquidatorImg;
    if (trade.isBuy === true && trade.type === "long") {
      liquidatorColor = "#8fce51";
      liquidatorImg = "green";
    } else if (trade.isBuy === false && trade.type === "long") {
      liquidatorColor = "#ee1b41";
      liquidatorImg = "red";
    } else if (trade.isBuy === true && trade.type === "short") {
      liquidatorColor = "#ee1b41";
      liquidatorImg = "red";
    } else if (trade.isBuy === false && trade.type === "short") {
      liquidatorColor = "#8fce51";
      liquidatorImg = "green";
    } else if (trade.isBuy === true && trade.type === "lp") {
      liquidatorColor = "#8fce51";
      liquidatorImg = "green";
    } else if (trade.isBuy === false && trade.type === "lp") {
      liquidatorColor = "#ee1b41";
      liquidatorImg = "red";
    } else if (trade.isBuy === true && trade.type === "account") {
      liquidatorColor = "#8fce51";
      liquidatorImg = "green";
    } else if (trade.isBuy === false && trade.type === "account") {
      liquidatorColor = "#ee1b41";
      liquidatorImg = "red";
    }

    return { liquidatorColor, liquidatorImg };
  };

  const onMouseEnterColumnHeader = e => {
    if (!tooltipIsVisible) {
      setTooltipIsVisible(true);
      setTooltipContent(TOOLTIPS_TEXT[e.currentTarget.getAttribute("name")]);
      setTooltipTarget(e.currentTarget.id);
    }
  };

  const onMouseLeaveColumnHeader = e => {
    if (!cursorRangeCheck(tooltipTarget, e.clientX, e.clientY)) {
      setTooltipIsVisible(false);
      setTooltipContent("");
      setTooltipTarget("");
      document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", -500 + "px");

      document.querySelector(":root").style.setProperty("--tooltipTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipLeftPosition", -500 + "px");
    }
  };

  const getTableColumns = () => {
    const tableColumns = [
      {
        Header: () => (
          <div className="alignLeft">
            <span id="pool-history-token" name="poolHistoryToken" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Token
            </span>
          </div>
        ),
        accessor: "token",
        align: "right",
        minWidth: 100,
        maxWidth: 5 * 100,
        filterable: false,
        sortable: true,
        Cell: row => {
          return (
            <>
              <span className="alignLeft">{row.value[0]}</span>
              &nbsp; &nbsp; &nbsp;
              <img className={row.value[1] ? (row.value[3] === "green" ? "liquidationGreen" : row.value[3] === "red" ? "liquidationRed" : "") : ""} />
              &nbsp;
              <span className="alignLeft" style={{ color: row.value[2] }}>
                {row.value[1] ? "(liquidated)" : ""}
              </span>
            </>
          );
        },
        Footer: () => (
          <span className="floatLeft">
            <strong>Total&nbsp;&nbsp;{tableData.length === 0 && "P/L"}</strong>
          </span>
        )
      },
      {
        Header: () => (
          <div>
            <span id="pool-history-type" name="poolHistoryType" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Type
            </span>
          </div>
        ),
        accessor: "type",
        align: "right",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: false,
        Cell: row => <span className="floatRight">{row.value}</span>
      },
      {
        Header: () => (
          <div>
            <span id="pool-history-size" name="poolHistorySize" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              QTY
            </span>
          </div>
        ),
        accessor: "size",
        minWidth: 30,
        maxWidth: 5 * 30,
        filterable: false,
        sortable: false,
        Cell: row => {
          return <span className="floatRight">{row.value !== 0 ? formatValue(row.value, 2) : ""}</span>;
        }
      },
      {
        Header: () => (
          <div>
            <span id="pool-history-price" name="poolHistoryPrice" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Price
            </span>
          </div>
        ),
        accessor: "price",
        minWidth: 30,
        maxWidth: 5 * 30,
        filterable: false,
        sortable: false,
        Cell: row => <span className="floatRight monospace">{row.value !== "" ? printPriceFromString(row.value.toFixed(2)) : ""}</span>,
        sortMethod: (a, b) => {
          return b[0] - a[0];
        }
      },
      {
        Header: () => (
          <div>
            <span id="pool-history-collateral" name="poolHistoryCollateral" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Collateral
            </span>
          </div>
        ),
        accessor: "collateral",
        minWidth: 40,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        Cell: row => <span className="floatRight">{row.value.length === 2 ? row.value[0].toFixed(2) + " " + row.value[1] : row.value[0]}</span>
      },
      {
        Header: () => (
          <div>
            <span id="pool-history-fees" name="poolHistoryFees" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Interest
            </span>
          </div>
        ),
        accessor: "fees",
        minWidth: 40,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        Cell: row => (
          <span className="floatRight">{row.value.length === 2 ? printPriceFromString(row.value[0].toFixed(2)) + " " + row.value[1] : row.value[0]}</span>
        )
      },
      {
        Header: () => (
          <div>
            <span id="pool-history-value" name="poolHistoryValue" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Total Premium
            </span>
          </div>
        ),
        accessor: "value",
        minWidth: 40,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        Cell: row => <span className="floatRight monospace">{printPriceFromString(row.value[0].toFixed(2))}</span>,
        sortMethod: (a, b) => {
          return b[0] - a[0];
        },
        Footer: rows => (
          <span className="floatRight monospace">
            <strong>
              {rows.data.length > 0
                ? getValueFooter(rows) === 0 || Math.abs(getValueFooter(rows)).toFixed(2) === "0.00"
                  ? printPriceFromString("0")
                  : rows.data && printPriceFromString(getValueFooter(rows).toFixed(2))
                : ""}
            </strong>
          </span>
        )
      },
      {
        Header: () => (
          <div>
            <span id="pool-history-timestamp" name="poolHistoryTimestamp" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Timestamp
            </span>
          </div>
        ),
        accessor: "timestamp",
        minWidth: 40,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        Cell: row => <span className="floatRight">{row.value[0]}</span>,
        sortMethod: (a, b) => {
          return b[1] - a[1];
        }
      }
    ];

    return tableColumns;
  };

  let allData = getTableData();

  const filterTableData = () => {
    let filteredData = [];

    // use penalties and rewards if "Liquidations" is selected
    if (expirationTimesSearchParam === "Liquidations") {
      return allData.filter(d => d.type === "penalty" || d.type === "reward");
    }

    let formattedLastExpirationDate = null;
    for (let [expirationTime, _] of expirationTimesSelected) {
      if (expirationTime !== "Last expiration" && expirationTime !== "All Expirations") {
        formattedLastExpirationDate = expirationTime;
      }
    }

    allData.forEach(d => {
      const expirationInSeconds = d.expirationTime;
      const expirationDate = new Date(expirationInSeconds * 1000);

      const day = expirationDate.getDate();
      const monthNumber = expirationDate.getMonth();
      const month = toMonthName(monthNumber + 1)
        .slice(0, 3)
        .toUpperCase();
      const year = expirationDate.getFullYear();

      const formattedExpirationDate = `${day} ${month} ${String(year).slice(2)}`;
      if (
        (expirationTimesSearchParam === "Last expiration" && formattedExpirationDate !== formattedLastExpirationDate) ||
        (expirationTimesSearchParam !== "All Expirations" &&
          expirationTimesSearchParam !== "Last expiration" &&
          !expirationTimesSelected.get(formattedExpirationDate))
      ) {
        return;
      }
      filteredData.push(d);
    });
    return filteredData;
  };

  const tableData = filterTableData();

  const printPoolTable = () => {
    if (web3AccountLoaded && areTradesLoaded && allData.length > 0) {
      return (
        <div key="tableHistoryWrapper" ref={tableWrapper}>
          <ReactTable
            // className="-highlight"
            data={tableData}
            // filterable
            columns={getTableColumns()}
            defaultPageSize={5}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <FilterContext.Consumer>
        {({ closeAllFilterDropmenus }) => {
          return (
            <>
              <FiltersHistory
                parent={tableWrapper.current}
                expirationTimesSearchParam={expirationTimesSearchParam}
                expirationTimesSelected={expirationTimesSelected}
                setExpirationTimesSearchParam={setExpirationTimesSearchParam}
                setExpirationTimesSelected={setExpirationTimesSelected}
              />
              <div className="content" style={{ paddingTop: "10px" }}>
                <Row>
                  <Col className="mb-1" md="12">
                    <Card>
                      <CardHeader
                        className="handle"
                        onDoubleClick={e => {
                          e.stopPropagation();
                          closeAllFilterDropmenus();
                          localStorage.getItem("isPhpDisplayed") === "true"
                            ? localStorage.setItem("isPhpDisplayed", false)
                            : localStorage.setItem("isPhpDisplayed", true);
                          setPanelIsDisplayed(panelIsDisplayed => !panelIsDisplayed);
                        }}
                      >
                        <CardTitle tag="h4">
                          <div className="historyHeaderContainer">
                            <span className="headerTitle">{CARD_TITLE}</span>

                            {panelIsDisplayed && (
                              <>
                                <div
                                  id="phpArrowUp"
                                  className="arrowHeaderWrapper"
                                  onClick={e => {
                                    e.stopPropagation();
                                    closeAllFilterDropmenus();
                                    setPanelIsDisplayed(false);
                                    localStorage.setItem("isPhpDisplayed", false);
                                    setTooltipContent("Show panel");
                                  }}
                                  onMouseEnter={e => {
                                    if (!tooltipIsVisible) {
                                      setTooltipIsVisible(true);
                                      setTooltipContent("Hide panel");
                                      setTooltipTarget(e.currentTarget.id);
                                    }
                                  }}
                                  onMouseLeave={onMouseLeaveColumnHeader}
                                >
                                  <img style={{ margin: "auto" }} className="arrowUpIcon" />
                                </div>
                              </>
                            )}
                            {!panelIsDisplayed && (
                              <>
                                <div
                                  id="phpArrowDown"
                                  className="arrowHeaderWrapper"
                                  onClick={e => {
                                    e.stopPropagation();
                                    closeAllFilterDropmenus();
                                    setPanelIsDisplayed(true);
                                    localStorage.setItem("isPhpDisplayed", true);
                                    setTooltipContent("Hide panel");
                                  }}
                                  onMouseEnter={e => {
                                    if (!tooltipIsVisible) {
                                      setTooltipIsVisible(true);
                                      setTooltipContent("Show panel");
                                      setTooltipTarget(e.currentTarget.id);
                                    }
                                  }}
                                  onMouseLeave={onMouseLeaveColumnHeader}
                                >
                                  <img style={{ margin: "auto" }} className="arrowDownIcon" />
                                </div>
                              </>
                            )}
                          </div>
                        </CardTitle>
                      </CardHeader>
                      <CardBody id={"php"} className={panelIsDisplayed ? "" : "displayNone"}>
                        {printPoolTable()}
                        {printEmptyTable(web3AccountLoaded, areTradesLoaded, allData, EMPTY_TABLE_TEXT)}
                        {printLoadingIcon(web3AccountLoaded, areTradesLoaded)}
                        <ConnectPanel />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </>
          );
        }}
      </FilterContext.Consumer>
      {tooltipIsVisible && (
        <MyTooltip2 target={tooltipTarget} setTooltipIsVisible={setTooltipIsVisible} setTooltipContent={setTooltipContent} setTooltipTarget={setTooltipTarget}>
          {tooltipContent}
        </MyTooltip2>
      )}
    </>
  );
};

export default PoolHistoryPanel;
