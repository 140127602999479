import ERC20 from "../../abis/ERC20.json";
import { handleNewError } from "./errorInteractions";
import { Promise } from "bluebird";
import {
  borrowEventAdded,
  lendEventAdded,
  loadingTokenAccountBalance,
  loadingTokenWalletBalance,
  marginAccountDataLoaded,
  marginEventsLoaded,
  marginPoolDataLoaded,
  redeemEventAdded,
  repaidEventAdded,
  tokenAccountBalanceLoaded,
  tokenWalletBalanceLoaded
} from "../actions/actions.js";
import { loadAssetAccountBalance, loadAssetWalletBalance, wethAddress } from "./tokensInteractions";
import { formatUnits } from "@ethersproject/units";
import { ding, getNonce, hasTxFailed, onConfirmed, onError, onFailed, onPending } from "../../utils/utils.js";
import { store } from "../configureStore";
import { BigNumber } from "@ethersproject/bignumber";
import { Contract } from "@ethersproject/contracts";
import { getInterestRate } from "../../utils/MarginHelper";
import { union } from "lodash";
import { toBn } from "evm-bn";
import murmurhash from "murmurhash-js";
import { batch } from "react-redux";
import { getEventsStreamCached } from "./interactionsManager.js";
import { loadMarginAccountData2, loadMarginPoolData2 } from "../utils/marginUtils.js";
import { loadAssetAccountBalance2, loadAssetWalletBalance2 } from "../utils/tokensUtils.js";

//TODO: napravljena je funkcija koja rekurzivno ucitava likvidirane naloge, Bojan treba da pogleda i odobri
// TODO: v1 ovu funkciju treba usavrsiti jer je moguce da je i neki od likvidiranih account-ova bio likvidator pa:
// 1. treba povuci sve evente account-ova koje je on likvidirao
// 2. napraviti odbranu od cirkularne zavisnosti i beskonacne petlje (ja likvidirao tebe ti likvidirao mene)
export const loadMarginPoolEvents = async (networkData, marginPool, userBalances, lpManager, userAccount, dispatch) => {
  const startTime = new Date().getTime();
  try {
    // NEW CODE
    const [marginPoolAllEvents, userBalancesAllEvents, lpManagerAllEvents] = await Promise.all([
      getEventsStreamCached(networkData, marginPool, "MarginPool", "allEvents", {}),
      getEventsStreamCached(networkData, userBalances, "UserBalances", "allEvents", {}),
      getEventsStreamCached(networkData, lpManager, "LPManager", "allEvents", {})
    ]);

    const lendStream = marginPoolAllEvents.filter(e => e.event === "UnderlyingLent" && e.lender === userAccount);
    const redeemStream = marginPoolAllEvents.filter(e => e.event === "UnderlyingRedeemed" && e.redeemer === userAccount);
    const repaidStream = marginPoolAllEvents.filter(e => e.event === "UnderlyingRepaid" && e.payer === userAccount);
    const borrowStream = marginPoolAllEvents.filter(e => e.event === "UnderlyingBorrowed");
    const liquidationsStream = userBalancesAllEvents.filter(e => e.event === "AccountLiquidated" && e.liquidatorAccount === userAccount);
    const lpLiquidationStream = lpManagerAllEvents.filter(e => e.event === "LiquidatedMarketMakerPosition" && e.user === userAccount);

    // extract an array of liquidated accounts
    let borrowAccounts = liquidationsStream.map(event => {
      return event.returnValues.liquidatedAccount;
    });
    borrowAccounts.push(userAccount);

    let mpBorrowEvents;
    const allBorrowEvents = union(undefined, borrowStream); // todo: can we remove union, does it filter duplicates?

    mpBorrowEvents = allBorrowEvents.map(event => {
      const borrow = event.returnValues;
      return {
        borrower: borrow.borrower,
        assetAddress: borrow.underlyingTokenAddress,
        amount: borrow.amount.toString(),
        marketId: borrow.marketId,
        borrowIndex: borrow.borrowIndex,
        isLPPosition: borrow.isLPPosition,
        positionId: borrow.positionId,
        timestamp: borrow.timestamp,
        signature: event.transactionHash + murmurhash(event.raw.data)
      };
    });
    // in case my LP position was liquidated, and I was left with some short tokens, those short token borrows correspond to borrows
    // done when opening the LP position, the only difference is the amount, which is remaining shorts that I am left with
    for (let i = 0; i < lpLiquidationStream.length; i++) {
      const borrow = lpLiquidationStream[i].returnValues;
      if (borrow.shortsUpdated > 0) {
        const borrowEvent = mpBorrowEvents.find(
          event => event.borrower === borrow.user && event.marketId == borrow.marketId && event.positionId == borrow.positionId
        );
        if (borrowEvent) {
          mpBorrowEvents.push({
            borrower: borrowEvent.borrower,
            assetAddress: borrowEvent.assetAddress,
            amount: borrow.shortsUpdated.toString(),
            marketId: borrowEvent.marketId,
            borrowIndex: borrowEvent.borrowIndex,
            isLPPosition: false,
            positionId: 0,
            timestamp: borrowEvent.timestamp,
            signature: borrowEvent.transactionHash + murmurhash(lpLiquidationStream[i].raw.data)
          });
        }
      }
    }

    let mpLendEvents = lendStream.map(event => {
      const lend = event.returnValues;
      return {
        lender: lend.lender,
        assetAddress: lend.underlyingTokenAddress,
        amount: lend.amount.toString(),
        timestamp: lend.timestamp,
        signature: event.transactionHash + murmurhash(event.raw.data)
      };
    });
    let mpRedeemEvents = redeemStream.map(event => {
      const redeem = event.returnValues;
      return {
        redeemer: redeem.redeemer,
        assetAddress: redeem.underlyingTokenAddress,
        amount: redeem.amount.toString(),
        premium: redeem.newPremium.toString(),
        timestamp: redeem.timestamp,
        signature: event.transactionHash + murmurhash(event.raw.data)
      };
    });
    let mpRepaidEvents = repaidStream.map(event => {
      const repaid = event.returnValues;
      return {
        borrower: repaid.payer,
        assetAddress: repaid.underlyingTokenAddress,
        amount: repaid.amount.toString(),
        base: repaid.newBase.toString(),
        timestamp: repaid.timestamp,
        signature: event.transactionHash + murmurhash(event.raw.data)
      };
    });

    const endTime = new Date().getTime();
    console.log("loadMarginPoolEvents took: ", endTime - startTime, "mS");

    dispatch(marginEventsLoaded(mpLendEvents, mpRedeemEvents, mpBorrowEvents, mpRepaidEvents));
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while fetching margin account data. Please reload the application!", 1, dispatch);
  }
};

export const loadMarginPoolData = async (loadHelper, marginPool, underPriceBN, gammaContractsRegistry, dispatch) => {
  try {
    // use state to get token addresses
    const { tokens } = store.getState();
    const collateralTokens = tokens.data.map(t => t.address);
    const referenceTokenAddress = tokens.data.find(t => t.symbol === "USD").address;

    const marginData = await Promise.map(collateralTokens, async (token, i) => {
      console.log("RPC method: loadHelper.loadMarginPoolData");
      const result = await loadHelper.methods.loadMarginPoolData(marginPool._address, token).call(); // TODO: this can be optimized - send array, return array
      let price;
      if (token.toString() === referenceTokenAddress.toString()) {
        price = 10 ** 18;
      } else {
        price = underPriceBN;
      }
      return {
        assetAddress: token,
        borrowedAmount: result.totalBorrowedAmount,
        currentSupply: result.availableUnderlyingAmount,
        interestRate: result.interestRate,
        price
      };
    });
    dispatch(marginPoolDataLoaded(marginData));
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while fetching margin pool data. Please reload the application!", 1, dispatch);
  }
};

export const loadMarginAccountData = async (account, loadHelper, marginPool, userBalances, underPriceBN, gammaContractsRegistry, dispatch) => {
  try {
    // use state to get token addresses
    const { tokens } = store.getState();
    const referenceTokenAddress = tokens.data.find(t => t.symbol === "USD").address;

    console.log("RPC method: loadHelper.loadMarginAccountData");
    const result = await loadHelper.methods.loadMarginAccountData(marginPool._address, userBalances._address, account, underPriceBN).call();
    let collateralTokens = result.collateralTokens;
    collateralTokens = await Promise.map(collateralTokens, async (token, i) => {
      let price;
      if (token.collateralToken.toString() === referenceTokenAddress.toString()) {
        price = 10 ** 18;
      } else {
        price = underPriceBN;
      }
      return {
        assetAddress: token.collateralToken,
        borrowedAmount: token.borrowedAmount,
        lendProceeds: token.lendProceeds,
        price
      };
    });
    const marginAccountData = {
      collateralTokens,
      health: result.health,
      isLiquidatable: result.isLiquidatable,
      liquidationThreshold: result.liquidationThreshold,
      recoveryThreshold: result.recoveryThreshold,
      totalLiquidity: result.totalLiquidity,
      totalDebt: result.totalDebt
    };
    dispatch(marginAccountDataLoaded(marginAccountData));
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while fetching margin account data. Please reload the application!", 1, dispatch);
  }
};

export const subscribeToMarginPoolEvents = async (
  // TODO: v1 when we have this, do we need to load margin pool related data on every position call
  provider,
  web3,
  loadHelper,
  marginPool,
  underPriceBN,
  userBalances,
  gammaContractsRegistry,
  account,
  dispatch
) => {
  const abi = [
    "event UnderlyingLent(address indexed lender, address indexed underlyingTokenAddress, uint128 amount, uint32 timestamp)",
    "event UnderlyingRedeemed(address indexed redeemer, address indexed underlyingTokenAddress, uint128 amount, uint128 newPremium, uint32 timestamp)",
    "event UnderlyingBorrowed(address indexed borrower, address indexed underlyingTokenAddress, uint128 amount, uint40 marketId, uint64 borrowIndex, bool isLPPosition, uint40 positionId, uint32 timestamp)",
    "event UnderlyingRepaid(address indexed payer, address indexed underlyingTokenAddress, uint128 amount, uint128 newBase, uint32 timestamp)"
  ];

  const marginPoolContract = new Contract(marginPool.options.address, abi, provider);

  marginPoolContract.on("UnderlyingLent", async (lender, underlyingTokenAddress, amount, timestamp, event) => {
    if (account === lender) {
      const { marginPoolData, marginAccountData, accountBalance, walletBalance } = await handleAccountOnMarginPoolEvent2(
        lender,
        underlyingTokenAddress,
        amount,
        event
      );

      batch(() => {
        dispatch(
          lendEventAdded({
            lender: lender,
            assetAddress: underlyingTokenAddress,
            amount: amount.toString(),
            timestamp: timestamp,
            signature: event.transactionHash + murmurhash(event.data)
          })
        );
        dispatch(marginPoolDataLoaded(marginPoolData));
        if (account === lender) {
          dispatch(marginAccountDataLoaded(marginAccountData));
          dispatch(loadingTokenAccountBalance(underlyingTokenAddress));
          dispatch(tokenAccountBalanceLoaded(underlyingTokenAddress, accountBalance));
          dispatch(loadingTokenWalletBalance(underlyingTokenAddress));
          dispatch(tokenWalletBalanceLoaded(underlyingTokenAddress, walletBalance));
        }
      });
    }
  });
  marginPoolContract.on("UnderlyingRedeemed", async (redeemer, underlyingTokenAddress, amount, newPremium, timestamp, event) => {
    if (account === redeemer) {
      const { marginPoolData, marginAccountData, accountBalance, walletBalance } = await handleAccountOnMarginPoolEvent2(
        redeemer,
        underlyingTokenAddress,
        amount,
        event
      );

      batch(() => {
        dispatch(
          redeemEventAdded({
            redeemer: redeemer,
            assetAddress: underlyingTokenAddress,
            amount: amount.toString(),
            premium: newPremium.toString(),
            timestamp: timestamp,
            signature: event.transactionHash + murmurhash(event.data)
          })
        );
        dispatch(marginPoolDataLoaded(marginPoolData));
        if (account === redeemer) {
          dispatch(marginAccountDataLoaded(marginAccountData));
          dispatch(loadingTokenAccountBalance(underlyingTokenAddress));
          dispatch(tokenAccountBalanceLoaded(underlyingTokenAddress, accountBalance));
          dispatch(loadingTokenWalletBalance(underlyingTokenAddress));
          dispatch(tokenWalletBalanceLoaded(underlyingTokenAddress, walletBalance));
        }
      });
    }
  });
  marginPoolContract.on(
    "UnderlyingBorrowed",
    async (borrower, underlyingTokenAddress, amount, marketId, borrowIndex, isLPPosition, positionId, timestamp, event) => {
      if (account === borrower) {
        const { marginPoolData, marginAccountData, accountBalance, walletBalance } = await handleAccountOnMarginPoolEvent2(
          borrower,
          underlyingTokenAddress,
          amount,
          event
        );
        batch(() => {
          dispatch(
            borrowEventAdded({
              borrower: borrower,
              assetAddress: underlyingTokenAddress,
              amount: amount.toString(),
              marketId: marketId.toString(),
              borrowIndex: borrowIndex,
              isLPPosition: isLPPosition,
              positionId: positionId.toString(),
              timestamp: timestamp,
              signature: event.transactionHash + murmurhash(event.data)
            })
          );
          dispatch(marginPoolDataLoaded(marginPoolData));
          if (account === borrower) {
            dispatch(marginAccountDataLoaded(marginAccountData));
            dispatch(loadingTokenAccountBalance(underlyingTokenAddress));
            dispatch(tokenAccountBalanceLoaded(underlyingTokenAddress, accountBalance));
            dispatch(loadingTokenWalletBalance(underlyingTokenAddress));
            dispatch(tokenWalletBalanceLoaded(underlyingTokenAddress, walletBalance));
          }
        });
      }
    }
  );
  marginPoolContract.on("UnderlyingRepaid", async (payer, underlyingTokenAddress, amount, newBase, timestamp, event) => {
    if (account === payer) {
      const { marginPoolData, marginAccountData, accountBalance, walletBalance } = await handleAccountOnMarginPoolEvent2(
        payer,
        underlyingTokenAddress,
        amount,
        event
      );

      batch(() => {
        dispatch(
          repaidEventAdded({
            payer: payer,
            assetAddress: underlyingTokenAddress,
            amount: amount.toString(),
            base: newBase.toString(),
            timestamp: timestamp,
            signature: event.transactionHash + murmurhash(event.data)
          })
        );
        dispatch(marginPoolDataLoaded(marginPoolData));
        if (account === payer) {
          dispatch(marginAccountDataLoaded(marginAccountData));
          dispatch(loadingTokenAccountBalance(underlyingTokenAddress));
          dispatch(tokenAccountBalanceLoaded(underlyingTokenAddress, accountBalance));
          dispatch(loadingTokenWalletBalance(underlyingTokenAddress));
          dispatch(tokenWalletBalanceLoaded(underlyingTokenAddress, walletBalance));
        }
      });
    }
  });

  const handleAccountOnMarginPoolEvent2 = async (executionAccount, assetAddress, amount, event) => {
    const { tokens } = store.getState();
    const wethAddress = tokens.data.find(t => t.symbol === "ETH").address;
    const marginPoolData = await loadMarginPoolData2(loadHelper, marginPool, underPriceBN, tokens);
    let marginAccountData = null,
      accountBalance = null,
      walletBalance = null;
    if (account === executionAccount) {
      [marginAccountData, accountBalance, walletBalance] = await Promise.all([
        loadMarginAccountData2(account, loadHelper, marginPool, userBalances, underPriceBN, tokens),
        loadAssetAccountBalance2(assetAddress, userBalances, executionAccount, dispatch),
        loadAssetWalletBalance2(web3, assetAddress, account, wethAddress)
      ]);
    }
    return { marginPoolData, marginAccountData, accountBalance, walletBalance };
  };
};

const getNotificationText = (amount, symbol, isLend, isRedeem) => {
  const quantity = Number(parseFloat(formatUnits(amount.toString(), 18))).toLocaleString();
  if (isRedeem) {
    return "Withdraw " + quantity + " " + symbol + " from Margin Pool";
  } else {
    return "Deposit " + quantity + " " + symbol + " to Margin Pool";
  }
};

export const deposit = async (web3, marginPool, assetAddress, amount, userAccount, symbol, closeModal, isLend, shouldRepayEntireDebt, setStep, dispatch) => {
  try {
    const notificationText = getNotificationText(amount, symbol, isLend, false);
    const txNonce = await getNonce(web3, userAccount);
    if (assetAddress === wethAddress) {
      let debt = await marginPool.methods.getBorrowerDebtAmount(wethAddress, userAccount).call();
      let debtAddValue = BigNumber.from(debt.toString()).div(BigNumber.from("10000"));
      debt = BigNumber.from(debt.toString()).add(debtAddValue);
      if (shouldRepayEntireDebt) {
        let contractMethod;
        const networkName = localStorage.getItem("networkName");
        if (networkName === "Sepolia" || networkName === "Base Sepolia") {
          // todo: should repay using weth, should be implemented in MarginPool
          // NOTE: even on mainnet or base, user should be able to repay with weth from his wallet.
          // UI already checks if there is enough ETH and WETH in wallet, so this will create errors
          // when user has WETH instead of ETH in wallet
          contractMethod = marginPool.methods.repayEntireDebtETH(userAccount);
        } else {
          contractMethod = marginPool.methods.repayEntireDebtETH(userAccount);
        }

        const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount, value: debt.toString() })) * 1.2); // 20% extra gas
        const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
        console.log("limitGas:", limitGas);
        let isConfirmed = false;
        await contractMethod
          .send({ value: debt.toString(), from: userAccount, gas: limitGas })
          .on("transactionHash", async function(hash) {
            onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
            const hasFailed = await hasTxFailed(web3, hash);
            if (hasFailed) {
              setStep(null);
              onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
            }
          })
          .on("confirmation", function(confirmationNumber, receipt) {
            if (!isConfirmed) {
              if (receipt.status) {
                setStep(null);
                onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              } else {
                setStep(null);
                onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              }
              isConfirmed = true;
            }
          });
      } else {
        const contractMethod = marginPool.methods.depositETH(userAccount);
        const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount, value: amount })) * 1.2); // 20% extra gas
        const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
        console.log("limitGas:", limitGas);
        let isConfirmed = false;
        await contractMethod
          .send({ value: amount, from: userAccount, gas: limitGas })
          .on("transactionHash", async function(hash) {
            onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
            const hasFailed = await hasTxFailed(web3, hash);
            if (hasFailed) {
              setStep(null);
              onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
            }
          })
          .on("confirmation", function(confirmationNumber, receipt) {
            if (!isConfirmed) {
              if (receipt.status) {
                setStep(null);
                onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              } else {
                setStep(null);
                onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              }
              isConfirmed = true;
            }
          });
      }
    } else {
      const tokenContract = new web3.eth.Contract(ERC20.abi, assetAddress);
      // check allowance, approve if needed
      let allowance = await tokenContract.methods.allowance(userAccount, marginPool.options.address).call();
      let decimals = await tokenContract.methods.decimals().call();
      const allowance18 = BigNumber.from(allowance).mul(BigNumber.from(10 ** (18 - decimals)));
      let amount18 = BigNumber.from(amount);
      amount18 = amount18.add(toBn("0.01", 18));
      const approveValue6 = amount18.div(10 ** (18 - decimals));

      if (allowance18.lt(amount18)) {
        setStep(1);
        const contractMethod = tokenContract.methods.approve(marginPool.options.address, approveValue6);
        const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
        console.log("limitGas:", limitGas);
        await contractMethod.send({ from: userAccount, gas: limitGas }).on("transactionHash", function(hash) {
          setStep(2);
          ding();
        });
        setStep(3);
      }
      if (shouldRepayEntireDebt) {
        const contractMethod = marginPool.methods.repayEntireDebt(userAccount, assetAddress);
        const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
        const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
        console.log("limitGas:", limitGas);
        let isConfirmed = false;
        await contractMethod
          .send({ from: userAccount, gas: limitGas })
          .on("transactionHash", async function(hash) {
            onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
            const hasFailed = await hasTxFailed(web3, hash);
            if (hasFailed) {
              setStep(null);
              onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
            }
          })
          .on("confirmation", function(confirmationNumber, receipt) {
            if (!isConfirmed) {
              if (receipt.status) {
                setStep(null);
                onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              } else {
                setStep(null);
                onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              }
              isConfirmed = true;
            }
          });
      } else {
        const contractMethod = marginPool.methods.deposit(userAccount, assetAddress, amount);
        const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
        const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
        console.log("limitGas:", limitGas);
        let isConfirmed = false;
        await contractMethod
          .send({ from: userAccount, gas: limitGas })
          .on("transactionHash", async function(hash) {
            onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
            const hasFailed = await hasTxFailed(web3, hash);
            if (hasFailed) {
              setStep(null);
              onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
            }
          })
          .on("confirmation", function(confirmationNumber, receipt) {
            if (!isConfirmed) {
              if (receipt.status) {
                setStep(null);
                onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              } else {
                setStep(null);
                onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              }
              isConfirmed = true;
            }
          });
      }
    }
  } catch (e) {
    setStep(null);
    onError(e, "There was an error while lending assets to margin pool!", dispatch);
  }
};

export const redeem = async (web3, marginPool, assetAddress, redeemAmount, userAccount, symbol, closeModal, shouldRedeemEntireAmount, dispatch) => {
  const notificationText = getNotificationText(redeemAmount, symbol, false, true);
  const txNonce = await getNonce(web3, userAccount);
  try {
    if (assetAddress === wethAddress) {
      if (shouldRedeemEntireAmount) {
        const contractMethod = marginPool.methods.redeemAllETH();
        const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
        const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
        console.log("limitGas:", limitGas);
        let isConfirmed = false;
        await contractMethod
          .send({ from: userAccount, gas: limitGas })
          .on("transactionHash", async function(hash) {
            onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
            const hasFailed = await hasTxFailed(web3, hash);
            if (hasFailed) {
              onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
            }
          })
          .on("confirmation", function(confirmationNumber, receipt) {
            if (!isConfirmed) {
              if (receipt.status) {
                onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              } else {
                onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              }
              isConfirmed = true;
            }
          });
      } else {
        const contractMethod = marginPool.methods.redeemETH(redeemAmount);
        const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
        const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
        console.log("limitGas:", limitGas);
        let isConfirmed = false;
        await contractMethod
          .send({ from: userAccount, gas: limitGas })
          .on("transactionHash", async function(hash) {
            onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
            const hasFailed = await hasTxFailed(web3, hash);
            if (hasFailed) {
              onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
            }
          })
          .on("confirmation", function(confirmationNumber, receipt) {
            if (!isConfirmed) {
              if (receipt.status) {
                onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              } else {
                onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              }
              isConfirmed = true;
            }
          });
      }
    } else {
      if (shouldRedeemEntireAmount) {
        const contractMethod = marginPool.methods.redeemAll(assetAddress);
        const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
        const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
        console.log("limitGas:", limitGas);
        let isConfirmed = false;
        await contractMethod
          .send({ from: userAccount, gas: limitGas })
          .on("transactionHash", async function(hash) {
            onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
            const hasFailed = await hasTxFailed(web3, hash);
            if (hasFailed) {
              onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
            }
          })
          .on("confirmation", function(confirmationNumber, receipt) {
            if (!isConfirmed) {
              if (receipt.status) {
                onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              } else {
                onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              }
              isConfirmed = true;
            }
          });
      } else {
        const contractMethod = marginPool.methods.redeem(assetAddress, redeemAmount);
        const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
        const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
        console.log("limitGas:", limitGas);
        let isConfirmed = false;
        await contractMethod
          .send({ from: userAccount, gas: limitGas })
          .on("transactionHash", async function(hash) {
            onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
            const hasFailed = await hasTxFailed(web3, hash);
            if (hasFailed) {
              onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
            }
          })
          .on("confirmation", function(confirmationNumber, receipt) {
            if (!isConfirmed) {
              if (receipt.status) {
                onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              } else {
                onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              }
              isConfirmed = true;
            }
          });
      }
    }
  } catch (e) {
    onError(e, "There was an error while redeeming assets from margin pool!", dispatch);
  }
};

export const getAPR = (currentAmountInPool, borrowedAmountFromPool, value, action, dispatch, isMax = false) => {
  if (action.toUpperCase() === "LEND") {
    return getLendAPRWithAddValue(currentAmountInPool, borrowedAmountFromPool, value, dispatch);
  } else if (action.toUpperCase() === "BORROW") {
    return isMax ? { normalizedAPR: 0, readableAPR: "0.00%" } : getBorrowAPRWithAddValue(currentAmountInPool, borrowedAmountFromPool, value, dispatch);
  } else if (action.toUpperCase() === "REDEEM") {
    return isMax ? { normalizedAPR: 0, readableAPR: "0.00%" } : getLendAPRWithSubValue(currentAmountInPool, borrowedAmountFromPool, value, dispatch);
  } else {
    return isMax ? { normalizedAPR: 0, readableAPR: "0.00%" } : getBorrowAPRWithSubValue(currentAmountInPool, borrowedAmountFromPool, value, dispatch);
  }
};

const getBorrowAPRWithSubValue = (currentAmountInPool, borrowedAmountFromPool, subValue, dispatch) => {
  try {
    if (subValue.toString().includes("e") || borrowedAmountFromPool.toString().includes("e") || currentAmountInPool.toString().includes("e")) {
      return;
    }
    const borrowedAmount = borrowedAmountFromPool < subValue ? 0 : borrowedAmountFromPool - subValue;
    const normalizedAPR = getInterestRate(currentAmountInPool + subValue, borrowedAmount);
    const readableAPR = Number((normalizedAPR * 100).toFixed(2)).toLocaleString() + "%";
    return { normalizedAPR, readableAPR };
  } catch (e) {
    onError(e, "There was an error calculating APR!", dispatch);
  }
};

const getBorrowAPRWithAddValue = (currentAmountInPool, borrowedAmountFromPool, addValue, dispatch) => {
  try {
    if (addValue.toString().includes("e") || borrowedAmountFromPool.toString().includes("e") || currentAmountInPool.toString().includes("e")) {
      if (addValue.toString().includes("e")) {
        addValue = 0;
      } else if (borrowedAmountFromPool.toString().includes("e")) {
        borrowedAmountFromPool = 0;
      } else if (currentAmountInPool.toString().includes("e")) {
        currentAmountInPool = 0;
      }

      const normalizedAPR = parseFloat(getInterestRate(currentAmountInPool - addValue, borrowedAmountFromPool + addValue));
      const readableAPR = Number((normalizedAPR * 100).toFixed(2)).toLocaleString() + "%";
      return { normalizedAPR, readableAPR };
    }
    if (addValue > currentAmountInPool) return;
    const normalizedAPR = parseFloat(getInterestRate(currentAmountInPool - addValue, borrowedAmountFromPool + addValue));
    const readableAPR = Number((normalizedAPR * 100).toFixed(2)).toLocaleString() + "%";
    return { normalizedAPR, readableAPR };
  } catch (e) {
    onError(e, "There was an error calculating APR!", dispatch);
  }
};

const getLendAPRWithAddValue = (currentAmountInPool, borrowedAmountFromPool, addValue, dispatch) => {
  try {
    if (addValue.toString().includes("e") || borrowedAmountFromPool.toString().includes("e") || currentAmountInPool.toString().includes("e")) {
      return;
    }
    const normalizedBorrowAPR = parseFloat(getInterestRate(currentAmountInPool + addValue, borrowedAmountFromPool));
    const normalizedAPR = (normalizedBorrowAPR * borrowedAmountFromPool) / (currentAmountInPool + borrowedAmountFromPool + addValue);
    const readableAPR = Number((normalizedAPR * 100).toFixed(2)).toLocaleString() + "%";
    return { normalizedAPR, readableAPR };
  } catch (e) {
    onError(e, "There was an error calculating APR!", dispatch);
  }
};

const getLendAPRWithSubValue = (currentAmountInPool, borrowedAmountFromPool, addValue, dispatch) => {
  try {
    if (addValue.toString().includes("e") || borrowedAmountFromPool.toString().includes("e") || currentAmountInPool.toString().includes("e")) {
      return;
    }
    const normalizedBorrowAPR = parseFloat(getInterestRate(currentAmountInPool - addValue, borrowedAmountFromPool));
    const normalizedAPR = (normalizedBorrowAPR * borrowedAmountFromPool) / (currentAmountInPool + borrowedAmountFromPool - addValue);
    const readableAPR = Number((normalizedAPR * 100).toFixed(2)).toLocaleString() + "%";
    return { normalizedAPR, readableAPR };
  } catch (e) {
    onError(e, "There was an error calculating APR!", dispatch);
  }
};
