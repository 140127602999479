import { toBn } from "evm-bn";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "reactstrap/lib/Form";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import { decreaseReserves, increaseReserves } from "../../store/interactions/positionsManagerInteractions";
import { lpManagerSelector } from "../../store/selectors/contractsSelectors";
import { web3AccountSelector, web3Selector } from "../../store/selectors/web3Selectors";
import { tokenBalancesSelector } from "../../store/selectors/tokensSelectors";
import { isPositiveNumber, printPriceFromNumber, toPreciseString } from "../../utils/utils.js";
import PropTypes from "prop-types";
import { marginAccountDataSelector } from "../../store/selectors/marginSelectors";
import MyTooltip from "./MyTooltip.jsx";
import { getRequiredBase, toSqrtPrice } from "../../utils/MarketHelper.js";

const Reserves = props => {
  const [reservesAmount, setReservesAmount] = useState("");
  const [reservesInputIncreaseClass, setReservesInputIncreaseClass] = useState("");
  const [reservesInputDecreaseClass, setReservesInputDecreaseClass] = useState("");
  const [reservesInputErrMessage, setReservesInputErrMessage] = useState("");

  const web3Account = useSelector(web3AccountSelector);
  const lpManager = useSelector(lpManagerSelector);
  const tokens = useSelector(tokenBalancesSelector);
  const marginAccountData = useSelector(marginAccountDataSelector);
  const web3 = useSelector(web3Selector);

  const dispatch = useDispatch();

  const onIncreaseDecreaseInputChange = e => {
    let inputValue = document.getElementById("reserve-amount").value;

    // is empty
    if (inputValue === "") {
      setReservesInputIncreaseClass("");
      setReservesInputDecreaseClass("");
      setReservesInputErrMessage("");

      return;
    }

    if (inputValue[0] === "0" && inputValue[1] !== ".") {
      setReservesInputIncreaseClass("has-danger");
      setReservesInputDecreaseClass("has-danger");
      setReservesInputErrMessage("Please enter a positive number");
      return;
    }

    // is not positive number
    if (!isPositiveNumber(inputValue)) {
      setReservesInputIncreaseClass("has-danger");
      setReservesInputDecreaseClass("has-danger");
      setReservesInputErrMessage("Please enter a positive number");

      return;
    }

    const token = tokens.find(t => t.symbol === "USD");
    const accountBalance = token.normalizedAccountBalance;
    const excessLiquidity = marginAccountData.normalizedExcessLiquidity;

    // calculate excess reserved (that can be withdrawn from LP position)
    const position = props.selectedPositions[0];
    const market = props.market;
    const sqrtPriceLower = toSqrtPrice(1.0001 ** position.lower);
    const sqrtPriceUpper = toSqrtPrice(1.0001 ** position.upper);
    const sqrtPriceCurrent = toSqrtPrice(market.longPriceInVol);
    const liquidity = (position.balance * 1e18).toString();
    const required = getRequiredBase(sqrtPriceLower, sqrtPriceUpper, sqrtPriceCurrent, liquidity, market, 4025, 0.0487);
    const cashReserves = position.balance * position.liqPrice[0];
    const excessReserves = cashReserves - (required - market.penaltyBase);

    let amountSplit = inputValue.split(".");
    const newAmountSplit = amountSplit.length === 1 || amountSplit[1] === "" ? amountSplit[0] : amountSplit[0] + "." + amountSplit[1];

    // check for increase
    let canIncrease = true;
    if (toBn(excessLiquidity.toString(), 18).lt(toBn(accountBalance.toString(), 18))) {
      // there's less excess liquidity than account balance
      if (toBn(newAmountSplit, 18).gt(toBn(excessLiquidity.toString(), 18))) {
        canIncrease = false;
      }
    } else {
      // there's more excess liquidity than account balance
      if (toBn(newAmountSplit, 18).gt(toBn(accountBalance.toString(), 18))) {
        canIncrease = false;
      }
    }

    // check for decrease
    let canDecrease = true;
    if (newAmountSplit > excessReserves) {
      canDecrease = false;
    }

    setReservesInputIncreaseClass(canIncrease ? "has-success" : "has-danger");
    setReservesInputDecreaseClass(canDecrease ? "has-success" : "has-danger");
  };

  const onIncreaseReservesButtonClick = event => {
    event.stopPropagation();
    let amount = reservesAmount;
    if (amount[amount.length - 1] === ".") {
      amount = amount.slice(0, amount.length - 1);
    }
    increaseReserves(
      web3,
      web3Account,
      lpManager,
      props.selectedPositions[0].marketId,
      props.selectedPositions[0].positionId,
      toBn(toPreciseString(amount, 18), 18).toString(),
      props.closeModal,
      dispatch
    );
  };

  const onDecreaseReservesButtonClick = event => {
    event.stopPropagation();
    let amount = reservesAmount;
    if (amount[amount.length - 1] === ".") {
      amount = amount.slice(0, amount.length - 1);
    }
    decreaseReserves(
      web3,
      web3Account,
      lpManager,
      props.selectedPositions[0].marketId,
      props.selectedPositions[0].positionId,
      toBn(toPreciseString(amount, 18), 18).toString(),
      props.closeModal,
      dispatch
    );
  };

  const clearInput = () => {
    document.getElementById("reserve-amount").value = "";
    setReservesAmount("");
    setReservesInputIncreaseClass("");
    setReservesInputDecreaseClass("");
    setReservesInputErrMessage("");
    document.getElementById("reserve-amount").focus();
  };

  let cashReserves = 0;
  for (let i = 0; i < props.selectedPositions.length; i++) {
    const currentPosition = props.selectedPositions[i];
    cashReserves += currentPosition.balance * currentPosition.liqPrice[0];
  }
  const printedCashReserves = printPriceFromNumber(cashReserves);
  return (
    <div className="increaseDecreaseLpPosition">
      <div className="reservesLabelInput">
        <span id="usdReserves" className={!props.disabled ? "reservesLabel" : "reservesLabelDisabled"}>
          Cash reserves
        </span>
        <MyTooltip key="usdReserves" target="usdReserves" optionalOffset={15}>
          Cash reserves are used for buying options in the defined range. <br /> <br />
          Increase cash reserves to increase LP Health.
        </MyTooltip>
        <Form>
          <FormGroup>
            <Input
              disabled={props.disabled}
              id={"reserve-amount"}
              type="text"
              placeholder="Increase/decrease cash reserves..."
              className={`inputText${reservesInputIncreaseClass === "has-danger" && reservesInputDecreaseClass === "has-danger" ? " inputDanger" : ""}${
                reservesInputIncreaseClass === "has-success" || reservesInputDecreaseClass === "has-success" ? " inputSuccess" : ""
              }`}
              onChange={e => {
                const value = e.target.value.trim();
                if (isNaN(value)) {
                  return;
                }
                setReservesAmount(value);
                onIncreaseDecreaseInputChange(e);
              }}
              value={reservesAmount}
              autoComplete="off"
            />
            {reservesInputIncreaseClass === "has-danger" && reservesInputDecreaseClass === "has-danger" ? (
              <img className="clearInputError" onClick={clearInput} />
            ) : null}
            {reservesInputIncreaseClass === "has-success" || reservesInputDecreaseClass === "has-success" ? <img className="validInput" /> : null}
          </FormGroup>
        </Form>
      </div>
      <div className="increaseDecreaseButtonsCashReservesContainer">
        <span className="reservesLabel" style={{ textAlign: "left", width: "unset" }}>
          Current cash reserves: {printedCashReserves}
        </span>
        <div className="increaseDecreaseButtons">
          <button id="increaseReservesButton" disabled={reservesInputIncreaseClass !== "has-success" || props.disabled} onClick={onIncreaseReservesButtonClick}>
            INCREASE
          </button>
          <button id="decreaseReservesButton" disabled={reservesInputDecreaseClass !== "has-success" || props.disabled} onClick={onDecreaseReservesButtonClick}>
            DECREASE
          </button>
        </div>
      </div>
    </div>
  );
};

Reserves.propTypes = {
  closeModal: PropTypes.func,
  selectedPositions: PropTypes.array,
  market: PropTypes.object,
  disabled: PropTypes.bool
};

export default Reserves;
