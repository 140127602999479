import * as types from "../actionTypes.js";

// CONTRACTS ACTIONS
export function allContractsLoaded(contracts) {
  return { type: types.ALL_CONTRACTS_LOADED, contracts };
}

export function marketsLoaded(immutableAttributes, mutableAttributes) {
  return { type: types.MARKETS_LOADED, immutableAttributes, mutableAttributes };
}

export function marketCreated(newImmutableAttributes, newMutableAttributes) {
  return { type: types.MARKET_CREATED, newImmutableAttributes, newMutableAttributes };
}

export function marketUpdated(mutableAttributes) {
  return { type: types.MARKET_UPDATED, mutableAttributes };
}

export function marketPricesUpdated(updatedMarkets) {
  return { type: types.MARKET_PRICES_UPDATED, updatedMarkets };
}

export function expirationTimesLoaded(expirationTimes) {
  return { type: types.EXPIRATION_TIMES_LOADED, expirationTimes };
}

export function expirationTimeSelected(expirationTime) {
  return { type: types.EXPIRATION_TIME_SELECTED, expirationTime };
}

export function tradesLoaded(trades) {
  return { type: types.TRADES_LOADED, trades };
}

export function lpTradesLoaded(trades) {
  return { type: types.LP_TRADES_LOADED, trades };
}

export function liquidationTradesLoaded(trades) {
  return { type: types.LIQUIDATION_TRADES_LOADED, trades };
}

export function tradeAdded(newTrade) {
  return { type: types.TRADE_ADDED, newTrade };
}

export function lpTradeAdded(newTrade) {
  return { type: types.LP_TRADE_ADDED, newTrade };
}

export function marketsSettled(settlementsData) {
  return { type: types.MARKETS_SETTLED, settlementsData };
}

export function marketSettled(settlementData) {
  return { type: types.MARKET_SETTLED, settlementData };
}

// ERROR ACTIONS
export function addError(error) {
  return { type: types.ERROR_ADDED, error };
}

export function removeError(errorId) {
  return { type: types.ERROR_REMOVED, errorId };
}

export function removeErrors() {
  return { type: types.ERRORS_REMOVED };
}

// NOTIFICATION ACTIONS
export function addNotification(notification) {
  return { type: types.NOTIFICATION_ADDED, notification };
}

export function removeNotification(notificationId) {
  return { type: types.NOTIFICATION_REMOVED, notificationId };
}

export function removeNotifications() {
  return { type: types.NOTIFICATIONS_REMOVED };
}

//-----------------------------------------------------

export function addPendingTransactionNotification(notification, account) {
  return { type: types.ADD_PENDING_TRANSACTION_NOTIFICATION, notification, account };
}

export function confirmTransactionNotification(notification, account) {
  return { type: types.CONFIRM_TRANSACTION_NOTIFICATION, notification, account };
}

export function confirmLiquidatedAccountNotification(notification, account) {
  return { type: types.CONFIRM_LIQUIDATED_ACCOUNT_NOTIFICATION, notification, account };
}

export function addFailedTransactionNotification(notification, account) {
  return { type: types.ADD_FAILED_TRANSACTION_NOTIFICATION, notification, account };
}

export function removeConfirmedTransactionNotification(account) {
  return { type: types.REMOVE_CONFIRMED_TRANSACTION_NOTIFICATION, account };
}

export function clearAllNotifications(account) {
  return { type: types.CLEAR_ALL_NOTIFICATIONS, account };
}

export function removeSettlementPendingNotification(account, hash) {
  return { type: types.REMOVE_SETTLEMENT_PENDING_NOTIFICATION, account, hash };
}

// WEB3 ACTIONS
export function loadingWeb3() {
  return { type: types.LOADING_WEB3 };
}

export function web3Loaded(connection, web3read = null, web3Events = null, web3Subs = null) {
  return { type: types.WEB3_LOADED, connection, web3read, web3Events, web3Subs };
}

export function web3Unloaded() {
  return { type: types.WEB3_UNLOADED };
}

export function web3AccountLoaded(account) {
  return { type: types.WEB3_ACCOUNT_LOADED, account };
}

export function web3AccountUnloaded() {
  return { type: types.WEB3_ACCOUNT_UNLOADED };
}

export function currentTimestampLoaded(timestamp) {
  return { type: types.CURRENT_TIMESTAMP_LOADED, timestamp };
}

export function tokenPairsLoaded(tokenPairs) {
  return { type: types.TOKEN_PAIRS_LOADED, tokenPairs };
}

export function tokensLoaded(tokens) {
  return { type: types.TOKENS_LOADED, tokens };
}

export function tokenAccountBalanceLoaded(assetAddress, tokenBalance) {
  return { type: types.TOKEN_ACCOUNT_BALANCE_LOADED, assetAddress, tokenBalance };
}

export function tokenWalletBalanceLoaded(assetAddress, tokenBalance, isWeth = false) {
  return { type: types.TOKEN_WALLET_BALANCE_LOADED, assetAddress, tokenBalance, isWeth };
}

export function loadingTokenAccountBalance(assetAddress) {
  return { type: types.LOADING_TOKEN_ACCOUNT_BALANCE, assetAddress };
}

export function loadingTokenWalletBalance(assetAddress) {
  return { type: types.LOADING_TOKEN_WALLET_BALANCE, assetAddress };
}

export function positionTokensLoaded(positionTokens) {
  return { type: types.POSITION_TOKENS_LOADED, positionTokens };
}

export function lpPositionTokensLoaded() {
  return { type: types.LP_POSITION_TOKENS_LOADED };
}

export function lpPositionTokensData(lpPositionTokens) {
  return { type: types.LP_POSITION_TOKENS_DATA, lpPositionTokens };
}

export function lpTokenAddedOrUpdated(lpToken) {
  return { type: types.LP_TOKEN_ADDED_OR_UPDATED, lpToken };
}

export function loadingPositionTokenAccountBalance(tokenId) {
  return { type: types.LOADING_POSITION_TOKEN_ACCOUNT_BALANCE, tokenId };
}

export function positionTokenAccountBalanceLoaded(tokenId, balance) {
  return { type: types.POSITION_TOKEN_ACCOUNT_BALANCE_LOADED, tokenId, balance };
}

export function positionTokenAccountBalancesLoaded(addressBalances) {
  return { type: types.POSITION_TOKEN_ACCOUNT_BALANCES_LOADED, addressBalances };
}

export function loadingLPPositionTokenAccountBalance(marketId, tokenId) {
  return { type: types.LOADING_LP_POSITION_TOKEN_ACCOUNT_BALANCE, marketId, tokenId };
}

export function lpPositionTokenAccountBalanceLoaded(
  marketId,
  tokenId,
  lpBalance,
  longBalance,
  baseBalance,
  feeBalance,
  lower,
  upper,
  sizeInLongs,
  shortBalance,
  health
) {
  return {
    type: types.LP_POSITION_TOKEN_ACCOUNT_BALANCE_LOADED,
    marketId,
    tokenId,
    lpBalance,
    longBalance,
    baseBalance,
    feeBalance,
    lower,
    upper,
    sizeInLongs,
    shortBalance,
    health
  };
}

export function lpPositionTokenAccountBalancesLoaded(lpPositionBalances) {
  return { type: types.LP_POSITION_TOKEN_ACCOUNT_BALANCES_LOADED, lpPositionBalances };
}

export function tokenPairAdded(newTokenPair) {
  return { type: types.TOKEN_PAIR_ADDED, newTokenPair };
}

export function tokenAdded(newToken) {
  return { type: types.TOKEN_ADDED, newToken };
}

export function positionTokenAdded(newPositionToken) {
  return { type: types.POSITION_TOKEN_ADDED, newPositionToken };
}

export function tokenPairSelected(tokenPair) {
  return { type: types.TOKEN_PAIR_SELECTED, tokenPair };
}

export function underlyingTokenPriceLoaded(tokenPair, underlyingPrice, avgUnderlyingPrice) {
  return { type: types.UNDERLYING_TOKEN_PRICE_LOADED, tokenPair, underlyingPrice, avgUnderlyingPrice };
}

export function depositsAndWithdrawalsLoaded(depositsAndWithdrawals) {
  return { type: types.DEPOSITS_AND_WITHDRAWALS_LOADED, depositsAndWithdrawals };
}

export function depositOrWithdrawalAdded(depositOrWithdrawal) {
  return { type: types.DEPOSIT_OR_WITHDRAWAL_ADDED, depositOrWithdrawal };
}

export function insuranceFundDepositsAndWithdrawalsLoaded(depositsAndWithdrawals) {
  return { type: types.INSURANCE_FUND_DEPOSITS_AND_WITHDRAWALS_LOADED, depositsAndWithdrawals };
}

export function insuranceFundDepositOrWithdrawalAdded(depositOrWithdrawal) {
  return { type: types.INSURANCE_FUND_DEPOSIT_OR_WITHDRAWAL_ADDED, depositOrWithdrawal };
}

export function insuranceFundLossesCoveredLoaded(lossesCovered) {
  return { type: types.INSURANCE_FUND_LOSSES_COVERED_LOADED, lossesCovered };
}

export function insuranceFundLossCoveredAdded(lossCovered) {
  return { type: types.INSURANCE_FUND_LOSS_COVERED_ADDED, lossCovered };
}

// MARGIN ACTIONS
export function marginPoolDataLoaded(marginData) {
  return { type: types.MARGIN_POOL_DATA_LOADED, marginData };
}

export function marginAccountDataLoaded(marginAccountData) {
  return { type: types.MARGIN_ACCOUNT_DATA_LOADED, marginAccountData };
}

export function marginEventsLoaded(lendEvents, redeemEvents, borrowEvents, repaidEvents) {
  return { type: types.MARGIN_EVENTS_LOADED, lendEvents, redeemEvents, borrowEvents, repaidEvents };
}

export function lendEventAdded(lendEvent) {
  return { type: types.LEND_EVENT_ADDED, lendEvent };
}

export function redeemEventAdded(redeemEvent) {
  return { type: types.REDEEM_EVENT_ADDED, redeemEvent };
}

export function borrowEventAdded(borrowEvent) {
  return { type: types.BORROW_EVENT_ADDED, borrowEvent };
}

export function repaidEventAdded(repaidEvent) {
  return { type: types.REPAID_EVENT_ADDED, repaidEvent };
}

//INSURANCE FUND ACTIONS
export function insuranceFundLoaded(insuranceFund) {
  return { type: types.INSURANCE_FUND_LOADED, insuranceFund };
}

export function insurerBalanceLoaded(insurerBalance) {
  return { type: types.INSURER_BALANCE_LOADED, insurerBalance };
}
