import React, { useRef, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { avgUnderlyingTokenPriceSelector, selectedTokenPairSelector } from "../../store/selectors/tokensSelectors";
import { web3AccountLoadedSelector } from "../../store/selectors/web3Selectors";
import { displayLoadingIconSmall } from "../../utils/utils.js";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { expirationTimesSelector, selectedExpirationTimeSelector } from "../../store/selectors/dateUtilsSelectors";
import * as actions from "../../store/actions/actions.js";
import { useMatch, useNavigate } from "react-router-dom";
import MyTooltip from "./MyTooltip.jsx";

const UnderlyingTokenPriceTicker = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [top, setTop] = useState(0);

  const web3AccountLoaded = useSelector(web3AccountLoadedSelector);
  const selectedTokenPair = useSelector(selectedTokenPairSelector);
  const underlyingTokenPrice = useSelector(avgUnderlyingTokenPriceSelector); // using avg price
  const expirationTimes = useSelector(expirationTimesSelector);
  const selectedExpirationTime = useSelector(selectedExpirationTimeSelector);

  const priceTickerRef = useRef();

  const match = useMatch("/:param1/:param2/:param3");

  useEffect(() => {
    if (priceTickerRef.current) {
      const domRect = priceTickerRef.current.getBoundingClientRect();

      if (!top) {
        const top = domRect.top + domRect.height - 5;
        setTop(top);
      }
    }
  });

  useEffect(() => {
    if (expirationTimes.length > 0) {
      if (match && (match.params.param1 === "trade" || match.params.param1 === "pool") && match.params.param3) {
        let urlTime = findExpirationTime(match.params.param3);
        if (urlTime != null) {
          // if url time different than selected
          if (urlTime.urlFormat !== selectedExpirationTime.urlFormat) {
            dispatch(actions.expirationTimeSelected(urlTime));
          }
        } else {
          // time not found in expirationTimes, set time to all
          navigate("daily");
        }
      }
    }
  }, [expirationTimes, selectedExpirationTime]);

  const findExpirationTime = useCallback(
    time => {
      if (time === "all") {
        return { timestamp: "all", sidebarFormat: "All Expirations", urlFormat: "all" };
      }

      if (time === "daily") {
        return { timestamp: "daily", sidebarFormat: "Daily Expirations", urlFormat: "daily" };
      }

      return expirationTimes.find(expirationTime => {
        return expirationTime.urlFormat === time;
      });
    },
    [expirationTimes]
  );

  const hasHover = !window.location.pathname.includes("portfolio") && !window.location.pathname.includes("margin");

  if (!selectedTokenPair || !web3AccountLoaded) {
    return <div />;
  }

  return (
    <>
      <div>
        <div
          id="underlying-token-price-ticker"
          ref={priceTickerRef}
          className={`underlyingTokenPriceTicker${hasHover ? " underlyingTokenPriceTickerHasHover" : ""}`}
        >
          <img id="ethLogo" />
          <span className="priceTickerText">{selectedTokenPair.underlyingTokenSymbol}</span>
          {underlyingTokenPrice ? (
            <>
              <span className={underlyingTokenPrice.uptrend ? "uptrendColor" : "downtrendColor"}>
                ${underlyingTokenPrice.printable}&nbsp;
                <FontAwesomeIcon icon={underlyingTokenPrice.uptrend ? faArrowUp : faArrowDown} />
              </span>
            </>
          ) : (
            <span className="loaderColor">{displayLoadingIconSmall()}</span>
          )}
        </div>
        <MyTooltip key="underlying-token-price-ticker" target="underlying-token-price-ticker" optionalOffset={15}>
          ETH price ticker is time-weighted average price (TWAP) of ETH from Uniswap. <br /> <br /> Time frame used for TWAP calculation is last 2 minutes,
          excluding the last block. <br /> <br />
          Learn more how price ticker works: <br />
          <a href="https://gamma-options.gitbook.io/docs/" target="_blank" rel="noreferrer">
            Docs
          </a>
        </MyTooltip>
      </div>
    </>
  );
};

export default UnderlyingTokenPriceTicker;
