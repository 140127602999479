import { decorateStrikePrices } from "../../utils/decorators";
import { handleNewError } from "./errorInteractions";
import { uniq } from "lodash";

export const loadStrikePrices = async (priceUtils, baseTokenAddress, underlyingTokenAddress, expirationTime, dispatch) => {
  try {
    console.log("RPC method: getAvailableStrikePrices");
    let strikePrices = await priceUtils.methods.getAvailableStrikePrices(baseTokenAddress, underlyingTokenAddress, expirationTime, true).call();
    // filter out zeros
    strikePrices = strikePrices.filter(price => Number(price));

    // sort
    strikePrices.sort((a, b) => {
      return a - b;
    });

    // remove duplicates
    strikePrices = uniq(strikePrices);
    return strikePrices.map(strikePrice => {
      return decorateStrikePrices(strikePrice, 18);
    });
  } catch (e) {
    handleNewError(e, "Error while fetching available strike prices. Please reload the application!", 1, dispatch);
  }
};
