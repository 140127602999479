/* eslint-disable import/no-named-as-default-member */
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { settlementDataSelector } from "../../store/selectors/balancesSelectors";
import { openedLPPositionsSelector } from "../../store/selectors/positionsManagerSelectors";
import { selectedTokenPairSelector } from "../../store/selectors/tokensSelectors";
import { currentTimestampSelector } from "../../store/selectors/web3Selectors";
import { formatExpirationTime, getUnderlyingPriceAtExpiredStr } from "../../utils/utils.js";
import TradeModalPoolTab from "./TradeModalPoolTab.jsx";
import MyModal from "./utilities/MyModal.jsx";

let lastSelectedInputTimestamp = null;
let lastClickedModalOverlay = null;

const PoolModal = ({
  isOpen,
  toggle,
  market,
  getMaxLongAmount,
  size,
  longShortLiq,
  isCall,
  marketNameProp,
  needsToBeClosed,
  setNeedsToBeClosed,
  selectedPosition
}) => {
  const [tabs, setTabs] = useState("");
  const [marketName, setMarketName] = useState("");
  const [rangeTokenDropmenuOpened, setRangeTokenDropmenuOpened] = useState(false);
  const [rangeFutureTokenDropmenuOpened, setFutureRangeTokenDropmenuOpened] = useState(false);

  const [cogChartOptionsOpenAdd, setCogChartOptionsOpenAdd] = useState(false);

  const selectedTokenPair = useSelector(selectedTokenPairSelector);
  const openedLPPositions = useSelector(openedLPPositionsSelector);
  const currentTimestamp = useSelector(currentTimestampSelector);
  const settlements = useSelector(settlementDataSelector);

  useEffect(() => {
    const modalDialog = document.querySelector(".modal-dialog");

    if (modalDialog) {
      $(".modal-black").draggable({
        handle: ".modal-header"
      });
    }

    let chunk = "";
    if (longShortLiq === "lp" && needsToBeClosed) {
      setTabs("remove");
      chunk = "remove";
    } else {
      chunk = "add";
      setTabs("add");
    }

    console.log("Navigate to: " + window.location.pathname + "/" + chunk); // eslint-disable-line no-console
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + "/" + chunk, title: "Pool Modal" });
  }, []);

  useEffect(() => {
    if (marketNameProp && marketNameProp !== marketName) {
      setMarketName(marketNameProp);
    }
  }, [marketNameProp, marketName]);

  useEffect(() => {
    const newMarketName = getMarketName();
    setMarketName(marketNameProp ? marketNameProp : newMarketName);

    if (!isOpen) {
      setTabs("add");
    }
  });

  const changeActiveTab = (e, tabName) => {
    e.preventDefault();
    setTabs(tabName);
    cogChartOptionsOpenAdd && setCogChartOptionsOpenAdd(false);

    const chunk = tabName;
    console.log("Navigate to: " + window.location.pathname + "/" + chunk); // eslint-disable-line no-console
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + "/" + chunk, title: "Pool Modal" });
  };

  const getMarketName = () => {
    if (market) {
      const marketIsExpired = market.isSettled || Number(market.expirationTime) < currentTimestamp;

      let expiredText = " (EXPIRED)";
      if (market.isSettled) {
        const underlyingPriceAtExpired = getUnderlyingPriceAtExpiredStr(market, settlements);
        expiredText = " (EXPIRED $" + underlyingPriceAtExpired + ")";
      }
      if (market.isFuture) {
        return (
          (selectedTokenPair ? selectedTokenPair.underlyingTokenSymbol : "Loading...") +
          "-" +
          (market.readableExpirationTime ? formatExpirationTime(market.readableExpirationTime) : "Loading...") +
          "-FUTURE" +
          (marketIsExpired ? expiredText : "")
        );
      }
      return (
        (selectedTokenPair ? selectedTokenPair.underlyingTokenSymbol : "Loading...") +
        "-" +
        (market.readableExpirationTime ? formatExpirationTime(market.readableExpirationTime) : "Loading...") +
        "-" +
        market.printableStrikePrice +
        "-" +
        (market.isCall ? "C" : "P") +
        (marketIsExpired ? expiredText : "")
      );
    }
    return null;
  };

  const removeTabDisable = () => {
    let isDisabled = false;
    if (openedLPPositions && openedLPPositions.length === 0) {
      isDisabled = true;
    }

    const tempMarket = openedLPPositions && openedLPPositions.find(lp => lp.marketId === market.marketId);

    if (!tempMarket) {
      isDisabled = true;
    }

    return isDisabled;
  };

  const isFuture = market.isFuture;
  let rangeTokenProps = null;
  if (tabs === "remove") {
    rangeTokenProps = {
      rangeTokenDropmenuOpened: rangeTokenDropmenuOpened,
      setRangeTokenDropmenuOpened: setRangeTokenDropmenuOpened
    };
  } else if (isFuture) {
    rangeTokenProps = {
      rangeFutureTokenDropmenuOpened: rangeFutureTokenDropmenuOpened,
      setFutureRangeTokenDropmenuOpened: setFutureRangeTokenDropmenuOpened
    };
  }

  const marketIsExpired = market.isSettled || Number(market.expirationTime) < currentTimestamp;

  return (
    <MyModal
      isOpen={isOpen}
      toggle={e => {
        lastClickedModalOverlay = Date.now();
        const diff = lastClickedModalOverlay - lastSelectedInputTimestamp;
        if (diff < 500) {
          lastClickedModalOverlay = null;
          lastSelectedInputTimestamp = null;
          return;
        }
        toggle();
      }}
    >
      <div
        className="modalFrame"
        onClick={e => {
          e.stopPropagation();
          rangeTokenDropmenuOpened && setRangeTokenDropmenuOpened(false);
          rangeFutureTokenDropmenuOpened && setFutureRangeTokenDropmenuOpened(false);
        }}
        onMouseDown={e => {
          lastSelectedInputTimestamp = Date.now();
        }}
      >
        <div className="poolModalHeader">
          <span className="tokenSpan">{marketName}</span>

          <div className="navButtons">
            <button id="addTabButton" disabled={marketIsExpired} onClick={e => changeActiveTab(e, "add")} className={tabs === "add" ? "activeNavLink" : ""}>
              <a href="#add">Add</a>
            </button>
            <button
              id="removeTab"
              onClick={e => changeActiveTab(e, "remove")}
              disabled={removeTabDisable()}
              className={tabs === "remove" ? "activeNavLink" : ""}
            >
              <a href="#remove" className={removeTabDisable() ? "disabledNavLink" : tabs === "remove" ? "activeNavLink" : ""}>
                Remove
              </a>
            </button>
          </div>
          <button aria-hidden data-dismiss="modal" type="button" className="closeModal" onClick={() => toggle(true)}>
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
        {tabs === "add" || tabs === "remove" ? (
          <TradeModalPoolTab
            market={market}
            getMaxLongAmount={getMaxLongAmount}
            tabs={tabs}
            needsToBeClosed={needsToBeClosed}
            setNeedsToBeClosed={setNeedsToBeClosed}
            selectedPosition={selectedPosition}
            size={size}
            toggle={toggle}
            {...rangeTokenProps}
            cogChartOptionsOpenAdd={cogChartOptionsOpenAdd}
            setCogChartOptionsOpenAdd={setCogChartOptionsOpenAdd}
            isOpen={isOpen}
          />
        ) : null}
      </div>
    </MyModal>
  );
};

PoolModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  market: PropTypes.object.isRequired,
  selectedTokenPair: PropTypes.object,
  getMaxLongAmount: PropTypes.func.isRequired,
  openedLPPositions: PropTypes.array,
  needsToBeClosed: PropTypes.bool,
  setNeedsToBeClosed: PropTypes.func,
  selectedPosition: PropTypes.array,
  size: PropTypes.object,
  marketName: PropTypes.object,
  marketNameProp: PropTypes.object,
  isCall: PropTypes.bool,
  longShortLiq: PropTypes.object
};

export default PoolModal;
