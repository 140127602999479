// TODO: v1 this function mimics the functionality of the InterestRate smart contract. If InterestRate smart contract is changed,
// this function will no longer be valid
export const getInterestRate = (amountInPool, borrowedAmount) => {
  const baseRate = 0.06;
  const multiplier = 0.2;
  const jump1Multiplier = 0;
  const jump2Multiplier = 3.8;
  const jump3Multiplier = 35;
  const kink1 = 0.3;
  const kink2 = 0.8;
  const kink3 = 0.9;
  const util = borrowedAmount == 0 ? 0 : borrowedAmount / (amountInPool + borrowedAmount);

  if (util < kink1) {
    return util * multiplier + baseRate;
  }
  let normalRate, excessUtil;
  if (util < kink2) {
    normalRate = kink1 * multiplier + baseRate;
    excessUtil = util - kink1;
    return excessUtil * jump1Multiplier + normalRate;
  }
  if (util <= kink3) {
    normalRate = kink1 * multiplier + baseRate;
    excessUtil = kink2 - kink1;
    normalRate += excessUtil * jump1Multiplier;
    excessUtil = util - kink2;
    return excessUtil * jump2Multiplier + normalRate;
  }
  normalRate = kink1 * multiplier + baseRate;
  excessUtil = kink2 - kink1;
  normalRate += excessUtil * jump1Multiplier;
  excessUtil = kink3 - kink2;
  normalRate += excessUtil * jump2Multiplier;
  excessUtil = util - kink3;
  return excessUtil * jump3Multiplier + normalRate;
};

// export const getInterestRateForPosition = (assetAddress, user, size, timestamp, events) => {
// }
