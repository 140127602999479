import React from "react";
import MyTooltip from "./MyTooltip.jsx";

// eslint-disable-next-line react/prop-types
const GreeksTable = ({ idPrefix, delta, gamma, theta, vega, shouldBeDisabled }) => {
  return (
    <div className={`greeksTable${shouldBeDisabled ? " disabledTextColor" : ""}`}>
      <table>
        <tr>
          <th>
            <span id={idPrefix + "delta"} className="greeksColumnAlign deltaWidth">
              &Delta; DELTA
            </span>
          </th>
          <th>
            <span id={idPrefix + "gamma"} className="greeksColumnAlign gammaWidth">
              &gamma; GAMMA
            </span>
          </th>
          <th>
            <span id={idPrefix + "theta"} className="greeksColumnAlign thetaWidth">
              &Theta; THETA
            </span>
          </th>
          <th>
            <span id={idPrefix + "vega"} className="greeksColumnAlign vegaWidth">
              V VEGA
            </span>
          </th>
        </tr>
        <tr>
          <td>
            <span className="greeksColumnAlign deltaWidth">{delta}</span>
          </td>
          <td>
            <span className="greeksColumnAlign gammaWidth">{gamma}</span>
          </td>
          <td>
            <span className="greeksColumnAlign thetaWidth">{theta}</span>
          </td>
          <td>
            <span className="greeksColumnAlign vegaWidth">{vega}</span>
          </td>
        </tr>
      </table>
      <MyTooltip key={idPrefix + "delta"} target={idPrefix + "delta"} optionalOffset={15}>
        Delta (&Delta;) of the position represents the rate of change of the LP position's price relative to underlying price change.
      </MyTooltip>
      <MyTooltip key={idPrefix + "gamma"} target={idPrefix + "gamma"} optionalOffset={15}>
        Gamma (&gamma;) of the position represents the rate of change of the Delta (&Delta;) relative to underlying price change.
      </MyTooltip>
      <MyTooltip key={idPrefix + "theta"} target={idPrefix + "theta"} optionalOffset={15}>
        Theta (&Theta;) of the position represents the rate of change of the LP position's price relative to time change, also known as time decay.
      </MyTooltip>
      <MyTooltip key={idPrefix + "vega"} target={idPrefix + "vega"} optionalOffset={15}>
        Vega (V) of the position represents the rate of change of the LP position's price relative to implied volatility (IV) change.
      </MyTooltip>
    </div>
  );
};

export default GreeksTable;
