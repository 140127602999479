import { uniqBy } from "lodash";
import ERC20 from "../../abis/ERC20.json";

import {
  tokenPairsLoaded,
  positionTokensLoaded,
  lpPositionTokensLoaded,
  loadingPositionTokenAccountBalance,
  positionTokenAccountBalanceLoaded,
  positionTokenAccountBalancesLoaded,
  loadingLPPositionTokenAccountBalance,
  lpPositionTokenAccountBalanceLoaded,
  tokensLoaded,
  tokenAccountBalanceLoaded,
  tokenWalletBalanceLoaded,
  loadingTokenAccountBalance,
  loadingTokenWalletBalance,
  tokenPairAdded,
  tokenAdded,
  positionTokenAdded,
  tokenPairSelected,
  underlyingTokenPriceLoaded,
  lpTokenAddedOrUpdated,
  lpPositionTokensData,
  lpPositionTokenAccountBalancesLoaded
} from "../actions/actions.js";
import { handleNewError } from "./errorInteractions";
import { _ } from "core-js";
import { Promise } from "bluebird";
import { formatUnits } from "@ethersproject/units";
import { Contract } from "@ethersproject/contracts";
import { getTokenNames } from "./marketsInteractions";
import { fromBn } from "evm-bn";
import { store } from "../configureStore";
import { getEventsStreamCached } from "./interactionsManager.js";

export let wethAddress = "0x0";
const mainnetWETHAddress = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";

export const setWETHAddress = (wethAddr, dispatch) => {
  try {
    wethAddress = wethAddr;
  } catch (e) {
    handleNewError({}, "Unable to set WETH address", 1, dispatch, true);
  }
};

const loadTokenPair = async (tokenRegistry, usdToken, wethToken, underlyingTokenPrice) => {
  // eslint-disable-next-line no-console
  console.log("RPC method: tokenRegistry.getTokenAttributes");
  const result = await tokenRegistry.methods.getTokenAttributes(usdToken._address, wethToken._address).call();
  const tokenPairs = [
    {
      baseTokenAddress: usdToken._address,
      baseTokenName: result[0],
      baseTokenSymbol: result[1],
      baseTokenDecimals: result[2],
      underlyingTokenAddress: wethToken._address,
      underlyingTokenName: result[3],
      underlyingTokenSymbol: result[4],
      underlyingTokenDecimals: result[5],
      riskFreeRate: parseFloat(formatUnits(result[6], 18))
    }
  ];
  if (underlyingTokenPrice !== null) {
    tokenPairs[0].underlyingTokenPrice = underlyingTokenPrice;
  }

  return tokenPairs;
};

export const loadAllTokenPairs = async (
  provider,
  web3,
  loadHelper,
  tokenRegistry,
  userBalances,
  wethToken,
  usdToken,
  userAccount,
  dispatch,
  underlyingTokenPrice = null
) => {
  try {
    const tokenPairs = await loadTokenPair(tokenRegistry, usdToken, wethToken, underlyingTokenPrice);
    dispatch(tokenPairsLoaded(tokenPairs));
    subscribeToTokenRegistryEvents(provider, web3, tokenRegistry, userBalances, userAccount, dispatch);
    loadTokenBalances(web3, loadHelper, tokenPairs, userBalances, userAccount, dispatch);

    return tokenPairs;
  } catch (e) {
    handleNewError({}, "Unable to load registered token pairs!", 1, dispatch, true);
  }
};

export const loadAllTokenPairs2 = async (
  provider,
  web3,
  loadHelper,
  tokenRegistry,
  userBalances,
  wethToken,
  usdToken,
  userAccount,
  dispatch,
  underlyingTokenPrice,
  avgUnderPrice
) => {
  try {
    const tokenPairs = await loadTokenPair(tokenRegistry, usdToken, wethToken, underlyingTokenPrice);

    let tokens = [];
    tokenPairs.forEach(pair => {
      tokens.push({
        address: pair.baseTokenAddress,
        name: pair.baseTokenName,
        symbol: pair.baseTokenSymbol,
        decimals: pair.baseTokenDecimals,
        accountBalance: null,
        walletBalance: null
      });
      tokens.push({
        address: pair.underlyingTokenAddress,
        name: pair.underlyingTokenName,
        symbol: pair.underlyingTokenSymbol,
        decimals: pair.underlyingTokenDecimals,
        accountBalance: null,
        walletBalance: null
      });
    });
    tokens = uniqBy(tokens, "address");
    const assetAddresses = tokens.map(asset => asset.address);

    loadTokenBalances(web3, loadHelper, tokenPairs, userBalances, userAccount, dispatch);

    const userAccountAndWalletAssetBalances = await loadHelper.methods
      .getUserAccountAndWalletAssetBalances(assetAddresses, userAccount, wethAddress, userBalances._address)
      .call();
    const token = new web3.eth.Contract(ERC20.abi, wethAddress);
    const result = await loadHelper.methods.getUserAccountAndWalletAssetBalances(assetAddresses, userAccount, wethAddress, userBalances._address).call();
    const tokenBalanceETH = result[1][1];
    const tokenBalanceWETH = await token.methods.balanceOf(userAccount).call();
    let isWeth = false;
    let bigger;
    if (Number(fromBn(tokenBalanceWETH.toString())) > Number(fromBn(tokenBalanceETH.toString()))) {
      bigger = tokenBalanceWETH.toString();
      isWeth = true;
    } else {
      bigger = tokenBalanceETH.toString();
    }
    return [tokenPairs, tokens, assetAddresses, userAccountAndWalletAssetBalances, bigger, isWeth];
  } catch (e) {
    handleNewError({}, "Unable to load registered token pairs!", 1, dispatch, true);
  }
};

const subscribeToTokenRegistryEvents = async (provider, web3, tokenRegistry, userBalances, userAccount, dispatch) => {
  const abi = [
    "event TokenPairRegistered(address indexed baseTokenAddress, string baseTokenName, string baseTokenSymbol, uint8 baseTokenDecimals, address indexed underlyingTokenAddress, string underlyingTokenName, string underlyingTokenSymbol, uint8 underlyingTokenDecimals, int256 riskFreeRate)"
  ];

  const tokenRegistryContract = new Contract(tokenRegistry.options.address, abi, provider);

  tokenRegistryContract.on(
    "TokenPairRegistered",
    (
      baseTokenAddress,
      baseTokenName,
      baseTokenSymbol,
      baseTokenDecimals,
      underlyingTokenAddress,
      underlyingTokenName,
      underlyingTokenSymbol,
      underlyingTokenDecimals,
      riskFreeRate,
      event
    ) => {
      const tokenPair = {
        baseTokenAddress,
        baseTokenName,
        baseTokenSymbol,
        baseTokenDecimals,
        underlyingTokenAddress,
        underlyingTokenName,
        underlyingTokenSymbol,
        underlyingTokenDecimals,
        riskFreeRate
      };

      dispatch(tokenPairAdded(tokenPair));
      addToken(web3, baseTokenAddress, baseTokenName, baseTokenSymbol, baseTokenDecimals, userBalances, userAccount, dispatch);
      addToken(web3, underlyingTokenAddress, underlyingTokenName, underlyingTokenSymbol, underlyingTokenDecimals, userBalances, userAccount, dispatch);
    }
  );
};

const addToken = async (web3, assetAddress, tokenName, tokenSymbol, tokenDecimals, userBalances, userAccount, dispatch) => {
  dispatch(
    tokenAdded({
      address: assetAddress,
      name: tokenName,
      symbol: tokenSymbol,
      decimals: tokenDecimals,
      accountBalance: null,
      walletBalance: null
    })
  );
  loadAssetAccountBalance(assetAddress, userBalances, userAccount, dispatch);
  loadAssetWalletBalance(web3, assetAddress, userAccount, dispatch);
};

export const loadTokenBalances = async (web3, loadHelper, tokenPairs, userBalances, userAccount, dispatch) => {
  let tokens = [];
  tokenPairs.forEach(pair => {
    tokens.push({
      address: pair.baseTokenAddress,
      name: pair.baseTokenName,
      symbol: pair.baseTokenSymbol,
      decimals: pair.baseTokenDecimals,
      accountBalance: null,
      walletBalance: null
    });
    tokens.push({
      address: pair.underlyingTokenAddress,
      name: pair.underlyingTokenName,
      symbol: pair.underlyingTokenSymbol,
      decimals: pair.underlyingTokenDecimals,
      accountBalance: null,
      walletBalance: null
    });
  });
  tokens = uniqBy(tokens, "address");
  dispatch(tokensLoaded(tokens));

  const assetAddresses = tokens.map(asset => asset.address);
  loadAccountAndWalletAssetBalances(web3, loadHelper, assetAddresses, userBalances, userAccount, dispatch);
};

export const loadAccountAndWalletAssetBalances = async (web3, loadHelper, tokenAddresses, userBalances, userAccount, dispatch) => {
  try {
    for (let i = 0; i < tokenAddresses.length; i++) {
      dispatch(loadingTokenAccountBalance(tokenAddresses[i]));
      dispatch(loadingTokenWalletBalance(tokenAddresses[i]));
    }
    console.log("RPC method: loadHelper.getUserAccountAndWalletAssetBalances");
    const result = await loadHelper.methods.getUserAccountAndWalletAssetBalances(tokenAddresses, userAccount, wethAddress, userBalances._address).call();
    const token = new web3.eth.Contract(ERC20.abi, wethAddress);
    const tokenBalanceETH = result[1][1];
    const tokenBalanceWETH = await token.methods.balanceOf(userAccount).call();
    let isWeth = false;
    let bigger;
    if (Number(fromBn(tokenBalanceWETH.toString())) > Number(fromBn(tokenBalanceETH.toString()))) {
      bigger = tokenBalanceWETH.toString();
      isWeth = true;
    } else {
      bigger = tokenBalanceETH.toString();
    }
    for (let i = 0; i < tokenAddresses.length; i++) {
      if (tokenAddresses[i] === wethAddress) {
        dispatch(tokenWalletBalanceLoaded(tokenAddresses[i], bigger, isWeth));
      } else {
        dispatch(tokenWalletBalanceLoaded(tokenAddresses[i], result[1][i]));
      }
      dispatch(tokenAccountBalanceLoaded(tokenAddresses[i], result[0][i]));
    }
  } catch (e) {
    handleNewError({}, "Error while loading account and wallet asset balances. Please reload the application!", 1, dispatch);
  }
};
export const loadAssetAccountBalance = async (assetAddress, userBalances, userAccount, dispatch) => {
  try {
    dispatch(loadingTokenAccountBalance(assetAddress));
    console.log("RPC method: userBalances.balanceOf");
    const tokenBalance = await userBalances.methods.balanceOf(assetAddress, userAccount).call();
    dispatch(tokenAccountBalanceLoaded(assetAddress, tokenBalance));
  } catch (e) {
    handleNewError({}, "Error while loading account balance for asset: " + assetAddress + ". Please reload the application!", 1, dispatch);
  }
};

export const loadPositionTokenAccountBalance = async (tokenId, userBalances, userAccount, dispatch) => {
  try {
    dispatch(loadingPositionTokenAccountBalance(tokenId));
    console.log("RPC method: userBalances.balanceOfPosition");
    const tokenBalance = await userBalances.methods.balanceOfPosition(tokenId, userAccount).call();
    dispatch(positionTokenAccountBalanceLoaded(tokenId, tokenBalance));
  } catch (e) {
    handleNewError({}, "Error while loading account balance for position: " + tokenId + ". Please reload the application!", 1, dispatch);
  }
};

export const loadAssetWalletBalance = async (web3, assetAddress, userAccount, dispatch) => {
  try {
    dispatch(loadingTokenWalletBalance(assetAddress));
    let tokenBalance;
    let isWeth = false;
    if (assetAddress === wethAddress) {
      console.log("RPC method: getBalance");
      console.log("RPC method: balanceOf");
      const token = new web3.eth.Contract(ERC20.abi, wethAddress);
      const tokenBalanceETH = await web3.eth.getBalance(userAccount);
      const tokenBalanceWETH = await token.methods.balanceOf(userAccount).call();
      if (Number(fromBn(tokenBalanceWETH.toString())) > Number(fromBn(tokenBalanceETH.toString()))) {
        tokenBalance = tokenBalanceWETH.toString();
        isWeth = true;
      } else {
        tokenBalance = tokenBalanceETH.toString();
      }
    } else {
      const token = new web3.eth.Contract(ERC20.abi, assetAddress);
      console.log("RPC method: balanceOf");
      tokenBalance = await token.methods.balanceOf(userAccount).call();
    }
    dispatch(tokenWalletBalanceLoaded(assetAddress, tokenBalance, isWeth));
  } catch (e) {
    handleNewError({}, "Error while loading wallet balance for asset: " + assetAddress + ". Please reload the application!", 1, dispatch);
  }
};

export const updateUnderlyingMarketPrice = async (tokenPairs, loadHelper, dispatch) => {
  let newUnderlyingPrice, newAvgUnderlyingPrice;
  try {
    for (let i = 0; i < tokenPairs.length; i++) {
      let tokenPair = tokenPairs[i];
      const result = await loadUnderlyingMarketPrice(loadHelper, dispatch);
      newUnderlyingPrice = result.newUnderPrice;
      newAvgUnderlyingPrice = result.newAvgUnderPrice;
      if (newUnderlyingPrice != null && newAvgUnderlyingPrice != null) {
        // update state if price changed
        if (tokenPair.underlyingTokenPrice !== newUnderlyingPrice) {
          dispatch(underlyingTokenPriceLoaded(tokenPair, newUnderlyingPrice, newAvgUnderlyingPrice));
        }
      }
    }
  } catch (e) {
    handleNewError(e, "There was an error while fetching underlying prices!", 2, dispatch);
  }

  return newAvgUnderlyingPrice;
};

export const loadUnderlyingMarketPrice = async (loadHelper, dispatch) => {
  let newUnderPrice, newAvgUnderPrice;
  try {
    console.log("RPC method: loadHelper.getSpotAndTwapPrices");
    const result = await loadHelper.methods.getSpotAndTwapPrices().call();
    newUnderPrice = result.underSpotPrice.toString();
    newAvgUnderPrice = result.underTwapPrice.toString();
  } catch (e) {
    handleNewError(e, "There was an error while fetching underlying prices!", 2, dispatch);
  }

  return { newUnderPrice, newAvgUnderPrice };
};

export const loadPositionTokens = async (provider, web3, loadHelper, userBalances, markets, userAddress, dispatch) => {
  try {
    const positionTokens = (
      await Promise.map(markets, async (market, i) => {
        let { isCall, strikePrice, expirationTime } = market;
        const normalizedStrikePrice = parseFloat(formatUnits(strikePrice.toString(), 18));
        let { longTokenName, shortTokenName } = getTokenNames(isCall, normalizedStrikePrice, expirationTime);

        const longSplits = longTokenName.split("-");
        const shortSplits = shortTokenName.split("-");
        let longTokenShortName = "";
        let shortTokenShortName = "";
        if (market.isFuture) {
          longTokenShortName = longSplits[1] + "-" + longSplits[2] + "-" + longSplits[3];
          shortTokenShortName = shortSplits[1] + "-" + shortSplits[2] + "-" + longSplits[3];
        } else {
          longTokenShortName = longSplits[1] + "-" + longSplits[2] + "-" + longSplits[3] + "-" + longSplits[4];
          shortTokenShortName = shortSplits[1] + "-" + shortSplits[2] + "-" + shortSplits[3] + "-" + shortSplits[4];
        }

        return [
          {
            tokenId: market.marketId,
            name: longTokenName,
            shortName: longTokenShortName,
            symbol: longTokenName,
            type: "long",
            marketId: market.marketId,
            decimalsLoaded: true,
            decimals: 18
          },
          {
            tokenId: (parseInt(market.marketId) + 1).toString(),
            name: shortTokenName,
            shortName: shortTokenShortName,
            symbol: shortTokenName,
            type: "short",
            marketId: market.marketId,
            decimalsLoaded: true,
            decimals: 18
          }
        ];
      })
    ).flat();

    dispatch(positionTokensLoaded(positionTokens));
    const tokenIds = positionTokens.map(positionToken => positionToken.tokenId);

    // fetch account and wallet balances
    let startTime2 = new Date();
    const result = await loadHelper.methods.getUserAccountAndWalletPositionBalances(tokenIds, userAddress, userBalances._address).call();
    const accountBalances = result[0];
    const walletBalances = result[1];
    console.log("RPC method: loadHelper.getUserAccountAndWalletPositionBalances took: " + (new Date() - startTime2) + " ms");
    // dispatch
    const addressBalances2 = [];
    for (let i = 0; i < tokenIds.length; i++) {
      addressBalances2.push({ tokenId: tokenIds[i], balance: accountBalances[i] });
    }
    dispatch(positionTokenAccountBalancesLoaded(addressBalances2));
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while loading your positions. Please reload the application!", 1, dispatch);
  }
};

export const loadPositionTokens2 = async (provider, web3, loadHelper, userBalances, markets, userAddress, dispatch) => {
  try {
    const positionTokens = (
      await Promise.map(markets, async (market, i) => {
        let { isCall, strikePrice, expirationTime } = market;
        const normalizedStrikePrice = parseFloat(formatUnits(strikePrice.toString(), 18));
        let { longTokenName, shortTokenName } = getTokenNames(isCall, normalizedStrikePrice, expirationTime);

        const longSplits = longTokenName.split("-");
        const shortSplits = shortTokenName.split("-");
        let longTokenShortName = "";
        let shortTokenShortName = "";
        if (market.isFuture) {
          longTokenShortName = longSplits[1] + "-" + longSplits[2] + "-" + longSplits[3];
          shortTokenShortName = shortSplits[1] + "-" + shortSplits[2] + "-" + longSplits[3];
        } else {
          longTokenShortName = longSplits[1] + "-" + longSplits[2] + "-" + longSplits[3] + "-" + longSplits[4];
          shortTokenShortName = shortSplits[1] + "-" + shortSplits[2] + "-" + shortSplits[3] + "-" + shortSplits[4];
        }

        return [
          {
            tokenId: market.marketId,
            name: longTokenName,
            shortName: longTokenShortName,
            symbol: longTokenName,
            type: "long",
            marketId: market.marketId,
            decimalsLoaded: true,
            decimals: 18
          },
          {
            tokenId: (parseInt(market.marketId) + 1).toString(),
            name: shortTokenName,
            shortName: shortTokenShortName,
            symbol: shortTokenName,
            type: "short",
            marketId: market.marketId,
            decimalsLoaded: true,
            decimals: 18
          }
        ];
      })
    ).flat();

    const tokenIds = positionTokens.map(positionToken => positionToken.tokenId);

    // fetch account and wallet balances
    const result = await loadHelper.methods.getUserAccountAndWalletPositionBalances(tokenIds, userAddress, userBalances._address).call();
    const accountBalances = result[0];
    const addressBalances = [];
    for (let i = 0; i < tokenIds.length; i++) {
      addressBalances.push({ tokenId: tokenIds[i], balance: accountBalances[i] });
    }

    return [positionTokens, addressBalances];
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while loading your positions. Please reload the application!", 1, dispatch);
  }
};

export const addPositionToken = (web3, tokenId, type, market, dispatch) => {
  const { longTokenName, shortTokenName } = getTokenNames(market.isCall, Number(fromBn(market.strikePrice, 18)), market.expirationTime);
  const name = type === "long" ? longTokenName : shortTokenName;
  const splits = name.split("-");
  const shortName = splits[1] + "-" + splits[2] + "-" + splits[3] + "-" + splits[4];
  const symbol = name;

  dispatch(
    positionTokenAdded({
      tokenId: tokenId,
      name,
      shortName,
      symbol,
      type,
      marketId: market.marketId,
      decimalsLoaded: true,
      decimals: 18,
      walletBalance: "0",
      accountBalance: "0"
    })
  );
};

export const loadLPPositionTokens = async (networkData, web3, loadHelper, lpManager, markets, userBalances, userAddress, underPriceBN, dispatch) => {
  try {
    let lpPositionTokens = [];

    // fast, because we already loaded all events, most of it is cached
    const lpManagerAllEvents = await getEventsStreamCached(networkData, lpManager, "LPManager", "allEvents");
    const lpManagerEvents = lpManagerAllEvents.filter(event => event.event == "OpenedMarketMakerPosition");
    const increaseLiquidityRaw = lpManagerEvents.map(event => event.returnValues);

    for (let i = 0; i < increaseLiquidityRaw.length; i++) {
      let event = increaseLiquidityRaw[i];
      const marketId = event.marketId.toString();
      const positionId = event.positionId.toString();
      const market = markets.find(market => market.marketId.toString() === marketId);
      const { isCall, strikePrice, expirationTime } = market;
      const normalizedStrikePrice = parseFloat(formatUnits(strikePrice.toString(), 18));
      let { longTokenName, shortTokenName } = getTokenNames(isCall, normalizedStrikePrice, expirationTime);
      const name = "LP" + positionId + longTokenName.substring(4, longTokenName.length);
      const symbol = "LP" + positionId + longTokenName.substring(4, longTokenName.length);
      const splits = name.split("-");
      let shortName = "";
      if (!market.isFuture) {
        shortName = "LP" + positionId + "-" + splits[1] + "-" + splits[2] + "-" + splits[3] + "-" + splits[4];
      } else {
        shortName = "LP" + positionId + "-" + splits[1] + "-" + splits[2] + "-" + splits[3];
      }

      lpPositionTokens.push({
        marketId: marketId,
        positionId: positionId,
        name,
        shortName,
        symbol,
        accountBalance: 0,
        accountBalanceLoaded: true,
        longBalance: "0",
        baseBalance: "0",
        feeBalance: "0",
        lower: 0,
        upper: 0,
        sizeInLongs: 0,
        shortBalance: "0",
        health: 0
      });
    }
    lpPositionTokens = _.uniqBy(lpPositionTokens, lpt => [lpt.marketId, lpt.positionId].join());
    dispatch(lpPositionTokensData(lpPositionTokens));
    // load balances
    const marketIds = lpPositionTokens.map(position => position.marketId);
    const tokenIds = lpPositionTokens.map(position => position.positionId);
    let startTime2 = new Date().getTime();
    const accountBalances = await loadHelper.methods.getUserAccountLPBalances(marketIds, tokenIds, userAddress, userBalances._address).call();
    console.log("RPC method: loadHelper.getUserAccountLPBalances took", new Date().getTime() - startTime2, "mS");

    // if user has liquidity, load and dispatch more balance and stuff
    const userMarketIds = [];
    const userTokenIds = [];
    for (let i = 0; i < lpPositionTokens.length; i++) {
      if (accountBalances[i] !== "0") {
        userMarketIds.push(marketIds[i]);
        userTokenIds.push(tokenIds[i].toString());
      }
    }
    let startTime1 = new Date().getTime();
    if (userMarketIds.length > 0) {
      await loadAccountLPBalances(web3, loadHelper, userMarketIds, userTokenIds, userAddress, underPriceBN, dispatch);
    }
    let endTime1 = new Date().getTime();
    console.log("Time to load LP position data (faster):", endTime1 - startTime1, "mS");

    dispatch(lpPositionTokensLoaded());
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while loading your lp positions. Please reload the application!", 1, dispatch);
  }
};

export const loadLPPositionTokens2 = async (web3, networkData, loadHelper, lpManager, markets, userBalances, userAddress, underPriceBN, dispatch) => {
  try {
    let lpPositionTokens = [];
    // fast, because we already loaded all events, most of it is cached
    const lastBlock = await web3.eth.getBlockNumber();
    const networkDataLocal = { firstBlock: networkData.firstBlock, lastBlock: lastBlock };
    const lpManagerAllEvents = await getEventsStreamCached(networkDataLocal, lpManager, "LPManager", "allEvents");
    const lpManagerEvents = lpManagerAllEvents.filter(event => event.event == "OpenedMarketMakerPosition");
    const increaseLiquidityRaw = lpManagerEvents.map(event => event.returnValues);

    for (let i = 0; i < increaseLiquidityRaw.length; i++) {
      let event = increaseLiquidityRaw[i];
      const marketId = event.marketId.toString();
      const positionId = event.positionId.toString();
      const market = markets.find(market => market.marketId.toString() === marketId);
      const { isCall, strikePrice, expirationTime } = market;
      const normalizedStrikePrice = parseFloat(formatUnits(strikePrice.toString(), 18));
      let { longTokenName, shortTokenName } = getTokenNames(isCall, normalizedStrikePrice, expirationTime);
      const name = "LP" + positionId + longTokenName.substring(4, longTokenName.length);
      const symbol = "LP" + positionId + longTokenName.substring(4, longTokenName.length);
      const splits = name.split("-");
      let shortName = "";
      if (!market.isFuture) {
        shortName = "LP" + positionId + "-" + splits[1] + "-" + splits[2] + "-" + splits[3] + "-" + splits[4];
      } else {
        shortName = "LP" + positionId + "-" + splits[1] + "-" + splits[2] + "-" + splits[3];
      }

      lpPositionTokens.push({
        marketId: marketId,
        positionId: positionId,
        name,
        shortName,
        symbol,
        accountBalance: 0,
        accountBalanceLoaded: true,
        longBalance: "0",
        baseBalance: "0",
        feeBalance: "0",
        lower: 0,
        upper: 0,
        sizeInLongs: 0,
        shortBalance: "0",
        health: 0
      });
    }
    lpPositionTokens = _.uniqBy(lpPositionTokens, lpt => [lpt.marketId, lpt.positionId].join());

    // load balances
    const marketIds = lpPositionTokens.map(position => position.marketId);
    const tokenIds = lpPositionTokens.map(position => position.positionId);
    const accountBalances = await loadHelper.methods.getUserAccountLPBalances(marketIds, tokenIds, userAddress, userBalances._address).call();
    const userMarketIds = [];
    const userTokenIds = [];
    for (let i = 0; i < lpPositionTokens.length; i++) {
      if (accountBalances[i] !== "0") {
        userMarketIds.push(marketIds[i]);
        userTokenIds.push(tokenIds[i].toString());
      }
    }
    let accountLPBalances = null;
    if (userMarketIds.length > 0) {
      accountLPBalances = await loadAccountLPBalances2(web3, loadHelper, userMarketIds, userTokenIds, userAddress, underPriceBN, dispatch);
    }

    dispatch(lpPositionTokensLoaded());
    return [lpPositionTokens, accountLPBalances, userMarketIds.length];
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while loading your lp positions. Please reload the application!", 1, dispatch);
  }
};

export const loadAccountLPBalance = async (loadHelper, marketId, positionId, userAccount, underPriceBN, dispatch) => {
  try {
    // todo: v2 check if this is needed, if acountBalanceLoaded is false, then we should'nt call loadingLPPositionTokenAccountBalance
    dispatch(loadingLPPositionTokenAccountBalance(marketId, positionId));
    let health = 0;

    console.log("RPC method: loadHelper.getLPPositionData");
    const data = await loadHelper.methods.getLPPositionData(marketId, positionId, userAccount, underPriceBN).call();
    if (data.liquidity.toString() !== "0") {
      health = parseFloat(formatUnits(data.health.toString(), 18));
      console.log(
        "Position",
        positionId + ":",
        parseFloat(formatUnits(data.longBalance.toString(), 18)).toFixed(2),
        "longs +",
        parseFloat(formatUnits(data.shortBalance.toString(), 18)).toFixed(2),
        "shorts +",
        "$" + parseFloat(formatUnits(data.baseBalance.toString(), 18)).toFixed(2),
        "(out of which $" + parseFloat(formatUnits(data.feeBalance.toString(), 18)).toFixed(2),
        "fees), health:",
        (health * 100).toFixed(),
        "%"
      );
    }

    dispatch(
      lpPositionTokenAccountBalanceLoaded(
        marketId.toString(),
        positionId.toString(),
        data.liquidity.toString(),
        data.longBalance.toString(),
        data.baseBalance.toString(),
        data.feeBalance.toString(),
        parseInt(data.lower),
        parseInt(data.upper),
        data.sizeInLongs.toString(),
        data.shortBalance.toString(),
        health
      )
    );
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while loading account balance for lp token. Please reload the application!", 1, dispatch);
  }
};

export const loadAccountLPBalances = async (web3, loadHelper, marketIds, tokenIds, userAccounts, underPriceBN, dispatch) => {
  try {
    let startTime = new Date().getTime();
    const dataArray = await loadHelper.methods.getLPPositionsData(marketIds, tokenIds, userAccounts, underPriceBN).call();
    console.log("RPC method: loadHelper.getLPPositionsData in", new Date().getTime() - startTime, "mS");

    let startPartTime = new Date().getTime();
    for (let i = 0; i < marketIds.length; i++) {
      let health = 0;
      let data = dataArray[i];
      if (data.liquidity.toString() !== "0") {
        health = parseFloat(formatUnits(data.health.toString(), 18));
        console.log(
          "Position",
          tokenIds[i] + ":",
          parseFloat(formatUnits(data.longBalance.toString(), 18)).toFixed(2),
          "longs +",
          parseFloat(formatUnits(data.shortBalance.toString(), 18)).toFixed(2),
          "shorts +",
          "$" + parseFloat(formatUnits(data.baseBalance.toString(), 18)).toFixed(2),
          "(out of which $" + parseFloat(formatUnits(data.feeBalance.toString(), 18)).toFixed(2),
          "fees), health:",
          (health * 100).toFixed(),
          "%"
        );
      }
    }

    const positionsData = dataArray.map((position, i) => {
      return {
        marketId: marketIds[i],
        tokenId: tokenIds[i].toString(),
        liquidity: position.liquidity.toString(),
        longBalance: position.longBalance.toString(),
        baseBalance: position.baseBalance.toString(),
        feeBalance: position.feeBalance.toString(),
        lower: parseInt(position.lower),
        upper: parseInt(position.upper),
        sizeInLongs: position.sizeInLongs.toString(),
        shortBalance: position.shortBalance.toString(),
        health: parseFloat(formatUnits(position.health.toString(), 18))
      };
    });

    dispatch(lpPositionTokenAccountBalancesLoaded(positionsData));

    let endPartTime = new Date().getTime();
    console.log("Time to log and dispatch (faster):", endPartTime - startPartTime, "mS");
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while loading account balance for lp token. Please reload the application!", 1, dispatch);
  }
};
export const loadAccountLPBalances2 = async (web3, loadHelper, marketIds, tokenIds, userAccounts, underPriceBN, dispatch) => {
  try {
    const dataArray = await loadHelper.methods.getLPPositionsData(marketIds, tokenIds, userAccounts, underPriceBN).call();

    const positionsData = dataArray.map((position, i) => {
      return {
        marketId: marketIds[i],
        tokenId: tokenIds[i].toString(),
        liquidity: position.liquidity.toString(),
        longBalance: position.longBalance.toString(),
        baseBalance: position.baseBalance.toString(),
        feeBalance: position.feeBalance.toString(),
        lower: parseInt(position.lower),
        upper: parseInt(position.upper),
        sizeInLongs: position.sizeInLongs.toString(),
        shortBalance: position.shortBalance.toString(),
        health: parseFloat(formatUnits(position.health.toString(), 18))
      };
    });

    return positionsData;
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while loading account balance for lp token. Please reload the application!", 1, dispatch);
  }
};

export const addOrUpdateLPToken = (web3, marketId, longTokenName, positionId, dispatch) => {
  const name = "LP" + positionId + longTokenName.substring(4, longTokenName.length);
  const symbol = "LP" + positionId + longTokenName.substring(4, longTokenName.length);
  const splits = name.split("-");
  let shortName = "";
  const { marketsImmutable } = store.getState();
  const marketImmutableData = marketsImmutable.data.find(market => market.marketId === marketId.toString());
  const isFuture = marketImmutableData.isFuture;
  if (!isFuture) {
    shortName = "LP" + positionId + "-" + splits[1] + "-" + splits[2] + "-" + splits[3] + "-" + splits[4];
  } else {
    shortName = "LP" + positionId + "-" + splits[1] + "-" + splits[2] + "-" + splits[3];
  }

  dispatch(
    lpTokenAddedOrUpdated({
      marketId: marketId.toString(),
      positionId: positionId.toString(),
      name,
      shortName,
      symbol,
      accountBalance: 0
    })
  );
};

export const reloadMarketTokensBalances = async (web3, marketId, userBalances, loadHelper, userAccount, underPriceBN, dispatch, tokenId = null) => {
  console.log("RPC method: getImmutableAttributes");
  const marketImmutableParams = await loadHelper.methods.getAssetAddresses().call();

  // base token
  loadAssetAccountBalance(marketImmutableParams.baseTokenAddress, userBalances, userAccount, dispatch);
  loadAssetWalletBalance(web3, marketImmutableParams.baseTokenAddress, userAccount, dispatch);
  // underlying token
  loadAssetAccountBalance(marketImmutableParams.underTokenAddress, userBalances, userAccount, dispatch);
  loadAssetWalletBalance(web3, marketImmutableParams.underTokenAddress, userAccount, dispatch);
  // long token
  loadPositionTokenAccountBalance(marketId, userBalances, userAccount, dispatch);
  // short token
  loadPositionTokenAccountBalance((parseInt(marketId) + 1).toString(), userBalances, userAccount, dispatch);
  // liquidity token
  if (tokenId) {
    loadAccountLPBalance(loadHelper, marketId, tokenId, userAccount, underPriceBN, dispatch);
  }
};

export const selectTokenPair = async (selectedPair, dispatch) => {
  dispatch(tokenPairSelected(selectedPair));
};
