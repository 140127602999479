/* eslint-disable no-undef */
import GammaContractsRegistry from "../../abis/GammaContractsRegistry.json";
import MarketFactory from "../../abis/MarketFactory.json";
import IMarketRegistry from "../../abis/IMarketRegistry.json";
import UserBalances from "../../abis/UserBalances.json";
import UserBalancesVault from "../../abis/UserBalancesVault.json";
import DateUtils from "../../abis/DateUtils.json";
import IPriceUtils from "../../abis/IPriceUtils.json";
import IUniswapAdapter from "../../abis/IUniswapAdapter.json";
import TokenRegistry from "../../abis/TokenRegistry.json";
import PositionsManager from "../../abis/PositionsManager.json";
import MarginPool from "../../abis/MarginPool.json";
import InterestRateModel from "../../abis/InterestRateModel.json";
import LoadHelper from "../../abis/LoadHelper.json";
import LPManager from "../../abis/LPManager.json";
import SettlementManager from "../../abis/SettlementManager.json";
import VolRangeMarketSingleton from "../../abis/VolRangeMarketSingleton.json";
import Settlements from "../../abis/Settlements.json";
import InsuranceFund from "../../abis/InsuranceFund.json";
import ERC20 from "../../abis/ERC20.json";
import { setWETHAddress } from "./tokensInteractions";
import { handleNewError } from "./errorInteractions";
import { getMethodResultCached } from "./interactionsManager";
import { BASE_SEPOLIA_NETWORK_ID, GANACHE_NETWORK_ID, BASE_MAINNET_NETWORK_ID } from "../../config";

export const isSupportedNetwork = async (networkId, dispatch) => {
  const isBaseSepolia = networkId == BASE_SEPOLIA_NETWORK_ID;
  const isGanache = networkId == GANACHE_NETWORK_ID;
  const isBase = networkId == BASE_MAINNET_NETWORK_ID;
  if (!(isBase || isBaseSepolia || isGanache) || GammaContractsRegistry.networks[networkId] === undefined) {
    handleNewError({}, "", 1, dispatch, true); // this triggers Switch network modal
    return false;
  }

  return true;
};

export const getMainContractAddress = networkId => {
  return GammaContractsRegistry.networks[networkId].address;
};

export const loadContracts = async (web3, web3Read, networkId, dispatch) => {
  try {
    // load gamma contracts registry
    const gammaContractsRegistry = new web3.eth.Contract(GammaContractsRegistry.abi, GammaContractsRegistry.networks[networkId].address);
    // load other contracts
    // NEW CODE
    const ids = await gammaContractsRegistry.methods.getIds().call();
    const contractIds = [
      Number(ids.UniswapAdapter),
      Number(ids.MarketRegistry),
      Number(ids.MarketFactory),
      Number(ids.DateUtils),
      Number(ids.PriceUtils),
      Number(ids.UserBalances),
      Number(ids.TokenRegistry),
      Number(ids.PositionsManager),
      Number(ids.MarginPool),
      Number(ids.InterestRateModel),
      Number(ids.LoadHelper),
      Number(ids.LPManager),
      Number(ids.SettlementManager),
      Number(ids.VolRangeMarketSingleton),
      Number(ids.UserBalancesVault),
      Number(ids.Settlements),
      Number(ids.WETH),
      Number(ids.ReferenceToken),
      Number(ids.InsuranceFund)
    ];

    const contractMethod = gammaContractsRegistry.methods.getMultiple(contractIds);
    // eslint-disable-next-line no-console
    console.log("RPC method (cached): getMultiple");
    const addresses = await getMethodResultCached(contractMethod, "GammaContractsRegistry", "getMultiple");
    //const addresses = await gammaContractsRegistry.methods.getMultiple(contractIds).call();

    const contracts = createContracts(web3, networkId, addresses, dispatch);
    const contractsRead = createContracts(web3Read, networkId, addresses, dispatch);

    return { contracts, contractsRead };
  } catch (e) {
    console.log(e);
    handleNewError({}, "", 1, dispatch, true);
  }
};

export const createContracts = (web3, networkId, addresses, dispatch) => {
  try {
    const gammaContractsRegistry = new web3.eth.Contract(GammaContractsRegistry.abi, GammaContractsRegistry.networks[networkId].address);
    const uniswapAdapter = new web3.eth.Contract(IUniswapAdapter.abi, addresses[0]);
    const marketRegistry = new web3.eth.Contract(IMarketRegistry.abi, addresses[1]);
    const marketFactory = new web3.eth.Contract(MarketFactory.abi, addresses[2]);
    const dateUtils = new web3.eth.Contract(DateUtils.abi, addresses[3]);
    const priceUtils = new web3.eth.Contract(IPriceUtils.abi, addresses[4]);
    const userBalances = new web3.eth.Contract(UserBalances.abi, addresses[5]);
    const tokenRegistry = new web3.eth.Contract(TokenRegistry.abi, addresses[6]);
    const positionsManager = new web3.eth.Contract(PositionsManager.abi, addresses[7]);
    const marginPool = new web3.eth.Contract(MarginPool.abi, addresses[8]);
    const interestRateModel = new web3.eth.Contract(InterestRateModel.abi, addresses[9]);
    const loadHelper = new web3.eth.Contract(LoadHelper.abi, addresses[10]);
    const lpManager = new web3.eth.Contract(LPManager.abi, addresses[11]);
    const settlementManager = new web3.eth.Contract(SettlementManager.abi, addresses[12]);
    const volRangeMarketSingleton = new web3.eth.Contract(VolRangeMarketSingleton.abi, addresses[13]);
    const userBalancesVault = new web3.eth.Contract(UserBalancesVault.abi, addresses[14]);
    const settlements = new web3.eth.Contract(Settlements.abi, addresses[15]);
    const wethToken = new web3.eth.Contract(ERC20.abi, addresses[16]);
    const usdToken = new web3.eth.Contract(ERC20.abi, addresses[17]);
    const insuranceFund = new web3.eth.Contract(InsuranceFund.abi, addresses[18]);

    setWETHAddress(addresses[16], dispatch);

    const contracts = {
      gammaContractsRegistry,
      uniswapAdapter,
      marketRegistry,
      marketFactory,
      dateUtils,
      priceUtils,
      userBalances,
      tokenRegistry,
      positionsManager,
      marginPool,
      interestRateModel,
      loadHelper,
      lpManager,
      settlementManager,
      volRangeMarketSingleton,
      userBalancesVault,
      settlements,
      wethToken,
      usdToken,
      insuranceFund
    };

    return contracts;
  } catch (e) {
    console.log(e);
    handleNewError({}, "", 1, dispatch, true);
  }
};
