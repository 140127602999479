import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import { cursorRangeCheck } from "../../utils/utils.js";

let timeoutId = null;

const MyTooltip = ({ target, optionalOffset, children, maxWidth }) => {
  const [visible, setVisible] = useState(false);
  const [targetElement, setTargetElement] = useState(null);

  useEffect(() => {
    setTargetElement(document.getElementById(target));
  }, []);

  useEffect(() => {
    if (targetElement) {
      targetElement.addEventListener("mouseenter", onTargetElementEnter);
      targetElement.addEventListener("mouseleave", onTargetElementLeave);
    }
    return () => {
      if (targetElement) {
        targetElement.removeEventListener("mouseenter", onTargetElementEnter);
        targetElement.removeEventListener("mouseleave", onTargetElementLeave);
      }
    };
  }, [targetElement]);

  useEffect(() => {
    if (visible) {
      const targetOverlay = document.getElementById(target + "_tooltipOverlay");
      if (maxWidth) {
        targetOverlay.style.maxWidth = maxWidth + "px";
      }
      targetOverlay.classList.add("hidden");
      document.querySelector(".tooltipArrow").classList.add("hidden");
      const { width, height } = targetOverlay.getBoundingClientRect();
      const { left: targetLeft, width: targetWidth, top: targetTop, bottom: targetBottom } = targetElement.getBoundingClientRect();
      let newLeft = targetLeft + targetWidth / 2 - width / 2;
      if (newLeft < 0) {
        // if tooltip is out of left viewport
        newLeft = 5;
      }
      const leftMoreThanVw = newLeft + width;
      if (leftMoreThanVw > window.innerWidth) {
        const translateLeft = leftMoreThanVw - window.innerWidth;
        newLeft = newLeft - translateLeft - 5;
      }

      document.querySelector(":root").style.setProperty("--tooltipLeftPosition", newLeft + "px");
      document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", targetLeft + targetWidth / 2 - 8 + "px");

      document
        .querySelector(":root")
        .style.setProperty("--tooltipTopPosition", targetTop - height + (optionalOffset !== undefined ? -optionalOffset : "") + "px");
      document
        .querySelector(":root")
        .style.setProperty("--tooltipArrowTopPosition", targetTop - 10 + (optionalOffset !== undefined ? -optionalOffset : "") + "px");

      targetOverlay.style.height = height + 15 + "px";

      // this is case when top of tooltio when is above has negative top value and is not visible so we put them below
      const newTopTargetOverlay = targetOverlay.getBoundingClientRect().top;
      if (newTopTargetOverlay < 0) {
        document
          .querySelector(":root")
          .style.setProperty("--tooltipTopPosition", targetBottom - 20 + (optionalOffset !== undefined ? optionalOffset : "") + "px");
        document
          .querySelector(":root")
          .style.setProperty("--tooltipArrowTopPosition", targetBottom - 10 + (optionalOffset !== undefined ? optionalOffset : "") + "px");
        targetOverlay.style.justifyContent = "flex-end";
      }

      document.getElementById(target + "_tooltipOverlay").classList.remove("hidden");
      document.querySelector(".tooltipArrow").classList.remove("hidden");
    }
  }, [visible]);

  const onTargetElementEnter = e => {
    if (!visible) {
      timeoutId = setTimeout(() => setVisible(true), 300);
    }
  };

  const onTargetElementLeave = e => {
    if (!cursorRangeCheck(target, e.clientX, e.clientY)) {
      clearTimeout(timeoutId);
      setVisible(false);
      document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", -500 + "px");

      document.querySelector(":root").style.setProperty("--tooltipTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipLeftPosition", -500 + "px");
    }
  };

  const onTargetOverlayLeave = e => {
    clearTimeout(timeoutId);
    setVisible(false);
    document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", -500 + "px");
    document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", -500 + "px");

    document.querySelector(":root").style.setProperty("--tooltipTopPosition", -500 + "px");
    document.querySelector(":root").style.setProperty("--tooltipLeftPosition", -500 + "px");
  };

  return visible
    ? createPortal(
        <>
          <div
            id={target + "_tooltipOverlay"}
            key={target + "_tooltipOverlay"}
            className="tooltipOverlay"
            onMouseLeave={onTargetOverlayLeave}
            onClick={e => e.stopPropagation()}
          >
            <div key={target + "_tooltipContainer"} id={target + "_tooltipContainer"} className="tooltipContainer">
              <div className="tooltipContent">{children}</div>
            </div>
          </div>
          <div key={target + "_tooltipArrow"} id={target + "_tooltipArrow"} className="tooltipArrow" />
        </>,
        document.getElementById("root")
      )
    : null;
};

MyTooltip.propTypes = {
  target: PropTypes.string,
  delay: PropTypes.number,
  optionalOffset: PropTypes.number,
  maxWidth: PropTypes.number,
  children: PropTypes.object
};

export default MyTooltip;
