import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { UncontrolledTooltip, Form, FormGroup, Input, Row, Col } from "reactstrap";
import MyModal from "./utilities/MyModal.jsx";
import { isPositiveNumber } from "../../utils/utils.js";

const TransactionSettingsModal = props => {
  const [slippageTolerance, setSlippageTolerance] = useState(() => {
    !localStorage.getItem("tradeSlippageTolerance") && localStorage.setItem("tradeSlippageTolerance", 1);
    return Number(localStorage.getItem("tradeSlippageTolerance"));
  });
  const [slippageToleranceInputClass, setSlippageToleranceInputClass] = useState("");
  const [slippageToleranceInputErrorMessage, setSlippageToleranceInputErrorMessage] = useState("");

  const [transactionTimeout, setTransactionTimeout] = useState(() => {
    !localStorage.getItem("tradeTransactionTimeout") && localStorage.setItem("tradeTransactionTimeout", 20);
    return Number(localStorage.getItem("tradeTransactionTimeout"));
  });
  const [transactionTimeoutInputClass, setTransactionTimeoutInputClass] = useState("");
  const [transactionTimeoutInputErrorMessage, setTransactionTimeoutInputErrorMessage] = useState("");

  const resetSettingsRef = useRef();

  useEffect(() => {
    const transactionSettingsModalContent = document.querySelector("#transactionSettingsModal > .modal-content");

    if (transactionSettingsModalContent) {
      const optionPriceInformationContainer = document.querySelector(".optionPriceInformation");
      const width = optionPriceInformationContainer.getBoundingClientRect().width;
      transactionSettingsModalContent.style.width = width + "px";
    }

    setSlippageTolerance(props.slippageTolerance);
    setTransactionTimeout(props.transactionTimeout);

    document.onkeydown = onKeyPress;
  }, []);

  useEffect(() => {
    setSlippageTolerance(props.slippageTolerance);
    setSlippageToleranceInputClass("has-success");
    setSlippageToleranceInputErrorMessage("");

    setTransactionTimeout(props.transactionTimeout);
    setTransactionTimeoutInputClass("has-success");
    setTransactionTimeoutInputErrorMessage("");
  }, [props.isOpen]);

  const onKeyPress = e => {
    e.stopPropagation();
    if (e.key === "Escape") {
      props.toggleTransactionSettingsModal();
    }
  };

  const onResetMouseEnter = e => {
    resetSettingsRef.current.querySelector(".resetImg").classList.add("resetImgHover");
  };
  const onResetMouseLeave = e => {
    resetSettingsRef.current.querySelector(".resetImg").classList.remove("resetImgHover");
  };

  const onSlippageToleranceChange = e => {
    const inputString = e.target.value.trim();
    if (isNaN(inputString)) {
      return;
    }
    setSlippageTolerance(inputString);
    if (inputString === "") {
      setSlippageToleranceInputClass("");
      setSlippageToleranceInputErrorMessage("");
    } else if (!isPositiveNumber(inputString)) {
      setSlippageToleranceInputClass("has-danger");
      setSlippageToleranceInputErrorMessage("Please enter a positive number.");
    } else {
      // is positive number
      let inputAmount = parseFloat(inputString);
      if (inputAmount < 0.1 || inputAmount > 10) {
        setSlippageToleranceInputClass("has-danger");
        setSlippageToleranceInputErrorMessage("Please enter a valid percentage.");
      } else {
        setSlippageToleranceInputClass("has-success");
        setSlippageToleranceInputErrorMessage("");
        props.setSlippageTolerance(inputAmount);
        localStorage.setItem("tradeSlippageTolerance", inputString);
      }
      // todo: v1 add warning for > 2% slippage that there will be frontrunning
    }
  };

  const onTransactionTimeoutChange = e => {
    // const inputString = document.getElementById("transactionTimeout").value;
    const inputString = e.target.value.trim();
    if (isNaN(inputString)) {
      return;
    }
    setTransactionTimeout(inputString);
    if (inputString === "") {
      setTransactionTimeoutInputClass("");
      setTransactionTimeoutInputErrorMessage("");
    } else if (!isPositiveNumber(inputString)) {
      setTransactionTimeoutInputClass("has-danger");
      setTransactionTimeoutInputErrorMessage("Please enter a positive number.");
    } else {
      // is positive number
      let inputAmount = parseFloat(inputString);
      if (inputAmount < 1 || inputAmount > 1440) {
        setTransactionTimeoutInputClass("has-danger");
        setTransactionTimeoutInputErrorMessage("Please enter a valid timeout.");
      } else {
        setTransactionTimeoutInputClass("has-success");
        setTransactionTimeoutInputErrorMessage("");
        props.setTransactionTimeout(inputAmount);
        localStorage.setItem("tradeTransactionTimeout", inputString);
      }
    }
  };

  const clearInput = e => {
    if (e.target.previousSibling.id.includes("slippage")) {
      setSlippageTolerance("");
      setSlippageToleranceInputClass("");
      e.target.previousSibling.focus();
    } else if (e.target.previousSibling.id.includes("transaction")) {
      setTransactionTimeout("");
      setTransactionTimeoutInputClass("");
      e.target.previousSibling.focus();
    }
  };

  const resetSettings = () => {
    localStorage.setItem("tradeSlippageTolerance", 1);
    localStorage.setItem("tradeTransactionTimeout", 20);

    setSlippageTolerance(1);
    setSlippageToleranceInputClass("has-success");
    setSlippageToleranceInputErrorMessage("");

    setTransactionTimeout(20);
    setTransactionTimeoutInputClass("has-success");
    setTransactionTimeoutInputErrorMessage("");

    props.setSlippageTolerance(1);
    props.setTransactionTimeout(20);
  };

  const isDefault = () => {
    return slippageTolerance == 1 && transactionTimeout == 20;
  };

  return (
    <MyModal isOpen={props.isOpen} toggle={() => props.toggleTransactionSettingsModal()}>
      <div
        id="transactionSettingsModal"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div className="modalHeader">
          <span className="tokenSpan">Transaction Settings</span>
          <button
            aria-hidden
            data-dismiss="modal"
            type="button"
            className="transactionSettingsCloseModal"
            onClick={() => props.toggleTransactionSettingsModal()}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
        <div className="transactionSettingsModalContent">
          <Row>
            <Col id="slippageToleranceText" md="6" style={{ textAlign: "left" }}>
              Slippage Tolerance [%]
            </Col>
            <UncontrolledTooltip placement="bottom" target={"#slippageToleranceText"} delay={300}>
              Your transaction will revert if the price changes unfavorably by more than this percentage.
            </UncontrolledTooltip>
            <Col md="6">
              <Form style={{ width: "100%" }}>
                <FormGroup>
                  <Input
                    id="slippageTolerance"
                    type="text"
                    autoComplete="off"
                    placeholder="0.5% - 2% works best"
                    style={{ width: "100%" }}
                    value={slippageTolerance}
                    onChange={e => {
                      const value = e.target.value;
                      if (isNaN(value)) {
                        return;
                      }
                      if (value[0] === "0" && value[1] && value[1] !== ".") {
                        return;
                      }
                      onSlippageToleranceChange(e);
                    }}
                    className={`input${slippageToleranceInputClass === "has-danger" ? " inputDanger" : ""}${
                      slippageToleranceInputClass === "has-success" ? " inputSuccess" : ""
                    }`}
                  />
                  {slippageToleranceInputClass === "has-danger" ? <img className="clearInputError" onClick={clearInput} /> : null}
                  {slippageToleranceInputClass === "has-success" ? <img className="validInput" /> : null}
                </FormGroup>
                {slippageToleranceInputClass === "has-danger" ? <span className="errorLabel">{slippageToleranceInputErrorMessage}</span> : null}
              </Form>
            </Col>
          </Row>
          <Row>
            <Col id="transactionTimeoutText" md="6" style={{ textAlign: "left" }}>
              Transaction Timeout [min]
            </Col>
            <UncontrolledTooltip placement="bottom" target={"#transactionTimeoutText"} delay={300}>
              Your transaction will revert if it is not confirmed for more than this period of time.
            </UncontrolledTooltip>
            <Col md="6">
              <Form style={{ width: "100%" }}>
                <FormGroup>
                  <Input
                    id="transactionTimeout"
                    type="text"
                    autoComplete="off"
                    placeholder="20 minutes"
                    style={{ width: "100%" }}
                    value={transactionTimeout}
                    onChange={e => {
                      const value = e.target.value;
                      if (isNaN(value)) {
                        return;
                      }
                      if (value[0] === "0" && value[1] && value[1] !== ".") {
                        return;
                      }
                      onTransactionTimeoutChange(e);
                    }}
                    className={`input${transactionTimeoutInputClass === "has-danger" ? " inputDanger" : ""}${
                      transactionTimeoutInputClass === "has-success" ? " inputSuccess" : ""
                    }`}
                  />
                  {transactionTimeoutInputClass === "has-danger" ? <img className="clearInputError" onClick={clearInput} /> : null}
                  {transactionTimeoutInputClass === "has-success" ? <img className="validInput" /> : null}
                </FormGroup>
                {transactionTimeoutInputClass === "has-danger" ? <span className="errorLabel">{transactionTimeoutInputErrorMessage}</span> : null}
              </Form>
            </Col>
          </Row>
          <Row>
            <div className="resetFlex">
              <div
                ref={resetSettingsRef}
                className={`imgAndResetFiltersSpan${isDefault() ? " imgAndResetFiltersSpanDisabled" : ""}`}
                onClick={() => !isDefault() && resetSettings()}
                onMouseEnter={onResetMouseEnter}
                onMouseLeave={onResetMouseLeave}
              >
                <img className={`resetImg${isDefault() ? " resetImgDisabled" : ""}`} />
                <span className={`resetFiltersText${isDefault() ? " resetFiltersTextDisabled" : ""}`}>Reset Settings</span>
              </div>
            </div>
          </Row>
        </div>
      </div>
    </MyModal>
  );
};

TransactionSettingsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleTransactionSettingsModal: PropTypes.func.isRequired,
  slippageTolerance: PropTypes.number.isRequired,
  transactionTimeout: PropTypes.number.isRequired,
  setSlippageTolerance: PropTypes.func.isRequired,
  setTransactionTimeout: PropTypes.func.isRequired
};

export default TransactionSettingsModal;
