import * as types from "../actionTypes.js";

// WEB3
export const web3 = (state = { loaded: false, loading: false }, action) => {
  switch (action.type) {
    case types.LOADING_WEB3:
      return { ...state, loaded: false, loading: true };
    case types.WEB3_LOADED:
      if (action.web3read) {
        return {
          ...state,
          loaded: true,
          loading: false,
          connection: action.connection,
          web3read: action.web3read,
          web3Events: action.web3Events,
          web3Subs: action.web3Subs
        };
      }
      return { ...state, loaded: true, loading: false, connection: action.connection };
    case types.WEB3_UNLOADED:
      return { ...state, loaded: false, loading: false, connection: null };
    case types.WEB3_ACCOUNT_LOADED:
      return { ...state, accountLoaded: true, account: action.account };
    case types.WEB3_ACCOUNT_UNLOADED:
      return { ...state, accountLoaded: false, account: null };
    case types.CURRENT_TIMESTAMP_LOADED:
      return { ...state, currentTimestamp: action.timestamp };
    default:
      return state;
  }
};
