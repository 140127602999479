/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/no-named-as-default-member */
import greeks from "greeks";
import $ from "jquery";
import { groupBy } from "lodash";
import moment from "moment";
import React, { Component, createRef } from "react";
import Timer from "react-compound-timer";
import ReactGA from "react-ga4";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { handleNewError } from "../../store/interactions/errorInteractions";
import { openedLPPositionsSelector } from "../../store/selectors/positionsManagerSelectors";
import { expirationTimesSelector, selectedExpirationTimeSelector } from "../../store/selectors/dateUtilsSelectors";
import { selectedTokenPairSelector, avgUnderlyingTokenPriceSelector } from "../../store/selectors/tokensSelectors";
import { currentTimestampSelector, web3AccountLoadedSelector } from "../../store/selectors/web3Selectors";
import {
  displayLoadingIcon,
  displayLoadingIconOld,
  formatValue,
  cursorRangeCheck,
  currentPriceIsInGap,
  logBase,
  activeMarketsFiltered,
  printUnderlyingFuturePrice,
  printBidPrice,
  printAskPrice,
  marketsEqualByLongPrice
} from "../../utils/utils.js";
import ConnectPanel from "./ConnectPanel.jsx";
import PoolModal from "./PoolModal.jsx";
import { OPTIONS_TABLE_SETTINGS_POOL } from "./utilities/MarketsPanels/CogModalOptions";
import { marketsHistoricalDataSelector, marketsLoadedSelector, marketsNonExpiredSelector } from "../../store/selectors/marketsSelectors";
import { FilterContext } from "../../layouts/market/MainRouter.jsx";
import CogMarketsModal from "./CogMarketsModal.jsx";
import MarketsSelect from "./MarketsSelect.jsx";
import MyTooltip from "./MyTooltip.jsx";
import MyTooltip2 from "./MyTooltip2.jsx";
import PropTypes from "prop-types";
import { getMaxLongAmountWhenBuyingOptions, isMarketEmpty } from "../../utils/MarketHelper";
import MarketWorker from "./../../workers/marketWorker.worker";
import CreateMultipleMarketsModal from "./CreateMultipleMarketsModal";

class PoolMarketsPanel extends Component {
  constructor(props) {
    super(props);

    !localStorage.getItem("isPmpDisplayed") && localStorage.setItem("isPmpDisplayed", true);

    !localStorage.getItem("gammaPool") && localStorage.setItem("gammaPool", false);
    !localStorage.getItem("vegaPool") && localStorage.setItem("vegaPool", false);
    !localStorage.getItem("thetaPool") && localStorage.setItem("thetaPool", false);
    !localStorage.getItem("lotsPool") && localStorage.setItem("lotsPool", true);
    !localStorage.getItem("IVPool") && localStorage.setItem("IVPool", true);
    !localStorage.getItem("deltaPool") && localStorage.setItem("deltaPool", true);
    !localStorage.getItem("openPool") && localStorage.setItem("openPool", true);
    !localStorage.getItem("volPool") && localStorage.setItem("volPool", true);

    this.state = {
      isTradingModalOpen: false,
      isCreateMarketModalOpen: false,
      market: null,
      isChecked: {
        gammaPool: localStorage.getItem("gammaPool") !== "false",
        vegaPool: localStorage.getItem("vegaPool") === "true",
        thetaPool: localStorage.getItem("thetaPool") !== "false",
        lotsPool: localStorage.getItem("lotsPool") !== "false",
        IVPool: localStorage.getItem("IVPool") !== "false",
        deltaPool: localStorage.getItem("deltaPool") !== "false",
        openPool: localStorage.getItem("openPool") !== "false",
        volPool: localStorage.getItem("volPool") !== "false"
      },
      cogModalPositionTop: "0px",
      cogModalPositionRight: "0px",
      marketDepths: [],
      marketGreeks: [],
      panelIsDisplayed: localStorage.getItem("isPmpDisplayed") === "true",
      tooltipIsVisible: false,
      tooltipTarget: "",
      tooltipContent: "",
      priceIsUp: true,
      lightUpMarketIds: new Set() // this is for indication which markets rows should be light up
    };

    this.previosUnderlyingTokenPrice = createRef();
    this.previosMarkets = createRef();
    this.idPrefix = "pool-markets-panel";
  }

  componentDidMount() {
    this.marketWorker = new MarketWorker();

    this.marketWorker.onmessage = event => {
      const newMarketDepths = event.data.newMarketDepths;
      const newMarketGreeks = event.data.marketGreeks;
      this.setState({ marketDepths: newMarketDepths, marketGreeks: newMarketGreeks });
    };

    if (this.props.marketsLoaded && this.props.underlyingTokenPrice) {
      this.calculateMarketDepth();
    }

    console.log("Navigate to: " + window.location.pathname); // eslint-disable-line no-console
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Pool" });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.isTradingModalOpen && (nextProps.markets !== this.props.markets || nextProps.openedLPPositions !== this.props.openedLPPositions)) {
      return false;
    }
    if (!this.props.marketsLoaded && !nextProps.marketsLoaded) {
      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    // update selected market
    if (this.state.market != null) {
      const selectedMarket = this.props.markets.find(m => m.marketId === this.state.market.marketId);
      if (selectedMarket != null && selectedMarket !== this.state.market) {
        this.setState({ market: selectedMarket });
      }
    }

    if (prevProps.underlyingTokenPrice !== this.props.underlyingTokenPrice) {
      const prevUnderPrice = parseFloat(prevProps.underlyingTokenPrice.normalized);
      const currentUnderPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
      const priceIsUp = currentUnderPrice > prevUnderPrice;
      if (this.state.priceIsUp !== priceIsUp) this.setState({ priceIsUp });
    }

    if (!marketsEqualByLongPrice(prevProps.markets, this.props.markets)) {
      const lightUpMarketIds = new Set(
        this.props.markets
          .filter(market => {
            const prevMarket = prevProps.markets.find(prevMarket => market.marketId == prevMarket.marketId);

            if (!prevMarket) return false;

            const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
            const openPriceDiff = printAskPrice(prevMarket, underPrice) !== printAskPrice(market, underPrice);
            const closePriceDiff = printBidPrice(prevMarket, underPrice) !== printBidPrice(market, underPrice);

            if (openPriceDiff || closePriceDiff) {
              return true;
            }

            return false;
          })
          .map(market => market.marketId)
      );
      this.setState({ lightUpMarketIds });
      let timeoutId = setTimeout(() => {
        this.setState({ lightUpMarketIds: new Set() });
        clearTimeout(timeoutId);
      }, 1500);
    }

    // update depths when markets changed
    if (prevProps.markets !== this.props.markets || prevState.isTradingModalOpen !== this.state.isTradingModalOpen) {
      if (!this.state.isTradingModalOpen) {
        this.calculateMarketDepth();
      }
    }

    // update depths when under changed
    if (prevProps.underlyingTokenPrice !== this.props.underlyingTokenPrice || prevState.isTradingModalOpen !== this.state.isTradingModalOpen) {
      if (!this.state.isTradingModalOpen) {
        this.calculateMarketDepth();
      }
    }
  }

  componentWillUnmount() {
    this.marketWorker.terminate();
  }

  calculateMarketDepth = () => {
    // calculate +5% and -5%, and update state
    if (this.props.marketsLoaded && this.props.underlyingTokenPrice) {
      let marketsToWorker = this.props.markets.map(market => {
        const { marketId, isCall, openLongPrice, closeLongPrice, longPrice, marketHelper, priceOracle, normalizedStrikePrice, expirationTime } = market;
        return {
          marketId,
          isCall,
          openLongPrice,
          closeLongPrice,
          longPrice,
          marketHelper,
          priceOracle,
          normalizedStrikePrice,
          expirationTime
        };
      });
      const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
      this.marketWorker.postMessage({ markets: marketsToWorker, underPrice });
    }
  };

  handleClick = (event, isCogMarketsModalOpened, setCogMarketsModalOpen) => {
    const modalIsOpen = !isCogMarketsModalOpened;
    setCogMarketsModalOpen(modalIsOpen);

    if (modalIsOpen) {
      event.stopPropagation();
      document.querySelector("body").classList.toggle("scrollDisabled");
      let { top } = event.currentTarget.getBoundingClientRect();
      const { width } = event.currentTarget.closest(".card-header").getBoundingClientRect();
      const right = (window.innerWidth - width) / 2;
      const height = window.innerHeight;
      const { bottom: cogBottom } = event.target.getBoundingClientRect();
      cogBottom + 197 > height ? (top -= 300) : (top -= 38);
      setCogMarketsModalOpen(modalIsOpen);
      this.setState({ cogModalPositionTop: top, cogModalPositionRight: right });
    }
  };

  handleMarketRowMouseOver = (e, callMarket, putMarket) => {
    if (
      e.target.className.includes &&
      (e.target.className.includes("strike-column") ||
        e.target.className.includes("strikePriceWrapper") ||
        e.target.className.includes("priceUpDownImg") ||
        e.target.className.includes("priceUpGreenImg") ||
        e.target.className.includes("strikePriceText") ||
        e.target.className.includes("priceDownRedImg") ||
        !!e.currentTarget.querySelector("td > svg"))
    ) {
      return;
    }

    let optionsIncrement = 0;
    OPTIONS_TABLE_SETTINGS_POOL.forEach(element => {
      if (localStorage.getItem(element) === "true") {
        optionsIncrement = optionsIncrement + 1;
      }
    });
    $(".market-column").removeClass("market-hover");
    $(".market-column").removeClass("hasMarketRowHoverColor");
    $(".strike-column").removeClass("market-hover");
    $(".loader").removeClass("market-hover");
    let childNode = e.target;
    let childNodeIndex = Array.prototype.indexOf.call(e.target.parentNode.children, childNode);

    let nodesForIteration = null;
    if (e.target.nodeName === "svg") {
      nodesForIteration = e.target.parentNode.parentNode.childNodes;
    } else {
      nodesForIteration = e.target.parentNode.childNodes;
    }

    nodesForIteration.forEach(node => {
      if (node.className.includes && node.className.includes("strike-column")) {
        return;
      }

      let currentNodeIndex = Array.prototype.indexOf.call(e.target.parentNode.children, node);
      if (childNodeIndex <= 4 + optionsIncrement && currentNodeIndex <= 4 + optionsIncrement && callMarket) {
        let currentClass = node.getAttribute("class");

        if (node.nodeName !== "path" && node.nodeName !== "svg") {
          const hoverClass = node.className.includes("hasMarketRowColor") ? " hasMarketRowHoverColor" : " market-hover";
          node.setAttribute("class", currentClass + hoverClass);
        }
      }
      if (childNodeIndex >= 5 + optionsIncrement && currentNodeIndex >= 5 + optionsIncrement && putMarket) {
        let currentClass = node.getAttribute("class");

        if (node.nodeName !== "path" && node.nodeName !== "svg") {
          const hoverClass = node.className.includes("hasMarketRowColor") ? " hasMarketRowHoverColor" : " market-hover";
          node.setAttribute("class", currentClass + hoverClass);
        }
      }
    });
  };

  toggleTradingModal = (market = {}) => {
    // check if market is market object, not some object from UI
    if (market != null && market.isCall != null && market.isSettled != null) {
      this.setState({ market: market });
    }
    document.querySelector("body").classList.toggle("scrollDisabled");
    this.setState({ isTradingModalOpen: !this.state.isTradingModalOpen });
  };

  toggleCreateMarketModal = () => {
    document.querySelector("body").classList.toggle("scrollDisabled");
    this.setState({ isCreateMarketModalOpen: !this.state.isCreateMarketModalOpen });
  };

  printIV = market => {
    if (isMarketEmpty(market.marketHelper) || market.isFuture) return "-";

    if (currentPriceIsInGap(logBase(1.0001, market.longPriceInVol), market.marketHelper.ticks)) return "-";
    return formatValue(parseFloat(market.longPriceInVol), 1) + "%";
  };

  printDelta = market => {
    if (isMarketEmpty(market.marketHelper)) return "";

    if (this.props.underlyingTokenPrice != null && this.state.marketGreeks[market.marketId]) {
      const delta = this.state.marketGreeks[market.marketId].delta;
      return formatValue(delta, 2);
    }
    return displayLoadingIconOld();
  };

  printGamma = market => {
    if (isMarketEmpty(market.marketHelper)) return "";

    if (this.props.underlyingTokenPrice != null && this.state.marketGreeks[market.marketId]) {
      const gamma = this.state.marketGreeks[market.marketId].gamma;
      return formatValue(gamma, 2);
    }

    return displayLoadingIconOld();
  };

  printVega = market => {
    if (isMarketEmpty(market.marketHelper)) return "";

    if (this.props.underlyingTokenPrice && this.state.marketGreeks[market.marketId]) {
      const vega = this.state.marketGreeks[market.marketId].vega;
      return formatValue(vega, 2);
    }

    return displayLoadingIconOld();
  };

  printTheta = market => {
    if (isMarketEmpty(market.marketHelper)) return "";

    if (this.props.underlyingTokenPrice && this.state.marketGreeks[market.marketId]) {
      const theta = this.state.marketGreeks[market.marketId].theta;
      return formatValue(theta, 2);
    }

    return displayLoadingIconOld();
  };

  printLP = market => {
    if (this.props.openedLPPositions) {
      const positions = this.props.openedLPPositions.filter(position => {
        return market.marketId === position.marketId;
      });

      let balance = 0;
      let lots = 0;
      for (let position of positions) {
        balance += position.sizeInLongs;
        lots += (position.liqPrice[1] - position.openLiqPrice[1]) * position.balance;
      }
      if (Number(balance.toFixed(0)) === 0) {
        return "";
      }

      return formatValue(lots, 0);
    }

    return displayLoadingIconOld();
  };

  lpPositionOnMarket = market => {
    if (this.props.openedLPPositions && market) {
      return this.props.openedLPPositions.find(position => {
        return market.marketId === position.marketId;
      });
    }
    return null;
  };

  getMaxLongAmount = (market, isBuy) => {
    if (isBuy) {
      return this.state.marketDepths[market.marketId].plusFive;
    } else {
      return this.state.marketDepths[market.marketId].minusFive;
    }
  };

  printBidSize = market => {
    if (isMarketEmpty(market.marketHelper) && !market.isFuture) return "-";

    if (market.isFuture) {
      return formatValue(122.4, 1); // todo: v1 depths
    }

    return this.state.marketDepths[market.marketId] ? formatValue(Number(this.getMaxLongAmount(market, false)), 1) : displayLoadingIconOld();
  };

  printAskSize = market => {
    if (isMarketEmpty(market.marketHelper) && !market.isFuture) return "-";

    if (market.isFuture) {
      return formatValue(131.2, 1);
    }

    return this.state.marketDepths[market.marketId] ? formatValue(Number(this.getMaxLongAmount(market, true)), 1) : displayLoadingIconOld();
  };

  printVolume = market => {
    const historicalData = this.props.marketsHistoricalData.get(market.marketId);
    if (!historicalData) {
      return displayLoadingIconOld();
    }
    let volume = 0;
    let dayAgo = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
    let dayAgoTimestamp = Math.floor(dayAgo.getTime() / 1000);
    for (let i = 0; i < historicalData.trades.length; i++) {
      if (historicalData.trades[i].timestamp > dayAgoTimestamp) {
        volume += historicalData.trades[i].optionAmount;
      }
    }

    if (Number(volume.toFixed(0)) === 0) {
      return "";
    }
    return formatValue(volume, 0);
  };

  printOpenInterest = market => {
    const historicalData = this.props.marketsHistoricalData.get(market.marketId);
    if (!historicalData) {
      return displayLoadingIconOld();
    }

    // sum up market maker created options supply
    let marketMakerSupply = 0;
    for (let i = 0; i < historicalData.lpTrades.length; i++) {
      let lpTrade = historicalData.lpTrades[i];
      marketMakerSupply += (lpTrade.isBuy ? 1 : -1) * lpTrade.optionAmount;
    }

    // sum up short traders created options supply
    let shortSupply = 0;
    for (let i = 0; i < historicalData.trades.length; i++) {
      let trade = historicalData.trades[i];
      if (trade.type === "short") {
        shortSupply += (trade.isBuy ? -1 : 1) * trade.optionAmount;
      }
    }

    let openInterest = 0;
    const totalSupply = marketMakerSupply + shortSupply;
    if (!market.isFuture) {
      const optionsOnMarket = getMaxLongAmountWhenBuyingOptions(market.marketHelper);
      openInterest = totalSupply - Math.min(marketMakerSupply, optionsOnMarket);
    }

    if (Number(openInterest.toFixed(0)) === 0) {
      return "";
    }
    return formatValue(openInterest, 0);
  };

  //   // TODO: v1 consider extracting the following method into separate component
  printMarketsWithSameExpTime = (markets, futurePrice, closeAllFilterDropmenus) => {
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    let strikePrices = Object.keys(markets);
    strikePrices = strikePrices.sort((a, b) => {
      return a - b;
    });
    return strikePrices.map((strikePrice, index) => {
      if (markets[strikePrice].length < 1 || markets[strikePrice].length > 2) {
        // eslint-disable-next-line no-console
        console.error("ERROR: markets with same expiration time have more than 2 markets");
        handleNewError({}, "Error while printing markets with same expiration time. Please reload the application!", 1, this.props.dispatch);
      }
      const isFuture = markets[strikePrice][0].isFuture;

      if (isFuture) return null;

      const callMarket = markets[strikePrice].find(market => market.isCall);
      const putMarket = markets[strikePrice].find(market => !market.isCall);
      const printableStrikePrice = markets[strikePrice][0].printableStrikePrice;

      const lpCallPosition = this.lpPositionOnMarket(callMarket);
      const lpPutPosition = this.lpPositionOnMarket(putMarket);

      const shouldHaveStrikeBorderOnCall = futurePrice - Number(strikePrice) > 0;

      const shouldHaveLightUpCall = callMarket && !isMarketEmpty(callMarket.marketHelper) && this.state.lightUpMarketIds.has(callMarket.marketId);
      const shouldHaveLightUpPut = putMarket && !isMarketEmpty(putMarket.marketHelper) && this.state.lightUpMarketIds.has(putMarket.marketId);

      let callGreen = null;
      let putGreen = null;
      if (shouldHaveLightUpCall) {
        if (this.state.priceIsUp) {
          callGreen = true;
        } else {
          callGreen = false;
        }
      }

      if (shouldHaveLightUpPut) {
        if (this.state.priceIsUp) {
          putGreen = false;
        } else {
          putGreen = true;
        }
      }

      return (
        <tr
          key={strikePrice}
          className="market-row"
          onMouseOver={e => this.handleMarketRowMouseOver(e, callMarket, putMarket)}
          onMouseOut={e => {
            $(".market-column").removeClass("market-hover");
            $(".market-column").removeClass("hasMarketRowHoverColor");
            $(".strike-column").removeClass("market-hover");
            $(".loader").removeClass("market-hover");
          }}
          onClick={e => {
            e.stopPropagation();
            closeAllFilterDropmenus();
            if (
              e.target.classList.contains("strike-column") ||
              (!e.target.classList.contains("market-hover") && !e.target.classList.contains("hasMarketRowHoverColor"))
            ) {
              return;
            }
            let strikePriceTd = Array.from(e.target.parentNode.children).findIndex(el => el === e.target.parentNode.querySelector(".strike-column"));
            if (!e.target.parentNode.querySelector("svg")) {
              if (Array.from(e.target.parentNode.children).indexOf(e.target) < strikePriceTd) {
                this.toggleTradingModal(callMarket);
              } else {
                this.toggleTradingModal(putMarket);
              }
            }
          }}
        >
          {this.state.isChecked.openPool ? (
            <td
              className={`market-column${shouldHaveLightUpCall ? (callGreen ? " animateCellGreen" : " animateCellRed") : ""}${
                shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
              }`}
            >
              {callMarket ? this.printOpenInterest(callMarket) : ""}
            </td>
          ) : null}
          {this.state.isChecked.volPool ? (
            <td
              className={`market-column${shouldHaveLightUpCall ? (callGreen ? " animateCellGreen" : " animateCellRed") : ""}${
                shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
              }`}
            >
              {callMarket ? this.printVolume(callMarket) : ""}
            </td>
          ) : null}
          <td
            className={`market-column${shouldHaveLightUpCall ? (callGreen ? " animateCellGreen" : " animateCellRed") : ""}${
              shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
            }`}
          >
            {callMarket ? this.printBidSize(callMarket) : ""}
          </td>
          <td
            className={`market-column bidHasMarketColor${shouldHaveLightUpCall ? (callGreen ? " animateCellGreen" : " animateCellRed") : ""}${
              shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
            }`}
          >
            {callMarket ? printBidPrice(callMarket, underPrice) : ""}
          </td>
          <td
            className={`market-column askHasMarketColor${shouldHaveLightUpCall ? (callGreen ? " animateCellGreen" : " animateCellRed") : ""}${
              shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
            }`}
          >
            {callMarket ? printAskPrice(callMarket, underPrice) : ""}
          </td>
          <td
            className={`market-column${shouldHaveLightUpCall ? (callGreen ? " animateCellGreen" : " animateCellRed") : ""}${
              shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
            }`}
          >
            {callMarket ? this.printAskSize(callMarket) : ""}
          </td>
          {this.state.isChecked.IVPool ? (
            <td
              className={`market-column${shouldHaveLightUpCall ? (callGreen ? " animateCellGreen" : " animateCellRed") : ""}${
                shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
              }`}
            >
              {callMarket ? this.printIV(callMarket) : ""}
            </td>
          ) : null}
          {this.state.isChecked.deltaPool ? (
            <td
              className={`market-column${shouldHaveLightUpCall ? (callGreen ? " animateCellGreen" : " animateCellRed") : ""}${
                shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
              }`}
            >
              {callMarket ? this.printDelta(callMarket) : ""}
            </td>
          ) : null}
          {this.state.isChecked.gammaPool ? (
            <td
              className={`market-column${shouldHaveLightUpCall ? (callGreen ? " animateCellGreen" : " animateCellRed") : ""}${
                shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
              }`}
            >
              {callMarket ? this.printGamma(callMarket) : ""}
            </td>
          ) : null}
          {this.state.isChecked.thetaPool ? (
            <td
              className={`market-column${shouldHaveLightUpCall ? (callGreen ? " animateCellGreen" : " animateCellRed") : ""}${
                shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
              }`}
            >
              {callMarket ? this.printTheta(callMarket) : ""}
            </td>
          ) : null}
          {this.state.isChecked.vegaPool ? (
            <td
              className={`market-column${shouldHaveLightUpCall ? (callGreen ? " animateCellGreen" : " animateCellRed") : ""}${
                shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
              }`}
            >
              {callMarket ? this.printVega(callMarket) : ""}
            </td>
          ) : null}
          {this.state.isChecked.lotsPool ? (
            <td
              className={`market-column${shouldHaveLightUpCall ? (callGreen ? " animateCellGreen" : " animateCellRed") : ""}${
                lpCallPosition && lpCallPosition.type === "lp" ? " lpMarketColor" : ""
              }${shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""}`}
            >
              {callMarket ? this.printLP(callMarket) : ""}
            </td>
          ) : null}
          <td className={`strike-column${shouldHaveStrikeBorderOnCall ? " strikeCallHasMarketBorder" : " strikePutHasMarketBorder"}`}>
            <div className="strikePriceWrapper">
              <img className={`priceUpDownImg${shouldHaveLightUpCall ? (this.state.priceIsUp ? " priceUpGreenImg" : " priceDownRedImg") : ""}`} />
              <span className="strikePriceText">{printableStrikePrice}</span>{" "}
              <img className={`priceUpDownImg${shouldHaveLightUpPut ? (this.state.priceIsUp ? " priceDownRedImg" : " priceUpGreenImg") : ""}`} />
            </div>
          </td>
          {this.state.isChecked.lotsPool ? (
            <td
              className={`market-column${shouldHaveLightUpPut ? (putGreen ? " animateCellGreen" : " animateCellRed") : ""}${
                lpPutPosition && lpPutPosition.type === "lp" ? " lpMarketColor" : ""
              }${!shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""}`}
            >
              {putMarket ? this.printLP(putMarket) : ""}
            </td>
          ) : null}
          <td
            className={`market-column${shouldHaveLightUpPut ? (putGreen ? " animateCellGreen" : " animateCellRed") : ""}${
              !shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
            }`}
          >
            {putMarket ? this.printBidSize(putMarket) : ""}
          </td>
          <td
            className={`market-column bidHasMarketColor${shouldHaveLightUpPut ? (putGreen ? " animateCellGreen" : " animateCellRed") : ""}${
              !shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
            }`}
          >
            {putMarket ? printBidPrice(putMarket, underPrice) : ""}
          </td>
          <td
            className={`market-column askHasMarketColor${shouldHaveLightUpPut ? (putGreen ? " animateCellGreen" : " animateCellRed") : ""}${
              !shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
            }`}
          >
            {putMarket ? printAskPrice(putMarket, underPrice) : ""}
          </td>
          <td
            className={`market-column${shouldHaveLightUpPut ? (putGreen ? " animateCellGreen" : " animateCellRed") : ""}${
              !shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
            }`}
          >
            {putMarket ? this.printAskSize(putMarket) : ""}
          </td>
          {this.state.isChecked.IVPool ? (
            <td
              className={`market-column${shouldHaveLightUpPut ? (putGreen ? " animateCellGreen" : " animateCellRed") : ""}${
                !shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
              }`}
            >
              {putMarket ? this.printIV(putMarket) : ""}
            </td>
          ) : null}
          {this.state.isChecked.deltaPool ? (
            <td
              className={`market-column${shouldHaveLightUpPut ? (putGreen ? " animateCellGreen" : " animateCellRed") : ""}${
                !shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
              }`}
            >
              {putMarket ? this.printDelta(putMarket) : ""}
            </td>
          ) : null}
          {this.state.isChecked.gammaPool ? (
            <td
              className={`market-column${shouldHaveLightUpPut ? (putGreen ? " animateCellGreen" : " animateCellRed") : ""}${
                !shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
              }`}
            >
              {putMarket ? this.printGamma(putMarket) : ""}
            </td>
          ) : null}
          {this.state.isChecked.thetaPool ? (
            <td
              className={`market-column${shouldHaveLightUpPut ? (putGreen ? " animateCellGreen" : " animateCellRed") : ""}${
                !shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
              }`}
            >
              {putMarket ? this.printTheta(putMarket) : ""}
            </td>
          ) : null}
          {this.state.isChecked.vegaPool ? (
            <td
              className={`market-column${shouldHaveLightUpPut ? (putGreen ? " animateCellGreen" : " animateCellRed") : ""}${
                !shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
              }`}
            >
              {putMarket ? this.printVega(putMarket) : ""}
            </td>
          ) : null}
          {this.state.isChecked.volPool ? (
            <td
              className={`market-column${shouldHaveLightUpPut ? (putGreen ? " animateCellGreen" : " animateCellRed") : ""}${
                !shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
              }`}
            >
              {putMarket ? this.printVolume(putMarket) : ""}
            </td>
          ) : null}
          {this.state.isChecked.openPool ? (
            <td
              className={`market-column${shouldHaveLightUpPut ? (putGreen ? " animateCellGreen" : " animateCellRed") : ""}${
                !shouldHaveStrikeBorderOnCall ? " hasMarketRowColor" : ""
              }`}
            >
              {putMarket ? this.printOpenInterest(putMarket) : ""}
            </td>
          ) : null}
        </tr>
      );
    });
  };

  groupMarkets = markets => {
    let readableExpTimes = {};
    markets = groupBy(markets, "expirationTime");
    let expTimes = Object.keys(markets);
    expTimes = expTimes.sort((a, b) => {
      return a - b;
    });

    for (let expTime of expTimes) {
      readableExpTimes[expTime] = markets[expTime][0].readableExpirationTime;
      markets[expTime] = groupBy(markets[expTime], "strikePrice");
    }

    return {
      expTimes: expTimes,
      groupedMarkets: markets,
      readableExpTimes: readableExpTimes
    };
  };

  areMarketsLoaded = () => {
    return (
      this.props.web3AccountLoaded &&
      this.props.marketsLoaded &&
      this.props.selectedTokenPair &&
      this.props.selectedExpirationTime &&
      Object.keys(this.props.selectedExpirationTime).length &&
      this.props.currentTimestamp &&
      this.props.underlyingTokenPrice != null
    );
  };

  printTimeUntilExpiration = (expTime, index) => {
    return (
      <Timer
        formatValue={value => `${value < 10 ? `0${value}` : value} units `}
        initialTime={(expTime - moment(new Date()).unix()) * 1000}
        direction="backward"
      >
        {() => (
          <React.Fragment>
            <div id={this.idPrefix + "time-to-expiration" + index}>
              <Timer.Days formatValue={value => `${value} days `} />
              <Timer.Hours formatValue={value => `${value} hours `} />
            </div>
            <MyTooltip key={this.idPrefix + "time-to-expiration" + index} target={this.idPrefix + "time-to-expiration" + index} optionalOffset={15}>
              Time until expiration
            </MyTooltip>
          </React.Fragment>
        )}
      </Timer>
    );
  };

  printMarketsTable = closeAllFilterDropmenus => {
    const filteredMarkets = activeMarketsFiltered(
      this.props.markets,
      this.props.selectedTokenPair,
      this.props.selectedExpirationTime,
      this.props.expirationTimes
    );

    if (filteredMarkets.length === 0 && this.props.web3AccountLoaded) {
      return (
        <Row style={{ height: "200px" }}>
          <Col className="mb-1" style={{ textAlign: "center", color: "#ffffff", opacity: 0.8, alignSelf: "center" }} md="12">
            <span className="emptyTableMessage">No existing market for {this.props.selectedExpirationTime.sidebarFormat}.</span>
          </Col>
        </Row>
      );
    }
    let resultOptions = this.groupMarkets(filteredMarkets.filter(market => !market.isFuture));
    return (
      <>
        <span className="futureOptionText" style={{ padding: "15px" }}>
          OPTIONS
        </span>
        {resultOptions.expTimes.length ? (
          resultOptions.expTimes.map((expTime, index) => {
            const { printedUnderlyingFuturePrice, futurePrice } = printUnderlyingFuturePrice(
              expTime,
              resultOptions.readableExpTimes[expTime],
              this.props.selectedTokenPair,
              this.props.underlyingTokenPrice
            );
            const timeUntilExpiration = this.printTimeUntilExpiration(expTime, index);

            return (
              <>
                <div key={expTime}>
                  <Row style={{ marginTop: "20px" }}>
                    <Col md="2">
                      <div
                        id={this.idPrefix + "future-price" + index}
                        style={{ width: "100%", textAlign: "left", color: "#ffffff", opacity: 0.8, paddingLeft: "15px" }}
                      >
                        {printedUnderlyingFuturePrice}
                      </div>
                      <MyTooltip key={this.idPrefix + "future-price" + index} target={this.idPrefix + "future-price" + index} optionalOffset={15}>
                        Future price is ETH price with risk-free rate applied until expiration.
                      </MyTooltip>
                    </Col>
                    <Col md="3">
                      <div style={{ textAlign: "center", color: "#0ABAE7", fontWeight: "500", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.03em" }}>
                        <span>CALLS</span>
                      </div>
                    </Col>
                    <Col md="2">
                      <h4
                        className="mb-0"
                        style={{
                          width: "100%",
                          textAlign: "center",
                          color: "#8FCE51",
                          fontWeight: "300",
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.03em"
                        }}
                      >
                        {resultOptions.readableExpTimes[expTime]}
                      </h4>
                    </Col>
                    <Col md="3">
                      <div style={{ textAlign: "center", color: "#0ABAE7", fontWeight: "500", fontSize: "16px", lineHeight: "24px", letterSpacing: "0.03em" }}>
                        <span>PUTS</span>
                      </div>
                    </Col>
                    <Col md="2">
                      <div
                        style={{
                          width: "100%",
                          textAlign: "right",
                          color: "#ffffff",
                          opacity: 0.8,
                          fontWeight: "275",
                          fontSize: "16px",
                          lineHeight: "24px",
                          letterSpacing: "0.03em"
                        }}
                      >
                        {timeUntilExpiration}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "20px" }}>
                    <Col md="12">
                      <table className="table" style={{ width: "100%", tableLayout: "fixed" }}>
                        <thead>
                          <tr>
                            {this.state.isChecked.openPool ? (
                              <th scope="col" className="columnHeading">
                                <span id={"open_call" + index} style={{ padding: "12px 0px" }}>
                                  OPEN
                                </span>
                              </th>
                            ) : null}
                            {this.state.isChecked.volPool ? (
                              <th scope="col" className="columnHeading">
                                <span id={"vol_call" + index} style={{ padding: "12px 0px" }}>
                                  VOL
                                </span>
                              </th>
                            ) : null}
                            <th scope="col" className="columnHeading">
                              <span id={"size_bid_call" + index} style={{ padding: "12px 0px" }}>
                                -5%
                              </span>
                            </th>
                            <th scope="col" className="columnHeading bidColumnWidth">
                              <span id={"bid_call" + index} style={{ padding: "12px 0px" }}>
                                BID
                              </span>
                            </th>
                            <th scope="col" className="columnHeading askColumnWidth">
                              <span id={"ask_call" + index} style={{ padding: "12px 0px" }}>
                                ASK
                              </span>
                            </th>
                            <th scope="col" className="columnHeading">
                              <span id={"size_ask_call" + index} style={{ padding: "12px 0px" }}>
                                +5%
                              </span>
                            </th>
                            {this.state.isChecked.IVPool ? (
                              <th scope="col" className="columnHeading">
                                <span id={"iv_call" + index} style={{ padding: "12px 0px" }}>
                                  IV
                                </span>
                              </th>
                            ) : null}
                            {this.state.isChecked.deltaPool ? (
                              <th scope="col" className="columnHeading">
                                <span id={"delta_call" + index} style={{ padding: "12px 0px" }}>
                                  &Delta;
                                </span>
                              </th>
                            ) : null}
                            {this.state.isChecked.gammaPool ? (
                              <th scope="col" className="columnHeading">
                                <span id={"gamma_call" + index} style={{ textTransform: "lowercase", padding: "12px 0px" }}>
                                  &gamma;
                                </span>
                              </th>
                            ) : null}
                            {this.state.isChecked.thetaPool ? (
                              <th scope="col" className="columnHeading">
                                <span id={"theta_call" + index} style={{ padding: "12px 0px" }}>
                                  &Theta;
                                </span>
                              </th>
                            ) : null}
                            {this.state.isChecked.vegaPool ? (
                              <th scope="col" className="columnHeading">
                                <span id={"vega_call" + index} style={{ padding: "12px 0px" }}>
                                  V
                                </span>
                              </th>
                            ) : null}
                            {this.state.isChecked.lotsPool ? (
                              <th scope="col" className="columnHeading">
                                <span id={"lp_call" + index} style={{ padding: "12px 0px" }}>
                                  QTY
                                </span>
                              </th>
                            ) : null}
                            <th scope="col" className="strikeHeading">
                              STRIKE
                            </th>
                            {this.state.isChecked.lotsPool ? (
                              <th scope="col" className="columnHeading">
                                <span id={"lp_put" + index} style={{ padding: "12px 0px" }}>
                                  QTY
                                </span>
                              </th>
                            ) : null}
                            <th scope="col" className="columnHeading">
                              <span id={"size_bid_put" + index} style={{ padding: "12px 0px" }}>
                                -5%
                              </span>
                            </th>
                            <th scope="col" className="columnHeading bidColumnWidth">
                              <span id={"bid_put" + index} style={{ padding: "12px 0px" }}>
                                BID
                              </span>
                            </th>
                            <th scope="col" className="columnHeading askColumnWidth">
                              <span id={"ask_put" + index} style={{ padding: "12px 0px" }}>
                                ASK
                              </span>
                            </th>
                            <th scope="col" className="columnHeading">
                              <span id={"size_ask_put" + index} style={{ padding: "12px 0px" }}>
                                +5%
                              </span>
                            </th>
                            {this.state.isChecked.IVPool ? (
                              <th scope="col" className="columnHeading">
                                <span id={"iv_put" + index} style={{ padding: "12px 0px" }}>
                                  IV
                                </span>
                              </th>
                            ) : null}
                            {this.state.isChecked.deltaPool ? (
                              <th scope="col" className="columnHeading">
                                <span id={"delta_put" + index} style={{ padding: "12px 0px" }}>
                                  &Delta;
                                </span>
                              </th>
                            ) : null}
                            {this.state.isChecked.gammaPool ? (
                              <th scope="col" className="columnHeading">
                                <span id={"gamma_put" + index} style={{ textTransform: "lowercase", padding: "12px 0px" }}>
                                  &gamma;
                                </span>
                              </th>
                            ) : null}
                            {this.state.isChecked.thetaPool ? (
                              <th scope="col" className="columnHeading">
                                <span id={"theta_put" + index} style={{ padding: "12px 0px" }}>
                                  &Theta;
                                </span>
                              </th>
                            ) : null}
                            {this.state.isChecked.vegaPool ? (
                              <th scope="col" className="columnHeading">
                                <span id={"vega_put" + index} style={{ padding: "12px 0px" }}>
                                  V
                                </span>
                              </th>
                            ) : null}
                            {this.state.isChecked.volPool ? (
                              <th scope="col" className="columnHeading">
                                <span id={"vol_put" + index} style={{ padding: "12px 0px" }}>
                                  VOL
                                </span>
                              </th>
                            ) : null}
                            {this.state.isChecked.openPool ? (
                              <th scope="col" className="columnHeading">
                                <span id={"open_put" + index} style={{ padding: "12px 0px" }}>
                                  OPEN
                                </span>
                              </th>
                            ) : null}
                          </tr>
                        </thead>
                        {this.state.isChecked.openPool ? (
                          <MyTooltip key={"open_call" + index} target={"open_call" + index}>
                            Open interest is the total amount of outstanding option tokens.
                          </MyTooltip>
                        ) : null}
                        {this.state.isChecked.volPool ? (
                          <MyTooltip key={"vol_call" + index} target={"vol_call" + index}>
                            Trading volume over the last 24hrs.
                          </MyTooltip>
                        ) : null}
                        <MyTooltip key={"size_bid_call" + index} target={"size_bid_call" + index}>
                          -5% is the amount of options you can sell with up to 5% slippage. <br /> <br />
                          The higher the number, the more liquidity the market has.{" "}
                        </MyTooltip>
                        <MyTooltip key={"bid_call" + index} target={"bid_call" + index}>
                          Highest bid on the market.
                        </MyTooltip>
                        <MyTooltip key={"ask_call" + index} target={"ask_call" + index}>
                          Lowest ask on the market.
                        </MyTooltip>
                        <MyTooltip key={"size_ask_call" + index} target={"size_ask_call" + index}>
                          +5% is the amount of options you can buy with up to 5% slippage. <br /> <br />
                          The higher the number, the more liquidity the market has.{" "}
                        </MyTooltip>
                        {this.state.isChecked.IVPool ? (
                          <MyTooltip key={"iv_call" + index} target={"iv_call" + index}>
                            Implied volatility of the median price.
                          </MyTooltip>
                        ) : null}
                        {this.state.isChecked.deltaPool ? (
                          <MyTooltip key={"delta_call" + index} target={"delta_call" + index}>
                            Delta (&Delta;) represents the rate of change of option's price relative to underlying price change.
                          </MyTooltip>
                        ) : null}
                        {this.state.isChecked.gammaPool ? (
                          <MyTooltip key={"gamma_call" + index} target={"gamma_call" + index}>
                            Gamma (&gamma;) represents the rate of change of the Delta (&Delta;) relative to underlying price change.
                          </MyTooltip>
                        ) : null}
                        {this.state.isChecked.thetaPool ? (
                          <MyTooltip key={"theta_call" + index} target={"theta_call" + index}>
                            Theta (&Theta;) represents the rate of change of the option's price relative to time, also known as an option's time decay.
                          </MyTooltip>
                        ) : null}
                        {this.state.isChecked.vegaPool ? (
                          <MyTooltip key={"vega_call" + index} target={"vega_call" + index}>
                            Vega (V) represents the rate of change of the option's price relative to option's implied volatility (IV) change, also known as
                            option's sensitivity to implied volatility.
                          </MyTooltip>
                        ) : null}
                        {this.state.isChecked.lotsPool ? (
                          <MyTooltip key={"lp_call" + index} target={"lp_call" + index}>
                            Size of the position in options.
                          </MyTooltip>
                        ) : null}
                        {this.state.isChecked.lotsPool ? (
                          <MyTooltip key={"lp_put" + index} target={"lp_put" + index}>
                            Size of the position in options.
                          </MyTooltip>
                        ) : null}
                        {this.state.isChecked.openPool ? (
                          <MyTooltip key={"open_put" + index} target={"open_put" + index}>
                            Open interest is the total amount of outstanding option tokens.
                          </MyTooltip>
                        ) : null}
                        {this.state.isChecked.volPool ? (
                          <MyTooltip key={"vol_put" + index} target={"vol_put" + index}>
                            Trading volume over the last 24hrs.
                          </MyTooltip>
                        ) : null}
                        <MyTooltip key={"size_bid_put" + index} target={"size_bid_put" + index}>
                          -5% is the amount of options you can sell with up to 5% slippage. <br /> <br />
                          The higher the number, the more liquidity the market has.{" "}
                        </MyTooltip>
                        <MyTooltip key={"bid_put" + index} target={"bid_put" + index}>
                          Highest bid on the market.
                        </MyTooltip>
                        <MyTooltip key={"ask_put" + index} target={"ask_put" + index}>
                          Lowest ask on the market.
                        </MyTooltip>
                        <MyTooltip key={"size_ask_put" + index} target={"size_ask_put" + index}>
                          +5% is the amount of options you can buy with up to 5% slippage. <br /> <br />
                          The higher the number, the more liquidity the market has.{" "}
                        </MyTooltip>
                        {this.state.isChecked.IVPool ? (
                          <MyTooltip key={"iv_put" + index} target={"iv_put" + index}>
                            Implied volatility of the median price.
                          </MyTooltip>
                        ) : null}
                        {this.state.isChecked.deltaPool ? (
                          <MyTooltip key={"delta_put" + index} target={"delta_put" + index}>
                            Delta (&Delta;) represents the rate of change of option's price relative to underlying price change.
                          </MyTooltip>
                        ) : null}
                        {this.state.isChecked.gammaPool ? (
                          <MyTooltip key={"gamma_put" + index} target={"gamma_put" + index}>
                            Gamma (&gamma;) represents the rate of change of the Delta (&Delta;) relative to underlying price change.
                          </MyTooltip>
                        ) : null}
                        {this.state.isChecked.thetaPool ? (
                          <MyTooltip key={"theta_put" + index} target={"theta_put" + index}>
                            Theta (&Theta;) represents the rate of change of the option's price relative to time, also known as an option's time decay.
                          </MyTooltip>
                        ) : null}
                        {this.state.isChecked.vegaPool ? (
                          <MyTooltip key={"vega_put" + index} target={"vega_put" + index}>
                            Vega (V) represents the rate of change of the option's price relative to option's implied volatility (IV) change, also known as
                            option's sensitivity to implied volatility.
                          </MyTooltip>
                        ) : null}
                        <tbody style={{ fontSize: "0.75rem" }}>
                          {this.printMarketsWithSameExpTime(resultOptions.groupedMarkets[expTime], futurePrice, closeAllFilterDropmenus)}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </div>
              </>
            );
          })
        ) : (
          <Row style={{ height: "100px" }}>
            <Col className="mb-1" style={{ textAlign: "center", color: "#ffffff", opacity: 0.8, alignSelf: "center" }} md="12">
              <span className="emptyTableMessage">No existing option market for {this.props.selectedExpirationTime.sidebarFormat}.</span>
            </Col>
          </Row>
        )}
      </>
    );
    // }
  };

  printLoadingIcon = () => {
    return (
      <Row style={{ height: "200px" }}>
        <Col className="mb-1" style={{ textAlign: "center", color: "#ffffff", opacity: 0.8, alignSelf: "center" }} md="12">
          <h1>{displayLoadingIcon()}</h1>
        </Col>
      </Row>
    );
  };

  checkHandler = e => {
    localStorage.setItem(e.target.name + "Pool", !this.state.isChecked[e.target.name + "Pool"]);
    this.setState({ isChecked: { ...this.state.isChecked, [e.target.name + "Pool"]: !this.state.isChecked[e.target.name + "Pool"] } });
  };

  handleShowHidePanel = (lsId, bodyId, arrowDownId, arrowUpId) => {
    const headerActions = document.getElementById("poolHeaderActions");

    if (localStorage.getItem(lsId) === "true") {
      localStorage.setItem(lsId, false);
      headerActions.style.justifyContent = "flex-end";
    } else if (localStorage.getItem(lsId) === "false") {
      localStorage.setItem(lsId, true);
      headerActions.style.justifyContent = "space-between";
    }
  };

  resetPmpSwitches = () => {
    localStorage.setItem("gammaPool", false);
    localStorage.setItem("vegaPool", false);
    localStorage.setItem("thetaPool", false);
    localStorage.setItem("lotsPool", true);
    localStorage.setItem("IVPool", true);
    localStorage.setItem("deltaPool", true);
    localStorage.setItem("openPool", true);
    localStorage.setItem("volPool", true);

    this.setState({
      isChecked: {
        gammaPool: false,
        vegaPool: false,
        thetaPool: false,
        lotsPool: true,
        IVPool: true,
        deltaPool: true,
        openPool: true,
        volPool: true
      }
    });
  };

  onMouseEnterColumnHeader = (e, text) => {
    if (!this.state.tooltipIsVisible) {
      this.setState({ tooltipIsVisible: true, tooltipContent: text, tooltipTarget: e.currentTarget.id });
    }
  };

  onMouseLeaveColumnHeader = e => {
    if (!cursorRangeCheck(this.state.tooltipTarget, e.clientX, e.clientY)) {
      this.setState({ tooltipIsVisible: true, tooltipContent: "", tooltipTarget: "" });
      document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipLeftPosition", -500 + "px");
    }
  };

  render() {
    const isDefaultSelected =
      this.state.isChecked.gammaPool === false &&
      this.state.isChecked.vegaPool === false &&
      this.state.isChecked.thetaPool === false &&
      this.state.isChecked.lotsPool &&
      this.state.isChecked.IVPool &&
      this.state.isChecked.deltaPool &&
      this.state.isChecked.openPool &&
      this.state.isChecked.volPool;

    const isCheckedProp = {
      gamma: this.state.isChecked.gammaPool,
      vega: this.state.isChecked.vegaPool,
      theta: this.state.isChecked.thetaPool,
      lots: this.state.isChecked.lotsPool,
      IV: this.state.isChecked.IVPool,
      delta: this.state.isChecked.deltaPool,
      open: this.state.isChecked.openPool,
      vol: this.state.isChecked.volPool
    };

    const marketsLoaded = this.areMarketsLoaded();

    return (
      <>
        <FilterContext.Consumer>
          {({ closeAllFilterDropmenus, isCogMarketsModalOpened, setCogMarketsModalOpen }) => {
            return (
              <Card>
                {this.state.isCreateMarketModalOpen ? (
                  <CreateMultipleMarketsModal isOpen={this.state.isCreateMarketModalOpen} toggle={this.toggleCreateMarketModal} />
                ) : null}
                {this.state.isTradingModalOpen && (
                  <PoolModal
                    isOpen={this.state.isTradingModalOpen}
                    toggle={this.toggleTradingModal}
                    market={this.state.market}
                    getMaxLongAmount={this.getMaxLongAmount}
                  />
                )}
                <CardHeader
                  className="handle"
                  onDoubleClick={e => {
                    e.stopPropagation();
                    closeAllFilterDropmenus();
                    this.setState({ panelIsDisplayed: !this.state.panelIsDisplayed });
                    this.handleShowHidePanel("isPmpDisplayed", "pmp", "pmpArrowDown", "pmpArrowUp");
                  }}
                >
                  <CardTitle tag="h4">
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", height: "100%", alignSelf: "center", alignItems: "center" }}>
                      <div style={{ display: "flex", gap: "15px" }}>
                        <span className="headerTitle">MARKETS</span>
                        <MarketsSelect />
                      </div>
                      <div id="poolHeaderActions" className="headerCogArrowContainer">
                        <button
                          id="newMarket"
                          disabled={!this.props.web3AccountLoaded || !marketsLoaded}
                          className={this.state.panelIsDisplayed ? "newMarket" : "newMarket hidden"}
                          onClick={event => {
                            event.stopPropagation();
                            closeAllFilterDropmenus();
                            this.toggleCreateMarketModal();
                          }}
                        >
                          CREATE NEW MARKETS
                        </button>
                        {this.state.panelIsDisplayed && (
                          <div
                            onClick={e => {
                              e.stopPropagation();
                              closeAllFilterDropmenus();
                              this.handleClick(e, isCogMarketsModalOpened, setCogMarketsModalOpen);
                            }}
                            id="cog"
                            className="cogHeaderWrapper"
                          >
                            <img id={this.idPrefix + "table-settings"} style={{ margin: "auto" }} className="cog" />
                            <MyTooltip key={this.idPrefix + "table-settings"} target={this.idPrefix + "table-settings"} optionalOffset={15}>
                              Table settings
                            </MyTooltip>
                          </div>
                        )}
                        {this.state.panelIsDisplayed && (
                          <>
                            <div
                              id="pmpArrowUp"
                              className="arrowHeaderWrapper"
                              onClick={e => {
                                e.stopPropagation();
                                closeAllFilterDropmenus();
                                this.setState({ panelIsDisplayed: false });
                                this.handleShowHidePanel("isPmpDisplayed", "pmp", "pmpArrowDown", "pmpArrowUp");
                                this.setState({ tooltipContent: "Show panel" });
                              }}
                              onMouseEnter={e => this.onMouseEnterColumnHeader(e, "Hide panel")}
                              onMouseLeave={this.onMouseLeaveColumnHeader}
                            >
                              <img style={{ margin: "auto" }} className="arrowUpIcon" />
                            </div>
                          </>
                        )}

                        {!this.state.panelIsDisplayed && (
                          <>
                            <div
                              id="pmpArrowDown"
                              className="arrowHeaderWrapper"
                              onClick={e => {
                                e.stopPropagation();
                                closeAllFilterDropmenus();
                                this.setState({ panelIsDisplayed: true });
                                this.handleShowHidePanel("isPmpDisplayed", "pmp", "pmpArrowDown", "pmpArrowUp");
                                this.setState({ tooltipContent: "Hide panel" });
                              }}
                              onMouseEnter={e => this.onMouseEnterColumnHeader(e, "Show panel")}
                              onMouseLeave={this.onMouseLeaveColumnHeader}
                            >
                              <img style={{ margin: "auto" }} className="arrowDownIcon" />
                            </div>
                          </>
                        )}
                      </div>
                      {isCogMarketsModalOpened && (
                        <CogMarketsModal
                          cogModalPositionTop={this.state.cogModalPositionTop}
                          cogModalPositionRight={this.state.cogModalPositionRight}
                          isChecked={isCheckedProp}
                          isDefault={isDefaultSelected}
                          checkHandler={this.checkHandler}
                          resetSwitches={this.resetPmpSwitches}
                          setCogMarketsModalOpen={setCogMarketsModalOpen}
                          isPool
                        />
                      )}
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody id="pmp" className={this.state.panelIsDisplayed ? "" : "displayNone"}>
                  {!marketsLoaded && this.props.web3AccountLoaded ? this.printLoadingIcon() : null}
                  {marketsLoaded ? this.printMarketsTable(closeAllFilterDropmenus) : null}
                  <ConnectPanel />
                </CardBody>
              </Card>
            );
          }}
        </FilterContext.Consumer>
        {this.state.tooltipIsVisible && (
          <MyTooltip2
            target={this.state.tooltipTarget}
            setTooltipIsVisible={visible => this.setState({ tooltipIsVisible: visible })}
            setTooltipContent={content => this.setState({ tooltipContent: content })}
            setTooltipTarget={target => this.setState({ tooltipTarget: target })}
          >
            {this.state.tooltipContent}
          </MyTooltip2>
        )}
      </>
    );
  }
}

PoolMarketsPanel.propTypes = {
  markets: PropTypes.array,
  marketsLoaded: PropTypes.bool,
  marketsHistoricalData: PropTypes.object,
  openedLPPositions: PropTypes.array,
  selectedTokenPair: PropTypes.object,
  selectedExpirationTime: PropTypes.object,
  web3AccountLoaded: PropTypes.bool,
  underlyingTokenPrice: PropTypes.object,
  currentTimestamp: PropTypes.number,
  dispatch: PropTypes.func,
  expirationTimes: PropTypes.array
};

function mapStateToProps(state) {
  return {
    markets: marketsNonExpiredSelector(state),
    marketsLoaded: marketsLoadedSelector(state),
    marketsHistoricalData: marketsHistoricalDataSelector(state),
    openedLPPositions: openedLPPositionsSelector(state),
    selectedTokenPair: selectedTokenPairSelector(state),
    selectedExpirationTime: selectedExpirationTimeSelector(state),
    web3AccountLoaded: web3AccountLoadedSelector(state),
    underlyingTokenPrice: avgUnderlyingTokenPriceSelector(state),
    currentTimestamp: currentTimestampSelector(state),
    expirationTimes: expirationTimesSelector(state)
  };
}

export default connect(mapStateToProps)(PoolMarketsPanel);
