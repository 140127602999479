import { handleNewError } from "./errorInteractions";
import {
  getNonce,
  getOpenCloseLongFuturePrice,
  getOpenCloseLongOptionPrice,
  hasTxFailed,
  onConfirmed,
  onError,
  onFailed,
  onPending,
  SECONDS_IN_YEAR,
  sleepFunc,
  toPreciseString
} from "../../utils/utils.js";
import {
  marketCreated,
  marketUpdated,
  marketPricesUpdated,
  marginAccountDataLoaded,
  marginPoolDataLoaded,
  underlyingTokenPriceLoaded,
  insuranceFundLoaded,
  insurerBalanceLoaded
} from "../actions/actions.js";
import { formatUnits } from "@ethersproject/units";
import { fromSqrtPrice, getMaxLongAmountWhenSellingOptions, insert, MarketHelper } from "../../utils/MarketHelper";
import { store } from "../configureStore";
import { addPositionToken, loadUnderlyingMarketPrice } from "./tokensInteractions";
import moment from "moment";
import { batch } from "react-redux";
import { Contract } from "@ethersproject/contracts";
import { loadInsuranceFundBalances, loadInsurerBalance } from "./insuranceFundInteractions";
import { getEventsStreamCached } from "./interactionsManager.js";
import { getMuttableMarketAttributes } from "../utils/marketsUtils.js";
import { loadMarginAccountData2, loadMarginPoolData2 } from "../utils/marginUtils.js";
import { toBn } from "evm-bn";
import greeks from "greeks";

// TODO: v2 make this configurable (for example last 8 months)
export const TEN_TO_EIGHTEEN = 10 ** 18;
let periodicalMarketBasePricesUpdater;
const TEN_SECONDS = 10000;

export const subscribeToMarketFactoryEvents = async (provider, riskFreeRate, web3, marketFactory, loadHelper, userBalances, userAddress, dispatch) => {
  try {
    const abi = [
      "event MarketCreated(uint40 indexed marketId, bytes32 indexed marketHash, uint40 longTokenId, uint40 shortTokenId, bool isCall, uint128 strikePrice, uint32 expirationTime, address baseTokenAddress, address underlyingTokenAddress)",
      "event MultipleMarketsCreated(uint16 marketCount)"
    ];
    const marketFactoryContract = new Contract(marketFactory._address, abi, provider);

    marketFactoryContract.on(
      "MarketCreated",
      async (marketId, marketHash, longTokenId, shortTokenId, isCall, strikePrice, expirationTime, baseTokenAddress, underlyingTokenAddress, event) => {
        console.log("MarketCreated event received:", event);
        const marketAttributes = {
          marketId,
          marketHash,
          isCall,
          strikePrice,
          expirationTime: expirationTime.toString(),
          baseTokenAddress,
          underlyingTokenAddress
        };
        addNewMarket(riskFreeRate, web3, marketAttributes, loadHelper, userBalances, dispatch);
      }
    );
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while loading data. Please reset your account and reload the application!", 1, dispatch, true);
  }
};

const getChunkedMarketIdsArray = (marketIds, chunkSize) => {
  let chunkedMarketIdsArray = [];
  for (let j = 0; j < marketIds.length; j += chunkSize) {
    let chunkArray = marketIds.slice(j, j + chunkSize);
    chunkedMarketIdsArray.push(chunkArray);
  }

  return chunkedMarketIdsArray;
};

// loads markets that are unsettled, and also settled within the last 48 hours
export const loadLatestMarkets = async (networkData, loadHelper, riskFreeRate, marketFactory, underPriceBN, settlementsStream, dispatch) => {
  try {
    console.log("Loading markets start.");
    const startTime = new Date();

    // part 1 - fetch events
    const createdMarketStream = await getEventsStreamCached(networkData, marketFactory, "MarketFactory", "MarketCreated", {});
    console.log("   RPC method: getEventsStreamCached:MarketCreated in: " + (new Date() - startTime) + " ms");
    const createdMarketEvents = createdMarketStream.map(event => event.returnValues);
    const settlementEvents = settlementsStream.map(event => event.returnValues);

    // use markets with expiration time in the future + markets with past expirations up to 25 hours
    const HOURS_25 = 25 * 60 * 60;
    const filteredMarketEvents = createdMarketEvents.filter(event => event.expirationTime > Math.round(new Date().getTime() / 1000) - HOURS_25);
    console.log("Created/filtered markets: ", createdMarketEvents.length, filteredMarketEvents.length);

    const marketIdsData = filteredMarketEvents.map(event => event.marketId);
    const timestampNow = Math.round(new Date().getTime() / 1000);

    // part 2 - fetch market data, fetch in batches (chunks)
    const chunkedMarketIdsArray = getChunkedMarketIdsArray(marketIdsData, 120); // 120 is based on estimateGas below
    let marketDataFlat = [];
    let singletonData = [];
    for (let j = 0; j < chunkedMarketIdsArray.length; j++) {
      console.log("   RPC method: loadHelper.getMarketData chunk " + j);
      // const gas = await loadHelper.methods.getMarketData(chunkedMarketIdsArray[j], timestampNow).estimateGas();
      // console.log("Gas estimate: ", gas);
      const chunkResult = await loadHelper.methods.getMarketData(chunkedMarketIdsArray[j], timestampNow).call();
      singletonData = chunkResult[0];
      marketDataFlat.push(...chunkResult[1]);
      await sleepFunc(20); // limit to max 50 requests per second
    }
    const marketSingletonData = singletonData;
    const marketData = marketDataFlat;
    console.log("   RPC method: loadHelper.getMarketData in " + (new Date() - startTime) + " ms");

    // part 3 - fetch market prices, fetch in batches (chunks)
    const marketIdsPrices = marketIdsData.filter(id => Number(id) < 1000000000); // we sent on blockchain only market ids that are not futures
    const chunkedMarketIdsArray2 = getChunkedMarketIdsArray(marketIdsPrices, 300); // 300 is based on estimateGas below
    const marketPrices = [];
    for (let j = 0; j < chunkedMarketIdsArray2.length; j++) {
      console.log("   RPC method: loadHelper.getMarketPrices chunk " + j);
      // const gas = await loadHelper.methods.getMarketPrices(chunkedMarketIdsArray2[j], underPriceBN, timestampNow).estimateGas();
      // console.log("Gas estimate: ", gas);
      const chunkResult = await loadHelper.methods.getMarketPrices(chunkedMarketIdsArray2[j], underPriceBN, timestampNow).call();
      marketPrices.push(...chunkResult);
      await sleepFunc(20); // limit to max 50 requests per second
    }
    console.log("   RPC method: loadHelper.getMarketPrices took " + (new Date() - startTime) + " ms");

    // repack marketData and marketPrices
    // eslint-disable-next-line no-undef
    const nonFutureMarketDataMap = new Map();
    marketData.forEach((marketData, i) => {
      nonFutureMarketDataMap.set(marketIdsData[i], marketData);
    });

    // eslint-disable-next-line no-undef
    const nonFutureMarketPricesMap = new Map();
    marketPrices.forEach((marketPrice, i) => {
      nonFutureMarketPricesMap.set(marketIdsPrices[i], marketPrice);
    });

    // part 4 - go through all market events and map
    const markets = createdMarketEvents.map((marketEvent, i) => {
      let { marketId, isCall, strikePrice, expirationTime, baseTokenAddress, underlyingTokenAddress } = marketEvent;

      // if we haven't loaded data, then use default data (because they are settled)
      let marketData = nonFutureMarketDataMap.get(marketId);
      if (!marketData) {
        const isSettled = settlementEvents.find(event => event.expirationTime === expirationTime) !== undefined;
        marketData = {
          longPriceInVol: "80000000000000000000",
          belowTick: "0",
          liquidity: "0",
          ticks: [
            { index: 0, liquidity: 0 },
            { index: 69088, liquidity: 0 }
          ],
          oracleData: {
            lowerLimitSqrtPrice: "0",
            upperLimitSqrtPrice: "0"
          },
          isSettled: isSettled,
          openInterest: "0"
        };
      }

      const marketPrice = nonFutureMarketPricesMap.get(marketId);
      const priceInBase = marketPrice ? marketPrice.priceInBase : 0;

      const isFuture = strikePrice.toString() === (10 ** 12).toString();
      const normalizedStrikePrice = parseFloat(formatUnits(strikePrice.toString(), 18));
      const underPrice = parseFloat(formatUnits(marketSingletonData.underPriceInBase.toString(), 18));
      const volatility = parseFloat(formatUnits(marketData.longPriceInVol.toString(), 18));

      const longPriceInBase = isFuture ? underPrice : parseFloat((priceInBase / 10 ** 18).toString());

      // calculate open and close prices
      let { openLongPrice, closeLongPrice } = isFuture
        ? getOpenCloseLongFuturePrice(longPriceInBase, expirationTime)
        : getOpenCloseLongOptionPrice(
            longPriceInBase,
            underPrice,
            expirationTime,
            marketSingletonData.maxFee,
            marketSingletonData.minFee,
            marketSingletonData.steepness
          );

      // market helper
      let helper = new MarketHelper(0, parseInt(marketData.belowTick), Math.sqrt(volatility), expirationTime, normalizedStrikePrice, isCall, riskFreeRate);
      if (isFuture) {
        // delete zero tick if future
        helper.ticks = [];
        helper.currentSqrtPrice = Math.sqrt(underPrice);
      }
      for (let tick of marketData.ticks) {
        const tickLiquidity = parseFloat(formatUnits(tick.liquidity.toString(), isFuture ? 12 : 18));
        insert(helper, { index: parseInt(tick.index), liquidity: tickLiquidity });
      }

      const currentLiquidity = parseFloat(formatUnits(marketData.liquidity.toString(), isFuture ? 12 : 18));
      helper.currentLiquidity = currentLiquidity;

      // oracle prices
      const lowerOraclePriceInVol = fromSqrtPrice(marketData.oracleData.lowerLimitSqrtPrice.toString());
      const upperOraclePriceInVol = fromSqrtPrice(marketData.oracleData.upperLimitSqrtPrice.toString());
      const currentOraclePriceInVol = lowerOraclePriceInVol * Math.sqrt(upperOraclePriceInVol / lowerOraclePriceInVol);
      const oracle = {
        lowerPriceInVol: lowerOraclePriceInVol,
        currentPriceInVol: currentOraclePriceInVol,
        upperPriceInVol: upperOraclePriceInVol
      };

      const optionsPoolBalance = getMaxLongAmountWhenSellingOptions(helper).toString();

      const requiredThreshold = marketSingletonData.requiredThresholdOption;
      const liquidationThreshold = marketSingletonData.liquidationThresholdOption;

      return {
        immutableAttributes: {
          marketId: marketId.toString(),
          isCall: isCall,
          strikePrice: strikePrice.toString(),
          expirationTime: expirationTime.toString(),
          baseTokenAddress: baseTokenAddress,
          baseTokenDecimals: 18,
          underlyingTokenAddress: underlyingTokenAddress,
          isFuture: isFuture
        },
        // variable
        mutableAttributes: {
          marketId: marketId.toString(),
          longPriceInVol: volatility.toString(),
          longPrice: longPriceInBase.toString(),
          openLongPrice: openLongPrice.toString(),
          closeLongPrice: closeLongPrice.toString(),
          openShortPrice: [closeLongPrice.toString(), isCall ? TEN_TO_EIGHTEEN : strikePrice],
          closeShortPrice: [openLongPrice.toString(), isCall ? TEN_TO_EIGHTEEN : strikePrice],
          isSettled: marketData.isSettled,
          openInterest: marketData.openInterest.toString(),
          minFee: marketSingletonData.minFee.toString(),
          maxFee: marketSingletonData.maxFee.toString(),
          steepness: marketSingletonData.steepness.toString(),
          requiredThreshold: requiredThreshold.toString(),
          liquidationThreshold: liquidationThreshold.toString(),
          penaltyBase: marketSingletonData.penaltyBase.toString(),
          isEmpty: false, // todo: what is this used for? contract returned false always
          optionsPoolBalance: optionsPoolBalance,
          marketHelper: helper,
          liquidityInCurrentTick: marketData.liquidity,
          priceOracle: oracle
        }
      };
    });

    console.log("Loading markets finish in: " + (new Date() - startTime) + " ms");
    return markets;
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while loading markets. Please reload the application!", 1, dispatch);
  }
};

// todo: delete later, loadLatestMarkets is used for faster loaading
export const loadMarkets = async (networkData, loadHelper, riskFreeRate, marketFactory, underPriceBN, dispatch) => {
  try {
    console.log("Loading markets start.");
    let startTime = new Date();

    // part 1 - fetch events
    const createdMarketStream = await getEventsStreamCached(networkData, marketFactory, "MarketFactory", "MarketCreated", {});
    console.log("   RPC method: getEventsStreamCached:MarketCreated in: " + (new Date() - startTime) + " ms");
    const createdMarketEvents = createdMarketStream.map(event => event.returnValues);
    let marketIds = createdMarketEvents.map(event => event.marketId);
    const timestampNow = Math.round(new Date().getTime() / 1000);

    // part 2 - fetch market data, fetch in batches (chunks)
    const chunkedMarketIdsArray = getChunkedMarketIdsArray(marketIds, 40);
    let marketDataFlat = [];
    let singletonData = [];
    for (let j = 0; j < chunkedMarketIdsArray.length; j++) {
      console.log("   RPC method: loadHelper.getMarketData chunk " + j);
      const chunkResult = await loadHelper.methods.getMarketData(chunkedMarketIdsArray[j], timestampNow).call();
      singletonData = chunkResult[0];
      marketDataFlat.push(...chunkResult[1]);
      await sleepFunc(20); // limit to max 50 requests per second
    }
    const marketSingletonData = singletonData;
    const marketData = marketDataFlat;

    console.log("   RPC method: loadHelper.getMarketData in " + (new Date() - startTime) + " ms");
    marketIds = marketIds.filter(id => Number(id) < 1000000000); // we sent on blockchain only market ids that are not futures

    // NEW CODE
    const chunkedMarketIdsArray2 = getChunkedMarketIdsArray(marketIds, 100);
    const marketPrices = [];
    for (let j = 0; j < chunkedMarketIdsArray2.length; j++) {
      console.log("   RPC method: loadHelper.getMarketPrices chunk " + j);
      const chunkResult = await loadHelper.methods.getMarketPrices(chunkedMarketIdsArray2[j], underPriceBN, timestampNow).call();
      marketPrices.push(...chunkResult);
      await sleepFunc(20); // limit to max 50 requests per second
    }
    console.log("   RPC method: loadHelper.getMarketPrices took " + (new Date() - startTime) + " ms");

    const nonFuturemarketPricesMap = new Map();
    marketPrices.forEach((marketPrice, i) => {
      nonFuturemarketPricesMap.set(marketIds[i], marketPrice);
    });

    const markets = marketData.map((market, i) => {
      let { marketId, isCall, strikePrice, expirationTime, baseTokenAddress, underlyingTokenAddress } = createdMarketEvents[i];

      const isFuture = strikePrice.toString() === (10 ** 12).toString();
      const normalizedStrikePrice = parseFloat(formatUnits(strikePrice.toString(), 18));
      const underPrice = parseFloat(formatUnits(marketSingletonData.underPriceInBase.toString(), 18));
      const volatility = parseFloat(formatUnits(market.longPriceInVol.toString(), 18));

      const longPriceInBase = isFuture ? underPrice : parseFloat((nonFuturemarketPricesMap.get(marketId).priceInBase / 10 ** 18).toString());

      // calculate open and close prices
      let { openLongPrice, closeLongPrice } = isFuture
        ? getOpenCloseLongFuturePrice(longPriceInBase, expirationTime)
        : getOpenCloseLongOptionPrice(
            longPriceInBase,
            underPrice,
            expirationTime,
            marketSingletonData.maxFee,
            marketSingletonData.minFee,
            marketSingletonData.steepness
          );

      // market helper
      let helper = new MarketHelper(0, parseInt(market.belowTick), Math.sqrt(volatility), expirationTime, normalizedStrikePrice, isCall, riskFreeRate);
      if (isFuture) {
        // delete zero tick if future
        helper.ticks = [];
        helper.currentSqrtPrice = Math.sqrt(underPrice);
      }
      for (let tick of market.ticks) {
        const tickLiquidity = parseFloat(formatUnits(tick.liquidity.toString(), isFuture ? 12 : 18));
        insert(helper, { index: parseInt(tick.index), liquidity: tickLiquidity });
      }

      const currentLiquidity = parseFloat(formatUnits(market.liquidity.toString(), isFuture ? 12 : 18));
      helper.currentLiquidity = currentLiquidity;

      // oracle prices
      const lowerOraclePriceInVol = fromSqrtPrice(market.oracleData.lowerLimitSqrtPrice.toString());
      const upperOraclePriceInVol = fromSqrtPrice(market.oracleData.upperLimitSqrtPrice.toString());
      const currentOraclePriceInVol = lowerOraclePriceInVol * Math.sqrt(upperOraclePriceInVol / lowerOraclePriceInVol);
      const oracle = {
        lowerPriceInVol: lowerOraclePriceInVol,
        currentPriceInVol: currentOraclePriceInVol,
        upperPriceInVol: upperOraclePriceInVol
      };

      const optionsPoolBalance = getMaxLongAmountWhenSellingOptions(helper).toString();

      const requiredThreshold = marketSingletonData.requiredThresholdOption;
      const liquidationThreshold = marketSingletonData.liquidationThresholdOption;

      return {
        immutableAttributes: {
          marketId: marketId.toString(),
          isCall: isCall,
          strikePrice: strikePrice.toString(),
          expirationTime: expirationTime.toString(),
          baseTokenAddress: baseTokenAddress,
          baseTokenDecimals: 18,
          underlyingTokenAddress: underlyingTokenAddress,
          isFuture: isFuture
        },
        // variable
        mutableAttributes: {
          marketId: marketId.toString(),
          longPriceInVol: volatility.toString(),
          longPrice: longPriceInBase.toString(),
          openLongPrice: openLongPrice.toString(),
          closeLongPrice: closeLongPrice.toString(),
          openShortPrice: [closeLongPrice.toString(), isCall ? TEN_TO_EIGHTEEN : strikePrice],
          closeShortPrice: [openLongPrice.toString(), isCall ? TEN_TO_EIGHTEEN : strikePrice],
          isSettled: market.isSettled,
          openInterest: market.openInterest.toString(),
          minFee: marketSingletonData.minFee.toString(),
          maxFee: marketSingletonData.maxFee.toString(),
          steepness: marketSingletonData.steepness.toString(),
          requiredThreshold: requiredThreshold.toString(),
          liquidationThreshold: liquidationThreshold.toString(),
          penaltyBase: marketSingletonData.penaltyBase.toString(),
          isEmpty: false, // todo: what is this used for? contract returned false always
          optionsPoolBalance: optionsPoolBalance,
          marketHelper: helper,
          liquidityInCurrentTick: market.liquidity,
          priceOracle: oracle
        }
      };
    });

    console.log("Loading markets finish in: " + (new Date() - startTime) + " ms");
    return markets;
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while loading markets. Please reload the application!", 1, dispatch);
  }
};

export const addNewMarket = async (riskFreeRate, web3, marketAttributes, loadHelper, userBalances, dispatch) => {
  try {
    let { marketId, isCall, strikePrice, expirationTime, baseTokenAddress, underlyingTokenAddress } = marketAttributes;
    // fetch data from contracts
    const isFuture = strikePrice.toString() === (10 ** 12).toString();
    const immutableMarketAttributes = {
      marketId: marketId.toString(),
      isCall: isCall,
      strikePrice: strikePrice.toString(),
      expirationTime: expirationTime.toString(),
      baseTokenAddress: baseTokenAddress,
      baseTokenDecimals: 18,
      underlyingTokenAddress: underlyingTokenAddress,
      isFuture: isFuture
    };
    const mutableMarketAttributes = await getMuttableMarketAttributes(loadHelper, userBalances, marketId, immutableMarketAttributes, riskFreeRate);

    dispatch(marketCreated(immutableMarketAttributes, mutableMarketAttributes));
    addPositionToken(web3, marketId.toString(), "long", immutableMarketAttributes, dispatch);
    addPositionToken(web3, (parseInt(marketId) + 1).toString(), "short", immutableMarketAttributes, dispatch);
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while fetching market data. Please reload the application!", 1, dispatch);
  }
};

export const updateMarket = async (riskFreeRate, web3, marketId, dispatch) => {
  try {
    const { loadHelper, userBalances } = store.getState().contracts.data;
    const { marketsImmutable } = store.getState();
    const marketImmutableData = marketsImmutable.data.find(market => market.marketId === marketId.toString());
    let { isCall, strikePrice, expirationTime } = marketImmutableData;
    const normalizedStrikePrice = parseFloat(formatUnits(strikePrice.toString(), 18));

    const mutableMarketAttributes = await getMuttableMarketAttributes(loadHelper, userBalances, marketId, marketImmutableData, riskFreeRate);

    dispatch(marketUpdated(mutableMarketAttributes));

    return getTokenNames(isCall, normalizedStrikePrice, expirationTime);
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while fetching market data. Please reload the application!", 1, dispatch);
  }
};

export const getTokenNames = (isCall, strikePrice, expirationTime) => {
  const isFuture = strikePrice === 0.000001;
  const dateStr = moment(new Date(expirationTime * 1000))
    .format("DDMMMYY")
    .toString()
    .toUpperCase();
  if (isFuture) {
    const longNameAndSymbol = "LONG-ETH-" + dateStr + "-FUTURE-USD";
    const shortNameAndSymbol = "SHORT-ETH-" + dateStr + "-FUTURE-USD";

    return { longTokenName: longNameAndSymbol, shortTokenName: shortNameAndSymbol };
  }

  const strikeStr = strikePrice.toString();
  const isCallStr = isCall ? "C" : "P";

  const longNameAndSymbol = "LONG-ETH-" + dateStr + "-" + strikeStr + "-" + isCallStr + "-USD";
  const shortNameAndSymbol = "SHORT-ETH-" + dateStr + "-" + strikeStr + "-" + isCallStr + "-USD";

  return { longTokenName: longNameAndSymbol, shortTokenName: shortNameAndSymbol };
};

const multipleMarketsNotificationText = length => {
  const marketText = length > 1 ? "markets" : "market";
  return "Creating " + length + " " + marketText;
};

export const createMultipleMarkets = async (
  web3,
  account,
  marketFactory,
  isCalls,
  strikePrices,
  expirationTime,
  baseTokenAddress,
  underlyingTokenAddress,
  closeModal,
  dispatch
) => {
  try {
    const notificationText = multipleMarketsNotificationText(isCalls.length);
    const txNonce = await getNonce(web3, account);
    const contractMethod = marketFactory.methods.createMultipleMarkets(isCalls, strikePrices, expirationTime, baseTokenAddress, underlyingTokenAddress);
    const limitGas = Math.round((await contractMethod.estimateGas({ from: account })) * 1.2); // 20% extra gas
    console.log("Estimated gas: " + limitGas);
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    let isConfirmed = false;
    await contractMethod
      .send({ from: account, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, account, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, account, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, account, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, account, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    onError(e, "Error while creating new market!", dispatch);
  }
};

export const periodicallyUpdateMarketBasePrices = async (loadHelper, userBalances, marginPool, userAccount, dispatch) => {
  try {
    let finished = true;
    // start fetching periodically if not already started
    if (periodicalMarketBasePricesUpdater == null) {
      periodicalMarketBasePricesUpdater = setInterval(async () => {
        // protection in case there are slow fetches, we don't want to start another fetch
        // because setInterval will be called every 10 seconds, unrelated to the time it takes to fetch
        if (!finished) {
          return;
        }
        finished = false;

        // in 10 seconds, update with real data, and 2x with fake data
        await updateUnderAndMarketPricesRealData(loadHelper, userBalances, marginPool, userAccount, dispatch);

        await sleepFunc(3000);
        await updateUnderAndMarketPricesRandomData(dispatch);

        await sleepFunc(3000);
        await updateUnderAndMarketPricesRandomData(dispatch);

        finished = true;
      }, TEN_SECONDS);
    }
    // first 10 secods update with fake data
    await sleepFunc(3000);
    await updateUnderAndMarketPricesRandomData(dispatch);

    await sleepFunc(3000);
    await updateUnderAndMarketPricesRandomData(dispatch);
  } catch (e) {
    handleNewError(e, "There was an error with periodical fetcher!", 2, dispatch);
  }
};

export const updateUnderAndMarketPricesRealData = async (loadHelper, userBalances, marginPool, userAccount, dispatch) => {
  try {
    // NOTE: we can't update under without updating option and future prices, so it's all in one updater
    const { tokenPairs, marketsImmutable, marketsMutable, tokens } = store.getState();
    if (tokenPairs.loaded && marketsImmutable.loaded && marketsMutable.loaded) {
      console.log("--- Periodical market prices updater ---");
      // step 1) get under price
      const { newUnderPrice, newAvgUnderPrice } = await loadUnderlyingMarketPrice(loadHelper, dispatch);

      // step 2) fire loading of margin data
      const marginAccountData = await loadMarginAccountData2(userAccount, loadHelper, marginPool, userBalances, newAvgUnderPrice, tokens);
      const marginPoolData = await loadMarginPoolData2(loadHelper, marginPool, newAvgUnderPrice, tokens);

      // step 3) get market prices for non-settled markets
      const nonSettledMarkets = marketsMutable.data.filter(m => !m.isSettled);
      let marketIds = nonSettledMarkets.map(m => m.marketId);
      const timestampNow = Math.round(new Date().getTime() / 1000);

      if (marketIds && newAvgUnderPrice) {
        let futureMarketIds = marketIds.filter(id => Number(id) >= 1000000000);
        marketIds = marketIds.filter(id => Number(id) < 1000000000); // we sent on blockchain only market ids that are not futures
        console.log("RPC method: loadHelper.getMarketPrices for:", marketIds.length, " markets");
        const startTime = new Date();
        let marketPrices = await loadHelper.methods.getMarketPrices(marketIds, newAvgUnderPrice, timestampNow).call();
        console.log("RPC method: loadHelper.getMarketPrices took:", new Date() - startTime, "ms");
        const marketPricesForFutures = futureMarketIds.map(id => {
          return {
            priceInBase: newAvgUnderPrice
          };
        });
        marketIds = [...futureMarketIds, ...marketIds];
        marketPrices = [...marketPricesForFutures, ...marketPrices];

        // repack
        let markets = [];
        for (let i = 0; i < marketIds.length; i++) {
          const longPriceInBase = (marketPrices[i].priceInBase / 10 ** 18).toString();
          const avgUnderPrice = newAvgUnderPrice / 10 ** 18;
          const marketMuttable = marketsMutable.data.find(m => m.marketId === marketIds[i]);
          const marketImuttable = marketsImmutable.data.find(m => m.marketId === marketIds[i]);

          // const longPriceInBase = isFuture ? underPrice : getOptionPrice(riskFreeRate, expirationTime, normalizedStrikePrice, isCall, underPrice, longPriceInVol);
          let { openLongPrice, closeLongPrice } = marketImuttable.isFuture
            ? getOpenCloseLongFuturePrice(avgUnderPrice, marketImuttable.expirationTime)
            : getOpenCloseLongOptionPrice(
                parseFloat(longPriceInBase),
                avgUnderPrice,
                marketImuttable.expirationTime,
                marketMuttable.maxFee,
                marketMuttable.minFee,
                marketMuttable.steepness
              );

          // oracle prices
          const isFuture = Number(marketIds[i]) >= 1000000000;
          let oracle = null;
          if (!isFuture) {
            const lowerOraclePriceInVol = fromSqrtPrice(marketPrices[i].oracleData.lowerLimitSqrtPrice.toString());
            const upperOraclePriceInVol = fromSqrtPrice(marketPrices[i].oracleData.upperLimitSqrtPrice.toString());
            const currentOraclePriceInVol = lowerOraclePriceInVol * Math.sqrt(upperOraclePriceInVol / lowerOraclePriceInVol);
            oracle = {
              lowerPriceInVol: lowerOraclePriceInVol,
              currentPriceInVol: currentOraclePriceInVol,
              upperPriceInVol: upperOraclePriceInVol
            };
          }

          markets.push({
            marketId: marketIds[i],
            longPrice: longPriceInBase,
            openLongPrice: openLongPrice.toString(),
            closeLongPrice: closeLongPrice.toString(),
            openShortPrice: [closeLongPrice.toString(), marketImuttable.isCall ? 10 ** 18 : marketImuttable.strikePrice],
            closeShortPrice: [openLongPrice.toString(), marketImuttable.isCall ? 10 ** 18 : marketImuttable.strikePrice],
            priceOracle: !isFuture ? oracle : null
          });
        }

        const insuranceFund = await loadInsuranceFundBalances(loadHelper);
        const insurerBalance = await loadInsurerBalance(loadHelper, userAccount);

        batch(() => {
          const pair = tokenPairs.data[0];
          if (newUnderPrice != null && newAvgUnderPrice != null) {
            // update state if price changed
            if (pair.underlyingTokenPrice !== newUnderPrice || pair.avgUnderlyingTokenPrice !== newAvgUnderPrice) {
              dispatch(underlyingTokenPriceLoaded(pair, newUnderPrice, newAvgUnderPrice));
            }
          }
          dispatch(marketPricesUpdated(markets));
          dispatch(marginAccountDataLoaded(marginAccountData));
          dispatch(marginPoolDataLoaded(marginPoolData));

          dispatch(insuranceFundLoaded(insuranceFund));
          dispatch(insurerBalanceLoaded(insurerBalance));
        });
      }
      console.log("---   Spot:", Number((newUnderPrice / 1e18).toFixed(2)), "TWAP:", Number((newAvgUnderPrice / 1e18).toFixed(2)), "   ---");
    }
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while fetching market prices. Please reload the application!", 1, dispatch);
  }
};

export const updateUnderAndMarketPricesRandomData = async dispatch => {
  try {
    // NOTE: we can't update under without updating option and future prices, so it's all in one updater
    const { tokenPairs, marketsImmutable, marketsMutable } = store.getState();
    if (tokenPairs.loaded && marketsImmutable.loaded && marketsMutable.loaded) {
      console.log("--- Periodical fake under price updater ---");
      let startTime = new Date();
      // 1 - randomize under price
      const pair = tokenPairs.selectedTokenPair;

      const underPrice = pair.normalizedUnderlyingTokenPrice;
      const avgUnderPrice = pair.avgUnderPrice / 1e18;

      const randomCents = Math.random() * 0.04 - 0.02; // up or down up to 2 cents
      const newUnderPrice = underPrice + randomCents;
      const newAvgUnderPrice = avgUnderPrice + randomCents;
      const newUnderPriceBN = toBn(toPreciseString(newUnderPrice, 18), 18).toString();
      const newAvgUnderPriceBN = toBn(toPreciseString(newAvgUnderPrice, 18), 18).toString();

      // 2 - update market prices
      const nonSettledMarkets = marketsMutable.data.filter(m => !m.isSettled);

      let combinedMarkets = nonSettledMarkets.map(market => {
        const { marketId, openLongPrice, closeLongPrice, longPrice, marketHelper, maxFee, minFee, steepness } = market;
        const immuttableMarket = marketsImmutable.data.find(m => m.marketId === market.marketId);
        const { isCall, strikePrice, expirationTime } = immuttableMarket;
        return {
          marketId,
          isFuture: Number(marketId) >= 1000000000,
          isCall,
          openLongPrice,
          closeLongPrice,
          longPrice: parseFloat(longPrice),
          marketHelper,
          strikePrice: parseFloat(strikePrice / 1e18),
          expirationTime: parseInt(expirationTime),
          maxFee,
          minFee,
          steepness
        };
      });

      let markets = [];
      for (let market of combinedMarkets) {
        // calculate delta
        const currentTime = moment().unix();
        const strikePrice = market.strikePrice;
        const timeToExpirationInYears = (market.expirationTime - currentTime) / SECONDS_IN_YEAR;
        const muttableMarket = marketsMutable.data.find(m => m.marketId === market.marketId);
        const iv = muttableMarket.marketHelper.currentSqrtPrice ** 2 / 100;
        const riskFreeRate = 0.0487;
        const callPut = market.isCall ? "call" : "put";
        let delta = greeks.getDelta(newAvgUnderPrice, strikePrice, timeToExpirationInYears, iv, riskFreeRate, callPut);
        delta = Math.min(1, Math.max(-1, delta));

        // apply delta and price change to option price
        const longPriceInBase = market.longPrice + delta * randomCents;

        // calculate open and close prices
        let { openLongPrice, closeLongPrice } = market.isFuture
          ? getOpenCloseLongFuturePrice(avgUnderPrice, market.expirationTime)
          : getOpenCloseLongOptionPrice(longPriceInBase, avgUnderPrice, market.expirationTime, market.maxFee, market.minFee, market.steepness);

        // fill the markets array
        markets.push({
          marketId: market.marketId,
          longPrice: longPriceInBase,
          openLongPrice: openLongPrice.toString(),
          closeLongPrice: closeLongPrice.toString()
        });
      }
      console.log("Periodical fake under price delta took:", new Date() - startTime, "ms");

      // dispatch updates
      batch(() => {
        dispatch(underlyingTokenPriceLoaded(pair, newUnderPriceBN, newAvgUnderPriceBN));
        dispatch(marketPricesUpdated(markets));
      });

      console.log("Periodical fake under price updater took:", new Date() - startTime, "ms");
    }
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while updating under and market prices. Please reload the application!", 1, dispatch);
  }
};
