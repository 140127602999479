import React, { useCallback } from "react";
import { createPortal } from "react-dom";
import { Card, CardBody } from "reactstrap";

const DropdownMenuFilterContainer = ({ data, searchParam, setSearchParam, containerType, closeDropmenu, top, left, width }) => {
  const filterData = data.filter(element => element !== searchParam);

  const onRowClickEventHandler = useCallback(
    e => {
      const choosenValue = e.currentTarget.textContent.trim();
      setSearchParam(choosenValue);
      const select = document.querySelector(`.-${containerType} > span`);
      select.textContent = choosenValue;
      closeDropmenu();
      select.nextElementSibling.classList.remove("transformArrow");
      document.body.classList.remove("scrollDisabled");
    },
    [setSearchParam, closeDropmenu]
  );

  return createPortal(
    <div className={`${containerType}`} style={{ top: top, left: left, width: width }}>
      <Card>
        <CardBody>
          <table className="table">
            <tbody>
              {filterData.map(element => {
                return (
                  <tr key={element} onClick={onRowClickEventHandler}>
                    <td className="singleOption">{element}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>,
    document.getElementById("root")
  );
};

export default DropdownMenuFilterContainer;
