import React from "react";
import MySwitch from "./MySwitch.jsx";
import PropTypes from "prop-types";

class CogChartOptions extends React.Component {
  constructor(props) {
    super(props);
  }

  onStandardDeviationIncrease = e => {
    e.stopPropagation();
    this.props.setStandardDeviation(Math.min(5, this.props.standardDeviation + 1));
  };

  onStandardDeviationDecrease = e => {
    e.stopPropagation();
    this.props.setStandardDeviation(Math.max(1, this.props.standardDeviation - 1));
  };

  render() {
    return (
      <div className="cogChartOptionsFrame" onClick={e => e.stopPropagation()}>
        <div className="chartSwitch">
          {this.props.showOptionNewPosition && (
            <div className="chartSwitchItem">
              <span className={this.props.isNewPositionSelected ? "disabledSpan" : ""} style={{ marginRight: "10px" }}>
                All Positions
              </span>
              <MySwitch
                id="chartSwitch"
                name="chartSwitch"
                checked={this.props.isNewPositionSelected}
                onChange={() => this.props.allNewSwitchChanged()}
                isAllNew
              />
              <span className={!this.props.isNewPositionSelected ? "disabledSpan" : ""}>New Position</span>
            </div>
          )}
        </div>

        <div className="standardDeviationContainer">
          <span className="standardDeviationLabel">Standard deviations</span>
          <div className="increaseDecreaseStandardDeviationSelect">
            <span>±{this.props.standardDeviation}σ</span>
            <div className="increaseDecreaseStandardDeviation">
              <img className="increaseButton" onClick={this.onStandardDeviationIncrease} />
              <img className="decreaseButton" onClick={this.onStandardDeviationDecrease} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CogChartOptions.propTypes = {
  // should option be displayed
  showOptionNewPosition: PropTypes.bool,

  // what option/value is selected/set
  isNewPositionSelected: PropTypes.bool,
  standardDeviation: PropTypes.object,

  // on change
  allNewSwitchChanged: PropTypes.func,
  setStandardDeviation: PropTypes.func
};

export default CogChartOptions;
