export const OPTIONS_TABLE_SETTINGS_TRADE = [
  "gammaTrade",
  "vegaTrade",
  "thetaTrade",
  "rhoTrade",
  "lotsTrade",
  "IVTrade",
  "deltaTrade",
  "openTrade",
  "volTrade"
];
export const OPTIONS_TABLE_SETTINGS_POOL = ["gammaPool", "vegaPool", "thetaPool", "rhoPool", "lotsPool", "IVPool", "deltaPool", "openPool", "volPool"];
