import { get } from "lodash";
import { createSelector } from "reselect";
import { tokens, tokensLoaded, positionTokensLoaded, positionTokens, selectedTokenPair } from "./tokensSelectors";
import { formatUnits } from "@ethersproject/units";
import { getDateString } from "../utils/positionsManagerUtils";

export const settlementData = state => get(state, "settlements.data", []);
export const settlementDataSelector = createSelector(settlementData, data => data);

export const depositsAndWithdrawalsLoaded = state => get(state, "depositsAndWithdrawals.loaded");
export const depositsAndWithdrawalsLoadedSelector = createSelector(depositsAndWithdrawalsLoaded, loaded => loaded);

export const depositsAndWithdrawalsData = state => get(state, "depositsAndWithdrawals.data");
export const depositsAndWithdrawalsSelector = createSelector(
  depositsAndWithdrawalsData,
  depositsAndWithdrawalsLoaded,
  tokensLoaded,
  tokens,
  positionTokensLoaded,
  positionTokens,
  selectedTokenPair,
  (depositsAndWithdrawalsData, depositsAndWithdrawalsLoaded, tokensLoaded, tokens, positionTokensLoaded, positionTokens, selectedTokenPair) => {
    const startTime = new Date().getTime();
    if (depositsAndWithdrawalsLoaded && tokensLoaded && positionTokensLoaded && selectedTokenPair && selectedTokenPair.underlyingTokenPrice) {
      const normalizedUnderlyingPrice = formatUnits(selectedTokenPair.underlyingTokenPrice, 18);
      const returnData = depositsAndWithdrawalsData.map(daw => {
        const normalizedAmount = parseFloat(formatUnits(daw.amount.toString(), 18));
        // const printableAmount = parseFloat(normalizedAmount).toFixed(2);
        const printableAmount = parseFloat(normalizedAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        const underlyingTokenAddress = tokens.find(token => token.symbol === "ETH").address;
        const normalizedValue =
          daw.token === underlyingTokenAddress ? parseFloat(normalizedAmount) * parseFloat(normalizedUnderlyingPrice) : parseFloat(normalizedAmount);
        // const printableValue = parseFloat(normalizedValue).toFixed(2);
        const printableValue = parseFloat(normalizedValue).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        let matchingToken = tokens.find(asset => asset.address === daw.token);
        let tokenSymbol;
        if (!matchingToken) {
          matchingToken = positionTokens.find(asset => asset.address === daw.token);
        }
        tokenSymbol = matchingToken.symbol;

        const dateString = getDateString(new Date(daw.timestamp * 1000));

        return {
          ...daw,
          normalizedAmount,
          printableAmount,
          normalizedValue,
          printableValue,
          tokenSymbol,
          readableTimestamp: dateString
        };
      });

      // console.log("Selector: depositsAndWithdrawalsSelector took ", new Date().getTime() - startTime, "mS");
      return returnData;
    }
    return [];
  }
);
