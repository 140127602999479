/* eslint-disable no-undef */
import React, { useState, useEffect, useCallback } from "react";
import { createPortal } from "react-dom";
import { Card, CardBody } from "reactstrap";

const DropdownMenuMultipleSelectionContainer = ({ data, containerType, firstOptionValue, setData, setSelectValue, top, left, width }) => {
  const [copiedDataFromProp, setCopiedDataFromProp] = useState(data);

  useEffect(() => {
    setCopiedDataFromProp(data);
  }, []);

  const onRowClickEventHandler = useCallback(
    e => {
      e.stopPropagation();
      const select = document.querySelector(`.-${containerType} > span`);

      const key = e.currentTarget.textContent;
      const firstOption = document.querySelector(`.${containerType} .singleOption`);

      if (key === firstOptionValue && firstOption.classList.contains("selectedOption")) {
        return;
      }
      const newData = new Map(copiedDataFromProp);

      if (key === firstOptionValue && !firstOption.classList.contains("selectedOption")) {
        newData.set(key, true);
        Array.from(document.querySelectorAll(`.${containerType} .singleOption`))
          .slice(1)
          .forEach(el => {
            el.classList.remove("selectedOption");
          });
        firstOption.classList.add("selectedOption");
        for (let k of newData.keys()) {
          if (k === firstOptionValue) {
            continue;
          }
          newData.set(k, false);
        }
        setData(newData);
        setSelectValue(firstOptionValue);
        select.textContent = firstOptionValue;
        setCopiedDataFromProp(newData);
        return;
      }

      if (firstOption.classList.contains("selectedOption")) {
        newData.set(firstOptionValue, false);
        firstOption.classList.remove("selectedOption");
      }

      newData.set(key, !copiedDataFromProp.get(key));
      e.currentTarget.classList.toggle("selectedOption");
      let selectedValue;
      if ([...newData.values()].filter(selected => selected).length > 1) {
        selectedValue = "Custom";
      } else {
        selectedValue = [...newData.keys()].find(k => newData.get(k));
      }
      setSelectValue(selectedValue);
      select.textContent = selectedValue;
      setData(newData);
      setCopiedDataFromProp(newData);
    },
    [copiedDataFromProp, containerType, firstOptionValue]
  );

  return createPortal(
    <div className={`${containerType}`} style={{ top: top, left: left, width: width }}>
      <Card>
        <CardBody>
          <table className="table">
            <tbody>
              {Array.from(copiedDataFromProp.keys()).map((element, idx) => {
                return (
                  <tr key={element}>
                    <td className={`singleOption${copiedDataFromProp.get(element) ? " selectedOption" : ""}`} onClick={onRowClickEventHandler}>
                      <span style={{ float: idx === 0 ? "left" : "unset" }}>{element}</span>
                      {copiedDataFromProp.get(element) ? <img className="selectedOptionMark" /> : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>,
    document.getElementById("root")
  );
};

export default DropdownMenuMultipleSelectionContainer;
