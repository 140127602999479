/* eslint-disable no-undef */
import React, { useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import PoolPositionsPanel from "./PoolPositionsPanel.jsx";
import PoolHistoryPanel from "./PoolHistoryPanel.jsx";
import PoolMarketsPanel from "./PoolMarketsPanel.jsx";
import PerfectScrollbar from "perfect-scrollbar";
import UnderlyingChart from "./charts/UnderlyingChart.jsx";
import { useSelector } from "react-redux";
import { tokenBalancesSelector, tokensLoadedSelector } from "../../store/selectors/tokensSelectors.js";
import { useNavigate } from "react-router";
import { web3LoadedSelector, web3Selector } from "../../store/selectors/web3Selectors.js";

let ps;
let container = null;

const PoolLayout = () => {
  const poolPanelRef = useRef();

  const tokens = useSelector(tokenBalancesSelector);
  const tokensLoaded = useSelector(tokensLoadedSelector);
  const web3 = useSelector(web3Selector);
  const web3Loaded = useSelector(web3LoadedSelector);

  const navigate = useNavigate();

  useEffect(() => {
    document.documentElement.classList.remove("nav-open");
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(poolPanelRef.current);
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }

    container = document.querySelector(".content .row");

    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  }, []);

  useEffect(() => {
    if (tokensLoaded && tokens && web3Loaded) {
      const tokenETH = tokens.find(t => t.symbol === "ETH");
      const tokenUSD = tokens.find(t => t.symbol === "USD");
      if (
        tokenETH.accountBalance == "0" &&
        tokenETH.walletBalance == "0" &&
        tokenUSD.accountBalance == "0" &&
        tokenUSD.walletBalance == "0" &&
        (web3.currentProvider.networkVersion === "7933" || web3.currentProvider.networkVersion === "11155111")
      ) {
        navigate("/portfolio");
      }
    }
  }, [tokens, tokensLoaded]);

  const displayTables = () => {
    !localStorage.getItem("poolOrder") && localStorage.setItem("poolOrder", "underlying positions markets history");
    const poolOrder = localStorage.getItem("poolOrder").split(" ");
    const underlying = (
      <Col id="underlying" className="mb-1 draggable" md="12">
        <UnderlyingChart />
      </Col>
    );
    const positions = (
      <Col id="positions" className="mb-1 draggable" md="12">
        <PoolPositionsPanel />
      </Col>
    );

    const markets = (
      <Col id="markets" className="mb-1 draggable" md="12">
        <PoolMarketsPanel />
      </Col>
    );

    const history = (
      <Col id="history" className="mb-1 draggable" md="12">
        <PoolHistoryPanel />
      </Col>
    );

    const poolElements = new Map();

    // poolElements.set("settle", settle);
    poolElements.set("markets", markets);
    poolElements.set("positions", positions);
    poolElements.set("underlying", underlying);
    poolElements.set("history", history);

    return Array.from(poolOrder).map(e => {
      return poolElements.get(e);
    });
  };

  !localStorage.getItem("poolOrder") && localStorage.setItem("poolOrder", "markets positions underlying history");

  return (
    <div className="content" ref={poolPanelRef} style={{ paddingTop: "0px" }}>
      <Row>{displayTables()}</Row>
    </div>
  );
};

export default PoolLayout;
