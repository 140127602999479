/* eslint-disable react/no-multi-comp */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactTable from "react-table";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import {
  tradesSelector,
  tradesLoadedSelector,
  openedTradePositionsSelector,
  liquidationTradesSelector,
  liquidationTradesLoadedSelector
} from "../../store/selectors/positionsManagerSelectors";
import { currentTimestampSelector, web3AccountLoadedSelector } from "../../store/selectors/web3Selectors";
import ConnectPanel from "./ConnectPanel.jsx";
import { printEmptyTable, printLoadingIcon } from "./utilities/TradePanels/PrintUtility.jsx";
import { checkAllUnderlyingTokenPriceExist } from "./utilities/TradePanels/CheckUtility";
import { marketsLoadedSelector, marketsSelector } from "../../store/selectors/marketsSelectors";
import { cursorRangeCheck, formatValue, getUnderlyingPriceAtExpiredNumber, printPriceFromString, toMonthName } from "../../utils/utils.js";
import { FilterContext } from "../../layouts/market/MainRouter.jsx";
import MyTooltip2 from "./MyTooltip2.jsx";
import { getValueFooter } from "./utilities/TradePanels/FooterUtility";
import { settlementDataSelector } from "../../store/selectors/balancesSelectors";
import FiltersHistory from "./FiltersHistory";

const EMPTY_TABLE_TEXT = "You haven't made any trades.";
const CARD_TITLE = "TRADE HISTORY";

const TOOLTIPS_TEXT = {
  tradeHistoryToken: "Token name.",
  tradeHistoryType: "Buy when you bought the tokens, sell when you sold them.",
  tradeHistorySize: "Size of the trade in options.",
  tradeHistoryPrice: "The price per token at the time of trade.",
  tradeHistoryFees: "Borrow interest paid on a trade close.",
  tradeHistoryCollateral: "The collateral locked at the time of trade.",
  tradeHistoryValue: "Proceeds in $ USD from the trade. ",
  tradeHistoryTimestamp: "Date and time of trade."
};

const TradeHistoryPanel = () => {
  !localStorage.getItem("isPhpDisplayed") && localStorage.setItem("isPhpDisplayed", true);

  const [panelIsDisplayed, setPanelIsDisplayed] = useState(() => {
    return localStorage.getItem("isPhpDisplayed") === "true";
  });

  const [tooltipIsVisible, setTooltipIsVisible] = useState(false);
  const [tooltipTarget, setTooltipTarget] = useState("");
  const [tooltipContent, setTooltipContent] = useState("");

  const [expirationTimesSearchParam, setExpirationTimesSearchParam] = useState("");
  const [expirationTimesSelected, setExpirationTimesSelected] = useState(new Map());

  const markets = useSelector(marketsSelector);
  const marketsLoaded = useSelector(marketsLoadedSelector);
  const web3AccountLoaded = useSelector(web3AccountLoadedSelector);
  const trades = useSelector(tradesSelector);
  const tradesLoaded = useSelector(tradesLoadedSelector);
  const liquidationTrades = useSelector(liquidationTradesSelector);
  const liquidationTradesLoaded = useSelector(liquidationTradesLoadedSelector);
  const settlements = useSelector(settlementDataSelector);
  const currentTimestamp = useSelector(currentTimestampSelector);
  const openedTradePositions = useSelector(openedTradePositionsSelector);

  const tableWrapper = useRef(null);

  useEffect(() => {
    if (openedTradePositions && openedTradePositions.hasOwnProperty("length")) {
      initializeFilterOptions();
    }
  }, [openedTradePositions]);

  const initializeFilterOptions = () => {
    let initialExpirationTimesSearchParam = expirationTimesSearchParam;
    let newExpirationTimesSelected = new Map(expirationTimesSelected);
    let last5expirationsInSeconds = [];

    const marketIds = [...new Set(trades.map(trade => trade.marketId))];
    const expirationsInSeconds = [...new Set(markets.filter(market => marketIds.includes(market.marketId)).map(market => market.expirationTime))];
    expirationsInSeconds.sort((a, b) => Number(a) - Number(b));

    if (newExpirationTimesSelected.size === 0) {
      let closestExpirationDiff = Infinity;
      let closestExpiration = null;
      for (let i = 0; i < expirationsInSeconds.length; i++) {
        last5expirationsInSeconds.push(expirationsInSeconds[i]);
        const diff = currentTimestamp - expirationsInSeconds[i];
        if (diff < closestExpirationDiff) {
          closestExpirationDiff = diff;
          closestExpiration = expirationsInSeconds[i];
        }
      }

      if (closestExpiration) {
        !initialExpirationTimesSearchParam && (initialExpirationTimesSearchParam = "All Expirations");
        newExpirationTimesSelected.set("All Expirations", true);
        newExpirationTimesSelected.set("Last expiration", false);
        newExpirationTimesSelected.set("Liquidations", false);
        last5expirationsInSeconds.sort(function(a, b) {
          return b - a;
        });
        if (last5expirationsInSeconds.length > 5) {
          last5expirationsInSeconds = last5expirationsInSeconds.slice(0, 5);
        }
      } else {
        !initialExpirationTimesSearchParam && (initialExpirationTimesSearchParam = "All Expirations");
        newExpirationTimesSelected.set("All Expirations", true);
        newExpirationTimesSelected.set("Last expiration", false);
        newExpirationTimesSelected.set("Liquidations", false);
      }
    }

    const last5expirationsInSecondsSet = new Set([...last5expirationsInSeconds]);
    const allExpirationsMap = new Map();

    expirationsInSeconds.forEach(expirationInSeconds => {
      const expirationDate = new Date(expirationInSeconds * 1000);

      const day = expirationDate.getDate();

      const monthNumber = expirationDate.getMonth();
      const month = toMonthName(monthNumber + 1)
        .slice(0, 3)
        .toUpperCase();
      const year = expirationDate.getFullYear();

      const formattedExpirationDate = `${day} ${month} ${String(year).slice(2)}`;

      allExpirationsMap.set(expirationInSeconds, formattedExpirationDate);
    });

    let allExpirationsSorted = [];
    for (let [expInSec, _] of allExpirationsMap) {
      allExpirationsSorted.push(expInSec);
    }
    allExpirationsSorted.forEach(expInSeconds => {
      const selected = newExpirationTimesSelected.get(allExpirationsMap.get(expInSeconds));
      newExpirationTimesSelected.set(allExpirationsMap.get(expInSeconds), selected);
    });
    setExpirationTimesSearchParam(initialExpirationTimesSearchParam);
    setExpirationTimesSelected(newExpirationTimesSelected);
  };

  const tradesAreLoaded = () => {
    if (!tradesLoaded || !marketsLoaded || !liquidationTradesLoaded) {
      return false;
    }
    // Check Utility
    return checkAllUnderlyingTokenPriceExist(trades);
  };

  const areTradesLoaded = tradesAreLoaded();

  const isTradeAfterSettlement = (trade, market) => {
    const tradeTime = parseFloat(trade.timestamp);
    if (market.isSettled) {
      const settlement = settlements.find(s => s.expirationTime == market.expirationTime);
      if (settlement) {
        const settlementTime = parseFloat(settlement.settlementTime);
        if (tradeTime >= settlementTime) {
          return true;
        }
      }
    }

    return false;
  };

  const getTableData = () => {
    if (web3AccountLoaded && areTradesLoaded) {
      let sortableTrades = [...trades];
      sortableTrades.sort((a, b) => {
        return parseInt(b.timestamp) - parseInt(a.timestamp);
      });

      // filter out very small trades (dust)
      sortableTrades = sortableTrades.filter(trade => trade.size > 1000000 / 10 ** 18);
      // TODO: v2 it would be cleaner and testable if this mapping was done through selector
      const mappedTrades = sortableTrades.map((trade, index) => {
        const tokenName = trade.shortName;
        if (tokenName !== "...") {
          const isShort = trade.type === "short";
          const isLong = trade.type === "long";
          const market = markets.find(ex => ex.marketId === trade.marketId);
          const baseName = trade.tokenPair.baseTokenSymbol;
          const collName = market.isCall ? trade.tokenPair.underlyingTokenSymbol : trade.tokenPair.baseTokenSymbol;
          const isSettled = market.isSettled;

          // token
          let shortName = trade.shortName;

          const marketIsExpired = isSettled || Number(market.expirationTime) < currentTimestamp;
          const tradeTime = parseFloat(trade.timestamp);
          const tradeAfterSettlement = isTradeAfterSettlement(trade, market);
          let underlyingPriceAtExpired = 0;
          if (tradeAfterSettlement) {
            underlyingPriceAtExpired = getUnderlyingPriceAtExpiredNumber(market, settlements);
          }

          // TODO: v1 move to common (in PoolHistory duplicate code)
          if (tradeAfterSettlement) {
            shortName += " (EXPIRED $" + underlyingPriceAtExpired.toFixed(2) + ")";
          } else if (marketIsExpired) {
            const expirationTime = parseFloat(market.expirationTime);
            if (tradeTime >= expirationTime) {
              shortName += " (EXPIRED)";
            }
          }

          // type
          let type = trade.isBuy ? "buy" : "sell";
          if (tradeAfterSettlement) {
            type = "settle";
          }

          // size
          const size = trade.size;

          // price
          let tradeBasePrice = parseFloat(trade.basePrice); // without interest
          let displayTradeBasePrice = tradeBasePrice;
          if (tradeAfterSettlement) {
            if (market.isFuture) {
              displayTradeBasePrice = underlyingPriceAtExpired;
            } else {
              displayTradeBasePrice = market.isCall
                ? Math.max(0, underlyingPriceAtExpired - market.strikePrice)
                : Math.max(0, market.strikePrice - underlyingPriceAtExpired);
            }
          }

          // fees
          let feesArr = "";
          let fees = -trade.interest;
          if (fees < -0.005) {
            feesArr = [fees, ""];
          }

          // collateral
          // todo: if long and reduces debt, it shoud show collateral
          let collateralArr = "";
          if (isShort) {
            collateralArr = [(trade.isBuy ? 1 : -1) * trade.size * (market.isCall ? 1 : market.strikePrice), collName];
          }

          // proceeds (opposite of cost)
          let proceeds = -size * tradeBasePrice;
          if (!trade.isBuy) {
            proceeds *= -1;
          }
          proceeds -= trade.interest;
          const proceedsArr = [proceeds, baseName];
          // liquidated
          let liquidated = trade.isLiquidation;
          const { liquidatorColor, liquidatorImg } = getLiquidatorColorAndImage(trade);

          return {
            id: trade,
            token: [shortName, liquidated, liquidatorColor, liquidatorImg],
            type: type,
            size: trade.size,
            price: [displayTradeBasePrice, baseName],
            collateral: collateralArr,
            fees: feesArr,
            value: proceedsArr,
            expirationTime: parseInt(market.expirationTime),
            timestamp: [trade.readableTimestamp, trade.timestamp]
          };
        }
      });

      // account liquidation trades (penalties and rewards)
      const mappedLiquidationTrades = liquidationTrades.map((trade, index) => {
        const { liquidatorColor, liquidatorImg } = getLiquidatorColorAndImage(trade);
        // NOTE: contract event returns 110%, so we divide by 11 or 22 (10% or 5%) to get the penalty/reward part
        // todo: this might not be correct for all cases of liquidation, smart contract needs to be updated
        const userPart = trade.isBuy ? 1 / 22 : -1 / 11;
        let proceeds = parseFloat(trade.baseAmount) * userPart;
        const proceedsArr = [proceeds, "USD"];

        return {
          id: trade,
          token: ["Account Liquidation", true, liquidatorColor, liquidatorImg],
          type: trade.isBuy ? "reward" : "penalty",
          size: 0,
          price: "",
          collateral: "",
          fees: "",
          value: proceedsArr,
          expirationTime: parseInt(0),
          timestamp: [trade.readableTimestamp, trade.timestamp]
        };
      });

      // mapping can introduce undefined elements, so we filter them out
      const mappedFilteredTrades = mappedTrades.filter(trade => trade !== undefined);
      const tradesFinal = [...mappedLiquidationTrades, ...mappedFilteredTrades];
      const tradesFinalSorted = tradesFinal.sort((a, b) => {
        return b.timestamp[1] - a.timestamp[1];
      });

      return tradesFinalSorted;
    }

    return [];
  };

  const getLiquidatorColorAndImage = trade => {
    let liquidatorColor;
    let liquidatorImg;
    if (trade.isBuy === true && trade.type === "long") {
      liquidatorColor = "#8fce51";
      liquidatorImg = "green";
    } else if (trade.isBuy === false && trade.type === "long") {
      liquidatorColor = "#ee1b41";
      liquidatorImg = "red";
    } else if (trade.isBuy === true && trade.type === "short") {
      liquidatorColor = "#ee1b41";
      liquidatorImg = "red";
    } else if (trade.isBuy === false && trade.type === "short") {
      liquidatorColor = "#8fce51";
      liquidatorImg = "green";
    } else if (trade.isBuy === true && trade.type === "lp") {
      liquidatorColor = "#8fce51";
      liquidatorImg = "green";
    } else if (trade.isBuy === false && trade.type === "lp") {
      liquidatorColor = "#ee1b41";
      liquidatorImg = "red";
    } else if (trade.isBuy === true && trade.type === "account") {
      liquidatorColor = "#8fce51";
      liquidatorImg = "green";
    } else if (trade.isBuy === false && trade.type === "account") {
      liquidatorColor = "#ee1b41";
      liquidatorImg = "red";
    }

    return { liquidatorColor, liquidatorImg };
  };

  const onMouseEnterColumnHeader = e => {
    if (!tooltipIsVisible) {
      setTooltipIsVisible(true);
      setTooltipContent(TOOLTIPS_TEXT[e.currentTarget.getAttribute("name")]);
      setTooltipTarget(e.currentTarget.id);
    }
  };

  const onMouseLeaveColumnHeader = e => {
    if (!cursorRangeCheck(tooltipTarget, e.clientX, e.clientY)) {
      setTooltipIsVisible(false);
      setTooltipContent("");
      setTooltipTarget("");
      document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", -500 + "px");

      document.querySelector(":root").style.setProperty("--tooltipTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipLeftPosition", -500 + "px");
    }
  };

  const getTableColumns = () => {
    const tableColumns = [
      {
        Header: () => (
          <div className="alignLeft">
            <span id="trade-history-token" name="tradeHistoryToken" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Token
            </span>
          </div>
        ),
        accessor: "token",
        align: "right",
        minWidth: 100,
        maxWidth: 5 * 100,
        filterable: false,
        sortable: false,
        Cell: row => {
          return (
            <>
              <span className="alignLeft">{row.value[0]}</span>
              &nbsp; &nbsp; &nbsp;
              <img className={row.value[1] ? (row.value[3] === "green" ? "liquidationGreen" : row.value[3] === "red" ? "liquidationRed" : "") : ""} />
              &nbsp;
              <span className="alignLeft" style={{ color: row.value[2] }}>
                {row.value[1] ? "(liquidated)" : ""}
              </span>
            </>
          );
        },
        Footer: () => (
          <span className="floatLeft">
            <strong>Total&nbsp;&nbsp;{tableData.length === 0 && "P/L"}</strong>
          </span>
        )
      },
      {
        Header: () => (
          <div>
            <span id="trade-history-type" name="tradeHistoryType" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Type
            </span>
          </div>
        ),
        accessor: "type",
        align: "right",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: false,
        Cell: row => <span className="floatRight">{row.value}</span>
      },
      {
        Header: () => (
          <div>
            <span id="trade-history-size" name="tradeHistorySize" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              QTY
            </span>
          </div>
        ),
        accessor: "size",
        minWidth: 30,
        maxWidth: 5 * 30,
        filterable: false,
        sortable: false,
        Cell: row => <span className="floatRight">{row.value !== 0 ? formatValue(row.value, 2) : ""}</span>
      },
      {
        Header: () => (
          <div>
            <span id="trade-history-price" name="tradeHistoryPrice" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Price
            </span>
          </div>
        ),
        accessor: "price",
        minWidth: 30,
        maxWidth: 5 * 30,
        filterable: false,
        sortable: false,
        Cell: row => <span className="floatRight monospace">{row.value !== "" ? printPriceFromString(row.value[0].toFixed(2)) : ""}</span>,
        sortMethod: (a, b) => {
          return b[0] - a[0];
        }
      },
      {
        Header: () => (
          <div>
            <span id="trade-history-collateral" name="tradeHistoryCollateral" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Collateral
            </span>
          </div>
        ),
        accessor: "collateral",
        minWidth: 40,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        Cell: row => <span className="floatRight">{row.value.length === 2 ? row.value[0].toFixed(2) + " " + row.value[1] : row.value[0]}</span>
      },
      {
        Header: () => (
          <div>
            <span id="trade-history-fees" name="tradeHistoryFees" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Interest
            </span>
          </div>
        ),
        accessor: "fees",
        minWidth: 40,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        Cell: row => (
          <span className="floatRight">{row.value.length === 2 ? printPriceFromString(row.value[0].toFixed(2)) + " " + row.value[1] : row.value[0]}</span>
        )
      },
      {
        Header: () => (
          <div>
            <span id="trade-history-value" name="tradeHistoryValue" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Total Premium
            </span>
          </div>
        ),
        accessor: "value",
        minWidth: 40,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        Cell: row => <span className="floatRight monospace">{printPriceFromString(row.value[0].toFixed(2))}</span>,
        sortMethod: (a, b) => {
          return b[0] - a[0];
        },
        Footer: rows => (
          <span className="floatRight monospace">
            <strong>
              {rows.data.length > 0
                ? getValueFooter(rows) === 0 || Math.abs(getValueFooter(rows)).toFixed(2) === "0.00"
                  ? printPriceFromString("0")
                  : rows.data && printPriceFromString(getValueFooter(rows).toFixed(2))
                : ""}
            </strong>
          </span>
        )
      },
      {
        Header: () => (
          <div>
            <span id="trade-history-timestamp" name="tradeHistoryTimestamp" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Timestamp
            </span>
          </div>
        ),
        accessor: "timestamp",
        minWidth: 40,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        Cell: row => <span className="floatRight">{row.value[0]}</span>,
        sortMethod: (a, b) => {
          return b[1] - a[1];
        }
      }
    ];
    return tableColumns;
  };

  let allData = getTableData();

  const filterTableData = () => {
    let filteredData = [];

    // use penalties and rewards if "Liquidations" is selected
    if (expirationTimesSearchParam === "Liquidations") {
      return allData.filter(d => d.type === "penalty" || d.type === "reward");
    }

    let formattedLastExpirationDate = null;
    for (let [expirationTime, _] of expirationTimesSelected) {
      if (expirationTime !== "Last expiration" && expirationTime !== "All Expirations") {
        formattedLastExpirationDate = expirationTime;
        // break;
      }
    }

    allData.forEach(d => {
      // for (let d of allData) {
      const expirationInSeconds = d.expirationTime;
      const expirationDate = new Date(expirationInSeconds * 1000);

      const day = expirationDate.getDate();
      const monthNumber = expirationDate.getMonth();
      const month = toMonthName(monthNumber + 1)
        .slice(0, 3)
        .toUpperCase();
      const year = expirationDate.getFullYear();

      const formattedExpirationDate = `${day} ${month} ${String(year).slice(2)}`;
      if (
        (expirationTimesSearchParam === "Last expiration" && formattedExpirationDate !== formattedLastExpirationDate) ||
        (expirationTimesSearchParam !== "All Expirations" &&
          expirationTimesSearchParam !== "Last expiration" &&
          !expirationTimesSelected.get(formattedExpirationDate))
      ) {
        return;
      }
      filteredData.push(d);
    });
    return filteredData;
  };

  const tableData = filterTableData();

  const printTable = () => {
    // if (web3AccountLoaded && areTradesLoaded && tableData.length > 0) {
    if (web3AccountLoaded && areTradesLoaded && allData.length > 0) {
      return (
        <div key="tableHistoryWrapper" ref={tableWrapper}>
          <ReactTable
            // className="-highlight"
            data={tableData}
            // filterable
            columns={getTableColumns()}
            defaultPageSize={5}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <FilterContext.Consumer>
        {({ closeAllFilterDropmenus }) => {
          return (
            <>
              <FiltersHistory
                parent={tableWrapper.current}
                expirationTimesSearchParam={expirationTimesSearchParam}
                expirationTimesSelected={expirationTimesSelected}
                setExpirationTimesSearchParam={setExpirationTimesSearchParam}
                setExpirationTimesSelected={setExpirationTimesSelected}
              />
              <div className="content" style={{ paddingTop: "10px" }}>
                <Row>
                  <Col className="mb-1" md="12">
                    <Card>
                      <CardHeader
                        className="handle"
                        onDoubleClick={e => {
                          e.stopPropagation();
                          closeAllFilterDropmenus();
                          localStorage.getItem("isPhpDisplayed") === "true"
                            ? localStorage.setItem("isPhpDisplayed", false)
                            : localStorage.setItem("isPhpDisplayed", true);
                          setPanelIsDisplayed(panelIsDisplayed => !panelIsDisplayed);
                        }}
                      >
                        <CardTitle tag="h4">
                          <div className="historyHeaderContainer">
                            <span className="headerTitle">{CARD_TITLE}</span>

                            {panelIsDisplayed && (
                              <>
                                <div
                                  id="phpArrowUp"
                                  className="arrowHeaderWrapper"
                                  onClick={e => {
                                    e.stopPropagation();
                                    closeAllFilterDropmenus();
                                    setPanelIsDisplayed(false);
                                    localStorage.setItem("isPhpDisplayed", false);
                                    setTooltipContent("Show panel");
                                  }}
                                  onMouseEnter={e => {
                                    if (!tooltipIsVisible) {
                                      setTooltipIsVisible(true);
                                      setTooltipContent("Hide panel");
                                      setTooltipTarget(e.currentTarget.id);
                                    }
                                  }}
                                  onMouseLeave={onMouseLeaveColumnHeader}
                                >
                                  <img style={{ margin: "auto" }} className="arrowUpIcon" />
                                </div>
                              </>
                            )}

                            {!panelIsDisplayed && (
                              <>
                                <div
                                  id="phpArrowDown"
                                  className="arrowHeaderWrapper"
                                  onClick={e => {
                                    e.stopPropagation();
                                    closeAllFilterDropmenus();
                                    setPanelIsDisplayed(true);
                                    localStorage.setItem("isPhpDisplayed", true);
                                    setTooltipContent("Hide panel");
                                  }}
                                  onMouseEnter={e => {
                                    if (!tooltipIsVisible) {
                                      setTooltipIsVisible(true);
                                      setTooltipContent("Show panel");
                                      setTooltipTarget(e.currentTarget.id);
                                    }
                                  }}
                                  onMouseLeave={onMouseLeaveColumnHeader}
                                >
                                  <img style={{ margin: "auto" }} className="arrowDownIcon" />
                                </div>
                              </>
                            )}
                          </div>
                        </CardTitle>
                      </CardHeader>
                      <CardBody id={"php"} className={panelIsDisplayed ? "" : "displayNone"}>
                        {printTable()}
                        {printEmptyTable(web3AccountLoaded, areTradesLoaded, allData, EMPTY_TABLE_TEXT)}
                        {printLoadingIcon(web3AccountLoaded, areTradesLoaded)}
                        <ConnectPanel />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </>
          );
        }}
      </FilterContext.Consumer>
      {tooltipIsVisible && (
        <MyTooltip2 target={tooltipTarget} setTooltipIsVisible={setTooltipIsVisible} setTooltipContent={setTooltipContent} setTooltipTarget={setTooltipTarget}>
          {tooltipContent}
        </MyTooltip2>
      )}
    </>
  );
};

export default TradeHistoryPanel;
