/* eslint-disable no-undef */
// import moment from "moment";
// import React from "react";
// import { matchPath } from "react-router";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
// import {
//   getInputAmountWhenBuyingFuturesUpToTargetImpact,
//   getInputAmountWhenBuyingOptionsUpToBaseBalance,
//   getInputAmountWhenBuyingOptionsUpToTargetImpact,
//   getInputAmountWhenSellingFuturesDownToTargetImpact,
//   getInputAmountWhenSellingOptionsDownToTargetImpact,
//   getMaxLongAmountWhenBuyingOptions,
//   getMaxLongAmountWhenSellingOptions,
//   getOraclePriceMaxBuyAmount,
//   getOraclePriceMaxSellAmount,
//   isMarketEmpty
// } from "./MarketHelper.mjs";
// import { remSep } from "string-remove-thousand-separators";
// import { formatUnits } from "ethers/lib/utils";
// import { toBn } from "evm-bn";

//let iv = require("implied-volatility");
//let bs = require("black-scholes");
import moment from "moment";
import pkg from "black-scholes";
const { blackScholes } = pkg;

export const SECONDS_IN_YEAR = 31536000;

//this is when we don't have entered anything
export const defaultInputValue = 0.000001;

export const TICK_SPACING = 32;
// calculate option price based on Black-Scholes
export function getOptionPrice(riskFreeRate, expirationTime, strikePrice, isCall, underlyingPrice, iv, currentTime = moment().unix()) {
  const timeToExpirationInYears = Math.max(0, (expirationTime - currentTime) / SECONDS_IN_YEAR);
  if (underlyingPrice === strikePrice && timeToExpirationInYears === 0) {
    return 0;
  }

  return blackScholes(underlyingPrice, strikePrice, timeToExpirationInYears, iv / 100, riskFreeRate, isCall ? "call" : "put");
}

export function getFee(underPrice, optionPrice, minFee = 3000, maxFee = 500000, steepness = 1000) {
  if (optionPrice === 0) {
    return maxFee;
  }

  return Math.min(maxFee, Math.max(minFee, (steepness * underPrice) / optionPrice));
}

export function toPreciseString(value, maxDigits = 18) {
  return value.toLocaleString("fullwide", { useGrouping: false, maximumFractionDigits: maxDigits });
}

export function logBase(base, number) {
  return Math.log(number) / Math.log(base);
}