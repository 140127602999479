import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import DepositWithdrawHistoryPanel, { POOL_NAME_INSURANCE_FUND } from "./DepositWithdrawHistoryPanel.jsx";
import { connect } from "react-redux";
import { web3AccountLoadedSelector } from "../../store/selectors/web3Selectors.js";
import {
  insuranceFundDepositsAndWithdrawalsLoadedSelector,
  insuranceFundDepositsAndWithdrawalsSelector,
  insuranceFundLoadedSelector,
  insuranceFundLossesCoveredLoadedSelector,
  insuranceFundLossesCoveredSelector,
  insuranceFundWithUnclaimedSelector,
  insurerBalanceWithUnclaimedSelector,
  insurerDataLoadedSelector,
  insurerDataSelector
} from "../../store/selectors/insuranceFundSelector.js";
import { insuranceFundSelector } from "../../store/selectors/contractsSelectors.js";
import { displayLoadingIconOld, printPriceFromNumber } from "../../utils/utils.js";
import DepositInsuranceFundsModal from "./DepositInsuranceFundsModal.jsx";
import { avgUnderlyingTokenPriceSelector, tokenBalancesSelector, tokensLoadedSelector } from "../../store/selectors/tokensSelectors.js";
import WithdrawInsuranceFundsModal from "./WithdrawInsuranceFundsModal.jsx";
import LossesCoveredHistoryPanel from "./LossesCoveredHistoryPanel.jsx";
import { getRollingAPR } from "../../store/utils/insuranceFundUtils.js";
import {
  allLiquidationTradesSelector,
  liquidationTradesLoadedSelector,
  lpTradesLoadedSelector,
  tradesLoadedSelector,
  allLPTradesSelector,
  allTradesSelector
} from "../../store/selectors/positionsManagerSelectors.js";
import MyTooltip from "./MyTooltip.jsx";

class InsuranceFund extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDepositModalOpen: false,
      isWithdrawModalOpen: false,
      isPositionToken: false,
      idPrefix: "insurance-page-"
    };
  }

  toggleDepositModal = () => {
    this.setState({ isDepositModalOpen: !this.state.isDepositModalOpen });
    document.querySelector("body").classList.toggle("scrollDisabled");
  };

  toggleWithdrawModal = () => {
    this.setState({ isWithdrawModalOpen: !this.state.isWithdrawModalOpen });
    document.querySelector("body").classList.toggle("scrollDisabled");
  };

  onDepositEventHandler = e => {
    e.stopPropagation();
    this.setState({ isDepositModalOpen: true });
    document.querySelector("body").classList.toggle("scrollDisabled");
  };

  onWithdrawEventHandler = e => {
    e.stopPropagation();
    this.setState({ isWithdrawModalOpen: true });
    document.querySelector("body").classList.toggle("scrollDisabled");
  };

  arePropsLoaded = () => {
    return (
      this.props.insuranceFundLoaded &&
      this.props.insurerDataLoaded &&
      this.props.tradesLoaded &&
      this.props.lpTradesLoaded &&
      this.props.liquidationTradesLoaded &&
      this.props.insuranceFundLossesCoveredLoaded
    );
  };

  render() {
    let avgAPR = 0;
    if (this.arePropsLoaded()) {
      const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
      // console.log("liquidationTrades", this.props.liquidationTrades);
      const startTime = Date.now();
      avgAPR = getRollingAPR(
        this.props.insuranceFundDepositsAndWithdrawals,
        this.props.trades,
        this.props.lpTrades,
        this.props.liquidationTrades,
        this.props.insuranceFundLossesCovered,
        underPrice
      );
      const endTime = Date.now();
      console.log("getRollingAPR took:", endTime - startTime, "mS");
    }

    const supplied = this.props.insuranceFundLoaded ? printPriceFromNumber(this.props.insuranceFundWithUnclaimed) : displayLoadingIconOld();

    //TODO: replace this later with values from events
    const principal = this.arePropsLoaded() ? printPriceFromNumber(this.props.insurerData.principal) : displayLoadingIconOld();
    const proceeds = this.arePropsLoaded() ? printPriceFromNumber(this.props.insurerData.proceeds) : displayLoadingIconOld();
    const pnl = this.arePropsLoaded() ? printPriceFromNumber(this.props.insurerData.pnl) : displayLoadingIconOld();
    const apr = this.arePropsLoaded() ? avgAPR.toFixed(2) + "%" : displayLoadingIconOld();

    const usdToken = this.props.tokensLoaded ? this.props.tokens[0] : null;

    const depositDisabled = !this.props.insuranceFundLoaded;
    const withdrawDisabled = !this.props.insuranceFundLoaded || (this.props.insuranceFundLoaded && this.props.insurerBalanceWithUnclaimed == 0);

    return (
      <>
        {this.state.isWithdrawModalOpen && (
          <WithdrawInsuranceFundsModal
            isOpen={this.state.isWithdrawModalOpen}
            toggle={this.toggleWithdrawModal}
            token={usdToken}
            isPositionToken={this.state.isPositionToken}
          />
        )}
        {this.state.isDepositModalOpen && (
          <DepositInsuranceFundsModal
            isOpen={this.state.isDepositModalOpen}
            toggle={this.toggleDepositModal}
            token={usdToken}
            isPositionToken={this.state.isPositionToken}
          />
        )}
        <div id="insuranceFundContent" className="content">
          <div id="insuranceFundContainer">
            <span id="insuranceFundText">INSURANCE FUND</span>
            <div id="poolAccountInsuranceFundContainer">
              <div id="poolInsuranceFundContainer">
                <span className="poolAccountTitle">POOL</span>
                <div className="lendBorrowContainer">
                  <table className="lendLayoutTable">
                    <tr>
                      <th id={this.state.idPrefix + "col-token"} className="tableFirstColumn">
                        TOKEN
                      </th>
                      <MyTooltip key={this.state.idPrefix + "col-token"} target={this.state.idPrefix + "col-token"} optionalOffset={15}>
                        Name of the token
                      </MyTooltip>
                      <th id={this.state.idPrefix + "col-supply"}>SUPPLIED</th>
                      <MyTooltip key={this.state.idPrefix + "col-supply"} target={this.state.idPrefix + "col-supply"} optionalOffset={15}>
                        Total amount of tokens supplied to the insurance fund.
                      </MyTooltip>
                      <th id={this.state.idPrefix + "col-apr"}>APR</th>
                      <MyTooltip key={this.state.idPrefix + "col-apr"} target={this.state.idPrefix + "col-apr"} optionalOffset={15}>
                        Interest rate depositors are receiving, calculated as the average of the last 7 days.
                      </MyTooltip>
                    </tr>
                    <tr className="poolAccountDataRow">
                      <td className="tableFirstColumn">USDC</td>
                      <td>{supplied}</td>
                      <td id="aprInsuranceFundColor">{apr}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div id="accountInsuranceFundContainer">
                <span className="poolAccountTitle">ACCOUNT</span>
                <div className="lendBorrowContainer">
                  <table className="lendLayoutTable">
                    <tr>
                      <th id={this.state.idPrefix + "col-principal"} className="tableFirstColumn">
                        PRINCIPAL
                      </th>
                      <MyTooltip key={this.state.idPrefix + "col-principal"} target={this.state.idPrefix + "col-principal"} optionalOffset={15}>
                        The amount of tokens you deposited to the insurance fund on which you are receiving interest.
                      </MyTooltip>
                      <th id={this.state.idPrefix + "col-pnl"}>P/L</th>
                      <MyTooltip key={this.state.idPrefix + "col-pnl"} target={this.state.idPrefix + "col-pnl"} optionalOffset={15}>
                        Profit/Loss (P/L) of your deposit.
                      </MyTooltip>
                      <th id={this.state.idPrefix + "col-total"}>TOTAL</th>
                      <MyTooltip key={this.state.idPrefix + "col-total"} target={this.state.idPrefix + "col-total"} optionalOffset={15}>
                        The sum of principal and P/L accrued, expressed in $ USD.
                      </MyTooltip>
                      <th className="actionsHeader">ACTIONS</th>
                    </tr>
                    <tr className="poolAccountDataRow">
                      <td className="tableFirstColumn">{principal}</td>
                      <td>{pnl}</td>
                      <td>{proceeds}</td>
                      <td id="depositWithdrawButtonsContainer">
                        <button className="depositWithdrawInsuranceFundButton" disabled={depositDisabled} onClick={this.onDepositEventHandler}>
                          DEPOSIT
                        </button>
                        <button className="depositWithdrawInsuranceFundButton" disabled={withdrawDisabled} onClick={this.onWithdrawEventHandler}>
                          WITHDRAW
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <Row key="DepositWithdrawHistoryPanelInsuranceFundKey">
              <Col className="mb-1" md="12">
                <DepositWithdrawHistoryPanel targetFund={POOL_NAME_INSURANCE_FUND} />
              </Col>
            </Row>
            <Row key="LossesCoveredHistoryPanelInsuranceFundKey">
              <Col className="mb-1" md="12">
                <LossesCoveredHistoryPanel targetFund={POOL_NAME_INSURANCE_FUND} />
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

InsuranceFund.propTypes = {
  web3AccountLoaded: PropTypes.bool,
  insuranceFundLoaded: PropTypes.bool,
  insuranceFundWithUnclaimed: PropTypes.number,
  insurerBalanceWithUnclaimed: PropTypes.number,
  insurerDataLoaded: PropTypes.bool,
  insurerData: PropTypes.object,
  insuranceFundContract: PropTypes.object,
  tokensLoaded: PropTypes.bool,
  tokens: PropTypes.array,
  insuranceFundDepositsAndWithdrawalsLoaded: PropTypes.bool,
  insuranceFundDepositsAndWithdrawals: PropTypes.array,
  insuranceFundLossesCoveredLoaded: PropTypes.bool,
  insuranceFundLossesCovered: PropTypes.array,
  tradesLoaded: PropTypes.bool,
  trades: PropTypes.array,
  lpTradesLoaded: PropTypes.bool,
  lpTrades: PropTypes.array,
  liquidationTradesLoaded: PropTypes.bool,
  liquidationTrades: PropTypes.array,
  underlyingTokenPrice: PropTypes.object
};

function mapStateToProps(state) {
  return {
    web3AccountLoaded: web3AccountLoadedSelector(state),
    insuranceFundLoaded: insuranceFundLoadedSelector(state),
    insuranceFundWithUnclaimed: insuranceFundWithUnclaimedSelector(state),
    insurerBalanceWithUnclaimed: insurerBalanceWithUnclaimedSelector(state),
    insurerDataLoaded: insurerDataLoadedSelector(state),
    insurerData: insurerDataSelector(state),
    insuranceFundContract: insuranceFundSelector(state),
    tokensLoaded: tokensLoadedSelector(state),
    tokens: tokenBalancesSelector(state),
    insuranceFundDepositsAndWithdrawalsLoaded: insuranceFundDepositsAndWithdrawalsLoadedSelector(state),
    insuranceFundDepositsAndWithdrawals: insuranceFundDepositsAndWithdrawalsSelector(state),
    insuranceFundLossesCoveredLoaded: insuranceFundLossesCoveredLoadedSelector(state),
    insuranceFundLossesCovered: insuranceFundLossesCoveredSelector(state),
    tradesLoaded: tradesLoadedSelector(state),
    trades: allTradesSelector(state),
    lpTradesLoaded: lpTradesLoadedSelector(state),
    lpTrades: allLPTradesSelector(state),
    liquidationTradesLoaded: liquidationTradesLoadedSelector(state),
    liquidationTrades: allLiquidationTradesSelector(state),
    underlyingTokenPrice: avgUnderlyingTokenPriceSelector(state)
  };
}

export default connect(mapStateToProps)(InsuranceFund);
