/*!

=========================================================
* Black Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import configureStore, { store } from "./store/configureStore";
// import * as serviceWorker from "./serviceWorker";

import App from "./App.jsx";

// import { ApolloClient, InMemoryCache, ApolloProvider, gql } from "@apollo/client";

const hist = createBrowserHistory();

// export const client = new ApolloClient({
//   uri: "http://0.0.0.0:8000/subgraphs/name/Gamma/test",
//   cache: new InMemoryCache()
// });

ReactDOM.render(
  // <Provider store={configureStore()}>
  <Provider store={store}>
    <App history={hist} />
  </Provider>,
  document.getElementById("root")
);

// serviceWorker.register();
