import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { FilterContext } from "../../layouts/market/MainRouter.jsx";
import * as actions from "../../store/actions/actions.js";
import { expirationTimesSelector, selectedExpirationTimeSelector } from "../../store/selectors/dateUtilsSelectors";
import { selectedTokenPairSelector } from "../../store/selectors/tokensSelectors";
import { web3AccountLoadedSelector } from "../../store/selectors/web3Selectors";

class MenuPanel extends React.Component {
  componentDidMount() {
    document.documentElement.classList.remove("nav-open");
  }

  render() {
    return (
      <FilterContext.Consumer>
        {({ selectedExpirationTime }) => {
          return (
            <div className="nav">
              <NavLink
                to={`/trade/eth-usd/${selectedExpirationTime.urlFormat}`}
                className={({ isActive, isPending }) =>
                  !window.location.pathname.includes("trade") ? "navItem" : window.location.pathname.includes("trade") ? "navItem activeNav" : ""
                }
              >
                <span>Trade</span>
              </NavLink>
              <NavLink
                to={`/pool/eth-usd/${selectedExpirationTime.urlFormat}`}
                className={({ isActive, isPending }) =>
                  !window.location.pathname.includes("pool") ? "navItem" : window.location.pathname.includes("pool") ? "navItem activeNav" : ""
                }
              >
                Pool
              </NavLink>
              <NavLink
                to={"/portfolio"}
                className={({ isActive, isPending }) =>
                  !window.location.pathname.includes("portfolio") ? "navItem" : window.location.pathname.includes("portfolio") ? "navItem activeNav" : ""
                }
              >
                Portfolio
              </NavLink>
              <NavLink
                to={"/lend"}
                className={({ isActive, isPending }) =>
                  !window.location.pathname.includes("lend") ? "navItem" : window.location.pathname.includes("lend") ? "navItem activeNav" : ""
                }
              >
                Lend
              </NavLink>
            </div>
          );
        }}
      </FilterContext.Consumer>
    );
  }
}

MenuPanel.propTypes = {
  web3AccountLoaded: PropTypes.bool,
  expirationTimes: PropTypes.array,
  selectedTokenPair: PropTypes.object,
  selectedExpirationTime: PropTypes.object,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    web3AccountLoaded: web3AccountLoadedSelector(state),
    expirationTimes: expirationTimesSelector(state),
    selectedExpirationTime: selectedExpirationTimeSelector(state),
    selectedTokenPair: selectedTokenPairSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuPanel);
