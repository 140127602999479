/* eslint-disable react/no-multi-comp */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReactTable from "react-table";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { web3AccountLoadedSelector, web3AccountSelector } from "../../store/selectors/web3Selectors";
import ConnectPanel from "./ConnectPanel.jsx";
import { printEmptyTable, printLoadingIcon } from "./utilities/TradePanels/PrintUtility.jsx";
import { cursorRangeCheck } from "../../utils/utils.js";
import { FilterContext } from "../../layouts/market/MainRouter.jsx";
import MyTooltip2 from "./MyTooltip2.jsx";
import { depositsAndWithdrawalsLoadedSelector, depositsAndWithdrawalsSelector } from "../../store/selectors/balancesSelectors";
import { insuranceFundDepositsAndWithdrawalsLoadedSelector, insuranceFundDepositsAndWithdrawalsSelector } from "../../store/selectors/insuranceFundSelector";
import PropTypes from "prop-types";

const EMPTY_TABLE_TEXT = "You haven't made any deposits/withdrawals.";
const CARD_TITLE = "DEPOSIT/WITHDRAW HISTORY";

const TOOLTIPS_TEXT = {
  depositWithdrawHistoryToken: "Name of the token.",
  depositWithdrawHistoryType: "Deposit or withdrawal to your account.",
  depositWithdrawHistorySize: "Size of the deposit/withdrawal in tokens.",
  depositWithdrawHistoryValue: "Value in $ USD of the deposit/withdrawal.",
  depositWithdrawHistoryTimestamp: "Date and time of deposit/withdrawal."
};

export const POOL_NAME_MARGIN_ACCOUNT = "Margin Account";
export const POOL_NAME_MARGIN_POOL = "Margin Pool";
export const POOL_NAME_INSURANCE_FUND = "Insurance Fund";

const DepositWithdrawHistoryPanel = ({ targetFund }) => {
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false);
  const [tooltipTarget, setTooltipTarget] = useState("");
  const [tooltipContent, setTooltipContent] = useState("");

  const web3AccountLoaded = useSelector(web3AccountLoadedSelector);
  const web3Account = useSelector(web3AccountSelector);

  const depositsAndWithdrawalsLoaded = useSelector(depositsAndWithdrawalsLoadedSelector);
  const depositsAndWithdrawals = useSelector(depositsAndWithdrawalsSelector);
  const insuranceFundDepositsAndWithdrawalsLoaded = useSelector(insuranceFundDepositsAndWithdrawalsLoadedSelector);
  const insuranceFundDepositsAndWithdrawals = useSelector(insuranceFundDepositsAndWithdrawalsSelector);
  const idPrefix = "deposit-withdraw-history-panel-"; // todo: why isn't this used?

  const targetPool = targetFund;

  const depositsAndWithdrawalsAreLoaded = () => {
    if (targetPool === POOL_NAME_MARGIN_ACCOUNT) {
      return !!depositsAndWithdrawalsLoaded;
    } else if (targetPool === POOL_NAME_INSURANCE_FUND) {
      return !!insuranceFundDepositsAndWithdrawalsLoaded;
    }

    return false;
  };

  const getTableData = () => {
    if (targetPool === POOL_NAME_MARGIN_ACCOUNT) {
      if (web3AccountLoaded && depositsAndWithdrawalsAreLoaded()) {
        let sortableDepositsAndWithdrawals = [...depositsAndWithdrawals];
        sortableDepositsAndWithdrawals.sort((a, b) => {
          return parseInt(b.timestamp) - parseInt(a.timestamp);
        });

        return sortableDepositsAndWithdrawals.map(dw => {
          return {
            token: dw.tokenSymbol === "USD" ? "USDC" : dw.tokenSymbol,
            type: dw.isDeposit ? "DEPOSIT" : "WITHDRAWAL",
            qty: dw.printableAmount,
            value: dw.printableValue,
            timestamp: dw.readableTimestamp
          };
        });
      }
    }

    if (targetPool === POOL_NAME_INSURANCE_FUND) {
      if (web3AccountLoaded && depositsAndWithdrawalsAreLoaded) {
        const insuranceFundDepositsAndWithdrawalsFiltered = insuranceFundDepositsAndWithdrawals.filter(daw => daw.user === web3Account);
        let sortableDepositsAndWithdrawals = [...insuranceFundDepositsAndWithdrawalsFiltered];
        sortableDepositsAndWithdrawals.sort((a, b) => {
          return parseInt(b.timestamp) - parseInt(a.timestamp);
        });

        return sortableDepositsAndWithdrawals.map(dw => {
          return {
            token: dw.tokenSymbol === "USD" ? "USDC" : dw.tokenSymbol,
            type: dw.isDeposit ? "DEPOSIT" : "WITHDRAWAL",
            qty: dw.printableAmount,
            value: dw.printableValue,
            timestamp: dw.readableTimestamp
          };
        });
      }
    }

    return [];
  };

  const onMouseEnterColumnHeader = e => {
    if (!tooltipIsVisible) {
      setTooltipIsVisible(true);
      setTooltipContent(TOOLTIPS_TEXT[e.currentTarget.getAttribute("name")]);
      setTooltipTarget(e.currentTarget.id);
    }
  };

  const onMouseLeaveColumnHeader = e => {
    if (!cursorRangeCheck(tooltipTarget, e.clientX, e.clientY)) {
      setTooltipIsVisible(false);
      setTooltipContent("");
      setTooltipTarget("");
      document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", -500 + "px");

      document.querySelector(":root").style.setProperty("--tooltipTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipLeftPosition", -500 + "px");
    }
  };

  const getTableColumns = () => {
    const tableColumns = [
      {
        Header: () => (
          <div className="alignLeft">
            <span
              id="deposit-withdraw-history-token"
              name="depositWithdrawHistoryToken"
              onMouseEnter={onMouseEnterColumnHeader}
              onMouseLeave={onMouseLeaveColumnHeader}
            >
              Token
            </span>
          </div>
        ),
        accessor: "token",
        align: "right",
        minWidth: 100,
        maxWidth: 5 * 100,
        filterable: false,
        sortable: false,
        Cell: row => {
          return (
            <>
              <span className="alignLeft">{row.value}</span>
            </>
          );
        }
      },
      {
        Header: () => (
          <div>
            <span
              id="deposit-withdraw-history-type"
              name="depositWithdrawHistoryType"
              onMouseEnter={onMouseEnterColumnHeader}
              onMouseLeave={onMouseLeaveColumnHeader}
            >
              Type
            </span>
          </div>
        ),
        accessor: "type",
        align: "right",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: false,
        Cell: row => <span style={{ float: "right" }}>{row.value}</span>
      },
      {
        Header: () => (
          <div>
            <span
              id="deposit-withdraw-history-size"
              name="depositWithdrawHistorySize"
              onMouseEnter={onMouseEnterColumnHeader}
              onMouseLeave={onMouseLeaveColumnHeader}
            >
              QTY
            </span>
          </div>
        ),
        accessor: "qty",
        minWidth: 30,
        maxWidth: 5 * 30,
        filterable: false,
        sortable: false,
        Cell: row => (
          <span className="monospace" style={{ float: "right" }}>
            {row.value}
          </span>
        )
      },
      {
        Header: () => (
          <div>
            <span
              id="deposit-withdraw-history-value"
              name="depositWithdrawHistoryValue"
              onMouseEnter={onMouseEnterColumnHeader}
              onMouseLeave={onMouseLeaveColumnHeader}
            >
              Value
            </span>
          </div>
        ),
        accessor: "value",
        minWidth: 40,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        Cell: row => (
          <span className="monospace" style={{ float: "right" }}>
            {"$" + row.value}
          </span>
        ),
        sortMethod: (a, b) => {
          return b[0] - a[0];
        }
      },
      {
        Header: () => (
          <div>
            <span
              id="deposit-withdraw-history-timestamp"
              name="depositWithdrawHistoryTimestamp"
              onMouseEnter={onMouseEnterColumnHeader}
              onMouseLeave={onMouseLeaveColumnHeader}
            >
              Timestamp
            </span>
          </div>
        ),
        accessor: "timestamp",
        minWidth: 40,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        Cell: row => <span style={{ float: "right" }}>{row.value}</span>,
        sortMethod: (a, b) => {
          return b[1] - a[1];
        }
      }
    ];
    return tableColumns;
  };

  const printTable = () => {
    if (web3AccountLoaded && depositsAndWithdrawalsAreLoaded() && getTableData().length > 0) {
      return (
        <div>
          <ReactTable
            className="-highlight"
            data={getTableData()}
            //filterable
            columns={getTableColumns()}
            defaultPageSize={5}
          />
        </div>
      );
    }
  };

  return (
    <>
      <FilterContext.Consumer>
        {({ closeAllFilterDropmenus }) => {
          return (
            <div className="content" style={{ paddingTop: "10px" }}>
              <Row>
                <Col className="mb-1" md="12">
                  <Card>
                    <CardHeader className="handle">
                      <CardTitle tag="h4">
                        <div className="historyHeaderContainer">
                          <span className="headerTitle">{CARD_TITLE}</span>
                        </div>
                      </CardTitle>
                    </CardHeader>
                    {/* <CardBody id={"php"} className={panelIsDisplayed ? "" : "displayNone"}> */}
                    <CardBody id={"php"}>
                      {printTable()}
                      {printEmptyTable(web3AccountLoaded, depositsAndWithdrawalsAreLoaded(), getTableData(), EMPTY_TABLE_TEXT)}
                      {printLoadingIcon(web3AccountLoaded, depositsAndWithdrawalsAreLoaded())}
                      <ConnectPanel />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          );
        }}
      </FilterContext.Consumer>
      {tooltipIsVisible && (
        <MyTooltip2 target={tooltipTarget} setTooltipIsVisible={setTooltipIsVisible} setTooltipContent={setTooltipContent} setTooltipTarget={setTooltipTarget}>
          {tooltipContent}
        </MyTooltip2>
      )}
    </>
  );
};

export default DepositWithdrawHistoryPanel;

DepositWithdrawHistoryPanel.propTypes = {
  targetFund: PropTypes.string.isRequired
};
