import ERC20 from "../../abis/ERC20.json";
import {
  confirmTransactionNotification,
  lpTradeAdded,
  tradeAdded,
  depositOrWithdrawalAdded,
  depositsAndWithdrawalsLoaded,
  tokenAccountBalanceLoaded,
  tokenWalletBalanceLoaded,
  marginAccountDataLoaded,
  marginPoolDataLoaded,
  tokenPairsLoaded,
  tokensLoaded,
  loadingTokenAccountBalance,
  loadingTokenWalletBalance,
  positionTokensLoaded,
  positionTokenAccountBalancesLoaded,
  lpPositionTokensData,
  lpPositionTokensLoaded,
  lpPositionTokenAccountBalancesLoaded,
  loadingPositionTokenAccountBalance,
  positionTokenAccountBalanceLoaded,
  removeSettlementPendingNotification
} from "../actions/actions.js";
import { handleNewError } from "./errorInteractions";
import {
  wethAddress,
  reloadMarketTokensBalances,
  addOrUpdateLPToken,
  loadAllTokenPairs2,
  loadPositionTokens2,
  loadLPPositionTokens2
} from "./tokensInteractions";
import { loadMarginAccountData, loadMarginPoolData } from "./marginInteractions";
import { updateMarket } from "./marketsInteractions";
import { formatUnits } from "@ethersproject/units";
import { ding, getNonce, getNotificationData, hasTxFailed, onConfirmed, onError, onFailed, onPending, toShortAddress } from "../../utils/utils.js";
import { store } from "../configureStore";
import { BigNumber } from "@ethersproject/bignumber";
import { Contract } from "@ethersproject/contracts";
import { Promise } from "bluebird";
import murmurhash from "murmurhash-js";
import { batch } from "react-redux";
import moment from "moment";
import { getEventsStreamCached } from "./interactionsManager.js";
import { loadMarginAccountData2, loadMarginPoolData2 } from "../utils/marginUtils.js";
import { loadAssetAccountBalance2, loadAssetWalletBalance2, reloadMarketTokensBalances2 } from "../utils/tokensUtils.js";

// TODO: v2 make this configurable (for example last 8 months)
// TODO: v1 use this kind of filtering for all events when we only need events from current user
export const loadDepositAndWithdrawalEvents = async (networkData, userBalancesVault, userAccount, dispatch) => {
  try {
    const userBalancesVaultAllEvents = await getEventsStreamCached(networkData, userBalancesVault, "UserBalancesVault", "allEvents", {});
    const depositsStream = userBalancesVaultAllEvents.filter(e => e.event === "Deposit" && e.returnValues.user === userAccount);
    const withdrawalsStream = userBalancesVaultAllEvents.filter(e => e.event === "Withdraw" && e.returnValues.user === userAccount);

    let depositsAndWithdrawals = [
      depositsStream.map(event => {
        return {
          token: event.returnValues.token,
          amount: event.returnValues.amount,
          timestamp: event.returnValues.timestamp,
          isDeposit: true
        };
      }),
      withdrawalsStream.map(event => {
        return {
          token: event.returnValues.token,
          amount: event.returnValues.amount,
          timestamp: event.returnValues.timestamp,
          isDeposit: false
        };
      })
    ].flat();
    depositsAndWithdrawals = depositsAndWithdrawals.filter(daw => daw != null);
    dispatch(depositsAndWithdrawalsLoaded(depositsAndWithdrawals));
  } catch (e) {
    handleNewError({}, "Error while fetching past deposits and withdrawals. Please reload the application!", 1, dispatch);
  }
};

export const loadSettleEvents = async (networkData, settlements, dispatch) => {
  try {
    const settlementsStream = getEventsStreamCached(networkData, settlements, "Settlements", "Settlement", {});
    return settlementsStream;
  } catch (e) {
    handleNewError({}, "Error while fetching past settlements. Please reload the application!", 1, dispatch);
  }
};

export const subscribeToUserBalancesEvents = async (
  provider,
  web3,
  networkData,
  userAccount,
  userBalances,
  userBalancesVault,
  settlements,
  tokenRegistry,
  positionsManager,
  marginPool,
  gammaContractsRegistry,
  wethToken,
  usdToken,
  riskFreeRate,
  loadHelper,
  dispatch
) => {
  const userBalancesVaultAbi = [
    "event Deposit(address indexed token, address indexed user, bool isPositionToken, uint128 amount, uint32 timestamp)",
    "event Withdraw(address indexed token, address indexed user, bool isPositionToken, uint128 amount, uint32 timestamp)"
  ];
  const userBalancesAbi = [
    "event AccountLiquidated(address indexed liquidatedAccount, address indexed liquidatorAccount, uint128 penaltyInBase, uint32 timestamp)",
    "event LongPositionLiquidated(uint40 marketId, address liquidatedAccount, address liquidatorAccount, uint128 longSent, uint128 baseReceived, uint32 timestamp)",
    "event ShortPositionLiquidated(uint40 marketId, address liquidatedAccount, address liquidatorAccount, uint128 shortSent, uint128 baseSent, uint128 collReceived, uint32 timestamp)",
    "event LPPositionLiquidated(uint40 marketId, address liquidatedAccount, address liquidatorAccount, uint40 positionId, uint128 liqSent, uint128 baseReceived, uint128 collReceived, uint128 shortReceived, uint128 longPriceInBase, uint32 timestamp)"
  ];
  const settlementsAbi = [
    "event Settlement(address indexed baseTokenAddress, address indexed underTokenAddress, uint32 indexed expirationTime, uint32 settlementTime, uint128 underlyingPrice, uint128 actualUnderlyingPrice, address user)"
  ];
  const userBalancesVaultContract = new Contract(userBalancesVault.options.address, userBalancesVaultAbi, provider);
  const userBalancesContract = new Contract(userBalances.options.address, userBalancesAbi, provider);
  const settlementsContract = new Contract(settlements.options.address, settlementsAbi, provider);

  try {
    settlementsContract.on(
      "Settlement",
      async (baseTokenAddress, underTokenAddress, expirationTime, settlementTime, underlyingPrice, actualUnderlyingPrice, user, event) => {
        // once a day (at most) app reloads
        const expiringDate = moment(new Date(expirationTime * 1000)).format("DD MMM YYYY");
        // const expiringDate = new Date(expirationTime * 1000).toLocaleDateString("en-GB", {
        //   day: "numeric",
        //   month: "short",
        //   year: "numeric"
        // });
        const notificationText = "Settlement " + expiringDate;
        const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
        const notification = getNotificationData(notificationText, event.transactionHash, timestamp, "confirmed", -1, true);
        dispatch(removeSettlementPendingNotification(userAccount, event.transactionHash));
        dispatch(confirmTransactionNotification(notification, userAccount));
        ding();
        window.location.reload();
      }
    );
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while fetching markets settlement status. Please reload the application!", 1, dispatch);
  }

  try {
    userBalancesContract.on("AccountLiquidated", async (liquidatedAccount, liquidatorAccount, penaltyInBase, ts, event) => {
      // TODO: ovde postoji bug. Ako sam ja likvidator, necu dobiti njegove borrow events osim ako ne uradim refresh
      // to je obradjeno samo na loadMarginPoolEvents, a trebalo bi i ovde
      const { tokenPairs, marketsImmutable, tokens } = store.getState();
      const markets = marketsImmutable.data;
      const underPriceBN = tokenPairs.selectedTokenPair.avgUnderPrice;

      const [
        tokenPairsLiquidatedAccount,
        tokensLiquidatedAccount,
        assetAddressesLiquidatedAccount,
        userAccountAndWalletAssetBalancesLiquidatedAccount,
        biggerLiquidatedAccount,
        isWethLiquidatedAccount
      ] = await loadAllTokenPairs2(provider, web3, loadHelper, tokenRegistry, userBalances, wethToken, usdToken, liquidatedAccount, dispatch, underPriceBN);
      const [
        tokenPairsLiquidatorAccount,
        tokensLiquidatorAccount,
        assetAddressesLiquidatorAccount,
        userAccountAndWalletAssetBalancesLiquidatorAccount,
        biggerLiquidatorAccount,
        isWethLiquidatorAccount
      ] = await loadAllTokenPairs2(provider, web3, loadHelper, tokenRegistry, userBalances, wethToken, usdToken, liquidatorAccount, dispatch, underPriceBN);

      const [positionsTokensLiquidatedAccount, addressBalancesLiquidatedAccount] = await loadPositionTokens2(
        provider,
        web3,
        loadHelper,
        userBalances,
        markets,
        liquidatedAccount,
        dispatch
      );
      const [positionsTokensLiquidatorAccount, addressBalancesLiquidatorAccount] = await loadPositionTokens2(
        provider,
        web3,
        loadHelper,
        userBalances,
        markets,
        liquidatorAccount,
        dispatch
      );

      const [lpPositionTokensLiquidatedAccount, accountLPBalancesLiquidatedAccount, userMarketIdsLengthLiquidatedAccount] = await loadLPPositionTokens2(
        web3,
        networkData,
        loadHelper,
        positionsManager,
        markets,
        userBalances,
        liquidatedAccount,
        underPriceBN,
        dispatch
      );
      const [lpPositionTokensLiquidatorAccount, accountLPBalancesLiquidatorAccount, userMarketIdsLengthLiquidatorAccount] = await loadLPPositionTokens2(
        web3,
        networkData,
        loadHelper,
        positionsManager,
        markets,
        userBalances,
        liquidatorAccount,
        underPriceBN,
        dispatch
      );

      const marginPoolData = await loadMarginPoolData2(loadHelper, marginPool, underPriceBN, tokens);

      const marginAccountDataLiquidatedAccount = await loadMarginAccountData2(liquidatedAccount, loadHelper, marginPool, userBalances, underPriceBN, tokens);
      const marginAccountDataLiquidatorAccount = await loadMarginAccountData2(liquidatorAccount, loadHelper, marginPool, userBalances, underPriceBN, tokens);

      const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));

      let [
        tokenAccountBalanceBaseTokens,
        assetWalletBalanceBaseTokens,
        tokenAccountBalanceUnderTokens,
        assetWalletBalanceUnderTokens,
        longPositionTokenAccountBalances,
        shortPositionTokenAccountBalances
      ] = [[], [], [], [], [], []];
      const { baseTokenAddress, underTokenAddress } = await loadHelper.methods.getAssetAddresses().call();
      if (userAccount == liquidatedAccount) {
        for (let i = 0; i < markets.length; i++) {
          const retvals = await reloadMarketTokensBalances2(web3, markets[i].marketId, userBalances, loadHelper, userAccount, underPriceBN);
          tokenAccountBalanceBaseTokens.push(retvals[0]);
          assetWalletBalanceBaseTokens.push(retvals[1]);
          tokenAccountBalanceUnderTokens.push(retvals[2]);
          assetWalletBalanceUnderTokens.push(retvals[3]);
          longPositionTokenAccountBalances.push(retvals[4]);
          shortPositionTokenAccountBalances.push(retvals[5]);
        }
      }

      batch(() => {
        dispatch(tokenPairsLoaded(tokenPairsLiquidatedAccount));
        dispatch(tokenPairsLoaded(tokenPairsLiquidatorAccount));

        dispatch(tokensLoaded(tokensLiquidatedAccount));
        dispatch(tokensLoaded(tokensLiquidatorAccount));

        for (let i = 0; i < assetAddressesLiquidatedAccount.length; i++) {
          dispatch(loadingTokenAccountBalance(assetAddressesLiquidatedAccount[i]));
          dispatch(loadingTokenWalletBalance(assetAddressesLiquidatedAccount[i]));
        }
        for (let i = 0; i < assetAddressesLiquidatorAccount.length; i++) {
          dispatch(loadingTokenAccountBalance(assetAddressesLiquidatorAccount[i]));
          dispatch(loadingTokenWalletBalance(assetAddressesLiquidatorAccount[i]));
        }

        for (let i = 0; i < assetAddressesLiquidatedAccount.length; i++) {
          if (assetAddressesLiquidatedAccount[i] === wethAddress) {
            dispatch(tokenWalletBalanceLoaded(assetAddressesLiquidatedAccount[i], biggerLiquidatedAccount, isWethLiquidatedAccount));
          } else {
            dispatch(tokenWalletBalanceLoaded(assetAddressesLiquidatedAccount[i], userAccountAndWalletAssetBalancesLiquidatedAccount[1][i]));
          }
          dispatch(tokenAccountBalanceLoaded(assetAddressesLiquidatedAccount[i], userAccountAndWalletAssetBalancesLiquidatedAccount[0][i]));
        }
        for (let i = 0; i < assetAddressesLiquidatorAccount.length; i++) {
          if (assetAddressesLiquidatorAccount[i] === wethAddress) {
            dispatch(tokenWalletBalanceLoaded(assetAddressesLiquidatorAccount[i], biggerLiquidatorAccount, isWethLiquidatorAccount));
          } else {
            dispatch(tokenWalletBalanceLoaded(assetAddressesLiquidatorAccount[i], userAccountAndWalletAssetBalancesLiquidatorAccount[1][i]));
          }
          dispatch(tokenAccountBalanceLoaded(assetAddressesLiquidatorAccount[i], userAccountAndWalletAssetBalancesLiquidatorAccount[0][i]));
        }

        dispatch(positionTokensLoaded(positionsTokensLiquidatedAccount));
        dispatch(positionTokenAccountBalancesLoaded(addressBalancesLiquidatedAccount));

        dispatch(positionTokensLoaded(positionsTokensLiquidatorAccount));
        dispatch(positionTokenAccountBalancesLoaded(addressBalancesLiquidatorAccount));

        dispatch(lpPositionTokensData(lpPositionTokensLiquidatedAccount));
        if (userMarketIdsLengthLiquidatedAccount > 0) {
          dispatch(lpPositionTokenAccountBalancesLoaded(accountLPBalancesLiquidatedAccount));
        }
        dispatch(lpPositionTokensData(lpPositionTokensLiquidatorAccount));
        if (userMarketIdsLengthLiquidatorAccount > 0) {
          dispatch(lpPositionTokenAccountBalancesLoaded(accountLPBalancesLiquidatorAccount));
        }
        dispatch(lpPositionTokensLoaded());

        dispatch(marginPoolDataLoaded(marginPoolData));

        dispatch(marginAccountDataLoaded(marginAccountDataLiquidatedAccount));
        dispatch(marginAccountDataLoaded(marginAccountDataLiquidatorAccount));

        if (userAccount == liquidatedAccount) {
          for (let i = 0; i < markets.length; i++) {
            dispatch(loadingTokenAccountBalance(baseTokenAddress));
            dispatch(tokenAccountBalanceLoaded(baseTokenAddress, tokenAccountBalanceBaseTokens[i]));

            dispatch(loadingTokenWalletBalance(baseTokenAddress));
            dispatch(tokenWalletBalanceLoaded(baseTokenAddress, assetWalletBalanceBaseTokens[i]));

            dispatch(loadingTokenAccountBalance(underTokenAddress));
            dispatch(tokenAccountBalanceLoaded(underTokenAddress, tokenAccountBalanceUnderTokens[i]));

            dispatch(loadingTokenWalletBalance(underTokenAddress));
            dispatch(tokenWalletBalanceLoaded(underTokenAddress, assetWalletBalanceUnderTokens[i]));

            dispatch(loadingPositionTokenAccountBalance(markets[i].marketId.toString()));
            dispatch(positionTokenAccountBalanceLoaded(markets[i].marketId.toString(), longPositionTokenAccountBalances[i]));

            dispatch(loadingPositionTokenAccountBalance((parseInt(markets[i].marketId) + 1).toString()));
            dispatch(positionTokenAccountBalanceLoaded((parseInt(markets[i].marketId) + 1).toString(), shortPositionTokenAccountBalances[i]));
          }
          const shortAccount = toShortAddress(liquidatorAccount);
          const notification = {
            quantity: `Your account is liquidated by ${shortAccount}`,
            tx: event.transactionHash,
            timestamp,
            status: "confirmed",
            nonce: -1,
            isExternal: true
          };
          dispatch(confirmTransactionNotification(notification, userAccount));
          ding();
        }
      });
    });
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while updating user data after liquidation. Please reload the application!", 1, dispatch);
  }

  try {
    userBalancesVaultContract.on("Deposit", async (token, user, isPositionToken, amount, timestamp, event) => {
      if (user === userAccount) {
        const { tokenPairs, tokens } = store.getState();
        const wethAddress = tokens.data.find(t => t.symbol === "ETH").address;

        let accountBalance = null,
          walletBalance = null;
        if (!isPositionToken) {
          [accountBalance, walletBalance] = await Promise.all([
            loadAssetAccountBalance2(token, userBalances, userAccount, dispatch),
            loadAssetWalletBalance2(web3, token, userAccount, wethAddress)
          ]);
        }

        const underPriceBN = tokenPairs.selectedTokenPair.avgUnderPrice;
        const marginAccountData = await loadMarginAccountData2(userAccount, loadHelper, marginPool, userBalances, underPriceBN, tokens);
        const marginPoolData = await loadMarginPoolData2(loadHelper, marginPool, underPriceBN, tokens);

        const isWeth = tokens.data.find(t => t.address === wethAddress).isWeth;

        batch(() => {
          dispatch(
            depositOrWithdrawalAdded({
              token,
              isDeposit: true,
              amount,
              timestamp
            })
          );
          if (!isPositionToken) {
            dispatch(tokenAccountBalanceLoaded(token, accountBalance));
            dispatch(tokenWalletBalanceLoaded(token, walletBalance, isWeth));
          }
          dispatch(marginAccountDataLoaded(marginAccountData));
          dispatch(marginPoolDataLoaded(marginPoolData));
        });
      }
    });
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while depositing funds", 1, dispatch);
  }

  try {
    userBalancesVaultContract.on("Withdraw", async (token, user, isPositionToken, amount, timestamp, event) => {
      if (user === userAccount) {
        const { tokenPairs, tokens } = store.getState();
        const wethAddress = tokens.data.find(t => t.symbol === "ETH").address;

        let accountBalance = null,
          walletBalance = null;
        if (!isPositionToken) {
          [accountBalance, walletBalance] = await Promise.all([
            loadAssetAccountBalance2(token, userBalances, userAccount, dispatch),
            loadAssetWalletBalance2(web3, token, userAccount, wethAddress)
          ]);
        }

        const underPriceBN = tokenPairs.selectedTokenPair.avgUnderPrice;
        const marginAccountData = await loadMarginAccountData2(userAccount, loadHelper, marginPool, userBalances, underPriceBN, tokens);
        const marginPoolData = await loadMarginPoolData2(loadHelper, marginPool, underPriceBN, tokens);

        const isWeth = tokens.data.find(t => t.address === wethAddress).isWeth;

        batch(() => {
          dispatch(
            depositOrWithdrawalAdded({
              token,
              isDeposit: false,
              amount,
              timestamp
            })
          );
          if (!isPositionToken) {
            dispatch(tokenAccountBalanceLoaded(token, accountBalance));
            dispatch(tokenWalletBalanceLoaded(token, walletBalance, isWeth));
          }
          dispatch(marginAccountDataLoaded(marginAccountData));
          dispatch(marginPoolDataLoaded(marginPoolData));
        });
      }
    });
  } catch (e) {
    handleNewError({}, "Error while withdrawing funds", 1, dispatch);
  }

  try {
    userBalancesContract.on("LongPositionLiquidated", async (marketId, liquidatedAccount, liquidatorAccount, longSent, baseReceived, timestamp, event) => {
      let state = store.getState();
      let underPriceBN = state.tokenPairs.selectedTokenPair.avgUnderPrice;
      if (state.trades.loaded) {
        const existingTrade = state.trades.data.find(trade => trade.signature === event.transactionHash + murmurhash(event.data));

        if (existingTrade) {
          return;
        }
      }

      // eslint-disable-next-line no-console
      console.log("LongPositionLiquidated event received");

      // handle event created by any user
      updateMarket(riskFreeRate, web3, marketId, dispatch);

      const liquidatorTrade = {
        marketId: marketId.toString(),
        user: liquidatorAccount,
        type: "long",
        isBuy: true,
        size: longSent,
        baseAmount: baseReceived / 10 ** 18,
        basePrice: parseFloat(baseReceived / 10 ** 18 / (longSent / 10 ** 18)),
        collPrice: 0,
        timestamp: timestamp.toString(),
        signature: event.transactionHash + murmurhash(event.data),
        isLiquidation: true
      };
      const liquidatedTrade = {
        marketId: marketId.toString(),
        user: liquidatedAccount,
        type: "long",
        isBuy: false,
        size: longSent,
        baseAmount: baseReceived / 10 ** 18,
        basePrice: parseFloat(baseReceived / 10 ** 18 / (longSent / 10 ** 18)),
        collPrice: 0,
        timestamp: timestamp.toString(),
        signature: event.transactionHash + murmurhash(event.data),
        isLiquidation: true
      };

      batch(() => {
        dispatch(tradeAdded(liquidatorTrade));
        dispatch(tradeAdded(liquidatedTrade));
      });

      loadMarginPoolData(loadHelper, marginPool, underPriceBN, gammaContractsRegistry, dispatch);

      // handle event created by userAccount
      if (userAccount === liquidatedAccount || userAccount === liquidatorAccount) {
        reloadMarketTokensBalances(web3, marketId, userBalances, loadHelper, userAccount, underPriceBN, dispatch);
        loadMarginAccountData(userAccount, loadHelper, marginPool, userBalances, underPriceBN, gammaContractsRegistry, dispatch);
        // dispatch(historicalDataUpdated(marketId, trade, true));
        const { newNotifications } = store.getState();
        // const notificationForEventHash = newNotifications[userAccount] && newNotifications[userAccount].find(n => n.tx === event.transactionHash);
        const notificationForEvent = newNotifications[userAccount] && newNotifications[userAccount].find(n => n.nonce === event.getTransaction().nonce);
        if (notificationForEvent) {
          dispatch(confirmTransactionNotification(notificationForEvent.nonce, userAccount));
          ding();
        }
      }
    });
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while liquidating long positions", 1, dispatch);
  }

  try {
    userBalancesContract.on(
      "ShortPositionLiquidated",
      async (marketId, liquidatedAccount, liquidatorAccount, shortSent, baseSent, collReceived, timestamp, event) => {
        // check if received event already exists
        let state = store.getState();
        let underPriceBN = state.tokenPairs.selectedTokenPair.avgUnderPrice;
        if (state.trades.loaded) {
          const existingTrade = state.trades.data.find(trade => trade.signature === event.transactionHash + murmurhash(event.data));
          if (existingTrade) {
            return;
          }
        }

        // eslint-disable-next-line no-console
        console.log("ShortPositionLiquidated event received");

        // handle event created by any user
        updateMarket(riskFreeRate, web3, marketId, dispatch);

        const liquidatorTrade = {
          marketId: marketId.toString(),
          user: liquidatorAccount,
          type: "short",
          isBuy: false,
          size: shortSent,
          baseAmount: baseSent / 10 ** 18,
          basePrice: parseFloat(baseSent / 10 ** 18 / (shortSent / 10 ** 18)),
          collPrice: parseFloat(collReceived / 10 ** 18 / (shortSent / 10 ** 18)),
          timestamp: timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.data),
          isLiquidation: true
        };

        const liquidatedTrade = {
          baseAmount: baseSent,
          marketId: marketId.toString(),
          user: liquidatedAccount,
          type: "short",
          isBuy: true,
          size: shortSent,
          basePrice: parseFloat(baseSent / 10 ** 18 / (shortSent / 10 ** 18)),
          collPrice: parseFloat(collReceived / 10 ** 18 / (shortSent / 10 ** 18)),
          timestamp: timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.data),
          isLiquidation: true
        };

        batch(() => {
          dispatch(tradeAdded(liquidatorTrade));
          dispatch(tradeAdded(liquidatedTrade));
        });

        loadMarginPoolData(loadHelper, marginPool, underPriceBN, gammaContractsRegistry, dispatch);

        // handle event created by userAccount
        if (userAccount === liquidatedAccount || userAccount === liquidatorAccount) {
          reloadMarketTokensBalances(web3, marketId, userBalances, loadHelper, userAccount, underPriceBN, dispatch);
          loadMarginAccountData(userAccount, loadHelper, marginPool, userBalances, underPriceBN, gammaContractsRegistry, dispatch);
          const { newNotifications } = store.getState();
          const notificationForEvent = newNotifications[userAccount] && newNotifications[userAccount].find(n => n.nonce === event.getTransaction().nonce);
          if (notificationForEvent) {
            dispatch(confirmTransactionNotification(notificationForEvent.nonce, userAccount));
            ding();
          }
        }
      }
    );
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while liquidating short position", 1, dispatch);
  }

  try {
    userBalancesContract.on(
      "LPPositionLiquidated",
      async (
        marketId,
        liquidatedAccount,
        liquidatorAccount,
        positionId,
        liqSent,
        baseReceived,
        collReceived,
        shortReceived,
        longPriceInBase,
        timestamp,
        event
      ) => {
        // check if received event already exists
        let state = store.getState();
        let underPriceBN = state.tokenPairs.selectedTokenPair.avgUnderPrice;
        if (state.lpTrades.loaded) {
          const existingTrade = state.lpTrades.data.find(trade => trade.signature === event.transactionHash + murmurhash(event.data));
          if (existingTrade) {
            return;
          }
        }

        // eslint-disable-next-line no-console
        console.log("LPPositionLiquidated event received");

        // handle event created by any user
        const attr = await updateMarket(riskFreeRate, web3, marketId, dispatch);
        addOrUpdateLPToken(web3, marketId, attr.longTokenName, positionId, dispatch);

        const liquidatorTrade = {
          marketId: marketId.toString(),
          user: liquidatorAccount,
          positionId: positionId.toString(),
          isBuy: true,
          size: liqSent,
          baseAmount: baseReceived / 10 ** 18,
          basePrice: parseFloat(baseReceived / 10 ** 18 / (liqSent / 10 ** 18)),
          collPrice: parseFloat(collReceived / 10 ** 18 / (liqSent / 10 ** 18)),
          shortPrice: parseFloat(shortReceived / 10 ** 18 / (liqSent / 10 ** 18)),
          optionAmount: shortReceived.toString(),
          longPriceInBase: longPriceInBase,
          timestamp: timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.data),
          isLiquidation: true
        };
        const liquidatedTrade = {
          marketId: marketId.toString(),
          user: liquidatedAccount,
          positionId: positionId.toString(),
          isBuy: false,
          size: liqSent,
          baseAmount: baseReceived / 10 ** 18,
          basePrice: parseFloat(baseReceived / 10 ** 18 / (liqSent / 10 ** 18)),
          collPrice: parseFloat(collReceived / 10 ** 18 / (liqSent / 10 ** 18)),
          shortPrice: parseFloat(shortReceived / 10 ** 18 / (liqSent / 10 ** 18)),
          optionAmount: shortReceived.toString(),
          longPriceInBase: longPriceInBase,
          timestamp: timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.data),
          isLiquidation: true
        };

        batch(() => {
          dispatch(lpTradeAdded(liquidatorTrade));
          dispatch(lpTradeAdded(liquidatedTrade));
        });

        loadMarginPoolData(loadHelper, marginPool, underPriceBN, gammaContractsRegistry, dispatch);

        // handle event created by userAccount
        if (userAccount === liquidatedAccount) {
          reloadMarketTokensBalances(web3, marketId, userBalances, loadHelper, userAccount, underPriceBN, dispatch, positionId);
          loadMarginAccountData(userAccount, loadHelper, marginPool, userBalances, underPriceBN, gammaContractsRegistry, dispatch);
          const notificationText = "Liquidated LP position by " + toShortAddress(liquidatorAccount);
          const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
          const notification = getNotificationData(notificationText, event.transactionHash, timestamp, "confirmed", -1);
          dispatch(confirmTransactionNotification(notification, userAccount));
          ding();
        }
      }
    );
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while liquidating LP position", 1, dispatch);
  }
};

const getDepositWithdrawNotificationText = (isDeposit, quantity, symbol) => {
  const action = isDeposit ? "Deposit " : "Withdraw ";
  if ((symbol === "long" || symbol === "short" || symbol === "lp") && quantity > 1) {
    symbol += "s";
  }
  return action + quantity.toLocaleString() + " " + symbol;
};

export const checkAndApprove = async (web3, assetAddress, userAccount, contractBalances, amount, setStep) => {
  const tokenContract = new web3.eth.Contract(ERC20.abi, assetAddress);
  const decimals = await tokenContract.methods.decimals().call();
  // check allowance, approve if needed
  const allowance = await tokenContract.methods.allowance(userAccount, contractBalances.options.address).call();
  const allowance18 = BigNumber.from(allowance).mul(BigNumber.from(10 ** (18 - decimals)));
  const amount18 = BigNumber.from(amount);
  const approveValueWithDecimals = amount18.div(10 ** (18 - decimals));
  if (allowance18.lt(amount18)) {
    setStep(1);
    const contractMethod = tokenContract.methods.approve(contractBalances.options.address, approveValueWithDecimals);
    const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
    await contractMethod.send({ from: userAccount, gas: limitGas }).on("transactionHash", function(hash) {
      setStep(2);
      ding();
    });
    setStep(3);
  }
};

export const depositFunds = async (web3, userBalancesVault, assetAddress, amount, userAccount, symbol, closeModal, setStep, dispatch) => {
  const quantity = parseFloat(formatUnits(amount.toString(), 18));
  const notificationText = getDepositWithdrawNotificationText(true, quantity, symbol);
  const txNonce = await getNonce(web3, userAccount);
  try {
    if (assetAddress === wethAddress) {
      const { tokens } = store.getState();
      const isWeth = tokens.data.find(t => t.address === wethAddress).isWeth;
      console.log("isWeth:", isWeth);

      let contractMethod;
      if (isWeth) {
        await checkAndApprove(web3, assetAddress, userAccount, userBalancesVault, amount, setStep);
        contractMethod = userBalancesVault.methods.depositToken(wethAddress, amount);
      } else {
        contractMethod = userBalancesVault.methods.depositETH();
      }
      const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount, value: isWeth ? null : amount })) * 2); // 100% extra gas
      const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
      console.log("limitGas:", limitGas);
      let isConfirmed = false;
      await contractMethod
        .send({ value: isWeth ? null : amount, from: userAccount, gas: limitGas })
        .on("transactionHash", async function(hash) {
          onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
          const hasFailed = await hasTxFailed(web3, hash);
          if (hasFailed) {
            onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
        })
        .on("confirmation", function(confirmationNumber, receipt) {
          if (!isConfirmed) {
            if (receipt.status) {
              onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
            } else {
              onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
            }
            isConfirmed = true;
          }
        });
    } else {
      await checkAndApprove(web3, assetAddress, userAccount, userBalancesVault, amount, setStep);
      const contractMethod = userBalancesVault.methods.depositToken(assetAddress, amount);
      const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
      const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
      console.log("limitGas:", limitGas);
      let isConfirmed = false;
      await contractMethod
        .send({ from: userAccount, gas: limitGas })
        .on("transactionHash", async function(hash) {
          onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
          const hasFailed = await hasTxFailed(web3, hash);
          if (hasFailed) {
            onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
        })
        .on("confirmation", function(confirmationNumber, receipt) {
          if (!isConfirmed) {
            if (receipt.status) {
              onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              setStep(null);
            } else {
              onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
              setStep(null);
            }
            isConfirmed = true;
          }
        });
    }
  } catch (e) {
    onError(e, "There was an error while depositing funds! Please try again!", dispatch);
    setStep(null);
  }
};

export const withdrawFunds = async (web3, userBalancesVault, assetAddress, amount, userAccount, symbol, closeModal, dispatch) => {
  const quantity = parseFloat(formatUnits(amount.toString(), 18));
  const notificationText = getDepositWithdrawNotificationText(false, quantity, symbol);
  const txNonce = await getNonce(web3, userAccount);
  try {
    if (assetAddress === wethAddress) {
      const { tokens } = store.getState();
      let contractMethod;

      // sepolia withdrawToken
      const networkName = localStorage.getItem("networkName");
      if (networkName === "Sepolia" || networkName === "Base Sepolia") {
        contractMethod = userBalancesVault.methods.withdrawToken(wethAddress, amount);
      } else {
        contractMethod = userBalancesVault.methods.withdrawETH(amount);
      }
      // contractMethod = userBalancesVault.methods.withdrawToken(wethAddress, amount);
      const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
      const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
      console.log("limitGas:", limitGas);
      let isConfirmed = false;
      await contractMethod
        .send({ from: userAccount, gas: limitGas })
        .on("transactionHash", async function(hash) {
          onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
          const hasFailed = await hasTxFailed(web3, hash);
          if (hasFailed) {
            onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
        })
        .on("confirmation", function(confirmationNumber, receipt) {
          if (!isConfirmed) {
            if (receipt.status) {
              onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
            } else {
              onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
            }
            isConfirmed = true;
          }
        });
    } else {
      const contractMethod = userBalancesVault.methods.withdrawToken(assetAddress, amount);
      const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
      const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
      console.log("limitGas:", limitGas);
      let isConfirmed = false;
      await contractMethod
        .send({ from: userAccount, gas: limitGas })
        .on("transactionHash", async function(hash) {
          onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
          const hasFailed = await hasTxFailed(web3, hash);
          if (hasFailed) {
            onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
        })
        .on("confirmation", function(confirmationNumber, receipt) {
          if (!isConfirmed) {
            if (receipt.status) {
              onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
            } else {
              onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
            }
            isConfirmed = true;
          }
        });
    }
  } catch (e) {
    onError(e, "There was an error while withdrawing funds! Please try again!", dispatch);
  }
};

const getSettleNotificationText = expirationTime => {
  const expiringDate = moment(new Date(expirationTime * 1000)).format("DD MMM YYYY");
  return "Settlement " + expiringDate;
};

// settle
export const settle = async (web3, settlements, baseTokenAddress, underTokenAddress, expirationTime, userAccount, dispatch) => {
  const notificationText = getSettleNotificationText(expirationTime);
  const txNonce = await getNonce(web3, userAccount);
  try {
    // TODO: v1 update balances of all used tokens on receipt
    const contractMethod = settlements.methods.settle(baseTokenAddress, underTokenAddress, expirationTime);
    const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.3); // 30% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    let isConfirmed = false;
    await contractMethod
      .send({ from: userAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, null, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    console.log(e);
    onError(e, "There was an error while trying to settle multiple markets. Please try again!", dispatch);
  }
};

export const areSettled = async (userBalances, baseTokenAddress, underTokenAddress, expirationTime) => {
  try {
    const areSettled = await userBalances.methods.areSettled(baseTokenAddress, underTokenAddress, expirationTime).call();
    return areSettled;
  } catch (e) {
    return null;
  }
};

const getMarginNotificationText = (isRepay, quantity, symbol) => {
  if (isRepay) {
    return `Margin Pool repay entire ${symbol} debt`;
  } else {
    return `Deposit ${Number(parseFloat(formatUnits(quantity.toString(), 18))).toLocaleString()} ${symbol} to Margin Pool`;
  }
};

const getMarginRedeemNotificationText = (isRepay, quantity, symbol) => {
  if (isRepay) {
    return `Margin Pool redeem entire ${symbol} debt`;
  } else {
    return `Redeem ${Number(parseFloat(formatUnits(quantity.toString(), 18))).toLocaleString()} ${symbol} from Margin Pool`;
  }
};

export const transferToMarginPool = async (web3, userBalances, assetAddress, amount, userAccount, symbol, closeModal, shouldRepayEntireDebt, dispatch) => {
  const txNonce = await getNonce(web3, userAccount);
  try {
    const notificationText = getMarginNotificationText(shouldRepayEntireDebt, shouldRepayEntireDebt ? null : amount, symbol);
    const contractMethod = userBalances.methods.transferToMarginPool(assetAddress, amount, shouldRepayEntireDebt);
    const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.3); // 30% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    console.log("limitGas:", limitGas);
    let isConfirmed = false;
    await contractMethod
      .send({ from: userAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    onError(e, "There was an error while lending assets to margin pool!", dispatch);
  }
};

export const redeemFromMarginPool = async (web3, userBalances, assetAddress, amount, userAccount, symbol, closeModal, shouldRepayEntireDebt, dispatch) => {
  const txNonce = await getNonce(web3, userAccount);
  try {
    const notificationText = getMarginRedeemNotificationText(shouldRepayEntireDebt, shouldRepayEntireDebt ? null : amount, symbol);
    const contractMethod = userBalances.methods.redeemFromMarginPool(assetAddress, amount, shouldRepayEntireDebt);
    const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.3); // 30% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    let isConfirmed = false;
    await contractMethod
      .send({ from: userAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    onError(e, "There was an error while redeeming assets to margin pool!", dispatch);
  }
};
