/* eslint-disable react/no-multi-comp */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import ReactTable from "react-table";
import {
  openedLPPositionBalancesSelector,
  openedPositionBalancesSelector,
  positionTokensSelector,
  tokenBalancesSelector,
  tokensLoadedSelector,
  avgUnderlyingTokenPriceSelector
} from "../../store/selectors/tokensSelectors";
import { currentTimestampSelector, web3AccountLoadedSelector, web3AccountSelector, web3Selector } from "../../store/selectors/web3Selectors";
import {
  cursorRangeCheck,
  displayHealthIcon,
  displayLoadingIcon,
  displayLoadingIconOld,
  formatValue,
  getUnderlyingPriceAtExpiredStr,
  healthClassName,
  printApr,
  printPriceFromNumber,
  toShortAddress
} from "../../utils/utils.js";
import { openedLPPositionsSelector, openedTradePositionsSelector } from "../../store/selectors/positionsManagerSelectors";
import WithdrawFundsModal from "./WithdrawFundsModal.jsx";
import DepositFundsModal from "./DepositFundsModal.jsx";
import { fromBn, toBn } from "evm-bn";
import { depositFunds, withdrawFunds } from "../../store/interactions/balancesInteractions";
import { marketsSelector } from "../../store/selectors/marketsSelectors";
import { gammaContractsRegistrySelector, marginPoolSelector, userBalancesVaultSelector, loadHelperSelector } from "../../store/selectors/contractsSelectors";
import { BigNumber } from "@ethersproject/bignumber";
import { formatUnits } from "@ethersproject/units";
import TotalLiquidityLegend from "./TotalLiquidityLegend.jsx";
import {
  marginAccountDataLoadedSelector,
  marginAccountDataSelector,
  marginPoolDataSelector,
  marginPoolDataLoadedSelector
} from "../../store/selectors/marginSelectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faExpandArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import MyTooltip2 from "./MyTooltip2.jsx";
import OnboardingModal from "./OnboardingModal.jsx";
import ReactGA from "react-ga4";
import DepositWithdrawHistoryPanel, { POOL_NAME_MARGIN_ACCOUNT } from "./DepositWithdrawHistoryPanel.jsx";

import ERC20 from "../../abis/ERC20.json";
import BorrowHoverPanel from "./BorrowHoverPanel.jsx";
import { settlementDataSelector } from "../../store/selectors/balancesSelectors";
import ConnectPanel from "./ConnectPanel.jsx";
import { ARBITRUM_SEPOLIA_NETWORK_ID, BASE_SEPOLIA_NETWORK_ID, SEPOLIA_NETWORK_ID } from "../../config.js";

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverviewBodyDisplayed: true,
      isPositionsBodyDisplayed: true,

      isWithdrawModalOpen: false,
      isDepositModalOpen: false,
      token: null,
      isPositionToken: false,

      totalLiquidityLegendOpen: false,
      totalLiquidityLegendRight: 0,
      totalLiquidityLegendTop: 0,

      borrowHoverPanelOpen: false,
      borrowHoverPanelLeft: 0,
      borrowHoverPanelTop: 0,

      tooltipIsVisible: false,
      tooltipTarget: "",
      tooltipContent: "",
      isOnboardingModalOpen: false,
      isOnboardingModalCheckedForOpening: false
    };

    this.portfolioOverviewTileTitleHealthRef = React.createRef();
    this.totalLiquidityInfoRef = React.createRef();
    this.getTableColumns = this.getTableColumns.bind(this);
    this.getMyAccountData = this.getMyAccountData.bind(this);
  }

  componentDidMount() {
    if (this.totalLiquidityInfoRef.current) {
      this.totalLiquidityInfoRef.current.addEventListener("mouseenter", this.totalLiquidityMouseEnterHandler);
      this.totalLiquidityInfoRef.current.addEventListener("mouseleave", this.totalLiquidityMouseLeaveHandler);
    }

    document.querySelector(".card-header").style.cursor = "unset";

    console.log("Navigate to: " + window.location.pathname); // eslint-disable-line no-console
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Portfolio" });

    this.setIsOnboardingModalOpen();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.openedTradePositions !== this.props.openedTradePositions && prevProps.markets !== this.props.markets) {
      this.getMyPositionsData();
    }

    if (this.areTokenBalancesLoaded() && !this.state.isOnboardingModalCheckedForOpening) {
      this.setIsOnboardingModalOpen();
    }
  }

  componentWillUnmount() {
    if (this.totalLiquidityInfoRef.current) {
      this.totalLiquidityInfoRef.current.removeEventListener("mouseenter", this.totalLiquidityMouseEnterHandler);
      this.totalLiquidityInfoRef.current.removeEventListener("mouseleave", this.totalLiquidityMouseLeaveHandler);
    }
  }

  areTokenBalancesLoaded = () => {
    if (this.props.tokensLoaded) {
      const tokenETH = this.props.tokens.find(t => t.symbol === "ETH");
      const tokenUSD = this.props.tokens.find(t => t.symbol === "USD");

      // check if usd and eth account and wallet balances loaded
      if (tokenETH.accountBalance !== null && tokenETH.walletBalance !== null && tokenUSD.accountBalance !== null && tokenUSD.walletBalance !== null) {
        return true;
      }
    }

    return false;
  };

  getParamFooter = (rows, param) => {
    let total = 0;
    for (let row of rows.data) {
      total += row[param] && Number(row[param][0]);
    }
    return "$" + formatValue(total, 2);
  };

  copyToClipboard = address => {
    let dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    // Be careful if you use textarea. setAttribute('value', value), which works with "input" does not work with "textarea".
    dummy.value = address.toString();
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  onTokenClick = token => {
    const isLp = token.includes("LP");
    const isPositionToken = token !== "USD" && token !== "ETH";

    if (isPositionToken) {
      if (isLp) {
        document.querySelector(".nav").children[1].click();
      } else {
        document.querySelector(".nav").children[0].click();
      }

      localStorage.setItem("tokenFromPortfolio", token);
    }
  };

  getTableColumns = () => {
    let shouldShowRequestButton = false;
    if (this.props.web3AccountLoaded) {
      const isGammaNet = this.props.web3.currentProvider.networkVersion === "7933";
      const isEthereumSepolia = this.props.web3.currentProvider.networkVersion === SEPOLIA_NETWORK_ID.toString();
      const isArbitrumSepolia = this.props.web3.currentProvider.networkVersion === ARBITRUM_SEPOLIA_NETWORK_ID.toString();
      const isBaseSepolia = this.props.web3.currentProvider.networkVersion === BASE_SEPOLIA_NETWORK_ID.toString();
      const isLocalHost = this.props.web3.currentProvider.networkVersion === "1337";
      shouldShowRequestButton = isGammaNet || isEthereumSepolia || isArbitrumSepolia || isBaseSepolia || isLocalHost;
    }

    const tableColumns = [
      {
        Header: () => (
          <div className="alignLeft">
            <span
              id="my-positions-token"
              onMouseEnter={e => this.onMouseEnterColumnHeader(e, "Name of the token")}
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              Token
            </span>
          </div>
        ),
        accessor: "token",
        align: "right",
        minWidth: 45,
        // maxWidth: 200,
        filterable: false,
        sortable: false,
        Cell: row => (
          <div
            className={row.value !== "USD" && row.value !== "ETH" ? "portfolioPositionToken" : ""}
            onClick={e => {
              e.stopPropagation();
              this.onTokenClick(row.value);
            }}
          >
            {row.value === "USD" ? "USDC" : row.value}
          </div>
        ),
        Footer: () => (
          <span style={{ float: "left" }}>
            <strong>TOTAL LIQ</strong>
          </span>
        )
      },
      {
        Header: () => (
          <div>
            <span
              id="my-token-address"
              onMouseEnter={e => this.onMouseEnterColumnHeader(e, "Address of the token")}
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              Address
            </span>
          </div>
        ),
        accessor: "address",
        align: "right",
        minWidth: 25,
        filterable: false,
        sortable: false,
        Cell: row => (
          <div className="addressTextAndButton" style={{ float: "right" }}>
            <span>{row.value.startsWith("0x") ? toShortAddress(row.value) : row.value}</span>
            {row.value.startsWith("0x") ? (
              <button
                id={"copy" + row.value}
                className="copy"
                onClick={e => {
                  e.stopPropagation();
                  this.copyToClipboard(row.value);
                }}
                onMouseEnter={e => this.onMouseEnterColumnHeader(e, "Copy address to clipboard")}
                onMouseLeave={this.onMouseLeaveColumnHeader}
              >
                <FontAwesomeIcon icon={faCopy} color="#0abae7" />
              </button>
            ) : null}
          </div>
        )
      },
      {
        Header: () => (
          <div>
            <span
              id="my-account-balance"
              style={{ float: "right" }}
              onMouseEnter={e => this.onMouseEnterColumnHeader(e, "Tokens deposited to your account")}
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              Account Balance
            </span>
          </div>
        ),
        accessor: "accountBalance",
        minWidth: 30,
        maxWidth: 200,
        filterable: false,
        sortable: false,
        Cell: row => {
          const printedAccountBalance = !isNaN(row.value)
            ? row.original.token === "ETH"
              ? formatValue(Number(row.value), 4)
              : formatValue(Number(row.value), 2)
            : "";
          return (
            <span className="monospace" style={{ float: "right" }}>
              {printedAccountBalance}
            </span>
          );
        }
      },
      {
        Header: () => (
          <div>
            <span
              id="my-value"
              style={{ float: "right" }}
              onMouseEnter={e =>
                this.onMouseEnterColumnHeader(
                  e,
                  <>
                    Value in $ USD for tokens deposited to your account, including locked collateral. <br /> <br />
                  </>
                )
              }
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              Account Value
            </span>
          </div>
        ),
        accessor: "value",
        minWidth: 30,
        maxWidth: 200,
        filterable: false,
        sortable: false,
        Cell: row => {
          return (
            <span className="monospace" style={{ float: "right" }}>
              {(row.value || row.value == 0) && printPriceFromNumber(row.value[0])}
            </span>
          );
        },
        Footer: rows => {
          return (
            <span className="monospace" style={{ float: "right" }}>
              {rows.data.length > 0 && rows.data && this.getParamFooter(rows, "value")}
            </span>
          );
        }
      },
      {
        Header: () => (
          <div>
            <span
              id="my-wallet-balance"
              style={{ float: "right" }}
              onMouseEnter={e => this.onMouseEnterColumnHeader(e, "Tokens deposited to your wallet")}
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              Wallet Balance
            </span>
          </div>
        ),
        accessor: "walletBalance",
        minWidth: 30,
        maxWidth: 200,
        filterable: false,
        sortable: false,
        Cell: row => {
          return (
            <span className="monospace" style={{ float: "right" }}>
              {row.value || row.value == 0 ? (row.original.token === "ETH" ? formatValue(row.value, 4) : formatValue(row.value, 2)) : null}
            </span>
          );
        }
      },
      {
        Header: () => (
          <div>
            <span style={{ display: "block", margin: "auto", width: "fit-content" }}>Actions</span>
          </div>
        ),
        minWidth: 80,
        maxWidth: 320,
        filterable: false,
        sortable: false,
        Cell: row => (
          <div className="portfolioActions">
            {row.original.token === "USD" || row.original.token === "ETH" ? (
              <button
                className="portfolioActionButton"
                onClick={e => {
                  e.stopPropagation();
                  const token = this.selectData(row);
                  if (row.original.walletBalance != 0) {
                    this.setState({ isDepositModalOpen: true });
                    document.querySelector("body").classList.toggle("scrollDisabled");
                  }
                }}
                disabled={!this.props.marginAccountData || row.original.walletBalance == 0}
              >
                DEPOSIT
              </button>
            ) : null}
            {row.original.token === "USD" || row.original.token === "ETH" ? (
              <button
                className="portfolioActionButton"
                onClick={e => {
                  e.stopPropagation();
                  const token = this.selectData(row);
                  if (row.original.accountBalance != 0) {
                    this.setState({ isWithdrawModalOpen: true });
                    document.querySelector("body").classList.toggle("scrollDisabled");
                  }
                }}
                disabled={!this.props.marginAccountData || row.original.accountBalance == 0}
              >
                WITHDRAW
              </button>
            ) : null}
            {(row.original.token === "USD" || row.original.token === "ETH") && shouldShowRequestButton ? (
              <button
                className="portfolioActionButton"
                onClick={e => {
                  e.stopPropagation();
                  this.openOnboardingModal();
                }}
              >
                REQUEST
              </button>
            ) : null}
          </div>
        )
      }
    ];
    return tableColumns;
  };

  selectData = row => {
    let token;
    token = this.props.tokens.find(t => {
      return t.address === row.original.address;
    });
    if (!token) {
      token =
        this.props.openedPositionBalances.find(t => {
          return t.address === row.original.address;
        }) ||
        this.props.openedLpPositionBalances.find(t => {
          return t.address === row.original.address;
        });

      this.setState({ isPositionToken: true });
    } else {
      this.setState({ isPositionToken: false });
    }
    this.setState({ token: token });
    return token;
  };

  getTokenValue = token => {
    if (token.symbol === "USD") {
      return Number(token.normalizedAccountBalance);
    } else if (token.symbol === "ETH") {
      const underPrice = this.props.underlyingTokenPrice && Number(this.props.underlyingTokenPrice.normalized);
      return this.props.underlyingTokenPrice && Number(token.normalizedAccountBalance) * underPrice;
    }
  };

  getMyAccountData = () => {
    const myAccountData = this.props.tokens.map(t => {
      let token = t.symbol;
      let address = t.address;
      let accountBalance = t.symbol === "ETH" ? t.normalizedAccountBalance4Decimals : t.normalizedAccountBalance2Decimals;
      let walletBalance = t.symbol === "ETH" ? t.normalizedWalletBalance4Decimals : t.normalizedWalletBalance2Decimals;
      let value = [this.getTokenValue(t), "$"];

      return {
        token,
        address,
        accountBalance,
        value,
        walletBalance
      };
    });

    return myAccountData;
  };

  getMyPositionsData = () => {
    let trades = [];
    let lpTrades = [];

    this.props.openedTradePositions &&
      (trades = this.props.openedPositionBalances
        .filter(t => !(t.printableAccountBalance === "0.00") && t.printableAccountBalance)
        .map(t => {
          if (t.printableAccountBalance === "0.00") {
            return;
          }

          const position = this.props.openedTradePositions && this.props.openedTradePositions.find(p => p.tokenId === t.tokenId);
          const isShort = t.type === "short";

          let markValue;
          let baseName;

          let token = t.shortName;

          if (position && this.props.markets) {
            const market = this.props.markets.find(market => market.marketId === position.marketId);
            const isFuture = market.isFuture;
            const strikePrice = parseFloat(market.normalizedStrikePrice);
            const isCall = market.isCall;

            if (!position.tokenPair.hasOwnProperty("underlyingTokenPrice")) {
              return null;
            }
            const underlyingPrice = parseFloat(formatUnits(position.tokenPair.avgUnderPrice.toString(), 18));
            const collateralInBase = isCall ? underlyingPrice : strikePrice;
            const markBasePrice = isShort ? (!isFuture ? collateralInBase - position.midShortPrice[0] : 0) : isFuture ? underlyingPrice : position.midLongPrice;
            baseName = position.tokenPair.baseTokenSymbol;
            markValue = position.balance * markBasePrice;

            const marketIsExpired = market.isSettled || Number(market.expirationTime) < this.props.currentTimestamp;

            let expiredText = " (EXPIRED)";
            if (market.isSettled) {
              const underlyingPriceAtExpired = getUnderlyingPriceAtExpiredStr(market, this.props.settlements);
              expiredText = " (EXPIRED $" + underlyingPriceAtExpired + ")";
            }

            marketIsExpired && (token += expiredText);
          }

          let tokenId = t.tokenId.toString();
          let accountBalance = isShort && t.printableAccountBalance !== "0.00" ? "-" + t.printableAccountBalance : t.printableAccountBalance;
          let walletBalance = "0.00";

          baseName === "USD" && (baseName = "$");
          let value = markValue && [markValue, baseName];

          if (!position) {
            value = [0, "$"];
          }

          return {
            token,
            address: "",
            accountBalance,
            value,
            walletBalance
          };
        })
        .filter(t => {
          return t !== null;
        }));

    this.props.openedLPPositions &&
      (lpTrades = this.props.openedLpPositionBalances
        .map(t => {
          if (t.printableAccountBalance === "0.00" && t.printableWalletBalance === "0.00") {
            return;
          }

          const lpPosition = this.props.openedLPPositions && this.props.openedLPPositions.find(lp => lp.marketId === t.marketId);
          let markValue;
          let baseName;

          let token = t.shortName;

          if (lpPosition && this.props.markets) {
            const market = this.props.markets.find(market => market.marketId === lpPosition.marketId);
            const longPriceInBase = parseFloat(market.longPrice.toString());
            const strikePrice = parseFloat(market.normalizedStrikePrice);
            const isCall = market.isCall;

            if (!lpPosition.tokenPair.hasOwnProperty("underlyingTokenPrice")) {
              return null;
            }

            const underlyingPrice = parseFloat(formatUnits(lpPosition.tokenPair.underlyingTokenPrice.toString(), 18));
            const collateralInBase = isCall ? underlyingPrice : strikePrice;
            const markBasePrice =
              (lpPosition.liqPrice[0] + lpPosition.liqPrice[1] * longPriceInBase + lpPosition.liqPrice[2] * (collateralInBase - longPriceInBase)) *
              (lpPosition.balance / lpPosition.sizeInLongs);
            markValue = lpPosition.sizeInLongs * markBasePrice + 300;
            baseName = lpPosition.tokenPair.baseTokenSymbol;

            const marketIsExpired = market.isSettled || Number(market.expirationTime) < this.props.currentTimestamp;

            let expiredText = " (EXPIRED)";
            if (market.isSettled) {
              const underlyingPriceAtExpired = getUnderlyingPriceAtExpiredStr(market, this.props.settlements);
              expiredText = " (EXPIRED $" + underlyingPriceAtExpired + ")";
            }

            marketIsExpired && (token += expiredText);
          }
          let accountBalance = t.sizeInLongs && parseFloat(formatUnits(t.sizeInLongs.toString(), 18));

          let walletBalance = "0.00";
          baseName === "USD" && (baseName = "$");
          let value = [markValue, baseName];

          return {
            token,
            address: "",
            accountBalance,
            value,
            walletBalance
          };
        })
        .filter(t => {
          return t != null;
        }));

    const myPositionsData = [...trades, ...lpTrades];

    return myPositionsData;
  };

  getPortfolioTableData = () => {
    if (this.props.web3AccountLoaded) {
      return [...this.getMyAccountData(), ...this.getMyPositionsData()];
    }
    return [];
  };

  toggleWithdrawModal = () => {
    this.setState({ isWithdrawModalOpen: !this.state.isWithdrawModalOpen });
    document.querySelector("body").classList.toggle("scrollDisabled");
  };

  toggleDepositModal = () => {
    this.setState({ isDepositModalOpen: !this.state.isDepositModalOpen });
    document.querySelector("body").classList.toggle("scrollDisabled");
  };

  depositShouldBeApproved = async amount => {
    const tokenContract = new this.props.web3.eth.Contract(ERC20.abi, this.state.token.address);
    const allowance = await tokenContract.methods.allowance(this.props.web3account, this.props.userBalancesVault.options.address).call();
    return BigNumber.from(allowance.toString()).lt(BigNumber.from(amount.toString()));
  };

  withdrawFunds = amount => {
    // a trick to handle big numbers because Javascript numbers
    // larger than 9007199254740992 and smaller than -9007199254740992
    // are not precisely represented numbers and will not produce exact results
    let bnAmount = toBn(amount.toString(), 18);
    const symbol = this.state.token.type ? this.state.token.type : this.state.token.symbol;
    withdrawFunds(
      this.props.web3,
      this.props.userBalancesVault,
      this.state.token.address,
      bnAmount.toString(),
      this.props.web3account,
      symbol,
      this.toggleWithdrawModal,
      this.props.dispatch
    );
  };

  depositFunds = (amount, setStep) => {
    // a trick to handle big numbers because Javascript numbers
    // larger than 9007199254740992 and smaller than -9007199254740992
    // are not precisely represented numbers and will not produce exact results
    let bnAmount = toBn(amount.toString(), 18);
    const symbol = this.state.token.type ? this.state.token.type : this.state.token.symbol;
    depositFunds(
      this.props.web3,
      this.props.userBalancesVault,
      this.state.token.address,
      bnAmount.toString(),
      this.props.web3account,
      symbol,
      this.toggleDepositModal,
      setStep,
      this.props.dispatch
    );
  };

  debtMouseEnterHandler = e => {
    const { top, left } = e.currentTarget.getBoundingClientRect();
    this.setState({ borrowHoverPanelOpen: true, borrowHoverPanelLeft: window.innerWidth - left, borrowHoverPanelTop: top + 70 });
  };

  debtMouseLeaveHandler = e => {
    this.setState({ borrowHoverPanelOpen: false });
  };

  totalLiquidityMouseEnterHandler = e => {
    const { top, right } = e.currentTarget.getBoundingClientRect();
    this.setState({ totalLiquidityLegendOpen: true, totalLiquidityLegendRight: window.innerWidth - right, totalLiquidityLegendTop: top + 70 });
  };

  totalLiquidityMouseLeaveHandler = e => {
    this.setState({ totalLiquidityLegendOpen: false });
  };

  setTooltipIsVisible = visible => {
    this.setState({ tooltipIsVisible: visible });
  };

  setTooltipTarget = target => {
    this.setState({ tooltipTarget: target });
  };

  setTooltipContent = content => {
    this.setState({ tooltipContent: content });
  };

  onMouseEnterColumnHeader = (e, text) => {
    if (!this.state.tooltipIsVisible) {
      this.setState({ tooltipIsVisible: true, tooltipContent: text, tooltipTarget: e.currentTarget.id });
    }
  };

  onMouseLeaveColumnHeader = e => {
    if (!cursorRangeCheck(this.state.tooltipTarget, e.clientX, e.clientY)) {
      this.setState({ tooltipIsVisible: false, tooltipContent: "", tooltipTarget: "" });
      document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", -500 + "px");

      document.querySelector(":root").style.setProperty("--tooltipTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipLeftPosition", -500 + "px");
    }
  };

  setIsOnboardingModalOpen = () => {
    if (this.props.tokensLoaded) {
      const tokenETH = this.props.tokens.find(t => t.symbol === "ETH");
      const tokenUSD = this.props.tokens.find(t => t.symbol === "USD");
      const isGammaNet = this.props.web3.currentProvider.networkVersion === "7933";
      const isEthereumSepolia = this.props.web3.currentProvider.networkVersion === SEPOLIA_NETWORK_ID.toString();
      const isArbitrumSepolia = this.props.web3.currentProvider.networkVersion === ARBITRUM_SEPOLIA_NETWORK_ID.toString();
      const isBaseSepolia = this.props.web3.currentProvider.networkVersion === BASE_SEPOLIA_NETWORK_ID.toString();
      const isLocalHost = this.props.web3.currentProvider.networkVersion === "1337";
      if (
        tokenETH.accountBalance == "0" &&
        tokenETH.walletBalance == "0" &&
        tokenUSD.accountBalance == "0" &&
        tokenUSD.walletBalance == "0" &&
        (isGammaNet || isEthereumSepolia || isArbitrumSepolia || isBaseSepolia || isLocalHost)
      ) {
        this.setState({ isOnboardingModalOpen: true, isOnboardingModalCheckedForOpening: true });
        const chunk = "request-funds-modal";
        console.log("Navigate to: " + window.location.pathname + "/" + chunk); // eslint-disable-line no-console
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + "/" + chunk, title: "Request Funds Modal" });
      } else {
        this.setState({ isOnboardingModalOpen: false, isOnboardingModalCheckedForOpening: true });
      }
    }
  };

  openOnboardingModal = () => {
    if (this.props.tokensLoaded) {
      this.setState({ isOnboardingModalOpen: true, isOnboardingModalCheckedForOpening: true });
      const chunk = "request-funds-modal";
      console.log("Navigate to: " + window.location.pathname + "/" + chunk); // eslint-disable-line no-console
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + "/" + chunk, title: "Request Funds Modal" });
    }
  };

  render() {
    const netLiquidity =
      this.props.marginAccountData && this.props.web3AccountLoaded ? this.props.marginAccountData.readableNetLiquidity : displayLoadingIconOld();
    const excessLiquidity =
      this.props.marginAccountData && this.props.web3AccountLoaded ? this.props.marginAccountData.readableExcessLiquidity : displayLoadingIconOld();
    const totalDept = this.props.marginAccountData && this.props.web3AccountLoaded ? this.props.marginAccountData.readableTotalDebt : displayLoadingIconOld();
    const health = this.props.marginAccountData && this.props.web3AccountLoaded ? this.props.marginAccountData.readableHealth : displayLoadingIconOld();
    const healthForColor = this.props.marginAccountData && this.props.web3AccountLoaded ? this.props.marginAccountData.normalizedHealth : 0;
    const recoveryThreshold =
      this.props.marginAccountData && this.props.web3AccountLoaded ? parseFloat(fromBn(this.props.marginAccountData.recoveryThreshold)) * 100 : 0;
    const liquidationThreshold =
      this.props.marginAccountData && this.props.web3AccountLoaded ? parseFloat(fromBn(this.props.marginAccountData.liquidationThreshold)) * 100 : 0;

    const healthColorClass = this.props.web3AccountLoaded ? healthClassName(health, healthForColor, recoveryThreshold, liquidationThreshold) : "";
    const healthIcon = this.props.web3AccountLoaded
      ? displayHealthIcon(this.props.marginAccountData, health, healthForColor, recoveryThreshold, liquidationThreshold)
      : "";
    const apr = this.props.web3AccountLoaded
      ? printApr(this.props.marginAccountDataLoaded, this.props.marginPoolDataLoaded, this.props.marginAccountData, this.props.marginPoolData)
      : "";

    const aprClassName = typeof apr === "string" ? (apr.includes("-") ? "redAprColor" : "greenAprColor") : "";

    return (
      <>
        {this.state.borrowHoverPanelOpen && (
          <BorrowHoverPanel
            key={"borrowHoverPanelPortfolioPage"}
            open={this.state.borrowHoverPanelOpen}
            toggle={this.debtMouseLeaveHandler}
            left={this.state.borrowHoverPanelLeft}
            top={this.state.borrowHoverPanelTop}
          />
        )}
        {this.state.totalLiquidityLegendOpen && (
          <TotalLiquidityLegend
            key={"totalLiquidityLegentPortfolioPage"}
            open={this.state.totalLiquidityLegendOpen}
            toggle={this.totalLiquidityMouseLeaveHandler}
            right={this.state.totalLiquidityLegendRight}
            top={this.state.totalLiquidityLegendTop}
            isPortfolioPage
          />
        )}
        <div id="portfolioPageContent" className="content" style={{ paddingTop: "0px" }}>
          {this.state.isWithdrawModalOpen && (
            <WithdrawFundsModal
              isOpen={this.state.isWithdrawModalOpen}
              toggle={this.toggleWithdrawModal}
              token={this.state.token}
              withdrawFunds={this.withdrawFunds}
              isPositionToken={this.state.isPositionToken}
            />
          )}
          {this.state.isDepositModalOpen && (
            <DepositFundsModal
              isOpen={this.state.isDepositModalOpen}
              toggle={this.toggleDepositModal}
              token={this.state.token}
              depositFunds={this.depositFunds}
              isPositionToken={this.state.isPositionToken}
              depositShouldBeApproved={this.depositShouldBeApproved}
            />
          )}
          <OnboardingModal
            isOpen={this.state.isOnboardingModalOpen}
            closeModal={() => this.setState({ isOnboardingModalOpen: false })}
            toggleDepositModal={this.toggleDepositModal}
            setToken={token => this.setState({ token: token })}
            dispatch={this.props.dispatch}
          />
          <div className="portfolioOverview">
            <div className="portfolioOverviewTilesContainer">
              <div className="portfolioOverviewTile" key={"netLiquidity"}>
                <span
                  id="netLiquidityHeader"
                  className="portfolioOverviewTileTitle"
                  onMouseEnter={e => this.onMouseEnterColumnHeader(e, "Net liquidity is total account liquidity minus total account debt")}
                  onMouseLeave={this.onMouseLeaveColumnHeader}
                >
                  NET LIQUIDITY
                </span>
                <span className="portfolioOverviewTileContent">{this.props.web3AccountLoaded ? netLiquidity : ""}</span>
              </div>
              <div className="portfolioOverviewTile" key={"excessLiquidity"}>
                <span
                  id="excessLiquidityHeader"
                  className="portfolioOverviewTileTitle"
                  onMouseEnter={e => this.onMouseEnterColumnHeader(e, "Excess liquidity is available liquidity that can be withdrawn from the account.")}
                  onMouseLeave={this.onMouseLeaveColumnHeader}
                >
                  EXCESS LIQUIDITY
                </span>
                <span className="portfolioOverviewTileContent">{this.props.web3AccountLoaded ? excessLiquidity : ""}</span>
              </div>
              <div className="portfolioOverviewTile" key={"totalDept"}>
                <span
                  id="debtHeader"
                  className="portfolioOverviewTileTitle"
                  onMouseEnter={e => this.onMouseEnterColumnHeader(e, "Borrowed funds in $ USD. Borrow rate is applied to this amount.")}
                  onMouseLeave={this.onMouseLeaveColumnHeader}
                >
                  DEBT{" "}
                  {this.props.web3AccountLoaded && (
                    <FontAwesomeIcon icon={faExpandArrowsAlt} onMouseEnter={this.debtMouseEnterHandler} onMouseLeave={this.debtMouseLeaveHandler} />
                  )}
                </span>
                <span className="portfolioOverviewTileContent">{this.props.web3AccountLoaded ? totalDept : ""}</span>
              </div>
              <div className="portfolioOverviewTile" key={"apr"}>
                <span
                  id="aprHeader"
                  className="portfolioOverviewTileTitle"
                  onMouseEnter={e => this.onMouseEnterColumnHeader(e, "Annual percentage rate paid on debt")}
                  onMouseLeave={this.onMouseLeaveColumnHeader}
                >
                  BORROW RATE
                </span>
                <span className={`portfolioOverviewTileContent ${aprClassName}`}>
                  {this.props.web3AccountLoaded
                    ? printApr(this.props.marginAccountDataLoaded, this.props.marginPoolDataLoaded, this.props.marginAccountData, this.props.marginPoolData)
                    : ""}
                </span>
              </div>
              <div className="portfolioOverviewTile" key={"health"}>
                <div className="portfolioOverviewTileTitleHealth">
                  <span
                    id="healthHeader"
                    className="portfolioOverviewTileTitleTextHealth"
                    onMouseEnter={e =>
                      this.onMouseEnterColumnHeader(
                        e,
                        <>
                          Health is the ratio betweeen total account liquidity (including debt) and debt. <br /> <br />
                          Formula used: <br />
                          HEALTH = TOTAL_LIQ / DEBT <br /> <br />
                          Learn more: <br />
                          <a href="https://gamma-options.gitbook.io/docs/" target="_blank" rel="noreferrer">
                            Docs
                          </a>
                        </>
                      )
                    }
                    onMouseLeave={this.onMouseLeaveColumnHeader}
                  >
                    HEALTH
                  </span>
                  <img className={`${healthIcon}`} ref={this.totalLiquidityInfoRef} />
                </div>
                <span className={`portfolioOverviewTileContent ${healthColorClass}`}>{this.props.web3AccountLoaded ? health : ""}</span>
              </div>
            </div>
          </div>
          <Row>
            <Col className="mb-1" md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">
                    <div className="historyHeaderContainer">
                      <span className="headerTitle">POSITIONS</span>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody id="portfolioPositions" className={this.state.isPositionsBodyDisplayed ? "" : "displayNone"}>
                  {this.props.web3AccountLoaded ? (
                    this.props.marginAccountDataLoaded ? (
                      <ReactTable
                        className="-highlight"
                        data={this.getPortfolioTableData()}
                        columns={this.getTableColumns()}
                        defaultPageSize={10}
                        getTrProps={(state, rowInfo, column) => {
                          if (rowInfo && rowInfo.row) {
                            if (rowInfo.original.token !== "USD" && rowInfo.original.token !== "ETH") {
                              return {
                                style: {
                                  background: "#1b1b1e"
                                }
                              };
                            }
                          }
                          return {};
                        }}
                      />
                    ) : (
                      <div className="tableLoaderContainer">{displayLoadingIcon()}</div>
                    )
                  ) : null}
                  <ConnectPanel />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="mb-1" md="12">
              <DepositWithdrawHistoryPanel targetFund={POOL_NAME_MARGIN_ACCOUNT} />
            </Col>
          </Row>
        </div>
        {this.state.tooltipIsVisible && (
          <MyTooltip2
            target={this.state.tooltipTarget}
            setTooltipIsVisible={this.setTooltipIsVisible}
            setTooltipContent={this.setTooltipContent}
            setTooltipTarget={this.setTooltipTarget}
          >
            {this.state.tooltipContent}
          </MyTooltip2>
        )}
      </>
    );
  }
}

Portfolio.propTypes = {
  tokensLoaded: PropTypes.bool,
  tokens: PropTypes.array,
  web3AccountLoaded: PropTypes.bool,
  openedPositionBalances: PropTypes.array,
  openedLpPositionBalances: PropTypes.array,
  positionTokens: PropTypes.array,
  web3: PropTypes.object,
  userBalancesVault: PropTypes.object,
  web3account: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  markets: PropTypes.array,
  openedTradePositions: PropTypes.array,
  openedLPPositions: PropTypes.array,
  underlyingTokenPrice: PropTypes.object,
  marginAccountData: PropTypes.object,
  marginPoolData: PropTypes.object,
  marginAccountDataLoaded: PropTypes.bool,
  marginPoolDataLoaded: PropTypes.bool,
  marginPool: PropTypes.object,
  gammaContractsRegistry: PropTypes.object,
  loadHelper: PropTypes.object,
  currentTimestamp: PropTypes.number,
  settlements: PropTypes.array
};

function mapStateToProps(state) {
  return {
    tokensLoaded: tokensLoadedSelector(state),
    tokens: tokenBalancesSelector(state),
    web3AccountLoaded: web3AccountLoadedSelector(state),
    openedPositionBalances: openedPositionBalancesSelector(state),
    openedLpPositionBalances: openedLPPositionBalancesSelector(state),
    positionTokens: positionTokensSelector(state),
    web3: web3Selector(state),
    web3account: web3AccountSelector(state),
    markets: marketsSelector(state),
    userBalancesVault: userBalancesVaultSelector(state),
    openedTradePositions: openedTradePositionsSelector(state),
    openedLPPositions: openedLPPositionsSelector(state),
    underlyingTokenPrice: avgUnderlyingTokenPriceSelector(state),
    marginAccountData: marginAccountDataSelector(state),
    marginAccountDataLoaded: marginAccountDataLoadedSelector(state),
    marginPoolData: marginPoolDataSelector(state),
    marginPoolDataLoaded: marginPoolDataLoadedSelector(state),
    marginPool: marginPoolSelector(state),
    gammaContractsRegistry: gammaContractsRegistrySelector(state),
    loadHelper: loadHelperSelector(state),
    currentTimestamp: currentTimestampSelector(state),
    settlements: settlementDataSelector(state)
  };
}

export default connect(mapStateToProps)(Portfolio);
