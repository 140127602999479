import { get } from "lodash";
import { createSelector } from "reselect";

// web3 account
export const web3accountLoaded = state => get(state, "web3.accountLoaded");
export const web3AccountLoadedSelector = createSelector(web3accountLoaded, loaded => loaded);

export const web3account = state => get(state, "web3.account");
export const web3AccountSelector = createSelector(web3account, acc => acc);

// web3
const web3Loading = state => get(state, "web3.loading", {});
export const web3LoadingSelector = createSelector(web3Loading, loading => loading);

const web3Loaded = state => get(state, "web3.loaded", {});
export const web3LoadedSelector = createSelector(web3Loaded, loaded => loaded);

const connection = state => get(state, "web3.connection", {});
export const web3Selector = createSelector(connection, web3 => web3);

const web3read = state => get(state, "web3.web3read", {});
export const web3readSelector = createSelector(web3read, web3read => web3read);

const web3Object = state => get(state, "web3", {});
export const web3ObjectSelector = createSelector(web3Object, web3 => web3);

// blockchain timestamp
const currentTimestamp = state => get(state, "web3.currentTimestamp");
export const currentTimestampSelector = createSelector(currentTimestamp, ct => ct);
