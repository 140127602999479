import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

let timeoutId = null;

const MyTooltip2 = ({ target, setTooltipIsVisible, setTooltipContent, setTooltipTarget, children }) => {
  const targetElement = document.getElementById(target);

  useEffect(() => {
    if (
      (targetElement && parseInt(document.querySelector(":root").style.getPropertyValue("--tooltipLeftPosition")) <= 0) ||
      !parseInt(document.querySelector(":root").style.getPropertyValue("--tooltipLeftPosition"))
    ) {
      const targetOverlay = document.getElementById(target + "_tooltipOverlay");
      targetOverlay.classList.add("hidden");
      document.querySelector(".tooltipArrow").classList.add("hidden");
      const { width, height } = targetOverlay.getBoundingClientRect();
      const { left: targetLeft, width: targetWidth, top: targetTop, bottom: targetBottom } = targetElement.getBoundingClientRect();
      let newLeft = targetLeft + targetWidth / 2 - width / 2;
      if (newLeft < 0) {
        // if tooltip is out of left viewport
        newLeft = 5;
      }
      const leftMoreThanVw = newLeft + width;
      if (leftMoreThanVw > window.innerWidth) {
        const translateLeft = leftMoreThanVw - window.innerWidth;
        newLeft = newLeft - translateLeft - 5;
      }

      document.querySelector(":root").style.setProperty("--tooltipLeftPosition", newLeft + "px");
      document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", targetLeft + targetWidth / 2 - 8 + "px");

      document.querySelector(":root").style.setProperty("--tooltipTopPosition", targetTop - height - 10 + "px");
      document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", targetTop - 20 + "px");

      targetOverlay.style.height = height + 15 + "px";

      // this is case when top of tooltio when is above has negative top value and is not visible so we put them below
      const newTopTargetOverlay = targetOverlay.getBoundingClientRect().top;
      if (newTopTargetOverlay < 0) {
        document.querySelector(":root").style.setProperty("--tooltipTopPosition", targetBottom - 8 + "px");
        document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", targetBottom + 2 + "px");
        targetOverlay.style.justifyContent = "flex-end";
      }

      document.getElementById(target + "_tooltipOverlay").classList.remove("hidden");
      document.querySelector(".tooltipArrow").classList.remove("hidden");
    }
  }, [targetElement]);

  const onTargetOverlayLeave = e => {
    setTooltipIsVisible(false);
    setTooltipContent("");
    setTooltipTarget("");

    document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", -500 + "px");
    document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", -500 + "px");

    document.querySelector(":root").style.setProperty("--tooltipTopPosition", -500 + "px");
    document.querySelector(":root").style.setProperty("--tooltipLeftPosition", -500 + "px");
  };

  return createPortal(
    <>
      <div
        id={target + "_tooltipOverlay"}
        key={target + "_tooltipOverlay"}
        className="tooltipOverlay"
        onMouseLeave={onTargetOverlayLeave}
        onClick={e => e.stopPropagation()}
      >
        <div key={target + "_tooltipContainer"} id={target + "_tooltipContainer"} className="tooltipContainer">
          <div className="tooltipContent">{children}</div>
        </div>
      </div>
      <div key={target + "_tooltipArrow"} id={target + "_tooltipArrow"} className="tooltipArrow" />
    </>,
    document.getElementById("root")
  );
};

MyTooltip2.propTypes = {
  target: PropTypes.string,
  delay: PropTypes.number,
  children: PropTypes.object
};

export default MyTooltip2;
