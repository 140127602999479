import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader } from "reactstrap";
import { selectedTokenPairSelector } from "../../store/selectors/tokensSelectors";
import { web3Selector, web3AccountSelector, web3LoadedSelector, web3AccountLoadedSelector } from "../../store/selectors/web3Selectors";
import { settle } from "../../store/interactions/balancesInteractions";
import Timer from "react-compound-timer";
import { marketsLoadedSelector, marketsSelector } from "../../store/selectors/marketsSelectors";
import { userBalancesSelector, settlementsSelector } from "../../store/selectors/contractsSelectors";
import { expirationTimesSelector } from "../../store/selectors/dateUtilsSelectors";
import ConnectPanel from "./ConnectPanel.jsx";
import { displayLoadingIcon } from "../../utils/utils.js";

class SettlePanel extends React.Component {
  constructor() {
    super();
    this.state = {
      expiringMarkets: [],
      secondsUntilExpiration: 0,
      expiredMarkets: [],
      dataLoaded: false,
      deadlineEnableSettleButton: 60 // 1 minute in seconds
    };

    this.updateState = this.updateState.bind(this);
    this.getExpiringMarkets = this.getExpiringMarkets.bind(this);
    this.getExpiredNonSettledMarkets = this.getExpiredNonSettledMarkets.bind(this);
    this.settle = this.settle.bind(this);
    this.displayMessage = this.displayMessage.bind(this);
  }

  async componentDidMount() {
    if (this.props.web3AccountLoaded && this.props.web3Loaded) {
      await this.updateState();
    }
  }

  async componentDidUpdate(prevProps) {
    // update state if props updated

    if (this.props !== prevProps && this.props.web3AccountLoaded && this.props.web3Loaded) {
      await this.updateState();
    }
  }

  updateState = async () => {
    if (this.props.marketsLoaded) {
      const block = await this.props.web3.eth.getBlock();
      const now = block.timestamp;

      const expiringMarkets = this.getExpiringMarkets(now);
      const expiredMarkets = this.getExpiredNonSettledMarkets(now);
      const minimumTimestamp = this.props.markets.length > 0 ? this.findMinimumExpirationTime(expiringMarkets) : 0;
      const secsTilExp = minimumTimestamp - now;
      if (secsTilExp > 0 || expiredMarkets.length > 0) {
        this.setState({
          dataLoaded: true,
          expiringMarkets: expiringMarkets,
          secondsUntilExpiration: expiringMarkets.length > 0 ? secsTilExp : 0,
          expiredMarkets: expiredMarkets
        });
      } else {
        this.setState({ dataLoaded: true });
      }
    }
  };

  getExpiringMarkets = now => {
    if (this.props.marketsLoaded && this.props.selectedTokenPair && this.props.web3Loaded) {
      const minimumTimestamp = this.findMinimumExpirationTime(this.props.markets);

      return this.props.markets.filter(
        market =>
          market.baseTokenAddress === this.props.selectedTokenPair.baseTokenAddress &&
          market.underlyingTokenAddress === this.props.selectedTokenPair.underlyingTokenAddress &&
          Number(market.expirationTime) === minimumTimestamp &&
          !market.isSettled
      );
    }
    return [];
  };

  findMinimumExpirationTime = markets => {
    let minimumTimestamp = null;
    for (let i = 0; i < markets.length; i++) {
      const timestamp = Number(markets[i].expirationTime);
      if ((timestamp < minimumTimestamp || minimumTimestamp === null) && !markets[i].isSettled) {
        minimumTimestamp = timestamp;
      }
    }
    return minimumTimestamp;
  };

  getExpiredNonSettledMarkets = now => {
    if (this.props.web3Loaded && this.props.marketsLoaded && this.props.selectedTokenPair) {
      // find all non-settled expired markets
      return this.props.markets.filter(
        market =>
          market.baseTokenAddress === this.props.selectedTokenPair.baseTokenAddress &&
          market.underlyingTokenAddress === this.props.selectedTokenPair.underlyingTokenAddress &&
          market.expirationTime <= now + 1 &&
          !market.isSettled
      );
    }
    return [];
  };

  settle = () => {
    const minimumTimestamp = this.findMinimumExpirationTime(this.props.markets);

    settle(
      this.props.web3,
      this.props.settlementsContract,
      this.props.selectedTokenPair.baseTokenAddress,
      this.props.selectedTokenPair.underlyingTokenAddress,
      minimumTimestamp,
      this.props.web3Account,
      this.props.dispatch
    );
  };

  displayMessage = () => {
    if (this.state.expiredMarkets.length > 0) {
      return (
        <span className="settleMarketMessage">
          There are {this.state.expiredMarkets.length} expired markets that need to be settled. You can settle them right now.
        </span>
      );
    } else if (this.state.expiringMarkets.length > 0) {
      return (
        <Timer
          formatValue={value => `${value < 10 ? `0${value}` : value} units `}
          initialTime={Number(this.state.secondsUntilExpiration) * 1000}
          direction="backward"
          checkpoints={[
            {
              time: this.state.deadlineEnableSettleButton * 1000,
              callback: () => this.updateState()
            },
            {
              time: 1000, // 1 second before expiration
              callback: () => this.updateState()
            }
          ]}
        >
          {() => (
            <React.Fragment>
              <div className="settleMarketMessage">
                <span className="settleMarketMessage">
                  There are {this.state.expiringMarkets.length} active markets with the expiration date of{" "}
                  {this.state.expiringMarkets[0].readableExpirationTime} that will expire in:{" "}
                </span>
                <Timer.Days formatValue={value => `${value} days `} />
                <Timer.Hours formatValue={value => `${value} hours `} />
                <Timer.Minutes formatValue={value => `${value} minutes `} />
                <Timer.Seconds formatValue={value => `${value} seconds. `} />
                <br />
                <span className="settleMarketMessage">Anyone can settle them as soon as they expire.</span>
              </div>
            </React.Fragment>
          )}
        </Timer>
      );
    } else {
      return <span className="settleMarketMessage">There are no markets to settle.</span>;
    }
  };

  render() {
    const shouldHaveSettleButton = this.state.expiredMarkets.length > 0 || this.state.expiringMarkets.length > 0;

    return (
      <Card>
        <CardHeader className="handle">
          <div className="settleHeaderContainer">
            <span className="headerTitle">EXPIRING MARKETS</span>
          </div>
        </CardHeader>
        <CardBody>
          {this.props.web3AccountLoaded && (
            <div className="settleBodyContainer" style={{ justifyContent: this.state.dataLoaded ? "space-between" : "center" }}>
              {this.state.dataLoaded && this.props.marketsLoaded ? (
                <>
                  {this.displayMessage()}
                  {shouldHaveSettleButton && (
                    <button
                      className="settleButton"
                      disabled={
                        !(
                          this.state.expiredMarkets.length > 0 ||
                          (this.state.expiringMarkets.length > 0 && this.state.secondsUntilExpiration <= this.state.deadlineEnableSettleButton)
                        )
                      }
                      onClick={() => this.settle()}
                    >
                      SETTLE
                    </button>
                  )}
                </>
              ) : (
                this.props.web3AccountLoaded && displayLoadingIcon()
              )}
            </div>
          )}
          <ConnectPanel />
        </CardBody>
      </Card>
    );
  }
}

SettlePanel.propTypes = {
  settlementsContract: PropTypes.object,
  userBalances: PropTypes.object,
  web3: PropTypes.object,
  web3Loaded: PropTypes.bool.isRequired,
  web3Account: PropTypes.string,
  marketsLoaded: PropTypes.bool,
  web3AccountLoaded: PropTypes.bool,
  markets: PropTypes.array,
  selectedTokenPair: PropTypes.object,
  expirationTimes: PropTypes.array,
  dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    settlementsContract: settlementsSelector(state),
    userBalances: userBalancesSelector(state),
    web3: web3Selector(state),
    web3Loaded: web3LoadedSelector(state),
    web3Account: web3AccountSelector(state),
    web3AccountLoaded: web3AccountLoadedSelector(state),
    markets: marketsSelector(state),
    marketsLoaded: marketsLoadedSelector(state),
    selectedTokenPair: selectedTokenPairSelector(state),
    expirationTimes: expirationTimesSelector(state)
  };
}

export default connect(mapStateToProps)(SettlePanel);
