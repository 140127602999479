import { createSelector } from "reselect";
import { get, groupBy } from "lodash";
import { formatUnits } from "@ethersproject/units";
import { decorateTokenBalances, getLpPositionTokens, getPositionTokens, getTokenPairsList } from "../utils/tokensUtils";

export const tokensLoaded = state => get(state, "tokens.loaded");
export const tokensLoadedSelector = createSelector(tokensLoaded, loaded => loaded);

export const tokens = state => get(state, "tokens.data", []);
export const tokenBalancesSelector = createSelector(tokens, tokens => {
  tokens = tokens.map(token => {
    return decorateTokenBalances(token);
  });
  return tokens;
});

const selectedBaseTokenAddress = state => get(state, "tokenPairs.selectedTokenPair.baseTokenAddress");
export const selectedBaseTokenBalancesSelector = createSelector(tokens, selectedBaseTokenAddress, (tokens, selectedBaseTokenAddress) => {
  const selectedBaseTokens = tokens.filter(asset => asset.address === selectedBaseTokenAddress);
  if (selectedBaseTokens.length > 0) {
    return decorateTokenBalances(selectedBaseTokens[0]);
  } else {
    return null;
  }
});

const selectedUnderlyingTokenAddress = state => get(state, "tokenPairs.selectedTokenPair.underlyingTokenAddress");
export const selectedUnderlyingTokenBalancesSelector = createSelector(tokens, selectedUnderlyingTokenAddress, (tokens, selectedUnderlyingTokenAddress) => {
  const selectedUnderlyingTokens = tokens.filter(asset => asset.address === selectedUnderlyingTokenAddress);
  if (selectedUnderlyingTokens.length > 0) {
    return decorateTokenBalances(selectedUnderlyingTokens[0]);
  } else {
    return null;
  }
});

// TOKEN PAIRS
export const tokenPairsLoaded = state => get(state, "tokenPairs.loaded");
export const tokenPairsLoadedSelector = createSelector(tokenPairsLoaded, loaded => loaded);

export const tokenPairs = state => get(state, "tokenPairs.data", []);
export const tokenPairsSelector = createSelector(tokenPairs, pairs => {
  pairs = pairs.map(pair => {
    return {
      ...pair,
      normalizedUnderlyingTokenPrice: parseFloat(formatUnits(pair.underlyingTokenPrice.toString(), 18))
    };
  });
  pairs = groupBy(pairs, "baseTokenSymbol");
  return pairs;
});

export const tokenPairsListSelector = createSelector(tokenPairs, getTokenPairsList);

export const selectedTokenPair = state => get(state, "tokenPairs.selectedTokenPair");
export const selectedTokenPairSelector = createSelector(selectedTokenPair, pair => pair);

// POSITION TOKENS
const positionTokenRegistry = state => get(state, "positionTokenRegistry.contract");
export const positionTokenRegistrySelector = createSelector(positionTokenRegistry, contract => contract);

export const positionTokensLoaded = state => get(state, "positionTokens.loaded");
export const positionTokensLoadedSelector = createSelector(positionTokensLoaded, loaded => loaded);

export const positionTokens = state => get(state, "positionTokens.data", []);
export const positionTokensSelector = createSelector(positionTokens, getPositionTokens);

export const openedPositionBalancesSelector = createSelector(positionTokens, data => {
  if (data) {
    data = data.filter(
      positionToken =>
        (positionToken.accountBalance && Number(positionToken.accountBalance) > 0) || (positionToken.walletBalance && positionToken.walletBalance > 0)
    );
    data = data.map(token => {
      return decorateTokenBalances(token);
    });
    return data;
  }
  return [];
});

// LP POSITION TOKENS
export const lpPositionTokensLoaded = state => get(state, "lpPositionTokens.loaded");
export const lpPositionTokensLoadedSelector = createSelector(lpPositionTokensLoaded, loaded => loaded);

const lpPositionTokensNamesLoaded = state => get(state, "lpPositionTokens.data");
export const lpPositionTokensNamesLoadedSelector = createSelector(lpPositionTokensNamesLoaded, data => {
  if (!data) {
    return false;
  }

  for (let positionToken of data) {
    if (!positionToken.name) {
      return false;
    }
  }

  return true;
});

export const lpPositionTokens = state => get(state, "lpPositionTokens.data", []);
export const lpPositionTokensSelector = createSelector(lpPositionTokens, getLpPositionTokens);

export const openedLPPositionBalancesSelector = createSelector(lpPositionTokens, data => {
  if (data) {
    data = data.filter(positionToken => positionToken.accountBalanceLoaded && Number(positionToken.accountBalance) > 0);
    data = data.map(token => {
      //return decorateTokenBalances(token);
      return decorateTokenBalances(token);
    });
    return data;
  }
  return [];
});

export const underlyingTokenPriceSelector = createSelector(selectedTokenPair, selectedTokenPair => {
  if (selectedTokenPair && selectedTokenPair.underlyingTokenPrice) {
    const normalizedUnderlyingPrice = formatUnits(selectedTokenPair.underlyingTokenPrice, 18);

    let returnValue = {
      // price of one underlying token denominated in 18 decimals
      raw: selectedTokenPair.underlyingTokenPrice.toString(),
      // price of one underlying token
      normalized: normalizedUnderlyingPrice.toString(),
      // price of one underlying token with two decimal points (ready for print)
      printable: (+parseFloat(normalizedUnderlyingPrice).toFixed(2)).toLocaleString(),
      // true if the price went up or remained the same, false if it went down
      uptrend: selectedTokenPair.uptrend
    };
    return returnValue;
  } else {
    return null;
  }
});

export const avgUnderlyingTokenPriceSelector = createSelector(selectedTokenPair, selectedTokenPair => {
  if (selectedTokenPair && selectedTokenPair.avgUnderPrice) {
    const normalizedAvgUnderPrice = formatUnits(selectedTokenPair.avgUnderPrice, 18);

    let returnValue = {
      // price of one underlying token denominated in 18 decimals
      raw: selectedTokenPair.avgUnderPrice.toString(),
      // price of one underlying token
      normalized: normalizedAvgUnderPrice.toString(),
      // price of one underlying token with two decimal points (ready for print)
      printable: (+parseFloat(normalizedAvgUnderPrice).toFixed(2)).toLocaleString(),
      // true if the price went up or remained the same, false if it went down
      uptrend: selectedTokenPair.avgUptrend
    };
    return returnValue;
  } else {
    return null;
  }
});
