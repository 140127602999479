import React from "react";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";

const MyModal = props => {
  const closeModal = e => {
    e.stopPropagation();
    if (!props.isUnclosable) {
      props.toggle(true);
    }
  };

  if (!props.isOpen) {
    return null;
  }

  return createPortal(
    <div className="containerOverlay" onClick={closeModal}>
      {props.children}
    </div>,
    document.getElementById("root")
  );
};

MyModal.propTypes = {
  isOpen: PropTypes.bool,
  isUnclosable: PropTypes.bool,
  children: PropTypes.object,
  toggle: PropTypes.func
};

export default MyModal;

// class MyModal extends React.Component {
//   // eslint-disable-next-line react/require-render-return
//   constructor(props) {
//     super(props);
//   }

//   closeModal = e => {
//     e.stopPropagation();
//     this.props.toggle(true);
//   };

//   render() {
//     if (!this.props.isOpen) {
//       return null;
//     }

//     return createPortal(
//       <div className="containerOverlay" onClick={this.closeModal}>
//         {this.props.children}
//       </div>,
//       document.getElementById("root")
//     );
//   }
// }

// MyModal.propTypes = {
//   isOpen: PropTypes.bool,
//   children: PropTypes.object,
//   toggle: PropTypes.func
// };

// export default MyModal;
