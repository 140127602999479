/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
import React, { createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Input, FormGroup } from "reactstrap";
import {
  selectedTokenPairSelector,
  selectedBaseTokenBalancesSelector,
  selectedUnderlyingTokenBalancesSelector,
  avgUnderlyingTokenPriceSelector,
  tokenBalancesSelector
} from "../../store/selectors/tokensSelectors";
import { web3Selector, web3AccountSelector, web3ObjectSelector, currentTimestampSelector } from "../../store/selectors/web3Selectors";
import { openedLPPositionsSelector, openedTradePositionsSelector } from "../../store/selectors/positionsManagerSelectors";
import { positionsManagerSelector, lpManagerSelector, dydxMathSelector } from "../../store/selectors/contractsSelectors";
import {
  getOptionPrice,
  getDailyVolume,
  toPreciseString,
  limitValue,
  getRangeDecimals,
  healthClassName,
  defaultInputValue,
  debounce,
  isPositiveNumber,
  isHex,
  formatValue,
  printPriceFromNumber,
  getIdFromString,
  inputDecimals,
  removeCommaLocales,
  replaceCharacter,
  logBase,
  TICK_SPACING,
  getMaxLendAmount,
  getHealth
} from "../../utils/utils.js";
import {
  getLimits,
  getTickAtPrice,
  getNearestValidEvenTick,
  toSqrtPrice,
  MarketHelper,
  getLiquidityForLongs,
  getLongsForLiquidity,
  getRequiredBase,
  setTicks,
  insert,
  isMarketEmpty,
  getVolatilityWhenBuyingOptions,
  getVolatilityWhenSellingOptions,
  isPriceInGap,
  getOptionsInRangeFromLongsOnMarket,
  getVolatility
} from "../../utils/MarketHelper";
import { openMarketMakerPosition, closeMarketMakerPosition } from "../../store/interactions/positionsManagerInteractions";
import TransactionSettingsModal from "./TransactionSettingsModal.jsx";
import { fromBn, toBn } from "evm-bn";
import PositionsChart from "./charts/PositionsChart.jsx";
import { interpolateY, getChartData, interpolateGreeks, interpolateValue, getHighestDaysToExpiryRounded } from "../../utils/chartsUtils";
import { marketsSelector } from "../../store/selectors/marketsSelectors";
import { marginAccountDataSelector, marginPoolDataSelector, positionsBorrowFactorsSelector } from "../../store/selectors/marginSelectors";
import { getAPR } from "../../store/interactions/marginInteractions";
import TokenSelect from "./TokenSelect.jsx";
import SlidersPanel from "./SlidersPanel.jsx";
import CogChartOptions from "./CogChartOptions.jsx";
import MyTooltip from "./MyTooltip.jsx";

import LiquidationWorker from "./../../workers/liqWorker.worker.js";
import PositionLiquidationWorker from "./../../workers/positionLiqWorker.worker.js";
import { formatUnits } from "@ethersproject/units";
import { settlementDataSelector } from "../../store/selectors/balancesSelectors";
import moment from "moment";
import { currentIVOnMarket } from "../../../bot/api/deribitApi";
import FutureRangeSelect from "./FutureRangeSelect.jsx";

const FUTURE_RANGES = [
  {
    type: "buySell",
    text: "Buying and selling"
  }
];
class TradeModalPoolTab2 extends React.Component {
  constructor() {
    super();
    this.state = {
      addTabBaseAmount: 0,
      addTabCollateralAmount: 1,
      addTabCollateralAmountInput: "1",
      addTabBaseAmountInput: "",
      addTabLiqAmount: 0,
      addTabLiqAmountInput: "",
      addTabShortAmount: 0,
      addTabLongAmount: 0, // total longs on the market, both buying and selling
      addTabHealth: 0,
      addTabAPR: 0,
      addTabBaseAmountInputClass: "",
      addTabBaseErrMessage: "",
      addTabCollateralAmountInputClass: "",
      addTabCollateralErrMessage: "",
      addTabPreviewPosition: null,

      // adding/removing liquidity
      addTabMinPriceInVolAmount: 0,
      addTabMinPriceInBaseAmount: 0,
      addTabMinPriceTickIndex: 64, // can't be 0
      addTabMaxPriceInVolAmount: 0,
      addTabMaxPriceInBaseAmount: 0,
      addTabMaxPriceTickIndex: 96, // can't be 0
      addTabLeverage: 0,

      //for empty input field when default is 0.000001 or when is error and max should be pressed
      addTabObjectValues: null,

      removeTabSelectedPosition: null,
      removeTabBaseAmount: 0,
      removeTabCollateralAmount: 0,
      removeTabLiqAmount: 0,
      removeTabShortAmount: 0,

      tabs: "addLiquidity",

      isTransactionSettingsModalOpen: false,
      slippageTolerance: Number(localStorage.getItem("tradeSlippageTolerance")), // percent
      transactionTimeout: Number(localStorage.getItem("tradeTransactionTimeout")), // minutes

      idPrefix: "trading-modal-pool-tab-",

      selectedDaysToExpiry: 1,
      selectedVolatility: 30,
      dailyVolume: 0,

      shouldFocus: true,
      newPositionSelected: true,

      addTabCollateralBorrowed: false,

      cogChartOptionsOpen: false,

      liqPrices: null,
      lpLiqPrices: null,

      currentTick: 0, // used for current iv price

      // chart data
      chartData: {
        minDomainX: 60,
        maxDomainX: 100,
        minDomainY: 0,
        maxDomainY: 1,
        series1: [],
        series2: []
      },

      standardDeviation: 3,
      requiredCashMultiplier: 1.5,

      top: "0px",
      left: "0px",
      width: "0px",

      selectedFutureRange: FUTURE_RANGES[0]
    };

    this.collateralAmountInputRef = createRef();
    this.baseAmountInputRef = createRef();
    this.liqAmountInputRef = createRef();
    this.addMinPriceInputRef = createRef();
    this.addMaxPriceInputRef = createRef();
    this.poolModalAddButtonRef = createRef();
    this.poolModalRemoveButtonRef = createRef();
    this.currentIvInputRef = createRef();

    this.getEmptyChartData = this.getEmptyChartData.bind(this);
    this.getPreviewPosition = this.getPreviewPosition.bind(this);
    this.calculateChartData = this.calculateChartData.bind(this);
    this.onRemoveTabOptionsInRangeChange = this.onRemoveTabOptionsInRangeChange.bind(this);
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
    this.onRemoveButtonClick = this.onRemoveButtonClick.bind(this);
    this.onMaxAddButtonClick = this.onMaxAddButtonClick.bind(this);
    this.onMaxRemoveButtonClick = this.onMaxRemoveButtonClick.bind(this);
    this.setMaxRemoveAmount = this.setMaxRemoveAmount.bind(this);

    this.onMinPriceMinusButtonClick = this.onMinPriceMinusButtonClick.bind(this);
    this.onMinPricePlusButtonClick = this.onMinPricePlusButtonClick.bind(this);
    this.onMaxPriceMinusButtonClick = this.onMaxPriceMinusButtonClick.bind(this);
    this.onMaxPricePlusButtonClick = this.onMaxPricePlusButtonClick.bind(this);
    this.calculateMinAndMaxPriceOnUnderPriceUpdate = this.calculateMinAndMaxPriceOnUnderPriceUpdate.bind(this);

    this.hasInsufficientLiqFunds = this.hasInsufficientLiqFunds.bind(this);
    this.displayBigLine = this.displayBigLine.bind(this);
    this.displayLine = this.displayLine.bind(this);
    this.displayHealth = this.displayHealth.bind(this);
    this.displayAPR = this.displayAPR.bind(this);
    this.displayAddTransitions = this.displayAddTransitions.bind(this);
    this.displayRemoveTransitions = this.displayRemoveTransitions.bind(this);
    this.toggleTransactionSettingsModal = this.toggleTransactionSettingsModal.bind(this);
    this.setSlippageTolerance = this.setSlippageTolerance.bind(this);
    this.setTransactionTimeout = this.setTransactionTimeout.bind(this);

    this.onMinPriceInputBlur = this.onMinPriceInputBlur.bind(this);
    this.onMaxPriceInputBlur = this.onMaxPriceInputBlur.bind(this);
    this.onAddTabBaseChange = this.onAddTabBaseChange.bind(this);
    this.onAddTabOptionsInRangeChange = this.onAddTabOptionsInRangeChange.bind(this);
    this.setErrorMessage = this.setErrorMessage.bind(this);

    this.volAmountsAreCalculated = this.volAmountsAreCalculated.bind(this);

    this.displaySelectedLPPosition = this.displaySelectedLPPosition.bind(this);
    this.onLPPositionSelected = this.onLPPositionSelected.bind(this);
    this.getPositionsForSelectedMarket = this.getPositionsForSelectedMarket.bind(this);
    this.displayPnLTable = this.displayPnLTable.bind(this);
    this.getDaysSinceStart = this.getDaysSinceStart.bind(this);
    this.getMarketData = this.getMarketData.bind(this);
    this.selectXCallback = this.selectXCallback.bind(this);
    this.deselectXCallback = this.deselectXCallback.bind(this);
  }

  async componentDidMount() {
    // account liquidation worker
    this.liquidationWorker = new LiquidationWorker();
    this.liquidationWorker.onmessage = e => {
      const liqPrices = e.data;
      if (!this.state.liqPrices) {
        this.setState({ liqPrices });
        return;
      }

      if (
        this.state.liqPrices &&
        (this.state.liqPrices.upperLiqPrice !== liqPrices.upperLiqPrice ||
          this.state.liqPrices.lowerLiqPrice !== liqPrices.lowerLiqPrice ||
          this.state.liqPrices.maxX !== liqPrices.maxX)
      ) {
        this.setState({ liqPrices });
      }
    };

    // position liquidation worker
    this.positionLiquidationWorker = new PositionLiquidationWorker();
    this.positionLiquidationWorker.onmessage = e => {
      const lpLiqPrices = e.data;
      if (!this.state.lpLiqPrices) {
        this.setState({ lpLiqPrices });
        return;
      }

      if (
        this.state.lpLiqPrices &&
        (this.state.lpLiqPrices.callLiqPrice !== lpLiqPrices.callLiqPrice || this.state.lpLiqPrices.putLiqPrice !== lpLiqPrices.putLiqPrice)
      ) {
        this.setState({ lpLiqPrices });
      }
    };

    await this.initStateAndInputs();

    if (this.props.tabs === "add" && this.collateralAmountInputRef.current) {
      this.collateralAmountInputRef.current.select();
      if (this.props.market.marketHelper.currentLiquidity === 0) {
        this.setState({ addTabCollateralAmountInput: "3", addTabCollateralAmountInputClass: "has-success" });
        this.collateralAmountInputRef.current.value = 3;
      } else {
        this.setState({ addTabCollateralAmountInput: "3", addTabCollateralAmountInputClass: "has-success" });
        this.collateralAmountInputRef.current.value = 3;
      }
      this.validateInputs();
    }

    // set add/remove button width
    const greeksTable = document.querySelector(".greeksTable");
    if (greeksTable) {
      const { width } = greeksTable.getBoundingClientRect();
      const addRemoveActionButton = this.poolModalAddButtonRef.current || this.poolModalRemoveButtonRef.current;
      addRemoveActionButton.style.width = width + "px";
    }

    // keyboard events
    if (this.props.isOpen) {
      document.onkeydown = this.onKeyPress;
    }

    // add paste event listener to collateral input
    if (this.collateralAmountInputRef.current) {
      this.collateralAmountInputRef.current.addEventListener("paste", e => {
        this.paste(e, "collateral");
      });
    }

    if (this.baseAmountInputRef.current) {
      this.baseAmountInputRef.current.addEventListener("paste", e => {
        this.paste(e, "base");
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldRender = true;
    if (nextProps.markets !== this.props.markets) {
      const currentMarket = this.props.market;
      const nextMarket = nextProps.markets.find(market => market.marketId === currentMarket.marketId);
      if (nextMarket && nextMarket.openLongPrice === currentMarket.openLongPrice && nextMarket.closeLongPrice === currentMarket.closeLongPrice) {
        shouldRender = false;
      }
    }
    if (nextProps.web3Object !== this.props.web3Object) {
      if (nextProps.web3Object.currentTimestamp === this.props.web3Object.currentTimestamp) {
        shouldRender = false;
      }
    }
    return shouldRender;
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.shouldFocus && this.props.tabs === "add") {
      this.collateralAmountInputRef.current.select();
      this.setState({ shouldFocus: false });
    }

    // set add/remove button width
    const greeksTable = document.querySelector(".greeksTable");
    if (greeksTable) {
      const { width } = greeksTable.getBoundingClientRect();
      const addRemoveActionButton = this.poolModalAddButtonRef.current || this.poolModalRemoveButtonRef.current;
      addRemoveActionButton.style.width = width + "px";
    }

    // if switched from remove to add tab
    if (prevProps.tabs === "remove" && this.props.tabs === "add") {
      await this.initStateAndInputs();
      this.collateralAmountInputRef.current.select();
      this.setState({
        chartData: this.getEmptyChartData()
      });
    }

    if (this.props.tabs === "add" && prevProps.tabs !== "add") {
      this.collateralAmountInputRef.current.select();
      this.setState({ addTabCollateralAmountInput: "1", addTabCollateralAmountInputClass: "has-success" });
      this.validateInputs();
    }

    const underPrice = Math.round(parseFloat(this.props.underlyingTokenPrice.normalized) * 100) / 100;
    const volatility = getVolatility(this.props.market, this.state.currentTick);

    // update selected position in state on remove tab
    if (this.props.tabs === "remove") {
      const positionsForSelectedmarket = this.getPositionsForSelectedMarket();
      // if selected position is undefined, get first position
      // otherwise update selected position
      if (this.state.removeTabSelectedPosition === null && !this.props.needsToBeClosed) {
        if (positionsForSelectedmarket.length > 0) {
          const daysToExpiration = getHighestDaysToExpiryRounded([positionsForSelectedmarket[0]], this.props.markets);
          this.calculateChartData(underPrice, daysToExpiration, volatility, null, [positionsForSelectedmarket[0]]);
          this.setState({
            removeTabSelectedPosition: positionsForSelectedmarket[0]
          });
        }
      } else if (this.state.removeTabSelectedPosition === null && this.props.needsToBeClosed) {
        const daysToExpiration = getHighestDaysToExpiryRounded([positionsForSelectedmarket[0]], this.props.markets);
        this.calculateChartData(underPrice, daysToExpiration, volatility, null, [positionsForSelectedmarket[0]]);
        this.setState({
          removeTabSelectedPosition: this.props.selectedPosition
        });
        this.setMaxRemoveAmount(this.props.selectedPosition.sizeInLongs);
        this.props.setNeedsToBeClosed(null);
      } else {
        const updatedPosition = positionsForSelectedmarket.find(position => {
          return position.positionId === this.state.removeTabSelectedPosition.positionId;
        });
        if (updatedPosition !== undefined && updatedPosition !== this.state.removeTabSelectedPosition) {
          const daysToExpiration = getHighestDaysToExpiryRounded([updatedPosition], this.props.markets);
          this.calculateChartData(underPrice, daysToExpiration, volatility, null, [updatedPosition]);
          this.setState({
            removeTabSelectedPosition: updatedPosition
          });
        }
      }
    }

    // if switched from add to remove tab, fill in liquidity amount
    if (prevProps.tabs === "add" && this.props.tabs === "remove") {
      this.liqAmountInputRef.current.select();
      if (this.state.removeTabSelectedPosition !== null) {
        this.setMaxRemoveAmount(this.state.removeTabSelectedPosition.sizeInLongs);
      }
    }

    // if selected new position on remove tab, call set max remove amount
    if (
      this.props.tabs === "remove" &&
      (!prevState.removeTabSelectedPosition ||
        prevState.removeTabSelectedPosition.balance !== this.state.removeTabSelectedPosition.balance ||
        prevState.removeTabSelectedPosition.marketId !== this.state.removeTabSelectedPosition.marketId ||
        prevState.removeTabSelectedPosition.name !== this.state.removeTabSelectedPosition.name)
    ) {
      if (this.state.removeTabSelectedPosition) {
        this.liqAmountInputRef.current.select();
        this.setMaxRemoveAmount(this.state.removeTabSelectedPosition.sizeInLongs);
      }
    }

    if (this.props.isOpen) {
      document.onkeydown = this.onKeyPress;
    }

    let addTabObjectRetval = null;
    let shouldBePrintedDefaultForCollateral = false;
    if (
      this.props.tabs === "add" &&
      (this.state.addTabCollateralAmountInput !== prevState.addTabCollateralAmountInput ||
        this.state.addTabCollateralErrMessage !== prevState.addTabCollateralErrMessage)
    ) {
      shouldBePrintedDefaultForCollateral = this.state.addTabCollateralAmountInput === "" || this.state.addTabCollateralErrMessage.trim() !== "";
      if (shouldBePrintedDefaultForCollateral) {
        let maxOptionsInRangeInput;
        if (this.state.addTabCollateralErrMessage.includes("at least 1 option")) {
          maxOptionsInRangeInput = this.findMinimumInputForAtLeastOneBuyOneSell();
        } else {
          maxOptionsInRangeInput = this.getMaxOptionsInRangeInput();
        }
        const inputValueCollateral =
          this.state.addTabCollateralAmountInput === "" || Number(this.state.addTabCollateralAmountInput) == 0 ? defaultInputValue : maxOptionsInRangeInput;
        addTabObjectRetval = this.onAddTabOptionsInRangeChange(inputValueCollateral, true, false);
      }
    }

    let shouldBePrintedDefaultForBase = false;
    if (
      this.props.tabs === "add" &&
      (this.state.addTabBaseAmountInput !== prevState.addTabBaseAmountInput || this.state.addTabBaseErrMessage !== prevState.addTabBaseErrMessage) &&
      !shouldBePrintedDefaultForCollateral &&
      this.state.addTabCollateralAmountInput !== "" &&
      this.state.addTabCollateralErrMessage === ""
    ) {
      shouldBePrintedDefaultForBase = this.state.addTabBaseAmountInput === "" || this.state.addTabBaseErrMessage.trim() !== "";

      if (shouldBePrintedDefaultForBase) {
        addTabObjectRetval = this.onAddTabOptionsInRangeChange(this.state.addTabCollateralAmountInput, true, false);
      }
    }
    if (shouldBePrintedDefaultForCollateral || shouldBePrintedDefaultForBase) {
      this.setState({ addTabObjectValues: { ...addTabObjectRetval } });
    }

    // if market volatility price is changed, update USD reserves and min and max price
    if (this.props.market.longPriceInVol !== prevProps.market.longPriceInVol) {
      if (this.props.tabs === "add") {
        this.validateInputs();
        this.calculateMinAndMaxPriceOnUnderPriceUpdate();
      } else {
        this.onRemoveTabOptionsInRangeChange();
      }
    }

    // if market long price in base is changed, update USD reserves and min and max price
    if (this.props.market.longPrice !== prevProps.market.longPrice) {
      if (this.props.tabs === "add") {
        this.validateInputs();
        this.calculateMinAndMaxPriceOnUnderPriceUpdate();
      }
    }
  }

  componentWillUnmount() {
    document.onkeydown = null;
    this.liquidationWorker.terminate();
    this.positionLiquidationWorker.terminate();
  }

  // returns volatility from deribit if price in gap
  getDefaultVolatility = async () => {
    let volatility = Math.round(parseFloat(this.props.market.longPriceInVol));
    if (isPriceInGap(this.props.market.marketHelper)) {
      const expirationTime = this.props.market.expirationTime;
      const dateShort = moment(new Date(expirationTime * 1000))
        .format("DMMMYY")
        .toUpperCase();
      const strikePrice = this.props.market.strikePrice;
      const isCall = this.props.market.isCall;

      // const currentIVOnDeribitMarket = await currentIVOnMarket("ETH", dateShort, strikePrice / 10 ** 18, isCall);
      const currentIVOnDeribitMarket = await currentIVOnMarket("ETH", dateShort, strikePrice, isCall);
      if (currentIVOnDeribitMarket !== 0) {
        console.log("Setting default volatility from Deribit:", currentIVOnDeribitMarket);
      }
      if (currentIVOnDeribitMarket >= 1 && currentIVOnDeribitMarket <= 1000.7) {
        volatility = currentIVOnDeribitMarket;
      } else {
        volatility = 30;
      }
    }
    return volatility;
  };

  getMarkets = () => {
    return this.props.markets.map(market => {
      const marketOverriden = { ...market };
      if (marketOverriden.marketId === this.props.market.marketId) {
        marketOverriden.longPriceInVol = getVolatility(this.props.market, this.state.currentTick);
      }
      return marketOverriden;
    });
  };

  paste = (e, input) => {
    e.stopPropagation();
    e.preventDefault();

    const clipboardData = e.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData("Text");
    pastedData = removeCommaLocales(pastedData);
    if (input === "collateral") {
      this.pasteLogic(e, this.collateralAmountInputRef.current);
    } else if (input === "base") {
      this.pasteLogic(e, this.baseAmountInputRef.current);
    }
    this.validateInputs();

    if (input === "collateral") {
      this.onAddTabOptionsInRangeChange(null, false, false);
    } else if (input === "base") {
      this.onAddTabBaseChange(null, null, false);
    }
  };

  pasteLogic = (e, input) => {
    const selection = window.getSelection().toString();
    const clipboardData = e.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData("Text");
    pastedData = removeCommaLocales(pastedData);
    let newPastedData;
    if (selection !== "") {
      newPastedData = inputDecimals(replaceCharacter(input.value, input.selectionStart, input.selectionEnd - 1, pastedData), "LP");
    } else {
      newPastedData = inputDecimals(input.value + pastedData, "LP");
    }
    if (newPastedData !== undefined && newPastedData !== null && !isHex(newPastedData)) {
      if (selection !== "") {
        input.value = replaceCharacter(input.value, input.selectionStart, input.selectionEnd - 1, pastedData);
        this.setState({
          addTabCollateralAmountInput: replaceCharacter(input.value, input.selectionStart, input.selectionEnd - 1, pastedData)
        });
      } else {
        input.value = input.value + pastedData;
        this.setState({ addTabCollateralAmountInput: input.value + pastedData });
      }
    }
  };

  onKeyPress = e => {
    if (e.key === "Escape") {
      this.props.toggle();
    }
  };

  getEmptyChartData = () => {
    return {
      minDomainX: 60,
      maxDomainX: 100,
      minDomainY: 0,
      maxDomainY: 1,
      series1: [],
      series2: []
    };
  };

  initStateAndInputs = async () => {
    const daysToExpiration = getHighestDaysToExpiryRounded([{ marketId: this.props.market.marketId }], [this.props.market]);
    const selectedUnderPrice = Math.round(parseFloat(this.props.underlyingTokenPrice.normalized) * 100) / 100;
    let volatility = await this.getDefaultVolatility(this.props.market.marketHelper);
    const currentTick = parseInt(logBase(1.0001, volatility));

    let shouldFocus = true;
    if (this.collateralAmountInputRef.current && !this.collateralAmountInputRef.current.disabled && this.state.shouldFocus) {
      this.collateralAmountInputRef.current.focus();
      shouldFocus = false;
    }

    let minValidPriceInVol = this.state.addTabMinPriceInVolAmount;
    let maxValidPriceInVol = this.state.addTabMaxPriceInVolAmount;
    let dailyVolume = getDailyVolume(this.props.market);
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);

    let helper = new MarketHelper(0);

    // get min and max price price (-15%, + 15%)
    let minPriceInVol = volatility * (this.props.market.isFuture ? 0.926 : 0.86956);
    let maxPriceInVol = volatility * (this.props.market.isFuture ? 1.08 : 1.15);

    let limits = getLimits(minPriceInVol, maxPriceInVol, helper, TICK_SPACING);
    minValidPriceInVol = 1.0001 ** limits.lower;
    let minPriceInBase = getOptionPrice(
      this.props.selectedTokenPair.riskFreeRate / 100,
      this.props.market.expirationTime,
      this.props.market.normalizedStrikePrice,
      this.props.market.isCall,
      underPrice,
      minValidPriceInVol
    );

    maxValidPriceInVol = 1.0001 ** limits.upper;
    let maxPriceInBase = getOptionPrice(
      this.props.selectedTokenPair.riskFreeRate / 100,
      this.props.market.expirationTime,
      this.props.market.normalizedStrikePrice,
      this.props.market.isCall,
      underPrice,
      maxValidPriceInVol
    );

    if (this.props.selectedPosition && this.props.selectedPosition.type === "lp") {
      minValidPriceInVol = 1.0001 ** this.props.selectedPosition.lower;
      maxValidPriceInVol = 1.0001 ** this.props.selectedPosition.upper;
    }

    // update inputs if it's not price change
    let addMinPriceInput = this.addMinPriceInputRef.current;
    let addMaxPriceInput = this.addMaxPriceInputRef.current;

    if (addMinPriceInput !== null && addMaxPriceInput !== null) {
      const decimalsMin = getRangeDecimals(minValidPriceInVol);
      const decimalsMax = getRangeDecimals(maxValidPriceInVol);
      addMinPriceInput.value = minValidPriceInVol.toFixed(decimalsMin);
      addMaxPriceInput.value = maxValidPriceInVol.toFixed(decimalsMax);
    }

    // get first position for removeTabSelectedPosition
    const positionsForSelectedmarket = this.getPositionsForSelectedMarket();

    if (this.collateralAmountInputRef.current) {
      this.collateralAmountInputRef.current.value = 1;
      this.collateralAmountInputRef.current.select();
    }

    if (this.currentIvInputRef.current) {
      this.currentIvInputRef.current.value = volatility.toFixed(1);
    }

    // finally, update state and then call validateInputs
    this.setState(
      {
        currentTick: currentTick,
        selectedUnderPrice: selectedUnderPrice,
        selectedDaysToExpiry: daysToExpiration,
        selectedVolatility: volatility,
        addTabMinPriceInVolAmount: minValidPriceInVol,
        addTabMinPriceInBaseAmount: minPriceInBase,
        addTabMinPriceTickIndex: this.props.selectedPosition && this.props.selectedPosition.type === "lp" ? this.props.selectedPosition.lower : limits.lower,
        addTabMaxPriceInVolAmount: maxValidPriceInVol,
        addTabMaxPriceInBaseAmount: maxPriceInBase,
        addTabMaxPriceTickIndex: this.props.selectedPosition && this.props.selectedPosition.type === "lp" ? this.props.selectedPosition.upper : limits.upper,
        dailyVolume: dailyVolume,
        shouldFocus: shouldFocus,
        removeTabSelectedPosition: positionsForSelectedmarket[0]
      },
      () => {
        this.validateInputs();
      }
    );
  };

  getPreviewPosition = (sizeInLongs, requiredBase, openShorts, collateralAmount, liquidity) => {
    const openLongs = collateralAmount / (this.props.market.isCall ? 1 : this.props.market.normalizedStrikePrice);
    const longPrice = parseFloat(this.props.market.longPrice);
    const underPrice = this.props.underlyingTokenPrice.normalized;

    const previewLPPosition = {
      isPreview: true,
      type: "lp",
      balance: liquidity,
      sizeInLongs: sizeInLongs,
      openLiqPrice: requiredBase / liquidity,
      liqPrice: [requiredBase / liquidity, openLongs / liquidity, openShorts / liquidity],
      requiredBase: requiredBase,
      openLongs: openLongs,
      openShorts: openShorts,
      marketId: this.props.market.marketId,
      isCall: this.props.market.isCall,
      strikePrice: this.props.market.normalizedStrikePrice,
      midLongPrice: longPrice,
      lowerPriceInVol: this.state.addTabMinPriceInVolAmount,
      upperPriceInVol: this.state.addTabMaxPriceInVolAmount,
      lower: this.state.addTabMinPriceTickIndex,
      upper: this.state.addTabMaxPriceTickIndex,
      tokenPair: { underlyingTokenPrice: this.props.underlyingTokenPrice.raw },
      feeBalance: 0
    };

    return previewLPPosition;
  };

  calculateChartData = (selectedUnderPrice, selectedDaysToExpiry, selectedVolatility, selectedDeviation, positions, shouldSetState = true) => {
    // override selectedUnderPrice and daysToExpiration for remove tab
    const standardDeviation = selectedDeviation ? selectedDeviation : this.state.standardDeviation;

    // override selectedDaysToExpiry to 0 and underPrice to settlementPrice when expired or settled
    const marketIsExpired = this.props.market.isSettled || Number(this.props.market.expirationTime) < this.props.web3Object.currentTimestamp;
    let underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);

    if (marketIsExpired) {
      selectedDaysToExpiry = 0;
      const settlement = this.props.settlements.find(s => s.expirationTime === this.props.market.expirationTime);
      if (settlement) {
        underPrice = parseFloat(formatUnits(settlement.actualUnderlyingPrice.toString(), 18));
      }
    }

    // creates new array using user set volatility
    const markets = this.getMarkets();

    // chart data for new position
    const baseBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "USD").normalizedBorrowInterestRate;
    const underBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "ETH").normalizedBorrowInterestRate;
    const borrowRates = { baseBorrowRate, underBorrowRate };
    const chartData = getChartData(
      positions,
      markets,
      underPrice,
      selectedDaysToExpiry,
      selectedVolatility,
      this.props.selectedTokenPair.riskFreeRate / 100,
      borrowRates,
      standardDeviation
    );

    // liquidation price
    const position = positions[0];
    let assetsBase = this.props.tokenBalances.find(t => t.symbol === "USD").normalizedAccountBalance;
    let assetsUnder = this.props.tokenBalances.find(t => t.symbol === "ETH").normalizedAccountBalance;
    let debtBase = -this.props.marginAccountData.collateralTokens.find(data => data.symbol === "USD").normalizedBorrowedAmount;
    let debtUnder = -this.props.marginAccountData.collateralTokens.find(data => data.symbol === "ETH").normalizedBorrowedAmount;

    const positionsAreLoaded = !!(this.props.openedTradePositions && this.props.openedLPPositions);
    const allPositions = positionsAreLoaded ? [...this.props.openedTradePositions, ...this.props.openedLPPositions] : [];
    if (this.props.tabs === "add") {
      allPositions.push(position);
    }

    // assets and debt for new position
    if (this.props.tabs === "add") {
      if (position.balance > 0) {
        assetsBase -= position.requiredBase;
        if (position.isCall) {
          debtUnder += position.balance * position.liqPrice[2];
        } else {
          debtBase += position.balance * position.liqPrice[2] * position.strikePrice;
        }
      }
    }

    // adjust selected volatility for multiple positions
    let allPositionsSelectedVolatility = parseFloat(selectedVolatility);
    if (allPositions.length > 1) {
      let marketVolatility = getVolatility(this.props.market, this.state.currentTick);
      allPositionsSelectedVolatility = selectedVolatility - marketVolatility;
    }

    const marketsToWorker = this.props.markets.map(market => {
      const marketToWorker = { ...market };
      delete marketToWorker.contract;
      return marketToWorker;
    });

    // account liquidation worker
    this.liquidationWorker.postMessage({
      allPositions,
      markets: marketsToWorker,
      underPrice,
      selectedDaysToExpiry,
      allPositionsSelectedVolatility,
      riskFreeRate: this.props.selectedTokenPair.riskFreeRate / 100,
      assetsBase,
      assetsUnder,
      debtBase,
      debtUnder,
      borrowRates
    });

    // position liquidation worker
    this.positionLiquidationWorker.postMessage({
      allPositions: [position], // use only preview position
      markets: marketsToWorker,
      underPrice,
      riskFreeRate: this.props.selectedTokenPair.riskFreeRate / 100
    });

    if (shouldSetState) {
      this.setState({
        chartData: chartData,
        selectedUnderPrice,
        selectedDaysToExpiry,
        selectedVolatility,
        standardDeviation
      });
    }
    return chartData;
  };

  closeModalOnAction = () => {
    this.props.toggle();
    window.location.href = "#pool_positions";
  };

  onAddButtonClick = e => {
    e.stopPropagation();
    const baseAmount = this.state.addTabBaseAmount;

    // create limits object using ticks and MarketHelper copy
    let helper = new MarketHelper(0);
    for (let tickIndex of this.props.market.marketHelper.ticks) {
      insert(helper, tickIndex);
    }
    let limits = setTicks(helper, this.state.addTabMinPriceTickIndex, this.state.addTabMaxPriceTickIndex);
    const deadline = (Math.round(new Date().getTime() / 1000) + Math.round(this.state.transactionTimeout * 60)).toString();
    const currentTick = this.props.market.marketHelper.currentLiquidity > 0 ? 0 : this.state.currentTick;
    openMarketMakerPosition(
      this.props.web3,
      this.props.web3Account,
      this.props.lpManager,
      this.props.market.marketId,
      limits.lowerOld,
      limits.lower,
      limits.upperOld,
      limits.upper,
      currentTick, // 0 - don't change price
      this.state.currentTick, // this is because of text in notification in range, out of range
      toBn(toPreciseString(baseAmount, 18), 18).toString(),
      this.state.addTabCollateralAmountInput,
      deadline,
      this.closeModalOnAction,
      this.props.dispatch
    );
  };

  onRemoveButtonClick = () => {
    const position = this.state.removeTabSelectedPosition;

    // handle special case when user inputs max amount, we have to be careful
    // not to cut the last few digits
    let exactLiquidity = this.state.removeTabLiqAmount;
    if (Math.abs(exactLiquidity - parseFloat(position.balanceString)) < 0.001) {
      console.log("Quantity: ", exactLiquidity, " very close to max, using max quantity: ", position.balanceString);
      exactLiquidity = position.balanceString;
    }

    const deadline = (Math.round(new Date().getTime() / 1000) + Math.round(this.state.transactionTimeout * 60)).toString();
    let marketBorrowFactor = this.props.positionsBorrowFactors.get(this.props.market.marketId + true + position.positionId);
    closeMarketMakerPosition(
      this.props.web3,
      this.props.web3Account,
      this.props.lpManager,
      this.props.market.marketId,
      position.positionId,
      this.state.addTabLiqAmountInput,
      position.rawBalance,
      marketBorrowFactor,
      deadline,
      this.closeModalOnAction,
      this.props.dispatch
    );
  };

  onMinPriceMinusButtonClick = e => {
    e.stopPropagation();
    // get current price and tick
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    const currentPrice = this.state.addTabMinPriceInVolAmount;
    const currentTick = getTickAtPrice(toSqrtPrice(currentPrice));
    const currentValid = getNearestValidEvenTick(parseInt(currentTick), TICK_SPACING);

    // limit to min 1% volatility
    if (currentValid >= 0) {
      // get new price at one step below
      const newValidTick = getNearestValidEvenTick(parseInt(currentValid) - TICK_SPACING - 1, TICK_SPACING);
      const newPriceInVol = 1.0001 ** newValidTick;
      const newPriceInBase = getOptionPrice(
        this.props.selectedTokenPair.riskFreeRate / 100,
        this.props.market.expirationTime,
        this.props.market.normalizedStrikePrice,
        this.props.market.isCall,
        underPrice,
        newPriceInVol
      );
      // set state and input
      this.setState(
        {
          addTabMinPriceInVolAmount: newPriceInVol,
          addTabMinPriceInBaseAmount: newPriceInBase,
          addTabMinPriceTickIndex: newValidTick
        },
        () => this.validateInputs()
      );
      const decimals = getRangeDecimals(newPriceInVol);
      this.addMinPriceInputRef.current.value = newPriceInVol.toFixed(decimals);
    }
  };

  onMinPricePlusButtonClick = e => {
    e.stopPropagation();
    // get current price and tick
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    const currentPrice = this.state.addTabMinPriceInVolAmount;
    const currentTick = getTickAtPrice(toSqrtPrice(currentPrice));
    const currentValid = getNearestValidEvenTick(parseInt(currentTick), TICK_SPACING);

    // get new price at one step above
    const newValidTick = getNearestValidEvenTick(parseInt(currentValid) + TICK_SPACING + 1, TICK_SPACING);
    const newPriceInVol = 1.0001 ** newValidTick;
    const newPriceInBase = getOptionPrice(
      this.props.selectedTokenPair.riskFreeRate / 100,
      this.props.market.expirationTime,
      this.props.market.normalizedStrikePrice,
      this.props.market.isCall,
      underPrice,
      newPriceInVol
    );

    // set state and input
    if (newPriceInVol < this.state.addTabMaxPriceInVolAmount) {
      this.setState(
        {
          addTabMinPriceInVolAmount: newPriceInVol,
          addTabMinPriceInBaseAmount: newPriceInBase,
          addTabMinPriceTickIndex: newValidTick
        },
        () => this.validateInputs()
      );
      const decimals = getRangeDecimals(newPriceInVol);
      this.addMinPriceInputRef.current.value = newPriceInVol.toFixed(decimals);
    }
  };

  onMaxPriceMinusButtonClick = e => {
    e.stopPropagation();
    // get current price and tick
    const currentTick = this.state.addTabMaxPriceTickIndex;
    const currentPrice = this.state.addTabMaxPriceInVolAmount;

    // get new price at one step below
    const newTick = currentTick - 2 * TICK_SPACING;
    const newPriceInVol = 1.0001 ** newTick;
    const newPriceInBase = getOptionPrice(
      this.props.selectedTokenPair.riskFreeRate / 100,
      this.props.market.expirationTime,
      this.props.market.normalizedStrikePrice,
      this.props.market.isCall,
      parseFloat(this.props.underlyingTokenPrice.normalized),
      newPriceInVol
    );

    // set state and input
    if (newPriceInVol > this.state.addTabMinPriceInVolAmount) {
      this.setState(
        {
          addTabMaxPriceInVolAmount: newPriceInVol,
          addTabMaxPriceInBaseAmount: newPriceInBase,
          addTabMaxPriceTickIndex: newTick
        },
        () => this.validateInputs()
      );
      const decimals = getRangeDecimals(newPriceInVol);
      this.addMaxPriceInputRef.current.value = newPriceInVol.toFixed(decimals);
    }
  };

  onMaxPricePlusButtonClick = e => {
    e.stopPropagation();
    // get current price and tick
    const currentTick = this.state.addTabMaxPriceTickIndex;

    // limit to max 1000.7% volatility
    if (currentTick < 69088) {
      // get new price at one step above
      const newTick = currentTick + 2 * TICK_SPACING;
      const newPriceInVol = 1.0001 ** newTick;
      const newPriceInBase = getOptionPrice(
        this.props.selectedTokenPair.riskFreeRate / 100,
        this.props.market.expirationTime,
        this.props.market.normalizedStrikePrice,
        this.props.market.isCall,
        parseFloat(this.props.underlyingTokenPrice.normalized),
        newPriceInVol
      );

      // set state and input
      this.setState(
        {
          addTabMaxPriceInVolAmount: newPriceInVol,
          addTabMaxPriceInBaseAmount: newPriceInBase,
          addTabMaxPriceTickIndex: newTick
        },
        () => this.validateInputs()
      );
      const decimals = getRangeDecimals(newPriceInVol);
      this.addMaxPriceInputRef.current.value = newPriceInVol.toFixed(decimals);
    }
  };

  onMinPriceInputBlur = () => {
    // get current price and tick
    const currentPriceStr = this.addMinPriceInputRef.current.value;
    if (!isPositiveNumber(currentPriceStr)) {
      return;
    }
    const addMaxPrice = parseFloat(this.addMaxPriceInputRef.current.value);
    let currentPrice = parseFloat(currentPriceStr);
    if (currentPrice > addMaxPrice) {
      currentPrice = addMaxPrice - 10;
    }

    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    const currentTick = getTickAtPrice(toSqrtPrice(currentPrice));
    const currentValid = getNearestValidEvenTick(parseInt(currentTick), TICK_SPACING);

    // get new price at nearest valid tick
    const newPriceInVol = 1.0001 ** currentValid;
    const newPriceInBase = getOptionPrice(
      this.props.selectedTokenPair.riskFreeRate / 100,
      this.props.market.expirationTime,
      this.props.market.normalizedStrikePrice,
      this.props.market.isCall,
      underPrice,
      newPriceInVol
    );

    // set state and input
    if (newPriceInVol < this.state.addTabMaxPriceInVolAmount) {
      this.setState(
        {
          addTabMinPriceInVolAmount: newPriceInVol,
          addTabMinPriceInBaseAmount: newPriceInBase,
          addTabMinPriceTickIndex: currentValid
        },
        () => this.validateInputs()
      );

      const decimals = getRangeDecimals(newPriceInVol);
      this.addMinPriceInputRef.current.value = newPriceInVol.toFixed(decimals);
    }
  };

  onMinPriceInputBlurDebounced = debounce(() => {
    this.addMinPriceInputRef.current.blur();
  }, 1000);

  onCurrentIvPriceMinusButtonClick = e => {
    e.stopPropagation();
    const currentValue = parseFloat(this.currentIvInputRef.current.value);

    const newValue = currentValue - 0.1;
    this.currentIvInputRef.current.value = newValue.toFixed(1);
    this.onCurrentIvPriceInputBlur();
  };

  onCurrentIvPricePlusButtonClick = e => {
    e.stopPropagation();
    const currentValue = parseFloat(this.currentIvInputRef.current.value);
    const newValue = currentValue + 0.1;
    this.currentIvInputRef.current.value = newValue.toFixed(1);
    this.onCurrentIvPriceInputBlur();
  };

  onCurrentIvPriceInputBlur = async () => {
    let inputValue = this.currentIvInputRef.current.value.trim();

    // handle special case when input is empty
    if (inputValue === "") {
      const defaultVolatility = await this.getDefaultVolatility(this.props.market.marketHelper);

      this.setState(
        {
          currentTick: parseInt(logBase(1.0001, defaultVolatility)),
          selectedVolatility: Math.round(defaultVolatility)
        },
        () => this.validateInputs()
      );
      this.currentIvInputRef.current.value = defaultVolatility.toFixed(1); //69088

      return;
    }

    let inputNumber = Number(inputValue);

    // handle special case when market is empty
    if (isMarketEmpty(this.props.market.marketHelper)) {
      const limitedInputNumber = Math.min(1000.7, Math.max(1, inputNumber));

      this.setState(
        {
          currentTick: parseInt(logBase(1.0001, limitedInputNumber)),
          selectedVolatility: Math.round(limitedInputNumber)
        },
        () => this.validateInputs()
      );
      this.currentIvInputRef.current.value = limitedInputNumber.toFixed(1); //69088

      return;
    }

    // market not empty, we need to find boundaries
    let leftVolatility = getVolatilityWhenSellingOptions(this.props.market.marketHelper);
    let rightVolatility = getVolatilityWhenBuyingOptions(this.props.market.marketHelper);

    const limitedInputNumber = Math.min(parseFloat(rightVolatility.toFixed(1)) - 0.1, Math.max(parseFloat(leftVolatility.toFixed(1)) + 0.1, inputNumber));
    this.setState(
      {
        currentTick: parseInt(logBase(1.0001, limitedInputNumber)),
        selectedVolatility: Math.round(limitedInputNumber)
      },
      () => this.validateInputs()
    );
    this.currentIvInputRef.current.value = limitedInputNumber.toFixed(1); //69088
    return;
  };

  onCurrentIvPriceInputBlurDebounced = debounce(() => {
    this.currentIvInputRef.current.blur();
  }, 1000);

  onMaxPriceInputBlur = () => {
    // get current price and tick
    let currentPriceStr = this.addMaxPriceInputRef.current.value;
    if (!isPositiveNumber(currentPriceStr)) {
      return;
    }

    if (Number(currentPriceStr) > 1000) {
      currentPriceStr = 1000;
    }

    const addMinPrice = parseFloat(this.addMinPriceInputRef.current.value);
    let currentPrice = parseFloat(currentPriceStr);
    if (currentPrice < addMinPrice) {
      currentPrice = addMinPrice + 10;
    }

    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    const currentTick = getTickAtPrice(toSqrtPrice(currentPrice));
    const currentValid = getNearestValidEvenTick(parseInt(currentTick), TICK_SPACING) + TICK_SPACING;

    // get new price at nearest valid tick
    const newPriceInVol = 1.0001 ** currentValid;
    const newPriceInBase = getOptionPrice(
      this.props.selectedTokenPair.riskFreeRate / 100,
      this.props.market.expirationTime,
      this.props.market.normalizedStrikePrice,
      this.props.market.isCall,
      underPrice,
      newPriceInVol
    );
    // set state and input
    if (newPriceInVol > this.state.addTabMinPriceInVolAmount) {
      this.setState(
        {
          addTabMaxPriceInVolAmount: newPriceInVol,
          addTabMaxPriceInBaseAmount: newPriceInBase,
          addTabMaxPriceTickIndex: currentValid
        },
        () => this.validateInputs()
      );

      const decimals = getRangeDecimals(newPriceInVol);
      this.addMaxPriceInputRef.current.value = newPriceInVol.toFixed(decimals);
    }
  };

  onMaxPriceInputBlurDebounced = debounce(() => {
    this.addMaxPriceInputRef.current.blur();
  }, 1000);

  calculateMinAndMaxPriceOnUnderPriceUpdate = async () => {
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);

    // min price
    const newMinPriceInBase = getOptionPrice(
      this.props.selectedTokenPair.riskFreeRate / 100,
      this.props.market.expirationTime,
      this.props.market.normalizedStrikePrice,
      this.props.market.isCall,
      underPrice,
      this.state.addTabMinPriceInVolAmount
    );

    // max price
    const newMaxPriceInBase = getOptionPrice(
      this.props.selectedTokenPair.riskFreeRate / 100,
      this.props.market.expirationTime,
      this.props.market.normalizedStrikePrice,
      this.props.market.isCall,
      underPrice,
      this.state.addTabMaxPriceInVolAmount
    );

    this.setState({
      addTabMinPriceInBaseAmount: newMinPriceInBase,
      addTabMaxPriceInBaseAmount: newMaxPriceInBase
    });
  };

  onCollateralInputChangedDebounced = debounce(value => {
    this.setState({ addTabCollateralAmountInput: value });
    this.validateInputs();
  }, 200);

  onAddTabBaseChangeDebounced = debounce(value => {
    this.setState({ addTabBaseAmountInput: value });
    this.onAddTabBaseChange(null, null, false);
  }, 200);

  onRemoveTabOptionsInRangeChange = () => {
    const position = this.state.removeTabSelectedPosition;
    const sizeInLongsInput = position.sizeInLongs;
    const sizeInLongs = parseFloat(sizeInLongsInput);

    const liqTokensToRemove = (sizeInLongs / position.sizeInLongs) * position.balance;

    const marketBaseAmount = position.balance * position.liqPrice[0];
    const marketLongAmount = position.balance * position.liqPrice[1];
    const marketShortAmount = position.balance * position.liqPrice[2];

    const share = liqTokensToRemove / position.balance;
    const collateralAmount = marketLongAmount * share * (this.props.market.isCall ? 1 : this.props.market.normalizedStrikePrice);
    const baseAmount = marketBaseAmount * share;
    const shortAmount = marketShortAmount * share;

    this.calculateChartData(this.state.selectedUnderPrice, this.state.selectedDaysToExpiry, this.state.selectedVolatility, null, [position]);

    this.setState({
      removeTabBaseAmount: baseAmount,
      removeTabCollateralAmount: collateralAmount,
      removeTabLiqAmount: liqTokensToRemove,
      removeTabShortAmount: shortAmount
    });
  };

  validateInputs = () => {
    if (this.props.tabs === "add") {
      const returnValue = this.onAddTabOptionsInRangeChange(null, false, true);
      this.onAddTabBaseChange(returnValue ? returnValue.baseAmount : null, returnValue ? returnValue.shortAmount : null, false);
    } else {
      this.onRemoveTabOptionsInRangeChange();
    }
  };

  onAddTabOptionsInRangeChange = (collateralAmount, shouldBePrintedDefault, updateInput) => {
    const longInput = !shouldBePrintedDefault ? this.collateralAmountInputRef.current.value.trim() : collateralAmount;
    if (!shouldBePrintedDefault) {
      // is empty
      if (longInput === "") {
        this.setErrorMessage(true, "", true);
        return;
      }

      // is not positive number
      if ((longInput[0] === "0" && longInput[1] !== ".") || !isPositiveNumber(longInput)) {
        this.setErrorMessage(true, "Please enter a positive number.", true);
        return;
      }

      if (longInput.endsWith(".")) {
        this.setState({ addTabCollateralAmountInput: longInput });
        return;
      }
    }

    // get base and long amount
    const isCall = this.props.market.isCall;
    const strikePrice = parseFloat(this.props.market.normalizedStrikePrice);
    let longAmount = parseFloat(longInput);
    const marketData = this.getMarketData(longAmount, true);
    const baseInput = this.baseAmountInputRef.current.value;
    let baseAmount = 0;
    if (isPositiveNumber(baseInput)) {
      baseAmount = parseFloat(baseInput);
    }

    // insufficient funds in lending pool
    const lendingPoolSupplyUnder = this.props.marginPoolData.tokensData[1].normalizedCurrentSupply;
    const borrowedUnder = this.props.marginPoolData.tokensData[1].normalizedBorrowedAmount;
    const maxLendAmountUnder = lendingPoolSupplyUnder - borrowedUnder;
    const lendingPoolSupplyBase = this.props.marginPoolData.tokensData[0].normalizedCurrentSupply;
    const borrowedBase = this.props.marginPoolData.tokensData[0].normalizedBorrowedAmount;
    const maxLendAmountBase = lendingPoolSupplyBase - borrowedBase;

    if (
      !shouldBePrintedDefault &&
      ((isCall && marketData.collateralAmount > maxLendAmountUnder) || (!isCall && marketData.collateralAmount > maxLendAmountBase))
    ) {
      this.setErrorMessage(true, "Insufficient funds in lending pool. Click max button to add maximum allowed amount.", true);
      return;
    }

    // insufficient health
    const longsSelling = isCall ? marketData.collateralAmount : marketData.collateralAmount / strikePrice;
    const health = getHealth(0, this.props.market, this.props.underlyingTokenPrice, this.props.marginAccountData);
    const newHealth = getHealth(longsSelling, this.props.market, this.props.underlyingTokenPrice, this.props.marginAccountData);

    const liquidationThreshold = this.props.market.liquidationThreshold;
    // if in green, allow all trades that don't get account in yellow or below
    if (!shouldBePrintedDefault && health >= liquidationThreshold) {
      if (newHealth < liquidationThreshold) {
        this.setErrorMessage(true, "Account health will be too low. Click max button to add maximum allowed amount.", true);
        return;
      }
    }

    // if in yellow, allow all trades that increase health
    if (!shouldBePrintedDefault && health < liquidationThreshold) {
      if (newHealth < health) {
        this.setErrorMessage(true, "Only actions that increase health are allowed. Click max button to add maximum allowed amount.", true);
        return;
      }
    }

    // 1 option on buy and sell side
    const isPriceInRange = this.props.market.marketHelper.currentLiquidity > 0;
    const sellLongAmount = marketData.collateralAmount / (this.props.market.isCall ? 1 : strikePrice);
    const buyLongAmount = longAmount - sellLongAmount;
    const currentIvIsBetween = this.state.currentTick >= this.state.addTabMinPriceTickIndex && this.state.currentTick <= this.state.addTabMaxPriceTickIndex;
    if (!isPriceInRange && currentIvIsBetween && !shouldBePrintedDefault && (buyLongAmount < 1 || sellLongAmount < 1)) {
      this.setErrorMessage(true, "You need at least 1 option buying and selling in selected range. Update range or increase Options in range.", true);
      return;
    }

    // everything valid
    const position = this.getPreviewPosition(longAmount, marketData.requiredBase, marketData.shortAmount, marketData.collateralAmount, marketData.liquidity);
    const requiredBaseCeil = Math.ceil(marketData.requiredBase * this.state.requiredCashMultiplier); // NOTE: 50% more cash than minimum
    // calculation for apr
    const lendingPoolSupply = this.props.marginPoolData.tokensData.find(data => data.symbol === (isCall ? "ETH" : "USD")).normalizedCurrentSupply;
    const borrowedAmount = this.props.marginPoolData.tokensData.find(data => data.symbol === (isCall ? "ETH" : "USD")).normalizedBorrowedAmount;
    const borrowAmount = marketData.collateralAmount;
    const newAPR = getAPR(lendingPoolSupply, borrowedAmount, borrowAmount, "BORROW", this.props.dispatch).normalizedAPR * 100;

    if (!shouldBePrintedDefault) {
      this.setState({
        addTabLongAmount: longAmount,
        addTabCollateralAmount: marketData.collateralAmount,
        addTabCollateralAmountInput: longInput,
        addTabCollateralAmountInputClass: "has-success",
        addTabCollateralErrMessage: "",
        addTabBaseAmount: requiredBaseCeil,
        addTabLiqAmount: marketData.liquidity,
        addTabShortAmount: marketData.shortAmount,
        addTabLeverage: marketData.leverage,
        addTabHealth: newHealth * 100,
        addTabAPR: newAPR,
        addTabPreviewPosition: position,

        addTabObjectValues: null
      });
      if (updateInput) {
        this.setState({ addTabBaseAmountInput: requiredBaseCeil });
        this.baseAmountInputRef.current.value = requiredBaseCeil;
      }
    }

    if (shouldBePrintedDefault) {
      return {
        addTabCollateralAmount: marketData.collateralAmount,
        addTabShortAmount: marketData.shortAmount,
        addTabLiqAmount: marketData.liquidity,
        addTabBaseAmount: requiredBaseCeil,
        addTabLongAmount: longAmount,
        addTabLeverage: marketData.leverage,
        addTabHealth: newHealth * 100,
        addTabAPR: newAPR,
        addTabPreviewPosition: position
      };
    }

    return { baseAmount: requiredBaseCeil, shortAmount: marketData.shortAmount };
  };

  onAddTabBaseChange = (defaultBaseAmount, defaultShortAmount, shouldBePrintedDefault) => {
    let baseInput = defaultBaseAmount;
    if (!shouldBePrintedDefault) {
      if (!baseInput) {
        baseInput = this.baseAmountInputRef.current.value;
      }

      // is empty
      if (baseInput === "") {
        this.setErrorMessage(true, "", false);
        return;
      }

      // is not positive number
      if ((baseInput[0] === "0" && baseInput[1] !== ".") || !isPositiveNumber(baseInput)) {
        this.setErrorMessage(true, "Please enter a positive number.", false);
        return;
      }
    }

    // get sizeInLongs, base and short amount
    // todo: ??? use more precise (check state, and if addTabShortAmount.toFixed(2) === shortInput, then use addTabShortAmount)
    const baseAmount = parseFloat(baseInput);
    const longInput = !shouldBePrintedDefault ? this.collateralAmountInputRef.current.value : null;
    let sizeInLongs = 0;
    if (isPositiveNumber(longInput)) {
      sizeInLongs = parseFloat(longInput);
    }

    // insufficient funds in margin account (limited by base balance or excess liquidity)
    // todo: v1 shouldn't use excess liquidity, should use excess liquidity with new debt, and there is no function for that
    const excessLiquidity = this.props.marginAccountData.normalizedExcessLiquidity;
    const baseBalance = parseFloat(this.props.selectedBaseTokenBalances.normalizedAccountBalance);
    const maxBase = Math.min(baseBalance, excessLiquidity);
    const marketData = this.getMarketData(sizeInLongs, true);

    if (!shouldBePrintedDefault && baseAmount > maxBase) {
      this.setErrorMessage(true, "Insufficient funds. Click max button to add maximum allowed amount.", false);
      return;
    }

    // base input less than required base
    if (!shouldBePrintedDefault && marketData.requiredBase > baseAmount) {
      const requiredBaseCeil = Math.ceil(marketData.requiredBase);
      this.setErrorMessage(
        true,
        "Minimum deposit amount is " +
          requiredBaseCeil.toFixed(0) +
          " " +
          this.props.selectedTokenPair.baseTokenSymbol +
          ". Click max button to add maximum allowed amount.",
        false
      );
      return;
    }
    // everything valid
    const shortAmount = defaultShortAmount ? defaultShortAmount : marketData.shortAmount;
    const position = this.getPreviewPosition(sizeInLongs, baseAmount, shortAmount, marketData.collateralAmount, marketData.liquidity);
    if (!shouldBePrintedDefault) {
      this.calculateChartData(this.state.selectedUnderPrice, this.state.selectedDaysToExpiry, this.state.selectedVolatility, null, [position]);

      this.setState({
        addTabBaseAmount: baseAmount,
        addTabBaseAmountInputClass: "has-success",
        addTabBaseErrMessage: "",
        addTabPreviewPosition: position
      });
    }

    return { addTabBaseAmount: baseAmount, addTabPreviewPosition: position };
  };

  setErrorMessage = (isAdd, errorMessage, isCollateral) => {
    if (isAdd) {
      if (isCollateral) {
        this.setState({
          addTabCollateralAmountInputClass: errorMessage.length > 0 ? "has-danger" : "",
          addTabCollateralErrMessage: errorMessage,
          addTabPreviewPosition: null,
          chartData: this.getEmptyChartData(),
          addTabCollateralBorrowed: false,
          addTabBaseAmountInput: ""
        });
      } else {
        this.setState({
          addTabBaseAmountInputClass: errorMessage.length > 0 ? "has-danger" : "",
          addTabBaseErrMessage: errorMessage,
          addTabPreviewPosition: null,
          chartData: this.getEmptyChartData()
        });
      }
    }
  };

  getMarketData = (sizeInLongs, includePenalty) => {
    const strikePrice = parseFloat(this.props.market.normalizedStrikePrice);
    const lowerSqrtPrice = toSqrtPrice(1.0001 ** this.state.addTabMinPriceTickIndex).toString();
    const upperSqrtPrice = toSqrtPrice(1.0001 ** this.state.addTabMaxPriceTickIndex).toString();
    const currentPrice = toSqrtPrice(getVolatility(this.props.market, this.state.currentTick)).toString();
    const sizeInLongsBN = this.props.web3.utils.toWei(toPreciseString(sizeInLongs));
    const underPriceBN = this.props.underlyingTokenPrice.raw;
    const underPrice = underPriceBN / 10 ** 18;
    const rate = this.props.selectedTokenPair.riskFreeRate / 100;

    const market = this.props.market;
    const liquidityBN = getLiquidityForLongs(lowerSqrtPrice, upperSqrtPrice, sizeInLongsBN);
    const actualLongsBN = getLongsForLiquidity(lowerSqrtPrice, upperSqrtPrice, currentPrice, liquidityBN);
    let requiredBaseJS = getRequiredBase(lowerSqrtPrice, upperSqrtPrice, currentPrice, liquidityBN.toString(), market, underPrice, rate);
    const liquidity = liquidityBN / 10 ** 18;
    const actualLongs = actualLongsBN / 10 ** 18;
    const requiredBase = requiredBaseJS * 1.02; // todo: was 1.05, this makes sure that rounding errors don't happen

    const collateralAmount = this.props.market.isCall ? actualLongs : actualLongs * strikePrice;
    const shortAmount = actualLongs;

    // leverage calc, max range is 1% - 1000.7%, ticks 0 and 69088
    const lowerSqrtPriceMaxRange = toSqrtPrice(1.0001 ** 0).toString();
    const upperSqrtPriceMaxRange = toSqrtPrice(1.0001 ** 69088).toString();
    const liquidityFullRangeBN = getLiquidityForLongs(lowerSqrtPriceMaxRange, upperSqrtPriceMaxRange, sizeInLongsBN);
    const liquidityFullRange = liquidityFullRangeBN / 10 ** 18;
    const leverage = liquidity / liquidityFullRange;

    return { collateralAmount, requiredBase, liquidity, shortAmount, leverage };
  };

  hasInsufficientLiqFunds = value => {
    const position = this.state.removeTabSelectedPosition;

    if (position) {
      // handle special case when user inputs max amount, we have to be careful
      // not to cut the last few digits
      if (Math.abs(value - parseFloat(position.balanceString)) < 0.001) {
        return false;
      }

      return parseFloat(value) > parseFloat(position.balance);
    }

    return true;
  };

  getMaxOptionsInRangeInput = () => {
    // insufficient funds in lending pool
    const maxLendAmount = getMaxLendAmount(this.props.market, this.props.marginPoolData);
    console.log("Max longs to borrow from Lending pool:", maxLendAmount);
    const maxLendMaxAmount = getOptionsInRangeFromLongsOnMarket(
      this.props.market,
      this.state.currentTick,
      maxLendAmount,
      this.state.addTabMinPriceTickIndex,
      this.state.addTabMaxPriceTickIndex,
      this.props.web3
    );
    console.log("maxLendMaxAmount", maxLendMaxAmount);

    // insufficient funds in margin account (limited by base balance or excess liquidity)
    const excessLiquidity = this.props.marginAccountData.normalizedExcessLiquidity;
    const baseBalance = this.props.selectedBaseTokenBalances.normalizedAccountBalance;
    const maxBase = Math.min(baseBalance, excessLiquidity);
    let inputAmount = maxLendMaxAmount / 2;
    let step = maxLendMaxAmount / 2;
    for (let j = 0; j < 20; j++) {
      const marketData = this.getMarketData(inputAmount, true);
      const required = marketData.requiredBase * this.state.requiredCashMultiplier;

      // check if close enough
      if (required < maxBase && maxBase - required < 0.1) {
        break;
      }

      step = step / 2;
      inputAmount += step * (required > maxBase ? -1 : 1);
    }

    const maxBalanceMaxAmount = inputAmount;

    // insufficient health
    inputAmount = maxLendMaxAmount / 2;
    step = maxLendMaxAmount / 2;
    const liquidationThreshold = this.props.market.liquidationThreshold;
    for (let j = 0; j < 40; j++) {
      const newHealth = getHealth(inputAmount, this.props.market, this.props.underlyingTokenPrice, this.props.marginAccountData);

      // check if close enough
      if (newHealth > liquidationThreshold && newHealth - liquidationThreshold < 0.0001) {
        break;
      }

      step = step / 2;
      inputAmount += step * (newHealth < liquidationThreshold ? -1 : 1);
    }
    const minHealthMaxAmount = getOptionsInRangeFromLongsOnMarket(
      this.props.market,
      this.state.currentTick,
      inputAmount,
      this.state.addTabMinPriceTickIndex,
      this.state.addTabMaxPriceTickIndex,
      this.props.web3
    );

    // use smallest amount for input
    const finalLongAmount = Math.floor(Math.min(maxLendMaxAmount, maxBalanceMaxAmount, minHealthMaxAmount));

    return toPreciseString(finalLongAmount, 18);
  };

  findMinimumInputForAtLeastOneBuyOneSell = () => {
    const maxLendAmount = getMaxLendAmount(this.props.market, this.props.marginPoolData);
    const maxLendMaxAmount = getOptionsInRangeFromLongsOnMarket(
      this.props.market,
      this.state.currentTick,
      maxLendAmount,
      this.state.addTabMinPriceTickIndex,
      this.state.addTabMaxPriceTickIndex,
      this.props.web3
    );

    let inputAmount = maxLendMaxAmount / 2;
    let prevInputAmount = inputAmount;
    let step = maxLendMaxAmount / 2;

    const strikePrice = this.props.market.normalizedStrikePrice;
    let buyLongAmount, sellLongAmount, marketData;
    for (let j = 0; j < 40; j++) {
      marketData = this.getMarketData(inputAmount, true);
      sellLongAmount = marketData.collateralAmount / (this.props.market.isCall ? 1 : strikePrice);
      buyLongAmount = inputAmount - sellLongAmount;

      if (buyLongAmount < 1 || sellLongAmount < 1) {
        break;
      }

      step = step / 2;

      prevInputAmount = inputAmount;
      inputAmount = inputAmount - step;
    }
    let appropriateInputAmount = parseInt(prevInputAmount);
    if (prevInputAmount - inputAmount > 1) {
      appropriateInputAmount = appropriateInputAmount + 1;
    }
    marketData = this.getMarketData(appropriateInputAmount, true);
    sellLongAmount = marketData.collateralAmount / (this.props.market.isCall ? 1 : strikePrice);
    buyLongAmount = appropriateInputAmount - sellLongAmount;
    return appropriateInputAmount;
  };

  onMaxAddButtonClick = e => {
    e.stopPropagation();
    let maxOptionsInRangeAmount = this.getMaxOptionsInRangeInput();

    // set state and input value
    this.setState({ addTabLongAmount: maxOptionsInRangeAmount, addTabCollateralAmountInput: maxOptionsInRangeAmount });
    this.collateralAmountInputRef.current.value = maxOptionsInRangeAmount;
    this.validateInputs();
  };

  setMaxRemoveAmount = amount => {
    const element = this.liqAmountInputRef.current;
    if (element !== null) {
      this.setState({
        addTabLiqAmountInput: amount.toFixed(0)
      });
      this.onRemoveTabOptionsInRangeChange();
    }
  };

  onMaxRemoveButtonClick = () => {
    this.liqAmountInputRef.current.value = this.state.removeTabSelectedPosition.sizeInLongs.toFixed(2);
    this.setMaxRemoveAmount(this.state.removeTabSelectedPosition.sizeInLongs);
  };

  displayBigLine = (leftText, rightText, shouldBeDisabled = false) => {
    const textId = getIdFromString(leftText, false);
    const valueId = getIdFromString(leftText, true);
    return (
      <div className={`bigLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
        <span id={this.state.idPrefix + textId}>{leftText}</span>
        <span id={this.state.idPrefix + valueId}>{rightText}</span>
      </div>
    );
  };

  displayLine = (text, value, shouldBeDisabled = false) => {
    const textId = getIdFromString(text, false);
    const valueId = getIdFromString(text, true);
    return (
      <div className={`smallLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
        <span id={this.state.idPrefix + textId}>{text}</span>
        <span id={this.state.idPrefix + valueId}>{value}</span>
      </div>
    );
  };

  displayHealth = (text, value, shouldBeDisabled = false) => {
    const health = value;
    const recoveryThreshold = this.props.marginAccountData ? parseFloat(fromBn(this.props.marginAccountData.recoveryThreshold)) * 100 : 0;
    const liquidationThreshold = this.props.marginAccountData ? parseFloat(fromBn(this.props.marginAccountData.liquidationThreshold)) * 100 : 0;

    const readableHealth = health !== Infinity ? (health < 1000 ? formatValue(health, 1) + "%" : "excellent") : "excellent";
    const healthColorClass = shouldBeDisabled ? "disabledTextColor" : healthClassName(readableHealth, health, recoveryThreshold, liquidationThreshold);

    const textId = getIdFromString(text, false);
    const valueId = getIdFromString(text, true);
    return (
      <div className={`smallLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
        <span id={this.state.idPrefix + textId}>{text}</span>
        <span id={this.state.idPrefix + valueId} className={healthColorClass}>
          {readableHealth}
        </span>
      </div>
    );
  };

  displayAPR = (text, value, shouldBeDisabled = false) => {
    return (
      <div className={`smallLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
        <span>{text}</span>
        <span>{value}</span>
      </div>
    );
  };

  displayAddTransitions = (addPropsState, shouldBePrintedDefault) => {
    const { addTabCollateralAmount, addTabShortAmount, addTabLiqAmount, addTabBaseAmount, addTabLongAmount } = addPropsState;

    if (this.props.market != null && this.props.underlyingTokenPrice != null) {
      const strikePrice = this.props.market.normalizedStrikePrice;

      // receive
      const sellLongAmount = addTabCollateralAmount / (this.props.market.isCall ? 1 : strikePrice);

      // cost
      const costStr = printPriceFromNumber(addTabBaseAmount);

      const isFuture = this.props.market.isFuture;
      const longName = !isFuture ? (this.props.market.isCall ? "calls" : "puts") : "futures";
      const minPriceInVol = this.state.addTabMinPriceInVolAmount.toFixed(getRangeDecimals(this.state.addTabMinPriceInVolAmount));
      const maxPriceInVol = this.state.addTabMaxPriceInVolAmount.toFixed(getRangeDecimals(this.state.addTabMaxPriceInVolAmount));

      let addTabLongAmountForCalc = addTabLongAmount;
      if (this.state.addTabCollateralErrMessage.includes("at least 1 option")) {
        addTabLongAmountForCalc = Number(this.collateralAmountInputRef.current.value);
      }

      const buyLongAmount = addTabLongAmountForCalc - sellLongAmount;
      const currentPriceInVol = parseFloat(getVolatility(this.props.market, this.state.currentTick)).toFixed(
        getRangeDecimals(parseFloat(getVolatility(this.props.market, this.state.currentTick)))
      );

      const liquidity = addTabLiqAmount;
      const poolShare = liquidity / (this.props.market.liquidityInCurrentTick + liquidity);

      // mid range
      let breakdownLine1 =
        "buying " + formatValue(buyLongAmount, 2) + " " + longName + (!isFuture ? " in " + minPriceInVol + "% - " + currentPriceInVol + "%" : "");
      let breakdownLine2 =
        "selling " + formatValue(sellLongAmount, 2) + " " + longName + (!isFuture ? " in " + currentPriceInVol + "% - " + maxPriceInVol + "%" : "");

      if (currentPriceInVol >= this.state.addTabMaxPriceInVolAmount) {
        // below current
        breakdownLine1 = "buying " + buyLongAmount.toFixed(2) + " " + longName + (!isFuture ? " in " + minPriceInVol + "% - " + maxPriceInVol + "%" : "");
        breakdownLine2 = "";
      } else if (currentPriceInVol <= this.state.addTabMinPriceInVolAmount) {
        breakdownLine1 = "selling " + sellLongAmount.toFixed(2) + " " + longName + (!isFuture ? " in " + minPriceInVol + "% - " + maxPriceInVol + "%" : "");
        breakdownLine2 = "";
      }

      return (
        <>
          {this.displayLine("Breakdown", breakdownLine1, shouldBePrintedDefault)}
          {this.displayLine("", breakdownLine2, shouldBePrintedDefault)}
          {this.displayLine("Leverage", formatValue(this.state.addTabLeverage, 0) + "x", shouldBePrintedDefault)}
          {this.displayLine("Pool share", formatValue(poolShare * 100, 2) + "%", shouldBePrintedDefault)}
          {this.displayHealth("Account health", this.state.addTabHealth, shouldBePrintedDefault)}
          {this.displayLine("Borrow rate", formatValue(this.state.addTabAPR, 2) + "%", shouldBePrintedDefault)}
          {this.displayBigLine("Total Cost", costStr, shouldBePrintedDefault)}
          <MyTooltip key={this.state.idPrefix + getIdFromString("Breakdown")} target={this.state.idPrefix + getIdFromString("Breakdown")} optionalOffset={15}>
            Breakdown displays how liquidity is divided to buy and sell side at the time of the LP position opening. <br /> <br />
            When a trader buys or sells {!isFuture ? "options" : "futures"} on this market, the ratio changes.
          </MyTooltip>
          <MyTooltip key={this.state.idPrefix + getIdFromString("Leverage")} target={this.state.idPrefix + getIdFromString("Leverage")} optionalOffset={15}>
            Leverage is the ratio between pool share received by concentrating liquidity to defined range and pool share received by adding the same liquidity
            to full-range. <br /> <br />
            Increase leverage by reducing the range in which your liquidity is traded.
          </MyTooltip>
          <MyTooltip key={this.state.idPrefix + getIdFromString("Pool share")} target={this.state.idPrefix + getIdFromString("Pool share")} optionalOffset={15}>
            Share in the liquidity pool consisted of all LP positions. <br /> <br />
            Pool share can change over time as liquidity is added/removed from pool, or when LP positions fall in/out of range.
          </MyTooltip>
          <MyTooltip
            key={this.state.idPrefix + getIdFromString("Account health")}
            target={this.state.idPrefix + getIdFromString("Account health")}
            optionalOffset={15}
          >
            New account health after opening LP position. <br /> <br />
            Health is the ratio betweeen total account liquidity (including debt) and debt.
          </MyTooltip>
          <MyTooltip
            key={this.state.idPrefix + getIdFromString("Borrow rate")}
            target={this.state.idPrefix + getIdFromString("Borrow rate")}
            optionalOffset={15}
          >
            New annual borrow rate (APR) for the account after opening LP position. <br /> <br />
            Borrow rate can change over time as liquidity is added/removed from Margin pool, or when LPs/traders borrow/repay debt.
          </MyTooltip>
          <MyTooltip key={this.state.idPrefix + getIdFromString("Total Cost")} target={this.state.idPrefix + getIdFromString("Total Cost")} optionalOffset={15}>
            Amount of $ USD removed from account and added to LP position.
          </MyTooltip>
        </>
      );
    }
  };

  displayRemoveTransitions = () => {
    if (this.props.market != null && this.props.underlyingTokenPrice != null) {
      const isCall = this.props.market.isCall;

      // proceeds
      let optionProceedsStr = "";
      let cashProceedsStr = "";
      let totalProceedsStr = "";
      const position = this.state.removeTabSelectedPosition;
      if (position) {
        const optionProceeds = (position.liqPrice[1] - position.liqPrice[2]) * position.balance;
        const optionProceedsValue = optionProceeds * this.props.market.longPrice;
        const isFuture = this.props.market.isFuture;
        optionProceedsStr = (optionProceeds != 0 ? optionProceeds.toFixed(2) : "0") + " " + (!isFuture ? (isCall ? "calls" : "puts") : "futures");

        const cashProceeds = position.liqPrice[0] * position.balance;
        cashProceedsStr = printPriceFromNumber(cashProceeds);

        const totalProceeds = optionProceedsValue + cashProceeds;
        totalProceedsStr = printPriceFromNumber(totalProceeds);
      }

      // range
      const lowerPrice = this.props.needsToBeClosed ? 1.0001 ** this.props.selectedPosition.lower : 1.0001 ** this.state.removeTabSelectedPosition.lower;
      const lowerDecimals = getRangeDecimals(lowerPrice);
      const upperPrice = this.props.needsToBeClosed ? 1.0001 ** this.props.selectedPosition.upper : 1.0001 ** this.state.removeTabSelectedPosition.upper;
      const upperDecimals = getRangeDecimals(upperPrice);

      // volatility
      const pricePerOption = parseFloat(this.props.market.longPrice);
      const iv = formatValue(parseFloat(this.props.market.longPriceInVol), 1);

      return (
        <>
          {this.displayLine("Volatility range", formatValue(lowerPrice, lowerDecimals) + "% - " + formatValue(upperPrice, upperDecimals) + "%")}
          {this.displayLine("Current volatility", iv + "%")}
          {this.displayLine("Option price", printPriceFromNumber(pricePerOption))}
          <br />
          {this.displayLine("Option proceeds", optionProceedsStr)}
          {this.displayLine("Cash proceeds", cashProceedsStr)}
          {this.displayBigLine("Total Proceeds", totalProceedsStr)}
          <MyTooltip
            key={this.state.idPrefix + getIdFromString("Volatility range")}
            target={this.state.idPrefix + getIdFromString("Volatility range")}
            optionalOffset={15}
          >
            Volatility range in which your LP position earns trading fees.
          </MyTooltip>
          <MyTooltip
            key={this.state.idPrefix + getIdFromString("Current volatility")}
            target={this.state.idPrefix + getIdFromString("Current volatility")}
            optionalOffset={15}
          >
            Current implied volatility (IV) on the market.
          </MyTooltip>
          <MyTooltip
            key={this.state.idPrefix + getIdFromString("Option price")}
            target={this.state.idPrefix + getIdFromString("Option price")}
            optionalOffset={15}
          >
            Option price in $ USD.
          </MyTooltip>
          <MyTooltip
            key={this.state.idPrefix + getIdFromString("Option proceeds")}
            target={this.state.idPrefix + getIdFromString("Option proceeds")}
            optionalOffset={15}
          >
            Amount of longs or shorts received to account. <br /> <br />
            New position can be viewed on 'Trade' page.
          </MyTooltip>
          <MyTooltip
            key={this.state.idPrefix + getIdFromString("Cash proceeds")}
            target={this.state.idPrefix + getIdFromString("Cash proceeds")}
            optionalOffset={15}
          >
            Cash reserves removed from LP position and added to account.
          </MyTooltip>
          <MyTooltip
            key={this.state.idPrefix + getIdFromString("Total Proceeds")}
            target={this.state.idPrefix + getIdFromString("Total Proceeds")}
            optionalOffset={15}
          >
            Total Proceeds is the sum of Option proceeds and Cash proceeds, in $ USD. <br /> <br />
          </MyTooltip>
        </>
      );
    }
  };

  toggleTransactionSettingsModal = e => {
    e && e.stopPropagation();
    this.setState({
      isTransactionSettingsModalOpen: !this.state.isTransactionSettingsModalOpen
    });
  };

  setSlippageTolerance = value => {
    this.setState({
      slippageTolerance: value
    });
  };

  setTransactionTimeout = value => {
    this.setState({
      transactionTimeout: value
    });
  };

  handleRangeTokenDropmenuClick = e => {
    e.stopPropagation();
    const { top, left, width } = e.currentTarget.getBoundingClientRect();
    const rangeTokenDropmenuOpened = !this.props.rangeTokenDropmenuOpened;
    this.props.setRangeTokenDropmenuOpened(rangeTokenDropmenuOpened);
    if (rangeTokenDropmenuOpened) {
      this.setState({
        top: top + 43 + "px",
        left: left + "px",
        width: width + "px"
      });
    }
  };

  handleFutureRangeTokenDropmenuClick = e => {
    e.stopPropagation();
    const { top, left, width } = e.currentTarget.getBoundingClientRect();
    const rangeFutureTokenDropmenuOpened = !this.props.rangeFutureTokenDropmenuOpened;
    this.props.setFutureRangeTokenDropmenuOpened(rangeFutureTokenDropmenuOpened);
    if (rangeFutureTokenDropmenuOpened) {
      this.setState({
        top: top + 43 + "px",
        left: left + "px",
        width: width + "px"
      });
    }
  };

  displaySelectedLPPosition = () => {
    if (this.props.needsToBeClosed) {
      return this.props.selectedPosition.shortName;
    }

    if (this.state.removeTabSelectedPosition !== null) {
      return this.state.removeTabSelectedPosition.shortName;
    }

    return "No positions for selected market";
  };

  onLPPositionSelected = position => {
    this.setState({
      removeTabSelectedPosition: position
    });

    this.setMaxRemoveAmount(position.sizeInLongs);
  };

  onFutureRangeSelected = rangeType => {
    this.setState({
      selectedFutureRange: FUTURE_RANGES.find(range => range.type === rangeType)
    });
  };

  getLpPositionsForSelectedMarket = () => {
    if (this.props.openedLPPositions !== null) {
      const positionsForSelectedmarket = this.getPositionsForSelectedMarket();
      return positionsForSelectedmarket;
    }

    return null;
  };

  getPositionsForSelectedMarket = () => {
    return this.props.openedLPPositions.filter(position => {
      return position.marketId === this.props.market.marketId;
    });
  };

  getDaysSinceStart = position => {
    let daysSinceStart = 0;
    const blockchainTimestamp = new Date(this.props.currentTimestamp * 1000);
    if (this.props.tabs === "add") {
      const timeToExpiry = Math.abs(this.props.market.expirationTime * 1000 - blockchainTimestamp);
      const daysToExpiry = timeToExpiry / (1000 * 60 * 60 * 24);
      daysSinceStart = Math.max(0, daysToExpiry - this.state.selectedDaysToExpiry);
    } else {
      let startDate = new Date(parseInt(position.openTime) * 1000);
      daysSinceStart = (blockchainTimestamp - startDate) / (1000 * 60 * 60 * 24);
    }

    return daysSinceStart;
  };

  // if same as in PositionModal, move to common
  displayPnLTable = (shouldBePrintedDefault, chartData) => {
    let position =
      this.props.tabs === "add" && this.state.addTabPreviewPosition
        ? this.state.addTabPreviewPosition
        : this.props.tabs === "remove" && this.state.removeTabSelectedPosition
        ? this.state.removeTabSelectedPosition
        : null;

    if (shouldBePrintedDefault && this.state.addTabObjectValues) {
      position = this.state.addTabObjectValues.addTabPreviewPosition;
    }

    if (position !== null && position !== undefined) {
      // time
      let daysSinceStart = this.getDaysSinceStart(position);

      const underlyingPrice = this.state.selectedUnderPrice;

      let totalProfit = 0;
      let principalProfit = 0;
      let valueOnOpen = 0;
      let valueOnClose = 0;
      let feeProfit = 0;

      if (chartData.series1 && chartData.series1.length > 0) {
        totalProfit = interpolateY(chartData.series1, underlyingPrice);
        valueOnClose = interpolateValue(chartData.series1, underlyingPrice);
      }

      feeProfit += chartData.feesProjected;

      valueOnOpen = valueOnClose - totalProfit;
      principalProfit = totalProfit - feeProfit;

      // ROI
      const principalROI = ((totalProfit - feeProfit) / valueOnOpen) * 100;
      const feeROI = (feeProfit / valueOnOpen) * 100;
      const totalROI = (totalProfit / valueOnOpen) * 100;

      // APY
      let totalAPY = 0;
      let principalAPY = 0;
      let feeAPY = 0;
      if (Math.abs(principalProfit) > 0.000001 && daysSinceStart > 0) {
        const principalAPR = (principalProfit / valueOnOpen / daysSinceStart) * 365 * 100;
        principalAPY = (Math.pow(1 + principalAPR / 100 / 365, 365) - 1) * 100;
      }
      if (Math.abs(feeProfit) > 0.000001 && daysSinceStart > 0) {
        const feeAPR = (feeProfit / valueOnOpen / daysSinceStart) * 365 * 100;
        feeAPY = (Math.pow(1 + feeAPR / 100 / 365, 365) - 1) * 100;
      }
      if (Math.abs(totalProfit) > 0.000001 && daysSinceStart > 0) {
        const totalAPR = (totalProfit / valueOnOpen / daysSinceStart) * 365 * 100;
        totalAPY = (Math.pow(1 + totalAPR / 100 / 365, 365) - 1) * 100;
      }

      const now = this.props.currentTimestamp;
      const timestamp = parseInt(position.timestamp);

      // if trade happened recently, don't show APY
      if (Math.abs(now - timestamp) < 60 * 60) {
        principalAPY = "";
        feeAPY = "";
        totalAPY = "";
      }

      return (
        <div className={`pnlTable${shouldBePrintedDefault ? " disabledTextColor" : ""}`}>
          <table>
            <thead>
              <tr>
                <th className="table-heading" scope="col" />
                <th className="table-heading" scope="col" style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <span id={this.state.idPrefix + "roi"} className="pnlTableCell lpTableTh">
                    ROI
                  </span>
                  <MyTooltip key={this.state.idPrefix + "roi"} target={this.state.idPrefix + "roi"} optionalOffset={15}>
                    Projected Return on investment (ROI) of the LP position using selected Volatity, Time to expiration, Underlying price, and projected trading
                    volume.
                  </MyTooltip>
                </th>
                <th className="table-heading" scope="col" style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <span id={this.state.idPrefix + "apy"} className="pnlTableCell lpTableTh">
                    APY
                  </span>
                  <MyTooltip key={this.state.idPrefix + "apy"} target={this.state.idPrefix + "apy"} optionalOffset={15}>
                    Projected Annual percentage yield (APY) of the LP position using selected Volatity, Time to expiration, Underlying price, and projected
                    trading volume.
                  </MyTooltip>
                </th>
                <th className="table-heading" scope="col" style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <span id={this.state.idPrefix + "pnl"} className="pnlTableCell lpTableTh">
                    P/L
                  </span>
                  <MyTooltip key={this.state.idPrefix + "pnl"} target={this.state.idPrefix + "pnl"} optionalOffset={15}>
                    Projected Profit/Loss (P/L) of the LP position using selected Volatity, Time to expiration, Underlying price, and projected trading volume.
                  </MyTooltip>
                </th>
              </tr>
            </thead>
            <tbody className="table-heading">
              <tr>
                <td>
                  <span id={this.state.idPrefix + "pnl-table-principal"} className="pnlTableRowLabel">
                    Principal
                  </span>
                  <MyTooltip key={this.state.idPrefix + "pnl-table-principal"} target={this.state.idPrefix + "pnl-table-principal"} optionalOffset={15}>
                    Principal is provided liquidity that is traded on the market, and can have profit or loss depending on the market conditions. <br /> <br />
                    There are 3 risks associated with principal:
                    <ul>
                      <li style={{ color: "black" }}>Option risk</li>
                      <li style={{ color: "black" }}>Trading risk</li>
                      <li style={{ color: "black" }}>Impermanent loss</li>
                    </ul>
                    Learn more: <br />
                    <a href="https://gamma-options.gitbook.io/docs/" target="_blank" rel="noreferrer">
                      Docs
                    </a>
                  </MyTooltip>
                </td>
                <td>
                  <span className="pnlTableCell">{limitValue(principalROI, 10000, 2) + "%"}</span>
                </td>
                <td>
                  <span className="pnlTableCell">{principalAPY ? limitValue(principalAPY, 10000, 2) + "%" : ""}</span>
                </td>
                <td>
                  <span className="pnlTableCell">{printPriceFromNumber(principalProfit)}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span id={this.state.idPrefix + "pnl-table-fees-earned"} className="pnlTableRowLabel">
                    Fees Earned - Borrow Rate
                  </span>
                  <MyTooltip key={this.state.idPrefix + "pnl-table-fees-earned"} target={this.state.idPrefix + "pnl-table-fees-earned"} optionalOffset={15}>
                    Fees earned are trading fees earned by providing liquidity to the market. Trading fees are accrued in USD, and can only grow. <br /> <br />
                    Borrow Interest is interest paid on borrowed funds that are used as part of the liquidity. Can change over time, and is always negative.{" "}
                    <br /> <br />
                    Learn more: <br />
                    <a href="https://gamma-options.gitbook.io/docs/" target="_blank" rel="noreferrer">
                      Docs
                    </a>
                  </MyTooltip>
                </td>
                <td>
                  <span className="pnlTableCell">{limitValue(feeROI, 10000, 2) + "%"}</span>
                </td>
                <td>
                  <span className="pnlTableCell">{feeAPY ? limitValue(feeAPY, 10000, 2) + "%" : ""}</span>
                </td>
                <td>
                  <span className="pnlTableCell">{printPriceFromNumber(feeProfit)}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span id={this.state.idPrefix + "pnl-table-total"} className="pnlTableRowLabel">
                    Total
                  </span>
                  <MyTooltip key={this.state.idPrefix + "pnl-table-total"} target={this.state.idPrefix + "pnl-table-total"} optionalOffset={15}>
                    Total is the sum of Principal and Fees earned minus interest paid to borrow. <br /> <br />
                    Learn more: <br />
                    <a href="https://gamma-options.gitbook.io/docs/" target="_blank" rel="noreferrer">
                      Docs
                    </a>
                  </MyTooltip>
                </td>
                <td>
                  <span className="pnlTableCell">{limitValue(totalROI, 10000, 2) + "%"}</span>
                </td>
                <td>
                  <span className="pnlTableCell">{totalAPY ? limitValue(totalAPY, 10000, 2) + "%" : ""}</span>
                </td>
                <td>
                  <span className="pnlTableCell">{printPriceFromNumber(totalProfit)}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  };

  clearInput = e => {
    if (e.target.previousSibling.id.includes("collateral-amount")) {
      this.collateralAmountInputRef.current.value = "";
      this.baseAmountInputRef.current.value = "";
      this.setState({
        addTabCollateralAmount: "",
        addTabCollateralAmountInput: "",
        addTabBaseAmount: "",
        addTabShortAmount: "",
        addTabBaseAmountInputClass: "",
        addTabCollateralAmountInputClass: "",
        addTabBaseErrMessage: "",
        addTabCollateralErrMessage: ""
      });
      e.target.previousSibling.focus();
    }
  };

  volAmountsAreCalculated = () => {
    return this.state.addTabMinPriceInVolAmount > 0 && this.state.addTabMaxPriceInVolAmount > 0;
  };

  increaseOptionsInRangeHandler = e => {
    e.stopPropagation();
    let newLongsInRange = 1;
    if (this.collateralAmountInputRef.current && this.collateralAmountInputRef.current.value) {
      const prevValue = this.collateralAmountInputRef.current.value;
      // step is 1 from 0 - 100 longs, 10 from 100 - 1000 longs, etc.
      const step = Math.max(10 ** (Math.round(prevValue).toString().length - 2), 1);
      newLongsInRange = Math.floor(Number(prevValue / step)) * step + step;
    }

    this.collateralAmountInputRef.current.value = newLongsInRange;

    // set state and input value
    this.increaseDecreaseOptionsInRangeDebounced(newLongsInRange);
  };

  decreaseOptionsInRangeHandler = e => {
    e.stopPropagation();
    if (this.collateralAmountInputRef.current && this.collateralAmountInputRef.current.value && this.collateralAmountInputRef.current.value > 1) {
      const prevValue = this.collateralAmountInputRef.current.value;
      // step is 1 from 0 - 100 input value, 10 from 100 - 1000 input value, etc.
      const step = Math.max(10 ** (Math.round(prevValue).toString().length - 2), 1);
      const newLongsInRange = Math.floor(Number(prevValue / step)) * step - step;

      this.collateralAmountInputRef.current.value = newLongsInRange;

      // set state and input value
      this.increaseDecreaseOptionsInRangeDebounced(newLongsInRange);
    }
  };

  increaseDecreaseOptionsInRangeDebounced = debounce(newLongsInRange => {
    this.setState({ addTabLongAmount: newLongsInRange, addTabCollateralAmountInput: newLongsInRange });
    this.validateInputs();
  }, 200);

  increaseUsdReservesHandler = e => {
    e.stopPropagation();
    // set base amount to be minimum
    const longInput = this.collateralAmountInputRef.current.value;
    const marketData = this.getMarketData(Number(longInput), false);
    let newBaseAmount = Math.ceil(marketData.requiredBase * 100) / 100;

    // if there is a value in the input, round up to the next step size
    const input = this.baseAmountInputRef.current;
    const prevValue = input.value;
    let baseAmountShortAmountObj = null;
    if (input.value) {
      // step is 1 from 0 - 100 input value, 10 from 100 - 1000 input value, etc.
      const step = Math.max(10 ** (Math.round(prevValue).toString().length - 2), 1);
      newBaseAmount = Math.floor(Number(prevValue / step)) * step + step;
    } else {
      baseAmountShortAmountObj = {
        baseAmount: Math.ceil(marketData.requiredBase),
        shortAmount: marketData.shortAmount
      };
      newBaseAmount = baseAmountShortAmountObj.baseAmount;
    }

    input.value = newBaseAmount;
    this.increaseDecreaseUsdReservesDebounced(newBaseAmount, baseAmountShortAmountObj);
  };

  decreaseUsdReservesHandler = e => {
    e.stopPropagation();
    // get required amount
    const longInput = this.collateralAmountInputRef.current.value;
    const marketData = this.getMarketData(Number(longInput), false);
    const requiredAmount = Math.ceil(marketData.requiredBase * 100) / 100;

    // if there is a value in the input, round up to the next step size
    const input = this.baseAmountInputRef.current;
    const prevValue = input.value;
    if (prevValue && prevValue > requiredAmount) {
      // step is 1 from 0 - 100 input value, 10 from 100 - 1000 input value, etc.
      const step = Math.max(10 ** (Math.round(prevValue).toString().length - 2), 1);
      let newBaseAmount = Math.floor(Number(prevValue / step)) * step - step;
      // if new base amount is less than required amount, set to required amount
      if (newBaseAmount < requiredAmount) {
        newBaseAmount = requiredAmount;
      }

      input.value = newBaseAmount;
      this.increaseDecreaseUsdReservesDebounced(newBaseAmount);
    }
  };

  increaseDecreaseUsdReservesDebounced = debounce((newBaseAmount, baseAmountShortAmountObj) => {
    baseAmountShortAmountObj && this.onAddTabOptionsInRangeChange(null, false, true);
    this.setState({ addTabBaseAmount: newBaseAmount, addTabBaseAmountInput: newBaseAmount });
    this.onAddTabBaseChange(
      baseAmountShortAmountObj ? baseAmountShortAmountObj.baseAmount : null,
      baseAmountShortAmountObj ? baseAmountShortAmountObj.shortAmount : null,
      false
    );
  }, 200);

  selectXCallback = x => {
    if (x !== this.state.selectedUnderPrice) {
      this.setState({
        selectedUnderPrice: x
      });
    }
  };

  deselectXCallback = () => {
    const position = this.props.tabs === "add" ? this.state.addTabPreviewPosition : this.state.removeTabSelectedPosition;
    if (position) {
      this.setState({
        selectedUnderPrice: parseFloat(this.props.underlyingTokenPrice.normalized)
      });
    }
  };

  onAllNewSwitchChange = () => {
    if (document.getElementById(this.state.idPrefix + "buy-amount")) {
      document.getElementById(this.state.idPrefix + "buy-amount").focus();
    } else if (document.getElementById(this.state.idPrefix + "sell-amount")) {
      document.getElementById(this.state.idPrefix + "sell-amount").focus();
    }
  };

  onStandardDeviationChange = newDeviation => {
    const position = this.props.tabs === "add" ? this.state.addTabPreviewPosition : this.state.removeTabSelectedPosition;
    this.calculateChartData(this.state.selectedUnderPrice, this.state.selectedDaysToExpiry, this.state.selectedVolatility, newDeviation, [position]);
  };

  toggleSetCogChartOptionsOpen = e => {
    e.stopPropagation();
    this.props.setCogChartOptionsOpenAdd(!this.props.cogChartOptionsOpen);
  };

  render() {
    const shouldBePrintedDefaultForCollateral = this.state.addTabCollateralAmountInput === "" || this.state.addTabCollateralErrMessage.trim() !== "";
    const shouldBePrintedDefaultForBase = this.state.addTabBaseAmountInput === "" || this.state.addTabBaseErrMessage.trim() !== "";
    const shouldBePrintedDefault = shouldBePrintedDefaultForCollateral || shouldBePrintedDefaultForBase;

    let collateralPlaceholder = "Please enter number";
    let basePlaceholder = "Please enter number";

    const volsAreCalculated = this.volAmountsAreCalculated();

    const daysToExpiry = getHighestDaysToExpiryRounded([{ marketId: this.props.market.marketId }], [this.props.market]);
    const markets = this.getMarkets();

    const addTabPropsState = shouldBePrintedDefault && this.state.addTabObjectValues ? this.state.addTabObjectValues : this.state;

    const addTransitionsContent = this.displayAddTransitions(addTabPropsState, shouldBePrintedDefault);

    const addTabPreviewPosition =
      shouldBePrintedDefault && this.state.addTabObjectValues ? this.state.addTabObjectValues.addTabPreviewPosition : this.state.addTabPreviewPosition;

    const marketIsExpired = this.props.market.isSettled || Number(this.props.market.expirationTime) < this.props.web3Object.currentTimestamp;
    let selectedUnderPrice = this.state.selectedUnderPrice;
    if (marketIsExpired) {
      const settlement = this.props.settlements.find(s => s.expirationTime === this.props.market.expirationTime);
      if (settlement) {
        selectedUnderPrice = parseFloat(formatUnits(settlement.actualUnderlyingPrice.toString(), 18));
      }
    }

    const slidersShouldBeDisabled = shouldBePrintedDefault || marketIsExpired;

    let selectedDaysToExpiry = marketIsExpired ? 0 : this.state.selectedDaysToExpiry;

    const chartData =
      shouldBePrintedDefault && this.state.addTabObjectValues
        ? this.calculateChartData(selectedUnderPrice, selectedDaysToExpiry, this.state.selectedVolatility, null, [addTabPreviewPosition], false)
        : this.state.chartData;

    // get greeks from chart data
    const greeks = interpolateGreeks(chartData.series1, selectedUnderPrice);
    const delta = formatValue(greeks.delta, 2);
    const gamma = formatValue(greeks.gamma, 2);
    const theta = formatValue(greeks.theta, 2);
    const vega = formatValue(greeks.vega, 2);

    const isPriceInRange = this.props.market.marketHelper.currentLiquidity > 0;

    const isFuture = this.props.market.isFuture;

    return (
      <>
        {this.props.rangeTokenDropmenuOpened && (
          <TokenSelect
            selectToken={this.onLPPositionSelected}
            positionsForSelectedmarket={this.getLpPositionsForSelectedMarket()}
            top={this.state.top}
            left={this.state.left}
            width={this.state.width}
          />
        )}
        {this.props.rangeFutureTokenDropmenuOpened && (
          <FutureRangeSelect
            selectedFutureRange={this.state.selectedFutureRange}
            selectRange={this.onFutureRangeSelected}
            futureRanges={FUTURE_RANGES}
            top={this.state.top}
            left={this.state.left}
            width={this.state.width}
          />
        )}

        <div
          className="modalContent"
          onClick={e => {
            e.stopPropagation();
            this.props.setCogChartOptionsOpenAdd(false);
            if (this.props.tabs === "remove") {
              this.props.setRangeTokenDropmenuOpened(false);
            }
          }}
        >
          {this.state.isTransactionSettingsModalOpen && (
            <TransactionSettingsModal
              isOpen={this.state.isTransactionSettingsModalOpen}
              toggleTransactionSettingsModal={this.toggleTransactionSettingsModal}
              slippageTolerance={this.state.slippageTolerance}
              setSlippageTolerance={this.setSlippageTolerance}
              transactionTimeout={this.state.transactionTimeout}
              setTransactionTimeout={this.setTransactionTimeout}
            />
          )}

          {this.props.tabs === "add" ? (
            <div className="addFormContainer">
              <div className="minMaxDepositContainer">
                {!isFuture ? (
                  <div className="minMaxPriceContainer">
                    <div className="priceRangeContainer">
                      <span id="minIV">Min IV (%)</span>
                      <MyTooltip key="minIV" target="minIV" optionalOffset={15}>
                        Minimum implied volatility (IV) is lower boundary of IV range in which your liquidity is traded on the market. <br /> <br />
                        If IV moves below Min IV, your liquidity is no longer traded, and your LP position no longer earns trading fees.
                      </MyTooltip>
                      <div>
                        <button id={this.state.idPrefix + "add-min-price-minus"} onClick={this.onMinPriceMinusButtonClick} disabled={!volsAreCalculated}>
                          <img className="arrowDownCircle" />
                        </button>
                        <MyTooltip key={this.state.idPrefix + "add-min-price-minus"} target={this.state.idPrefix + "add-min-price-minus"} optionalOffset={15}>
                          Decrease
                        </MyTooltip>
                        <input
                          ref={this.addMinPriceInputRef}
                          id={this.state.idPrefix + "add-min-price-input"}
                          type="text"
                          autoComplete="off"
                          onBlur={this.onMinPriceInputBlur}
                          onChange={e => {
                            let value = e.target.value.trim();

                            if (value[0] === ".") {
                              e.target.value = "";
                            }

                            e.target.value = e.target.value.trim().replace(/[^0-9.]|(?<=\..*)\./g, "");
                            if (isNaN(value)) {
                              return;
                            }
                            if (value[0] === "0" && value[1] && value[1] !== ".") {
                              e.target.value = "0";
                              return;
                            }
                            this.onMinPriceInputBlurDebounced();
                          }}
                        />
                        <button id={this.state.idPrefix + "add-min-price-plus"} onClick={this.onMinPricePlusButtonClick} disabled={!volsAreCalculated}>
                          <img className="arrowUpCircle" />
                        </button>
                        <MyTooltip key={this.state.idPrefix + "add-min-price-plus"} target={this.state.idPrefix + "add-min-price-plus"} optionalOffset={15}>
                          Increase
                        </MyTooltip>
                      </div>
                      <span id="minIVInDollars">{volsAreCalculated ? "$" + this.state.addTabMinPriceInBaseAmount.toFixed(2) : null}</span>
                      <MyTooltip key="minIVInDollars" target="minIVInDollars" optionalOffset={15}>
                        Option price in $ USD
                      </MyTooltip>
                    </div>
                    {isPriceInRange ? (
                      <div key="currentIvStatic" className="priceRangeContainer">
                        <span id="currentIV">Current IV (%)</span>
                        <MyTooltip key="currentIV" target="currentIV" optionalOffset={15}>
                          Current implied volatility (IV) on the market.
                        </MyTooltip>
                        <div className="currentPriceLabel">
                          {/* <span className="currentPriceText">{formatValue(Number(getVolatility(this.props.market, this.state.currentTick)), 1)}</span> */}
                          <span className="currentPriceText">{formatValue(parseFloat(this.props.market.longPriceInVol), 1)}</span>
                        </div>
                        <span id="currentIVInDollars">${formatValue(Number(this.props.market.longPrice), 2)}</span>
                        <MyTooltip key="currentIVInDollars" target="currentIVInDollars" optionalOffset={15}>
                          Option price in $ USD
                        </MyTooltip>
                      </div>
                    ) : (
                      <div key="currentIvInput" className="priceRangeContainer">
                        <span id="currentIV">Current IV (%)</span>
                        <MyTooltip key="currentIV" target="currentIV" optionalOffset={15}>
                          Current implied volatility (IV) on the market.
                        </MyTooltip>
                        <div>
                          <button
                            id={this.state.idPrefix + "current-iv-minus"}
                            onClick={this.onCurrentIvPriceMinusButtonClick}
                            // disabled={!volAmountsAreCalculated}
                          >
                            <img className="arrowDownCircle" />
                          </button>
                          <input
                            ref={this.currentIvInputRef}
                            id={this.state.idPrefix + "current-iv-input"}
                            type="text"
                            autoComplete="off"
                            onBlur={this.onCurrentIvPriceInputBlur}
                            onChange={e => {
                              let value = e.target.value.trim();

                              if (value[0] === ".") {
                                e.target.value = "";
                              }

                              e.target.value = e.target.value.trim().replace(/[^0-9.]|(?<=\..*)\./g, "");
                              if (isNaN(value)) {
                                return;
                              }
                              if (value[0] === "0" && value[1] && value[1] !== ".") {
                                e.target.value = "0";
                                return;
                              }
                              this.onCurrentIvPriceInputBlurDebounced();
                            }}
                          />
                          <button
                            id={this.state.idPrefix + "current-iv-plus"}
                            onClick={this.onCurrentIvPricePlusButtonClick}
                            // disabled={!volAmountsAreCalculated}
                          >
                            <img className="arrowUpCircle" />
                          </button>
                        </div>
                        <span id="currentIVInDollars">${formatValue(Number(this.props.market.longPrice), 2)}</span>
                        <span id="currentIVInDollars"></span>
                        <MyTooltip key="currentIVInDollars" target="currentIVInDollars" optionalOffset={15}>
                          Option price in $ USD
                        </MyTooltip>
                      </div>
                    )}
                    <div className="priceRangeContainer">
                      <span id="maxIV">Max IV (%)</span>
                      <MyTooltip key="maxIV" target="maxIV" optionalOffset={15}>
                        Maximum implied volatility (IV) is upper boundary of IV range in which your liquidity is traded on the market. <br /> <br />
                        If IV moves above Max IV, your liquidity is no longer traded, and your LP position no longer earns trading fees. <br /> <br />
                        Learn more: <br />
                        <a href="https://gamma-options.gitbook.io/docs/" target="_blank" rel="noreferrer">
                          Docs
                        </a>
                      </MyTooltip>
                      <div>
                        <button id={this.state.idPrefix + "add-max-price-minus"} onClick={this.onMaxPriceMinusButtonClick} disabled={!volsAreCalculated}>
                          <img className="arrowDownCircle" />
                        </button>
                        <MyTooltip key={this.state.idPrefix + "add-max-price-minus"} target={this.state.idPrefix + "add-max-price-minus"} optionalOffset={15}>
                          Decrease
                        </MyTooltip>
                        <input
                          ref={this.addMaxPriceInputRef}
                          id={this.state.idPrefix + "add-max-price-input"}
                          type="text"
                          autoComplete="off"
                          onBlur={this.onMaxPriceInputBlur}
                          onChange={e => {
                            // todo: v1 maybe use something like let value = inputDecimals(e.target.value, "LP");
                            let value = e.target.value.trim();

                            if (value[0] === ".") {
                              e.target.value = "";
                            }

                            e.target.value = e.target.value.trim().replace(/[^0-9.]|(?<=\..*)\./g, "");
                            if (isNaN(value)) {
                              return;
                            }
                            if (value[0] === "0" && value[1] && value[1] !== ".") {
                              e.target.value = "0";
                              return;
                            }

                            this.onMaxPriceInputBlurDebounced();
                          }}
                        />
                        <button id={this.state.idPrefix + "add-max-price-plus"} onClick={this.onMaxPricePlusButtonClick} disabled={!volsAreCalculated}>
                          <img className="arrowUpCircle" />
                        </button>
                        <MyTooltip key={this.state.idPrefix + "add-max-price-plus"} target={this.state.idPrefix + "add-max-price-plus"} optionalOffset={15}>
                          Increase
                        </MyTooltip>
                      </div>
                      <span id="maxIVInDollars">{volsAreCalculated ? "$" + this.state.addTabMaxPriceInBaseAmount.toFixed(2) : null}</span>
                      <MyTooltip key="maxIVInDollars" target="maxIVInDollars" optionalOffset={15}>
                        Option price in $ USD
                      </MyTooltip>
                    </div>
                  </div>
                ) : (
                  <div className="futuresRangesMenuContainer">
                    <div className="labelAndTooltipContainer">
                      <span>Select side:</span>
                    </div>
                    <div className="futureSelectOptionContainer">
                      <div className="rangeFutureSelect" onClick={this.handleFutureRangeTokenDropmenuClick}>
                        <span id="tokenSelectedOption" className="tokenSelectedOption">
                          {this.state.selectedFutureRange.text}
                        </span>
                        <img className={this.props.rangeFutureTokenDropmenuOpened ? "tokenSelectArrowUpIcon" : "tokenSelectArrowDownIcon"} />
                      </div>
                    </div>
                  </div>
                )}

                <div className="depositsContainer">
                  <div className="rowDepositsErrorMessageContainer">
                    <div className="rowDepositsContainer">
                      <div className="labelAndInputContainer">
                        <div className="labelAndTooltipContainer">
                          <span id="optionsInRangeAdd">{!isFuture ? "Options" : "Futures"} in range </span>
                          <MyTooltip key="optionsInRangeAdd" target="optionsInRangeAdd" optionalOffset={15}>
                            {!isFuture ? "Options" : "Futures"} in range is the sum of {!isFuture ? "options" : "futures"} being bought and sold in the range
                            (see Breakdown). <br /> <br />
                            It defines the size of the LP position as it is always the same, regardless of the option price or IV. <br /> <br />
                            Learn more: <br />
                            <a href="https://gamma-options.gitbook.io/docs/" target="_blank" rel="noreferrer">
                              Docs
                            </a>
                          </MyTooltip>
                        </div>
                        <div className="inputAmountAndSettingsContainer">
                          <FormGroup>
                            <Input
                              innerRef={this.collateralAmountInputRef}
                              id={this.state.idPrefix + "collateral-amount"}
                              type="text"
                              placeholder={collateralPlaceholder}
                              className={`depositInput${this.state.addTabCollateralAmountInputClass === "has-danger" ? " inputDanger" : ""}${
                                this.state.addTabCollateralAmountInputClass === "has-success" ? " inputSuccess" : ""
                              }`}
                              disabled={!volsAreCalculated}
                              onChange={e => {
                                let value = inputDecimals(e.target.value, "LP");

                                if (value === undefined || value === null) {
                                  e.target.value =
                                    e.target.value.slice(0, this.collateralAmountInputRef.current.selectionStart - 1) +
                                    e.target.value.slice(this.collateralAmountInputRef.current.selectionStart - 1 + 1);
                                  value = e.target.value.slice(0, -1);
                                } else {
                                  e.target.value = value;
                                  this.onCollateralInputChangedDebounced(value);
                                }
                              }}
                              autoComplete="off"
                              autoFocus
                            />
                            {this.state.addTabCollateralAmountInputClass === "has-danger" ? (
                              <img className="clearInputError" onClick={this.clearInput} />
                            ) : null}
                            {this.state.addTabCollateralAmountInputClass === "has-success" ? <img className="validInput" /> : null}
                            <div className="increaseDecreaseInputButtons">
                              <img className="increaseInputButton" onClick={this.increaseOptionsInRangeHandler} />
                              <img className="decreaseInputButton" onClick={this.decreaseOptionsInRangeHandler} />
                            </div>
                          </FormGroup>
                          <button id={this.state.idPrefix + "max-button"} className="maxButton" onClick={this.onMaxAddButtonClick}>
                            max
                          </button>
                          <MyTooltip key={this.state.idPrefix + "max-button"} target={this.state.idPrefix + "max-button"} optionalOffset={15}>
                            Calculate max value for {!isFuture ? "Options" : "Futures"} in range considering the limits: <br /> <br />
                            1. available assets in the Margin pool, <br />
                            2. excess liquidity in your account, <br />
                            3. deposited USD in your account, <br />
                            4. account health
                          </MyTooltip>
                          <button
                            id={this.state.idPrefix + "add-tx-settings"}
                            className="btn-link settingsCog"
                            disabled={this.state.addTabCollateralAmountInputClass !== "has-success"}
                            onClick={this.toggleTransactionSettingsModal}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="errorDepositsContainer">
                      <span className="errorDepositsText">
                        {this.state.addTabCollateralAmountInputClass === "has-danger" ? this.state.addTabCollateralErrMessage : ""}
                      </span>
                    </div>
                  </div>

                  <div className="rowDepositsErrorMessageContainer">
                    <div className="rowDepositsContainer">
                      <div className="labelAndInputContainer">
                        <div className="labelAndTooltipContainer">
                          <span id="usdReserves">Cash reserves </span>
                          <MyTooltip key="usdReserves" target="usdReserves" optionalOffset={15}>
                            Cash reserves are used for buying {!isFuture ? "options" : "futures"} in the defined range (see Breakdown). <br /> <br />
                            Formula used: <br />
                            RESERVES = ACTUAL x 2 + PENALTY <br /> <br />
                            where ACTUAL is cash needed for buying {!isFuture ? "options" : "futures"} at the time of opening, and PENALTY is fixed at $300.
                          </MyTooltip>
                        </div>
                        <div className="inputAmountAndSettingsContainer">
                          <FormGroup>
                            <Input
                              innerRef={this.baseAmountInputRef}
                              id={this.state.idPrefix + "base-amount"}
                              type="text"
                              placeholder={basePlaceholder}
                              className={`depositInput${this.state.addTabBaseAmountInputClass === "has-danger" ? " inputDanger" : ""}${
                                this.state.addTabBaseAmountInputClass === "has-success" ? " inputSuccess" : ""
                              }`}
                              disabled={!volsAreCalculated || this.state.addTabCollateralAmountInputClass !== "has-success"}
                              onChange={e => {
                                let value = inputDecimals(e.target.value, "LP");

                                if (value === undefined || value === null) {
                                  e.target.value =
                                    e.target.value.slice(0, this.baseAmountInputRef.current.selectionStart - 1) +
                                    e.target.value.slice(this.baseAmountInputRef.current.selectionStart - 1 + 1);
                                  value = e.target.value.slice(0, -1);
                                } else {
                                  e.target.value = value;
                                }

                                this.onAddTabBaseChangeDebounced(value);
                              }}
                              autoComplete="off"
                            />
                            {this.state.addTabBaseAmountInputClass === "has-danger" ? <img className="clearInputError" onClick={this.clearInput} /> : null}
                            {this.state.addTabBaseAmountInputClass === "has-success" ? <img className="validInput" /> : null}
                            <div className="increaseDecreaseInputButtons">
                              <img className="increaseInputButton" onClick={this.increaseUsdReservesHandler} />
                              <img className="decreaseInputButton" onClick={this.decreaseUsdReservesHandler} />
                            </div>
                          </FormGroup>
                          <MyTooltip target={this.state.idPrefix + "add-tx-settings"}>Transaction settings</MyTooltip>
                        </div>
                      </div>
                    </div>

                    <div className="errorDepositsContainer">
                      <span className="errorDepositsText">{this.state.addTabBaseAmountInputClass === "has-danger" ? this.state.addTabBaseErrMessage : ""}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="optionPriceInformation">{addTransitionsContent}</div>
            </div>
          ) : null}

          {this.props.tabs === "remove" ? (
            <div className="removeFormContainer">
              <div className="tokenSelectQuantityWrapper">
                <div className="labelAndInputContainer">
                  <div className="labelAndTooltipContainer">
                    <span id="removeSelectPosition">Select position</span>
                    <MyTooltip key="removeSelectPosition" target="removeSelectPosition" optionalOffset={15}>
                      If you have multiple LP positions on this market, select a specific position to be closed. <br /> <br />
                    </MyTooltip>
                  </div>

                  <div className="tokenSelectContainer">
                    <div className="tokenSelect" onClick={this.handleRangeTokenDropmenuClick}>
                      <span id="tokenSelectedOption" className="tokenSelectedOption">
                        {this.displaySelectedLPPosition()}
                      </span>
                      <img className={this.props.rangeTokenDropmenuOpened ? "tokenSelectArrowUpIcon" : "tokenSelectArrowDownIcon"} />
                    </div>
                  </div>
                </div>

                <div className="labelAndInputContainer">
                  <div className="labelAndTooltipContainer">
                    <span id="optionsInRangeRemove">{!isFuture ? "Options" : "Futures"} in range</span>
                    <MyTooltip key="optionsInRangeRemove" target="optionsInRangeRemove" optionalOffset={15}>
                      {!isFuture ? "Options" : "Futures"} to be removed from market, always 100%. <br /> <br />
                      After removal, your LP position no longer earns trading fees.
                    </MyTooltip>
                  </div>
                  <div className="inputAmountAndSettingsContainer">
                    <Form>
                      <FormGroup>
                        <Input
                          innerRef={this.liqAmountInputRef}
                          id={this.state.idPrefix + "liq-amount"}
                          type="text"
                          autoComplete="off"
                          className={`inputText${""}${" inputSuccess"}`}
                          placeholder="Liquidity to remove from market..."
                          value={this.state.addTabLiqAmountInput + " (100%)"}
                          disabled
                        />
                      </FormGroup>
                    </Form>
                    <button id={this.state.idPrefix + "remove-tx-settings"} className="btn-link settingsCog" onClick={this.toggleTransactionSettingsModal} />
                    <MyTooltip target={this.state.idPrefix + "remove-tx-settings"}>Transaction settings</MyTooltip>
                  </div>
                </div>
              </div>

              {<div className="optionPriceInformation">{this.displayRemoveTransitions()}</div>}
            </div>
          ) : null}

          {/* Right half */}
          <div className="chartTableContainer" style={{ position: "relative" }}>
            <div
              className="verticalChartLabelContainer"
              style={
                this.props.tabs === "add"
                  ? {
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      height: "300px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      zIndex: "3000",
                      marginTop: "5%"
                    }
                  : null
              }
            >
              <span className="verticalChartLabel">Profit/Loss [USD]</span>
            </div>

            <div className="horizontalChartLabelCogContainer">
              <span id={this.state.idPrefix + "risk-analysis-chart"} className="horizontalChartLabel">
                Risk Analysis: ETH price
              </span>
              <MyTooltip key={this.state.idPrefix + "risk-analysis-chart"} target={this.state.idPrefix + "risk-analysis-chart"} optionalOffset={15}>
                Risk analysis chart shows profit/loss of the LP position relative to ETH price change. <br /> <br />
                Move mouse over chart to simulate the change of ETH price. <br /> <br />
              </MyTooltip>
              <button
                id={this.state.idPrefix + "chart-options-button"}
                className="btn-link settingsCogChart"
                disabled={shouldBePrintedDefault}
                onClick={this.toggleSetCogChartOptionsOpen}
              />
              <MyTooltip key={this.state.idPrefix + "chart-options-button"} target={this.state.idPrefix + "chart-options-button"} optionalOffset={15}>
                Chart options
              </MyTooltip>
            </div>
            <div id="tradePositionPreviewContainer" className={`${shouldBePrintedDefault && this.props.tabs === "add" ? "disabledChartBackground" : ""}`}>
              {this.props.cogChartOptionsOpenAdd && this.props.tabs === "add" && (
                <CogChartOptions
                  key="cogChartoptions"
                  showOptionNewPosition
                  isNewPositionSelected={this.state.newPositionSelected}
                  allNewSwitchChanged={this.onAllNewSwitchChange}
                  standardDeviation={this.state.standardDeviation}
                  setStandardDeviation={this.onStandardDeviationChange}
                />
              )}
              {this.props.tabs === "add" && chartData.series1.length > 0 ? (
                <PositionsChart
                  disabled={shouldBePrintedDefault}
                  isXUnderlying
                  isTradeVolumeMode={false}
                  selectedUnderPrice={this.state.selectedUnderPrice}
                  daysToExpiry={this.state.selectedDaysToExpiry}
                  currentPriceInVol={getVolatility(this.props.market, this.state.currentTick)}
                  dailyVolume={this.state.dailyVolume}
                  minDomainX={chartData.minDomainX}
                  maxDomainX={chartData.maxDomainX}
                  minDomainY={chartData.minDomainY}
                  maxDomainY={chartData.maxDomainY}
                  series1={chartData.series1}
                  series2={chartData.series2}
                  minPriceInVol={addTabPreviewPosition ? addTabPreviewPosition.lowerPriceInVol : 0}
                  maxPriceInVol={addTabPreviewPosition ? addTabPreviewPosition.upperPriceInVol : 0}
                  selectXCallback={this.selectXCallback}
                  deselectXCallback={this.deselectXCallback}
                  liqPrices={this.state.liqPrices}
                  lpLiqPrices={this.state.lpLiqPrices}
                  tabs={this.props.tabs}
                  deviations={chartData.deviations}
                />
              ) : null}

              {this.props.tabs === "remove" && this.state.chartData.series1.length > 0 ? (
                <PositionsChart
                  disabled={false}
                  isXUnderlying
                  isTradeVolumeMode={false}
                  selectedUnderPrice={this.props.underlyingTokenPrice.normalized}
                  daysToExpiry={daysToExpiry}
                  currentPriceInVol={getVolatility(this.props.market, this.state.currentTick)}
                  minDomainX={this.state.chartData.minDomainX}
                  maxDomainX={this.state.chartData.maxDomainX}
                  minDomainY={this.state.chartData.minDomainY}
                  maxDomainY={this.state.chartData.maxDomainY}
                  series1={this.state.chartData.series1}
                  series2={this.state.chartData.series2}
                  minPriceInVol={this.state.removeTabSelectedPosition ? 1.0001 ** this.state.removeTabSelectedPosition.lower : 0}
                  maxPriceInVol={this.state.removeTabSelectedPosition ? 1.0001 ** this.state.removeTabSelectedPosition.upper : 0}
                  selectXCallback={this.selectXCallback}
                  deselectXCallback={this.deselectXCallback}
                  liqPrices={this.state.liqPrices}
                  lpLiqPrices={this.state.lpLiqPrices}
                  tabs={this.props.tabs}
                  deviations={this.state.chartData.deviations}
                />
              ) : null}
            </div>
            {this.props.tabs === "add" && chartData.series1.length > 0 ? (
              <SlidersPanel
                selectedPositions={addTabPreviewPosition ? [addTabPreviewPosition] : []}
                markets={markets}
                selectedUnderPrice={this.state.selectedUnderPrice}
                selectedDaysToExpiry={this.state.selectedDaysToExpiry}
                selectedVolatility={this.state.selectedVolatility}
                calculateChartDataCallback={this.calculateChartData}
                delta={delta}
                gamma={gamma}
                theta={theta}
                vega={vega}
                disabled={slidersShouldBeDisabled}
                currentVolatility={1.0001 ** this.state.currentTick} // used only by this component, when user is setting the price
              />
            ) : null}

            {this.displayPnLTable(shouldBePrintedDefault && this.props.tabs === "add", chartData)}
          </div>
        </div>

        {this.props.tabs === "add" && (
          <button
            ref={this.poolModalAddButtonRef}
            id="poolModalAddButton"
            className="addRemoveActionButton"
            disabled={this.state.addTabCollateralAmountInputClass !== "has-success" || this.state.addTabBaseAmountInputClass !== "has-success"}
            onClick={this.onAddButtonClick}
          >
            add
          </button>
        )}
        {this.props.tabs === "remove" && (
          <button
            ref={this.poolModalRemoveButtonRef}
            id="poolModalRemoveButton"
            className="addRemoveActionButton"
            disabled={false}
            onClick={this.onRemoveButtonClick}
          >
            remove
          </button>
        )}
      </>
    );
  }
}

TradeModalPoolTab2.propTypes = {
  web3Object: PropTypes.object,
  market: PropTypes.object.isRequired,
  markets: PropTypes.array,
  marketsLoaded: PropTypes.bool,
  positionsManager: PropTypes.object,
  lpManager: PropTypes.object,
  selectedTokenPair: PropTypes.object,
  web3: PropTypes.object,
  openedLPPositions: PropTypes.array,
  openedTradePositions: PropTypes.array,
  selectedBaseTokenBalances: PropTypes.object,
  selectedUnderlyingTokenBalances: PropTypes.object,
  underlyingTokenPrice: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  web3Account: PropTypes.string,
  dydxMath: PropTypes.object,
  tabs: PropTypes.object,
  needsToBeClosed: PropTypes.object,
  size: PropTypes.object,
  selectedPosition: PropTypes.array,
  selectedPositions: PropTypes.array,
  marketAccounts: PropTypes.array,
  marginAccountData: PropTypes.object,
  marginPoolData: PropTypes.object,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  isXUnderlying: PropTypes.bool,
  cogChartOptionsOpenAdd: PropTypes.object,
  rangeTokenDropmenuOpened: PropTypes.object,
  rangeFutureTokenDropmenuOpened: PropTypes.object,
  cogChartOptionsOpen: PropTypes.object,
  setCogChartOptionsOpenAdd: PropTypes.func,
  setRangeTokenDropmenuOpened: PropTypes.func,
  setFutureRangeTokenDropmenuOpened: PropTypes.func,
  tokenBalances: PropTypes.array,
  setNeedsToBeClosed: PropTypes.func,
  settlements: PropTypes.array,
  currentTimestamp: PropTypes.number,
  positionsBorrowFactors: PropTypes.object
};

function mapStateToProps(state) {
  return {
    web3Object: web3ObjectSelector(state),
    markets: marketsSelector(state),
    positionsManager: positionsManagerSelector(state),
    lpManager: lpManagerSelector(state),
    selectedTokenPair: selectedTokenPairSelector(state),
    web3: web3Selector(state),
    openedLPPositions: openedLPPositionsSelector(state),
    openedTradePositions: openedTradePositionsSelector(state),
    selectedBaseTokenBalances: selectedBaseTokenBalancesSelector(state),
    selectedUnderlyingTokenBalances: selectedUnderlyingTokenBalancesSelector(state),
    underlyingTokenPrice: avgUnderlyingTokenPriceSelector(state),
    web3Account: web3AccountSelector(state),
    dydxMath: dydxMathSelector(state),
    marginAccountData: marginAccountDataSelector(state),
    marginPoolData: marginPoolDataSelector(state),
    tokenBalances: tokenBalancesSelector(state),
    settlements: settlementDataSelector(state),
    currentTimestamp: currentTimestampSelector(state),
    positionsBorrowFactors: positionsBorrowFactorsSelector(state)
  };
}

export default connect(mapStateToProps)(TradeModalPoolTab2);
