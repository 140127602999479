import * as types from "../actionTypes.js";

const addNotification = (oldLocalStorage, action) => {
  // console.log("reducer: add pending notification", action);
  const newState =
    JSON.stringify(oldLocalStorage) === "{}" //if localStorage is empty
      ? { [action.account]: [action.notification] }
      : JSON.stringify(oldLocalStorage) !== "{}" && !oldLocalStorage[action.account] //if localStorage is not empty but there is no notifications for that account
      ? { ...oldLocalStorage, [action.account]: [action.notification] }
      : { ...oldLocalStorage, [action.account]: [action.notification, ...oldLocalStorage[action.account]] };
  localStorage.setItem("notifications", JSON.stringify(newState));
  return newState;
};

// const addPendingNotification = (oldLocalStorage, action) => {
//   console.log("reducer: add pending notification", action);
//   let newState;
//   if (JSON.stringify(oldLocalStorage) === "{}") {
//     newState = { [action.account]: [action.notification] };
//   } else if (JSON.stringify(oldLocalStorage) !== "{}" && !oldLocalStorage[action.account]) {
//     newState = { ...oldLocalStorage, [action.account]: [action.notification] };
//   } else {
//     let flag = false;
//     const oldNotifications = oldLocalStorage
//       ? oldLocalStorage[action.account].filter(n => {
//           if (
//             n.nonce !== -1 &&
//             action.notification.nonce !== -1 &&
//             n.nonce === action.notification.nocne &&
//             (n.status === "confirmed" || n.status === "failed")
//           ) {
//             flag = true;
//           }
//           return true;
//         })
//       : [];
//     if (flag) {
//       newState = { ...oldLocalStorage, [action.account]: [...oldNotifications] };
//     } else {
//       newState = { ...oldLocalStorage, [action.account]: [action.notification, ...oldNotifications] };
//     }
//   }
//   const sortedNotifications = newState[action.account].sort(function(x, y) {
//     return parseInt(y.timestamp) - parseInt(x.timestamp);
//   });
//   newState = { ...newState, [action.account]: sortedNotifications };
//   localStorage.setItem("notifications", JSON.stringify(newState));
//   return newState;
// };

const addFailedTransaction = (oldLocalStorage, action) => {
  // console.log("reducer: add failed notification", action);
  // const oldNotifications = oldLocalStorage
  //   ? oldLocalStorage[action.account].filter(n => {
  //       if (action.notification.nonce === -1 || n.nonce === -1 || n.nonce < action.notification.nonce) {
  //         return true;
  //       }
  //       return false;
  //     })
  //   : [];
  const oldNotifications =
    oldLocalStorage && oldLocalStorage[action.account] ? oldLocalStorage[action.account].filter(n => n.timestamp != action.notification.timestamp) : [];
  let newState;
  if (JSON.stringify(oldLocalStorage) === "{}") {
    newState = { [action.account]: [action.notification] };
  } else if (JSON.stringify(oldLocalStorage) !== "{}" && !oldLocalStorage[action.account]) {
    newState = { ...oldLocalStorage, [action.account]: [action.notification] };
  } else {
    newState = { ...oldLocalStorage, [action.account]: [action.notification, ...oldNotifications] };
  }
  let sortedNotifications = newState[action.account].sort(function(x, y) {
    return parseInt(y.timestamp) - parseInt(x.timestamp);
  });
  sortedNotifications = sortedNotifications.filter(n => {
    if (action.notification.nonce > n.nonce && n.status === "pending") {
      return false;
    }
    return true;
  });
  newState = { ...newState, [action.account]: sortedNotifications };
  localStorage.setItem("notifications", JSON.stringify(newState));
  return newState;
};

const addConfirmedTransaction = (oldLocalStorage, action) => {
  // console.log("reducer: add confirmed notification", action);
  // const oldNotifications = oldLocalStorage
  //   ? oldLocalStorage[action.account].filter(n => {
  //       if (action.notification.nonce === -1 || n.nonce === -1 || n.nonce < action.notification.nonce) {
  //         return true;
  //       }
  //       return false;
  //     })
  //   : [];
  const oldNotifications =
    oldLocalStorage && oldLocalStorage[action.account] ? oldLocalStorage[action.account].filter(n => n.timestamp != action.notification.timestamp) : [];
  let newState;
  if (JSON.stringify(oldLocalStorage) === "{}") {
    newState = { [action.account]: [action.notification] };
  } else if (JSON.stringify(oldLocalStorage) !== "{}" && !oldLocalStorage[action.account]) {
    newState = { ...oldLocalStorage, [action.account]: [action.notification] };
  } else {
    newState = { ...oldLocalStorage, [action.account]: [action.notification, ...oldNotifications] };
  }
  let sortedNotifications = newState[action.account].sort(function(x, y) {
    return parseInt(y.timestamp) - parseInt(x.timestamp);
  });
  sortedNotifications = sortedNotifications.filter(n => {
    if (action.notification.nonce > n.nonce && n.status === "pending") {
      return false;
    }
    return true;
  });
  newState = { ...newState, [action.account]: sortedNotifications };
  localStorage.setItem("notifications", JSON.stringify(newState));
  return newState;
};

export const newNotifications = (state = {}, action) => {
  let oldLocalStorage = JSON.parse(localStorage.getItem("notifications"));
  if (!oldLocalStorage) {
    oldLocalStorage = {};
  }
  switch (action.type) {
    case types.ADD_PENDING_TRANSACTION_NOTIFICATION: {
      // const newState = addPendingNotification(oldLocalStorage, action);
      const newState = addNotification(oldLocalStorage, action);
      return newState;
    }
    case types.ADD_FAILED_TRANSACTION_NOTIFICATION: {
      const newState = addFailedTransaction(oldLocalStorage, action);
      return newState;
    }
    case types.CONFIRM_TRANSACTION_NOTIFICATION: {
      // const modifiedNotificationsForAccount = oldLocalStorage[action.account].map(n => {
      //   if (n.nonce == action.nonce) {
      //     return { ...n, status: "confirmed" };
      //   }
      //   return n;
      // });
      // const modifiedNotifications = { ...oldLocalStorage, [action.account]: modifiedNotificationsForAccount };
      // localStorage.removeItem("notifications");
      // localStorage.setItem("notifications", JSON.stringify(modifiedNotifications));
      // return modifiedNotifications;
      const newState = addConfirmedTransaction(oldLocalStorage, action);
      return newState;
    }
    case types.CONFIRM_LIQUIDATED_ACCOUNT_NOTIFICATION: {
      const newState = addNotification(oldLocalStorage, action);
      return newState;
    }
    case types.REMOVE_CONFIRMED_TRANSACTION_NOTIFICATION: {
      const filteredNotificationsForAccount = oldLocalStorage[action.account].filter(n => n.status === "pending");
      const newState = { ...oldLocalStorage, [action.account]: filteredNotificationsForAccount };
      localStorage.removeItem("notifications");
      localStorage.setItem("notifications", JSON.stringify(newState));
      return newState;
    }
    case types.CLEAR_ALL_NOTIFICATIONS: {
      const newState = { ...oldLocalStorage, [action.account]: [] };
      localStorage.removeItem("notifications");
      localStorage.setItem("notifications", JSON.stringify(newState));
      return newState;
    }
    case types.REMOVE_SETTLEMENT_PENDING_NOTIFICATION: {
      const notifications = oldLocalStorage ? oldLocalStorage[action.account].filter(n => n.tx.split(" (")[0] != action.hash) : []; //split because of the nonce
      const newState = { ...oldLocalStorage, [action.account]: notifications };
      localStorage.removeItem("notifications");
      localStorage.setItem("notifications", JSON.stringify(newState));
      return newState;
    }
    default:
      return state;
  }
};
