import {
  insuranceFundDepositOrWithdrawalAdded,
  insuranceFundDepositsAndWithdrawalsLoaded,
  insuranceFundLoaded,
  insuranceFundLossCoveredAdded,
  insuranceFundLossesCoveredLoaded,
  insurerBalanceLoaded
} from "../actions/actions.js";
import { handleNewError } from "./errorInteractions";
import { getEventsStreamCached } from "./interactionsManager";
import { toBn } from "evm-bn";
import { getNonce, hasTxFailed, onConfirmed, onError, onFailed, onPending } from "../../utils/utils.js";
import { checkAndApprove } from "./balancesInteractions.js";
import { Contract } from "@ethersproject/contracts";
import { batch } from "react-redux";

export const loadInsuranceFundEvents = async (networkData, insuranceFund, userAccount, dispatch) => {
  try {
    const insuranceFundAllEvents = await getEventsStreamCached(networkData, insuranceFund, "InsuranceFund", "allEvents", {});
    const depositsStream = insuranceFundAllEvents.filter(e => e.event === "Deposit"); // && e.returnValues.user === userAccount
    const withdrawalsStream = insuranceFundAllEvents.filter(e => e.event === "Withdraw"); // && e.returnValues.user === userAccount
    const lossesCoveredStream = insuranceFundAllEvents.filter(e => e.event === "LossesCoveredForMarginAccount");

    let depositsAndWithdrawals = [
      depositsStream.map(event => {
        return {
          amount: event.returnValues.amount.toString(),
          timestamp: event.returnValues.timestamp.toString(),
          isDeposit: true,
          user: event.returnValues.user
        };
      }),
      withdrawalsStream.map(event => {
        return {
          amount: event.returnValues.amount.toString(),
          timestamp: event.returnValues.timestamp.toString(),
          isDeposit: false,
          user: event.returnValues.user
        };
      })
    ].flat();
    depositsAndWithdrawals = depositsAndWithdrawals.filter(daw => daw != null);

    let lossesCovered = lossesCoveredStream.map(event => {
      return {
        amount: event.returnValues.amount.toString(),
        isAccountLiquidation: event.returnValues.isAccountLiquidation,
        timestamp: event.returnValues.timestamp.toString()
      };
    });
    lossesCovered = lossesCovered.filter(lc => lc != null);

    batch(() => {
      dispatch(insuranceFundDepositsAndWithdrawalsLoaded(depositsAndWithdrawals));
      dispatch(insuranceFundLossesCoveredLoaded(lossesCovered));
    });
  } catch (e) {
    handleNewError({}, "Error while fetching past events for Insurance Fund. Please reload the application!", 1, dispatch);
  }
};

export const loadInsuranceFundBalances = async loadHelper => {
  let { insuranceFundBalance, unclaimedInsuranceFundBalance } = await loadHelper.methods.loadInsuranceFundData().call();
  insuranceFundBalance /= 1e18;
  unclaimedInsuranceFundBalance /= 1e18;
  return { insuranceFundBalance, unclaimedInsuranceFundBalance };
};

export const loadInsurerBalance = async (loadHelper, userAddress) => {
  let { insurerBalance, unclaimedInsurerBalance } = await loadHelper.methods.loadInsurerBalance(userAddress).call();
  insurerBalance /= 1e18;
  unclaimedInsurerBalance /= 1e18;
  return { insurerBalance, unclaimedInsurerBalance };
};

export const subscribeToInsuranceFundEvents = async (provider, userAccount, insuranceFund, loadHelper, dispatch) => {
  const insuranceFundAbi = [
    "event Deposit(address indexed user, uint128 amount, uint32 timestamp)",
    "event Withdraw(address indexed user, uint128 amount, uint32 timestamp)"
  ];
  const insuranceFundContract = new Contract(insuranceFund.options.address, insuranceFundAbi, provider);

  try {
    insuranceFundContract.on("Deposit", async (user, amount, timestamp, event) => {
      console.log("on Deposit event handler");
      if (user === userAccount) {
        // load insurance fund balances
        const insuranceFund = await loadInsuranceFundBalances(loadHelper);
        const insurerBalance = await loadInsurerBalance(loadHelper, userAccount);

        // todo: fetch wallet balance

        // batch dispatch
        batch(() => {
          dispatch(
            insuranceFundDepositOrWithdrawalAdded({
              user: user,
              amount: amount.toString(),
              timestamp: timestamp.toString(),
              isDeposit: true
            })
          );
          dispatch(insuranceFundLoaded(insuranceFund));
          dispatch(insurerBalanceLoaded(insurerBalance));
        });
      }
    });
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while depositing funds", 1, dispatch);
  }

  try {
    insuranceFundContract.on("Withdraw", async (user, amount, timestamp, event) => {
      console.log("on Withdraw event handler");
      if (user === userAccount) {
        // load insurance fund balances
        const insuranceFund = await loadInsuranceFundBalances(loadHelper);
        const insurerBalance = await loadInsurerBalance(loadHelper, userAccount);

        // todo: fetch wallet balance

        // batch dispatch
        batch(() => {
          dispatch(
            insuranceFundDepositOrWithdrawalAdded({
              user: user,
              amount: amount.toString(),
              timestamp: timestamp.toString(),
              isDeposit: false
            })
          );
          dispatch(insuranceFundLoaded(insuranceFund));
          dispatch(insurerBalanceLoaded(insurerBalance));
        });
      }
    });
  } catch (e) {
    handleNewError({}, "Error while withdrawing funds", 1, dispatch);
  }
};

export const subscribeToInsuranceFundLossesCoveredEvents = async (provider, userAccount, insuranceFund, loadHelper, dispatch) => {
  const insuranceFundAbi = ["event LossesCoveredForMarginAccount(uint128 amount, bool isAccountLiquidation, uint32 timestamp)"];
  const insuranceFundContract = new Contract(insuranceFund.options.address, insuranceFundAbi, provider);

  try {
    insuranceFundContract.on("LossesCoveredForMarginAccount", async (amount, isAccountLiquidation, timestamp, event) => {
      console.log("on LossesCoveredForMarginAccount event handler");

      // load insurance fund balances
      const insuranceFund = await loadInsuranceFundBalances(loadHelper);
      const insurerBalance = await loadInsurerBalance(loadHelper, userAccount);

      // batch dispatch
      batch(() => {
        dispatch(
          insuranceFundLossCoveredAdded({
            amount: amount.toString(),
            isAccountLiquidation: isAccountLiquidation,
            timestamp: timestamp.toString()
          })
        );
        dispatch(insuranceFundLoaded(insuranceFund));
        dispatch(insurerBalanceLoaded(insurerBalance));
      });
    });
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while handling LossesCoveredForMarginAccount event", 1, dispatch);
  }
};

export const depositToken = async (insuranceFundContract, account, amount, web3, assetAddress, closeModal, setStep, dispatch) => {
  try {
    const notificationText = `Deposit ${Number(amount).toFixed(2)} USD to Insurance Fund`;
    amount = toBn(amount, 18).toString();

    const txNonce = await getNonce(web3, account);
    await checkAndApprove(web3, assetAddress, account, insuranceFundContract, amount, setStep);
    const contractMethod = insuranceFundContract.methods.depositToken(amount);
    const limitGas = Math.round((await contractMethod.estimateGas({ from: account })) * 1.2); // 20% extra gas
    console.log("Estimated gas: " + limitGas);
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    let isConfirmed = false;
    await contractMethod
      .send({ from: account, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, account, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, account, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, account, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, account, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    console.log(e);
    onError(e, "Error while depositing insurance funds!", dispatch);
  }
};

export const withdrawToken = async (insuranceFundContract, account, amount, web3, assetAddress, closeModal, dispatch) => {
  try {
    const notificationText = `Withdraw ${Number(amount).toFixed(2)} USD from Insurance Fund`;
    amount = toBn(amount, 18).toString();
    const txNonce = await getNonce(web3, account);
    // await checkAndApprove(web3, assetAddress, account, insuranceFundContract, amount);
    const contractMethod = insuranceFundContract.methods.withdrawToken(amount);
    const limitGas = Math.round((await contractMethod.estimateGas({ from: account })) * 1.2); // 20% extra gas
    console.log("Estimated gas: " + limitGas);
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    let isConfirmed = false;
    await contractMethod
      .send({ from: account, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, account, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, account, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, account, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, account, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    console.log(e);
    onError(e, "Error while withdrawing insurance funds!", dispatch);
  }
};
