import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Form, Input, FormGroup } from "reactstrap";
import {
  cursorRangeCheck,
  defaultInputValue,
  formatValue,
  inputDecimals,
  isHex,
  isPositiveNumber,
  isSmallDifference,
  removeCommaLocales,
  replaceCharacter
} from "../../utils/utils.js";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import MyModal from "./utilities/MyModal.jsx";
import { useDispatch, useSelector } from "react-redux";
import { web3AccountSelector, web3Selector } from "../../store/selectors/web3Selectors";
import { fromBn, toBn } from "evm-bn";
import MyTooltip2 from "./MyTooltip2.jsx";
import ReactGA from "react-ga4";
import MyTooltip from "./MyTooltip.jsx";
import { insuranceFundLoadedSelector, insurerBalanceWithUnclaimedSelector } from "../../store/selectors/insuranceFundSelector.js";
import { insuranceFundSelector } from "../../store/selectors/contractsSelectors.js";
import { withdrawToken } from "../../store/interactions/insuranceFundInteractions.js";

let lastSelectedInputTimestamp = null;
let lastClickedModalOverlay = null;

const WithdrawInsuranceFundsModal = props => {
  const dispatch = useDispatch();

  const [inputClass, setInputClass] = useState("has-success");
  const [withdrawInputValue, setWithdrawInputValue] = useState("1");
  const [withdrawAmount, setWithdrawAmount] = useState(fromBn(toBn("1", 18)));
  const [withdrawInputErrorMessage, setWithdrawInputErrorMessage] = useState("");

  const [tooltipIsVisible, setTooltipIsVisible] = useState(false);
  const [tooltipTarget, setTooltipTarget] = useState("");
  const [tooltipContent, setTooltipContent] = useState("");

  const web3 = useSelector(web3Selector);
  const web3Account = useSelector(web3AccountSelector);
  const insuranceFundLoaded = useSelector(insuranceFundLoadedSelector);
  const insurerBalanceWithUnclaimed = useSelector(insurerBalanceWithUnclaimedSelector);
  const insuranceFundContract = useSelector(insuranceFundSelector);

  const withdrawModalInputRef = useRef(null);
  const withdrawButtonRef = useRef(null);
  const idPrefix = "withdraw-insurance-funds-modal-";

  useEffect(() => {
    const modalDialog = document.querySelector(".modal-dialog");

    if (modalDialog) {
      $(".modal-black").draggable({
        handle: ".modal-header"
      });
    }

    withdrawModalInputRef.current.addEventListener("paste", paste);
    withdrawModalInputRef.current.select();

    const chunk = "withdraw-insurance-funds-modal";
    console.log("Navigate to: " + window.location.pathname + "/" + chunk); // eslint-disable-line no-console
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + "/" + chunk, title: "Withdraw Insurance Funds Modal" });

    onInputChange();

    return () => {
      document.onkeydown = null;
    };
  }, []);

  useEffect(() => {
    document.onkeydown = onKeyPress;
  }, [withdrawInputValue]);

  const paste = e => {
    e.stopPropagation();
    e.preventDefault();

    const selection = window.getSelection().toString();

    const clipboardData = e.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData("Text");
    pastedData = removeCommaLocales(pastedData);
    let newPastedData;
    if (selection !== "") {
      newPastedData = inputDecimals(
        replaceCharacter(
          withdrawModalInputRef.current.value,
          withdrawModalInputRef.current.selectionStart,
          withdrawModalInputRef.current.selectionEnd - 1,
          pastedData
        ),
        props.token.symbol
      );
    } else {
      newPastedData = inputDecimals(withdrawModalInputRef.current.value + pastedData, props.token.symbol);
    }
    if (newPastedData !== undefined && newPastedData !== null && !isHex(newPastedData)) {
      if (selection !== "") {
        withdrawModalInputRef.current.value = replaceCharacter(
          withdrawModalInputRef.current.value,
          withdrawModalInputRef.current.selectionStart,
          withdrawModalInputRef.current.selectionEnd - 1,
          pastedData
        );
      } else {
        withdrawModalInputRef.current.value = withdrawModalInputRef.current.value + pastedData;
      }
    }
    onInputChange();
  };

  const onInputChange = () => {
    //TODO: try/catch block as temporary prevention for large number entered in input because when using toBn it will crash
    try {
      const inputString = withdrawModalInputRef.current.value.trim();

      const value = inputDecimals(inputString, props.token.symbol);
      if (value === undefined || value === null) {
        return;
      }

      setWithdrawInputValue(inputString);

      if (value === "") {
        setInputClass("");
        return;
      }

      if (!isPositiveNumber(value)) {
        setInputClass("has-danger");
        setWithdrawInputErrorMessage("Enter a positive number");
        return;
      }

      let amountSplit = value.split(".");
      const newAmountSplit = amountSplit.length === 1 || amountSplit[1] === "" ? amountSplit[0] : amountSplit[0] + "." + amountSplit[1];
      let newAmount = fromBn(toBn(newAmountSplit.toString(), 18));

      if (Number(value) > Number(insurerBalanceWithUnclaimed) && !isSmallDifference(Number(value), insurerBalanceWithUnclaimed, props.token.symbol)) {
        setInputClass("has-danger");
        setWithdrawInputErrorMessage(
          "Can't withdraw more than " + formatValue(insurerBalanceWithUnclaimed, 2) + ". Click max button to withdraw maximum allowed amount."
        );
        return;
      }

      const { max } = calculateMax();

      if (isSmallDifference(max, newAmount, props.token.symbol)) {
        setWithdrawAmount(max);
      } else {
        setWithdrawAmount(newAmount);
      }
      setInputClass("has-success");
    } catch (e) {
      console.log(e);
    }
  };

  const increaseWithdrawAmount = () => {
    if (withdrawInputValue === "") {
      setWithdrawInputValue(1);
      withdrawModalInputRef.current.value = "1";
      onInputChange();
      return;
    }

    if (Math.abs(withdrawInputValue - props.token.normalizedAccountBalance) >= 1) {
      //if it is not max value
      if (withdrawModalInputRef.current.value.split(".").length > 1) {
        setWithdrawInputValue(Math.ceil(withdrawInputValue));
        withdrawModalInputRef.current.value = Math.ceil(withdrawInputValue);
        onInputChange();
      } else {
        setWithdrawInputValue(withdrawInputValue + 1);
        withdrawModalInputRef.current.value = Number(withdrawInputValue) + 1;
        onInputChange();
      }
    }
  };

  const decreaseWithdrawAmount = () => {
    if (withdrawInputValue !== "" && withdrawInputValue != 1 && withdrawInputValue != 0) {
      if (withdrawModalInputRef.current.value.split(".").length > 1) {
        setWithdrawInputValue(Math.floor(withdrawInputValue));
        withdrawModalInputRef.current.value = Math.floor(withdrawInputValue);
        onInputChange();
      } else {
        setWithdrawInputValue(Number(withdrawInputValue) - 1);
        withdrawModalInputRef.current.value = Number(withdrawInputValue) - 1;
        onInputChange();
      }
    }
  };

  const clearInput = () => {
    withdrawModalInputRef.current.focus();
    setInputClass("");
    setWithdrawInputValue("");
  };

  const calculateMax = () => {
    const max = insurerBalanceWithUnclaimed.toString();
    const maxInputValue = insurerBalanceWithUnclaimed.toFixed(2);
    return { max, maxInputValue };
  };

  const onMaxButtonClicked = () => {
    const { max, maxInputValue } = calculateMax();
    if (max > 0) {
      setWithdrawInputValue(maxInputValue);
      setWithdrawAmount(max);
      setInputClass("has-success");
      setWithdrawInputErrorMessage("");
    }
  };

  const onKeyPress = e => {
    if (e && e.key === "Escape") {
      props.toggle();
    }
  };

  const onMouseEnterColumnHeader = (e, text) => {
    if (!tooltipIsVisible) {
      setTooltipIsVisible(true);
      setTooltipContent(text);
      setTooltipTarget(e.currentTarget.id);
    }
  };

  const onMouseLeaveColumnHeader = e => {
    if (!cursorRangeCheck(tooltipTarget, e.clientX, e.clientY)) {
      setTooltipIsVisible(false);
      setTooltipContent("");
      setTooltipTarget("");
      document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", -500 + "px");

      document.querySelector(":root").style.setProperty("--tooltipTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipLeftPosition", -500 + "px");
    }
  };

  const calculateInsuranceFundsBalance = (value, shouldSetStates) => {
    let amount = insuranceFundLoaded && insurerBalanceWithUnclaimed;
    let newAmount = Number(amount) - Number(value);
    return newAmount;
  };

  const calculateWalletBalance = value => {
    let amount = props.token && fromBn(props.token.walletBalance18Decimals);
    return Number(amount) + Number(value);
  };

  const onWithdrawClickHandler = e => {
    e.stopPropagation();
    const assetAddress = props.token.address;
    withdrawToken(insuranceFundContract, web3Account, withdrawAmount, web3, assetAddress, props.toggle, dispatch);
  };

  let printableAccountBalance;
  let printableWalletBalance;
  const max = calculateMax();
  if (withdrawInputValue && inputClass !== "has-danger") {
    printableAccountBalance = calculateInsuranceFundsBalance(withdrawInputValue, true);
    printableWalletBalance = calculateWalletBalance(withdrawInputValue);
  } else if (withdrawInputValue === "") {
    calculateInsuranceFundsBalance(withdrawInputValue, true);
    calculateWalletBalance(withdrawInputValue);
    printableAccountBalance = calculateInsuranceFundsBalance(String(defaultInputValue), false);
    printableWalletBalance = calculateWalletBalance(String(defaultInputValue));
  } else if (inputClass === "has-danger") {
    calculateInsuranceFundsBalance(withdrawInputValue, true);
    calculateWalletBalance(withdrawInputValue);
    printableAccountBalance = calculateInsuranceFundsBalance(String(max), false);
    printableWalletBalance = calculateWalletBalance(String(max));
  } else {
    printableAccountBalance = props.token.printableAccountBalance;
    printableWalletBalance = props.token.printableWalletBalance;
  }

  const shouldBeDisabled = withdrawInputValue === "" || inputClass !== "has-success";

  return (
    <MyModal
      isOpen={props.isOpen}
      toggle={e => {
        lastClickedModalOverlay = Date.now();
        const diff = lastClickedModalOverlay - lastSelectedInputTimestamp;
        if (diff < 500) {
          lastClickedModalOverlay = null;
          lastSelectedInputTimestamp = null;
          return;
        }
        props.toggle();
      }}
    >
      <div
        className="depositWithdrawModalFrame"
        onClick={e => {
          e.stopPropagation();
        }}
        onMouseDown={e => {
          lastSelectedInputTimestamp = Date.now();
        }}
      >
        <div className="headerOfPortfolioModal">
          <span className="portfolioModalHeaderText">Withdraw USDC</span>
          <button aria-hidden data-dismiss="modal" type="button" className="closeModal" onClick={() => props.toggle(true)}>
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>

        <div className="inputAmountAndSettingsErrorMessageContainer">
          <div className="inputAmountAndSettingsContainer">
            <Form>
              <FormGroup>
                <Input
                  id="withdrawModalInput"
                  type="text"
                  autoComplete="off"
                  placeholder="Withdraw amount, decimals allowed... (e.g., 100.50)"
                  className={`inputText${inputClass === "has-danger" ? " inputDanger" : ""}${inputClass === "has-success" ? " inputSuccess" : ""}`}
                  onChange={e => {
                    onInputChange();
                  }}
                  value={withdrawInputValue}
                  autoFocus
                  innerRef={withdrawModalInputRef}
                />
                {inputClass === "has-danger" ? <img className="clearInputError" onClick={clearInput} /> : null}
                {inputClass === "has-success" ? <img className="validInput" /> : null}
                <div className="increaseDecreaseInputButtons">
                  <img className="increaseInputButton" onClick={increaseWithdrawAmount} />
                  <img className="decreaseInputButton" onClick={decreaseWithdrawAmount} />
                </div>
              </FormGroup>
            </Form>
            <button
              id="withdrawModalMaxButton"
              className="maxButton"
              onClick={onMaxButtonClicked}
              onMouseEnter={e => onMouseEnterColumnHeader(e, "Max amount to withdraw to wallet.")}
              onMouseLeave={onMouseLeaveColumnHeader}
            >
              max
            </button>
          </div>
          <div className="errorMessageDepositWithdrawLendContainer">
            <span className="errorMessageDepositWithdrawLendSpan">{inputClass === "has-danger" ? withdrawInputErrorMessage : ""}</span>
          </div>
        </div>

        <div className="withdrawDepositDataInformationContainer">
          <div className="withdrawDepositDataInformationItem">
            <span id={idPrefix + "insurance-funds-balance"} className={`portfolioLendSmallLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              Insurance fund balance
            </span>
            <MyTooltip key={idPrefix + "insurance-funds-balance"} target={idPrefix + "insurance-funds-balance"} optionalOffset={15}>
              New insurance fund balance after withdrawing USD.
            </MyTooltip>
            <span className={`portfolioLendBigLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              {props.isPositionToken && props.token.type === "short" && printableAccountBalance !== "0.00"
                ? "-" + formatValue(printableAccountBalance, 2)
                : formatValue(printableAccountBalance, 2) + " " + "USDC"}
            </span>
          </div>
          <div className="withdrawDepositDataInformationItem">
            <span id={idPrefix + "wallet-balance"} className={`portfolioLendSmallLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              Wallet balance
            </span>
            <MyTooltip key={idPrefix + "wallet-balance"} target={idPrefix + "wallet-balance"} optionalOffset={15}>
              New wallet balance after withdrawing USD.
            </MyTooltip>
            <span className={`portfolioLendBigLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              {props.isPositionToken && props.token.type === "short" && printableWalletBalance !== "0.00"
                ? "-" + formatValue(printableWalletBalance, 2)
                : formatValue(printableWalletBalance, 2) + " " + "USDC"}
            </span>
          </div>
        </div>
        <button id="withdrawButton" className="withdrawDepositButton" onClick={onWithdrawClickHandler} disabled={shouldBeDisabled} ref={withdrawButtonRef}>
          withdraw
        </button>
      </div>
      {tooltipIsVisible && (
        <MyTooltip2 target={tooltipTarget} setTooltipIsVisible={setTooltipIsVisible} setTooltipContent={setTooltipContent} setTooltipTarget={setTooltipTarget}>
          {tooltipContent}
        </MyTooltip2>
      )}
    </MyModal>
  );
};

WithdrawInsuranceFundsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  token: PropTypes.object.isRequired,
  isPositionToken: PropTypes.bool
};

export default WithdrawInsuranceFundsModal;
