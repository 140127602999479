/* eslint-disable react/no-multi-comp */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReactTable from "react-table";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { web3AccountLoadedSelector } from "../../store/selectors/web3Selectors.js";
import ConnectPanel from "./ConnectPanel.jsx";
import { printEmptyTable, printLoadingIcon } from "./utilities/TradePanels/PrintUtility.jsx";
import { cursorRangeCheck } from "../../utils/utils.js";
import { FilterContext } from "../../layouts/market/MainRouter.jsx";
import MyTooltip2 from "./MyTooltip2.jsx";
import { insuranceFundLossesCoveredLoadedSelector, insuranceFundLossesCoveredSelector } from "../../store/selectors/insuranceFundSelector.js";

const EMPTY_TABLE_TEXT = "No losses were covered by Insurance Fund.";
const CARD_TITLE = "COVERED LOSSES HISTORY";

const TOOLTIPS_TEXT = {
  lossesCoveredHistoryToken: "Name of the token.",
  lossesCoveredHistoryType: "Type of liquidation when loss was realized - can be Account liquidation or LP position liquidation.",
  lossesCoveredHistorySize: "Size of the covered loss in tokens.",
  lossesCoveredHistoryValue: "Value in $ USD of the loss covered.",
  lossesCoveredHistoryTimestamp: "Date and time of liquidation when loss was covered."
};

const LossesCoveredHistoryPanel = () => {
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false);
  const [tooltipTarget, setTooltipTarget] = useState("");
  const [tooltipContent, setTooltipContent] = useState("");

  const web3AccountLoaded = useSelector(web3AccountLoadedSelector);

  const insuranceFundLossesCoveredLoaded = useSelector(insuranceFundLossesCoveredLoadedSelector);
  const insuranceFundLossesCovered = useSelector(insuranceFundLossesCoveredSelector);
  const idPrefix = "covered-losses-history-panel-"; // todo: why isn't this used?

  const lossesCoveredLoaded = () => {
    return !!insuranceFundLossesCoveredLoaded;
  };

  const getTableData = () => {
    if (web3AccountLoaded && lossesCoveredLoaded) {
      let sortableLossesCovered = [...insuranceFundLossesCovered];
      sortableLossesCovered.sort((a, b) => {
        return parseInt(b.timestamp) - parseInt(a.timestamp);
      });

      return sortableLossesCovered.map(dw => {
        return {
          token: dw.tokenSymbol === "USD" ? "USDC" : dw.tokenSymbol,
          type: dw.isAccountLiquidation ? "ACCOUNT" : "LP POSITION",
          qty: dw.printableAmount,
          value: dw.printableValue,
          timestamp: dw.readableTimestamp
        };
      });
    }

    return [];
  };

  const onMouseEnterColumnHeader = e => {
    if (!tooltipIsVisible) {
      setTooltipIsVisible(true);
      setTooltipContent(TOOLTIPS_TEXT[e.currentTarget.getAttribute("name")]);
      setTooltipTarget(e.currentTarget.id);
    }
  };

  const onMouseLeaveColumnHeader = e => {
    if (!cursorRangeCheck(tooltipTarget, e.clientX, e.clientY)) {
      setTooltipIsVisible(false);
      setTooltipContent("");
      setTooltipTarget("");
      document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", -500 + "px");

      document.querySelector(":root").style.setProperty("--tooltipTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipLeftPosition", -500 + "px");
    }
  };

  const getTableColumns = () => {
    const tableColumns = [
      {
        Header: () => (
          <div className="alignLeft">
            <span
              id="losses-covered-history-token"
              name="lossesCoveredHistoryToken"
              onMouseEnter={onMouseEnterColumnHeader}
              onMouseLeave={onMouseLeaveColumnHeader}
            >
              Token
            </span>
          </div>
        ),
        accessor: "token",
        align: "right",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: false,
        Cell: row => {
          return (
            <>
              <span className="alignLeft">{row.value}</span>
            </>
          );
        }
      },
      {
        Header: () => (
          <div>
            <span
              id="losses-covered-history-type"
              name="lossesCoveredHistoryType"
              onMouseEnter={onMouseEnterColumnHeader}
              onMouseLeave={onMouseLeaveColumnHeader}
            >
              Liquidation Type
            </span>
          </div>
        ),
        accessor: "type",
        align: "right",
        minWidth: 100,
        maxWidth: 5 * 100,
        filterable: false,
        sortable: false,
        Cell: row => <span style={{ float: "right" }}>{row.value}</span>
      },
      {
        Header: () => (
          <div>
            <span
              id="losses-covered-history-size"
              name="lossesCoveredHistorySize"
              onMouseEnter={onMouseEnterColumnHeader}
              onMouseLeave={onMouseLeaveColumnHeader}
            >
              QTY
            </span>
          </div>
        ),
        accessor: "qty",
        minWidth: 30,
        maxWidth: 5 * 30,
        filterable: false,
        sortable: false,
        Cell: row => (
          <span className="monospace" style={{ float: "right" }}>
            {row.value}
          </span>
        )
      },
      {
        Header: () => (
          <div>
            <span
              id="losses-covered-history-value"
              name="lossesCoveredHistoryValue"
              onMouseEnter={onMouseEnterColumnHeader}
              onMouseLeave={onMouseLeaveColumnHeader}
            >
              Value
            </span>
          </div>
        ),
        accessor: "value",
        minWidth: 40,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        Cell: row => (
          <span className="monospace" style={{ float: "right" }}>
            {"$" + row.value}
          </span>
        ),
        sortMethod: (a, b) => {
          return b[0] - a[0];
        }
      },
      {
        Header: () => (
          <div>
            <span
              id="losses-covered-history-timestamp"
              name="lossesCoveredHistoryTimestamp"
              onMouseEnter={onMouseEnterColumnHeader}
              onMouseLeave={onMouseLeaveColumnHeader}
            >
              Timestamp
            </span>
          </div>
        ),
        accessor: "timestamp",
        minWidth: 40,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        Cell: row => <span style={{ float: "right" }}>{row.value}</span>,
        sortMethod: (a, b) => {
          return b[1] - a[1];
        }
      }
    ];
    return tableColumns;
  };

  const printTable = () => {
    if (web3AccountLoaded && lossesCoveredLoaded() && getTableData().length > 0) {
      return (
        <div>
          <ReactTable
            className="-highlight"
            data={getTableData()}
            //filterable
            columns={getTableColumns()}
            defaultPageSize={5}
          />
        </div>
      );
    }
  };

  return (
    <>
      <FilterContext.Consumer>
        {({ closeAllFilterDropmenus }) => {
          return (
            <div className="content" style={{ paddingTop: "10px" }}>
              <Row>
                <Col className="mb-1" md="12">
                  <Card>
                    <CardHeader className="handle">
                      <CardTitle tag="h4">
                        <div className="historyHeaderContainer">
                          <span className="headerTitle">{CARD_TITLE}</span>
                        </div>
                      </CardTitle>
                    </CardHeader>
                    <CardBody id={"php"}>
                      {printTable()}
                      {printEmptyTable(web3AccountLoaded, lossesCoveredLoaded(), getTableData(), EMPTY_TABLE_TEXT)}
                      {printLoadingIcon(web3AccountLoaded, lossesCoveredLoaded())}
                      <ConnectPanel />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          );
        }}
      </FilterContext.Consumer>
      {tooltipIsVisible && (
        <MyTooltip2 target={tooltipTarget} setTooltipIsVisible={setTooltipIsVisible} setTooltipContent={setTooltipContent} setTooltipTarget={setTooltipTarget}>
          {tooltipContent}
        </MyTooltip2>
      )}
    </>
  );
};

export default LossesCoveredHistoryPanel;
