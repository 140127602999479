/* eslint-disable react/no-multi-comp */
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Col from "reactstrap/lib/Col";
import { tokenPairSelected } from "../../store/actions/actions.js";
import { tokenPairsListSelector, tokenPairsLoadedSelector } from "../../store/selectors/tokensSelectors";
import SettlePanel from "./SettlePanel.jsx";
import ReactGA from "react-ga4";
import { Card, CardBody, CardHeader } from "reactstrap";
import ReactTable from "react-table";
import { web3AccountLoadedSelector } from "../../store/selectors/web3Selectors";
import { marketsLoadedSelector, marketsSelector } from "../../store/selectors/marketsSelectors";
import { groupBy } from "lodash";
import { settlementDataSelector } from "../../store/selectors/balancesSelectors";
import { formatUnits } from "@ethersproject/units";
import { displayLoadingIcon } from "../../utils/utils.js";
import ConnectPanel from "./ConnectPanel.jsx";
import { printEmptyTable } from "./utilities/TradePanels/PrintUtility.jsx";

const EMPTY_PAST_SETTLEMENTS_TABLE_TEXT = "No past settlements";

const SettlementsPage = () => {
  const dispatch = useDispatch();

  const tokenPairsLoaded = useSelector(tokenPairsLoadedSelector);
  const tokenPairsList = useSelector(tokenPairsListSelector);
  const web3AccountLoaded = useSelector(web3AccountLoadedSelector);
  const markets = useSelector(marketsSelector);
  const marketsLoaded = useSelector(marketsLoadedSelector);
  const settlementData = useSelector(settlementDataSelector);

  useEffect(() => {
    console.log("Navigate to: " + window.location.pathname); // eslint-disable-line no-console
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Settlements" });
  }, []);

  useEffect(() => {
    if (tokenPairsLoaded) {
      dispatch(tokenPairSelected(tokenPairsList[0]));
    }
  }, [tokenPairsLoaded, tokenPairsList]);

  const getTableColumns = () => {
    const tableColumns = [
      {
        Header: () => (
          <div className="settlementColumnHeaderWrapper">
            <span style={{ textAlign: "right" }}>TOKEN PAIR</span>
          </div>
        ),
        accessor: "tokenPair",
        // minWidth: 30,
        filterable: false,
        sortable: false,
        Cell: row => (
          <div className="settlementColumnHeaderWrapper">
            <span style={{ float: "right" }}>{row.value}</span>
          </div>
        )
      },
      {
        Header: () => (
          <div className="settlementColumnHeaderWrapper">
            <span style={{ textAlign: "right" }}>EXPIRATION TIME</span>
          </div>
        ),
        accessor: "expirationTime",
        // minWidth: 30,
        filterable: false,
        sortable: false,
        Cell: row => (
          <div className="settlementColumnHeaderWrapper">
            <span style={{ float: "right" }}>{row.value}</span>
          </div>
        )
      },
      {
        Header: () => (
          <div className="settlementColumnHeaderWrapper">
            <span style={{ textAlign: "right" }}>SETTLEMENT TIME</span>
          </div>
        ),
        accessor: "settlementTime",
        // minWidth: 30,
        filterable: false,
        sortable: false,
        Cell: row => (
          <div className="settlementColumnHeaderWrapper">
            <span style={{ float: "right" }}>{row.value}</span>
          </div>
        )
      },
      {
        Header: () => (
          <div className="settlementColumnHeaderWrapper">
            <span style={{ float: "right" }}>SETTLEMENT PRICE</span>
          </div>
        ),
        accessor: "settlementPrice",
        align: "center",
        // minWidth: 45,
        // maxWidth: 200,
        filterable: false,
        sortable: false,
        Cell: row => (
          <div className="settlementColumnHeaderWrapper">
            <span style={{ float: "right" }}>{row.value}</span>
          </div>
        )
      },
      {
        Header: () => (
          <div className="settlementColumnHeaderWrapper">
            <span style={{ float: "right" }}>NUMBER OF MARKETS</span>
          </div>
        ),
        accessor: "numberOfMarkets",
        // minWidth: 30,
        filterable: false,
        sortable: false,
        Cell: row => (
          <div className="settlementColumnHeaderWrapper">
            <span style={{ float: "right" }}>{row.value}</span>
          </div>
        )
      }
    ];
    return tableColumns;
  };

  const settleMarketsData = useMemo(() => {
    const settledMarkets = markets.filter(market => market.isSettled);
    const groupByExpTime = groupBy(settledMarkets, "expirationTime");
    const keys = Object.keys(groupByExpTime)
      .sort()
      .reverse();
    const data = [];
    for (let key of keys) {
      const currentElements = groupByExpTime[key];
      const tokenPair = "ETH-USD";
      const foundSettlement = settlementData.find(sd => {
        return (
          sd.baseTokenAddress === currentElements[0].baseTokenAddress &&
          sd.underTokenAddress === currentElements[0].underlyingTokenAddress &&
          sd.expirationTime == key
        );
      });
      if (foundSettlement) {
        const formattedExpirationTime = getDateFormattedFromTimestamp(parseInt(foundSettlement.expirationTime));
        const formattedSettlementTime = getDateFormattedFromTimestamp(parseInt(foundSettlement.settlementTime));

        data.push({
          tokenPair,
          expirationTime: formattedExpirationTime,
          settlementTime: formattedSettlementTime,
          settlementPrice: "$" + parseFloat(formatUnits(foundSettlement.underlyingPrice.toString(), 18)).toFixed(2),
          numberOfMarkets: groupByExpTime[key].length
        });
      }
    }
    return data;
  }, [markets, settlementData]);

  return (
    <div className="content" style={{ paddingTop: "0px" }}>
      <Col id="settle" className="mb-1 draggable" md="12">
        <SettlePanel />
        <Card>
          <CardHeader className="handle">
            <div className="settleHeaderContainer">
              <span className="headerTitle">PAST SETTLEMENTS</span>
            </div>
          </CardHeader>
          <CardBody>
            <ConnectPanel />
            {marketsLoaded && web3AccountLoaded && settleMarketsData.length > 0 && (
              <ReactTable className="-highlight" data={settleMarketsData} columns={getTableColumns()} defaultPageSize={5} />
            )}
            {web3AccountLoaded && !marketsLoaded && <div className="tableLoaderContainer">{displayLoadingIcon()}</div>}
            {printEmptyTable(web3AccountLoaded, marketsLoaded, settleMarketsData, EMPTY_PAST_SETTLEMENTS_TABLE_TEXT)}
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

export default SettlementsPage;

// todo: move to some utils file
const getDateFormattedFromTimestamp = timestampInSeconds => {
  const date = new Date(timestampInSeconds * 1000);

  const day = Number(date.getDate());
  const month = Number(date.getMonth()) + 1;
  const year = Number(date.getFullYear());

  const hours = Number(date.getHours());
  const minutes = Number(date.getMinutes());

  return (
    (day <= 9 ? "0" + day : day) +
    "-" +
    (month <= 9 ? "0" + month : month) +
    "-" +
    year +
    " " +
    (hours <= 9 ? "0" + hours : hours) +
    ":" +
    (minutes <= 9 ? "0" + minutes : minutes)
  );
};
