import { get } from "lodash";
import { createSelector } from "reselect";
import { getMarkets, getMarketsHistoricalData, isMarketsLoaded } from "../utils/marketsUtils";
import { lpTrades, tradesLoaded, lpTradesLoaded, trades } from "./positionsManagerSelectorsData";

export const marketsImmutableAttributesLoaded = state => get(state, "marketsImmutable.loaded", false);
export const marketsImmutableAttributesLoadedSelector = createSelector(marketsImmutableAttributesLoaded, loaded => loaded);

export const marketsImmutableAttributes = state => get(state, "marketsImmutable.data", []);

export const marketsMutableAttributesLoaded = state => get(state, "marketsMutable.loaded", false);
export const marketsLoadedSelector = createSelector([marketsMutableAttributesLoaded, marketsImmutableAttributesLoaded], isMarketsLoaded);

export const marketsMutableAttributes = state => get(state, "marketsMutable.data", []);
export const marketsSelector = createSelector(
  [marketsMutableAttributes, marketsImmutableAttributes, marketsMutableAttributesLoaded, marketsImmutableAttributesLoaded],
  getMarkets
);

export const marketsHistoricalDataSelector = createSelector(
  [marketsImmutableAttributes, trades, lpTrades, marketsImmutableAttributesLoaded, tradesLoaded, lpTradesLoaded],
  getMarketsHistoricalData
);

export const returnExpired = () => true;

export const marketsNonExpiredSelector = createSelector(
  [marketsMutableAttributes, marketsImmutableAttributes, marketsMutableAttributesLoaded, marketsImmutableAttributesLoaded, returnExpired],
  getMarkets
);
