import { fromBn, toBn } from "evm-bn";
import PropTypes from "prop-types";
import React, { createRef } from "react";
import { connect } from "react-redux";
import { Form, FormGroup, Input } from "reactstrap";
import {
  buyLong,
  buyShort,
  sellLong,
  sellShort,
  closeShortOpenLongPosition,
  closeLongOpenShortPosition,
  buyLongWithDeltaHedge,
  buyShortWithDeltaHedge
} from "../../store/interactions/positionsManagerInteractions";
import { openedLPPositionsSelector, openedTradePositionsSelector } from "../../store/selectors/positionsManagerSelectors";
import {
  selectedBaseTokenBalancesSelector,
  selectedTokenPairSelector,
  selectedUnderlyingTokenBalancesSelector,
  avgUnderlyingTokenPriceSelector,
  tokenBalancesSelector
} from "../../store/selectors/tokensSelectors";
import { web3AccountSelector, web3ObjectSelector, web3Selector } from "../../store/selectors/web3Selectors";
import {
  debounce,
  defaultInputValue,
  findClosestMarketsToExpirationTime,
  findVolForClosestStrikePrice,
  formatValue,
  getIdFromString,
  getImpliedVolatility,
  getMaxLendAmount,
  healthClassName,
  inputDecimals,
  isHex,
  isPositiveNumber,
  isSmallDifference,
  printPriceFromNumber,
  removeCommaLocales,
  replaceCharacter,
  toPreciseString
} from "../../utils/utils.js";
import PositionsChart from "./charts/PositionsChart.jsx";
import { interpolateGreeks, getChartData, getChartPoint, getHighestDaysToExpiryRounded } from "../../utils/chartsUtils";
import TransactionSettingsModal from "./TransactionSettingsModal.jsx";
import { marketsLoadedSelector, marketsSelector } from "../../store/selectors/marketsSelectors";
import { positionsManagerSelector } from "../../store/selectors/contractsSelectors";
import SlidersPanel from "./SlidersPanel.jsx";
import { marginAccountDataSelector, marginPoolDataSelector, positionsBorrowFactorsSelector } from "../../store/selectors/marginSelectors";
import { getAPR } from "../../store/interactions/marginInteractions.js";
import {
  MIN_OPTION_PRICE,
  getBaseAmountWhenBuyingFutures,
  getBaseAmountWhenBuyingOptions,
  getBaseAmountWhenSellingFutures,
  getBaseAmountWhenSellingOptions,
  getInputAmountWhenBuyingOptionsUpToBaseBalance,
  getMaxLongAmountWhenBuyingFutures,
  getMaxLongAmountWhenBuyingOptions,
  getMaxLongAmountWhenSellingFutures,
  getMaxLongAmountWhenSellingOptions,
  getOraclePriceMaxBuyAmount,
  getOraclePriceMaxSellAmount,
  getPriceWhenBuyingOptions,
  getPriceWhenSellingOptions,
  getVolatilityWhenBuyingOptions,
  getVolatilityWhenSellingOptions
} from "../../utils/MarketHelper";
import CogChartOptions from "./CogChartOptions.jsx";
import { ChartContext } from "../../layouts/market/MainRouter.jsx";
import MyTooltip from "./MyTooltip.jsx";

import LiquidationWorker from "./../../workers/liqWorker.worker.js";
import PositionLiquidationWorker from "./../../workers/positionLiqWorker.worker.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { BigNumber } from "@ethersproject/bignumber";
import { formatUnits } from "@ethersproject/units";
import { settlementDataSelector } from "../../store/selectors/balancesSelectors";

let selectedDaysToExpiryInitialValueForFutureInput = null;
let selectedVolatilityInitialValueForFutureInput = null;

class TradeModalTradeTab extends React.Component {
  constructor(props) {
    super(props);

    !localStorage.getItem("tradeSlippageTolerance") && localStorage.setItem("tradeSlippageTolerance", 1);
    !localStorage.getItem("tradeTransactionTimeout") && localStorage.setItem("tradeTransactionTimeout", 20);

    this.state = {
      buyTabType: "",
      buyTabBaseAmount: 0,
      buyTabCollateralAmount: 0,
      buyTabLongAmount: 0,
      buyTabShortAmount: 0,
      buyTabInputAmount: 1,
      buyTabPriceImpact: 0,
      buyTabHealth: 0,
      buyTabLiqPrices: 0,
      buyTabLPLiqPrices: 0,
      buyTabInputClass: "",
      buyTabInputErrorMessage: "",
      buyTabPreviewPosition: null,
      buyTabInputValue: "1",

      //this is for storring neccesarry values when input value is empty string and then default input value is 0.000001 or when error is click on max and in that case default input value is max value
      buyObjectValues: null,

      buyTabFutureBaseAmount: 0,
      buyTabFutureShortAmount: 0,
      buyTabFutureInputAmount: 0,
      buyTabFuturePriceImpact: 0,
      buyTabFutureLiqPrices: 0,
      buyTabFutureInputClass: "",
      buyTabFutureInputErrorMessage: "",
      buyTabFuturePreviewPosition: null,
      buyTabFutureInputValue: "",

      //this is for storring neccesarry values when input value is empty string and then default input value is 0.000001 or when error is click on max and in that case default input value is max value

      sellTabType: "",
      sellTabBaseAmount: 0,
      sellTabCollateralAmount: 0,
      sellTabLongAmount: 0,
      sellTabShortAmount: 0,
      sellTabInputAmount: 1,
      sellTabPriceImpact: 0,
      sellTabHealth: 0,
      sellTabLiqPrices: 0,
      sellTabLPLiqPrices: 0,
      sellTabInputClass: "",
      sellTabInputErrorMessage: "",
      sellTabPreviewPosition: null,
      sellTabInputValue: "1",

      // this is for storring neccesarry values when input value is empty string and then default input value is 0.000001 or when error is click on max and in that case default input value is max value
      sellObjectValues: null,

      // sellTabType: "",
      sellTabFutureBaseAmount: 0,
      sellTabFutureLongAmount: 0,
      sellTabFutureInputAmount: 0,
      sellTabFuturePriceImpact: 0,
      sellTabFutureHealth: 0,
      sellTabFutureLiqPrices: 0,
      sellTabFutureInputClass: "",
      sellTabFutureInputErrorMessage: "",
      sellTabFuturePreviewPosition: null,
      sellTabFutureInputValue: "1",

      isTransactionSettingsModalOpen: false,
      slippageTolerance: Number(localStorage.getItem("tradeSlippageTolerance")), // percent
      transactionTimeout: Number(localStorage.getItem("tradeTransactionTimeout")), // minutes

      idPrefix: "trading-modal-trade-tab-",

      shouldFocus: true,

      newPositionSelected: true,

      selectedUnderPrice: 1000,
      selectedDaysToExpiry: 1,
      selectedVolatility: 30,

      // chart data
      chartData: {
        minDomainX: 1000,
        maxDomainX: 1600,
        minDomainY: 0,
        maxDomainY: 1,
        series1: [],
        series2: [],
        delta: 0,
        gamma: 0,
        theta: 0,
        vega: 0
      },

      underlyingVolatilitySwitchBuy: false,
      underlyingVolatilitySwitchSell: false,

      standardDeviation: 3,

      futureBuyPanelExpanded: false,
      futureSellPanelExpanded: false,

      futureMarket: null
    };

    this.getEmptyChartData = this.getEmptyChartData.bind(this);
    this.calculateChartData = this.calculateChartData.bind(this);
    this.calculateChartDataCallback = this.calculateChartDataCallback.bind(this);
    this.getLongAndFuturePositionAndChart = this.getLongAndFuturePositionAndChart.bind(this);
    this.getShortAndFuturePositionAndChart = this.getShortAndFuturePositionAndChart.bind(this);
    this.getTradeBalances = this.getTradeBalances.bind(this);
    this.onBuyInputChange = this.onBuyInputChange.bind(this);
    this.onSellInputChange = this.onSellInputChange.bind(this);
    this.setErrorMessage = this.setErrorMessage.bind(this);
    this.onBuyButtonClick = this.onBuyButtonClick.bind(this);
    this.onSellButtonClick = this.onSellButtonClick.bind(this);
    this.onMaxBuyButtonClick = this.onMaxBuyButtonClick.bind(this);
    this.onMaxSellButtonClick = this.onMaxSellButtonClick.bind(this);
    this.displayBigLine = this.displayBigLine.bind(this);
    this.displayPricePerOption = this.displayPricePerOption.bind(this);
    this.displayBuyBalancesTransitions = this.displayBuyBalancesTransitions.bind(this);
    this.displaySellBalancesTransitions = this.displaySellBalancesTransitions.bind(this);
    this.buyLong = this.buyLong.bind(this);
    this.sellLong = this.sellLong.bind(this);
    this.buyShort = this.buyShort.bind(this);
    this.sellShort = this.sellShort.bind(this);
    this.toggleTransactionSettingsModal = this.toggleTransactionSettingsModal.bind(this);
    this.setSlippageTolerance = this.setSlippageTolerance.bind(this);
    this.setTransactionTimeout = this.setTransactionTimeout.bind(this);

    this.clearInput = this.clearInput.bind(this);
    this.onAllNewSwitchChange = this.onAllNewSwitchChange.bind(this);
    this.selectXCallback = this.selectXCallback.bind(this);
    this.deselectXCallback = this.deselectXCallback.bind(this);
    this.onStandardDeviationChange = this.onStandardDeviationChange.bind(this);

    this.chartRef = createRef();
    this.buyInputRef = createRef();
    this.sellInputRef = createRef();
    this.tradeModalBuyButtonRef = createRef();
    this.tradeModalSellButtonRef = createRef();

    this.buyFutureInputRef = createRef();
    this.sellFutureInputRef = createRef();
  }

  componentDidMount() {
    // account liquidation worker
    this.liquidationWorker = new LiquidationWorker();
    this.liquidationWorker.onmessage = e => {
      const liqPrices = e.data;
      this.setState({ buyTabLiqPrices: liqPrices, sellTabLiqPrices: liqPrices });
    };

    // position liquidation worker
    this.positionLiquidationWorker = new PositionLiquidationWorker();
    this.positionLiquidationWorker.onmessage = e => {
      const lpLiqPrices = e.data;
      this.setState({
        buyTabLPLiqPrices: lpLiqPrices,
        sellTabLPLiqPrices: lpLiqPrices
      });
    };

    this.initStateAndInputs();

    if (this.props.tabs === "buy" && !this.props.needsToBeClosed) {
      const isFuture = this.props.market.isFuture;
      const maxBuyAmount = isFuture ? this.getMaxForBuyFutures() : this.getMaxForBuyOptions();
      if (this.buyInputRef.current) {
        this.buyInputRef.current.select();
        this.buyInputRef.current.value = parseFloat(maxBuyAmount) < 1 ? maxBuyAmount : "1";
        this.onBuyInputChange();
      }
    } else if (this.props.tabs === "sell" && !this.props.needsToBeClosed) {
      const isFuture = this.props.market.isFuture;
      const maxSellAmount = isFuture ? this.getMaxForSellFutures() : this.getMaxForSellOptions();
      this.sellInputRef.current.select();
      this.sellInputRef.current.value = parseFloat(maxSellAmount) < 1 ? maxSellAmount : "1";

      this.onSellInputChange();
    }

    // keyboard events
    document.onkeydown = this.onKeyPress;

    // add paste event listener to buy and sell input
    if (this.buyInputRef.current) {
      this.buyInputRef.current.select();
      this.buyInputRef.current.addEventListener("paste", e => {
        this.paste(e, this.buyInputRef.current);
        this.onBuyInputChange();
      });
    }
    if (this.sellInputRef.current) {
      this.sellInputRef.current.addEventListener("paste", e => {
        this.paste(e, this.sellInputRef.current);
        this.onSellInputChange();
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldRender = true;
    if (nextProps.markets !== this.props.markets) {
      const currentMarket = this.props.market;
      const nextMarket = nextProps.markets.find(market => market.marketId === currentMarket.marketId);
      if (nextMarket && nextMarket.openLongPrice === currentMarket.openLongPrice && nextMarket.closeLongPrice === currentMarket.closeLongPrice) {
        shouldRender = false;
      }
    }
    if (nextProps.web3Object !== this.props.web3Object) {
      if (nextProps.web3Object.currentTimestamp === this.props.web3Object.currentTimestamp) {
        shouldRender = false;
      }
    }
    return shouldRender;
  }

  componentDidUpdate(prevProps, prevState) {
    // set add/remove button width
    const greeksTable = document.querySelector(".greeksTable");
    if (greeksTable) {
      const { width } = greeksTable.getBoundingClientRect();
      if (this.tradeModalBuyButtonRef.current) {
        this.tradeModalBuyButtonRef.current.style.width = width + "px";
      } else if (this.tradeModalSellButtonRef.current) {
        this.tradeModalSellButtonRef.current.style.width = width + "px";
      }
    }

    // focus one time
    if (this.buyInputRef.current && this.state.shouldFocus) {
      this.buyInputRef.current.focus();
      this.buyInputRef.current.select();
      this.setState({ shouldFocus: false });
    }

    // if switched to buy tab
    if (this.props.tabs === "buy" && prevProps.tabs !== "buy" && !this.props.needsToBeClosed && this.buyInputRef.current) {
      const isFuture = this.props.market.isFuture;
      const maxBuyAmount = isFuture ? this.getMaxForBuyFutures() : this.getMaxForBuyOptions();
      this.buyInputRef.current.value = parseFloat(maxBuyAmount) < 1 ? maxBuyAmount : "1";
      this.buyInputRef.current.select();
      this.onBuyInputChange();
    }

    // if switched to sell tab
    if (this.props.tabs === "sell" && prevProps.tabs !== "sell" && !this.props.needsToBeClosed && this.sellInputRef.current) {
      const isFuture = this.props.market.isFuture;
      const maxSellAmount = isFuture ? this.getMaxForSellFutures() : this.getMaxForSellOptions();
      this.sellInputRef.current.value = parseFloat(maxSellAmount) < 1 ? maxSellAmount : "1";
      this.sellInputRef.current.select();
      this.onSellInputChange();
    }

    // if closing long position, set sell input to position size
    // TODO: looks like it's not needed as it's called in initStateAndInputs, remove later
    if (this.props.isLong && this.props.needsToBeClosed && this.sellInputRef.current) {
      this.sellInputRef.current.value = this.props.size.toString();
      this.sellInputRef.current.select();
      this.props.setNeedsToBeClosed(null);
    }

    // if closing short position, set buy input to position size
    // TODO: looks like it's not needed as it's called in initStateAndInputs, remove later
    if (this.props.isLong !== null && !this.props.isLong && this.props.needsToBeClosed && this.buyInputRef.current) {
      this.buyInputRef.current.value = this.props.size.toString();
      this.buyInputRef.current.select();
      this.props.setNeedsToBeClosed(null); // todo: v2 why is this called
    }

    // if balances updated or new position exists, update numbers
    if (
      this.props.selectedBaseTokenBalances.accountBalance !== prevProps.selectedBaseTokenBalances.accountBalance ||
      this.props.selectedUnderlyingTokenBalances.accountBalance !== prevProps.selectedUnderlyingTokenBalances.accountBalance ||
      this.props.openedTradePositions !== prevProps.openedTradePositions
    ) {
      if (this.props.tabs === "buy" && this.buyInputRef.current) {
        this.onBuyInputChange();
      } else if (this.props.tabs === "sell" && this.sellInputRef.current) {
        this.onSellInputChange();
      }
    }

    // if switched from sell to buy tab
    // todo: v1 merge with code at the begining of componentDidUpdate
    if (prevProps.tabs === "sell" && this.props.tabs === "buy" && this.buyInputRef.current) {
      this.buyInputRef.current.select();
      this.onBuyInputChange();
    }

    // if switched from buy to sell tab
    if (prevProps.tabs === "buy" && this.props.tabs === "sell" && this.sellInputRef.current) {
      console.log("calling again, should not do that");
      this.sellInputRef.current.select();
      this.onSellInputChange();
    }

    if (this.state.selectedUnderPrice === null) {
      this.setState({
        selectedUnderPrice: parseFloat(this.props.underlyingTokenPrice.normalized)
      });
    }

    document.onkeydown = this.onKeyPress;

    if (
      this.props.tabs === "buy" &&
      (this.state.buyTabInputValue !== prevState.buyTabInputValue || this.state.buyTabInputErrorMessage !== prevState.buyTabInputErrorMessage)
    ) {
      const shouldBePrintedDefault = this.state.buyTabInputValue === "" || this.state.buyTabInputErrorMessage.trim().toLowerCase() !== "";
      if (shouldBePrintedDefault) {
        const isFuture = this.props.market.isFuture;
        const maxBuyAmount = isFuture ? this.getMaxForBuyFutures() : this.getMaxForBuyOptions();

        let inputValue = this.state.buyTabInputValue === "" || Number(this.state.buyTabInputValue) == 0 ? defaultInputValue : maxBuyAmount;
        inputValue == "0.00" && (inputValue = defaultInputValue);
        const buyObjectRetval = this.calculateForBuyInput(inputValue);
        this.setState({ buyObjectValues: { ...buyObjectRetval } });
      }
    }

    if (
      this.props.tabs === "sell" &&
      (this.state.sellTabInputValue !== prevState.sellTabInputValue || this.state.sellTabInputErrorMessage !== prevState.sellTabInputErrorMessage)
    ) {
      const shouldBePrintedDefault = this.state.sellTabInputValue === "" || this.state.sellTabInputErrorMessage.trim().toLowerCase() !== "";
      if (shouldBePrintedDefault) {
        const isFuture = this.props.market.isFuture;
        const maxSellAmount = isFuture ? this.getMaxForSellFutures() : this.getMaxForSellOptions();
        let inputValue = this.state.sellTabInputValue === "" || Number(this.state.sellTabInputValue) == 0 ? defaultInputValue : maxSellAmount;
        inputValue == "0.00" && (inputValue = defaultInputValue);
        const sellObjectRetval = this.calculateForSellInput(inputValue);
        this.setState({ sellObjectValues: { ...sellObjectRetval } });
      }
    }
  }

  componentWillUnmount() {
    document.onkeydown = null;
    this.liquidationWorker.terminate();
    this.positionLiquidationWorker.terminate();
  }

  // todo: paste is all over the place, move to utils
  paste = (e, input) => {
    e.stopPropagation();
    e.preventDefault();

    const selection = window.getSelection().toString();

    const clipboardData = e.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData("Text");
    pastedData = removeCommaLocales(pastedData);
    let newPastedData;
    if (selection !== "") {
      newPastedData = inputDecimals(replaceCharacter(input.value, input.selectionStart, input.selectionEnd - 1, pastedData), "USD");
    } else {
      newPastedData = inputDecimals(input.value + pastedData, "USD");
    }
    if (newPastedData !== undefined && newPastedData !== null && !isHex(newPastedData)) {
      if (selection !== "") {
        input.value = replaceCharacter(input.value, input.selectionStart, input.selectionEnd - 1, pastedData);
      } else {
        input.value = input.value + pastedData;
      }
    }
  };

  initStateAndInputs = () => {
    const daysToExpiration = getHighestDaysToExpiryRounded([{ marketId: this.props.market.marketId }], [this.props.market]);
    const selectedUnderPrice = Math.round(parseFloat(this.props.underlyingTokenPrice.normalized) * 100) / 100;
    const isFuture = this.props.market.isFuture;
    let volatility;
    if (isFuture) {
      const closestMarkets = findClosestMarketsToExpirationTime(this.props.markets, this.props.market.expirationTime);

      if (closestMarkets.length > 0) {
        volatility = Math.round(findVolForClosestStrikePrice(closestMarkets, selectedUnderPrice) * 100);
      } else {
        volatility = 50;
      }
    } else {
      const volWhenBuying = getVolatilityWhenBuyingOptions(this.props.market.marketHelper);
      const volWhenSelling = getVolatilityWhenSellingOptions(this.props.market.marketHelper);
      volatility = Math.round(this.props.tabs === "buy" ? volWhenBuying : volWhenSelling);
    }

    // finally, update state and then call validateInputs
    this.setState(
      {
        selectedUnderPrice: selectedUnderPrice,
        selectedDaysToExpiry: daysToExpiration,
        selectedVolatility: volatility
      },
      () => {
        if (!selectedDaysToExpiryInitialValueForFutureInput && !selectedVolatilityInitialValueForFutureInput) {
          selectedDaysToExpiryInitialValueForFutureInput = daysToExpiration;
          selectedVolatilityInitialValueForFutureInput = volatility;
        }
        // once set, validate inputs
        if (this.props.tabs === "buy") {
          this.onBuyInputChange();
        } else if (this.props.tabs === "sell") {
          this.onSellInputChange();
        }
      }
    );
  };

  onKeyPress = e => {
    if (e.key === "Escape") {
      this.props.toggle();
    }
  };

  getLongAndFuturePositionAndChart = (optionAmount, futureAmount, optionPrice, newPositionSelected, selectedVolatility, selectedDeviation) => {
    const longPrice = parseFloat(this.props.market.longPrice);
    const previewPosition = {
      isPreview: true,
      isFuture: this.props.market.isFuture,
      type: "long",
      balance: optionAmount,
      marketId: this.props.market.marketId,
      isCall: this.props.market.isCall,
      strikePrice: this.props.market.normalizedStrikePrice,
      openBasePrice: optionPrice,
      midLongPrice: longPrice
    };

    const positions = [previewPosition];

    if (this.props.futureMarket) {
      const previewFuturePosition = {
        isPreview: true,
        isFuture: true,
        type: this.props.market.isCall ? "short" : "long",
        balance: futureAmount,
        marketId: this.props.futureMarket.marketId,
        isCall: this.props.futureMarket.isCall,
        strikePrice: this.props.futureMarket.normalizedStrikePrice,
        openBasePrice: parseFloat(this.props.underlyingTokenPrice.normalized),
        midLongPrice: parseFloat(this.props.futureMarket.longPrice)
      };
      positions.push(previewFuturePosition);
    }

    const { chartData } = this.calculateChartData(
      positions,
      this.state.selectedUnderPrice,
      this.state.selectedDaysToExpiry,
      selectedVolatility,
      newPositionSelected,
      selectedDeviation
    );

    return { positions, chartData };
  };

  getShortAndFuturePositionAndChart = (optionAmount, futureAmount, optionPrice, newPositionSelected, selectedVolatility, selectedDeviation) => {
    const longPrice = parseFloat(this.props.market.longPrice);
    const previewPosition = {
      isPreview: true,
      isFuture: this.props.market.isFuture,
      type: "short",
      balance: optionAmount,
      marketId: this.props.market.marketId,
      isCall: this.props.market.isCall,
      strikePrice: this.props.market.normalizedStrikePrice,
      openBasePrice: optionPrice,
      midShortPrice: longPrice
    };

    const positions = [previewPosition];

    if (this.props.futureMarket) {
      const previewFuturePosition = {
        isPreview: true,
        isFuture: true,
        type: this.props.market.isCall ? "long" : "short",
        balance: futureAmount,
        marketId: this.props.futureMarket.marketId,
        isCall: this.props.futureMarket.isCall,
        strikePrice: this.props.futureMarket.normalizedStrikePrice,
        openBasePrice: parseFloat(this.props.underlyingTokenPrice.normalized),
        midShortPrice: parseFloat(this.props.futureMarket.longPrice)
      };
      positions.push(previewFuturePosition);
    }

    const { chartData } = this.calculateChartData(
      positions,
      this.state.selectedUnderPrice,
      this.state.selectedDaysToExpiry,
      selectedVolatility,
      newPositionSelected,
      selectedDeviation
    );

    return { positions, chartData };
  };

  getLongPositionDelta = (inputAmount, optionPrice, selectedDeviation) => {
    const longPrice = parseFloat(this.props.market.longPrice);
    const previewPosition = {
      type: "long",
      balance: inputAmount,
      marketId: this.props.market.marketId,
      isCall: this.props.market.isCall,
      strikePrice: this.props.market.normalizedStrikePrice,
      openBasePrice: optionPrice,
      midLongPrice: longPrice
    };

    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);

    // chart data for new position
    const baseBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "USD").normalizedBorrowInterestRate;
    const underBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "ETH").normalizedBorrowInterestRate;
    const borrowRates = { baseBorrowRate, underBorrowRate };
    const chartPoint = getChartPoint(
      [previewPosition],
      this.props.markets,
      underPrice,
      //Kosta said that future input value should always be the same for specific options input(initial value for days to expiry and volatility), slider for volatility or days to expiry not effect changing future input value
      selectedDaysToExpiryInitialValueForFutureInput,
      selectedVolatilityInitialValueForFutureInput,
      this.props.selectedTokenPair.riskFreeRate / 100,
      borrowRates,
      selectedDeviation
    );

    return chartPoint.delta;
  };

  getShortPositionDelta = (inputAmount, optionPrice, selectedDeviation) => {
    const longPrice = parseFloat(this.props.market.longPrice);
    const previewPosition = {
      type: "short",
      balance: inputAmount,
      marketId: this.props.market.marketId,
      isCall: this.props.market.isCall,
      strikePrice: this.props.market.normalizedStrikePrice,
      openBasePrice: optionPrice,
      midShortPrice: longPrice
    };

    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);

    // chart data for new position
    const baseBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "USD").normalizedBorrowInterestRate;
    const underBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "ETH").normalizedBorrowInterestRate;
    const borrowRates = { baseBorrowRate, underBorrowRate };
    const chartPoint = getChartPoint(
      [previewPosition],
      this.props.markets,
      underPrice,
      //Kosta said that future input value should always be the same for specific options input(initial value for days to expiry and volatility), slider for volatility or days to expiry not effect changing future input value
      selectedDaysToExpiryInitialValueForFutureInput,
      selectedVolatilityInitialValueForFutureInput,
      this.props.selectedTokenPair.riskFreeRate / 100,
      borrowRates,
      selectedDeviation
    );

    return chartPoint.delta;
  };

  getEmptyChartData = () => {
    return {
      minDomainX: parseFloat(this.props.underlyingTokenPrice.normalized) * 0.7,
      maxDomainX: parseFloat(this.props.underlyingTokenPrice.normalized) * 1.3,
      minDomainY: 0,
      maxDomainY: 1,
      series1: [],
      series2: []
    };
  };

  calculateLiqPrice = (positions, selectedDaysToExpiry, selectedVolatility) => {
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    let assetsBase = this.props.tokenBalances.find(t => t.symbol === "USD").normalizedAccountBalance;
    let assetsUnder = this.props.tokenBalances.find(t => t.symbol === "ETH").normalizedAccountBalance;
    let debtBase = -this.props.marginAccountData.collateralTokens.find(data => data.symbol === "USD").normalizedBorrowedAmount;
    let debtUnder = -this.props.marginAccountData.collateralTokens.find(data => data.symbol === "ETH").normalizedBorrowedAmount;
    let allPositions = [];
    if (this.props.openedTradePositions && this.props.openedLPPositions) {
      allPositions = [...positions, ...this.props.openedTradePositions, ...this.props.openedLPPositions];
    }

    // assets and debt for new position
    // todo: move to function
    // todo: this is not going to be good when we have a hedge position, (for example buy 2 options, sell 1.2 futures), because
    // this.state.buyTabType and sellTabType apply only to first position, not the second one.
    for (let i = 0; i < positions.length; i++) {
      if (positions[i].balance > 0) {
        const market = this.props.markets.find(market => market.marketId === positions[i].marketId);
        if (!market.isFuture) {
          if (positions[i].type === "long") {
            assetsBase -= Math.max(0, getBaseAmountWhenBuyingOptions(market.marketHelper, positions[i].balance, underPrice));
          } else {
            assetsBase += Math.max(0, getBaseAmountWhenSellingOptions(market.marketHelper, positions[i].balance, underPrice));
            if (positions[i].isCall) {
              debtUnder += Math.max(0, positions[i].balance);
            } else {
              debtBase += Math.max(0, positions[i].balance * positions[i].strikePrice);
            }
          }
        } else {
          if (this.props.tabs === "buy") {
            if (this.state.buyTabType === "openLong") {
              debtBase += Math.max(0, getBaseAmountWhenBuyingFutures(market.marketHelper, positions[i].balance, underPrice));
            } else if (this.state.buyTabType === "closeShort") {
              debtUnder -= Math.max(0, positions[i].balance);
              assetsBase -= Math.max(0, getBaseAmountWhenBuyingFutures(market.marketHelper, positions[i].balance, underPrice));
            } else if (this.state.buyTabType === "closeShortOpenLong") {
              // todo: not tested enough
              debtUnder -= Math.max(0, this.state.buyTabShortAmount);
              assetsBase -= Math.max(0, getBaseAmountWhenBuyingFutures(market.marketHelper, this.state.buyTabShortAmount, underPrice));
              debtBase += Math.max(0, getBaseAmountWhenBuyingFutures(market.marketHelper, this.state.buyTabLongAmount, underPrice));
            }
          } else {
            if (this.state.sellTabType === "openShort") {
              debtUnder += Math.max(0, positions[i].balance);
              assetsBase += Math.max(0, getBaseAmountWhenSellingFutures(market.marketHelper, positions[i].balance, underPrice));
            } else if (this.state.sellTabType === "closeLong") {
              debtBase -= Math.max(0, getBaseAmountWhenSellingFutures(market.marketHelper, positions[i].balance, underPrice));
            } else if (this.state.sellTabType === "closeLongOpenShort") {
              // todo: not tested enough
              debtBase -= Math.max(0, getBaseAmountWhenSellingFutures(market.marketHelper, this.state.sellTabLongAmount, underPrice));
              debtUnder += Math.max(0, this.state.sellTabShortAmount);
              assetsBase += Math.max(0, getBaseAmountWhenSellingFutures(market.marketHelper, this.state.sellTabShortAmount, underPrice));
            }
          }
        }
      }
    }

    // adjust selected volatility for multiple positions
    let bothPositionsPreview = allPositions.length === 2 && !!allPositions[0].isPreview && !!allPositions[1].isPreview;
    let allPositionsSelectedVolatility = parseFloat(selectedVolatility);
    if (allPositions.length > 1 || !bothPositionsPreview) {
      let marketVolatility = Math.round(parseFloat(this.props.market.longPriceInVol));
      allPositionsSelectedVolatility = selectedVolatility - marketVolatility;
    }

    const marketsToWorker = this.props.markets.map(market => {
      const marketToWorker = { ...market };
      delete marketToWorker.contract;
      return marketToWorker;
    });

    // account liquidation worker
    const baseBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "USD").normalizedBorrowInterestRate;
    const underBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "ETH").normalizedBorrowInterestRate;
    const borrowRates = { baseBorrowRate, underBorrowRate };
    this.liquidationWorker.postMessage({
      allPositions,
      markets: marketsToWorker,
      underPrice,
      selectedDaysToExpiry,
      allPositionsSelectedVolatility,
      riskFreeRate: this.props.selectedTokenPair.riskFreeRate / 100,
      assetsBase,
      assetsUnder,
      debtBase,
      debtUnder,
      borrowRates
    });

    // position liquidation worker
    this.positionLiquidationWorker.postMessage({
      allPositions: this.props.openedLPPositions,
      markets: marketsToWorker,
      underPrice,
      riskFreeRate: this.props.selectedTokenPair.riskFreeRate / 100
    });
  };

  calculateChartData = (positions, selectedUnderPrice, selectedDaysToExpiry, selectedVolatility, newPositionSelected, selectedDeviation) => {
    if (!newPositionSelected) {
      positions = [...positions, ...this.props.openedTradePositions, ...this.props.openedLPPositions];
    }
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);

    // chart data for new position
    const baseBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "USD").normalizedBorrowInterestRate;
    const underBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "ETH").normalizedBorrowInterestRate;
    const borrowRates = { baseBorrowRate, underBorrowRate };
    const chartData = getChartData(
      positions,
      this.props.markets,
      underPrice,
      selectedDaysToExpiry,
      parseFloat(selectedVolatility),
      this.props.selectedTokenPair.riskFreeRate / 100,
      borrowRates,
      selectedDeviation
    );

    return { chartData };
  };

  calculateChartDataCallback = (selectedUnderPrice, selectedDaysToExpiry, selectedVolatility) => {
    let positions = null;
    let shouldBePrintedDefault = false;

    if (this.props.tabs === "buy") {
      shouldBePrintedDefault = this.state.buyTabInputValue === "" || this.state.buyTabInputErrorMessage.trim().toLowerCase() !== "";
      positions =
        shouldBePrintedDefault && this.state.buyObjectValues
          ? [this.state.buyObjectValues.buyTabPreviewPosition]
          : this.state.buyTabFuturePreviewPosition
          ? [this.state.buyTabPreviewPosition, this.state.buyTabFuturePreviewPosition]
          : [this.state.buyTabPreviewPosition];
    } else if (this.props.tabs === "sell") {
      shouldBePrintedDefault = this.state.sellTabInputValue === "" || this.state.sellTabInputErrorMessage.trim().toLowerCase() !== "";
      positions =
        shouldBePrintedDefault && this.state.sellObjectValues
          ? [this.state.sellObjectValues.sellTabPreviewPosition]
          : this.state.sellTabFuturePreviewPosition
          ? [this.state.sellTabPreviewPosition, this.state.sellTabFuturePreviewPosition]
          : [this.state.sellTabPreviewPosition];
    }

    const { chartData } = this.calculateChartData(
      positions,
      selectedUnderPrice,
      selectedDaysToExpiry,
      selectedVolatility,
      this.state.newPositionSelected,
      this.state.standardDeviation
    );

    this.setState(
      {
        chartData: chartData,
        selectedUnderPrice,
        selectedDaysToExpiry,
        selectedVolatility
      },
      () => {
        this.calculateLiqPrice(positions, this.state.selectedDaysToExpiry, this.state.selectedVolatility);
      }
    );
  };

  buyLong = amount => {
    const optionMaxBaseSold = this.state.buyTabBaseAmount * (1 + this.state.slippageTolerance / 100);
    const isCall = this.props.market.isCall;
    const futureLimitBaseAmount = this.state.buyTabFutureBaseAmount * (1 + (this.state.slippageTolerance / 100) * (isCall ? -1 : 1));
    const deadline = (Math.round(new Date().getTime() / 1000) + Math.round(this.state.transactionTimeout * 60)).toString();
    if (!this.state.futureBuyPanelExpanded) {
      buyLong(
        this.props.web3,
        this.props.positionsManager,
        this.props.market.marketId,
        toBn(toPreciseString(optionMaxBaseSold, 18), 18).toString(),
        this.props.web3.utils.toWei(toPreciseString(amount, 18)),
        this.props.web3Account,
        deadline,
        this.props.market.isCall,
        this.props.market.isFuture,
        this.closeModalOnAction,
        this.props.dispatch
      );
    } else {
      const marketBorrowFactor = (10 ** 18).toString();

      buyLongWithDeltaHedge(
        this.props.web3,
        this.props.positionsManager,
        this.props.market.marketId,
        this.props.futureMarket.marketId,
        this.props.web3.utils.toWei(toPreciseString(amount, 18)),
        toBn(toPreciseString(optionMaxBaseSold, 18), 18).toString(),
        this.props.web3.utils.toWei(toPreciseString(this.state.buyTabFutureInputAmount, 18)),
        toBn(toPreciseString(futureLimitBaseAmount, 18), 18).toString(),
        marketBorrowFactor,
        this.props.web3Account,
        deadline,
        this.props.market.isCall,
        this.closeModalOnAction,
        this.props.dispatch
      );
    }
  };

  sellLong = (amount, marketId) => {
    amount = this.props.web3.utils.toWei(toPreciseString(amount, 18));
    const position = this.props.rawSize;
    // handle special case when user inputs max amount, we have to be careful
    // not to cut the last few digits
    if (position) {
      const diff = BigNumber.from(BigNumber.from(position).sub(BigNumber.from(amount))).abs();
      if (diff.lt(BigNumber.from("1000000"))) {
        console.log("Quantity: ", amount, " very close to max, using max quantity: ", position.toString());
        amount = position;
      }
    }

    const minBaseBought = this.state.sellTabBaseAmount * (1 - this.state.slippageTolerance / 100);
    const deadline = (Math.round(new Date().getTime() / 1000) + Math.round(this.state.transactionTimeout * 60)).toString();

    sellLong(
      this.props.web3,
      this.props.positionsManager,
      marketId,
      toBn(toPreciseString(minBaseBought, 18), 18).toString(),
      amount,
      this.props.web3Account,
      deadline,
      this.props.market.isCall,
      this.closeModalOnAction,
      this.props.dispatch
    );
  };

  buyShort = (amount, marketId, isCall, strikePrice) => {
    let minBaseBought = this.state.sellTabBaseAmount * (1 - this.state.slippageTolerance / 100);
    const collateralPutAmount = parseFloat(amount) * parseFloat(strikePrice);
    const collateralAmount = isCall ? toBn(toPreciseString(amount, 18), 18) : toBn(toPreciseString(collateralPutAmount, 18), 18);
    const futureLimitBaseAmount = this.state.sellTabFutureBaseAmount * (1 + (this.state.slippageTolerance / 100) * (isCall ? 1 : -1));

    const deadline = (Math.round(new Date().getTime() / 1000) + Math.round(this.state.transactionTimeout * 60)).toString();
    if (!this.state.futureSellPanelExpanded) {
      buyShort(
        this.props.web3,
        strikePrice,
        this.props.positionsManager,
        marketId,
        collateralAmount.toString(),
        toBn(toPreciseString(minBaseBought, 18), 18).toString(),
        this.props.web3Account,
        deadline,
        this.props.market.isCall,
        this.closeModalOnAction,
        this.props.dispatch
      );
    } else {
      const marketBorrowFactor = (10 ** 18).toString();
      buyShortWithDeltaHedge(
        this.props.web3,
        this.props.positionsManager,
        marketId,
        this.props.futureMarket.marketId,
        toBn(toPreciseString(amount, 18), 18).toString(),
        toBn(toPreciseString(minBaseBought, 18), 18).toString(),
        this.props.web3.utils.toWei(toPreciseString(this.state.sellTabFutureInputAmount, 18)),
        toBn(toPreciseString(futureLimitBaseAmount, 18), 18).toString(),
        marketBorrowFactor,
        this.props.web3Account,
        deadline,
        this.props.market.isCall,
        this.closeModalOnAction,
        this.props.dispatch
      );
    }
  };

  sellShort = (amount, marketId) => {
    amount = this.props.web3.utils.toWei(toPreciseString(amount, 18));
    const position = this.props.rawSize;
    // handle special case when user inputs max amount, we have to be careful
    // not to cut the last few digits
    if (position) {
      const diff = BigNumber.from(BigNumber.from(position).sub(BigNumber.from(amount))).abs();
      if (diff.lt(BigNumber.from("1000000"))) {
        // eslint-disable-next-line no-console
        console.log("Quantity: ", amount, " very close to max, using max quantity: ", position.toString());
        amount = position;
      }
    }

    const maxBaseSold = this.state.buyTabBaseAmount * (1 + this.state.slippageTolerance / 100);
    const deadline = (Math.round(new Date().getTime() / 1000) + Math.round(this.state.transactionTimeout * 60)).toString();
    let marketBorrowFactor = this.props.positionsBorrowFactors.get(marketId + false + "0");
    sellShort(
      this.props.web3,
      this.props.positionsManager,
      marketId,
      amount,
      toBn(toPreciseString(maxBaseSold, 18), 18).toString(),
      this.props.web3Account,
      marketBorrowFactor,
      deadline,
      this.props.market.isCall,
      this.closeModalOnAction,
      this.props.dispatch
    );
  };

  getTradeBalances = market => {
    // go through opened trade positions and get balance for this market
    let longBalance = 0,
      shortBalance = 0;
    if (market != null) {
      this.props.openedTradePositions &&
        this.props.openedTradePositions.forEach(position => {
          if (position.tokenId === market.marketId) {
            longBalance = fromBn(position.rowBalance);
          } else if (position.tokenId === (parseInt(market.marketId) + 1).toString()) {
            shortBalance = fromBn(position.rowBalance);
          }
        });
    }

    return [parseFloat(longBalance), parseFloat(shortBalance)];
  };

  onBuyButtonClick = () => {
    if (this.state.buyTabType === "openLong") {
      this.buyLong(this.state.buyTabLongAmount, this.props.market.marketId);
    } else if (this.state.buyTabType === "closeShort") {
      this.sellShort(this.state.buyTabShortAmount, this.props.market.marketId);
    } else {
      // todo: v2 1.2 is a hack, fix it by changing smart contract to accept only one value for base amount,
      // it's all the same, user here is paying premium for both closing short and opening long
      const closeShortMaxBaseSold = this.state.buyTabBaseAmount.firstAmount * (1 + this.state.slippageTolerance / 100);
      const openLongMaxBaseSold = this.state.buyTabBaseAmount.secondAmount * (1.2 + this.state.slippageTolerance / 100);
      const deadline = (Math.round(new Date().getTime() / 1000) + Math.round(this.state.transactionTimeout * 60)).toString();

      let marketBorrowFactor = this.props.positionsBorrowFactors.get(this.props.market.marketId + false + "0");

      closeShortOpenLongPosition(
        this.props.web3,
        this.props.positionsManager,
        this.props.market.marketId,
        this.props.web3.utils.toWei(toPreciseString(this.state.buyTabShortAmount, 18)),
        this.props.web3.utils.toWei(toPreciseString(closeShortMaxBaseSold, 18)),
        this.props.web3.utils.toWei(toPreciseString(this.state.buyTabLongAmount, 18)),
        this.props.web3.utils.toWei(toPreciseString(openLongMaxBaseSold, 18)),
        marketBorrowFactor,
        this.props.web3Account,
        deadline,
        this.props.market.isCall,
        this.closeModalOnAction,
        this.props.dispatch
      );
    }
  };

  closeModalOnAction = () => {
    this.props.toggle();
    window.location.href = this.props.isTradePage ? "#trade_positions" : "#pool_positions";
  };

  onSellButtonClick = () => {
    if (this.state.sellTabType === "openShort") {
      this.buyShort(this.state.sellTabShortAmount, this.props.market.marketId, this.props.market.isCall, this.props.market.normalizedStrikePrice);
    } else if (this.state.sellTabType === "closeLong") {
      this.sellLong(this.state.sellTabLongAmount, this.props.market.marketId);
    } else {
      // todo: v2 0.8 is a hack, fix it by changing smart contract to accept only one value for base amount,
      // it's all the same, user here is paying premium for both closing short and opening long
      const closeLongMinBaseBought = this.state.sellTabBaseAmount.firstAmount * (1 - this.state.slippageTolerance / 100);
      const openShortMinBaseBought = this.state.sellTabBaseAmount.secondAmount * (0.8 - this.state.slippageTolerance / 100);
      const deadline = (Math.round(new Date().getTime() / 1000) + Math.round(this.state.transactionTimeout * 60)).toString();

      let marketBorrowFactor = this.props.positionsBorrowFactors.get(this.props.market.marketId + false + "0");

      closeLongOpenShortPosition(
        this.props.web3,
        this.props.positionsManager,
        this.props.market.marketId,
        this.props.web3.utils.toWei(toPreciseString(this.state.sellTabLongAmount, 18)),
        toBn(toPreciseString(closeLongMinBaseBought, 18), 18).toString(),
        this.props.web3.utils.toWei(toPreciseString(this.state.sellTabShortAmount, 18)),
        toBn(toPreciseString(openShortMinBaseBought, 18), 18).toString(),
        marketBorrowFactor,
        this.props.web3Account,
        deadline,
        this.props.market.isCall,
        this.closeModalOnAction,
        this.props.dispatch
      );
    }
  };

  onBuyInputChange = () => {
    if (this.buyInputRef.current) {
      const inputString = this.buyInputRef.current.value.trim();
      this.setState({ buyTabInputValue: inputString });
      // is input empty
      if (inputString === "") {
        this.setErrorMessage(true, "");
        return;
      }

      // is not positive number
      if ((inputString.startsWith("0") && inputString[1] !== ".") || !isPositiveNumber(inputString)) {
        this.setErrorMessage(true, "Please enter a positive number.");
        return;
      }

      // get input amount
      const isCall = this.props.market.isCall;
      const isFuture = this.props.market.isFuture;
      const instrumentName = isFuture ? "futures" : "options";
      const inputAmount = parseFloat(inputString);
      const baseBalance = parseFloat(this.props.selectedBaseTokenBalances.normalizedAccountBalance);
      const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
      const oldBalances = this.getTradeBalances(this.props.market);

      // market too small or empty
      const maxOptionsToBuyOnMarket = isFuture
        ? getMaxLongAmountWhenBuyingFutures(this.props.market.marketHelper)
        : getMaxLongAmountWhenBuyingOptions(this.props.market.marketHelper);
      if (inputAmount > maxOptionsToBuyOnMarket && !isSmallDifference(Number(inputAmount), Number(maxOptionsToBuyOnMarket), "USD")) {
        const errorMessage =
          "You are trying to buy " +
          inputAmount +
          " " +
          instrumentName +
          " , but there is only " +
          maxOptionsToBuyOnMarket.toFixed(2) +
          " " +
          instrumentName +
          " being sold on the market. Click max button to buy maximum allowed amount.";
        this.setErrorMessage(true, errorMessage);
        return;
      }

      let longAmount = 0,
        shortAmount = 0,
        collAmount = 0;
      let baseAmountWithFee = isFuture
        ? getBaseAmountWhenBuyingFutures(this.props.market.marketHelper, inputAmount, underPrice)
        : getBaseAmountWhenBuyingOptions(this.props.market.marketHelper, inputAmount, underPrice);
      let buyTabType = "";
      if (oldBalances[1] === 0) {
        // open long
        buyTabType = "openLong";
        longAmount = inputAmount;
      } else if (isSmallDifference(oldBalances[1], inputAmount, "USD")) {
        buyTabType = "closeShort";
        shortAmount = oldBalances[1];
        this.setState({ buyTabShortAmount: shortAmount });
      } else if (oldBalances[1] >= inputAmount) {
        // close short
        buyTabType = "closeShort";
        shortAmount = inputAmount;
        this.setState({ buyTabShortAmount: shortAmount });
        if (isCall) {
          collAmount = inputAmount;
        } else {
          collAmount = inputAmount * parseFloat(this.props.market.normalizedStrikePrice);
        }
      } else {
        // close short open long
        buyTabType = "closeShortOpenLong";
        longAmount = inputAmount - oldBalances[1];
        shortAmount = oldBalances[1];

        // get base sold for closing short
        const closeShortBaseSold = baseAmountWithFee * (shortAmount / (shortAmount + longAmount));
        const openLongBaseSold = baseAmountWithFee * (longAmount / (shortAmount + longAmount));

        // new base amount with fee
        baseAmountWithFee = { firstAmount: closeShortBaseSold, secondAmount: openLongBaseSold };

        if (isCall) {
          collAmount = oldBalances[1];
        } else {
          collAmount = oldBalances[1] * parseFloat(this.props.market.normalizedStrikePrice);
        }
      }

      if (!this.state.buyTabShortAmount && this.props.needsToBeClosed) {
        this.setState({ buyTabShortAmount: shortAmount });
        this.buyInputRef.current.value = Number(shortAmount).toFixed(2);
      }

      // insufficient funds in user margin account (only for options)
      if (!isFuture) {
        if (typeof baseAmountWithFee === "object") {
          if (baseAmountWithFee.firstAmount + baseAmountWithFee.secondAmount > baseBalance) {
            const errorMessage = "You don't have enough funds to complete this action. Click max button to buy maximum allowed amount.";
            this.setErrorMessage(true, errorMessage);
            return;
          }
        } else {
          if (baseAmountWithFee > baseBalance) {
            const errorMessage = "You don't have enough funds to complete this action. Click max button to buy maximum allowed amount.";
            this.setErrorMessage(true, errorMessage);
            return;
          }
        }
      }

      // insufficient health
      const netLiq = this.props.marginAccountData.normalizedNetLiquidity;
      const debt = this.props.marginAccountData.debt;
      const totalLiq = netLiq + debt;
      const health = totalLiq / debt;
      const premiumPaid = buyTabType !== "closeShortOpenLong" ? baseAmountWithFee : baseAmountWithFee.firstAmount + baseAmountWithFee.secondAmount;
      const newHealth = isFuture
        ? this.getNewHealthWhenBuyingFutures(inputAmount, premiumPaid)
        : this.getNewHealthWhenBuyingOptionsIncludedFuture(inputAmount, 0, premiumPaid, 0);

      const liquidationThreshold = this.props.market.liquidationThreshold;
      // if in green, allow all trades that don't get account in yellow or below
      if (health >= liquidationThreshold) {
        if (newHealth < liquidationThreshold) {
          this.setErrorMessage(true, "Account health will be too low. Click max button to buy maximum allowed amount.");
          return;
        }
      }

      // if in yellow or red, allow all trades that increase health
      if (health < liquidationThreshold) {
        if (newHealth < health) {
          this.setErrorMessage(true, "Only actions that increase health are allowed. Click max button to buy maximum allowed amount.");
          return;
        }
      }

      // price more than limit price
      if (!isFuture) {
        const limitOraclePrice = this.props.market.priceOracle.upperPriceInVol;
        const priceAfterBuy = getPriceWhenBuyingOptions(this.props.market.marketHelper, inputAmount);
        if (priceAfterBuy > limitOraclePrice) {
          this.setErrorMessage(true, "Price move is too high. Click max button to buy maximum allowed amount.");
          return;
        }
      }

      // user has funds, market has enough liquidity
      const optionPrice = premiumPaid / inputAmount;
      const minOptionPrice = parseFloat(this.props.market.openLongPrice);
      const priceImpact = ((optionPrice - minOptionPrice) / minOptionPrice) * 100;

      // option price too low when opening position (less than MIN_OPTION_PRICE -> $0.01)
      if ((buyTabType === "openLong" || buyTabType === "closeShortOpenLong") && optionPrice < MIN_OPTION_PRICE) {
        this.setErrorMessage(true, "Option price is too low to open a position. You can only close existing positions.");
        return;
      }

      const delta = this.getLongPositionDelta(inputAmount, optionPrice, this.state.standardDeviation);
      if (this.buyFutureInputRef.current) {
        this.buyFutureInputRef.current.value = Math.abs(delta).toFixed(2);
      }

      const returnData = this.getLongAndFuturePositionAndChart(
        inputAmount,
        0,
        optionPrice,
        this.state.newPositionSelected,
        this.state.selectedVolatility,
        this.state.standardDeviation
      );

      this.setState(
        {
          buyTabType: buyTabType,
          buyTabBaseAmount: baseAmountWithFee,
          buyTabCollateralAmount: collAmount,
          buyTabLongAmount: longAmount, // todo: remove, use buyTabInputAmount
          buyTabShortAmount: shortAmount,
          buyTabInputAmount: inputAmount,
          buyTabPriceImpact: priceImpact,
          buyTabHealth: newHealth * 100,
          buyTabInputClass: "has-success",
          buyTabInputErrorMessage: "",
          buyTabPreviewPosition: returnData.positions[0],
          buyTabFuturePreviewPosition: returnData.positions[1],
          chartData: returnData.chartData,

          buyObjectValues: null
        },
        () => {
          // if options input is validate, validate futures too
          if (this.state.futureBuyPanelExpanded) {
            this.onBuyFutureInputChange(baseAmountWithFee);
          }

          // liq price calculation uses state, so we call it after state is set
          this.calculateLiqPrice(returnData.positions, this.state.selectedDaysToExpiry, this.state.selectedVolatility);
        }
      );

      return premiumPaid;
    }
  };

  onBuyFutureInputChange = (optionPremiumPaid = 0) => {
    if (this.buyFutureInputRef.current) {
      const inputString = this.buyFutureInputRef.current.value.trim();

      this.setState({ buyTabFutureInputValue: inputString }); // todo: remove

      // is input empty
      if (inputString === "") {
        this.setFutureErrorMessage(true, "");
        return;
      }

      // is not positive number
      if ((inputString.startsWith("0") && inputString[1] !== ".") || !isPositiveNumber(inputString)) {
        this.setFutureErrorMessage(true, "Please enter a positive number.");
        return;
      }

      // get input amount
      const isCall = this.props.market.isCall;
      const inputAmount = parseFloat(inputString);
      const baseBalance = parseFloat(this.props.selectedBaseTokenBalances.normalizedAccountBalance);
      const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);

      // market too small or empty
      const maxFuturesToBuyOnMarket = this.props.market.isCall
        ? getMaxLongAmountWhenSellingFutures(this.props.futureMarket.marketHelper)
        : getMaxLongAmountWhenBuyingFutures(this.props.futureMarket.marketHelper);
      if (inputAmount > maxFuturesToBuyOnMarket && !isSmallDifference(Number(inputAmount), Number(maxFuturesToBuyOnMarket), "USD")) {
        const errorMessage =
          "You are trying to buy " +
          inputAmount +
          " futures, but there is only " +
          maxFuturesToBuyOnMarket.toFixed(2) +
          " futures being sold on the market. Click max button to buy maximum allowed amount.";
        this.setFutureErrorMessage(true, errorMessage);
        return;
      }

      let shortAmount = 0;
      let baseAmountPaid = isCall
        ? -getBaseAmountWhenSellingFutures(this.props.futureMarket.marketHelper, inputAmount, underPrice)
        : getBaseAmountWhenBuyingFutures(this.props.futureMarket.marketHelper, inputAmount, underPrice);
      let buyTabType = this.state.buyTabType;
      // insufficient funds
      if (baseAmountPaid + optionPremiumPaid > baseBalance) {
        const errorMessage = "You don't have enough funds to complete this action. Click max button to buy maximum allowed amount.";
        this.setFutureErrorMessage(true, errorMessage);
        return;
      }

      // todo: insufficient funds in lending pool (just like onSellFutureInputChange)
      // insufficient health
      const netLiq = this.props.marginAccountData.normalizedNetLiquidity;
      const debt = this.props.marginAccountData.debt;
      const totalLiq = netLiq + debt;
      const health = totalLiq / debt;

      let newHealth = 0;
      if (optionPremiumPaid != 0) {
        const inputOptionAmount = Number(this.buyInputRef.current.value);
        const inputFutureAmount = inputAmount;
        newHealth = this.getNewHealthWhenBuyingOptionsIncludedFuture(inputOptionAmount, inputFutureAmount, optionPremiumPaid, baseAmountPaid);
      }

      const liquidationThreshold = this.props.market.liquidationThreshold;
      // if in green, allow all trades that don't get account in yellow or below
      if (health >= liquidationThreshold) {
        if (newHealth < liquidationThreshold) {
          this.setFutureErrorMessage(true, "Account health will be too low. Click max button to buy maximum allowed amount.");
          return;
        }
      }

      // if in yellow or red, allow all trades that increase health
      if (health < liquidationThreshold) {
        if (newHealth < health) {
          this.setFutureErrorMessage(true, "Only actions that increase health are allowed. Click max button to buy maximum allowed amount.");
          return;
        }
      }

      // user has funds, market has enough liquidity
      const returnData = this.getLongAndFuturePositionAndChart(
        this.state.buyTabInputAmount,
        inputAmount,
        parseFloat(this.props.market.openLongPrice), // todo: use premium/input
        this.state.newPositionSelected,
        this.state.selectedVolatility,
        this.state.standardDeviation
      );

      // future price impact calculation
      const futurePrice = Math.abs(baseAmountPaid) / inputAmount;
      let futurePriceImpact = 0;
      if (isCall) {
        const minFuturePrice = parseFloat(this.props.futureMarket.closeLongPrice);
        futurePriceImpact = ((minFuturePrice - futurePrice) / minFuturePrice) * 100;
      } else {
        const maxFuturePrice = parseFloat(this.props.futureMarket.openLongPrice);
        futurePriceImpact = ((futurePrice - maxFuturePrice) / maxFuturePrice) * 100;
      }

      this.setState(
        {
          buyTabFutureBaseAmount: Math.abs(baseAmountPaid),
          buyTabFutureInputAmount: inputAmount,
          buyTabFuturePriceImpact: futurePriceImpact,
          buyTabHealth: newHealth * 100,
          buyTabFutureInputClass: "has-success",
          buyTabFutureInputErrorMessage: "",
          buyTabPreviewPosition: returnData.positions[0],
          buyTabFuturePreviewPosition: returnData.positions[1],
          chartData: returnData.chartData
        },
        () => {
          // liq price calculation uses state, so we call it after state is set
          this.calculateLiqPrice(returnData.positions, this.state.selectedDaysToExpiry, this.state.selectedVolatility);
        }
      );
    }
  };

  // this is calculating when is empty string and default input value should be 0.000001 or when max button should be clicked and then default input value should be max
  calculateForBuyInput = inputString => {
    const isCall = this.props.market.isCall;
    const isFuture = this.props.market.isFuture;
    const inputAmount = parseFloat(inputString);
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    const oldOptionBalances = this.getTradeBalances(this.props.market);

    let longAmount = 0,
      shortAmount = 0,
      collAmount = 0;
    let baseAmountWithFee = getBaseAmountWhenBuyingOptions(this.props.market.marketHelper, inputAmount, underPrice);
    let buyTabType = "";
    if (oldOptionBalances[1] === 0) {
      buyTabType = "openLong";
      longAmount = inputAmount;
    } else if (oldOptionBalances[1] >= inputAmount) {
      buyTabType = "closeShort";
      shortAmount = inputAmount;
      if (isCall) {
        collAmount = inputAmount;
      } else {
        collAmount = inputAmount * parseFloat(this.props.market.normalizedStrikePrice);
      }
    } else {
      buyTabType = "closeShortOpenLong";
      longAmount = inputAmount - oldOptionBalances[1];
      shortAmount = oldOptionBalances[1];

      const closeShortBaseSold = baseAmountWithFee * (shortAmount / (shortAmount + longAmount));
      const openLongBaseSold = baseAmountWithFee * (longAmount / (shortAmount + longAmount));

      baseAmountWithFee = { firstAmount: closeShortBaseSold, secondAmount: openLongBaseSold };

      if (isCall) {
        collAmount = oldOptionBalances[1];
      } else {
        collAmount = oldOptionBalances[1] * parseFloat(this.props.market.normalizedStrikePrice);
      }
    }

    const premiumPaid = buyTabType !== "closeShortOpenLong" ? baseAmountWithFee : baseAmountWithFee.firstAmount + baseAmountWithFee.secondAmount;

    const newHealth = isFuture
      ? this.getNewHealthWhenBuyingFutures(inputAmount, premiumPaid)
      : this.getNewHealthWhenBuyingOptionsIncludedFuture(inputAmount, 0, premiumPaid, 0);

    const optionPrice = premiumPaid / inputAmount;
    const minOptionPrice = parseFloat(this.props.market.openLongPrice);
    const priceImpact = ((optionPrice - minOptionPrice) / minOptionPrice) * 100;

    const returnData = this.getLongAndFuturePositionAndChart(
      inputAmount,
      0,
      optionPrice,
      this.state.newPositionSelected,
      this.state.selectedVolatility,
      this.state.standardDeviation
    );

    return {
      buyTabType: buyTabType,
      buyTabBaseAmount: baseAmountWithFee,
      buyTabInputAmount: inputAmount,
      buyTabPriceImpact: priceImpact,
      buyTabHealth: newHealth * 100,
      buyTabPreviewPosition: returnData.previewPosition,
      chartData: returnData.chartData
    };
  };

  onSellInputChange = () => {
    const inputString = this.sellInputRef.current.value.trim();

    this.setState({ sellTabInputValue: inputString });

    // is input empty
    if (inputString === "") {
      this.setErrorMessage(false, "");
      return;
    }

    // is not positive number
    if ((inputString.startsWith("0") && inputString[1] !== ".") || !isPositiveNumber(inputString)) {
      this.setErrorMessage(false, "Please enter a positive number.");
      return;
    }

    // get input amount
    const isCall = this.props.market.isCall;
    const isFuture = this.props.market.isFuture;
    const instrumentName = isFuture ? "futures" : "options";
    const inputAmount = parseFloat(inputString);
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    const oldOptionBalances = this.getTradeBalances(this.props.market);

    // market too small or empty
    const maxLongsBoughtByMarket = isFuture
      ? getMaxLongAmountWhenSellingFutures(this.props.market.marketHelper)
      : getMaxLongAmountWhenSellingOptions(this.props.market.marketHelper);

    if ((this.props.market.isEmpty || inputAmount > maxLongsBoughtByMarket) && !isSmallDifference(inputAmount, maxLongsBoughtByMarket, "USD")) {
      const errorMessage =
        "You are trying to sell " +
        inputAmount +
        " " +
        instrumentName +
        " , but there is only " +
        maxLongsBoughtByMarket.toFixed(2) +
        " " +
        instrumentName +
        " being bought on the market. Click max button to sell maximum allowed amount.";
      this.setErrorMessage(false, errorMessage);
      return;
    }

    // is user opening short, closing long or both
    let longAmount = 0,
      shortAmount = 0,
      collAmount = 0;
    let baseAmountWithFee = isFuture
      ? getBaseAmountWhenSellingFutures(this.props.market.marketHelper, inputAmount, underPrice, false)
      : getBaseAmountWhenSellingOptions(this.props.market.marketHelper, inputAmount, underPrice, false);
    let sellTabType = "";
    if (oldOptionBalances[0] === 0) {
      sellTabType = "openShort";
      shortAmount = inputAmount;
      if (isCall) {
        collAmount = inputAmount;
      } else {
        collAmount = inputAmount * parseFloat(this.props.market.normalizedStrikePrice);
      }
    } else if (isSmallDifference(oldOptionBalances[1], inputAmount, "USD")) {
      sellTabType = "closeLong";
      longAmount = oldOptionBalances[0];
      this.setState({ sellTabLongAmount: longAmount });
    } else if (oldOptionBalances[0] >= inputAmount) {
      sellTabType = "closeLong";
      longAmount = inputAmount;
      this.setState({ sellTabLongAmount: longAmount });
    } else {
      sellTabType = "closeLongOpenShort";
      longAmount = oldOptionBalances[0];
      shortAmount = inputAmount - oldOptionBalances[0];
      if (isCall) {
        collAmount = inputAmount - oldOptionBalances[0];
      } else {
        collAmount = (inputAmount - oldOptionBalances[0]) * parseFloat(this.props.market.normalizedStrikePrice);
      }
      // get base bought for closing long
      const closeLongBaseBought = baseAmountWithFee * (longAmount / (shortAmount + longAmount));
      const openShortBaseBought = baseAmountWithFee * (shortAmount / (shortAmount + longAmount));

      // new base amount with fee
      baseAmountWithFee = { firstAmount: closeLongBaseBought, secondAmount: openShortBaseBought };
    }

    if (!this.state.sellTabLongAmount && this.props.needsToBeClosed) {
      this.setState({ sellTabLongAmount: longAmount });
      this.sellInputRef.current.value = Number(longAmount).toFixed(2);
    }

    // insufficient funds in lending pool
    // todo: v1 generally handle when user is opening short, but has some funds in margin account,
    // and doesn't want to borrow from pool
    const lendingPoolSupplyUnder = this.props.marginPoolData.tokensData[1].normalizedCurrentSupply;
    const borrowedUnder = this.props.marginPoolData.tokensData[1].normalizedBorrowedAmount;
    const maxLendAmountUnder = lendingPoolSupplyUnder - borrowedUnder;
    const lendingPoolSupplyBase = this.props.marginPoolData.tokensData[0].normalizedCurrentSupply;
    const borrowedBase = this.props.marginPoolData.tokensData[0].normalizedBorrowedAmount;
    const maxLendAmountBase = lendingPoolSupplyBase - borrowedBase;
    if (sellTabType === "openShort" || sellTabType === "closeLongOpenShort") {
      if ((isCall && collAmount > maxLendAmountUnder) || (!isCall && collAmount > maxLendAmountBase)) {
        this.setErrorMessage(false, "Insufficient funds in lending pool. Click max button to sell maximum allowed amount.");
        return;
      }
    }

    // insufficient health
    const netLiq = this.props.marginAccountData.normalizedNetLiquidity;
    const debt = this.props.marginAccountData.debt;
    const totalLiq = netLiq + debt;
    const health = totalLiq / debt;
    const premiumPaid = sellTabType !== "closeLongOpenShort" ? -baseAmountWithFee : -(baseAmountWithFee.firstAmount + baseAmountWithFee.secondAmount);
    const newHealth = isFuture
      ? this.getNewHealthWhenSellingFutures(inputAmount, -premiumPaid)
      : this.getNewHealthWhenSellingOptionsIncludedFuture(inputAmount, 0, premiumPaid, 0);

    const liquidationThreshold = this.props.market.liquidationThreshold;

    // if in green, allow all trades that don't get account in yellow or below
    if (health >= liquidationThreshold) {
      if (newHealth < liquidationThreshold) {
        this.setErrorMessage(false, "Account health will be too low. Click max button to sell maximum allowed amount.");
        return;
      }
    }

    // if in yellow, allow all trades that increase health
    if (health < liquidationThreshold) {
      if (newHealth < health) {
        this.setErrorMessage(false, "Only actions that increase health are allowed. Click max button to sell maximum allowed amount.");
        return;
      }
    }

    // price less than limit price
    if (!isFuture) {
      const limitOraclePrice = this.props.market.priceOracle.lowerPriceInVol;
      const priceAfterSell = getPriceWhenSellingOptions(this.props.market.marketHelper, inputAmount);
      if (priceAfterSell < limitOraclePrice) {
        this.setErrorMessage(false, "Price move is too high. Click max button to sell maximum allowed amount.");
        return;
      }
    }

    // calculate option price and impact
    const impactlessOptionPrice = parseFloat(this.props.market.closeLongPrice);
    const optionPrice = -premiumPaid / inputAmount;
    const priceImpact = ((impactlessOptionPrice - optionPrice) / impactlessOptionPrice) * 100;

    // option price too low when opening position (less than MIN_OPTION_PRICE -> $0.01)
    if ((sellTabType === "openShort" || sellTabType === "closeLongOpenShort") && optionPrice < MIN_OPTION_PRICE) {
      this.setErrorMessage(false, "Option price is too low to open a position. You can only close existing positions.");
      return;
    }

    // lending pool has funds, market has enough liquidity
    const delta = this.getShortPositionDelta(inputAmount, optionPrice, this.state.standardDeviation);
    if (this.sellFutureInputRef.current) {
      this.sellFutureInputRef.current.value = Math.abs(delta).toFixed(2);
    }

    const returnData = this.getShortAndFuturePositionAndChart(
      inputAmount,
      0,
      optionPrice,
      this.state.newPositionSelected,
      this.state.selectedVolatility,
      this.state.standardDeviation
    );

    this.setState(
      {
        sellTabType: sellTabType,
        sellTabBaseAmount: baseAmountWithFee,
        sellTabCollateralAmount: collAmount,
        sellTabLongAmount: longAmount,
        sellTabShortAmount: shortAmount,
        sellTabInputAmount: inputAmount,
        sellTabPriceImpact: priceImpact,
        sellTabHealth: newHealth * 100,
        sellTabInputClass: "has-success",
        sellTabInputErrorMessage: "",
        sellTabPreviewPosition: returnData.positions[0],
        sellTabFuturePreviewPosition: returnData.positions[1],
        chartData: returnData.chartData,

        sellObjectValues: null
      },
      () => {
        // if options input is validate, validate futures too
        if (this.state.futureSellPanelExpanded) {
          this.onSellFutureInputChange(premiumPaid);
        }

        // liq price calculation uses state, so we call it after state is set
        this.calculateLiqPrice(returnData.positions, this.state.selectedDaysToExpiry, this.state.selectedVolatility); // todo: look at buy
      }
    );
    return premiumPaid;
  };

  onSellFutureInputChange = (optionPremiumPaid = 0) => {
    if (this.sellFutureInputRef.current) {
      const inputString = this.sellFutureInputRef.current.value.trim();

      this.setState({ sellTabFutureInputValue: inputString }); // todo: remove

      // is input empty
      if (inputString === "") {
        this.setFutureErrorMessage(false, "");
        return;
      }

      // is not positive number
      if ((inputString.startsWith("0") && inputString[1] !== ".") || !isPositiveNumber(inputString)) {
        this.setFutureErrorMessage(false, "Please enter a positive number.");
        return;
      }

      // get input amount
      const isCall = this.props.futureMarket.isCall;
      const inputAmount = parseFloat(inputString);
      const baseBalance = parseFloat(this.props.selectedBaseTokenBalances.normalizedAccountBalance);
      const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);

      // market too small or empty
      const maxFuturesOnMarket = this.props.market.isCall
        ? getMaxLongAmountWhenBuyingFutures(this.props.futureMarket.marketHelper)
        : getMaxLongAmountWhenSellingFutures(this.props.futureMarket.marketHelper, true);

      //const maxLongsBoughtByMarket = getMaxLongAmountWhenSellingOptions(this.props.futureMarket.marketHelper);
      if ((this.props.futureMarket.isEmpty || inputAmount > maxFuturesOnMarket) && !isSmallDifference(inputAmount, maxFuturesOnMarket, "USD")) {
        const errorMessage =
          "You are trying to sell " +
          inputAmount +
          " futures, but there is only " +
          maxFuturesOnMarket.toFixed(2) +
          " futures being bought on the market. Click max button to sell maximum allowed amount.";
        this.setFutureErrorMessage(false, errorMessage);
        return;
      }

      // is user opening short, closing long or both
      let longAmount = 0,
        shortAmount = 0,
        collAmount = 0;
      let baseAmountPaid = isCall
        ? getBaseAmountWhenBuyingFutures(this.props.futureMarket.marketHelper, inputAmount, underPrice)
        : -getBaseAmountWhenSellingFutures(this.props.futureMarket.marketHelper, inputAmount, underPrice);
      let sellTabType = this.state.sellTabType;

      // insufficient funds
      if (baseAmountPaid + optionPremiumPaid > baseBalance) {
        const errorMessage = "You don't have enough funds to complete this action. Click max button to buy maximum allowed amount.";
        this.setFutureErrorMessage(true, errorMessage);
        return;
      }

      // insufficient funds in lending pool
      // todo: v1 generally handle when user is opening short, but has some funds in margin account,
      // and doesn't want to borrow from pool
      const lendingPoolSupplyUnder = this.props.marginPoolData.tokensData[1].normalizedCurrentSupply;
      const borrowedUnder = this.props.marginPoolData.tokensData[1].normalizedBorrowedAmount;
      const maxLendAmountUnder = lendingPoolSupplyUnder - borrowedUnder;
      const lendingPoolSupplyBase = this.props.marginPoolData.tokensData[0].normalizedCurrentSupply;
      const borrowedBase = this.props.marginPoolData.tokensData[0].normalizedBorrowedAmount;
      const maxLendAmountBase = lendingPoolSupplyBase - borrowedBase;

      if (sellTabType === "openShort" || sellTabType === "closeLongOpenShort") {
        if ((isCall && collAmount > maxLendAmountUnder) || (!isCall && collAmount > maxLendAmountBase)) {
          this.setFutureErrorMessage(false, "Insufficient funds in lending pool. Click max button to sell maximum allowed amount.");
          return;
        }
      }

      // insufficient health
      const netLiq = this.props.marginAccountData.normalizedNetLiquidity;
      const debt = this.props.marginAccountData.debt;
      const totalLiq = netLiq + debt;
      const health = totalLiq / debt;

      let newHealth = 0;
      if (optionPremiumPaid != 0) {
        const inputOptionAmount = Number(this.sellInputRef.current.value);
        const inputFutureAmount = Number(this.sellFutureInputRef.current.value);
        newHealth = this.getNewHealthWhenSellingOptionsIncludedFuture(inputOptionAmount, inputFutureAmount, optionPremiumPaid, baseAmountPaid);
      }
      const liquidationThreshold = this.props.market.liquidationThreshold;
      // if in green, allow all trades that don't get account in yellow or below
      if (health >= liquidationThreshold) {
        if (newHealth < liquidationThreshold) {
          this.setFutureErrorMessage(false, "Account health will be too low. Click max button to sell maximum allowed amount.");
          return;
        }
      }

      // if in yellow, allow all trades that increase health
      if (health < liquidationThreshold) {
        if (newHealth < health) {
          this.setFutureErrorMessage(false, "Only actions that increase health are allowed. Click max button to sell maximum allowed amount.");
          return;
        }
      }

      // margin pool and user have funds, market has enough liquidity
      const optionPrice = this.state.sellTabBaseAmount / this.state.sellTabInputAmount;
      const returnData = this.getShortAndFuturePositionAndChart(
        this.state.sellTabInputAmount,
        inputAmount,
        optionPrice,
        this.state.newPositionSelected,
        this.state.selectedVolatility,
        this.state.standardDeviation
      );

      // future price impact calculation
      const futurePrice = Math.abs(baseAmountPaid) / inputAmount;
      let futurePriceImpact = 0;
      if (isCall) {
        const maxFuturePrice = parseFloat(this.props.futureMarket.openLongPrice);
        futurePriceImpact = ((futurePrice - maxFuturePrice) / maxFuturePrice) * 100;
      } else {
        const minFuturePrice = parseFloat(this.props.futureMarket.closeLongPrice);
        futurePriceImpact = ((minFuturePrice - futurePrice) / minFuturePrice) * 100;
      }

      this.setState(
        {
          sellTabFutureBaseAmount: Math.abs(baseAmountPaid),
          sellTabFutureInputAmount: inputAmount,
          sellTabFuturePriceImpact: futurePriceImpact,
          sellTabHealth: newHealth * 100,
          sellTabFutureInputClass: "has-success",
          sellTabFutureInputErrorMessage: "",
          sellTabPreviewPosition: returnData.positions[0],
          sellTabFuturePreviewPosition: returnData.positions[1],
          chartData: returnData.chartData
        },
        () => {
          // liq price calculation uses state, so we call it after state is set
          this.calculateLiqPrice(returnData.positions, this.state.selectedDaysToExpiry, this.state.selectedVolatility);
        }
      );
    }
  };

  // this is calculating when is empty string and default input value should be 0.000001 or when max button should be clicked and then default input value should be max
  // TODO: this is a copy, refactor
  calculateForSellInput = inputString => {
    const isCall = this.props.market.isCall;
    const isFuture = this.props.market.isFuture;
    const inputAmount = parseFloat(inputString);
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    const oldOptionBalances = this.getTradeBalances(this.props.market);

    let longAmount = 0,
      shortAmount = 0,
      collAmount = 0;

    let baseAmountWithFee = isFuture
      ? getBaseAmountWhenSellingFutures(this.props.market.marketHelper, inputAmount, underPrice, false)
      : getBaseAmountWhenSellingOptions(this.props.market.marketHelper, inputAmount, underPrice, false);
    let sellTabType = "";
    if (oldOptionBalances[0] === 0) {
      sellTabType = "openShort";
      shortAmount = inputAmount;
      if (isCall) {
        collAmount = inputAmount;
      } else {
        collAmount = inputAmount * parseFloat(this.props.market.normalizedStrikePrice);
      }
    } else if (oldOptionBalances[0] >= inputAmount) {
      sellTabType = "closeLong";
      longAmount = inputAmount;
    } else {
      sellTabType = "closeLongOpenShort";
      longAmount = oldOptionBalances[0];
      shortAmount = inputAmount - oldOptionBalances[0];
      if (isCall) {
        collAmount = inputAmount - oldOptionBalances[0];
      } else {
        collAmount = (inputAmount - oldOptionBalances[0]) * parseFloat(this.props.market.normalizedStrikePrice);
      }
      const closeLongBaseBought = baseAmountWithFee * (longAmount / (shortAmount + longAmount));
      const openShortBaseBought = baseAmountWithFee * (shortAmount / (shortAmount + longAmount));

      baseAmountWithFee = { firstAmount: closeLongBaseBought, secondAmount: openShortBaseBought };
    }

    const premiumPaid = sellTabType !== "closeLongOpenShort" ? -baseAmountWithFee : -(baseAmountWithFee.firstAmount + baseAmountWithFee.secondAmount);
    const newHealth = isFuture
      ? this.getNewHealthWhenSellingFutures(inputAmount, -premiumPaid)
      : this.getNewHealthWhenSellingOptionsIncludedFuture(inputAmount, 0, premiumPaid, 0);

    const impactlessOptionPrice = parseFloat(this.props.market.closeLongPrice);
    const optionPrice = -premiumPaid / inputAmount;
    const priceImpact = ((impactlessOptionPrice - optionPrice) / impactlessOptionPrice) * 100;

    const returnData = this.getShortAndFuturePositionAndChart(
      inputAmount,
      0,
      optionPrice,
      this.state.newPositionSelected,
      this.state.selectedVolatility,
      this.state.standardDeviation
    );

    return {
      sellTabType: sellTabType,
      sellTabBaseAmount: baseAmountWithFee,
      sellTabInputAmount: inputAmount,
      sellTabPriceImpact: priceImpact,
      sellTabHealth: newHealth * 100,
      sellTabPreviewPosition: returnData.previewPosition,
      chartData: returnData.chartData
    };
  };

  setErrorMessage = (isBuy, errorMessage) => {
    if (isBuy) {
      this.setState({
        buyTabInputClass: errorMessage.length > 0 ? "has-danger" : "",
        buyTabInputErrorMessage: errorMessage,
        buyTabInputAmount: 0,
        buyTabPreviewPosition: null,
        chartData: this.getEmptyChartData()
      });
    } else {
      this.setState({
        sellTabInputClass: errorMessage.length > 0 ? "has-danger" : "",
        sellTabInputErrorMessage: errorMessage,
        sellTabInputAmount: 0,
        sellTabPreviewPosition: null,
        chartData: this.getEmptyChartData()
      });
    }
  };

  setFutureErrorMessage = (isBuy, errorMessage) => {
    if (isBuy) {
      this.setState({
        buyTabFutureInputClass: errorMessage.length > 0 ? "has-danger" : "",
        buyTabFutureInputErrorMessage: errorMessage,
        buyTabFutureInputAmount: 0
      });
    } else {
      this.setState({
        sellTabFutureInputClass: errorMessage.length > 0 ? "has-danger" : "",
        sellTabFutureInputErrorMessage: errorMessage,
        sellTabFutureInputAmount: 0
      });
    }
  };

  getNewHealthWhenBuyingOptionsIncludedFuture = (inputOptionAmount, inputFutureAmount, premiumOptionPaid, premiumFuturePaid) => {
    if (this.props.marginAccountData) {
      const isCall = this.props.market.isCall;
      const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
      const strikePrice = parseFloat(this.props.market.normalizedStrikePrice);
      const netLiq = this.props.marginAccountData.normalizedNetLiquidity;
      const debt = this.props.marginAccountData.debt;
      const totalLiq = netLiq + debt;
      const oldOptionBalances = this.getTradeBalances(this.props.market);
      const oldFutureBalances = this.getTradeBalances(this.props.futureMarket);
      const shortOptionBalance = oldOptionBalances[1];
      const longPriceOption = parseFloat(this.props.market.longPrice);
      const longPriceFuture = this.state.futurePriceShouldBeShow ? parseFloat(this.props.futureMarket.longPrice) : 0;

      const longsOptionReceived = inputOptionAmount;
      const longsFutureReceived = inputFutureAmount * (isCall ? -1 : 1);
      const newLiq = longsOptionReceived * longPriceOption - premiumOptionPaid + (longsFutureReceived * longPriceFuture - premiumFuturePaid);

      // when buying options, futures can be bought and sold
      const existingFutureLongs = oldFutureBalances[0];
      const existingFutureShorts = oldFutureBalances[1];
      let newFutureDebt = 0; // can be negative, meaning debt is reduced
      if (isCall) {
        // selling futures when buying call option
        newFutureDebt = Math.max(inputFutureAmount - existingFutureLongs, 0) * underPrice;
      } else {
        // buying futures when buying put option
        newFutureDebt = -Math.min(existingFutureShorts, inputFutureAmount) * underPrice;
      }

      // open long
      if (shortOptionBalance == 0) {
        return (totalLiq + newLiq + newFutureDebt) / Math.max(0, debt + newFutureDebt);
      }

      // close short
      if (shortOptionBalance >= inputOptionAmount) {
        const shortAmount = inputOptionAmount;
        const newOptionDebt = -shortAmount * (isCall ? underPrice : strikePrice);
        const newDebt = newOptionDebt + newFutureDebt;
        return (totalLiq + newLiq + newDebt) / Math.max(0, debt + newDebt);
      }

      // close short open long
      const shortAmount = oldOptionBalances[1];
      const newOptionDebt = -shortAmount * (isCall ? underPrice : strikePrice);
      const newDebt = newOptionDebt + newFutureDebt;
      return (totalLiq + newLiq + newDebt) / Math.max(0, debt + newDebt);
    }
  };

  getNewHealthWhenSellingOptionsIncludedFuture = (inputOptionAmount, inputFutureAmount, premiumOptionPaid, premiumFuturePaid) => {
    if (this.props.marginAccountData) {
      const isCall = this.props.market.isCall;
      const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
      const strikePrice = parseFloat(this.props.market.normalizedStrikePrice);
      const netLiq = this.props.marginAccountData.normalizedNetLiquidity;
      const debt = this.props.marginAccountData.debt;
      const totalLiq = netLiq + debt;
      const oldOptionBalances = this.getTradeBalances(this.props.market);
      const oldFutureBalances = this.getTradeBalances(this.props.futureMarket);
      const longBalance = oldOptionBalances[0];
      const longPriceOption = parseFloat(this.props.market.longPrice);
      const longPriceFuture = this.state.futurePriceShouldBeShow ? parseFloat(this.props.futureMarket.longPrice) : 0;

      const longsOptionReceived = -inputOptionAmount;
      const longsFutureReceived = inputFutureAmount * (isCall ? 1 : -1);
      const newLiq = longsOptionReceived * longPriceOption - premiumOptionPaid + (longsFutureReceived * longPriceFuture - premiumFuturePaid);

      // when selling options, futures can be bought and sold
      const existingFutureLongs = oldFutureBalances[0];
      const existingFutureShorts = oldFutureBalances[1];
      let newFutureDebt = 0; // can be negative, meaning debt is reduced
      if (isCall) {
        // buying futures when selling call option
        newFutureDebt = -Math.max(inputFutureAmount - existingFutureLongs, 0) * underPrice;
      } else {
        // selling futures when selling put option
        newFutureDebt = Math.min(existingFutureShorts, inputFutureAmount) * underPrice;
      }

      // open short
      if (longBalance == 0) {
        const shortAmount = inputOptionAmount;
        const newOptionDebt = shortAmount * (isCall ? underPrice : strikePrice);
        const newDebt = newOptionDebt + newFutureDebt;
        return (totalLiq + newLiq + newDebt) / Math.max(0, debt + newDebt);
      }

      // close long
      if (longBalance >= inputOptionAmount) {
        return (totalLiq + newLiq + newFutureDebt) / Math.max(0, debt + newFutureDebt);
      }

      // close long open short
      const shortAmount = inputOptionAmount - oldOptionBalances[1];
      const newOptionDebt = shortAmount * (isCall ? underPrice : strikePrice);
      const newDebt = newOptionDebt + newFutureDebt;
      return (totalLiq + newLiq + newDebt) / Math.max(0, debt + newDebt);
    }
  };

  getNewHealthWhenBuyingFutures = (inputAmount, premiumPaid) => {
    if (this.props.marginAccountData) {
      const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
      const netLiq = this.props.marginAccountData.normalizedNetLiquidity;
      const debt = this.props.marginAccountData.debt;
      const totalLiq = netLiq + debt;
      const oldBalances = this.getTradeBalances(this.props.market);
      const shortBalance = oldBalances[1];

      // open long
      if (shortBalance == 0) {
        const longsReceived = inputAmount;
        const newLiq = longsReceived * underPrice;
        const newDebt = premiumPaid;
        return (totalLiq + newLiq) / Math.max(0, debt + newDebt);
      }

      // close short
      if (shortBalance >= inputAmount) {
        const shortSent = inputAmount;
        const newLiq = -shortSent * underPrice;
        const newDebt = -premiumPaid;
        return (totalLiq + newLiq) / Math.max(0, debt + newDebt);
      }

      // close short open long
      const shortSent = shortBalance;
      const longsReceived = inputAmount - shortBalance;
      const newLiq = (longsReceived - shortSent) * underPrice;
      const newDebt = (longsReceived - shortSent) * underPrice;
      return (totalLiq + newLiq) / Math.max(0, debt + newDebt);
    }
  };

  getNewHealthWhenSellingFutures = (inputAmount, premiumReceived) => {
    if (this.props.marginAccountData) {
      const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
      const netLiq = this.props.marginAccountData.normalizedNetLiquidity;
      const debt = this.props.marginAccountData.debt;
      const totalLiq = netLiq + debt;
      const oldBalances = this.getTradeBalances(this.props.market);
      const longBalance = oldBalances[0];

      // open short
      if (longBalance == 0) {
        const shortReceived = inputAmount;
        const newLiq = premiumReceived;
        const newDebt = shortReceived * underPrice;
        return (totalLiq + newLiq) / Math.max(0, debt + newDebt);
      }

      // close long
      if (longBalance >= inputAmount) {
        const longSent = inputAmount;
        const newLiq = -premiumReceived;
        const newDebt = -longSent * underPrice;
        return (totalLiq + newLiq) / Math.max(0, debt + newDebt);
      }

      // close long open short
      const longSent = longBalance;
      const shortReceived = inputAmount - longBalance;
      const newLiq = (shortReceived - longSent) * underPrice;
      const newDebt = (shortReceived - longSent) * underPrice;
      return (totalLiq + newLiq) / Math.max(0, debt + newDebt);
    }
  };

  getMaxForBuyOptions = () => {
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    const market = this.props.market;
    const baseBalance = parseFloat(this.props.selectedBaseTokenBalances.normalizedAccountBalance);

    // 1 - get long amount up to max price impact
    // todo: max impact for futures
    const maxImpactMaxInput = this.props.getMaxLongAmount(market, true);

    // 2 - market too small or empty
    const maxOptionsBeingSoldOnMarket = getMaxLongAmountWhenBuyingOptions(market.marketHelper);

    // 3 - insufficient funds
    const maxBalanceInputAmount = Math.max(0.01, getInputAmountWhenBuyingOptionsUpToBaseBalance(market, baseBalance, underPrice));

    // 4 - insufficient health
    const minHealthMaxAmount = this.getMinHealthMaxBuyAmount(maxOptionsBeingSoldOnMarket, market.marketHelper, underPrice, false);

    // 5 - price more than limit price
    const oraclePriceMaxAmount = getOraclePriceMaxBuyAmount(maxOptionsBeingSoldOnMarket, market);

    // use smallest amount for input
    let finalInputAmount = Math.min(maxImpactMaxInput, maxOptionsBeingSoldOnMarket, maxBalanceInputAmount, minHealthMaxAmount, oraclePriceMaxAmount);
    finalInputAmount = (Math.floor(finalInputAmount / 0.01) * 0.01).toFixed(2); // 2 decimals but takes smaller value (4.999999 is 4.99 not 5)

    return toPreciseString(finalInputAmount, 18);
  };

  getMaxForBuyFutures = () => {
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    const market = this.props.market;

    // 1 - get long amount up to max price impact
    // todo: max impact for futures
    const maxImpactMaxInput = this.props.getMaxLongAmount(market, true);
    console.log("mfb1", maxImpactMaxInput);

    // 2 - market too small or empty
    const maxFuturesBeingSoldOnMarket = getMaxLongAmountWhenBuyingFutures(market.marketHelper);
    console.log("mfb2", maxFuturesBeingSoldOnMarket);

    // 3 - insufficient funds in lending pool
    const maxLendMaxAmount = getMaxLendAmount(market, this.props.marginPoolData, true, underPrice);
    console.log("mfb3", maxLendMaxAmount);

    // 4 - insufficient health
    const minHealthMaxAmount = this.getMinHealthMaxBuyAmount(maxFuturesBeingSoldOnMarket, market.marketHelper, underPrice, true);
    console.log("mfb4", minHealthMaxAmount);

    // use smallest amount for input
    let finalInputAmount = Math.min(maxImpactMaxInput, maxFuturesBeingSoldOnMarket, maxLendMaxAmount, minHealthMaxAmount);
    finalInputAmount = (Math.floor(finalInputAmount / 0.01) * 0.01).toFixed(2); // 2 decimals but takes smaller value (4.999999 is 4.99 not 5)

    return toPreciseString(finalInputAmount, 18);
  };

  onMaxBuyButtonClick = () => {
    const isFuture = this.props.market.isFuture;
    const maxInputAmount = isFuture ? this.getMaxForBuyFutures() : this.getMaxForBuyOptions();
    this.buyInputRef.current.value = maxInputAmount;
    this.onBuyInputChange();
  };

  getMaxForSellOptions = () => {
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    const market = this.props.market;

    // 1 - get long amount up to max price impact
    const maxImpactMaxInput = this.props.getMaxLongAmount(market, false);

    // 2 - market too small or empty
    const maxOptionsBeingBoughtOnMarket = getMaxLongAmountWhenSellingOptions(market.marketHelper);

    // 3 - insufficient funds in lending pool
    const maxLendMaxAmount = getMaxLendAmount(market, this.props.marginPoolData, false, underPrice);

    // 4 - insufficient health
    const minHealthMaxAmount = this.getMinHealthMaxSellAmount(maxOptionsBeingBoughtOnMarket, market.marketHelper, underPrice, false);

    // 5 - price less than limit price
    const oraclePriceMaxAmount = getOraclePriceMaxSellAmount(maxOptionsBeingBoughtOnMarket, market);

    // use smallest amount for input
    let finalInputAmount = Math.min(maxImpactMaxInput, maxOptionsBeingBoughtOnMarket, maxLendMaxAmount, minHealthMaxAmount, oraclePriceMaxAmount);
    finalInputAmount = (Math.floor(finalInputAmount / 0.01) * 0.01).toFixed(2);

    return toPreciseString(finalInputAmount, 18);
  };

  getMaxForSellFutures = () => {
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    const market = this.props.market;

    // 1 - get long amount up to max price impact
    // todo: max impact for futures
    const maxImpactMaxInput = this.props.getMaxLongAmount(market, false);
    console.log("mfs1", maxImpactMaxInput);

    // 2 - market too small or empty
    const maxOptionsBeingBoughtOnMarket = getMaxLongAmountWhenSellingFutures(market.marketHelper);
    console.log("mfs2", maxOptionsBeingBoughtOnMarket);

    // 3 - insufficient funds in lending pool
    const maxLendMaxAmount = getMaxLendAmount(market, this.props.marginPoolData, false, underPrice);
    console.log("mfs3", maxLendMaxAmount);

    // 4 - insufficient health
    const minHealthMaxAmount = this.getMinHealthMaxSellAmount(maxOptionsBeingBoughtOnMarket, market.marketHelper, underPrice, true);
    console.log("mfs4", minHealthMaxAmount);

    // use smallest amount for input
    let finalInputAmount = Math.min(maxImpactMaxInput, maxOptionsBeingBoughtOnMarket, maxLendMaxAmount, minHealthMaxAmount);
    finalInputAmount = (Math.floor(finalInputAmount / 0.01) * 0.01).toFixed(2);
    return toPreciseString(finalInputAmount, 18);
  };

  getMinHealthMaxBuyAmount = (maxOptionsBeingSoldOnMarket, marketHelper, underPrice, isFuture) => {
    let inputAmount = maxOptionsBeingSoldOnMarket / 2;
    let step = maxOptionsBeingSoldOnMarket / 2;
    for (let j = 0; j < 40; j++) {
      const premiumPaid = isFuture
        ? getBaseAmountWhenBuyingFutures(marketHelper, inputAmount, underPrice)
        : getBaseAmountWhenBuyingOptions(marketHelper, inputAmount, underPrice);
      const newHealth = isFuture
        ? this.getNewHealthWhenBuyingFutures(inputAmount, premiumPaid)
        : this.getNewHealthWhenBuyingOptionsIncludedFuture(inputAmount, 0, premiumPaid, 0); // todo: take into account futures
      const liquidationThreshold = this.props.market.liquidationThreshold;
      // check if close enough
      if (newHealth > liquidationThreshold && newHealth - liquidationThreshold < 0.0001) {
        break;
      }

      step = step / 2;
      inputAmount += step * (newHealth < liquidationThreshold ? -1 : 1);
    }
    const minHealthMaxAmount = inputAmount;

    return minHealthMaxAmount;
  };

  getMinHealthMaxSellAmount = (maxOptionsBeingBoughtOnMarket, marketHelper, underPrice, isFuture) => {
    let inputAmount = maxOptionsBeingBoughtOnMarket / 2;
    let step = maxOptionsBeingBoughtOnMarket / 2;
    for (let j = 0; j < 40; j++) {
      const premiumReceived = isFuture
        ? getBaseAmountWhenSellingFutures(marketHelper, inputAmount, underPrice)
        : getBaseAmountWhenSellingOptions(marketHelper, inputAmount, underPrice);
      const newHealth = isFuture
        ? this.getNewHealthWhenSellingFutures(inputAmount, premiumReceived)
        : this.getNewHealthWhenSellingOptionsIncludedFuture(inputAmount, 0, premiumReceived, 0); // todo: include futures if needed

      const liquidationThreshold = this.props.market.liquidationThreshold;
      // check if close enough
      if (newHealth > liquidationThreshold && newHealth - liquidationThreshold < 0.0001) {
        break;
      }

      step = step / 2;
      inputAmount += step * (newHealth < liquidationThreshold ? -1 : 1);
    }
    const minHealthMaxAmount = inputAmount;

    return minHealthMaxAmount;
  };

  onMaxSellButtonClick = () => {
    const isFuture = this.props.market.isFuture;
    const maxInputAmount = isFuture ? this.getMaxForSellFutures() : this.getMaxForSellOptions();
    this.sellInputRef.current.value = maxInputAmount;
    this.onSellInputChange();
  };

  displayBigLine = (text, value, symbol, shouldBeDisabled = false) => {
    const textId = getIdFromString(text, false);
    const valueId = getIdFromString(text, true);
    return (
      <div className={`bigLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
        <span id={this.state.idPrefix + textId}>{text}</span>
        <span id={this.state.idPrefix + valueId}>{printPriceFromNumber(value)}</span>
      </div>
    );
  };

  shouldHaveOrangeColor = impact => {
    return (impact.toFixed(1) > 5 && impact < 10) || impact > 10;
  };

  displayPricePerOption = (text, price, priceImpact, shouldBeDisabled = false) => {
    const textId = getIdFromString(text, false);
    const valueId = getIdFromString(text, true);
    const priceStr = printPriceFromNumber(price);

    let priceImpactStr = "0%";
    let textColor = "";
    const priceImpactNegative = -priceImpact;
    if (typeof priceImpact !== "string") {
      priceImpactStr = formatValue(priceImpactNegative, priceImpact <= 2 ? 2 : 1) + "%";
      textColor = this.shouldHaveOrangeColor(priceImpact) && !shouldBeDisabled ? "orange-text" : "";
    }

    return (
      <div className={`smallLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
        <span id={this.state.idPrefix + textId}>{text}</span>
        <span id={this.state.idPrefix + valueId}>
          {priceStr} (<span className={textColor}>{priceImpactStr}</span>)
        </span>
      </div>
    );
  };

  displayVolatilityPerOption = (text, volatility, volatilityImpact, shouldBeDisabled = false) => {
    const textId = getIdFromString(text, false);
    const valueId = getIdFromString(text, true);
    const volatilityStr = formatValue(Number(volatility), 1) + "%";

    let volatilityImpactStr = "0%";
    let textColor = "";
    const volatilityImpactNegative = -volatilityImpact;
    if (typeof volatilityImpact !== "string") {
      volatilityImpactStr = formatValue(volatilityImpactNegative, volatilityImpact <= 2 ? 2 : 1) + "%";
      textColor = this.shouldHaveOrangeColor(volatilityImpact) ? "orange-text" : "";
      shouldBeDisabled && (textColor = "disabledTextColor");
    }

    return (
      <div className={`smallLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
        <span id={this.state.idPrefix + textId}>{text}</span>
        <span id={this.state.idPrefix + valueId}>
          {volatilityStr} (<span className={textColor}>{volatilityImpactStr}</span>)
        </span>
      </div>
    );
  };

  displayHealth = (text, value, shouldBeDisabled = false) => {
    const textId = getIdFromString(text, false);
    const valueId = getIdFromString(text, true);
    const health = value;

    const recoveryThreshold = this.props.marginAccountData ? parseFloat(fromBn(this.props.marginAccountData.recoveryThreshold)) * 100 : 0;
    const liquidationThreshold = this.props.marginAccountData ? parseFloat(fromBn(this.props.marginAccountData.liquidationThreshold)) * 100 : 0;

    const readableHealth = health !== Infinity ? (health < 1000 ? Number(health).toFixed(1) + "%" : "excellent") : "excellent";
    let healthColorClass = this.props.marginAccountData ? healthClassName(readableHealth, health, recoveryThreshold, liquidationThreshold) : "";

    shouldBeDisabled && (healthColorClass = "disabledTextColor");

    return (
      <div className={`smallLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
        <span id={this.state.idPrefix + textId}>{text}</span>
        <span id={this.state.idPrefix + valueId} className={healthColorClass}>
          {readableHealth}
        </span>
      </div>
    );
  };

  displayLine = (text, value, shouldBeDisabled = false) => {
    const textId = getIdFromString(text, false);
    const valueId = getIdFromString(text, true);
    return (
      <div className={`smallLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
        <span id={this.state.idPrefix + textId}>{text}</span>
        <span id={this.state.idPrefix + valueId}>{value}</span>
      </div>
    );
  };

  getNewBorrowRateWhenBuyingFutures = () => {
    let newAPR = 0;
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    if (this.state.buyTabHealth !== Infinity) {
      // we need borrowed amount both usd and eth as well as pool supply to get APR
      const marginPoolSupplyUnder = this.props.marginPoolData.tokensData[1].normalizedCurrentSupply;
      const marginPoolBorrowedUnder = this.props.marginPoolData.tokensData[1].normalizedBorrowedAmount;
      const availableUnder = marginPoolSupplyUnder - marginPoolBorrowedUnder;

      const marginPoolSupplyBase = this.props.marginPoolData.tokensData[0].normalizedCurrentSupply;
      const marginPoolBorrowedBase = this.props.marginPoolData.tokensData[0].normalizedBorrowedAmount;
      const availableBase = marginPoolSupplyBase - marginPoolBorrowedBase;

      // old user debt
      const oldUserBorrowedUnder = Math.abs(this.props.marginAccountData.collateralTokens[1].normalizedBorrowedAmount);
      const oldUserBorrowedBase = Math.abs(this.props.marginAccountData.collateralTokens[0].normalizedBorrowedAmount);

      let newUserBorrowedBase = 0;
      let newUserBorrowedUnder = 0;
      if (this.state.buyTabType === "openLong") {
        // buy futures -> borrow USD
        newUserBorrowedBase += this.state.buyTabBaseAmount;
      } else if (this.state.buyTabType === "closeShort") {
        // buy futures, reduce short -> reduce borrowed ETH
        newUserBorrowedUnder -= this.state.buyTabInputAmount;
      } else if (this.state.buyTabType === "closeShortOpenLong") {
        newUserBorrowedUnder -= this.state.buyTabShortAmount;
        newUserBorrowedBase += this.state.buyTabBaseAmount.secondAmount;
      }

      const newAPRUnder = getAPR(availableUnder, marginPoolBorrowedUnder, newUserBorrowedUnder, "BORROW", this.props.dispatch).normalizedAPR * 100;
      const newAPRBase = getAPR(availableBase, marginPoolBorrowedBase, newUserBorrowedBase, "BORROW", this.props.dispatch).normalizedAPR * 100;

      // get USD share in total debt
      const totalUserBorrowedBase = oldUserBorrowedBase + newUserBorrowedBase;
      const totalUserBorrowedUnder = oldUserBorrowedUnder + newUserBorrowedUnder;
      const shareBase = totalUserBorrowedBase / (totalUserBorrowedBase + totalUserBorrowedUnder * underPrice);
      newAPR = newAPRBase * shareBase + newAPRUnder * (1 - shareBase);
    }

    return newAPR;
  };

  getNewBorrowRateWhenSellingFutures = () => {
    let newAPR = 0;
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    if (this.state.sellTabHealth !== Infinity) {
      // we need borrowed amount both usd and eth as well as pool supply to get APR
      const marginPoolSupplyUnder = this.props.marginPoolData.tokensData[1].normalizedCurrentSupply;
      const marginPoolBorrowedUnder = this.props.marginPoolData.tokensData[1].normalizedBorrowedAmount;
      const availableUnder = marginPoolSupplyUnder - marginPoolBorrowedUnder;

      const marginPoolSupplyBase = this.props.marginPoolData.tokensData[0].normalizedCurrentSupply;
      const marginPoolBorrowedBase = this.props.marginPoolData.tokensData[0].normalizedBorrowedAmount;
      const availableBase = marginPoolSupplyBase - marginPoolBorrowedBase;

      // old user debt
      const oldUserBorrowedUnder = Math.abs(this.props.marginAccountData.collateralTokens[1].normalizedBorrowedAmount);
      const oldUserBorrowedBase = Math.abs(this.props.marginAccountData.collateralTokens[0].normalizedBorrowedAmount);

      let newUserBorrowedBase = 0;
      let newUserBorrowedUnder = 0;
      if (this.state.sellTabType === "openShort") {
        // sell futures -> borrow ETH
        newUserBorrowedUnder += this.state.sellTabInputAmount;
      } else if (this.state.sellTabType === "closeLong") {
        // sell futures, reduce long, -> reduce borrowed USD
        newUserBorrowedBase -= this.state.sellTabBaseAmount;
      } else if (this.state.sellTabType === "closeLongOpenShort") {
        newUserBorrowedBase -= this.state.sellTabBaseAmount.firstAmount;
        newUserBorrowedUnder += this.state.sellTabLongAmount;
      }

      const newAPRUnder = getAPR(availableUnder, marginPoolBorrowedUnder, newUserBorrowedUnder, "BORROW", this.props.dispatch).normalizedAPR * 100;
      const newAPRBase = getAPR(availableBase, marginPoolBorrowedBase, newUserBorrowedBase, "BORROW", this.props.dispatch).normalizedAPR * 100;

      // get USD share in total debt
      const totalUserBorrowedBase = oldUserBorrowedBase + newUserBorrowedBase;
      const totalUserBorrowedUnder = oldUserBorrowedUnder + newUserBorrowedUnder;
      const shareBase = totalUserBorrowedBase / (totalUserBorrowedBase + totalUserBorrowedUnder * underPrice);
      newAPR = newAPRBase * shareBase + newAPRUnder * (1 - shareBase);
    }

    return newAPR;
  };

  getNewBorrowRateWhenBuyingOptions = () => {
    let newAPR = 0;
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    const isDeltaHedging = this.state.buyTabFutureInputAmount !== 0;
    const isCall = this.props.market.isCall;

    // we need borrowed amount both usd and eth as well as pool supply to get APR
    const marginPoolSupplyUnder = this.props.marginPoolData.tokensData[1].normalizedCurrentSupply;
    const marginPoolBorrowedUnder = this.props.marginPoolData.tokensData[1].normalizedBorrowedAmount;
    const availableUnder = marginPoolSupplyUnder - marginPoolBorrowedUnder;

    const marginPoolSupplyBase = this.props.marginPoolData.tokensData[0].normalizedCurrentSupply;
    const marginPoolBorrowedBase = this.props.marginPoolData.tokensData[0].normalizedBorrowedAmount;
    const availableBase = marginPoolSupplyBase - marginPoolBorrowedBase;

    // old user debt
    const oldUserBorrowedUnder = Math.abs(this.props.marginAccountData.collateralTokens[1].normalizedBorrowedAmount);
    const oldUserBorrowedBase = Math.abs(this.props.marginAccountData.collateralTokens[0].normalizedBorrowedAmount);

    let newUserBorrowedBase = 0;
    let newUserBorrowedUnder = 0;
    if (this.state.buyTabType === "openLong") {
      // buy options - no borrow
      if (isDeltaHedging) {
        if (isCall) {
          // buy call options, sell futures -> borrow ETH
          newUserBorrowedUnder += this.state.buyTabFutureInputAmount; // todo: could be reducing future position
        } else {
          // buy put options, buy futures -> borrow USD
          newUserBorrowedBase += this.state.buyTabFutureInputAmount * underPrice;
        }
      }
    } else if (this.state.buyTabType === "closeShort") {
      if (isCall) {
        // buy call options -> reduce borrowed ETH
        newUserBorrowedUnder -= this.state.buyTabInputAmount;
      } else {
        // buy put options -> reduce borrowed USD
        newUserBorrowedBase -= this.state.buyTabCollateralAmount;
      }
    } else if (this.state.buyTabType === "closeShortOpenLong") {
      if (isCall) {
        // buy call options -> reduce borrowed ETH to 0
        newUserBorrowedUnder -= oldUserBorrowedUnder;
      } else {
        // buy put options -> reduce borrowed USD to 0
        newUserBorrowedBase -= oldUserBorrowedBase;
      }
    }

    // console.log("total borrowed base after tx:", oldUserBorrowedBase + newUserBorrowedBase);
    // console.log("total borrowed under after tx:", oldUserBorrowedUnder + newUserBorrowedUnder);
    const newAPRUnder = getAPR(availableUnder, marginPoolBorrowedUnder, newUserBorrowedUnder, "BORROW", this.props.dispatch).normalizedAPR * 100;
    const newAPRBase = getAPR(availableBase, marginPoolBorrowedBase, newUserBorrowedBase, "BORROW", this.props.dispatch).normalizedAPR * 100;

    // get USD share in total debt
    const totalUserBorrowedBase = oldUserBorrowedBase + newUserBorrowedBase;
    const totalUserBorrowedUnder = oldUserBorrowedUnder + newUserBorrowedUnder;
    if (totalUserBorrowedBase + totalUserBorrowedUnder === 0) {
      return 0;
    }

    const shareBase = totalUserBorrowedBase / (totalUserBorrowedBase + totalUserBorrowedUnder * underPrice);
    newAPR = newAPRBase * shareBase + newAPRUnder * (1 - shareBase);

    return newAPR;
  };

  getNewBorrowRateWhenSellingOptions = () => {
    let newAPR = 0;
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    const isDeltaHedging = this.state.sellTabFutureInputAmount !== 0;
    const isCall = this.props.market.isCall;

    // we need borrowed amount both usd and eth as well as pool supply to get APR
    const marginPoolSupplyUnder = this.props.marginPoolData.tokensData[1].normalizedCurrentSupply;
    const marginPoolBorrowedUnder = this.props.marginPoolData.tokensData[1].normalizedBorrowedAmount;
    const availableUnder = marginPoolSupplyUnder - marginPoolBorrowedUnder;

    const marginPoolSupplyBase = this.props.marginPoolData.tokensData[0].normalizedCurrentSupply;
    const marginPoolBorrowedBase = this.props.marginPoolData.tokensData[0].normalizedBorrowedAmount;
    const availableBase = marginPoolSupplyBase - marginPoolBorrowedBase;

    // old user debt
    const oldUserBorrowedUnder = Math.abs(this.props.marginAccountData.collateralTokens[1].normalizedBorrowedAmount);
    const oldUserBorrowedBase = Math.abs(this.props.marginAccountData.collateralTokens[0].normalizedBorrowedAmount);

    let newUserBorrowedBase = 0;
    let newUserBorrowedUnder = 0;
    if (this.state.sellTabType === "openShort") {
      if (isCall) {
        // sell call options -> borrow ETH
        newUserBorrowedUnder += this.state.sellTabInputAmount;
      } else {
        // sell put options -> borrow USD
        newUserBorrowedBase += this.state.sellTabCollateralAmount;
      }

      if (isDeltaHedging) {
        if (isCall) {
          // sell call options, buy futures -> borrow USD
          newUserBorrowedBase += this.state.sellTabFutureBaseAmount; // todo: could be reducing future position
        } else {
          // sell put options, sell futures -> borrow ETH
          newUserBorrowedUnder += this.state.sellTabFutureInputAmount;
        }
      }
    } else if (this.state.sellTabType === "closeLong") {
      // do nothing
    } else if (this.state.sellTabType === "closeLongOpenShort") {
      if (isCall) {
        // sell call options -> increase borrowed ETH to short part
        newUserBorrowedUnder += this.state.sellTabCollateralAmount;
      } else {
        // sell put options -> increase borrowed USD to short part
        newUserBorrowedBase += this.state.sellTabCollateralAmount;
      }
    }

    // console.log("total borrowed base after tx:", oldUserBorrowedBase + newUserBorrowedBase);
    // console.log("total borrowed under after tx:", oldUserBorrowedUnder + newUserBorrowedUnder);
    const newAPRUnder = getAPR(availableUnder, marginPoolBorrowedUnder, newUserBorrowedUnder, "BORROW", this.props.dispatch).normalizedAPR * 100;
    const newAPRBase = getAPR(availableBase, marginPoolBorrowedBase, newUserBorrowedBase, "BORROW", this.props.dispatch).normalizedAPR * 100;

    // get USD share in total debt
    const totalUserBorrowedBase = oldUserBorrowedBase + newUserBorrowedBase;
    const totalUserBorrowedUnder = oldUserBorrowedUnder + newUserBorrowedUnder;
    if (totalUserBorrowedBase + totalUserBorrowedUnder === 0) {
      return 0;
    }

    const shareBase = totalUserBorrowedBase / (totalUserBorrowedBase + totalUserBorrowedUnder * underPrice);
    newAPR = newAPRBase * shareBase + newAPRUnder * (1 - shareBase);

    return newAPR;
  };

  displayBuyBalancesTransitions = (buyTabPropsState, shouldBePrintedDefault) => {
    if (this.props.market) {
      const isOpenLongOrCloseShort = buyTabPropsState.buyTabType === "openLong" || buyTabPropsState.buyTabType === "closeShort";
      const optionPremiumPaid = isOpenLongOrCloseShort
        ? buyTabPropsState.buyTabBaseAmount
        : buyTabPropsState.buyTabBaseAmount.firstAmount + buyTabPropsState.buyTabBaseAmount.secondAmount;
      const baseSymbol = this.props.selectedTokenPair.baseTokenSymbol;
      const pricePerOption = optionPremiumPaid / buyTabPropsState.buyTabInputAmount;
      const priceImpact = buyTabPropsState.buyTabPriceImpact;

      const isFuture = this.props.market.isFuture;
      const isCall = this.props.market.isCall;
      const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
      const strikePrice = parseFloat(this.props.market.normalizedStrikePrice);
      const expirationTime = this.props.market.expirationTime;
      const riskFreeRate = this.props.selectedTokenPair.riskFreeRate / 100;

      const openLongPrice = this.props.market.openLongPrice;
      const minVolatilityPerOption = getImpliedVolatility(riskFreeRate, isCall, expirationTime, strikePrice, underPrice, openLongPrice) * 100;
      const volatilityPerOption = getImpliedVolatility(riskFreeRate, isCall, expirationTime, strikePrice, underPrice, pricePerOption) * 100;
      const volatilityImpact = ((volatilityPerOption - minVolatilityPerOption) / minVolatilityPerOption) * 100;

      const priceStr = printPriceFromNumber(pricePerOption);

      const priceImpactStr = formatValue(priceImpact, priceImpact <= 2 ? 2 : 1) + "%";
      const volatilityStr = formatValue(volatilityPerOption, 1) + "%";

      const volatilityImpactStr = formatValue(volatilityImpact, volatilityImpact <= 2 ? 2 : 1) + "%";

      const oldOptionBalances = this.getTradeBalances(this.props.market);
      const openInterest = oldOptionBalances[0] - oldOptionBalances[1] + buyTabPropsState.buyTabInputAmount;

      let health = buyTabPropsState.buyTabHealth;
      const borrowRate = isFuture ? this.getNewBorrowRateWhenBuyingFutures() : this.getNewBorrowRateWhenBuyingOptions();

      const avgPriceStr = isFuture ? "Avg future price" : "Avg option price";
      const instrument = isFuture ? "future" : "option";

      let futurePremiumPaid = 0;
      let pricePerFuture = 0;
      if (!isFuture && this.props.futureMarket) {
        futurePremiumPaid = isOpenLongOrCloseShort
          ? this.state.buyTabFutureBaseAmount
          : this.state.buyTabFutureBaseAmount.firstAmount + this.state.buyTabFutureBaseAmount.secondAmount;
        pricePerFuture = futurePremiumPaid / this.state.buyTabFutureInputAmount;
      }

      const futurePriceShouldBeShow = this.state.futureBuyPanelExpanded && !isFuture && this.props.futureMarket;

      // net premium calclulation
      futurePremiumPaid *= isCall ? -1 : 1;
      const netPremium = -(optionPremiumPaid + futurePremiumPaid);

      return (
        <>
          {this.displayPricePerOption(avgPriceStr, pricePerOption, priceImpact, shouldBePrintedDefault)}
          {!isFuture ? this.displayVolatilityPerOption("Avg option volatility", volatilityPerOption, volatilityImpact, shouldBePrintedDefault) : null}
          {futurePriceShouldBeShow && this.displayPricePerOption("Avg future price", pricePerFuture, this.state.buyTabFuturePriceImpact, shouldBePrintedDefault)}
          <br />
          {this.displayLine("Open interest", formatValue(openInterest, 2), shouldBePrintedDefault)}
          {this.displayHealth("Account health", formatValue(health, 1), shouldBePrintedDefault)}
          {this.displayLine("Borrow rate", formatValue(borrowRate, 2) + "%", shouldBePrintedDefault)}
          {this.displayBigLine("Net Premium", netPremium, baseSymbol, shouldBePrintedDefault)}
          <MyTooltip
            key={buyTabPropsState.idPrefix + getIdFromString(avgPriceStr)}
            target={buyTabPropsState.idPrefix + getIdFromString(avgPriceStr)}
            optionalOffset={15}
          >
            Average {instrument} price {priceStr} shows the average price per {instrument} you are paying. <br /> <br />
            Price impact ({priceImpactStr}) shows by how much the average price is higher than lowest ask price. <br /> <br />
          </MyTooltip>
          <MyTooltip
            key={buyTabPropsState.idPrefix + getIdFromString("Avg option volatility")} // todo: target can be removed, but MyTooltip should be updated first
            target={buyTabPropsState.idPrefix + getIdFromString("Avg option volatility")}
            optionalOffset={15}
          >
            Average option volatility {volatilityStr} shows the average volatility per option you are paying. <br /> <br />
            Volatility impact ({volatilityImpactStr}) shows by how much the average volatility is higher than lowest ask volatility. <br /> <br />
          </MyTooltip>
          <MyTooltip
            key={buyTabPropsState.idPrefix + getIdFromString("Open interest")}
            target={buyTabPropsState.idPrefix + getIdFromString("Open interest")}
            optionalOffset={15}
          >
            New open interest after opening position.
          </MyTooltip>
          <MyTooltip
            key={buyTabPropsState.idPrefix + getIdFromString("Account health")}
            target={buyTabPropsState.idPrefix + getIdFromString("Account health")}
            optionalOffset={15}
          >
            New account health after opening position. <br /> <br />
            Health is the ratio betweeen total account liquidity (including debt) and debt.
          </MyTooltip>
          <MyTooltip
            key={buyTabPropsState.idPrefix + getIdFromString("Borrow rate")}
            target={buyTabPropsState.idPrefix + getIdFromString("Borrow rate")}
            optionalOffset={15}
          >
            New annual borrow rate (APR) for the account after opening position. <br /> <br />
            Borrow rate can change over time as liquidity is added/removed from Margin pool, or when LPs/traders borrow/repay debt.
          </MyTooltip>
          <MyTooltip
            key={buyTabPropsState.idPrefix + getIdFromString("Net Premium")}
            target={buyTabPropsState.idPrefix + getIdFromString("Net Premium")}
            optionalOffset={15}
          >
            Net premium is the sum of option premium and future premium (if you are delta hedging).
          </MyTooltip>
        </>
      );
    }
  };

  displaySellBalancesTransitions = (sellTabPropsState, shouldBePrintedDefault) => {
    if (this.props.market) {
      const isOpenShortOrCloseLong = sellTabPropsState.sellTabType === "openShort" || sellTabPropsState.sellTabType === "closeLong";

      const optionPremiumReceived = isOpenShortOrCloseLong
        ? sellTabPropsState.sellTabBaseAmount
        : sellTabPropsState.sellTabBaseAmount.firstAmount + sellTabPropsState.sellTabBaseAmount.secondAmount;
      const baseSymbol = this.props.selectedTokenPair.baseTokenSymbol;
      const pricePerOption = optionPremiumReceived / sellTabPropsState.sellTabInputAmount;
      const priceImpact = sellTabPropsState.sellTabPriceImpact;

      const isFuture = this.props.market.isFuture;
      const isCall = this.props.market.isCall;
      const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
      const strikePrice = parseFloat(this.props.market.normalizedStrikePrice);
      const expirationTime = this.props.market.expirationTime;
      const riskFreeRate = this.props.selectedTokenPair.riskFreeRate / 100;

      const closeLongPrice = this.props.market.closeLongPrice;
      const maxVolatilityPerOption = getImpliedVolatility(riskFreeRate, isCall, expirationTime, strikePrice, underPrice, closeLongPrice) * 100;
      const volatilityPerOption = getImpliedVolatility(riskFreeRate, isCall, expirationTime, strikePrice, underPrice, pricePerOption) * 100;
      const volatilityImpact = ((maxVolatilityPerOption - volatilityPerOption) / maxVolatilityPerOption) * 100;

      const priceStr = printPriceFromNumber(pricePerOption);
      const priceImpactStr = "-" + formatValue(priceImpact, priceImpact <= 2 ? 2 : 1) + "%";
      const volatilityStr = formatValue(volatilityPerOption, 1) + "%";
      const volatilityImpactStr = "-" + formatValue(volatilityImpact, volatilityImpact <= 2 ? 2 : 1) + "%";

      const oldOptionBalances = this.getTradeBalances(this.props.market);
      const openInterest = oldOptionBalances[0] - oldOptionBalances[1] - sellTabPropsState.sellTabInputAmount;

      let health = sellTabPropsState.sellTabHealth;
      const borrowRate = isFuture ? this.getNewBorrowRateWhenSellingFutures() : this.getNewBorrowRateWhenSellingOptions();

      const avgPriceStr = isFuture ? "Avg future price" : "Avg option price";
      const instrument = isFuture ? "future" : "option";

      let futurePremiumReceived = 0;
      let pricePerFuture = 0;
      if (!isFuture && this.props.futureMarket) {
        futurePremiumReceived = isOpenShortOrCloseLong
          ? this.state.sellTabFutureBaseAmount
          : this.state.sellTabFutureBaseAmount.firstAmount + this.state.sellTabFutureBaseAmount.secondAmount;
        pricePerFuture = futurePremiumReceived / this.state.sellTabFutureInputAmount;
      }

      const futurePriceShouldBeShow = this.state.futureSellPanelExpanded && !isFuture && this.props.futureMarket;

      // net premium calclulation
      futurePremiumReceived *= isCall ? -1 : 1;
      const netPremium = optionPremiumReceived + futurePremiumReceived;

      return (
        <>
          {this.displayPricePerOption(avgPriceStr, pricePerOption, priceImpact, shouldBePrintedDefault)}
          {!isFuture ? this.displayVolatilityPerOption("Avg option volatility", volatilityPerOption, volatilityImpact, shouldBePrintedDefault) : null}
          {futurePriceShouldBeShow && this.displayPricePerOption("Avg future price", pricePerFuture, this.state.sellTabFuturePriceImpact, shouldBePrintedDefault)}
          <br />
          {this.displayLine("Open interest", formatValue(openInterest, 2), shouldBePrintedDefault)}
          {this.displayHealth("Account health", formatValue(health, 1), shouldBePrintedDefault)}
          {this.displayLine("Borrow rate", formatValue(borrowRate, 2) + "%", shouldBePrintedDefault)}
          {this.displayBigLine("Net Premium", netPremium, baseSymbol, shouldBePrintedDefault)}
          <MyTooltip
            key={sellTabPropsState.idPrefix + getIdFromString(avgPriceStr)}
            target={sellTabPropsState.idPrefix + getIdFromString(avgPriceStr)}
            optionalOffset={15}
          >
            Average {instrument} price {priceStr} shows the average price per {instrument} you are receiving. <br /> <br />
            Price impact ({priceImpactStr}) shows by how much the average price is lower than highest bid price. <br /> <br />
          </MyTooltip>
          <MyTooltip
            key={sellTabPropsState.idPrefix + getIdFromString("Avg option volatility")}
            target={sellTabPropsState.idPrefix + getIdFromString("Avg option volatility")}
            optionalOffset={15}
          >
            Average option volatility {volatilityStr} shows the average volatility per option you are receiving. <br /> <br />
            Volatility impact ({volatilityImpactStr}) shows by how much the average volatility is lower than highest bid price. <br /> <br />
          </MyTooltip>
          <MyTooltip
            key={sellTabPropsState.idPrefix + getIdFromString("Open interest")}
            target={sellTabPropsState.idPrefix + getIdFromString("Open interest")}
            optionalOffset={15}
          >
            New open interest after opening position.
          </MyTooltip>
          <MyTooltip
            key={sellTabPropsState.idPrefix + getIdFromString("Account health")}
            target={sellTabPropsState.idPrefix + getIdFromString("Account health")}
            optionalOffset={15}
          >
            New account health after opening position. <br /> <br />
            Health is the ratio betweeen total account liquidity (including debt) and debt.
          </MyTooltip>
          <MyTooltip
            key={sellTabPropsState.idPrefix + getIdFromString("Borrow rate")}
            target={sellTabPropsState.idPrefix + getIdFromString("Borrow rate")}
            optionalOffset={15}
          >
            New annual borrow rate (APR) for the account after opening position. <br /> <br />
            Borrow rate can change over time as liquidity is added/removed from Margin pool, or when LPs/traders borrow/repay debt.
          </MyTooltip>
          <MyTooltip
            key={sellTabPropsState.idPrefix + getIdFromString("Net Premium")}
            target={sellTabPropsState.idPrefix + getIdFromString("Net Premium")}
            optionalOffset={15}
          >
            Net premium is the sum of option premium and future premium (if you are delta hedging).
          </MyTooltip>
        </>
      );
    }
  };

  toggleTransactionSettingsModal = () => {
    this.setState({
      isTransactionSettingsModalOpen: !this.state.isTransactionSettingsModalOpen
    });
  };

  setSlippageTolerance = value => {
    this.setState({
      slippageTolerance: value
    });
  };

  setTransactionTimeout = value => {
    this.setState({
      transactionTimeout: value
    });
  };

  clearInput = e => {
    if (e.target.previousSibling.id.includes("buy")) {
      this.buyInputRef.current.value = "";
      this.setState({ buyTabInputClass: "", buyTabInputValue: "" });
      this.buyInputRef.current.focus();
    } else if (e.target.previousSibling.id.includes("sell")) {
      this.sellInputRef.current.value = "";
      this.setState({ sellTabInputClass: "", sellTabInputValue: "" });
      this.sellInputRef.current.focus();
    }
  };

  updateChart = (newPositionSelected, standardDeviation) => {
    let selectedVolatility = this.state.selectedVolatility;
    // if in all positions mode, set selectedVolatility to relative value
    if (!newPositionSelected) {
      if (this.props.openedTradePositions.length > 0 || this.props.openedLPPositions.length > 0) {
        selectedVolatility = 0;
      }
    } else {
      selectedVolatility = Math.round(parseFloat(this.props.market.longPriceInVol));
    }

    if (this.props.tabs === "buy") {
      const baseAmount =
        this.state.buyTabBaseAmount.length === 2
          ? this.state.buyTabBaseAmount.firstAmount + this.state.buyTabBaseAmount.secondAmount
          : this.state.buyTabBaseAmount;
      const optionPrice = baseAmount / this.state.buyTabInputAmount;
      const returnData = this.getLongAndFuturePositionAndChart(
        this.state.buyTabInputAmount,
        this.state.buyTabFutureInputAmount,
        optionPrice,
        newPositionSelected,
        selectedVolatility,
        standardDeviation
      );

      this.setState(
        {
          chartData: returnData.chartData,
          newPositionSelected: newPositionSelected,
          standardDeviation: standardDeviation,
          selectedVolatility: selectedVolatility
        },
        () => {
          this.calculateLiqPrice(returnData.positions, this.state.selectedDaysToExpiry, this.state.selectedVolatility);
        }
      );
    }

    if (this.props.tabs === "sell") {
      const baseAmount =
        Object.keys(this.state.sellTabBaseAmount).length === 2
          ? this.state.sellTabBaseAmount.firstAmount + this.state.sellTabBaseAmount.secondAmount
          : this.state.sellTabBaseAmount;
      const optionPrice = baseAmount / this.state.sellTabInputAmount;
      const returnData = this.getShortAndFuturePositionAndChart(
        this.state.sellTabInputAmount,
        this.state.sellTabFutureInputAmount,
        optionPrice,
        newPositionSelected,
        this.state.selectedVolatility,
        standardDeviation
      );

      this.setState(
        {
          chartData: returnData.chartData,
          newPositionSelected: newPositionSelected,
          standardDeviation: standardDeviation,
          selectedVolatility: selectedVolatility
        },
        () => {
          this.calculateLiqPrice(returnData.positions, this.state.selectedDaysToExpiry, this.state.selectedVolatility);
        }
      );
    }
  };

  onAllNewSwitchChange = () => {
    this.updateChart(!this.state.newPositionSelected, this.state.standardDeviation);

    if (this.buyInputRef.current) {
      this.buyInputRef.current.focus();
    } else if (this.sellInputRef.current) {
      this.sellInputRef.current.focus();
    }
  };

  onStandardDeviationChange = newDeviation => {
    this.updateChart(this.state.newPositionSelected, newDeviation);
  };

  increaseQuantityBuyHandler = e => {
    e.stopPropagation();
    const value = this.buyInputRef.current.value;
    if (value || value == 0) {
      let newValue;
      const decimalsParts = value.split(".");
      if (decimalsParts.length === 2) {
        newValue = Math.ceil(value);
      } else {
        newValue = Number(value) + 1;
      }
      this.buyInputRef.current.value = newValue;
    } else {
      this.buyInputRef.current.value = 1;
    }

    this.onBuyInputChangeDebounced();
  };

  // todo: increase and decrease should be almost the same
  decreaseQuantityBuyHandler = e => {
    e.stopPropagation();
    const value = this.buyInputRef.current.value;

    if (value || value == 0) {
      let newBuyTabInputValue;
      const decimalsParts = value.split(".");
      if (decimalsParts.length === 2) {
        newBuyTabInputValue = Math.floor(value);
      } else {
        newBuyTabInputValue = Number(value) - 1;
      }
      if (newBuyTabInputValue > 0) {
        this.buyInputRef.current.value = newBuyTabInputValue;
        this.onBuyInputChangeDebounced();
      }
    } else {
      this.buyInputRef.current.value = 1;
      this.onBuyInputChangeDebounced();
    }
  };

  increaseQuantitySellHandler = e => {
    e.stopPropagation();
    const value = this.sellInputRef.current.value;

    if (value || value == 0) {
      let newValue;
      const decimalsParts = value.split(".");
      if (decimalsParts.length === 2) {
        newValue = Math.ceil(value);
      } else {
        newValue = Number(value) + 1;
      }
      this.sellInputRef.current.value = newValue;
    } else {
      this.sellInputRef.current.value = 1;
    }

    this.onSellInputChangeDebounced();
  };

  decreaseQuantitySellHandler = e => {
    e.stopPropagation();
    const value = this.sellInputRef.current.value;

    if (value || value == 0) {
      let newSellTabInputValue;
      const decimalsParts = value.split(".");
      if (decimalsParts.length === 2) {
        newSellTabInputValue = Math.floor(value);
      } else {
        newSellTabInputValue = Number(value) - 1;
      }
      if (newSellTabInputValue > 0) {
        this.sellInputRef.current.value = newSellTabInputValue;
        this.onSellInputChangeDebounced();
      }
    } else {
      this.sellInputRef.current.value = 1;
      this.onSellInputChangeDebounced();
    }
  };

  selectXCallback = x => {
    if (x !== this.state.selectedUnderPrice) {
      this.setState({
        selectedUnderPrice: x
      });
    }
  };

  deselectXCallback = () => {
    this.setState({
      selectedUnderPrice: parseFloat(this.props.underlyingTokenPrice.normalized)
    });
  };

  toggleSetCogChartOptionsOpen = e => {
    e.stopPropagation();
    if (this.props.tabs === "buy") {
      this.props.setCogChartOptionsOpenBuy(!this.props.cogChartOptionsOpenBuy);
    } else if (this.props.tabs === "sell") {
      this.props.setCogChartOptionsOpenSell(!this.props.cogChartOptionsOpenSell);
    }
  };

  onBuyInputChangeDebounced = debounce(() => {
    const optionPremiumPaid = this.onBuyInputChange();
    this.onBuyFutureInputChange(optionPremiumPaid);
  }, 200);

  onBuyFutureInputChangeDebounced = debounce(() => {
    this.onBuyFutureInputChange();
  }, 200);

  onSellInputChangeDebounced = debounce(() => {
    const premiumOptionReceived = this.onSellInputChange();
    this.onSellFutureInputChange(premiumOptionReceived);
  }, 200);

  onSellFutureInputChangeDebounced = debounce(() => {
    this.onSellFutureInputChange();
  }, 200);

  onFuturePanelClickHandler = e => {
    e.stopPropagation();
    const futureTypePanelExpanded = this.props.tabs === "buy" ? "futureBuyPanelExpanded" : "futureSellPanelExpanded";
    const futurePanelExpanded = !this.state[futureTypePanelExpanded];

    // if closed
    if (!futurePanelExpanded) {
      if (this.props.tabs === "buy") {
        this.setState(
          {
            [futureTypePanelExpanded]: false,
            buyTabFutureBaseAmount: 0,
            buyTabFutureInputAmount: defaultInputValue,
            buyTabFutureInputClass: "has-success",
            buyTabFutureInputErrorMessage: "",
            buyTabFuturePreviewPosition: null
          },
          () => {
            this.onBuyInputChange();
          }
        );
      } else {
        this.setState(
          {
            [futureTypePanelExpanded]: false,
            sellTabFutureBaseAmount: 0,
            sellTabFutureLongAmount: 0,
            sellTabFutureInputAmount: defaultInputValue,
            sellTabFutureHealth: 0,
            sellTabFutureInputClass: "has-success",
            sellTabFutureInputErrorMessage: "",
            sellTabFuturePreviewPosition: null
          },
          () => {
            this.onSellInputChange();
          }
        );
      }
    } else {
      this.setState({ [futureTypePanelExpanded]: true }, () => {
        if (this.props.tabs === "buy") {
          this.onBuyInputChange();
        } else {
          this.onSellInputChange();
        }
      });
    }
  };

  render() {
    let shouldBePrintedDefault = false;
    let position = null;
    let chartData = null;

    if (this.props.tabs === "buy") {
      shouldBePrintedDefault = this.state.buyTabInputValue === "" || this.state.buyTabInputErrorMessage.trim().toLowerCase() !== "";
      chartData = shouldBePrintedDefault && this.state.buyObjectValues ? this.state.buyObjectValues.chartData : this.state.chartData;
      position = shouldBePrintedDefault && this.state.buyObjectValues ? this.state.buyObjectValues.buyTabPreviewPosition : this.state.buyTabPreviewPosition;
    } else if (this.props.tabs === "sell") {
      shouldBePrintedDefault = this.state.sellTabInputValue === "" || this.state.sellTabInputErrorMessage.trim().toLowerCase() !== "";
      chartData = shouldBePrintedDefault && this.state.sellObjectValues ? this.state.sellObjectValues.chartData : this.state.chartData;
      position = shouldBePrintedDefault && this.state.sellObjectValues ? this.state.sellObjectValues.sellTabPreviewPosition : this.state.sellTabPreviewPosition;
    }

    const marketIsExpired = this.props.market.isSettled || Number(this.props.market.expirationTime) < this.props.web3Object.currentTimestamp;

    let underPrice = this.state.selectedUnderPrice;
    if (marketIsExpired) {
      const settlement = this.props.settlements.find(s => s.expirationTime === this.props.market.expirationTime);
      if (settlement) {
        underPrice = parseFloat(formatUnits(settlement.actualUnderlyingPrice.toString(), 18));
      }
    }
    const greeks = interpolateGreeks(chartData.series1, underPrice);
    const delta = formatValue(greeks.delta, 2);
    const gamma = formatValue(greeks.gamma, 2);
    const theta = formatValue(greeks.theta, 2);
    const vega = formatValue(greeks.vega, 2);

    const timeToExpiration = Math.abs(this.props.market.expirationTime * 1000 - new Date());
    const daysToExpiry = timeToExpiration / (1000 * 60 * 60 * 24);

    let allPositions = [];

    if (position) {
      allPositions.push(position);
    }

    // if allPositions is selected, send all positions to SlidersPanel
    if (!this.state.newPositionSelected) {
      if (this.props.openedTradePositions && this.props.openedLPPositions && position) {
        allPositions = [position, ...this.props.openedTradePositions, ...this.props.openedLPPositions];
      }
    }

    let shouldHaveOrangeColor = false;
    let buyTabPropsState = null;
    let buyBalancesTransitionsContent = null;
    let sellTabPropsState = null;
    let sellBalancesTransitionsContent = null;
    if (this.props.tabs === "buy") {
      buyTabPropsState = shouldBePrintedDefault && this.state.buyObjectValues ? this.state.buyObjectValues : this.state;
      buyBalancesTransitionsContent = this.displayBuyBalancesTransitions(buyTabPropsState, shouldBePrintedDefault);
      shouldHaveOrangeColor = this.shouldHaveOrangeColor(buyTabPropsState.buyTabPriceImpact) && this.state.buyTabInputErrorMessage === "";
    } else if (this.props.tabs === "sell") {
      sellTabPropsState = shouldBePrintedDefault && this.state.sellObjectValues ? this.state.sellObjectValues : this.state;
      sellBalancesTransitionsContent = this.displaySellBalancesTransitions(sellTabPropsState, shouldBePrintedDefault);
      shouldHaveOrangeColor = this.shouldHaveOrangeColor(sellTabPropsState.sellTabPriceImpact) && this.state.sellTabInputErrorMessage === "";
    }

    const isFuture = this.props.market.isFuture;

    return (
      <>
        <div
          className="modalContent"
          onClick={e => {
            e.stopPropagation();
            this.props.setCogChartOptionsOpenBuy(false);
            this.props.setCogChartOptionsOpenSell(false);
          }}
        >
          {this.state.isTransactionSettingsModalOpen && (
            <TransactionSettingsModal
              isOpen={this.state.isTransactionSettingsModalOpen}
              toggleTransactionSettingsModal={this.toggleTransactionSettingsModal}
              slippageTolerance={this.state.slippageTolerance}
              setSlippageTolerance={this.setSlippageTolerance}
              transactionTimeout={this.state.transactionTimeout}
              setTransactionTimeout={this.setTransactionTimeout}
            />
          )}

          {this.props.tabs === "buy" ? (
            <div id="buy" className="buySellFormContainer">
              <div className="inputsContainer">
                <div className="labelAndInputContainer">
                  <div className="labelAndTooltipContainer">
                    <span id={this.state.idPrefix + "inputTooltipBuy"}>{this.props.market.isFuture ? "Futures" : "Options"} to buy</span>
                    <MyTooltip key={this.state.idPrefix + "inputTooltipBuy"} target={this.state.idPrefix + "inputTooltipBuy"} optionalOffset={15}>
                      Amount of {!isFuture ? "options" : "futures"} to buy.
                    </MyTooltip>
                  </div>
                  <div className="inputAmountAndSettingsContainer">
                    <Form>
                      <FormGroup>
                        <Input
                          innerRef={this.buyInputRef}
                          id={this.state.idPrefix + "buy-amount"}
                          type="text"
                          autoComplete="off"
                          placeholder={`Please enter number (e.g., 1.25)`}
                          className={`inputText${this.state.buyTabInputClass === "has-danger" ? " inputDanger" : ""}${
                            this.state.buyTabInputClass === "has-success" && !shouldHaveOrangeColor ? " inputSuccess" : ""
                          }${this.state.buyTabInputClass === "has-success" && shouldHaveOrangeColor ? " inputWarning" : ""}`}
                          onChange={e => {
                            const inputString = e.target.value.trim();

                            const value = inputDecimals(inputString, "USD");
                            if (value === undefined || value === null) {
                              e.target.value =
                                inputString.slice(0, this.buyInputRef.current.selectionStart - 1) +
                                inputString.slice(this.buyInputRef.current.selectionStart - 1 + 1);
                              this.onBuyInputChangeDebounced();
                              return;
                            }

                            e.target.value = inputString;
                            this.onBuyInputChangeDebounced();
                          }}
                          autoFocus
                        />
                        {this.state.buyTabInputClass === "has-danger" ? <img className="clearInputError" onClick={this.clearInput} /> : null}
                        {this.state.buyTabInputClass === "has-success" && !shouldHaveOrangeColor ? <img className="validInput" /> : null}
                        {this.state.buyTabInputClass === "has-success" && shouldHaveOrangeColor ? <img className="warningInput" /> : null}
                        <div className="increaseDecreaseInputButtons">
                          <img className="increaseInputButton" onClick={this.increaseQuantityBuyHandler} />
                          <img className="decreaseInputButton" onClick={this.decreaseQuantityBuyHandler} />
                        </div>
                      </FormGroup>
                    </Form>
                    <button id={this.state.idPrefix + "buy-max-button"} className="maxButton" onClick={() => this.onMaxBuyButtonClick()}>
                      max
                    </button>
                    <MyTooltip key={this.state.idPrefix + "buy-max-button"} target={this.state.idPrefix + "buy-max-button"} optionalOffset={15}>
                      Calculate maximum {!isFuture ? "options" : "futures"} to buy considering the limits: <br /> <br />
                      1. available liquidity on the market, <br />
                      2. deposited USD in your account, <br />
                      3. account health, <br />
                      4. up to 5% price impact, <br />
                      5. up to 20% volatility change limit
                    </MyTooltip>
                    <button
                      id={this.state.idPrefix + "buy-tx-settings"}
                      className="btn-link settingsCog"
                      disabled={shouldBePrintedDefault}
                      onClick={() => this.toggleTransactionSettingsModal()}
                    />
                    <MyTooltip target={this.state.idPrefix + "buy-tx-settings"}>Transaction settings</MyTooltip>
                  </div>
                </div>

                {this.state.buyTabInputClass === "has-danger" ? (
                  <div className="errorMessageContainerBuySellRemove">
                    <span>{this.state.buyTabInputErrorMessage}</span>
                  </div>
                ) : null}
                {this.state.buyTabInputClass === "has-success" && shouldHaveOrangeColor ? (
                  <div className="warningMessageContainerBuySellRemove">
                    <span>
                      <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#eca70d" }} />
                      &nbsp; Price impact is above 5%!
                    </span>
                  </div>
                ) : null}
              </div>

              {!isFuture && this.props.tabs === "buy" && this.state.buyTabType === "openLong" && this.props.futureMarket ? (
                <div className="futuresTradeContainer">
                  <div className="futureHeaderPanel" onClick={this.onFuturePanelClickHandler}>
                    <span className="futurePanelHeaderText">Delta hedge</span>
                    <img className={`${!this.state.futureBuyPanelExpanded ? "arrowDownIconFuturePanel" : "arrowUpIconFuturePanel"}`} />
                  </div>

                  {this.state.futureBuyPanelExpanded ? (
                    <div className="inputsContainer">
                      <div className="futureLabelAndInputContainer">
                        <div className="labelAndTooltipContainer">
                          <span id={this.state.idPrefix + "inputTooltipBuyFutures"}>
                            Futures to {this.state.buyTabFuturePreviewPosition && this.state.buyTabFuturePreviewPosition.type === "long" ? "buy" : "sell"}
                          </span>
                          <MyTooltip
                            key={this.state.idPrefix + "inputTooltipBuyFutures"}
                            target={this.state.idPrefix + "inputTooltipBuyFutures"}
                            optionalOffset={15}
                          >
                            Amount of futures to{" "}
                            {this.state.buyTabFuturePreviewPosition && this.state.buyTabFuturePreviewPosition.type === "long" ? "buy" : "sell"} to delta hedge.
                          </MyTooltip>
                        </div>
                        <div className="futureInputContainer">
                          <Form style={{ width: "70%" }}>
                            <FormGroup>
                              <Input
                                disabled
                                innerRef={this.buyFutureInputRef}
                                id={this.state.idPrefix + "future-buy-amount"}
                                type="text"
                                autoComplete="off"
                                placeholder="Please enter number (e.g., 1.25)"
                                className={`inputText${this.state.buyTabFutureInputClass === "has-danger" ? " inputDanger" : ""}${
                                  this.state.buyTabFutureInputClass === "has-success" && !shouldHaveOrangeColor ? " inputSuccess" : ""
                                }${this.state.buyTabFutureInputClass === "has-success" && shouldHaveOrangeColor ? " inputWarning" : ""}`}
                                onChange={e => {
                                  const inputString = e.target.value.trim();

                                  if (isNaN(inputString)) {
                                    e.target.value = e.target.value.slice(0, -1);
                                    this.onBuyFutureInputChangeDebounced();
                                    return;
                                  }

                                  e.target.value = inputString;
                                }}
                                defaultValue={"1"}
                              />
                            </FormGroup>
                          </Form>
                        </div>
                      </div>
                      {this.state.buyTabFutureInputClass === "has-danger" ? (
                        <div className="errorMessageContainerBuySellRemove">
                          <span>{this.state.buyTabFutureInputErrorMessage}</span>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              ) : null}

              <div className="optionPriceInformation">{buyBalancesTransitionsContent}</div>
            </div>
          ) : null}

          {this.props.tabs === "sell" ? (
            <div id="sell" className="buySellFormContainer">
              <div className="inputsContainer">
                <div className="labelAndInputContainer">
                  <div className="labelAndTooltipContainer">
                    <span id={this.state.idPrefix + "inputTooltipSell"}>{this.props.market.isFuture ? "Futures" : "Options"} to sell</span>
                    <MyTooltip key={this.state.idPrefix + "inputTooltipSell"} target={this.state.idPrefix + "inputTooltipSell"} optionalOffset={15}>
                      Amount of {!isFuture ? "options" : "futures"} to sell.
                    </MyTooltip>
                  </div>
                  <div className="inputAmountAndSettingsContainer">
                    <Form>
                      <FormGroup>
                        <Input
                          innerRef={this.sellInputRef}
                          id={this.state.idPrefix + "sell-amount"}
                          type="text"
                          autoComplete="off"
                          placeholder={`Please enter number (e.g., 1.25)`}
                          className={`inputText${this.state.sellTabInputClass === "has-danger" ? " inputDanger" : ""}${
                            this.state.sellTabInputClass === "has-success" && !shouldHaveOrangeColor ? " inputSuccess" : ""
                          }${this.state.sellTabInputClass === "has-success" && shouldHaveOrangeColor ? " inputWarning" : ""}`}
                          onChange={e => {
                            const inputString = e.target.value.trim();

                            const value = inputDecimals(inputString, "USD");

                            if (value === undefined || value === null) {
                              e.target.value =
                                inputString.slice(0, this.sellInputRef.current.selectionStart - 1) +
                                inputString.slice(this.sellInputRef.current.selectionStart - 1 + 1);
                              this.onSellInputChangeDebounced();
                              return;
                            }
                            e.target.value = inputString;
                            this.onSellInputChangeDebounced();
                          }}
                          autoFocus
                        />
                        {this.state.sellTabInputClass === "has-danger" ? <img className="clearInputError" onClick={this.clearInput} /> : null}
                        {this.state.sellTabInputClass === "has-success" && !shouldHaveOrangeColor ? <img className="validInput" /> : null}
                        {this.state.sellTabInputClass === "has-success" && shouldHaveOrangeColor ? <img className="warningInput" /> : null}
                        <div className="increaseDecreaseInputButtons">
                          <img className="increaseInputButton" onClick={this.increaseQuantitySellHandler} />
                          <img className="decreaseInputButton" onClick={this.decreaseQuantitySellHandler} />
                        </div>
                      </FormGroup>
                    </Form>
                    <button id={this.state.idPrefix + "sell-max-button"} className="maxButton" onClick={() => this.onMaxSellButtonClick()}>
                      max
                    </button>
                    <MyTooltip key={this.state.idPrefix + "sell-max-button"} target={this.state.idPrefix + "sell-max-button"} optionalOffset={15}>
                      Calculate maximum {!isFuture ? "options" : "futures"} to sell considering the limits: <br /> <br />
                      1. available liquidity on the market, <br />
                      2. available assets in the Margin pool, <br />
                      3. account health, <br />
                      4. up to 5% price impact, <br />
                      5. up to 20% volatility change limit
                    </MyTooltip>
                    <button
                      id={this.state.idPrefix + "sell-tx-settings"}
                      className="btn-link settingsCog"
                      disabled={shouldBePrintedDefault}
                      onClick={() => this.toggleTransactionSettingsModal()}
                    />
                    <MyTooltip target={this.state.idPrefix + "sell-tx-settings"}>Transaction settings</MyTooltip>
                  </div>
                </div>

                {this.state.sellTabInputClass === "has-danger" ? (
                  <div className="errorMessageContainerBuySellRemove">
                    <span>{this.state.sellTabInputErrorMessage}</span>
                  </div>
                ) : null}
                {this.state.sellTabInputClass === "has-success" && shouldHaveOrangeColor ? (
                  <div className="warningMessageContainerBuySellRemove">
                    <span>
                      <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#eca70d" }} />
                      &nbsp; Price impact is above 5%!
                    </span>
                  </div>
                ) : null}
              </div>

              {!isFuture && this.props.tabs === "sell" && this.state.sellTabType === "openShort" && this.props.futureMarket ? (
                <div className="futuresTradeContainer">
                  <div className="futureHeaderPanel" onClick={this.onFuturePanelClickHandler}>
                    <span className="futurePanelHeaderText">Delta hedge</span>
                    <img className={`${!this.state.futureSellPanelExpanded ? "arrowDownIconFuturePanel" : "arrowUpIconFuturePanel"}`} />
                  </div>

                  {this.state.futureSellPanelExpanded ? (
                    <div className="inputsContainer">
                      <div className="futureLabelAndInputContainer">
                        <div className="labelAndTooltipContainer">
                          <span id={this.state.idPrefix + "inputTooltipSellFutures"}>
                            Futures to {this.state.sellTabFuturePreviewPosition && this.state.sellTabFuturePreviewPosition.type === "long" ? "buy" : "sell"}
                          </span>
                          <MyTooltip
                            key={this.state.idPrefix + "inputTooltipSellFutures"}
                            target={this.state.idPrefix + "inputTooltipSellFutures"}
                            optionalOffset={15}
                          >
                            Amount of futures to{" "}
                            {this.state.sellTabFuturePreviewPosition && this.state.sellTabFuturePreviewPosition.type === "long" ? "buy" : "sell"} to delta
                            hedge.
                          </MyTooltip>
                        </div>
                        <div className="futureInputContainer">
                          <Form style={{ width: "70%" }}>
                            <FormGroup>
                              <Input
                                disabled
                                innerRef={this.sellFutureInputRef}
                                id={this.state.idPrefix + "future-sell-amount"}
                                type="text"
                                autoComplete="off"
                                placeholder="Please enter number (e.g., 1.25)"
                                className={`inputText${this.state.sellTabFutureInputClass === "has-danger" ? " inputDanger" : ""}${
                                  this.state.sellTabFutureInputClass === "has-success" && !shouldHaveOrangeColor ? " inputSuccess" : ""
                                }${this.state.sellTabFutureInputClass === "has-success" && shouldHaveOrangeColor ? " inputWarning" : ""}`}
                                onChange={e => {
                                  const inputString = e.target.value.trim();

                                  if (isNaN(inputString)) {
                                    e.target.value = e.target.value.slice(0, -1);
                                    this.onSellFutureInputChangeDebounced();
                                    return;
                                  }

                                  e.target.value = inputString;
                                }}
                                defaultValue={"1"}
                              />
                            </FormGroup>
                          </Form>
                        </div>
                      </div>
                      {this.state.sellTabFutureInputClass === "has-danger" ? (
                        <div className="errorMessageContainerBuySellRemove">
                          <span>{this.state.sellTabFutureInputErrorMessage}</span>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              ) : null}

              <div className="optionPriceInformation">{sellBalancesTransitionsContent}</div>
            </div>
          ) : null}
          {/* Right half */}
          <div className="chartTableContainer" style={{ position: "relative" }}>
            <div className="verticalChartLabelContainer">
              <span className={`verticalChartLabel${shouldBePrintedDefault ? " disabledTextColor" : ""}`}>Profit/Loss [USD]</span>
            </div>
            <div className="horizontalChartLabelCogContainer">
              <span id={this.state.idPrefix + "risk-analysis-chart"} className={`horizontalChartLabel${shouldBePrintedDefault ? " disabledTextColor" : ""}`}>
                Risk Analysis: ETH price
              </span>
              <MyTooltip key={this.state.idPrefix + "risk-analysis-chart"} target={this.state.idPrefix + "risk-analysis-chart"} optionalOffset={15}>
                Risk analysis chart shows profit/loss of the position relative to ETH price change. <br /> <br />
                Move mouse over chart to simulate the change of ETH price. <br /> <br />
              </MyTooltip>
              <button
                id={this.state.idPrefix + "chart-options-button"}
                className="btn-link settingsCogChart"
                disabled={shouldBePrintedDefault}
                onClick={this.toggleSetCogChartOptionsOpen}
              />
              <MyTooltip key={this.state.idPrefix + "chart-options-button"} target={this.state.idPrefix + "chart-options-button"} optionalOffset={15}>
                Chart options
              </MyTooltip>
            </div>
            <div id="tradePositionPreviewContainer" className={`${shouldBePrintedDefault ? "disabledChartBackground" : ""}`} ref={this.chartRef}>
              {this.props.cogChartOptionsOpenBuy && this.props.tabs === "buy" && (
                <CogChartOptions
                  key="cogChartoptions"
                  showOptionNewPosition
                  isNewPositionSelected={this.state.newPositionSelected}
                  allNewSwitchChanged={this.onAllNewSwitchChange}
                  standardDeviation={this.state.standardDeviation}
                  setStandardDeviation={this.onStandardDeviationChange}
                />
              )}
              {this.props.tabs === "buy" ? (
                <ChartContext.Provider value={this.chartRef}>
                  {chartData.series1.length > 0 && (
                    <PositionsChart
                      key="positionsChartBuy"
                      disabled={shouldBePrintedDefault}
                      isXUnderlying
                      isTradeVolumeMode={false}
                      selectedUnderPrice={parseFloat(this.props.underlyingTokenPrice.normalized)}
                      daysToExpiry={daysToExpiry}
                      currentPriceInVol={this.props.market.longPriceInVol}
                      minDomainX={chartData.minDomainX}
                      maxDomainX={chartData.maxDomainX}
                      minDomainY={chartData.minDomainY}
                      maxDomainY={chartData.maxDomainY}
                      series1={chartData.series1}
                      series2={chartData.series2}
                      selectXCallback={this.selectXCallback}
                      deselectXCallback={this.deselectXCallback}
                      liqPrices={this.state.buyTabLiqPrices}
                      lpLiqPrices={this.state.buyTabLPLiqPrices}
                      tabs={this.props.tabs}
                      deviations={chartData.deviations}
                      chartX={this.state.selectedUnderPrice}
                    />
                  )}
                </ChartContext.Provider>
              ) : null}
              {this.props.cogChartOptionsOpenSell && this.props.tabs === "sell" && (
                <CogChartOptions
                  key="cogChartoptions"
                  showOptionNewPosition
                  isNewPositionSelected={this.state.newPositionSelected}
                  allNewSwitchChanged={this.onAllNewSwitchChange}
                  standardDeviation={this.state.standardDeviation}
                  setStandardDeviation={this.onStandardDeviationChange}
                />
              )}
              {this.props.tabs === "sell" ? (
                <ChartContext.Provider value={this.chartRef}>
                  {chartData.series1.length > 0 && (
                    <PositionsChart
                      key="positionsChartSell"
                      disabled={shouldBePrintedDefault}
                      isXUnderlying
                      isTradeVolumeMode={false}
                      selectedUnderPrice={parseFloat(this.props.underlyingTokenPrice.normalized)}
                      daysToExpiry={daysToExpiry}
                      currentPriceInVol={this.props.market.longPriceInVol}
                      minDomainX={chartData.minDomainX}
                      maxDomainX={chartData.maxDomainX}
                      minDomainY={chartData.minDomainY}
                      maxDomainY={chartData.maxDomainY}
                      series1={chartData.series1}
                      series2={chartData.series2}
                      selectXCallback={this.selectXCallback}
                      deselectXCallback={this.deselectXCallback}
                      liqPrices={this.state.sellTabLiqPrices}
                      lpLiqPrices={this.state.sellTabLPLiqPrices}
                      tabs={this.props.tabs}
                      deviations={chartData.deviations}
                      chartX={this.state.selectedUnderPrice}
                    />
                  )}
                </ChartContext.Provider>
              ) : null}
            </div>

            <SlidersPanel
              selectedPositions={allPositions}
              markets={this.props.markets}
              selectedUnderPrice={this.state.selectedUnderPrice}
              selectedDaysToExpiry={this.state.selectedDaysToExpiry}
              selectedVolatility={this.state.selectedVolatility}
              calculateChartDataCallback={this.calculateChartDataCallback}
              tabs={this.props.tabs}
              buyTabInputClass={this.state.buyTabInputClass}
              sellTabInputClass={this.state.sellTabInputClass}
              delta={delta}
              gamma={gamma}
              theta={theta}
              vega={vega}
              disabled={shouldBePrintedDefault}
            />
          </div>
        </div>

        {this.props.tabs === "buy" && (
          <button
            ref={this.tradeModalBuyButtonRef}
            id="tradeModalBuyButton"
            className="buySellActionButton"
            disabled={this.state.buyTabInputClass !== "has-success"}
            onClick={() => this.onBuyButtonClick()}
          >
            {shouldHaveOrangeColor ? (
              <>
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#eca70d", fontSize: "13px", verticalAlign: "middle" }} />
              </>
            ) : null}{" "}
            buy
          </button>
        )}
        {this.props.tabs === "sell" && (
          <button
            id="tradeModalSellButton"
            ref={this.tradeModalSellButtonRef}
            className="buySellActionButton sellActionButton"
            disabled={this.state.sellTabInputClass !== "has-success"}
            onClick={() => this.onSellButtonClick()}
          >
            {shouldHaveOrangeColor ? (
              <>
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#eca70d", fontSize: "13px", verticalAlign: "middle" }} />
              </>
            ) : null}
            sell
          </button>
        )}
      </>
    );
  }
}

TradeModalTradeTab.propTypes = {
  web3Object: PropTypes.object,
  web3: PropTypes.object,
  web3Account: PropTypes.string,
  market: PropTypes.object.isRequired,
  futureMarket: PropTypes.object,
  markets: PropTypes.array,
  marketsLoaded: PropTypes.bool,
  selectedTokenPair: PropTypes.object,
  openedTradePositions: PropTypes.array,
  openedLPPositions: PropTypes.array,
  selectedBaseTokenBalances: PropTypes.object,
  selectedUnderlyingTokenBalances: PropTypes.object,
  getMaxLongAmount: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  underlyingTokenPrice: PropTypes.object,
  tabs: PropTypes.object,
  needsToBeClosed: PropTypes.bool,
  size: PropTypes.object,
  rawSize: PropTypes.string,
  setNeedsToBeClosed: PropTypes.func.isRequired,
  isLong: PropTypes.bool,
  positionsManager: PropTypes.object,
  marginAccountData: PropTypes.object,
  marginPoolData: PropTypes.object,
  toggle: PropTypes.func,
  isTradePage: PropTypes.bool,
  isOpen: PropTypes.bool,
  cogChartOptionsOpenBuy: PropTypes.object,
  cogChartOptionsOpenSell: PropTypes.object,
  setCogChartOptionsOpenBuy: PropTypes.func,
  setCogChartOptionsOpenSell: PropTypes.func,
  tokenBalances: PropTypes.array,
  settlements: PropTypes.array,
  positionsBorrowFactors: PropTypes.object
};

function mapStateToProps(state) {
  return {
    web3Object: web3ObjectSelector(state),
    web3: web3Selector(state),
    web3Account: web3AccountSelector(state),
    selectedTokenPair: selectedTokenPairSelector(state),
    openedTradePositions: openedTradePositionsSelector(state),
    marketsLoaded: marketsLoadedSelector(state),
    markets: marketsSelector(state),
    openedLPPositions: openedLPPositionsSelector(state),
    selectedBaseTokenBalances: selectedBaseTokenBalancesSelector(state),
    selectedUnderlyingTokenBalances: selectedUnderlyingTokenBalancesSelector(state),
    underlyingTokenPrice: avgUnderlyingTokenPriceSelector(state),
    positionsManager: positionsManagerSelector(state),
    marginAccountData: marginAccountDataSelector(state),
    marginPoolData: marginPoolDataSelector(state),
    tokenBalances: tokenBalancesSelector(state),
    settlements: settlementDataSelector(state),
    positionsBorrowFactors: positionsBorrowFactorsSelector(state)
  };
}

export default connect(mapStateToProps)(TradeModalTradeTab);
