import * as types from "../actionTypes.js";

export const insuranceFund = (state = { loaded: false, data: {} }, action) => {
  switch (action.type) {
    case types.INSURANCE_FUND_LOADED:
      return { ...state, loaded: true, data: action.insuranceFund };
    case types.INSURER_BALANCE_LOADED:
      return { ...state, loaded: true, data: { ...state.data, ...action.insurerBalance } };
    default:
      return state;
  }
};

export const insuranceFundDepositsAndWithdrawals = (state = { loaded: false, data: [] }, action) => {
  switch (action.type) {
    case types.INSURANCE_FUND_DEPOSITS_AND_WITHDRAWALS_LOADED:
      return { ...state, loaded: true, data: action.depositsAndWithdrawals };
    case types.INSURANCE_FUND_DEPOSIT_OR_WITHDRAWAL_ADDED:
      return { ...state, data: [...state.data, action.depositOrWithdrawal] };
    default:
      return state;
  }
};

export const insuranceFundLossesCovered = (state = { loaded: false, data: [] }, action) => {
  switch (action.type) {
    case types.INSURANCE_FUND_LOSSES_COVERED_LOADED:
      return { ...state, loaded: true, data: action.lossesCovered };
    case types.INSURANCE_FUND_LOSS_COVERED_ADDED:
      return { ...state, data: [...state.data, action.lossCovered] };
    default:
      return state;
  }
};
