import * as types from "../actionTypes.js";

// todo: should add Settlements loaded action
export const settlements = (state = { loaded: false, data: [] }, action) => {
  switch (action.type) {
    case types.MARKETS_SETTLED:
      return {
        ...state,
        data: action.settlementsData.map(sd => {
          const { baseTokenAddress, underTokenAddress, settlementTime, expirationTime, underlyingPrice, actualUnderlyingPrice } = sd.returnValues;
          return {
            baseTokenAddress,
            underTokenAddress,
            settlementTime,
            expirationTime,
            underlyingPrice,
            actualUnderlyingPrice
          };
        })
      };
    case types.MARKET_SETTLED:
      if (
        state.data.find(
          settlementData =>
            settlementData.baseTokenAddress === action.settlementData.baseTokenAddress &&
            settlementData.underTokenAddress === action.settlementData.underTokenAddress &&
            settlementData.expirationTime === action.settlementData.expirationTime
        )
      ) {
        return state;
      } else {
        return {
          ...state,
          data: [...state.data, action.settlementData]
        };
      }
    default:
      return state;
  }
};

export const depositsAndWithdrawals = (state = { loaded: false, data: [] }, action) => {
  switch (action.type) {
    case types.DEPOSITS_AND_WITHDRAWALS_LOADED:
      return { ...state, loaded: true, data: action.depositsAndWithdrawals };
    case types.DEPOSIT_OR_WITHDRAWAL_ADDED:
      return { ...state, data: [...state.data, action.depositOrWithdrawal] };
    default:
      return state;
  }
};
