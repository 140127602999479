import { formatUnits } from "@ethersproject/units";
import { BigNumber } from "@ethersproject/bignumber";
import { isEmpty } from "lodash";
import { fromBn } from "evm-bn";
import ERC20 from "../../abis/ERC20.json";

export const getTokenPairsList = pairs => {
  if (!isEmpty(pairs)) {
    return pairs.map(pair => {
      return {
        ...pair,
        normalizedUnderlyingTokenPrice: pair.underlyingTokenPrice ? parseFloat(formatUnits(pair.underlyingTokenPrice.toString(), 18)) : null
      };
    });
  }
  return [];
};

export const getPositionTokens = positionTokens => {
  if (positionTokens) {
    return positionTokens.map(positionToken => {
      return {
        ...positionToken,
        address: positionToken.address,
        marketId: positionToken.marketId,
        name: positionToken.name,
        shortName: positionToken.shortName
      };
    });
  }
  return [];
};

export const getLpPositionTokens = lpPositionTokens => {
  if (lpPositionTokens) {
    return lpPositionTokens.map(positionToken => {
      return {
        marketId: positionToken.marketId,
        ...positionToken,
        positionId: positionToken.positionId.toString(),
        name: positionToken.name,
        shortName: positionToken.shortName
      };
    });
  }
  return [];
};

// DECORATORS
export const decorateTokenBalances = token => {
  const walletBalance18Decimals =
    token.decimals && token.walletBalance && token.accountBalance
      ? token.decimals.toString() === "18"
        ? token.walletBalance
        : BigNumber.from(token.walletBalance.toString())
            .mul(BigNumber.from((10 ** (18 - token.decimals)).toString()))
            .toString()
      : null;
  return {
    ...token,
    walletBalance18Decimals,
    normalizedAccountBalance: token.accountBalance ? parseFloat(formatUnits(token.accountBalance, 18)) : null,
    normalizedAccountBalance2Decimals: token.accountBalance ? parseFloat(Math.floor(fromBn(token.accountBalance) * Math.pow(10, 2)) / Math.pow(10, 2)) : null,
    normalizedAccountBalance4Decimals: token.accountBalance ? parseFloat(Math.floor(fromBn(token.accountBalance) * Math.pow(10, 4)) / Math.pow(10, 4)) : null,
    printableAccountBalance: token.accountBalance
      ? parseFloat(formatUnits(token.accountBalance, 18))
          .toFixed(2)
          .toString()
      : null,
    normalizedWalletBalance: token.walletBalance && token.decimals ? parseFloat(formatUnits(token.walletBalance, token.decimals)) : null,
    normalizedWalletBalance2Decimals:
      walletBalance18Decimals || walletBalance18Decimals == 0
        ? parseFloat(Math.floor(fromBn(walletBalance18Decimals) * Math.pow(10, 2)) / Math.pow(10, 2))
        : null,
    normalizedWalletBalance4Decimals:
      walletBalance18Decimals || walletBalance18Decimals == 0
        ? parseFloat(Math.floor(fromBn(walletBalance18Decimals) * Math.pow(10, 4)) / Math.pow(10, 4))
        : null,
    // normalizedWalletBalance4Decimals:
    //   token.walletBalance && token.decimals ? Math.floor(formatUnits(token.walletBalance, token.decimals) / 0.0001) * 0.0001 : null,
    printableWalletBalance:
      token.walletBalance && token.decimals
        ? parseFloat(formatUnits(token.walletBalance, token.decimals))
            .toFixed(2)
            .toString()
        : null
  };
};

//TODO: remove old methods when everything is changed
export const loadAssetAccountBalance2 = async (assetAddress, userBalances, userAccount) => {
  try {
    // dispatch(loadingTokenAccountBalance(assetAddress));
    console.log("RPC method: userBalances.balanceOf");
    const tokenBalance = await userBalances.methods.balanceOf(assetAddress, userAccount).call();
    // dispatch(tokenAccountBalanceLoaded(assetAddress, tokenBalance));
    return tokenBalance;
  } catch (e) {
    console.log(e)
    // handleNewError({}, "Error while loading account balance for asset: " + assetAddress + ". Please reload the application!", 1, dispatch);
  }
};

export const loadAssetWalletBalance2 = async (web3, assetAddress, userAccount, wethAddress) => {
  try {
    let tokenBalance;
    console.log("RPC method: balanceOf");
    if (assetAddress === wethAddress) {
      console.log("RPC method: getBalance");
      const token = new web3.eth.Contract(ERC20.abi, wethAddress);
      const tokenBalanceETH = await web3.eth.getBalance(userAccount);
      const tokenBalanceWETH = await token.methods.balanceOf(userAccount).call();
      if (Number(fromBn(tokenBalanceWETH.toString())) > Number(fromBn(tokenBalanceETH.toString()))) {
        tokenBalance = tokenBalanceWETH.toString();
      } else {
        tokenBalance = tokenBalanceETH.toString();
      }
    } else {
      const token = new web3.eth.Contract(ERC20.abi, assetAddress);
      tokenBalance = await token.methods.balanceOf(userAccount).call();
    }
    return tokenBalance;
  } catch (e) {
    console.log(e);
    // handleNewError({}, "Error while loading wallet balance for asset: " + assetAddress + ". Please reload the application!", 1, dispatch);
  }
};

export const loadPositionTokenAccountBalance2 = async (tokenId, userBalances, userAccount) => {
  try {
    // dispatch(loadingPositionTokenAccountBalance(tokenId));
    console.log("RPC method: userBalances.balanceOfPosition");
    const tokenBalance = await userBalances.methods.balanceOfPosition(tokenId, userAccount).call();
    // dispatch(positionTokenAccountBalanceLoaded(tokenId, tokenBalance));
    return tokenBalance;
  } catch (e) {
    console.log(e);
    // handleNewError({}, "Error while loading account balance for position: " + tokenId + ". Please reload the application!", 1, dispatch);
  }
};

export const loadAccountLPBalance2 = async (loadHelper, marketId, positionId, userAccount, underPriceBN) => {
  try {
    // dispatch(loadingLPPositionTokenAccountBalance(marketId, positionId));
    let health = 0;
    console.log("RPC method: loadHelper.getLPPositionsData");
    const data = await loadHelper.methods.getLPPositionData(marketId, positionId, userAccount, underPriceBN).call();
    if (data.liquidity.toString() !== "0") {
      health = parseFloat(formatUnits(data.health.toString(), 18));
    }
    const retVal = {
      marketId: marketId.toString(),
      positionId: positionId.toString(),
      liquidity: data.liquidity.toString(),
      longBalance: data.longBalance.toString(),
      baseBalance: data.baseBalance.toString(),
      feeBalance: data.feeBalance.toString(),
      lower: parseInt(data.lower),
      upper: parseInt(data.upper),
      sizeInLongs: data.sizeInLongs.toString(),
      shortBalance: data.shortBalance.toString(),
      health: health
    };
    return retVal;
  } catch (e) {
    console.log(e);
    // handleNewError({}, "Error while loading account balance for lp token. Please reload the application!", 1, dispatch);
  }
};

export const reloadMarketTokensBalances2 = async (web3, marketId, userBalances, loadHelper, userAccount, underPriceBN, tokenId = null) => {
  console.log("RPC method: getAssetAddresses");
  const marketImmutableParams = await loadHelper.methods.getAssetAddresses().call();
  const wethAddress = marketImmutableParams.underTokenAddress;

  // base token
  // loadAssetAccountBalance(marketImmutableParams.baseTokenAddress, userBalances, userAccount, dispatch);
  const tokenAccountBalanceBaseToken = await loadAssetAccountBalance2(marketImmutableParams.baseTokenAddress, userBalances, userAccount);

  // loadAssetWalletBalance(web3, marketImmutableParams.baseTokenAddress, userAccount, dispatch);
  const assetWalletBalanceBaseToken = await loadAssetWalletBalance2(web3, marketImmutableParams.baseTokenAddress, userAccount, wethAddress);

  // underlying token
  // loadAssetAccountBalance(marketImmutableParams.underTokenAddress, userBalances, userAccount, dispatch);
  const tokenAccountBalanceUnderToken = await loadAssetAccountBalance2(marketImmutableParams.underTokenAddress, userBalances, userAccount);

  // loadAssetWalletBalance(web3, marketImmutableParams.underTokenAddress, userAccount, dispatch);
  const assetWalletBalanceUnderToken = await loadAssetWalletBalance2(web3, marketImmutableParams.underTokenAddress, userAccount, wethAddress);

  // long token
  // loadPositionTokenAccountBalance(marketId, userBalances, userAccount, dispatch);
  const longPositionTokenAccountBalance = await loadPositionTokenAccountBalance2(marketId, userBalances, userAccount);
  // short token
  // loadPositionTokenAccountBalance((parseInt(marketId) + 1).toString(), userBalances, userAccount, dispatch);
  const shortPositionTokenAccountBalance = await loadPositionTokenAccountBalance2((parseInt(marketId) + 1).toString(), userBalances, userAccount);

  let lpPositionTokenAccountBalance = null;
  // liquidity token
  if (tokenId) {
    // loadAccountLPBalance(loadHelper, marketId, tokenId, userAccount, underPriceBN, dispatch);
    lpPositionTokenAccountBalance = await loadAccountLPBalance2(loadHelper, marketId, tokenId, userAccount, underPriceBN);
  }
  return [
    tokenAccountBalanceBaseToken,
    assetWalletBalanceBaseToken,
    tokenAccountBalanceUnderToken,
    assetWalletBalanceUnderToken,
    longPositionTokenAccountBalance,
    shortPositionTokenAccountBalance,
    lpPositionTokenAccountBalance
  ];
};
