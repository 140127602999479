/* eslint-disable no-undef */
import { SECONDS_IN_YEAR, getFee, getDailyVolume, findClosestMarketsToExpirationTime, findVolForClosestStrikePrice } from "./utils.js";
import { getExactBaseInSubrange, getExactLongsInSubrange, getPositionHealth, insert, MarketHelper } from "./MarketHelper.js";
import moment from "moment";
import bs from "black-scholes";
import greeks from "greeks";

export const getChartData = (positions, markets, underPrice, selectedDaysToExpiry, selectedVolatility, rate, borrowRates, deviations) => {
  const series1 = [];
  const series2 = [];
  //let p = probability(1200, 2500, 0.2383, 0.68, 0);
  //let p = probability(1200, 2500, 10, 0.68, 0);
  const domainX = addPositions(positions, markets, underPrice, selectedDaysToExpiry, selectedVolatility, rate, series1, series2, borrowRates, deviations);

  const domainY = getDomainYUnder(positions, markets, underPrice, selectedDaysToExpiry, rate, borrowRates, deviations);

  return {
    minDomainX: domainX.minDomainX,
    maxDomainX: domainX.maxDomainX,
    minDomainY: domainY.minDomainY,
    maxDomainY: domainY.maxDomainY,
    series1,
    series2,
    deviations,
    feesProjected: domainX.feesProjected
  };
};

export const getChartPoint = (positions, markets, underPrice, selectedDaysToExpiry, selectedVolatility, rate, borrowRates) => {
  const series1 = [];
  addPositions(positions, markets, underPrice, selectedDaysToExpiry, selectedVolatility, rate, series1, [], borrowRates, 0);
  return {
    pnl: series1[0].y,
    value: series1[0].value,
    optionValue: series1[0].optionValue,
    markPrice: series1[0].markPrice,
    lots: series1[0].lots,
    delta: series1[0].delta,
    gamma: series1[0].gamma,
    theta: series1[0].theta,
    vega: series1[0].vega
  };
};

export const getLiquidationPrice = (
  allPositions,
  markets,
  underPrice,
  selectedDaysToExpiry,
  selectedVolatility,
  rate,
  assetsBase,
  assetsUnder,
  debtBase,
  debtUnder,
  borrowRates
) => {
  // liquidations are calculated for all positions
  const series1 = [];
  addPositions(allPositions, markets, underPrice, selectedDaysToExpiry, selectedVolatility, rate, series1, [], borrowRates, 5, true);

  // if no debt, no liquidation prices
  const totalDebt = debtBase + debtUnder * underPrice;
  if (totalDebt === 0) {
    return {
      upperLiqPrice: series1[series1.length - 1].x + 1,
      lowerLiqPrice: 0.01,
      maxX: series1[series1.length - 1].x
    };
  }

  const liqPrices = interpolateLiqPrice(series1, assetsBase, assetsUnder, debtBase, debtUnder, underPrice);

  return {
    upperLiqPrice: liqPrices.upperLiqPrice,
    lowerLiqPrice: liqPrices.lowerLiqPrice,
    maxX: series1[series1.length - 1].x
  };
};

// lp position can be liquidated
export const getPositionLiquidationPrice = (position, market, underPrice, selectedVolatility, rate, liquidationThreshold) => {
  const liquidity = position.balance;
  const baseReserves = position.isPreview ? position.requiredBase - 300 : liquidity * position.openLiqPrice[0];
  const lowerPriceInVol = position.lowerPriceInVol ? position.lowerPriceInVol : 1.0001 ** position.lower;
  const upperPriceInVol = position.upperPriceInVol ? position.upperPriceInVol : 1.0001 ** position.upper;
  const currentPriceInVol = selectedVolatility;
  //console.log(0, position.lower, Math.sqrt(currentPriceInVol), market.expirationTime, market.strikePrice, market.isCall, rate)
  let helper = new MarketHelper(0, position.lower, Math.sqrt(currentPriceInVol), market.expirationTime, market.strikePrice, market.isCall, rate);
  insert(helper, { index: position.lower, liquidity: liquidity });
  insert(helper, { index: position.upper, liquidity: liquidity });

  let { minDomainX, maxDomainX } = calculateMinMaxDomainXAndFarthestExpireDate([position], [market], underPrice, 5);
  const series = [];
  const step = (maxDomainX - minDomainX) / 100;
  for (let x = minDomainX; x <= maxDomainX; x += step) {
    const health = getPositionHealth(helper, Math.sqrt(lowerPriceInVol), Math.sqrt(upperPriceInVol), Math.sqrt(currentPriceInVol), liquidity, x, baseReserves);
    series.push({ x: health, y: x });
  }

  // sort by x, which is health
  series.sort((a, b) => a.x - b.x);

  let liqPrice = maxDomainX;

  // check if series has element that is lower than liquidationThreshold
  let hasLiquidationPrice = false;
  let allBelowLiquidationThreshold = true;
  for (let point of series) {
    if (point.x < liquidationThreshold) {
      hasLiquidationPrice = true;
    } else {
      allBelowLiquidationThreshold = false;
    }
  }

  // handle when no all points are below liquidation threshold, interpolation doesn't work
  if (allBelowLiquidationThreshold && series.length > 0) {
    return series[series.length - 1].y;
  }

  // interpolate if there is liquidation price
  if (hasLiquidationPrice) {
    liqPrice = interpolateY(series, market.liquidationThreshold);
  }
  return liqPrice;
};

export const addPositions = (
  positions,
  markets,
  underPrice,
  selectedDaysToExpiry,
  selectedVolatility,
  rate,
  series1,
  series2,
  borrowRates,
  deviations,
  isLiquidation = false
) => {
  // calc min, max domain for X axis and time left to position that has closest expiration date
  let minDomainX = underPrice;
  let maxDomainX = underPrice;
  let specialXs = [underPrice];
  if (deviations !== 0) {
    const returnValue = calculateMinMaxDomainXAndFarthestExpireDate(positions, markets, underPrice, deviations);
    minDomainX = returnValue.minDomainX;
    maxDomainX = returnValue.maxDomainX;
    specialXs = getSpecialXs(positions, markets, underPrice);
  }

  const timestampNow = new Date();
  const highestDaysToExpiry = getHighestDaysToExpiry(positions, markets, timestampNow);
  const highestDaysToExpiryRounded = getHighestDaysToExpiryRounded(positions, markets);
  // if days to expiry is set left (max days to expiry), use exact value
  if (highestDaysToExpiryRounded === selectedDaysToExpiry) {
    selectedDaysToExpiry = highestDaysToExpiry;
  }
  let delta = 0;
  let gamma = 0;
  let theta = 0;
  let vega = 0;
  let feesProjected = 0;

  // go through all positions and add them
  positions.forEach(position => {
    let daysToExpiry = selectedDaysToExpiry;
    let volatility = selectedVolatility;
    const market = markets.find(market => market.marketId === position.marketId);
    const marketVolatility = parseFloat(market.longPriceInVol);

    // if multiple positions, selectedVolatility is relative
    let bothPositionsPreview = positions.length === 2 && !!positions[0].isPreview && !!positions[1].isPreview;
    if (positions.length > 1 && !bothPositionsPreview) {
      // multiple positions, use relative volatility
      volatility = Math.max(0, marketVolatility + selectedVolatility);
    } else {
      // single position, could be existing or preview
      // if existing position, selected volatility is set to current volatility, use exact value
      if (!position.isPreview) {
        if (Math.round(marketVolatility) === volatility) {
          volatility = marketVolatility;
        }
      } else {
        // if preview position, use 80% volatility for future (because we want wide chart)
        if (market.isFuture) {
          volatility = 80; // todo: v2
        }
      }
    }

    // if days to expiry is set left (max days to expiry), use exact value
    const exactSecsToExpiration = Math.abs(market.expirationTime * 1000 - timestampNow);
    const exactDaysToExpiration = exactSecsToExpiration / (1000 * 60 * 60 * 24);

    // calculate relative days to expiry (for positions that expire before selected days to expiry)
    if (exactDaysToExpiration < highestDaysToExpiry) {
      daysToExpiry = Math.max(0, daysToExpiry - (highestDaysToExpiry - exactDaysToExpiration)); // NOTE: can't do Math.round because we need exact time
    }

    const isLongOrShort = position.type === "short" || position.type === "long";
    let greeks;
    if (isLongOrShort) {
      greeks = addNewLongOrShortPosition(
        position,
        minDomainX,
        maxDomainX,
        daysToExpiry / 365,
        volatility / 100,
        rate,
        series1,
        series2,
        specialXs,
        isLiquidation
      );
    } else {
      greeks = addNewLPPosition(
        position,
        market,
        minDomainX,
        maxDomainX,
        underPrice,
        daysToExpiry,
        volatility,
        rate,
        series1,
        series2,
        specialXs,
        borrowRates,
        isLiquidation
      );
    }

    delta += greeks.delta;
    gamma += greeks.gamma;
    theta += greeks.theta;
    vega += greeks.vega;
    feesProjected += greeks.feesProjected;
  });

  return { minDomainX, maxDomainX, delta, gamma, theta, vega, feesProjected };
};

export const getDomainYUnder = (positions, markets, underPrice, selectedDaysToExpiry, rate, borrowRates, deviations) => {
  // get Y range
  const domainVol = calculateMinMaxDomainXVol(positions, markets);
  let bothPositionsPreview = positions.length === 2 && !!positions[0].isPreview && !!positions[1].isPreview;
  const volatilityMin = positions.length === 1 || bothPositionsPreview ? domainVol.minDomainX : Math.round(domainVol.minDomainX - domainVol.avgX);
  const volatilityMax = positions.length === 1 || bothPositionsPreview ? domainVol.maxDomainX : Math.round(domainVol.maxDomainX - domainVol.avgX);

  // get min and max Y for minimum volatility
  const minYSeries1 = [],
    minYSeries2 = [];
  addPositions(positions, markets, underPrice, selectedDaysToExpiry, volatilityMin, rate, minYSeries1, minYSeries2, borrowRates, deviations);
  const domainYMinVol = calculateMinMaxDomainY(minYSeries1, minYSeries2);

  // get min and max Y for maximum volatility
  const maxYSeries1 = [],
    maxYSeries2 = [];
  addPositions(positions, markets, underPrice, selectedDaysToExpiry, volatilityMax, rate, maxYSeries1, maxYSeries2, borrowRates, deviations);
  const domainYMaxVol = calculateMinMaxDomainY(maxYSeries1, maxYSeries2);

  // get lowest min, and highest max
  const minDomainY = Math.min(domainYMinVol.minDomainY, domainYMaxVol.minDomainY);
  const maxDomainY = Math.max(domainYMinVol.maxDomainY, domainYMaxVol.maxDomainY);

  return { minDomainY, maxDomainY };
};

export const addNewLongOrShortPosition = (position, minDomainX, maxDomainX, timeToExpiryYears, iv, rate, allToday, allAtExpire, specialXs, isLiquidation) => {
  const isLong = position.type === "long";
  const size = position.balance;
  const isCall = position.isCall;
  const strikePrice = Number(position.strikePrice);

  const openPrice = position.openBasePrice;
  const totalPremium = openPrice * size;

  const currentToday = [];
  const currentAtExpire = [];
  let delta, gamma, theta, vega;

  // prepare X array (steps + underlying + strikes)
  let arrayX = getArrayX(minDomainX, maxDomainX, specialXs, isLiquidation);

  // calculate y for each x
  for (let x of arrayX) {
    const longPriceToday = blackScholes(x, strikePrice, timeToExpiryYears, iv, rate, isCall);
    const isSettled = timeToExpiryYears === 0;
    currentToday.push({
      x,
      y: isLong ? -(totalPremium - size * longPriceToday) : totalPremium - size * longPriceToday,
      value: isLong ? size * longPriceToday : size * (isCall ? x - longPriceToday : strikePrice - longPriceToday),
      optionValue: size * (isLong ? 1 : -1) * longPriceToday,
      markPrice: longPriceToday,
      delta: isSettled ? 0 : size * (isLong ? 1 : -1) * greeks.getDelta(x, strikePrice, timeToExpiryYears, iv, rate, isCall ? "call" : "put"),
      gamma: isSettled ? 0 : size * (isLong ? 1 : -1) * greeks.getGamma(x, strikePrice, timeToExpiryYears, iv, rate, isCall ? "call" : "put"),
      theta: isSettled ? 0 : size * (isLong ? 1 : -1) * greeks.getTheta(x, strikePrice, timeToExpiryYears, iv, rate, isCall ? "call" : "put"),
      vega: isSettled ? 0 : size * (isLong ? 1 : -1) * greeks.getVega(x, strikePrice, timeToExpiryYears, iv, rate, isCall ? "call" : "put"),
      lots: size * (isLong ? 1 : -1)
    });

    const longPriceExpiry = blackScholes(x, strikePrice, 0, iv, rate, isCall);
    currentAtExpire.push({
      x,
      y: isLong ? -(totalPremium - size * longPriceExpiry) : totalPremium - size * longPriceExpiry,
      value: isLong ? size * longPriceExpiry : size * (isCall ? x - longPriceExpiry : strikePrice - longPriceExpiry),
      optionValue: size * (isLong ? 1 : -1) * longPriceExpiry,
      markPrice: longPriceExpiry,
      delta: 0, //size * (isLong ? 1 : -1) * greeks.getDelta(x, strikePrice, 0, iv, rate, isCall ? "call" : "put"),
      gamma: 0, //size * (isLong ? 1 : -1) * greeks.getGamma(x, strikePrice, 0, iv, rate, isCall ? "call" : "put"),
      theta: 0, //size * (isLong ? 1 : -1) * greeks.getTheta(x, strikePrice, 0, iv, rate, isCall ? "call" : "put"),
      vega: 0, //size * (isLong ? 1 : -1) * greeks.getVega(x, strikePrice, 0, iv, rate, isCall ? "call" : "put"),
      lots: size * (isLong ? 1 : -1)
    });

    // greeks
    if (x === specialXs[0]) {
      delta = size * (isLong ? 1 : -1) * greeks.getDelta(x, strikePrice, timeToExpiryYears, iv, rate, isCall ? "call" : "put");
      gamma = size * (isLong ? 1 : -1) * greeks.getGamma(x, strikePrice, timeToExpiryYears, iv, rate, isCall ? "call" : "put");
      theta = size * (isLong ? 1 : -1) * greeks.getTheta(x, strikePrice, timeToExpiryYears, iv, rate, isCall ? "call" : "put");
      vega = size * (isLong ? 1 : -1) * greeks.getVega(x, strikePrice, timeToExpiryYears, iv, rate, isCall ? "call" : "put");
    }
  }

  for (let i = 0; i < currentAtExpire.length; i++) {
    if (allAtExpire.length !== currentAtExpire.length) {
      allAtExpire.push(currentAtExpire[i]);
    } else {
      allAtExpire[i] = {
        ...allAtExpire[i],
        y: allAtExpire[i].y + currentAtExpire[i].y,
        value: allAtExpire[i].value + currentAtExpire[i].value,
        optionValue: allAtExpire[i].optionValue + currentAtExpire[i].optionValue,
        delta: allAtExpire[i].delta + currentAtExpire[i].delta,
        gamma: allAtExpire[i].gamma + currentAtExpire[i].gamma,
        theta: allAtExpire[i].theta + currentAtExpire[i].theta,
        vega: allAtExpire[i].vega + currentAtExpire[i].vega,
        lots: allAtExpire[i].lots + currentAtExpire[i].lots
      };
    }

    if (allToday.length !== currentToday.length) {
      allToday.push(currentToday[i]);
    } else {
      allToday[i] = {
        ...allToday[i],
        y: allToday[i].y + currentToday[i].y,
        value: allToday[i].value + currentToday[i].value,
        optionValue: allToday[i].optionValue + currentToday[i].optionValue,
        delta: allToday[i].delta + currentToday[i].delta,
        gamma: allToday[i].gamma + currentToday[i].gamma,
        theta: allToday[i].theta + currentToday[i].theta,
        vega: allToday[i].vega + currentToday[i].vega,
        lots: allToday[i].lots + currentToday[i].lots
      };
    }
  }

  return { delta, gamma, theta, vega, feesProjected: 0 }; // feesProjected is 0 because long and short don't earn fees
};

export const addNewLPPosition = (
  position,
  market,
  minDomainX,
  maxDomainX,
  underPrice,
  timeToExpiryDays,
  selectedVolatility,
  rate,
  allToday,
  allAtExpire,
  specialXs,
  borrowRates,
  isLiquidation
) => {
  // const size = position.balance;
  const isCall = position.isCall;
  const strikePrice = Number(position.strikePrice);
  const liquidity = position.balance;

  const currentToday = [];
  const currentAtExpire = [];
  let delta, gamma, theta, vega;

  const lowerPriceInVol = position.lowerPriceInVol ? position.lowerPriceInVol : 1.0001 ** position.lower;
  const upperPriceInVol = position.upperPriceInVol ? position.upperPriceInVol : 1.0001 ** position.upper;
  let currentPriceInVol = parseFloat(market.longPriceInVol);

  const helper = new MarketHelper(0, position.lower, Math.sqrt(currentPriceInVol), market.expirationTime, strikePrice, isCall, rate);
  insert(helper, { index: position.lower, liquidity: liquidity });
  insert(helper, { index: position.upper, liquidity: liquidity });

  //let feesCollected = position.feeBalance ? position.feeBalance : 0;

  const feesCollectedToday = getLPFeesCollected(position, market, underPrice, timeToExpiryDays, rate);
  const interestOnDebtToday = getInterestOnDebt(position, market, underPrice, timeToExpiryDays, borrowRates);
  const feesCollectedExpiry = getLPFeesCollected(position, market, underPrice, 0, rate);
  const interestOnDebtExpiry = getInterestOnDebt(position, market, underPrice, 0, borrowRates);

  // prepare X array (steps + underlying + strikes)
  const arrayX = getArrayX(minDomainX, maxDomainX, specialXs, isLiquidation);

  // calculate y for each x
  for (const x of arrayX) {
    const baseToday = position.requiredBase ? position.requiredBase - 300 : position.balance * position.openLiqPrice[0];
    const longsToday = position.openLongs !== undefined ? position.openLongs : position.balance * position.openLiqPrice[1];
    const shortsToday = position.openShorts !== undefined ? position.openShorts : position.balance * position.openLiqPrice[2];

    // hold value today
    const longPriceToday = blackScholes(x, strikePrice, timeToExpiryDays / 365, selectedVolatility / 100, rate, isCall);
    const holdValueToday = baseToday + 300 + longsToday * longPriceToday + shortsToday * (isCall ? x - longPriceToday : strikePrice - longPriceToday);

    {
      // position value today
      const { positionValue, extraLongs } = getLPPositionValue(
        position,
        underPrice,
        x,
        timeToExpiryDays,
        selectedVolatility,
        rate,
        lowerPriceInVol,
        currentPriceInVol,
        upperPriceInVol,
        helper,
        market.penaltyBase
      );

      const isSettled = timeToExpiryDays === 0;

      currentToday.push({
        x,
        y: Math.round((positionValue - holdValueToday + feesCollectedToday - interestOnDebtToday) * 1000) / 1000,
        value: positionValue + feesCollectedToday - interestOnDebtToday,
        optionValue: extraLongs * longPriceToday,
        markPrice: longPriceToday,
        delta: isSettled ? 0 : extraLongs * greeks.getDelta(x, strikePrice, timeToExpiryDays / 365, selectedVolatility / 100, rate, isCall ? "call" : "put"),
        gamma: isSettled ? 0 : extraLongs * greeks.getGamma(x, strikePrice, timeToExpiryDays / 365, selectedVolatility / 100, rate, isCall ? "call" : "put"),
        theta: isSettled ? 0 : extraLongs * greeks.getTheta(x, strikePrice, timeToExpiryDays / 365, selectedVolatility / 100, rate, isCall ? "call" : "put"),
        vega: isSettled ? 0 : extraLongs * greeks.getVega(x, strikePrice, timeToExpiryDays / 365, selectedVolatility / 100, rate, isCall ? "call" : "put"),
        lots: extraLongs
      });

      // greeks
      if (x === specialXs[0]) {
        delta = extraLongs * greeks.getDelta(x, strikePrice, timeToExpiryDays / 365, selectedVolatility / 100, rate, isCall ? "call" : "put");
        gamma = extraLongs * greeks.getGamma(x, strikePrice, timeToExpiryDays / 365, selectedVolatility / 100, rate, isCall ? "call" : "put");
        theta = extraLongs * greeks.getTheta(x, strikePrice, timeToExpiryDays / 365, selectedVolatility / 100, rate, isCall ? "call" : "put");
        vega = extraLongs * greeks.getVega(x, strikePrice, timeToExpiryDays / 365, selectedVolatility / 100, rate, isCall ? "call" : "put");
      }
    }

    // hold value expiry
    // NOTE: leave commented until we see how should we calculate position value
    const longPriceExpiry = blackScholes(x, strikePrice, 0, selectedVolatility / 100, rate, isCall);
    const holdValueExpiry = baseToday + 300 + longsToday * longPriceExpiry + shortsToday * (isCall ? x - longPriceExpiry : strikePrice - longPriceExpiry);

    {
      // position value expiry
      const { positionValue, extraLongs } = getLPPositionValue(
        position,
        underPrice,
        x,
        0,
        selectedVolatility,
        rate,
        lowerPriceInVol,
        currentPriceInVol,
        upperPriceInVol,
        helper,
        market.penaltyBase
      );
      currentAtExpire.push({
        x,
        y: positionValue - holdValueExpiry + feesCollectedExpiry - interestOnDebtExpiry,
        value: positionValue + feesCollectedExpiry - interestOnDebtExpiry,
        optionValue: extraLongs * longPriceToday,
        markPrice: longPriceExpiry,
        delta: 0,
        gamma: 0,
        theta: 0,
        vega: 0,
        lots: extraLongs
      });
    }
  }

  populateSeries(allAtExpire, currentAtExpire);
  populateSeries(allToday, currentToday);

  return { delta, gamma, theta, vega, feesProjected: feesCollectedToday - interestOnDebtToday };
};

const populateSeries = (allSeries, currentSeries) => {
  for (let i = 0; i < currentSeries.length; i++) {
    if (allSeries.length !== currentSeries.length) {
      allSeries.push(currentSeries[i]);
    } else {
      allSeries[i] = {
        ...allSeries[i],
        y: allSeries[i].y + currentSeries[i].y,
        value: allSeries[i].value + currentSeries[i].value,
        optionValue: allSeries[i].optionValue + currentSeries[i].optionValue,
        delta: allSeries[i].delta + currentSeries[i].delta,
        gamma: allSeries[i].gamma + currentSeries[i].gamma,
        theta: allSeries[i].theta + currentSeries[i].theta,
        vega: allSeries[i].vega + currentSeries[i].vega,
        lots: allSeries[i].lots + currentSeries[i].lots
      };
    }
  }
};

export const getArrayX = (minDomainX, maxDomainX, specialXs, isLiquidation) => {
  const arrayX = [];
  // if calculation is for liquidation price, use only 20 points
  const points = isLiquidation ? 20 : 40;
  const step = (maxDomainX - minDomainX) / points;
  if (step > 0) {
    for (let x = minDomainX; x <= maxDomainX; x += step) {
      arrayX.push(x);
    }
  }

  for (let specX of specialXs) {
    if (!arrayX.includes(specX)) {
      arrayX.push(specX);
    }
  }

  arrayX.sort((a, b) => a - b);

  return arrayX;
};

export const getLPPositionValue = (
  position,
  underPrice,
  selectedUnderPrice,
  selectedDaysToExpiry,
  vol,
  rate,
  lowerPriceInVol,
  currentPriceInVol,
  upperPriceInVol,
  helper,
  penalty
) => {
  const isCall = position.isCall;
  const strikePrice = Number(position.strikePrice);
  const liquidity = position.balance;
  const currentLongPrice = blackScholes(selectedUnderPrice, strikePrice, selectedDaysToExpiry / 365, vol / 100, rate, isCall);

  const openShorts = position.openShorts !== undefined ? position.openShorts : position.balance * position.openLiqPrice[2];
  const openLongs = position.openLongs !== undefined ? position.openLongs : position.balance * position.openLiqPrice[1];
  const shortsInBase = openShorts * (isCall ? selectedUnderPrice - currentLongPrice : strikePrice - currentLongPrice);

  const closeLongs = position.openLongs !== undefined ? position.openLongs : position.balance * position.liqPrice[1];
  const closeBaseBalance = position.requiredBase ? position.requiredBase - 300 : position.balance * position.liqPrice[0] - position.feeBalance;
  const currentTime = Math.round(new Date().getTime() / 1000);

  // TODO: this doesn't account for interest rate
  const debtInBase = 0;

  // special case when nothing is traded yet
  if (closeLongs === openShorts && currentPriceInVol === vol) {
    const positionValue = closeBaseBalance + 300 + closeLongs * (isCall ? selectedUnderPrice : strikePrice);
    const extraLongs = 0;
    return { positionValue, extraLongs };
  }

  let positionValue = 0;
  let extraLongs = 0;
  // calculate when vol below range
  if (vol <= lowerPriceInVol) {
    const longsBought = getExactLongsInSubrange(Math.sqrt(lowerPriceInVol), Math.sqrt(currentPriceInVol), liquidity);
    const baseSold = getExactBaseInSubrange(helper, Math.sqrt(lowerPriceInVol), Math.sqrt(currentPriceInVol), liquidity, underPrice, currentTime);

    positionValue = closeBaseBalance - baseSold + (closeLongs + longsBought) * currentLongPrice + shortsInBase - debtInBase;
    extraLongs = closeLongs + longsBought - openLongs;
  }

  // calculate when vol in range
  if (vol > lowerPriceInVol && vol < upperPriceInVol) {
    if (vol < currentPriceInVol) {
      const longsBought = getExactLongsInSubrange(Math.sqrt(vol), Math.sqrt(currentPriceInVol), liquidity);
      const baseSold = getExactBaseInSubrange(helper, Math.sqrt(vol), Math.sqrt(currentPriceInVol), liquidity, underPrice, currentTime);
      positionValue = closeBaseBalance - baseSold + (closeLongs + longsBought) * currentLongPrice + shortsInBase - debtInBase;
      extraLongs = closeLongs + longsBought - openLongs;
    } else {
      const longsSold = getExactLongsInSubrange(Math.sqrt(currentPriceInVol), Math.sqrt(vol), liquidity);
      const baseBought = getExactBaseInSubrange(helper, Math.sqrt(currentPriceInVol), Math.sqrt(vol), liquidity, underPrice, currentTime);
      positionValue = closeBaseBalance + baseBought + (closeLongs - longsSold) * currentLongPrice + shortsInBase - debtInBase;
      extraLongs = closeLongs - longsSold - openLongs;
    }
  }

  // calculate when vol above range
  if (vol >= upperPriceInVol) {
    const longsSold = getExactLongsInSubrange(Math.sqrt(currentPriceInVol), Math.sqrt(upperPriceInVol), liquidity);
    const baseBought = getExactBaseInSubrange(helper, Math.sqrt(currentPriceInVol), Math.sqrt(upperPriceInVol), liquidity, underPrice, currentTime);
    positionValue = closeBaseBalance + baseBought + (closeLongs - longsSold) * currentLongPrice + shortsInBase - debtInBase;
    extraLongs = closeLongs - longsSold - openLongs;
  }
  positionValue += penalty; // locked funds for penalty
  return { positionValue, extraLongs };
};

export const getLPFeesCollected = (position, market, selectedUnderPrice, selectedDaysToExpiry, rate) => {
  const isCall = position.isCall;
  const strikePrice = Number(position.strikePrice);
  const currentPriceInVol = parseFloat(market.longPriceInVol);
  const liquidity = position.balance;
  const expirationInMilliSec = Math.abs(market.expirationTime * 1000 - new Date());
  const expirationInDays = expirationInMilliSec / (1000 * 60 * 60 * 24);
  const days = expirationInDays - selectedDaysToExpiry;
  const currentLongPrice = blackScholes(selectedUnderPrice, strikePrice, expirationInDays / 365, currentPriceInVol / 100, rate, isCall);
  const fee = getFee(selectedUnderPrice, currentLongPrice, market.marketHelper.minFee, market.marketHelper.maxFee, market.marketHelper.steepness);
  const dailyVolume = getDailyVolume(market);
  const feesCollected = position.feeBalance;

  // if preview position or existing position with no fees collected,
  // use fee projections based on pool share, daily volume, and days selected
  if (position.isPreview || (!position.isPreview && feesCollected === 0)) {
    // if out of range, feesProjected is 0
    if (market.liquidityInCurrentTick === 0) {
      return 0;
    }
    const poolShare = liquidity / (market.liquidityInCurrentTick + (position.isPreview ? liquidity : 0));
    const feesProjected = days * dailyVolume * currentLongPrice * fee * poolShare;
    return feesProjected;
  }

  // if existing position with fees collected, use fees collected
  const openPositionTime = Math.abs(position.openTime * 1000 - new Date());
  // NOTE: if position is less than a day old, use 1 day
  const daysSinceOpen = Math.max(1, openPositionTime / (1000 * 60 * 60 * 24));
  const feesPerDay = feesCollected / daysSinceOpen;
  const feesProjected = feesCollected + feesPerDay * Math.max(0, days);
  return feesProjected;
};

export const getInterestOnDebt = (position, market, selectedUnderPrice, selectedDaysToExpiry, borrowRates) => {
  const isCall = position.isCall;
  const strikePrice = Number(position.strikePrice);
  const openLongs = position.openLongs !== undefined ? position.openLongs : position.balance * position.openLiqPrice[1];
  //console.log("openLongs", openLongs);
  // interest on debt TODO: v1 debt can be reduced by user
  const debtInBase = openLongs * (isCall ? selectedUnderPrice : strikePrice);
  const borrowRate = isCall ? borrowRates.underBorrowRate : borrowRates.baseBorrowRate;
  // console.log("borrowRate:", borrowRate);
  // console.log("debtInBase", debtInBase);
  // NOTE: if position is less than a day old, use 1 day
  const expirationInMilliSec = Math.abs(market.expirationTime * 1000 - new Date());
  const expirationInDays = expirationInMilliSec / (1000 * 60 * 60 * 24);
  const days = expirationInDays - selectedDaysToExpiry;
  // const interestPerDay = (debtInBase * 0.0655) / 365;
  const interestPerDay = (debtInBase * borrowRate) / 365;


  // console.log("interestPerDay", interestPerDay);

  // if preview position, use future interest
  if (position.isPreview) {
    const interestOnDebt = interestPerDay * Math.max(0, days);
    return interestOnDebt;
  }

  // if existing position with some time elapsed, use interest collected + future interest
  const openPositionTime = Math.abs(position.openTime * 1000 - new Date());
  const daysSinceOpen = Math.max(0, openPositionTime / (1000 * 60 * 60 * 24));
  const interestCollected = interestPerDay * daysSinceOpen;
  const interestOnDebt = interestCollected + interestPerDay * Math.max(0, days);

  // console.log("interestPerDay", interestPerDay, interestOnDebt, debtInBase, days);

  return interestOnDebt;
};

export const getSpecialXs = (positions, markets, underPrice) => {
  const specialXs = [];

  // add under price
  specialXs.push(underPrice);

  // add strike prices
  positions.forEach(position => {
    const market = markets.find(market => market.marketId === position.marketId);
    const strikePrice = Number(market.normalizedStrikePrice);
    if (!market.isFuture) {
      specialXs.push(strikePrice);
    }
  });

  return specialXs;
};

export const calculateMinMaxDomainXAndFarthestExpireDate = (positions, markets, underPrice, deviations) => {
  let minDomainX,
    maxDomainX,
    secondsLeftToFarthestExpirationDate,
    farthestExpirationTime = null;
  const currentTime = moment().unix();

  // find date and volatility of position with highest expiration time
  let volatilityOfFarthestPosition = 0;
  positions.forEach(position => {
    const market = markets.find(market => market.marketId === position.marketId);
    const expirationTime = parseInt(market.expirationTime);
    const secondsLeftToExpirationDate = expirationTime - currentTime;

    if (!secondsLeftToFarthestExpirationDate || secondsLeftToExpirationDate > secondsLeftToFarthestExpirationDate) {
      secondsLeftToFarthestExpirationDate = secondsLeftToExpirationDate;
      farthestExpirationTime = expirationTime;
      let volatility = parseFloat(market.longPriceInVol);
      // if future, use volatility of closest ATM strike price
      if (market.isFuture) {
        const closestMarkets = findClosestMarketsToExpirationTime(markets, market.expirationTime);
        if (closestMarkets.length > 0) {
          volatility = Math.round(findVolForClosestStrikePrice(closestMarkets, underPrice) * 100);
        } else {
          volatility = 50;
        }
      }
      volatilityOfFarthestPosition = volatility;
    }
  });
  // min and max X are "deviations" standard deviations from under price
  const avgVolatility = volatilityOfFarthestPosition / 100;
  const yearsToExpiry = (farthestExpirationTime - currentTime) / (60 * 60 * 24 * 365);
  const stdDeviation = avgVolatility * Math.sqrt(yearsToExpiry);

  minDomainX = underPrice / (1 + deviations * stdDeviation);
  maxDomainX = underPrice * (1 + deviations * stdDeviation);
  minDomainX = minDomainX - (minDomainX % 10);
  maxDomainX = maxDomainX - (maxDomainX % 10) + (maxDomainX % 10 !== 0 ? 10 : 0);

  return { minDomainX, maxDomainX, farthestExpirationTime };
};

export const calculateMinMaxDomainXVol = (positions, markets) => {
  let maxSecondsToExpiration = 0;
  let totalVolatility = 0;
  positions.forEach(position => {
    const market = markets.find(market => market.marketId === position.marketId);
    const secondsToExpiration = parseInt(market.expirationTime) - moment().unix();

    if (secondsToExpiration > maxSecondsToExpiration) {
      maxSecondsToExpiration = secondsToExpiration;
    }

    totalVolatility += parseFloat(market.longPriceInVol);
  });

  // min and max X are 3 standard deviations of 0.6 (which is volatility of volatility)
  const avgVolatility = totalVolatility / positions.length;
  const yearsToExpiry = maxSecondsToExpiration / SECONDS_IN_YEAR;
  // NOTE: volatility of volatility 60%
  const stdDeviation = 0.6 * Math.sqrt(yearsToExpiry);

  const minDomainX = Math.round(avgVolatility / (1 + 3 * stdDeviation));
  const maxDomainX = Math.round(avgVolatility * (1 + 3 * stdDeviation));

  return { minDomainX, maxDomainX, avgX: avgVolatility };
};

// y range for all positions sum chart
export const calculateMinMaxDomainY = (allToday, allAtExpire) => {
  let minDomainY = null;
  let maxDomainY = null;
  for (let i = 0; i < allAtExpire.length; i++) {
    // max y
    if (maxDomainY == null) {
      maxDomainY = allToday[i].y > allAtExpire[i].y ? allToday[i].y : allAtExpire[i].y;
    }
    if (allToday[i].y > maxDomainY) {
      maxDomainY = allToday[i].y;
    }
    if (allAtExpire[i].y > maxDomainY) {
      maxDomainY = allAtExpire[i].y;
    }
    // min y
    if (minDomainY == null) {
      minDomainY = allToday[i].y < allAtExpire[i].y ? allToday[i].y : allAtExpire[i].y;
    }
    if (allToday[i].y < minDomainY) {
      minDomainY = allToday[i].y;
    }
    if (allAtExpire[i].y < minDomainY) {
      minDomainY = allAtExpire[i].y;
    }
  }

  // add extra to domains to avoid UI display cutoff
  const diff = maxDomainY - minDomainY;
  if (diff > 0.1) {
    minDomainY -= 0.01 * diff;
    maxDomainY += 0.01 * diff;
  } else {
    minDomainY -= 0.25 * Math.abs(minDomainY);
    maxDomainY += 0.25 * Math.abs(maxDomainY);
  }

  // never use Y domain smaller than 1
  if (maxDomainY - minDomainY < 1) {
    minDomainY = maxDomainY - 1;
  }

  return { minDomainY, maxDomainY };
};

// todo: temporary, use optionPrice from utils when rate is used as parameter
export const blackScholes = (underPrice, strikePrice, timeToExpirationInYears, iv, rate, isCall) => {
  if (underPrice === strikePrice && timeToExpirationInYears === 0) {
    return 0;
  }

  return bs.blackScholes(underPrice, strikePrice, timeToExpirationInYears, iv, rate, isCall ? "call" : "put");
};

export const interpolateY = (series, xValue) => {
  let afterXIndex = 0;
  if (series.length > 0) {
    for (let i = 0; i < series.length; i++) {
      if (series[i].x >= xValue) {
        afterXIndex = i;
        break;
      }
    }

    if (afterXIndex === 0) {
      return series[afterXIndex].y;
    }

    let xValueBefore = series[afterXIndex - 1].x;
    let xValueAfter = series[afterXIndex].x;
    let yValueBefore = series[afterXIndex - 1].y;
    let yValueAfter = series[afterXIndex].y;
    let percent = (xValue - xValueBefore) / (xValueAfter - xValueBefore);

    return yValueBefore * (1 - percent) + yValueAfter * percent;
  }

  return 0;
};

export const interpolateGreeks = (series, xValue) => {
  const deltaSeries = series.map(item => {
    return { x: item.x, y: item.delta };
  });

  const gammaSeries = series.map(item => {
    return { x: item.x, y: item.gamma };
  });

  const thetaSeries = series.map(item => {
    return { x: item.x, y: item.theta };
  });

  const vegaSeries = series.map(item => {
    return { x: item.x, y: item.vega };
  });

  const delta = interpolateY(deltaSeries, xValue);
  const gamma = interpolateY(gammaSeries, xValue);
  const theta = interpolateY(thetaSeries, xValue);
  const vega = interpolateY(vegaSeries, xValue);

  return { delta, gamma, theta, vega };
};

export const interpolateValue = (series, xValue) => {
  const valueSeries = series.map(item => {
    return { x: item.x, y: item.value };
  });

  const value = interpolateY(valueSeries, xValue);

  return value;
};

export const interpolateOptionValue = (series, xValue) => {
  const valueSeries = series.map(item => {
    return { x: item.x, y: item.optionValue };
  });

  const value = interpolateY(valueSeries, xValue);

  return value;
};

export const interpolateLots = (series, xValue) => {
  const valueSeries = series.map(item => {
    return { x: item.x, y: item.lots };
  });

  const value = interpolateY(valueSeries, xValue);

  return value;
};

export const getHighestDaysToExpiry = (positions, markets, timestampNow = new Date()) => {
  let highestDaysToExpiry = 0;
  for (const position of positions) {
    const market = markets.find(market => {
      return market.marketId === position.marketId;
    });
    const timeToExpiration = Math.abs(market.expirationTime * 1000 - timestampNow);
    const daysToExpiration = timeToExpiration / (1000 * 60 * 60 * 24);
    if (daysToExpiration > highestDaysToExpiry) {
      highestDaysToExpiry = daysToExpiration;
    }
  }

  return highestDaysToExpiry;
};

export const getHighestDaysToExpiryRounded = (positions, markets) => {
  const timestampNow = new Date();
  const periodsInDay = getPeriodsInDay(positions, markets);
  const dayPeriodsToExpiration = Math.round(getHighestDaysToExpiry(positions, markets, timestampNow) * periodsInDay);
  const daysToExpirationRounded = dayPeriodsToExpiration / periodsInDay;

  return daysToExpirationRounded;
};

// function that returns periods in a day based on the highest days to expiry, used for time to expiration slider
export const getPeriodsInDay = (selectedPositions, markets) => {
  const daysToExpiration = Math.round(getHighestDaysToExpiry(selectedPositions, markets, new Date()));
  if (daysToExpiration <= 1) {
    return 24; // divide the day into 24 periods (1 hour)
  } else if (daysToExpiration <= 3) {
    return 8; // divide the day into 8 periods (3 hours)
  }

  return 1; // divide the day into 1 period (24 hours)
};

export const interpolateLiqPrice = (series, assetsBase, assetsUnder, debtBase, debtUnder, underPrice) => {
  // create new series with y being health
  const healthSeries = [];
  for (let i = 0; i < series.length; i++) {
    const debtAtUnder = debtBase + debtUnder * series[i].x;
    const valueAtUnder = series[i].value;
    const totalAtUnder = assetsBase + assetsUnder * series[i].x + valueAtUnder;
    const healthAtUnder = totalAtUnder / debtAtUnder;
    healthSeries.push({ x: series[i].x, health: healthAtUnder });
  }

  // console.log("healthSeries", healthSeries);

  const upperLiqPrice = findUpperAccountLiqPrice(healthSeries, 1.15);
  // console.log("upperLiqPrice", upperLiqPrice);

  const lowerLiqPrice = findLowerAccountLiqPrice(healthSeries, 1.15);
  // console.log("lowerLiqPrice", lowerLiqPrice);

  return { lowerLiqPrice, upperLiqPrice };
};

const findUpperAccountLiqPrice = (array, targetHealth) => {
  // iterate through the array of objects
  for (let i = 1; i < array.length; i++) {
    const prevObj = array[i - 1];
    const currObj = array[i];

    // check if the target health falls between the current and previous objects
    if (targetHealth <= prevObj.health && targetHealth >= currObj.health) {
      // perform linear interpolation to find the value of x
      const xRange = currObj.x - prevObj.x;
      const healthRange = prevObj.health - currObj.health;
      const healthDifference = prevObj.health - targetHealth;
      const xDifference = (healthDifference / healthRange) * xRange;
      const interpolatedX = prevObj.x + xDifference;

      return interpolatedX;
    }
  }

  // ff the target health is outside the range of the array, return null
  return array[array.length - 1].x + 100;
};

const findLowerAccountLiqPrice = (array, targetHealth) => {
  // Iterate through the array of objects
  for (let i = 1; i < array.length; i++) {
    const prevObj = array[i - 1];
    const currObj = array[i];

    // Check if the target health falls between the current and previous objects
    if (targetHealth >= prevObj.health && targetHealth <= currObj.health) {
      // Perform linear interpolation to find the value of x
      const xRange = currObj.x - prevObj.x;
      const healthRange = currObj.health - prevObj.health;
      const healthDifference = targetHealth - prevObj.health;
      const xDifference = (healthDifference / healthRange) * xRange;
      const interpolatedX = prevObj.x + xDifference;

      return interpolatedX;
    }
  }

  // If the target health is outside the range of the array, return null
  return array[0].x - 100;
};

export const findMinMaxY = series => {
  let minY = Number.MAX_VALUE;
  let maxY = Number.MIN_VALUE;
  for (const point of series) {
    minY = Math.min(point.y, minY);
    maxY = Math.max(point.y, maxY);
  }

  return { minY, maxY };
};

/**
 * Standard normal cumulative distribution function.  The probability is estimated
 * by expanding the CDF into a series using the first 100 terms.
 * See {@link http://en.wikipedia.org/wiki/Normal_distribution#Cumulative_distribution_function|Wikipedia page}.
 *
 * @param {Number} x The upper bound to integrate over.  This is P{Z <= x} where Z is a standard normal random variable.
 * @returns {Number} The probability that a standard normal random variable will be less than or equal to x
 */
// export const stdNormCDF = x => {
//   let probability = 0;
//   // avoid divergence in the series which happens around +/-8 when summing the
//   // first 100 terms
//   if (x >= 8) {
//     probability = 1;
//   } else if (x <= -8) {
//     probability = 0;
//   } else {
//     for (let i = 0; i < 100; i++) {
//       probability += Math.pow(x, 2 * i + 1) / _doubleFactorial(2 * i + 1);
//     }
//     probability *= Math.pow(Math.E, -0.5 * Math.pow(x, 2));
//     probability /= Math.sqrt(2 * Math.PI);
//     probability += 0.5;
//   }

//   return probability;
// };

// /**
//  * Double factorial.
//  *
//  * @param {Number} n The number to calculate the double factorial of
//  * @returns {Number} The double factorial of n
//  */
// const _doubleFactorial = n => {
//   let val = 1;
//   for (let i = n; i > 1; i -= 2) {
//     val *= i;
//   }
//   return val;
// };

// /**
//  * @param   {Number} s       Current price of the underlying
//  * @param   {Number} k       Strike price
//  * @param   {Number} t       Time to experiation in years
//  * @param   {Number} v       Volatility as a decimal
//  * @param   {Number} r       Anual risk-free interest rate as a decimal
//  * @param   {String} callPut The type of option to be priced - "call" or "put"
//  * @returns {Number}         Probability that price will end up above strike price
//  */
// export const probability = (s, k, t, v, r) => {
//   let d1 = (r * t + (Math.pow(v, 2) * t) / 2 - Math.log(k / s)) / (v * Math.sqrt(t));
//   let d2 = d1 - v * Math.sqrt(t);

//   // let p1 = stdNormCDF(d2) * 100;
//   //console.log("probability 1: ", p1.toFixed(3) + "%");

//   // const stdDeviationPercent = v * Math.sqrt(t);
//   // let z = (k / s - 1) / stdDeviationPercent;
//   // let p2 = (1 - stdNormCDF(z)) * 100;
//   //console.log("probability 2:", p2.toFixed(3) + "%")

//   return stdNormCDF(d2);
// };
