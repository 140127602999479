import React from "react";
import PropTypes from "prop-types";
import MyModal from "./utilities/MyModal.jsx";

async function addBaseSepoliaNetwork() {
  // try switching to existing network (Sepolia is in wallet already)
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x14A34" }] // 84532
    });
    return;
  } catch (error) {
    console.log("There was an error switching to Base Sepolia testnet in your wallet. Trying to add Base Sepolia testnet..");
  }

  // if that doesn't work, try adding new network
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x14A34", // 84532
          rpcUrls: ["https://base-sepolia-rpc.publicnode.com"],
          chainName: "Base Sepolia",
          nativeCurrency: {
            name: "ETH",
            symbol: "ETH",
            decimals: 18
          },
          blockExplorerUrls: ["https://base-sepolia.blockscout.com/"]
        }
      ]
    });
  } catch (error) {
    console.log("There was an error adding Base Sepolia testnet in your wallet.");
    console.log(error);
  }
}

async function addSepoliaNetwork() {
  // try switching to existing network (Sepolia is in wallet already)
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0xAA36A7" }] // 11155111
    });
    return;
  } catch (error) {
    console.log("There was an error switching to Ethereum Sepolia testnet in your wallet. Trying to add Ethereum Sepolia testnet..");
  }

  // if that doesn't work, try adding new network
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0xAA36A7", // 11155111
          rpcUrls: ["https://eth-sepolia.g.alchemy.com/v2/demo"],
          chainName: "Sepolia",
          nativeCurrency: {
            name: "ETH",
            symbol: "ETH",
            decimals: 18
          },
          blockExplorerUrls: ["https://sepolia.etherscan.io"]
        }
      ]
    });
  } catch (error) {
    console.log("There was an error adding Ethereum Sepolia testnet in your wallet.");
    console.log(error);
  }
}

async function addGammaNetwork() {
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x1EFD", // 7933
          rpcUrls: ["https://gethrpc.gammaoptions.com/"],
          chainName: "GammaNet",
          nativeCurrency: {
            name: "ETH",
            symbol: "ETH",
            decimals: 18
          },
          blockExplorerUrls: null
        }
      ]
    });
  } catch (error) {
    console.log("There was an error adding GammaNet to your wallet.");
    console.log(error);
  }
}

export const noContractsOnCurrentNetworkErrMessage = (
  <>
    We are currently in a beta testing phase on Base Sepolia test network. To use our app, switch the network to Base Sepolia in your wallet.
    <br /> <br />
    Click the button below to easily switch to Base Sepolia testnet. <nbsp />
    <br /> <br />
    <button className="addGammaNetToWalletButton" onClick={() => addBaseSepoliaNetwork()}>
      Switch to Base Sepolia Testnet
    </button>
  </>
);

class BreakingErrorModal extends React.Component {
  render() {
    const isEmptyMessage = this.props.message === "";
    return (
      <MyModal isOpen={this.props.isOpen} isUnclosable>
        <div
          id="errorModal"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {!isEmptyMessage && <img className="errorImg" />}
          {isEmptyMessage ? (
            <span className="welcomeText">WELCOME TO GAMMA OPTIONS</span>
          ) : (
            <span className="errorRedMessage">OOOPS, SOMETHING WENT WRONG</span>
          )}
          <span className="errorWhiteMessage">{isEmptyMessage ? noContractsOnCurrentNetworkErrMessage : this.props.message}</span>
        </div>
      </MyModal>
    );
  }
}

BreakingErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired
};

export default BreakingErrorModal;
