import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Form, Input, FormGroup } from "reactstrap";
import {
  cursorRangeCheck,
  defaultInputValue,
  formatValue,
  getReadableAccountHealth,
  healthClassName,
  inputDecimals,
  isHex,
  isPositiveNumber,
  isSmallDifference,
  removeCommaLocales,
  replaceCharacter
} from "../../utils/utils.js";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import MyModal from "./utilities/MyModal.jsx";
import { formatUnits } from "@ethersproject/units";
import { useSelector } from "react-redux";
import { marginAccountDataSelector } from "../../store/selectors/marginSelectors";
import { web3AccountLoadedSelector } from "../../store/selectors/web3Selectors";
import { avgUnderlyingTokenPriceSelector } from "../../store/selectors/tokensSelectors";
import { fromBn, toBn } from "evm-bn";
import MyTooltip2 from "./MyTooltip2.jsx";
import ReactGA from "react-ga4";
import MyTooltip from "./MyTooltip.jsx";

let lastSelectedInputTimestamp = null;
let lastClickedModalOverlay = null;

const WithdrawFundsModal = props => {
  const [inputClass, setInputClass] = useState("has-success");
  const [withdrawInputValue, setWithdrawInputValue] = useState("1");
  const [withdrawAmount, setWithdrawAmount] = useState(fromBn(toBn("1", 18)));
  const [withdrawInputErrorMessage, setWithdrawInputErrorMessage] = useState("");

  const [tooltipIsVisible, setTooltipIsVisible] = useState(false);
  const [tooltipTarget, setTooltipTarget] = useState("");
  const [tooltipContent, setTooltipContent] = useState("");

  const marginAccountData = useSelector(marginAccountDataSelector);
  const web3AccountLoaded = useSelector(web3AccountLoadedSelector);
  const underlyingTokenPrice = useSelector(avgUnderlyingTokenPriceSelector);

  const withdrawModalInputRef = useRef(null);
  const withdrawButtonRef = useRef(null);
  const idPrefix = "withdraw-funds-modal-";

  useEffect(() => {
    const modalDialog = document.querySelector(".modal-dialog");

    if (modalDialog) {
      $(".modal-black").draggable({
        handle: ".modal-header"
      });
    }

    withdrawModalInputRef.current.addEventListener("paste", paste);

    withdrawModalInputRef.current.select();

    const chunk = "withdraw-modal";
    console.log("Navigate to: " + window.location.pathname + "/" + chunk); // eslint-disable-line no-console
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + "/" + chunk, title: "Withdraw Modal" });

    onInputChange();

    return () => {
      document.onkeydown = null;
    };
  }, []);

  useEffect(() => {
    document.onkeydown = onKeyPress;
  }, [withdrawInputValue]);

  const paste = e => {
    e.stopPropagation();
    e.preventDefault();

    const selection = window.getSelection().toString();

    const clipboardData = e.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData("Text");
    pastedData = removeCommaLocales(pastedData);
    let newPastedData;
    if (selection !== "") {
      newPastedData = inputDecimals(
        replaceCharacter(
          withdrawModalInputRef.current.value,
          withdrawModalInputRef.current.selectionStart,
          withdrawModalInputRef.current.selectionEnd - 1,
          pastedData
        ),
        props.token.symbol
      );
    } else {
      newPastedData = inputDecimals(withdrawModalInputRef.current.value + pastedData, props.token.symbol);
    }
    if (newPastedData !== undefined && newPastedData !== null && !isHex(newPastedData)) {
      if (selection !== "") {
        withdrawModalInputRef.current.value = replaceCharacter(
          withdrawModalInputRef.current.value,
          withdrawModalInputRef.current.selectionStart,
          withdrawModalInputRef.current.selectionEnd - 1,
          pastedData
        );
      } else {
        withdrawModalInputRef.current.value = withdrawModalInputRef.current.value + pastedData;
      }
    }
    onInputChange();
  };

  const withdrawFunds = () => {
    props.withdrawFunds(withdrawAmount);
  };

  const onInputChange = () => {
    //TODO: try/catch block as temporary prevention for large number entered in input because when using toBn it will crash
    try {
      const inputString = withdrawModalInputRef.current.value.trim();

      const value = inputDecimals(inputString, props.token.symbol);
      if (value === undefined || value === null) {
        return;
      }

      setWithdrawInputValue(inputString);

      if (marginAccountData) {
        if (props.token.symbol === "ETH") {
          let excessInEth = marginAccountData.normalizedExcessLiquidity / Number(underlyingTokenPrice.normalized);
          excessInEth = marginAccountData.debt > 0 ? excessInEth - 0.001 : excessInEth;
          if (
            // check if excess liquidity in eth is less than input value
            excessInEth.toFixed(4) < Number(value) &&
            excessInEth.toFixed(4) < props.token.normalizedAccountBalance
          ) {
            let allowed = excessInEth;
            allowed = fromBn(toBn(allowed.toString(), 18));
            if (allowed < 0) {
              setInputClass("has-danger");
              setWithdrawInputErrorMessage("Can't withdraw ETH");
              return;
            }
            setInputClass("has-danger");
            setWithdrawInputErrorMessage("Your excess liquidity is " + Number(allowed).toFixed(4) + " in ETH");
            return;
          }
        } else if (props.token.symbol === "USD") {
          let excessLiquidity = marginAccountData.normalizedExcessLiquidity;
          excessLiquidity = marginAccountData.debt > 0 ? excessLiquidity - 0.01 : excessLiquidity;
          if (excessLiquidity.toFixed(2) < Number(value) && excessLiquidity.toFixed(2) < props.token.normalizedAccountBalance) {
            let allowed = excessLiquidity;
            allowed = fromBn(toBn(allowed.toString(), 18));
            if (allowed < 0) {
              setInputClass("has-danger");
              setWithdrawInputErrorMessage("Can't withdraw USD");
              return;
            }
            setInputClass("has-danger");
            setWithdrawInputErrorMessage("Your excess liquidity is " + Number(allowed).toFixed(2));
            return;
          }
        }
      }

      if (value === "") {
        setInputClass("");
        return;
      }

      if (!isPositiveNumber(value)) {
        setInputClass("has-danger");
        setWithdrawInputErrorMessage("Enter a positive number");
        return;
      }

      let amountSplit = value.split(".");
      const newAmountSplit = amountSplit.length === 1 || amountSplit[1] === "" ? amountSplit[0] : amountSplit[0] + "." + amountSplit[1];
      let newAmount = fromBn(toBn(newAmountSplit.toString(), 18));

      if (
        Number(value) > Number(props.token.normalizedAccountBalance) &&
        !isSmallDifference(Number(value), props.token.normalizedAccountBalance, props.token.symbol)
      ) {
        setInputClass("has-danger");
        setWithdrawInputErrorMessage(
          "Can't withdraw more than " +
            formatValue(props.token.normalizedAccountBalance, props.token.symbol === "USD" ? 2 : 4) +
            ". Click max button to withdraw maximum allowed amount."
        );
        return;
      }

      const { max } = calculateMax();

      if (isSmallDifference(max, newAmount, props.token.symbol)) {
        setWithdrawAmount(max);
      } else {
        setWithdrawAmount(newAmount);
      }
      setInputClass("has-success");
    } catch (e) {
      console.log(e);
    }
  };

  const increaseWithdrawAmount = () => {
    if (withdrawInputValue === "") {
      setWithdrawInputValue(1);
      withdrawModalInputRef.current.value = "1";
      onInputChange();
      return;
    }

    if (Math.abs(withdrawInputValue - props.token.normalizedAccountBalance) >= 1) {
      // if it is not max value
      if (withdrawModalInputRef.current.value.split(".").length > 1) {
        setWithdrawInputValue(Math.ceil(withdrawInputValue));
        withdrawModalInputRef.current.value = Math.ceil(withdrawInputValue);
        onInputChange();
      } else {
        setWithdrawInputValue(withdrawInputValue + 1);
        withdrawModalInputRef.current.value = Number(withdrawInputValue) + 1;
        onInputChange();
      }
    }
  };

  const decreaseWithdrawAmount = () => {
    if (withdrawInputValue !== "" && withdrawInputValue != 1 && withdrawInputValue != 0) {
      if (withdrawModalInputRef.current.value.split(".").length > 1) {
        setWithdrawInputValue(Math.floor(withdrawInputValue));
        withdrawModalInputRef.current.value = Math.floor(withdrawInputValue);
        onInputChange();
      } else {
        setWithdrawInputValue(Number(withdrawInputValue) - 1);
        withdrawModalInputRef.current.value = Number(withdrawInputValue) - 1;
        onInputChange();
      }
    }
  };

  const clearInput = () => {
    withdrawModalInputRef.current.focus();
    setInputClass("");
    setWithdrawInputValue("");
  };

  const calculateMax = () => {
    let max = null;
    let maxInputValue = null;
    if (props.token.symbol === "ETH") {
      if (marginAccountData.normalizedExcessLiquidity <= props.token.normalizedAccountBalance * Number(underlyingTokenPrice.normalized)) {
        let excessInEth = marginAccountData.normalizedExcessLiquidity / Number(underlyingTokenPrice.normalized);
        excessInEth = marginAccountData.debt > 0 ? excessInEth - 0.001 : excessInEth;
        max = fromBn(toBn(excessInEth.toString(), 18));
        maxInputValue = excessInEth.toFixed(4);
        if (max < 0) {
          max = 0;
          maxInputValue = 0;
        }
      } else {
        max = fromBn(props.token.accountBalance);
        maxInputValue = props.token.normalizedAccountBalance.toFixed(4);
      }
    } else if (props.token.symbol === "USD") {
      // todo: the problem with max button is that if user has debt,
      // his usd (or ETH) amount keeps going down, so by the time the tx is
      // confirmed, he might be underwater. 0.996 is temporary solution,
      // contract should check if amount is more that allowed, and cap it to max allowed
      // (user doesn't want failed tx, he would rather to withdraw whatever possible)
      if (toBn(marginAccountData.normalizedExcessLiquidity.toString(), 18).lt(toBn(fromBn(props.token.accountBalance)))) {
        max = marginAccountData.debt > 0 ? marginAccountData.normalizedExcessLiquidity * 0.996 : marginAccountData.normalizedExcessLiquidity;
        maxInputValue = max.toFixed(2);
      } else {
        max = fromBn(props.token.accountBalance);
        maxInputValue = props.token.normalizedAccountBalance.toFixed(2);
      }
    }

    return { max, maxInputValue }; //max is real max value which is sent when withdrawing funds, maxInputValue is only for ui (2 or 4 decimals)
  };

  const onMaxButtonClicked = () => {
    const { max, maxInputValue } = calculateMax();
    if (max > 0) {
      setWithdrawInputValue(maxInputValue);
      setWithdrawAmount(max);
      setInputClass("has-success");
      setWithdrawInputErrorMessage("");
    }
  };

  const onKeyPress = e => {
    if (e && e.key === "Escape") {
      props.toggle();
    }
  };

  const getNewHealth = inputValue => {
    const totalDebt = marginAccountData && marginAccountData.debt;
    const totalLiquidity = marginAccountData && parseFloat(formatUnits(marginAccountData.totalLiquidity, 18));

    let value;
    if (props.token.symbol === "USD") {
      value = inputValue;
    } else if (props.token.symbol === "ETH") {
      value = inputValue * Number(underlyingTokenPrice.normalized);
    }

    const health = (Number(totalLiquidity) - value) / totalDebt;

    const readableHealth = getReadableAccountHealth(totalDebt, health);

    const recoveryThreshold = marginAccountData && web3AccountLoaded ? parseFloat(fromBn(marginAccountData.recoveryThreshold)) * 100 : 0;
    const liquidationThreshold = marginAccountData && web3AccountLoaded ? parseFloat(fromBn(marginAccountData.liquidationThreshold)) * 100 : 0;
    const healthColorClass =
      withdrawInputValue !== "" && inputClass !== "has-danger"
        ? healthClassName(readableHealth, health * 100, recoveryThreshold, liquidationThreshold)
        : "disabledTextColor";

    return { healthColorClass, readableHealth };
  };

  const onMouseEnterColumnHeader = (e, text) => {
    if (!tooltipIsVisible) {
      setTooltipIsVisible(true);
      setTooltipContent(text);
      setTooltipTarget(e.currentTarget.id);
    }
  };

  const onMouseLeaveColumnHeader = e => {
    if (!cursorRangeCheck(tooltipTarget, e.clientX, e.clientY)) {
      setTooltipIsVisible(false);
      setTooltipContent("");
      setTooltipTarget("");
      document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", -500 + "px");

      document.querySelector(":root").style.setProperty("--tooltipTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipLeftPosition", -500 + "px");
    }
  };

  const calculateAccountBalance = (value, shouldSetStates) => {
    let amount = props.token && fromBn(props.token.accountBalance);
    const excessLiquidity = marginAccountData && web3AccountLoaded && marginAccountData.normalizedExcessLiquidity;

    let newAmount = Number(amount) - Number(value);

    if (newAmount >= 0) {
      amount = newAmount;
    } else if (isSmallDifference(Number(amount), Number(value), props.token.symbol)) {
      amount = 0;
    } else {
      if (inputClass !== "has-danger" && excessLiquidity > Number(amount) && shouldSetStates) {
        setInputClass("has-danger");
        setWithdrawInputErrorMessage("You don't have enough funds. Click max button to withdraw maximum allowed amount.");
      }
      amount = 0;
    }

    return Number(amount);
  };

  const calculateWalletBalance = value => {
    let amount = props.token && fromBn(props.token.walletBalance18Decimals);

    return Number(amount) + Number(value); //no conditions, only add new value because it withdrawing on wallet
  };

  const onWithdrawClickHandler = e => {
    e.stopPropagation();
    withdrawFunds();
  };

  let printableAccountBalance;
  let printableWalletBalance;
  const max = calculateMax();
  if (withdrawInputValue && inputClass !== "has-danger") {
    printableAccountBalance = calculateAccountBalance(withdrawInputValue, true);
    printableWalletBalance = calculateWalletBalance(withdrawInputValue);
  } else if (withdrawInputValue === "") {
    calculateAccountBalance(withdrawInputValue, true);
    calculateWalletBalance(withdrawInputValue);
    printableAccountBalance = calculateAccountBalance(String(defaultInputValue), false);
    printableWalletBalance = calculateWalletBalance(String(defaultInputValue));
  } else if (inputClass === "has-danger") {
    calculateAccountBalance(withdrawInputValue, true);
    calculateWalletBalance(withdrawInputValue);
    printableAccountBalance = calculateAccountBalance(String(max), false);
    printableWalletBalance = calculateWalletBalance(String(max));
  } else {
    printableAccountBalance = props.token.printableAccountBalance;
    printableWalletBalance = props.token.printableWalletBalance;
  }

  const { healthColorClass, readableHealth } = inputClass !== "has-danger" ? getNewHealth(withdrawInputValue) : getNewHealth(max);

  const shouldBeDisabled = withdrawInputValue === "" || inputClass !== "has-success";

  const tokenSymbol = props.token.symbol === "USD" ? "USDC" : props.token.symbol;

  return (
    <MyModal
      isOpen={props.isOpen}
      toggle={e => {
        lastClickedModalOverlay = Date.now();
        const diff = lastClickedModalOverlay - lastSelectedInputTimestamp;
        if (diff < 500) {
          lastClickedModalOverlay = null;
          lastSelectedInputTimestamp = null;
          return;
        }
        props.toggle();
      }}
    >
      <div
        className="depositWithdrawModalFrame"
        onClick={e => {
          e.stopPropagation();
        }}
        onMouseDown={e => {
          lastSelectedInputTimestamp = Date.now();
        }}
      >
        <div className="headerOfPortfolioModal">
          <span className="portfolioModalHeaderText">Withdraw {tokenSymbol}</span>
          <button aria-hidden data-dismiss="modal" type="button" className="closeModal" onClick={() => props.toggle(true)}>
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>

        <div className="inputAmountAndSettingsErrorMessageContainer">
          <div className="inputAmountAndSettingsContainer">
            <Form>
              <FormGroup>
                <Input
                  id="withdrawModalInput"
                  type="text"
                  autoComplete="off"
                  placeholder="Withdraw amount, decimals allowed... (e.g., 100.50)"
                  className={`inputText${inputClass === "has-danger" ? " inputDanger" : ""}${inputClass === "has-success" ? " inputSuccess" : ""}`}
                  onChange={e => {
                    onInputChange();
                  }}
                  value={withdrawInputValue}
                  autoFocus
                  innerRef={withdrawModalInputRef}
                />
                {inputClass === "has-danger" ? <img className="clearInputError" onClick={clearInput} /> : null}
                {inputClass === "has-success" ? <img className="validInput" /> : null}
                <div className="increaseDecreaseInputButtons">
                  <img className="increaseInputButton" onClick={increaseWithdrawAmount} />
                  <img className="decreaseInputButton" onClick={decreaseWithdrawAmount} />
                </div>
              </FormGroup>
            </Form>
            <button
              id="withdrawModalMaxButton"
              className="maxButton"
              onClick={onMaxButtonClicked}
              onMouseEnter={e => onMouseEnterColumnHeader(e, "Max amount to withdraw to wallet.")}
              onMouseLeave={onMouseLeaveColumnHeader}
            >
              max
            </button>
          </div>
          <div className="errorMessageDepositWithdrawLendContainer">
            <span className="errorMessageDepositWithdrawLendSpan">{inputClass === "has-danger" ? withdrawInputErrorMessage : ""}</span>
          </div>
        </div>

        <div className="withdrawDepositDataInformationContainer">
          <div className="withdrawDepositDataInformationItem">
            <span id={idPrefix + "account-balance"} className={`portfolioLendSmallLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              Account balance
            </span>
            <MyTooltip key={idPrefix + "account-balance"} target={idPrefix + "account-balance"} optionalOffset={15}>
              New account balance after withdrawing {tokenSymbol}.
            </MyTooltip>
            <span className={`portfolioLendBigLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              {props.isPositionToken && props.token.type === "short" && printableAccountBalance !== "0.00"
                ? "-" + formatValue(printableAccountBalance, 2)
                : formatValue(printableAccountBalance, 2) + " " + tokenSymbol}
            </span>
          </div>
          <div className="withdrawDepositDataInformationItem">
            <span id={idPrefix + "wallet-balance"} className={`portfolioLendSmallLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              Wallet balance
            </span>
            <MyTooltip key={idPrefix + "wallet-balance"} target={idPrefix + "wallet-balance"} optionalOffset={15}>
              New wallet balance after withdrawing {tokenSymbol}.
            </MyTooltip>
            <span className={`portfolioLendBigLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              {props.isPositionToken && props.token.type === "short" && printableWalletBalance !== "0.00"
                ? "-" + formatValue(printableWalletBalance, 2)
                : formatValue(printableWalletBalance, 2) + " " + tokenSymbol}
            </span>
          </div>
          <div className="withdrawDepositDataInformationItem">
            <span id={idPrefix + "health"} className={`portfolioLendSmallLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              Account health
            </span>
            <MyTooltip key={idPrefix + "health"} target={idPrefix + "health"} optionalOffset={15}>
              New account health after withdrawing {tokenSymbol}. <br /> <br />
              Health is decreased after withdrawing funds.
            </MyTooltip>
            <span className={`portfolioLendBigLine ${healthColorClass}`}>{readableHealth}</span>
          </div>
        </div>
        <button id="withdrawButton" className="withdrawDepositButton" onClick={onWithdrawClickHandler} disabled={shouldBeDisabled} ref={withdrawButtonRef}>
          withdraw
        </button>
      </div>
      {tooltipIsVisible && (
        <MyTooltip2 target={tooltipTarget} setTooltipIsVisible={setTooltipIsVisible} setTooltipContent={setTooltipContent} setTooltipTarget={setTooltipTarget}>
          {tooltipContent}
        </MyTooltip2>
      )}
    </MyModal>
  );
};

WithdrawFundsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  token: PropTypes.object.isRequired,
  withdrawFunds: PropTypes.func.isRequired,
  isPositionToken: PropTypes.bool
};

export default WithdrawFundsModal;
