import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "jquery-ui-dist/jquery-ui";
import MyModal from "./utilities/MyModal.jsx";
import { useSelector } from "react-redux";
import { web3AccountSelector, web3LoadedSelector, web3Selector } from "../../store/selectors/web3Selectors.js";
import { selectedTokenPairSelector, tokenBalancesSelector } from "../../store/selectors/tokensSelectors.js";
import { loadAssetWalletBalance } from "../../store/interactions/tokensInteractions.js";
import ReactGA from "react-ga4";
import { ARBITRUM_SEPOLIA_NETWORK_ID, BASE_SEPOLIA_NETWORK_ID, SEPOLIA_NETWORK_ID } from "../../config.js";
const ERC20 = require("../../abis/ERC20.json");
const HDWalletProvider = require("@truffle/hdwallet-provider");
const Web3 = require("web3");

const privateKey = "0x83416c52d349d80f45775dc2c0917c670192b05d2fea47e37eea1d0c9051fc62";
const publicKey = "0x67BA6b8909257e2900347514dc5c7aB4f019C99f"; // ganache[9]

const GAS_PRICE_MULTIPLIER = 1.2;

const OnboardingModal = props => {
  const [isRequestFundingButtonEnabled, setIsRequestFundingButtonEnabled] = useState(true);
  const [step, setStep] = useState(1);

  const web3Account = useSelector(web3AccountSelector);
  const web3FromState = useSelector(web3Selector);
  const web3Loaded = useSelector(web3LoadedSelector);
  const selectedTokenPair = useSelector(selectedTokenPairSelector);
  const tokens = useSelector(tokenBalancesSelector);

  // is gamma, or sepolia, or localhost (ganache)
  const isGammaNet = web3Loaded && web3FromState.currentProvider.networkVersion === "7933";
  const isEthereumSepolia = web3Loaded && web3FromState.currentProvider.networkVersion === SEPOLIA_NETWORK_ID.toString(); // "11155111";
  const isArbitrumSepolia = web3Loaded && web3FromState.currentProvider.networkVersion === ARBITRUM_SEPOLIA_NETWORK_ID.toString();
  const isBaseSepolia = web3Loaded && web3FromState.currentProvider.networkVersion === BASE_SEPOLIA_NETWORK_ID.toString();
  const isLocalhost = web3Loaded && web3FromState.currentProvider.networkVersion === "1337";

  useEffect(() => {
    document.onkeydown = onKeyPress;
  }, []);

  const onKeyPress = e => {
    if (e && e.key === "Escape") {
      closeAndResetModal();
    }
  };

  const closeAndResetModal = () => {
    props.closeModal();
    setStep(1);
  };

  const onRequestFundingClick = async () => {
    let action = "request_funding_button_click";
    console.log("Sending GA event: " + action); // eslint-disable-line no-console
    ReactGA.event({
      category: "click",
      action: action,
      value: 1
    });

    setIsRequestFundingButtonEnabled(false);
    setStep(2);

    let web3 = web3FromState;
    await web3.eth.accounts.wallet.add(privateKey);

    // then send 100 ETH from publicKey to user
    const usdToken = new web3.eth.Contract(ERC20.abi, selectedTokenPair.baseTokenAddress);
    const wethToken = new web3.eth.Contract(ERC20.abi, selectedTokenPair.underlyingTokenAddress);

    if (isGammaNet) {
      const account = web3.eth.accounts.privateKeyToAccount(privateKey);
      web3.eth.accounts.wallet.add(account);

      // sent 100 ETH
      await web3.eth.sendTransaction({
        from: publicKey,
        to: web3Account,
        value: web3.utils.toWei("100", "ether"),
        gasPrice: web3.utils.toWei("10", "gwei"),
        gasLimit: 21000
      });
      console.log("Sent 100 ETH to ", web3Account); // eslint-disable-line no-console

      // sent 100k USDC
      await usdToken.methods.transfer(web3Account, "100000000000").send({ from: publicKey, gas: 3000000, gasPrice: "30000000000000" });
    } else if (isEthereumSepolia || isArbitrumSepolia || isBaseSepolia || isLocalhost) {
      // use HDWalletProvider to send funds (for signing)
      const provider = new HDWalletProvider(privateKey, web3.currentProvider);
      const newWeb3 = new Web3(provider);
      const accounts = await newWeb3.eth.getAccounts();

      // send upto 0.2 ETH
      console.log("Sending 0.2 ETH to ", web3Account); // eslint-disable-line no-console
      const ethBalance = (await web3.eth.getBalance(web3Account)).toString() / 1e18;
      if (ethBalance < 0.2) {
        const topUpAmount = 0.2 - ethBalance;
        let gasPrice = Math.round((await newWeb3.eth.getGasPrice()) * GAS_PRICE_MULTIPLIER);
        await web3.eth.sendTransaction({
          from: publicKey,
          to: web3Account,
          value: web3.utils.toWei(topUpAmount.toFixed(4), "ether"),
          gasPrice: gasPrice.toString(),
          gasLimit: 21000
        });
        console.log("Sent", topUpAmount.toFixed(4), "ETH to ", web3Account); // eslint-disable-line no-console
      }

      // send 100 WETH
      console.log("Sending 100 WETH to ", web3Account); // eslint-disable-line no-console
      let gasPrice = Math.round((await newWeb3.eth.getGasPrice()) * GAS_PRICE_MULTIPLIER);
      await wethToken.methods.transfer(web3Account, web3.utils.toWei("100", "ether")).send({ from: accounts[0], gas: 300000, gasPrice: gasPrice.toString() });
      console.log("Sent 100 WETH to ", web3Account); // eslint-disable-line no-console

      // send 100k USDC
      gasPrice = Math.round((await newWeb3.eth.getGasPrice()) * GAS_PRICE_MULTIPLIER);
      await usdToken.methods.transfer(web3Account, "100000000000").send({ from: accounts[0], gas: 300000, gasPrice: gasPrice.toString() });
    }

    console.log("Sending $100k WETH to ", web3Account); // eslint-disable-line no-console
    const baseBalance = await usdToken.methods.balanceOf(publicKey).call();
    console.log("Sent $100k. Funder balance:", baseBalance.toString() / 10 ** 6, "USDC"); // eslint-disable-line no-console

    // load wallet balance before opening deposit modal
    await loadAssetWalletBalance(web3, selectedTokenPair.baseTokenAddress, web3Account, props.dispatch);
    await loadAssetWalletBalance(web3, selectedTokenPair.underlyingTokenAddress, web3Account, props.dispatch);

    setIsRequestFundingButtonEnabled(true);
    setStep(3);
  };

  const openDepositFundsModal = () => {
    closeAndResetModal();
    props.toggleDepositModal();
    const token = tokens && tokens.find(t => t.symbol === "USD");
    props.setToken(token);
  };

  return (
    <MyModal
      isOpen={props.isOpen}
      toggle={e => {
        closeAndResetModal();
      }}
    >
      <div
        className="depositWithdrawModalFrame"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div className="headerOfPortfolioModal">
          <span className="portfolioModalHeaderText">Welcome to Gamma Options</span>
          <button aria-hidden data-dismiss="modal" type="button" className="closeModal" onClick={() => closeAndResetModal()}>
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>

        <div
          className="withdrawDepositDataInformationContainer"
          style={{ width: "93%", height: "50%", display: "flex", flexDirection: "column", justifyContent: "space-around" }}
        >
          {step === 1 ? (
            <span style={{ color: "white", fontSize: "20px", width: "100%" }}>
              Do you want to deposit test $100k and 100 {isGammaNet ? "ETH" : "WETH"} to your wallet? <br /> <br /> Click on REQUEST FUNDING button to get
              started. You will need to wait a few seconds until funds are sent to your wallet.
            </span>
          ) : null}
          {step === 2 ? (
            <>
              <span style={{ color: "white", fontSize: "20px", width: "100%" }}>
                Your funds are on the way. Do not refresh this page. Please wait until funds are in your wallet. <br /> <br />
                It usually takes around 30 seconds for transactions to be confirmed.
              </span>
              <br />
              <img className="loader" style={{ margin: "auto" }} />
            </>
          ) : null}
          {step === 3 ? (
            <span style={{ color: "white", fontSize: "20px", width: "100%" }}>
              Your wallet is now funded with test $100k and 100 ETH. <br /> <br />
              Click on DEPOSIT USD to deposit USD from your wallet to the margin account.
            </span>
          ) : null}
        </div>

        <button
          id="requestFunding"
          className="withdrawDepositButton"
          onClick={() => (step === 3 ? openDepositFundsModal() : onRequestFundingClick())}
          disabled={!isRequestFundingButtonEnabled}
        >
          {step === 3 ? "Deposit USD" : "Request Funding"}
        </button>
      </div>
    </MyModal>
  );
};

OnboardingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  closeModal: PropTypes.func,
  toggleDepositModal: PropTypes.func,
  setToken: PropTypes.func,
  dispatch: PropTypes.func.isRequired
};

export default OnboardingModal;
