import { get } from "lodash";
import { createSelector } from "reselect";
import moment from "moment";

const expirationTimesLoaded = state => get(state, "dateUtils.loaded");
export const expirationTimesLoadedSelector = createSelector(expirationTimesLoaded, loaded => loaded);

const expirationTimes = state => get(state, "dateUtils.expirationTimes.data");
export const expirationTimesSelector = createSelector(expirationTimes, data => {
  if (data) {
    return data.map(timestamp => {
      let sidebarFormat = moment(new Date(timestamp * 1000)).format("DD MMM YYYY");
      let sidebarFormatWithoutYear = moment(new Date(timestamp * 1000)).format("DD MMM");
      // let sidebarFormat = new Date(timestamp * 1000).toLocaleDateString("en-GB", {
      //   day: "numeric",
      //   month: "short",
      //   year: "numeric"
      // });
      // let sidebarFormatWithoutYear = new Date(timestamp * 1000).toLocaleDateString("en-GB", {
      //   day: "numeric",
      //   month: "short"
      // });
      // NOTE: for some reason, month is Sept not Sep,
      // check console on: https://jsfiddle.net/wo1ck5Ls/
      const sidebarFormatFixed = sidebarFormat.replace("Sept", "Sep");
      const sidebarFormatWithoutYearFixed = sidebarFormatWithoutYear.replace("Sept", "Sep");
      let parts = sidebarFormatFixed.split(" ");
      parts[2] = parts[2].substring(2);
      let urlFormat = parts.join("").toLowerCase();
      return {
        timestamp: timestamp.toString(),
        sidebarFormat: sidebarFormatFixed,
        sidebarFormatWithoutYear: sidebarFormatWithoutYearFixed.toUpperCase(),
        urlFormat: urlFormat
      };
    });
  }
  return [];
});

const selectedExpirationTime = state => get(state, "dateUtils.selectedExpirationTime", {});
export const selectedExpirationTimeSelector = createSelector(selectedExpirationTime, expirationTime => expirationTime);
