//this method is called in ArePositionLoaded
export const checkAllUnderlyingTokenPriceExist = trades => {
  //openedTradePositions/trades
  if (trades) {
    for (let pos of trades) {
      if (!pos.tokenPair.underlyingTokenPrice) {
        return false;
      }
    }
  }
  return true;
};

export const checkTradeLiqPositions = (tradePos, lpTradePos) => {
  if (tradePos) {
    for (let pos of tradePos) {
      if (!pos.tokenPair.underlyingTokenPrice || !pos.name || pos.name === "...") {
        return false;
      }
    }
  }
  if (lpTradePos) {
    for (let pos of lpTradePos) {
      // check if under price loaded, as well as second part of position (lower)
      if (!pos.tokenPair.underlyingTokenPrice || isNaN(pos.lower) || !pos.name || pos.name === "...") {
        return false;
      }
    }
  }
  return true;
};

export const checkTradesAndLPTrades = (trades, lpTrades) => {
  if (trades) {
    for (let trade of trades) {
      if (!trade.tokenPair.underlyingTokenPrice) {
        return false;
      }
    }
  }

  if (lpTrades) {
    for (let trade of lpTrades) {
      if (!trade.tokenPair.underlyingTokenPrice) {
        return false;
      }
    }
  }

  return true;
};
