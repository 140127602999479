import React, { useContext, useEffect, useState, useRef } from "react";
import { Card, CardBody, CardTitle, CardHeader } from "reactstrap";
import { Helmet } from "react-helmet";
import { FilterContext } from "../../../layouts/market/MainRouter.jsx";
import MyTooltip2 from "../MyTooltip2.jsx";
import { cursorRangeCheck } from "../../../utils/utils.js";

const UnderlyingChart = () => {
  const myRef = useRef(null);
  const chartWidgetOverlayRef = useRef(null);

  const [tooltipIsVisible, setTooltipIsVisible] = useState(false);
  const [tooltipTarget, setTooltipTarget] = useState("");
  const [tooltipContent, setTooltipContent] = useState("");

  const [chartWillRender, setChartWillRender] = useState(false);
  const { underlyingChartIsInFocus, setUnderlyingChartIsInFocus } = useContext(FilterContext);

  !localStorage.getItem("underChartDisplayed") && localStorage.setItem("underChartDisplayed", false);
  const [isUnderChartDisplayed, setIsUnderChartDisplayed] = useState(localStorage.getItem("underChartDisplayed") === "true");

  const handleShowHidePanel = () => {
    if (localStorage.getItem("underChartDisplayed") === "true") {
      localStorage.setItem("underChartDisplayed", false);
    } else if (localStorage.getItem("underChartDisplayed") === "false") {
      localStorage.setItem("underChartDisplayed", true);
    }
  };

  const useScript = url => {
    useEffect(() => {
      const script = document.createElement("script");

      script.src = url;
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }, [url]);
  };

  useEffect(() => {
    setTimeout(() => {
      setChartWillRender(true);
    }, 500);
  }, []);

  const onClickChartWidgetOverlay = e => {
    e.stopPropagation();
    setUnderlyingChartIsInFocus(true);
    if (chartWidgetOverlayRef.current) chartWidgetOverlayRef.current.classList.add("displayNone");
  };

  const onMouseEnterColumnHeader = (e, text) => {
    if (!tooltipIsVisible) {
      setTooltipIsVisible(true);
      setTooltipContent(text);
      setTooltipTarget(e.currentTarget.id);
    }
  };

  const onMouseLeaveColumnHeader = e => {
    if (!cursorRangeCheck(tooltipTarget, e.clientX, e.clientY)) {
      setTooltipIsVisible(false);
      setTooltipContent("");
      setTooltipTarget("");
      document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", -500 + "px");

      document.querySelector(":root").style.setProperty("--tooltipTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipLeftPosition", -500 + "px");
    }
  };

  return (
    <>
      <Card>
        <CardHeader
          className="handle"
          onDoubleClick={e => {
            e.stopPropagation();
            setIsUnderChartDisplayed(isUnderChartDisplayed => !isUnderChartDisplayed);
            handleShowHidePanel();
          }}
        >
          <CardTitle tag="h4">
            <div className="underlyingChartHeaderContainer">
              <span className="headerTitle">ETH/USD CHART</span>

              {isUnderChartDisplayed && (
                <>
                  <div
                    id="underChartArrowUp"
                    className="arrowHeaderWrapper"
                    onClick={e => {
                      e.stopPropagation();
                      setIsUnderChartDisplayed(false);
                      handleShowHidePanel();
                      setTooltipContent("Show chart panel");
                    }}
                    onMouseEnter={e => onMouseEnterColumnHeader(e, "Minimize chart panel")}
                    onMouseLeave={onMouseLeaveColumnHeader}
                  >
                    <img style={{ margin: "auto" }} className="arrowUpIcon" />
                  </div>
                </>
              )}

              {!isUnderChartDisplayed && (
                <>
                  <div
                    id="underChartArrowDown"
                    className="arrowHeaderWrapper"
                    onClick={e => {
                      e.stopPropagation();
                      setIsUnderChartDisplayed(true);
                      handleShowHidePanel();
                      setTooltipContent("Minimize chart panel");
                    }}
                    onMouseEnter={e => onMouseEnterColumnHeader(e, "Show chart panel")}
                    onMouseLeave={onMouseLeaveColumnHeader}
                  >
                    <img style={{ margin: "auto" }} className="arrowDownIcon" />
                  </div>
                </>
              )}
            </div>
          </CardTitle>
        </CardHeader>
        <CardBody id="underChartBody" className={isUnderChartDisplayed ? "" : "displayNone"}>
          <div
            className={`chartWidgetOverlay${underlyingChartIsInFocus ? " displayNone" : ""}`}
            ref={chartWidgetOverlayRef}
            onClick={onClickChartWidgetOverlay}
          ></div>
          <div className="tradingview-widget-container" ref={myRef}>
            {useScript("https://s3.tradingview.com/tv.js")}

            <div id="tradingview_f196f"></div>
            <div className="tradingview-widget-copyright">
              <a href="https://www.tradingview.com/symbols/ETHUSD/?exchange=COINBASE" rel="noopener noreferrer" target="_blank">
                <span className="blue-text">ETHUSD Chart</span>
              </a>{" "}
              by TradingView
            </div>

            {chartWillRender ? (
              <Helmet>
                <script type="text/javascript">
                  {`
    new TradingView.widget({
    "width": "auto",
    "height": 400,
    "symbol": "COINBASE:ETHUSD",
    "interval": "W",
    "timezone": "Etc/UTC",
    "theme": "dark",
    "style": "1",
    "locale": "en",
    "toolbar_bg": "#F1F3F6",
    "enable_publishing": false,
    "withdateranges": true,
    "allow_symbol_change": false,
    "container_id": "tradingview_f196f"})`}
                </script>
              </Helmet>
            ) : null}
          </div>
        </CardBody>
      </Card>
      {tooltipIsVisible && (
        <MyTooltip2
          key={tooltipTarget}
          target={tooltipTarget}
          setTooltipIsVisible={setTooltipIsVisible}
          setTooltipContent={setTooltipContent}
          setTooltipTarget={setTooltipTarget}
        >
          {tooltipContent}
        </MyTooltip2>
      )}
    </>
  );
};

export default UnderlyingChart;
