import { createSelector } from "reselect";
import {
  lpPositionTokens,
  lpPositionTokensLoaded,
  positionTokens,
  positionTokensLoaded,
  tokenPairs,
  tokenPairsLoaded,
  avgUnderlyingTokenPriceSelector
} from "./tokensSelectors";
import { formatUnits } from "@ethersproject/units";
import { lpTrades, lpTradesLoaded, trades, tradesLoaded, liquidationTrades, liquidationTradesLoaded } from "./positionsManagerSelectorsData";
import { marketsImmutableAttributes, marketsImmutableAttributesLoaded, marketsMutableAttributes, marketsMutableAttributesLoaded } from "./marketsSelectors";
import { web3accountLoaded, web3account } from "./web3Selectors";
import { getTokenPairsList } from "../utils/tokensUtils";
import {
  getAllLPTrades,
  getLiquidationTrades,
  getLongShortPositionsFromAccount,
  getLpPositionsFromAccount,
  getLpTrades,
  getOthersLpTradesGroupByAccount,
  getOthersTrades,
  getTrades
} from "../utils/positionsManagerUtils";
import { settlementData } from "./balancesSelectors";
import { getMarkets } from "../utils/marketsUtils";
import { getPositionHealth } from "../../utils/MarketHelper";
import { checkAllPositionTokensHasAccountBalance } from "../../utils/utils.js";

export const tradesLoadedSelector = createSelector(tradesLoaded, loaded => loaded);

export const lpTradesLoadedSelector = createSelector(lpTradesLoaded, loaded => loaded);

export const liquidationTradesLoadedSelector = createSelector(liquidationTradesLoaded, loaded => loaded);

export const tradesSelector = createSelector(
  [trades, tradesLoaded, marketsImmutableAttributes, tokenPairs, tokenPairsLoaded, positionTokens, positionTokensLoaded, web3accountLoaded, web3account],
  getTrades
);

export const allTradesSelector = createSelector(
  [trades, tradesLoaded, marketsImmutableAttributes, tokenPairs, tokenPairsLoaded, positionTokens, positionTokensLoaded, web3accountLoaded],
  getTrades
);

export const lpTradesSelector = createSelector(
  [
    lpTrades,
    lpTradesLoaded,
    marketsImmutableAttributes,
    tokenPairs,
    tokenPairsLoaded,
    lpPositionTokens,
    lpPositionTokensLoaded,
    web3accountLoaded,
    web3account
  ],
  getLpTrades
);

export const liquidationTradesSelector = createSelector([liquidationTrades, liquidationTradesLoaded, web3accountLoaded, web3account], getLiquidationTrades);

export const allLiquidationTradesSelector = createSelector(liquidationTrades, trades => trades);

export const allLPTradesSelector = createSelector(
  [lpTrades, lpTradesLoaded, marketsImmutableAttributes, tokenPairs, tokenPairsLoaded, lpPositionTokens, lpPositionTokensLoaded],
  getAllLPTrades
);

// TODO: v1 refactor the following 2 functions due to the code overlapping
export const openedTradePositionsSelector = createSelector(
  [
    positionTokens,
    trades,
    tradesLoaded,
    marketsImmutableAttributes,
    marketsImmutableAttributesLoaded,
    marketsMutableAttributes,
    marketsMutableAttributesLoaded,
    tokenPairs,
    tokenPairsLoaded,
    positionTokens,
    positionTokensLoaded,
    web3account,
    web3accountLoaded,
    settlementData
  ],
  (
    positionTokensData,
    trades,
    tradesLoaded,
    marketsImmutableAttributes,
    marketsImmutableAttributesLoaded,
    marketsMutableAttributes,
    marketsMutableAttributesLoaded,
    tokenPairs,
    tokenPairsLoaded,
    positionTokens,
    positionTokensLoaded,
    web3account,
    web3accountLoaded,
    settlementData
  ) => {
    let startTime = new Date().getTime();
    if (
      positionTokensData &&
      positionTokensData.length > 0 &&
      checkAllPositionTokensHasAccountBalance(positionTokensData) &&
      tradesLoaded &&
      tokenPairsLoaded &&
      web3accountLoaded
    ) {
      const marketsData = getMarkets(marketsMutableAttributes, marketsImmutableAttributes, marketsMutableAttributesLoaded, marketsImmutableAttributesLoaded);

      const tradesData = getTrades(
        trades,
        tradesLoaded,
        marketsImmutableAttributes,
        tokenPairs,
        tokenPairsLoaded,
        positionTokens,
        positionTokensLoaded,
        web3accountLoaded,
        web3account
      );

      if (tradesData && tradesData.length === 0) {
        return [];
      }

      const tokenPairsList = getTokenPairsList(tokenPairs);

      // only positions with some positive balance (> 1000000 / 10 ** 18)
      let longShortPositionTokensData = positionTokensData.filter(positionToken => Number(positionToken.accountBalance) > 1000000);
      const returnData = longShortPositionTokensData.map(positionToken => {
        // find market where position token is at
        let market = marketsData.find(market => {
          if (positionToken.type === "long") {
            return market.marketId === positionToken.tokenId;
          } else {
            return market.marketId === (parseInt(positionToken.tokenId) - 1).toString();
          }
        });
        // find token pair for underlying price
        let tokenPair = tokenPairsList.find(tokenPair => {
          return tokenPair.baseTokenAddress === market.baseTokenAddress && tokenPair.underlyingTokenAddress === market.underlyingTokenAddress;
        });
        // get all trades with current position token
        let sameTokenTrades = tradesData.filter(trade => {
          return trade.marketId === market.marketId && trade.type === positionToken.type;
        });
        // sort trades by timestamp
        sameTokenTrades.sort((a, b) => {
          return parseInt(a.timestamp) - parseInt(b.timestamp);
        });

        // calculate cumulative open price for current opened position
        let totalBaseCost = 0,
          increaseAmount = 0,
          increaseBaseCost = 0,
          reduceBaseCost = 0,
          reduceAmount = 0,
          totalCollCost = 0,
          totalTokens = 0,
          openTime = 10000000000;

        sameTokenTrades.forEach(trade => {
          // todo: v1 we need underPrice at the time of trade to calculate everything
          let tradeSize = trade.size * (trade.isBuy ? 1 : -1);
          let newTradeSizeTotal = totalTokens + tradeSize;
          if (newTradeSizeTotal * totalTokens <= 0 && totalTokens !== 0) {
            //console.log("reset position");
            if (newTradeSizeTotal === 0) {
              // reset everything to 0, if position is closed
              totalBaseCost = 0;
              totalCollCost = 0;
              increaseAmount = 0;
              reduceAmount = 0;
              increaseBaseCost = 0;
              reduceBaseCost = 0;
            } else {
              // calculate the rest of the trade, exclude closed part
              const openingTradeSize = Math.abs(newTradeSizeTotal - totalTokens);
              const reduceTradeSize = Math.abs(newTradeSizeTotal) < Math.abs(totalTokens);
              if (reduceTradeSize) {
                reduceBaseCost = openingTradeSize * trade.basePrice * (trade.isBuy ? 1 : -1);
                reduceAmount = openingTradeSize;
              } else {
                increaseBaseCost = openingTradeSize * trade.basePrice * (trade.isBuy ? 1 : -1);
                increaseAmount = openingTradeSize;
              }
              totalBaseCost = openingTradeSize * trade.basePrice * (trade.isBuy ? 1 : -1);
              totalCollCost = openingTradeSize * trade.collPrice * (trade.isBuy ? 1 : -1);
            }
          } else {
            //console.log("no reset");
            const reduceTradeSize = Math.abs(newTradeSizeTotal) < Math.abs(totalTokens);
            if (reduceTradeSize) {
              reduceBaseCost += trade.size * trade.basePrice * (trade.isBuy ? 1 : -1);
              reduceAmount += trade.size;
            } else {
              increaseBaseCost += trade.size * trade.basePrice * (trade.isBuy ? 1 : -1);
              increaseAmount += trade.size;
            }
            // trade don't reset position
            totalBaseCost += trade.size * trade.basePrice * (trade.isBuy ? 1 : -1);
            totalCollCost += trade.size * trade.collPrice * (trade.isBuy ? 1 : -1);
          }
          totalTokens = newTradeSizeTotal;

          // get opening time
          openTime = Math.min(openTime, parseInt(trade.timestamp));
        });

        if (reduceAmount > 0) {
          totalBaseCost -= reduceBaseCost + increaseBaseCost * (reduceAmount / increaseAmount);
        }

        const normalizedOpenLongPrice = parseFloat(market.openLongPrice.toString());
        const normalizedCloseLongPrice = parseFloat(market.closeLongPrice.toString());
        const normalizedOpenShortPrice = [parseFloat(market.openShortPrice[0].toString()), parseFloat(market.openShortPrice[1].toString())];
        const normalizedCloseShortPrice = [parseFloat(market.closeShortPrice[0].toString()), parseFloat(market.closeShortPrice[1].toString())];

        // isSettled
        const marketForPosition = marketsData.find(m => m.marketId === positionToken.marketId);
        const isSettled = marketForPosition.isSettled ? "true" : "false";

        return {
          name: positionToken.name,
          timestamp: sameTokenTrades.length > 0 ? sameTokenTrades[sameTokenTrades.length - 1].timestamp.toString() : 0,
          tokenId: positionToken.tokenId,
          marketId: positionToken.marketId,
          rawBalance: positionToken.accountBalance.toString(),
          balance: parseFloat(formatUnits(positionToken.accountBalance.toString(), 18)),
          rowBalance: positionToken.accountBalance,
          totalBaseCost: totalBaseCost,
          openBasePrice: totalBaseCost / totalTokens,
          openCollPrice: totalCollCost / totalTokens,
          midLongPrice: (normalizedOpenLongPrice + normalizedCloseLongPrice) / 2,
          midShortPrice: [(normalizedOpenShortPrice[0] + normalizedCloseShortPrice[0]) / 2, (normalizedOpenShortPrice[1] + normalizedCloseShortPrice[1]) / 2],
          closeLongPrice: normalizedCloseLongPrice,
          closeShortPrice: [normalizedCloseShortPrice[0], normalizedCloseShortPrice[1]],
          expirationTime: market.expirationTime,
          isCall: market.isCall,
          strikePrice: market.normalizedStrikePrice,
          tokenPair: tokenPair,
          type: positionToken.type,
          openTime: openTime.toString(),
          shortName: positionToken.shortName,
          isSettled
        };
      });

      let endTime = new Date().getTime();
      // console.log("Selector: openedTradePositionsSelector took ", endTime - startTime, "ms");

      return returnData;
    }
    // return null if not everything needed is loaded
    return null;
  }
);

export const openedOthersTradePositionsSelector = createSelector(
  [
    positionTokens,
    trades,
    tradesLoaded,
    marketsImmutableAttributes,
    marketsImmutableAttributesLoaded,
    marketsMutableAttributes,
    marketsMutableAttributesLoaded,
    tokenPairs,
    tokenPairsLoaded,
    positionTokens,
    positionTokensLoaded,
    web3account,
    web3accountLoaded,
    settlementData
  ],
  (
    positionTokensData,
    trades,
    tradesLoaded,
    marketsImmutableAttributes,
    marketsImmutableAttributesLoaded,
    marketsMutableAttributes,
    marketsMutableAttributesLoaded,
    tokenPairs,
    tokenPairsLoaded,
    positionTokens,
    positionTokensLoaded,
    web3account,
    web3accountLoaded,
    settlementData
  ) => {
    if (
      positionTokensData &&
      positionTokensData.length > 0 &&
      checkAllPositionTokensHasAccountBalance(positionTokensData) &&
      tradesLoaded &&
      marketsImmutableAttributesLoaded &&
      marketsMutableAttributesLoaded &&
      tokenPairsLoaded &&
      positionTokensLoaded &&
      web3accountLoaded
    ) {
      const marketsData = getMarkets(marketsMutableAttributes, marketsImmutableAttributes, marketsMutableAttributesLoaded, marketsImmutableAttributesLoaded);

      const othersLongShortTradesGroupByAccount = getOthersTrades(
        trades,
        tradesLoaded,
        marketsImmutableAttributes,
        tokenPairs,
        tokenPairsLoaded,
        positionTokens,
        positionTokensLoaded,
        web3accountLoaded,
        web3account
      );

      // positionTokensData.forEach(pt => console.log(pt.accountBalance));

      if (othersLongShortTradesGroupByAccount.size === 0) {
        // eslint-disable-next-line no-undef
        return new Map();
      }

      const tokenPairsList = getTokenPairsList(tokenPairs);

      // eslint-disable-next-line no-undef
      const allTradePositionsGroupByAccount = new Map();
      for (const [key, value] of othersLongShortTradesGroupByAccount.entries()) {
        allTradePositionsGroupByAccount.set(key, getLongShortPositionsFromAccount(positionTokens, value, marketsData, tokenPairsList));
      }
      return allTradePositionsGroupByAccount;
    }

    // eslint-disable-next-line no-undef
    return new Map();
  }
);

export const openedLPPositionsSelector = createSelector(
  [
    lpPositionTokens,
    lpTrades,
    lpTradesLoaded,
    marketsMutableAttributes,
    marketsMutableAttributesLoaded,
    marketsImmutableAttributes,
    marketsImmutableAttributesLoaded,
    tokenPairs,
    tokenPairsLoaded,
    lpPositionTokens,
    lpPositionTokensLoaded,
    web3account,
    web3accountLoaded,
    settlementData,
    avgUnderlyingTokenPriceSelector
  ],
  (
    lpPositionTokensData,
    lpTrades,
    lpTradesLoaded,
    marketsMutableAttributes,
    marketsMutableAttributesLoaded,
    marketsImmutableAttributes,
    marketsImmutableAttributesLoaded,
    tokenPairs,
    tokenPairsLoaded,
    lpPositionTokens,
    lpPositionTokensLoaded,
    web3account,
    web3accountLoaded,
    settlementData,
    underlyingTokenPrice
  ) => {
    let startTime = new Date().getTime();
    const tokenPairsList = getTokenPairsList(tokenPairs);
    const marketsData = getMarkets(marketsMutableAttributes, marketsImmutableAttributes, marketsMutableAttributesLoaded, marketsImmutableAttributesLoaded);

    const lpTradesData = getLpTrades(
      lpTrades,
      lpTradesLoaded,
      marketsData,
      tokenPairs,
      tokenPairsLoaded,
      lpPositionTokens,
      lpPositionTokensLoaded,
      web3accountLoaded,
      web3account
    );

    // return empty array if there are no trades
    if (lpTradesLoaded && lpTradesData && lpTradesData.length === 0) {
      return [];
    }

    if (
      lpPositionTokensData &&
      lpPositionTokensData.length > 0 &&
      lpTradesLoaded &&
      lpTradesData &&
      lpTradesData.length > 0 &&
      tokenPairsLoaded &&
      underlyingTokenPrice
    ) {
      // only positions with some positive balance (> 1000000 / 10 ** 18)
      lpPositionTokensData = lpPositionTokensData.filter(positionToken => Number(positionToken.accountBalance) > 1000000);
      // return empty array if there are trades, but final balance is 0
      if (lpPositionTokensData.length === 0) {
        return [];
      }

      const returnData = lpPositionTokensData.map(positionToken => {
        // find market where nft position token is at
        let market = marketsData.find(market => {
          return market.marketId === positionToken.marketId;
        });
        // find token pair for underlying price
        let tokenPair = tokenPairsList.find(tokenPair => {
          return tokenPair.baseTokenAddress === market.baseTokenAddress && tokenPair.underlyingTokenAddress === market.underlyingTokenAddress;
        });

        // get all trades with current position token
        let sameTokenTrades = lpTradesData.filter(trade => {
          return trade.marketId === market.marketId && trade.positionId.toString() === positionToken.positionId.toString();
        });

        // calculate cumulative open price for current opened position
        let totalBaseCost = 0,
          totalCollCost = 0,
          totalShortCost = 0,
          totalCollCostInBase = 0,
          totalShortCostInBase = 0,
          totalTokens = 0,
          totalOpenInBase = 0,
          openTime = 10000000000;

        sameTokenTrades.sort((a, b) => {
          return parseInt(b.timestamp) - parseInt(a.timestamp);
        });

        sameTokenTrades.forEach(trade => {
          totalBaseCost += trade.size * trade.basePrice * (trade.isBuy ? 1 : -1);
          totalCollCost += trade.size * trade.collPrice * (trade.isBuy ? 1 : -1);
          totalShortCost += trade.size * trade.shortPrice * (trade.isBuy ? 1 : -1);
          totalTokens += trade.size * (trade.isBuy ? 1 : -1);

          // use different calculation for increase/decrease
          if (trade.size === 0) {
            totalBaseCost += (trade.isBuy ? 1 : -1) * trade.basePrice;
          }

          // calculate cost in base for longs part
          const collCost = trade.size * trade.collPrice * (trade.isBuy ? 1 : -1);
          totalCollCostInBase += collCost * trade.longPriceInBase;

          // calculate cost in base for short part
          const shortCost = trade.size * trade.shortPrice * (trade.isBuy ? 1 : -1);
          totalShortCostInBase += shortCost * tokenPair.normalizedUnderlyingTokenPrice - trade.longPriceInBase; // todo: v1 use under price on open, must be from event

          // opening time
          openTime = Math.min(openTime, parseInt(trade.timestamp));
        });

        totalBaseCost -= market.penaltyBase;

        // old code
        const baseBalance = parseFloat(formatUnits(positionToken.baseBalance, 18));
        const feeBalance = 0;
        const normalizedLiqPrice = [
          (baseBalance + feeBalance) / totalTokens,
          parseFloat(formatUnits(positionToken.longBalance, 18)) / totalTokens,
          parseFloat(formatUnits(positionToken.shortBalance, 18)) / totalTokens
        ];
        const normalizedOpenLongPrice = parseFloat(market.openLongPrice.toString());
        const normalizedCloseLongPrice = parseFloat(market.closeLongPrice.toString());

        const balance = parseFloat(formatUnits(positionToken.accountBalance.toString(), 18));

        // health
        const lowerPriceInVol = 1.0001 ** positionToken.lower;
        const upperPriceInVol = 1.0001 ** positionToken.upper;
        const currentPriceInVol = parseFloat(market.longPriceInVol.toString());
        const health = getPositionHealth(
          market.marketHelper,
          Math.sqrt(lowerPriceInVol),
          Math.sqrt(upperPriceInVol),
          Math.sqrt(currentPriceInVol),
          balance,
          underlyingTokenPrice.normalized,
          baseBalance
        );

        return {
          // immutables
          positionId: positionToken.positionId.toString(),
          type: "lp",
          name: positionToken.name,
          timestamp: sameTokenTrades.length > 0 ? sameTokenTrades[sameTokenTrades.length - 1].timestamp.toString() : 0,
          shortName: positionToken.shortName,
          marketId: positionToken.marketId,
          expirationTime: market.expirationTime,
          isCall: market.isCall,
          strikePrice: market.normalizedStrikePrice,
          tokenPair: tokenPair,
          lower: positionToken.lower,
          upper: positionToken.upper,
          openTime: openTime.toString(),

          // now
          rawBalance: positionToken.accountBalance.toString(),
          balance: balance,
          balanceString: formatUnits(positionToken.accountBalance.toString(), 18),
          liqPrice: normalizedLiqPrice, // how much base, longs, and shorts is 1 liq token
          sizeInLongs: parseFloat(formatUnits(positionToken.sizeInLongs.toString(), 18)),
          midLongPrice: (normalizedOpenLongPrice + normalizedCloseLongPrice) / 2,
          feeBalance: parseFloat(formatUnits(positionToken.feeBalance.toString(), 18)),
          health: health,

          // open
          openLiqPrice: [totalBaseCost / totalTokens, totalShortCost / totalTokens, totalShortCost / totalTokens]
        };
      });

      console.log("Selector: openedLPPositionsSelector took ", new Date().getTime() - startTime, "ms");

      return returnData;
    }
    // return null if not everything needed is loaded
    return null;
  }
);

export const openedOthersLPPositionsSelector = createSelector(
  [
    lpPositionTokens,
    lpTrades,
    lpTradesLoaded,
    marketsMutableAttributes,
    marketsMutableAttributesLoaded,
    marketsImmutableAttributes,
    marketsImmutableAttributesLoaded,
    tokenPairs,
    tokenPairsLoaded,
    lpPositionTokens,
    lpPositionTokensLoaded,
    web3account,
    web3accountLoaded,
    settlementData
  ],
  (
    lpPositionTokensData,
    lpTrades,
    lpTradesLoaded,
    marketsMutableAttributes,
    marketsMutableAttributesLoaded,
    marketsImmutableAttributes,
    marketsImmutableAttributesLoaded,
    tokenPairs,
    tokenPairsLoaded,
    lpPositionTokens,
    lpPositionTokensLoaded,
    web3account,
    web3accountLoaded,
    settlementData
  ) => {
    let startTime = new Date().getTime();
    if (
      lpTradesLoaded &&
      marketsImmutableAttributesLoaded &&
      marketsMutableAttributesLoaded &&
      tokenPairsLoaded &&
      lpPositionTokensLoaded &&
      web3accountLoaded
    ) {
      const tokenPairsList = getTokenPairsList(tokenPairs);
      const marketsData = getMarkets(marketsMutableAttributes, marketsImmutableAttributes, marketsMutableAttributesLoaded, marketsImmutableAttributesLoaded);

      const othersLpTradesGroupByAccount = getOthersLpTradesGroupByAccount(
        lpTrades,
        lpTradesLoaded,
        marketsData,
        tokenPairs,
        tokenPairsLoaded,
        lpPositionTokens,
        lpPositionTokensLoaded,
        web3accountLoaded,
        web3account
      );

      if (lpTradesLoaded && othersLpTradesGroupByAccount && othersLpTradesGroupByAccount.size === 0) {
        // eslint-disable-next-line no-undef
        return { loaded: false, data: new Map() };
      }

      // eslint-disable-next-line no-undef
      const allLpPositionsGroupByAccount = new Map();
      let counter = 0;
      for (const [key, value] of othersLpTradesGroupByAccount.entries()) {
        counter += value.length;

        allLpPositionsGroupByAccount.set(key, getLpPositionsFromAccount(lpPositionTokensData, value, tokenPairsLoaded, marketsData, tokenPairsList));
      }

      console.log("Selector: openedOthersLPPositionsSelector took ", new Date().getTime() - startTime, "ms");

      return { loaded: true, data: allLpPositionsGroupByAccount };
    }

    // eslint-disable-next-line no-undef
    return { loaded: false, data: new Map() };
  }
);
