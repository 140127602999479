import { formatUnits } from "@ethersproject/units";

export const decorateStrikePrices = (strikePrice, baseTokenDecimals) => {
  if (strikePrice && baseTokenDecimals) {
    const normalizedStrikePrice = formatUnits(strikePrice, baseTokenDecimals);
    // if whole number, print without decimals, otherwise print with two decimals
    const printableFixedDecimals = parseFloat(normalizedStrikePrice) % 1 !== 0 ? 2 : 0;
    return {
      raw: strikePrice.toString(),
      normalized: normalizedStrikePrice,
      printable: parseFloat(normalizedStrikePrice)
        .toFixed(printableFixedDecimals)
        .toString()
    };
  } else {
    return null;
  }
};
