import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { web3AccountSelector, web3AccountLoadedSelector } from "../../store/selectors/web3Selectors";
import ConnectButton from "../BlueDexCustom/ConnectButton.jsx";
import UnderlyingTokenPriceTicker from "../BlueDexCustom/UnderlyingTokenPriceTicker.jsx";
import { selectTokenPair } from "../../store/interactions/tokensInteractions";
import { Navbar, Container, NavbarBrand, Nav, NavItem } from "reactstrap";
import MenuPanel from "../BlueDexCustom/MenuPanel.jsx";
import { tokenPairsListSelector, tokenPairsLoadedSelector, selectedTokenPairSelector } from "../../store/selectors/tokensSelectors";
import { displayHealthIcon, displayLoadingIconOld, formatNumber, healthClassName } from "../../utils/utils.js";
import TotalLiquidityLegend from "../BlueDexCustom/TotalLiquidityLegend.jsx";
import { marginAccountDataLoadedSelector, marginAccountDataSelector } from "../../store/selectors/marginSelectors";
import { useMatch, useNavigate } from "react-router-dom";
import { FilterContext } from "../../layouts/market/MainRouter.jsx";
import { newNotificationsSelector } from "../../store/selectors/newNotificationsSelector";
import NotificationsFrame from "../BlueDexCustom/NotificationsFrame.jsx";
import MyTooltip from "../BlueDexCustom/MyTooltip.jsx";
import { fromBn } from "evm-bn";

const DEFAULT_LOCATION = "localhost:3000/";

const BlueNavbar = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { notificationsFrameOpen, setNotificationsFrameOpen } = useContext(FilterContext);

  const [totalLiquidityLegendOpen, setTotalLiquidityLegendOpen] = useState(false);
  const [totalLiquidityLegendLeft, setTotalLiquidityLegendLeft] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [networkNameForEtherscan, setNetworkNameForEtherscan] = useState(null);

  const accountLoaded = useSelector(web3AccountLoadedSelector);
  const account = useSelector(web3AccountSelector);
  const tokenPairsLoaded = useSelector(tokenPairsLoadedSelector);
  const tokenPairsList = useSelector(tokenPairsListSelector);
  const marginAccountData = useSelector(marginAccountDataSelector);
  const marginAccountDataLoaded = useSelector(marginAccountDataLoadedSelector);
  const selectedTokenPair = useSelector(selectedTokenPairSelector);
  const notificationsFromReducer = useSelector(newNotificationsSelector);
  localStorage.setItem("networkName", networkNameForEtherscan);

  const totalLiquidityInfoRef = useRef();

  const match3 = useMatch("/:param1/:param2/:param3");
  const match1 = useMatch("/:param1");

  useEffect(() => {
    const localStorageNotifications = JSON.parse(localStorage.getItem("notifications"));
    if (localStorageNotifications != null && localStorageNotifications[account]) {
      setNotifications(localStorageNotifications[account]);
    }
  }, [notificationsFromReducer, account]);

  useEffect(() => {
    if (tokenPairsLoaded) {
      if (match3 && (match3.params.param1 === "trade" || match3.params.param1 === "pool")) {
        let urlPair = findTokenPair("eth-usd");
        if (urlPair != null) {
          // if url pair different than selected
          if (urlPair !== selectedTokenPair) {
            onTokenPairSelect(urlPair);
          }
        } else {
          // pair not found in tokenPairs, redirect to root
          navigate("/");
        }
      } else if (
        match1 &&
        (match1.params.param1 === "lend" ||
          match1.params.param1 === "portfolio" ||
          match1.params.param1 === "liquidations" ||
          match1.params.param1 === "insurance")
      ) {
        let defaultPair = findTokenPair("eth-usd");
        // if url is /account, and there is no selected pair
        // if (this.props.selectedTokenPair === undefined) {
        if (defaultPair != null) {
          // todo: v2 default pair should go to state
          onTokenPairSelect(defaultPair);
        }
      }
    }
  }, [tokenPairsLoaded, tokenPairsList]);

  const findTokenPair = pair => {
    const tokens = pair.split("-");
    if (tokens.length === 2) {
      return tokenPairsList.find(tokenPair => {
        return tokenPair.underlyingTokenSymbol === tokens[0].toUpperCase() && tokenPair.baseTokenSymbol === tokens[1].toUpperCase();
      });
    }

    return null;
  };

  const onTokenPairSelect = pair => {
    selectTokenPair(pair, dispatch);
  };

  const totalLiquidityMouseEnterHandler = e => {
    const { left } = e.currentTarget.getBoundingClientRect();
    setTotalLiquidityLegendOpen(true);
    setTotalLiquidityLegendLeft(left);
  };

  const totalLiquidityMouseLeaveHandler = e => {
    setTotalLiquidityLegendOpen(false);
  };

  let shortedNetLiq = displayLoadingIconOld();
  if (marginAccountDataLoaded) {
    const netLiquidity = marginAccountData.normalizedNetLiquidity;
    let formattedNetLiquidity = formatNumber(netLiquidity);
    if (formattedNetLiquidity.startsWith("-")) {
      shortedNetLiq = "-$" + formattedNetLiquidity.slice(1);
    } else {
      shortedNetLiq = "$" + formatNumber(netLiquidity);
    }
  }

  const health = marginAccountData ? marginAccountData.normalizedHealth : 0;
  const recoveryThreshold = marginAccountData ? parseFloat(fromBn(marginAccountData.recoveryThreshold)) * 100 : 0;
  const liquidationThreshold = marginAccountData ? parseFloat(fromBn(marginAccountData.liquidationThreshold)) * 100 : 0;
  const readableHealth = marginAccountData && marginAccountData.readableHealth;

  const healthColorClass = marginAccountDataLoaded ? healthClassName(readableHealth, health, recoveryThreshold, liquidationThreshold) : "";
  const healthIcon = displayHealthIcon(marginAccountData, readableHealth, health, recoveryThreshold, liquidationThreshold);

  const onBellClickHandler = e => {
    e.stopPropagation();
    setNotificationsFrameOpen(!notificationsFrameOpen);
  };

  const isYellowBell =
    notifications.length > 0 &&
    notifications.some(n => {
      if (n) {
        return n.status === "pending";
      }
      return false;
    });

  return (
    <FilterContext.Consumer>
      {({ closeAllFilterDropmenus }) => {
        return (
          <>
            {notificationsFrameOpen && (
              <NotificationsFrame setNotificationsFrameOpen={setNotificationsFrameOpen} notifications={notifications} networkName={networkNameForEtherscan} />
            )}
            {totalLiquidityLegendOpen && (
              <TotalLiquidityLegend
                key={"totalLiquidityLegendNavbar"}
                open={totalLiquidityLegendOpen}
                toggle={totalLiquidityMouseLeaveHandler}
                left={totalLiquidityLegendLeft}
                top={67}
              />
            )}
            <Navbar className="navbar sticky-top" expand="lg">
              <Container fluid>
                <div className="logoPriceTickerFlex">
                  <div className="navbar-wrapper">
                    <img className="logotype" alt="GAMMA OPTIONS" onClick={() => navigate(DEFAULT_LOCATION)} />
                  </div>
                  <UnderlyingTokenPriceTicker {...props} />

                  {accountLoaded ? (
                    <div
                      className="totalLiquidityInfo"
                      ref={totalLiquidityInfoRef}
                      onMouseEnter={totalLiquidityMouseEnterHandler}
                      onMouseLeave={totalLiquidityMouseLeaveHandler}
                    >
                      <div className="netLiqLabelValueContainer">
                        <span className="totalLiquidityText">NET LIQ</span>
                        <span className={`${healthColorClass}`}>{shortedNetLiq}</span>
                      </div>

                      <img className={`${healthIcon}`} />
                    </div>
                  ) : null}
                </div>

                <div className="navMenuConnectButtonFlex">
                  <Nav navbar>
                    <MenuPanel />
                  </Nav>
                  <Nav navbar>
                    <NavItem>
                      <ConnectButton dispatch={dispatch} setNotifications={setNotifications} setNetworkNameForEtherscan={setNetworkNameForEtherscan} />
                    </NavItem>
                  </Nav>
                  <img
                    id="notification-bell"
                    className={isYellowBell ? "notificationYellowBellGif" : "notificationGreenBell"}
                    onClick={e => {
                      closeAllFilterDropmenus();
                      onBellClickHandler(e);
                    }}
                  />
                  <MyTooltip key="notification-bell" target="notification-bell" optionalOffset={15}>
                    {!notificationsFrameOpen ? "Show notifications" : "Hide notifications"}
                  </MyTooltip>
                </div>
              </Container>
            </Navbar>
          </>
        );
      }}
    </FilterContext.Consumer>
  );
};

export default BlueNavbar;
