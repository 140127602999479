import React, { useCallback } from "react";
import { createPortal } from "react-dom";
import { Card, CardBody } from "reactstrap";

const NewMarketDropdownMenu = ({ containerType, searchParam, setSearchParam, data, closeDropmenu, top, left, width }) => {
  const filterData = data.filter(element => element !== searchParam);

  const onRowClickEventHandler = useCallback(
    e => {
      e.stopPropagation();
      const choosenValue = e.target.textContent.trim();
      setSearchParam(choosenValue);
      const select = document.getElementById(`${containerType}`);
      select.textContent = choosenValue;
      closeDropmenu();
    },
    [setSearchParam, closeDropmenu]
  );

  return createPortal(
    <div className={`${containerType}`} style={{ top: top, left: left, width: width }}>
      <Card>
        <CardBody>
          <table className="table">
            <tbody>
              {filterData.map(element => {
                return (
                  <tr key={element} onClick={onRowClickEventHandler}>
                    <td className="singleOption">{element}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>,
    document.getElementById("root")
  );
};

export default NewMarketDropdownMenu;
