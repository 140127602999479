/* eslint-disable no-undef */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createPortal } from "react-dom";
import { FilterContext } from "../../layouts/market/MainRouter.jsx";
import DropdownMenuExpTimeHistorySelectionContainer from "./DropdownMenuExpTimeHistorySelectionContainer.jsx";

class FiltersHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      top: "0",
      left: "0",
      width: "0"
    };
  }

  dropmenuFilterOpenHandler = (e, dropmenuType, dropmenuOpened, setDropmenuOpened, closeAllFilterDropmenus, itemsLength) => {
    const img = e.currentTarget.querySelector("img");
    closeAllFilterDropmenus(img);
    img.classList.toggle("transformArrow");

    const newDropmenuOpened = !dropmenuOpened;

    if (newDropmenuOpened) {
      let { top, left, width, bottom } = e.currentTarget.getBoundingClientRect();

      bottom + 45 * Math.min(itemsLength, 5) > window.innerHeight ? (top -= 45 * Math.min(itemsLength, 5)) : (top += 30);

      this.setState({
        top: top + "px",
        left: left + "px",
        width: width + "px"
      });
      document.body.classList.add("scrollDisabled");
    } else {
      document.body.classList.remove("scrollDisabled");
    }
    setDropmenuOpened(dropmenuType, newDropmenuOpened);
  };

  render() {
    if (!this.props.parent) return null;

    const filterParamsAreDefault = this.props.searchParamsAreDefault;

    return createPortal(
      <FilterContext.Consumer>
        {({ expirationTimesHistoryDropmenuOpened, setDropmenuOpened, closeAllFilterDropmenus }) => {
          const isTradePage = window.location.pathname.includes("trade");

          let dropdownMenuFilterContainerCloseDropmenu = () => {};

          let dropdownMenuMultipleSelectionFirstOptionValue = "";
          let dropdownMenuMultipleSelectionSecondOptionValue = "";
          let dropdownMenuMultipleSelectionThirdOptionValue = "";
          let dropdownMenuMultipleSelectionContainerType = "";
          let dropdownMenuMultipleSelectionData = new Map();
          let dropdownMenuMultipleSelectionSetData = () => {};
          let dropdownMenuMultipleSelectionSetSearchParam = () => {};

          if (expirationTimesHistoryDropmenuOpened) {
            dropdownMenuMultipleSelectionFirstOptionValue = "All Expirations";
            dropdownMenuMultipleSelectionSecondOptionValue = "Last expiration";
            dropdownMenuMultipleSelectionThirdOptionValue = "Liquidations";
            dropdownMenuMultipleSelectionContainerType = "expirationHistoryContainer";
            dropdownMenuMultipleSelectionData = this.props.expirationTimesSelected;
            dropdownMenuMultipleSelectionSetData = this.props.setExpirationTimesSelected;
            dropdownMenuMultipleSelectionSetSearchParam = this.props.setExpirationTimesSearchParam;
          }

          return (
            <>
              <div className="searchFilterPositionsContainer">
                <div
                  key="expirationHistoryContainer"
                  className="bottomSelect -expirationHistoryContainer"
                  onClick={e => {
                    e.stopPropagation();
                    this.dropmenuFilterOpenHandler(
                      e,
                      "expirationTimesHistoryDropmenuOpened",
                      expirationTimesHistoryDropmenuOpened,
                      setDropmenuOpened,
                      closeAllFilterDropmenus,
                      this.props.expirationTimesSelected ? this.props.expirationTimesSelected.size : 0
                    );
                  }}
                >
                  <span>{this.props.expirationTimesSearchParam}</span>
                  <img />
                </div>
              </div>
              {expirationTimesHistoryDropmenuOpened && (
                <DropdownMenuExpTimeHistorySelectionContainer
                  key={dropdownMenuMultipleSelectionContainerType}
                  firstOptionValue={dropdownMenuMultipleSelectionFirstOptionValue}
                  secondOptionValue={dropdownMenuMultipleSelectionSecondOptionValue}
                  thirdOptionValue={dropdownMenuMultipleSelectionThirdOptionValue}
                  containerType={dropdownMenuMultipleSelectionContainerType}
                  data={dropdownMenuMultipleSelectionData}
                  setData={dropdownMenuMultipleSelectionSetData}
                  setSelectValue={dropdownMenuMultipleSelectionSetSearchParam}
                  top={this.state.top}
                  left={this.state.left}
                  width={this.state.width}
                  closeDropmenu={dropdownMenuFilterContainerCloseDropmenu}
                />
              )}
            </>
          );
        }}
      </FilterContext.Consumer>,
      this.props.parent.querySelector(".pagination-bottom")
    );
  }
}

FiltersHistory.propTypes = {
  parent: PropTypes.object,
  strikesSelected: PropTypes.object,
  resetFiltersToDefault: PropTypes.func,
  strikesSearchParam: PropTypes.string,
  expirationTimesSearchParam: PropTypes.string,
  expirationTimesSelected: PropTypes.object,
  searchCallPutParam: PropTypes.string,
  searchLongShortLpParam: PropTypes.string,
  longsShortsLpsSelected: PropTypes.object,
  setExpirationTimesSearchParam: PropTypes.func,
  setLongsShortsLpsSelected: PropTypes.func,
  setSearchCallPutParamState: PropTypes.func,
  setSearchLongShortLpParamState: PropTypes.func,
  searchParamsAreDefault: PropTypes.func,
  setStrikesSelected: PropTypes.func,
  setStrikesSearchParam: PropTypes.func,
  setExpirationTimesSelected: PropTypes.func
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(FiltersHistory);
