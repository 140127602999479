import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Form, Input, FormGroup } from "reactstrap";
import {
  defaultInputValue,
  formatValue,
  inputDecimals,
  isHex,
  isPositiveNumber,
  isSmallDifference,
  removeCommaLocales,
  replaceCharacter
} from "../../utils/utils.js";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import MyModal from "./utilities/MyModal.jsx";
import { fromBn, toBn } from "evm-bn";
import ReactGA from "react-ga4";
import MyTooltip from "./MyTooltip.jsx";
import { useDispatch, useSelector } from "react-redux";
import { insuranceFundLoadedSelector, insurerBalanceWithUnclaimedSelector } from "../../store/selectors/insuranceFundSelector.js";
import { insuranceFundSelector } from "../../store/selectors/contractsSelectors.js";
import { depositToken } from "../../store/interactions/insuranceFundInteractions.js";
import { web3AccountSelector, web3Selector } from "../../store/selectors/web3Selectors.js";

let lastSelectedInputTimestamp = null;
let lastClickedModalOverlay = null;

const DepositInsuranceFundsModal = props => {
  const dispatch = useDispatch();

  const [inputClass, setInputClass] = useState("has-success");
  const [depositInputValue, setDepositInputValue] = useState(() => {
    return props.token.normalizedWalletBalance2Decimals.toFixed(2);
  });
  const [depositAmount, setDepositAmount] = useState(() => {
    return fromBn(props.token.walletBalance18Decimals);
  });
  const [depositInputErrorMessage, setDepositInputErrorMessage] = useState("");
  const [step, setStep] = useState(null);

  const web3 = useSelector(web3Selector);
  const web3Account = useSelector(web3AccountSelector);
  const insuranceFundLoaded = useSelector(insuranceFundLoadedSelector);
  const insurerBalanceWithUnclaimed = useSelector(insurerBalanceWithUnclaimedSelector);
  const insuranceFundContract = useSelector(insuranceFundSelector);

  const depositInputValueRef = useRef(null);
  const depositButtonRef = useRef(null);
  const idPrefix = "deposit-insurance-funds-modal-";

  useEffect(() => {
    const modalDialog = document.querySelector(".modal-dialog");

    if (modalDialog) {
      $(".modal-black").draggable({
        handle: ".modal-header"
      });
    }

    depositInputValueRef.current.addEventListener("paste", paste);
    depositInputValueRef.current.select();

    const chunk = "deposit-insurance-funds-modal";
    console.log("Navigate to: " + window.location.pathname + "/" + chunk); // eslint-disable-line no-console
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + "/" + chunk, title: "Deposit Insurance Funds Modal" });

    return () => {
      document.onkeydown = null;
    };
  }, []);

  useEffect(() => {
    document.onkeydown = onKeyPress;
  }, [depositInputValue]);

  const paste = e => {
    e.stopPropagation();
    e.preventDefault();

    const selection = window.getSelection().toString();

    const clipboardData = e.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData("Text");
    pastedData = removeCommaLocales(pastedData);
    let newPastedData;
    if (selection !== "") {
      newPastedData = inputDecimals(
        replaceCharacter(
          depositInputValueRef.current.value,
          depositInputValueRef.current.selectionStart,
          depositInputValueRef.current.selectionEnd - 1,
          pastedData
        ),
        props.token.symbol
      );
    } else {
      newPastedData = inputDecimals(depositInputValueRef.current.value + pastedData, props.token.symbol);
    }
    if (newPastedData !== undefined && newPastedData !== null && !isHex(newPastedData)) {
      if (selection !== "") {
        depositInputValueRef.current.value = replaceCharacter(
          depositInputValueRef.current.value,
          depositInputValueRef.current.selectionStart,
          depositInputValueRef.current.selectionEnd - 1,
          pastedData
        );
      } else {
        depositInputValueRef.current.value = depositInputValueRef.current.value + pastedData;
      }
    }
    onInputChange();
  };

  const onInputChange = () => {
    const inputString = depositInputValueRef.current.value.trim();

    const value = inputDecimals(inputString, props.token.symbol);
    if (value === undefined || value === null) {
      return;
    }
    setDepositInputValue(inputString);

    if (value === "") {
      setInputClass("");
      return;
    }

    if (!isPositiveNumber(value)) {
      setInputClass("has-danger");
      setDepositInputErrorMessage("Enter a positive number");
      return;
    }

    if (
      Number(value) > Number(props.token.normalizedWalletBalance) &&
      !isSmallDifference(Number(value), props.token.normalizedWalletBalance, props.token.symbol)
    ) {
      setInputClass("has-danger");
      setDepositInputErrorMessage(
        "Can't deposit more than " + formatValue(props.token.normalizedWalletBalance, 2) + ". Click max button to withdraw maximum allowed amount."
      );
      return;
    }

    let amountSplit = value.split(".");
    const newAmountSplit = amountSplit.length === 1 || amountSplit[1] === "" ? amountSplit[0] : amountSplit[0] + "." + amountSplit[1];
    let newAmount = fromBn(toBn(newAmountSplit.toString(), 18));
    const maxDepositAmount = fromBn(props.token.walletBalance18Decimals);
    if (isSmallDifference(maxDepositAmount, newAmount, props.token.symbol)) {
      setDepositAmount(maxDepositAmount);
    } else {
      setDepositAmount(newAmount);
    }
    setInputClass("has-success");
  };

  const increaseDepositAmount = () => {
    if (depositInputValue === "") {
      setDepositInputValue(1);
      onInputChange();
      return;
    }

    if (Math.abs(depositInputValue - props.token.normalizedWalletBalance) >= 1) {
      //if it is not max value
      if (depositInputValueRef.current.value.split(".").length > 1) {
        setDepositInputValue(Math.ceil(Number(depositInputValue)));
        depositInputValueRef.current.value = Math.ceil(Number(depositInputValue));
        onInputChange();
      } else {
        setDepositInputValue(depositInputValue + 1);
        depositInputValueRef.current.value = Number(depositInputValue) + 1;
        onInputChange();
      }
    }
  };

  const decreaseDepositAmount = () => {
    if (depositInputValue !== "" && depositInputValue != 1 && depositInputValue != 0) {
      if (depositInputValueRef.current.value.split(".").length > 1) {
        setDepositInputValue(Math.floor(Number(depositInputValue)));
        depositInputValueRef.current.value = Math.floor(Number(depositInputValue));
        onInputChange();
      } else {
        setDepositInputValue(Number(depositInputValue) - 1);
        depositInputValueRef.current.value = Number(depositInputValue) - 1;
        onInputChange();
      }
    }
  };

  const clearInput = () => {
    setInputClass("");
    setDepositInputValue("");
    depositInputValueRef.current.focus();
  };

  const onMaxButtonClicked = () => {
    const maxDepositAmount = fromBn(props.token.walletBalance18Decimals);

    const maxDepositInputValue = props.token.normalizedWalletBalance2Decimals.toFixed(2);
    setDepositInputValue(maxDepositInputValue);
    setDepositAmount(maxDepositAmount);
    setInputClass("has-success");
    setDepositInputErrorMessage("");
  };

  const onKeyPress = e => {
    if (e && e.key === "Escape") {
      props.toggle();
    }
  };

  const calculateInsuranceFundsBalance = value => {
    const amount = insuranceFundLoaded && insurerBalanceWithUnclaimed;
    const newAmount = Number(amount) + Number(value);

    return newAmount;
  };

  const calculateWalletBalance = (value, shouldSetStates) => {
    let amount = props.token && fromBn(props.token.walletBalance18Decimals);
    let newAmount = Number(amount) - Number(value);
    if (newAmount > 0) {
      amount = newAmount;
    } else if (isSmallDifference(Number(amount), Number(value), props.token.symbol)) {
      amount = 0;
    } else {
      if (inputClass !== "has-danger" && shouldSetStates) {
        setInputClass("has-danger");
        setDepositInputErrorMessage("You don't have enough funds.  Click max button to deposit maximum allowed amount.");
      }
      amount = 0;
    }

    return Number(amount);
  };

  const onDepositClickHandler = async e => {
    e.stopPropagation();
    const assetAddress = props.token.address;
    depositToken(insuranceFundContract, web3Account, depositAmount, web3, assetAddress, props.toggle, setStep, dispatch);
  };

  let printableInsuranceFundBalance;
  let printableWalletBalance;
  const max = props.token.normalizedWalletBalance;
  if (depositInputValue && inputClass !== "has-danger") {
    printableInsuranceFundBalance = calculateInsuranceFundsBalance(depositInputValue);
    printableWalletBalance = calculateWalletBalance(depositInputValue, true);
  } else if (depositInputValue === "") {
    calculateInsuranceFundsBalance(depositInputValue);
    calculateWalletBalance(depositInputValue, true);
    printableInsuranceFundBalance = calculateInsuranceFundsBalance(String(defaultInputValue));
    printableWalletBalance = calculateWalletBalance(String(defaultInputValue), false);
  } else if (inputClass === "has-danger") {
    calculateInsuranceFundsBalance(depositInputValue);
    calculateWalletBalance(depositInputValue, true);
    printableInsuranceFundBalance = calculateInsuranceFundsBalance(String(max));
    printableWalletBalance = calculateWalletBalance(String(max), false);
  } else {
    printableInsuranceFundBalance = props.token.printableAccountBalance;
    printableWalletBalance = props.token.printableWalletBalance;
  }

  const shouldBeDisabled = depositInputValue === "" || inputClass === "has-danger";

  return (
    <MyModal
      isOpen={props.isOpen}
      toggle={e => {
        lastClickedModalOverlay = Date.now();
        const diff = lastClickedModalOverlay - lastSelectedInputTimestamp;
        if (diff < 500) {
          lastClickedModalOverlay = null;
          lastSelectedInputTimestamp = null;
          return;
        }
        props.toggle();
      }}
    >
      <div
        className="depositWithdrawModalFrame"
        onClick={e => {
          e.stopPropagation();
        }}
        onMouseDown={e => {
          lastSelectedInputTimestamp = Date.now();
        }}
      >
        <div className="headerOfPortfolioModal">
          <span className="portfolioModalHeaderText">Deposit USDC</span>
          <button aria-hidden data-dismiss="modal" type="button" className="closeModal" onClick={() => props.toggle(true)}>
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>

        <div className="inputAmountAndSettingsErrorMessageContainer">
          <div className="inputAmountAndSettingsContainer">
            <Form>
              <FormGroup>
                <Input
                  id="depositModalInput"
                  type="text"
                  autoComplete="off"
                  placeholder="Deposit amount, decimals allowed... (e.g., 100.50)"
                  className={`inputText${inputClass === "has-danger" ? " inputDanger" : ""}${inputClass === "has-success" ? " inputSuccess" : ""}${
                    inputClass === "has-warning" ? " inputWarning" : ""
                  }`}
                  onChange={e => {
                    onInputChange();
                  }}
                  value={depositInputValue}
                  autoFocus
                  innerRef={depositInputValueRef}
                />
                {inputClass === "has-danger" ? <img className="clearInputError" onClick={clearInput} /> : null}
                {inputClass === "has-success" ? <img className="validInput" /> : null}
                {inputClass === "has-warning" ? <img className="warningInput" /> : null}
                <div className="increaseDecreaseInputButtons">
                  <img className="increaseInputButton" onClick={increaseDepositAmount} />
                  <img className="decreaseInputButton" onClick={decreaseDepositAmount} />
                </div>
              </FormGroup>
            </Form>
            <button id="depositModalMaxButton" className="maxButton" onClick={onMaxButtonClicked}>
              max
            </button>
            <MyTooltip key={"depositModalMaxButton"} target={"depositModalMaxButton"}>
              Max amount to deposit into account.
            </MyTooltip>
          </div>
          <div className="errorMessageDepositWithdrawLendContainer">
            <span className={inputClass === "has-warning" ? "warningMessageDepositWithdrawLendSpan" : "errorMessageDepositWithdrawLendSpan"}>
              {inputClass === "has-danger" || inputClass === "has-warning" ? depositInputErrorMessage : ""}
            </span>
          </div>
        </div>

        <div className="withdrawDepositDataInformationContainer">
          <div className="withdrawDepositDataInformationItem">
            <span id={idPrefix + "insurance-funds-balance"} className={`portfolioLendSmallLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              Insurance fund balance
            </span>
            <MyTooltip key={idPrefix + "insurance-funds-balance"} target={idPrefix + "insurance-funds-balance"} optionalOffset={15}>
              New insurance fund balance after depositing USDC.
            </MyTooltip>
            <span className={`portfolioLendBigLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              {props.isPositionToken && props.token.type === "short" && printableInsuranceFundBalance !== "0.00"
                ? "-" + formatValue(printableInsuranceFundBalance, 2)
                : formatValue(printableInsuranceFundBalance, 2) + " " + "USDC"}
            </span>
          </div>
          <div className="withdrawDepositDataInformationItem">
            <span id={idPrefix + "wallet-balance"} className={`portfolioLendSmallLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              Wallet balance
            </span>
            <MyTooltip key={idPrefix + "wallet-balance"} target={idPrefix + "wallet-balance"} optionalOffset={15}>
              New wallet balance after depositing USDC.
            </MyTooltip>
            <span className={`portfolioLendBigLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              {props.isPositionToken && props.token.type === "short" && printableWalletBalance !== "0.00"
                ? "-" + formatValue(printableWalletBalance, 2)
                : formatValue(printableWalletBalance, 2) + " " + "USDC"}
            </span>
          </div>
          {step === 1 && (
            <div className="withdrawDepositDataInformationItem">
              <span className="portfolioLendBigLine">Approval Required (step 1 of 2)</span>
            </div>
          )}
          {step === 2 && (
            <div className="withdrawDepositDataInformationItem">
              <span className="approvingDepositMessage">Approving deposit</span>
              <img className="approvingRequest" />
            </div>
          )}
          {step === 3 && (
            <div className="withdrawDepositDataInformationItem">
              <span className="portfolioLendBigLine">Transaction Confirmation Required (step 2 of 2)</span>
            </div>
          )}
        </div>

        <button id="depositButton" className="withdrawDepositButton" onClick={onDepositClickHandler} disabled={shouldBeDisabled || step} ref={depositButtonRef}>
          deposit
        </button>
      </div>
    </MyModal>
  );
};

DepositInsuranceFundsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  token: PropTypes.object.isRequired,
  isPositionToken: PropTypes.bool,
  insuranceFundLoaded: PropTypes.bool,
  insuranceFundWithUnclaimed: PropTypes.number,
  insurerBalanceWithUnclaimed: PropTypes.number,
  insuranceFundContract: PropTypes.object,
  userBalancesVault: PropTypes.object
};

export default DepositInsuranceFundsModal;
