import { addNotification, removeNotification, removeNotifications } from "../actions/actions.js";
import { v4 as uuidv4 } from "uuid";

export const handleNewNotification = async (message, severity, dispatch) => {
  try {
    dispatch(addNotification({ id: uuidv4(), message: message, severity: severity }));
  } catch (e) {
    console.log(e);
  }
};

export const handleNotificationRemoval = async (notificationId, dispatch) => {
  dispatch(removeNotification(notificationId));
};

export const clearNotifications = dispatch => {
  dispatch(removeNotifications());
};
