/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
import { formatUnits } from "@ethersproject/units";
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactTable from "react-table";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { FilterContext } from "../../layouts/market/MainRouter.jsx";
import { marketsLoadedSelector, marketsSelector } from "../../store/selectors/marketsSelectors";
import { openedTradePositionsSelector } from "../../store/selectors/positionsManagerSelectors";
import { selectedTokenPairSelector, avgUnderlyingTokenPriceSelector } from "../../store/selectors/tokensSelectors";
import { currentTimestampSelector, web3AccountLoadedSelector } from "../../store/selectors/web3Selectors";
import {
  calculateMarketDepth,
  cursorRangeCheck,
  formatValue,
  getUnderlyingPriceAtExpiredStr,
  limitValue,
  printPriceFromString,
  printPriceFromNumber,
  toMonthName
} from "../../utils/utils.js";
import CogPositionsModal from "./CogPositionsModal.jsx";
import ConnectPanel from "./ConnectPanel.jsx";
import FiltersPositions from "./FiltersPositions.jsx";
import PositionModal from "./PositionModal.jsx";
import TradeModal from "./TradeModal.jsx";
import { checkAllUnderlyingTokenPriceExist } from "./utilities/TradePanels/CheckUtility";
import {
  getPNLFooter,
  getROIFooter,
  getValueFooter,
  getDeltaFooter,
  getGammaFooter,
  getThetaFooter,
  getVegaFooter,
  getAPRFooter,
  getAPYFooter
} from "./utilities/TradePanels/FooterUtility";
import { printLoadingIcon } from "./utilities/TradePanels/PrintUtility.jsx";
import { getChartPoint, getHighestDaysToExpiryRounded } from "../../utils/chartsUtils";
import MyTooltip from "./MyTooltip.jsx";
import MyTooltip2 from "./MyTooltip2.jsx";
import { settlementDataSelector } from "../../store/selectors/balancesSelectors";
import { marginPoolDataLoadedSelector, marginPoolDataSelector } from "../../store/selectors/marginSelectors.js";

const EMPTY_TABLE_TEXT = "No trade positions currently open in your account";
const CARD_TITLE = "TRADE POSITIONS";

export const callPutsSearchParams = ["Options & Futures", "Calls", "Puts", "Futures"];
export const longShortsSearchParams = ["Longs & Shorts", "Longs", "Shorts"];

const TOOLTIPS_TEXT = {
  myPositionToken: <>Name of the position token.</>,
  myPositionsOpened: "Time passed since the opening.",
  myPositionsTimeToExp: "Time to expiration.",
  myPositionsSize: (
    <>
      Current size of the position in options. <br /> <br /> Positive quantity means position is long option, negative quantity means position is short option.
    </>
  ),
  myPositionsOpen: (
    <>
      The average price of the option on the opening. <br /> <br /> If there were multiple trades that created this position, then open price is average option
      price at the time of trading.
    </>
  ),
  myPositionsMark: (
    <>
      Current mid-point option price on the market. <br /> <br /> Used for P/L, ROI, APR, APY, as well as Greeks calculations.
    </>
  ),
  myPositionsCollateral: "The total collateral locked for opening of the position.",
  myPositionsValue: (
    <>
      Total premium paid for opening position, positive if long, negative if short position. <br /> <br />
      Formula used: <br />
      PREMIUM = QTY x OPEN <br /> <br />
      The total row shows Net Option Premium which is the sum of all premiums paid and received.
    </>
  ),
  myPositionsPnl: (
    <>
      Profit/Loss (P/L) of the position since the opening. <br /> <br />
      Formula used: <br />
      P/L = QTY x (MARK - OPEN)
    </>
  ),
  myPositionsRoi: "Return on investment (ROI) of the position since the opening.",
  myPositionsApr: "Annual percentage rate (APR) of the position since the opening.",
  myPositionsApy: "Annual percentage yield (APY) of the position since the opening.",
  myPositionsDelta: <>Delta (&Delta;) of the position represents the rate of change of the position's price relative to underlying price change.</>,
  myPositionsGamma: <>Gamma (&gamma;) of the position represents the rate of change of the Delta (&Delta;) relative to underlying price change.</>,
  myPositionsTheta: (
    <>Theta (&Theta;) of the position represents the rate of change of the position's price relative to time change, also known as time decay.</>
  ),
  myPositionsVega: <>Vega (V) of the position represents the rate of change of the position's price relative to implied volatility (IV) change.</>
};

let closeAllFilterDropmenusRef = null;

const TradePositionsPanel = () => {
  !localStorage.getItem("isTppDisplayed") && localStorage.setItem("isTppDisplayed", true);

  !localStorage.getItem("timeToExpTrade") && localStorage.setItem("timeToExpTrade", false);
  !localStorage.getItem("createdAtTrade") && localStorage.setItem("createdAtTrade", true);

  !localStorage.getItem("pnlTrade") && localStorage.setItem("pnlTrade", true);
  !localStorage.getItem("roiTrade") && localStorage.setItem("roiTrade", false);
  !localStorage.getItem("aprTrade") && localStorage.setItem("aprTrade", false);
  !localStorage.getItem("apyTrade") && localStorage.setItem("apyTrade", false);

  !localStorage.getItem("totalGammaTrade") && localStorage.setItem("totalGammaTrade", false);
  !localStorage.getItem("totalVegaTrade") && localStorage.setItem("totalVegaTrade", false);
  !localStorage.getItem("totalThetaTrade") && localStorage.setItem("totalThetaTrade", false);
  !localStorage.getItem("totalDeltaTrade") && localStorage.setItem("totalDeltaTrade", true);

  const [isTradingModalOpen, setiIsTradingModalOpen] = useState(false);
  const [selectedPositionMarket, setSelectedPositionMarket] = useState(null);

  const [searchCallPutParam, setSearchCallPutParam] = useState("Options & Futures");
  const [searchLongShortParam, setSearchLongShortParam] = useState("Longs & Shorts");
  const [expirationTimesSearchParam, setExpirationTimesSearchParam] = useState("All Expirations");
  const [strikesSearchParam, setStrikesSearchParam] = useState("All Strikes");
  const [expirationTimesSelected, setExpirationTimesSelected] = useState(new Map());
  const [strikesSelected, setStrikesSelected] = useState(new Map());

  const [isCogModalOpen, setIsCogModalOpen] = useState(false);
  const [cogModalPositionTop, setCogModalPositionTop] = useState("0px");
  const [cogModalPositionRight, setCogModalPositionRight] = useState("0px");
  const [isPositionModalOpen, setIsPositionModalOpen] = useState(false);
  const [selectedPositions, setSelectedPositions] = useState(null);
  const [needsToBeClosed, setNeedsToBeClosed] = useState(null);
  const [isChecked, setIsChecked] = useState({
    timeToExpTrade: localStorage.getItem("timeToExpTrade") !== "false",
    createdAtTrade: localStorage.getItem("createdAtTrade") !== "false",

    pnlTrade: localStorage.getItem("pnlTrade") !== "false",
    roiTrade: localStorage.getItem("roiTrade") !== "false",
    aprTrade: localStorage.getItem("aprTrade") !== "false",
    apyTrade: localStorage.getItem("apyTrade") !== "false",

    totalGammaTrade: localStorage.getItem("totalGammaTrade") !== "false",
    totalVegaTrade: localStorage.getItem("totalVegaTrade") !== "false",
    totalThetaTrade: localStorage.getItem("totalThetaTrade") !== "false",
    totalDeltaTrade: localStorage.getItem("totalDeltaTrade") !== "false"
  });

  const [panelIsDisplayed, setPanelIsDisplayed] = useState(() => {
    return localStorage.getItem("isTppDisplayed") === "true";
  });

  const [tooltipIsVisible, setTooltipIsVisible] = useState(false);
  const [tooltipTarget, setTooltipTarget] = useState("");
  const [tooltipContent, setTooltipContent] = useState("");

  const openedTradePositions = useSelector(openedTradePositionsSelector);
  const web3AccountLoaded = useSelector(web3AccountLoadedSelector);
  const markets = useSelector(marketsSelector);
  const underlyingTokenPrice = useSelector(avgUnderlyingTokenPriceSelector);
  const selectedTokenPair = useSelector(selectedTokenPairSelector);
  const marketsLoaded = useSelector(marketsLoadedSelector);
  const settlements = useSelector(settlementDataSelector);
  const currentTimestamp = useSelector(currentTimestampSelector);
  const marginPoolDataLoaded = useSelector(marginPoolDataLoadedSelector);
  const marginPoolData = useSelector(marginPoolDataSelector);

  const tableWrapper = useRef();
  const idPrefix = "trade-positions-panel-";

  useEffect(() => {
    const tokenFromPortfolio = localStorage.getItem("tokenFromPortfolio");
    if (tokenFromPortfolio && closeAllFilterDropmenusRef) {
      localStorage.removeItem("tokenFromPortfolio");

      const rowInfo = {
        original: {
          token: tokenFromPortfolio
        }
      };

      onRowPositionClicked(rowInfo, closeAllFilterDropmenusRef);
    }
  });

  useEffect(() => {
    initializeFilterOptions();
  }, []);

  useEffect(() => {
    if (openedTradePositions && openedTradePositions.hasOwnProperty("length")) {
      initializeFilterOptions();
    }
  }, [openedTradePositions]);

  const initializeFilterOptions = () => {
    let newExpirationTimesSelected = new Map(expirationTimesSelected);
    const expirationTimestampsMap = new Map();
    if (newExpirationTimesSelected.size === 0) {
      newExpirationTimesSelected.set("All Expirations", true);
    }
    let newStrikesSelected = new Map(strikesSelected);
    if (newStrikesSelected.size === 0) {
      newStrikesSelected.set("All Strikes", true);
    }

    const strikeSet = new Set();
    const expirationTimesSet = new Set();
    openedTradePositions &&
      openedTradePositions.forEach(p => {
        const expirationInSeconds = p.expirationTime;
        const expirationDate = new Date(expirationInSeconds * 1000);

        const day = expirationDate.getDate();

        const monthNumber = expirationDate.getMonth();
        const month = toMonthName(monthNumber + 1)
          .slice(0, 3)
          .toUpperCase();
        const year = expirationDate.getFullYear();

        const formattedExpirationDate = `${day} ${month} ${String(year).slice(2)}`;
        if (!newExpirationTimesSelected.has(formattedExpirationDate)) {
          newExpirationTimesSelected.set(formattedExpirationDate, false);
        }
        if (!expirationTimestampsMap.has(Number(expirationInSeconds))) {
          expirationTimestampsMap.set(Number(expirationInSeconds), formattedExpirationDate);
        }

        const strikePrice = String(parseInt(p.strikePrice));
        if (!newStrikesSelected.has(strikePrice) && strikePrice != 0) {
          newStrikesSelected.set(strikePrice, false);
        }

        !expirationTimesSet.has(formattedExpirationDate) && expirationTimesSet.add(formattedExpirationDate);
        !strikeSet.has(strikePrice) && strikeSet.add(strikePrice);
      });
    const expirationTimestampsSorted = [...expirationTimestampsMap.keys()].sort();
    const expirationTimesSelectedSorted = new Map();
    expirationTimesSelectedSorted.set("All Expirations", newExpirationTimesSelected.get("All Expirations"));
    expirationTimestampsSorted.forEach(timestamp => {
      const formattedExpirationDate = expirationTimestampsMap.get(timestamp);
      expirationTimesSelectedSorted.set(formattedExpirationDate, newExpirationTimesSelected.get(formattedExpirationDate));
    });
    newExpirationTimesSelected = new Map(
      [...expirationTimesSelectedSorted.entries()].filter(ss => ss[0] === "All Expirations" || expirationTimesSet.has(ss[0]))
    );

    newStrikesSelected = new Map([...newStrikesSelected.entries()].filter(ss => ss[0] === "All Strikes" || strikeSet.has(ss[0])));
    newStrikesSelected = new Map([...newStrikesSelected.entries()].sort((a, b) => a[0] - b[0]));

    setExpirationTimesSelected(newExpirationTimesSelected);
    setStrikesSelected(newStrikesSelected);
  };

  //TODO: this is temporary because we pass function on FilterPositions component, remove later

  const searchParamsAreDefault =
    searchCallPutParam === "Options & Futures" &&
    searchLongShortParam === "Longs & Shorts" &&
    expirationTimesSearchParam === "All Expirations" &&
    strikesSearchParam === "All Strikes";

  const resetFiltersToDefault = () => {
    const newStrikesSelected = new Map();
    newStrikesSelected.set("All Strikes", true);
    for (const [key, value] of strikesSelected.entries()) {
      if (key === "All Strikes") {
        continue;
      }
      newStrikesSelected.set(key, false);
    }

    const newExpirationTimesSelected = new Map();
    newExpirationTimesSelected.set("All Expirations", true);
    for (const [key, value] of expirationTimesSelected.entries()) {
      if (key === "All Expirations") {
        continue;
      }
      newExpirationTimesSelected.set(key, false);
    }

    setSearchCallPutParam("Options & Futures");
    setSearchLongShortParam("Longs & Shorts");
    setExpirationTimesSearchParam("All Expirations");
    setStrikesSearchParam("All Strikes");
    setStrikesSelected(newStrikesSelected);
    setExpirationTimesSelected(newExpirationTimesSelected);
  };

  const arePositionsLoaded = () => {
    if (openedTradePositions === null) {
      return false;
    }
    return checkAllUnderlyingTokenPriceExist(openedTradePositions);
  };

  const sortPositions = positions => {
    positions.sort((a, b) => {
      if (parseInt(a.expirationTime) !== parseInt(b.expirationTime)) {
        return parseInt(a.expirationTime) - parseInt(b.expirationTime);
      } else if (a.strikePrice !== b.strikePrice) {
        return a.strikePrice - b.strikePrice;
      } else if (a.isCall !== b.isCall) {
        return (b.isCall ? 1 : 0) - (a.isCall ? 1 : 0);
      } else {
        if (a.name !== "..." && b.name !== "...") {
          return a.type.length - b.type.length;
        }
      }
      return 0;
    });
  };

  const getTableData = () => {
    // TODO: v2 it would be cleaner and testable if this mapping was done through selector
    if (arePositionsLoaded() && web3AccountLoaded && marketsLoaded && marginPoolDataLoaded) {
      let sortablePositions = [...openedTradePositions];
      sortPositions(sortablePositions);

      const mappedPositions = sortablePositions.map((position, index) => {
        let baseName = "";
        let collName = "";
        let openBasePriceArr = "...";
        let collateralArr = "";
        let markBasePriceArr = "...";
        let valueArr = "";
        let pnlArr = "";
        let roiArr = "";
        let aprArr = "";
        let apyArr = "";
        let deltaArr = "";
        let gammaArr = "";
        let thetaArr = "";
        let vegaArr = "";
        let openPriceLoaded = false;
        let underlyingPrice = 0;
        let token = "";
        let timeToExp = "";
        let timePassedFromTransaction = "";

        if (position.name !== "...") {
          const isShort = position.type === "short";
          baseName = position.tokenPair.baseTokenSymbol;
          collName = position.isCall ? position.tokenPair.underlyingTokenSymbol : position.tokenPair.baseTokenSymbol;
          underlyingPrice = parseFloat(formatUnits(position.tokenPair.avgUnderPrice.toString(), 18));
          const market = markets.find(ex => ex.marketId === position.marketId);
          const strikePrice = parseFloat(market.normalizedStrikePrice);
          const isCall = market.isCall;
          const isSettled = market.isSettled;
          token = position.shortName;

          let selectedDaysToExpiry = getHighestDaysToExpiryRounded([position], [market]);

          const marketIsExpired = isSettled || Number(market.expirationTime) < currentTimestamp;

          // if settled, use 0 days, and underPrice at settlement
          if (marketIsExpired) {
            selectedDaysToExpiry = 0;
            const settlement = settlements.find(s => s.expirationTime === market.expirationTime);
            if (settlement) {
              underlyingPrice = parseFloat(formatUnits(settlement.actualUnderlyingPrice.toString(), 18));
            }
          }

          // calculation are always done in chartUtils
          const baseBorrowRate = marginPoolData.tokensData.find(data => data.symbol === "USD").normalizedBorrowInterestRate;
          const underBorrowRate = marginPoolData.tokensData.find(data => data.symbol === "ETH").normalizedBorrowInterestRate;
          const borrowRates = { baseBorrowRate, underBorrowRate };
          const chartPoint = getChartPoint(
            [position],
            markets,
            underlyingPrice,
            selectedDaysToExpiry,
            parseFloat(market.longPriceInVol),
            selectedTokenPair.riskFreeRate / 100,
            borrowRates
          );

          let expiredText = " (EXPIRED)";
          if (isSettled) {
            const underlyingPriceAtExpired = getUnderlyingPriceAtExpiredStr(market, settlements);
            expiredText = " (EXPIRED $" + underlyingPriceAtExpired + ")";
          }

          token += marketIsExpired ? expiredText : "";

          // total premium (what user paid for opening position)
          let openValue = chartPoint.value - chartPoint.pnl;
          const optionValue = chartPoint.optionValue;
          valueArr = [optionValue, baseName, openValue];

          // locked collateral in base
          const collateralInBase = isCall ? underlyingPrice : strikePrice;

          // open price
          const openPriceInBase = position.openBasePrice;
          openBasePriceArr = formatValue(openPriceInBase, 2);

          // mark price
          let markBasePrice = chartPoint.markPrice;
          if (isSettled) {
            markBasePrice = optionValue / position.balance;
          }
          markBasePriceArr = formatValue(markBasePrice, 2);

          // collateral
          const collateralPrice = position.openCollPrice;
          const isFuture = market.isFuture;
          // collateralArr = isShort ? [position.balance * position.openCollPrice, collName] : "";
          collateralArr = isShort
            ? [position.balance * position.openCollPrice, collName]
            : isFuture
            ? [position.totalBaseCost, "USD"] // TODO: BUG: should collateral be adjusted for interest rate?
            : "";

          // PNL
          const profit = chartPoint.pnl;
          pnlArr = [profit, baseName];

          // TODO: v1 chartUtils could return roi, apr, apy
          // ROI
          const roi = (profit / openValue) * 100;
          roiArr = [roi, underlyingPrice];

          // APR
          const daysSinceStart = (Math.round(new Date().getTime() / 1000) - position.openTime) / (60 * 60 * 24);
          const apr = (profit / openValue / daysSinceStart) * 365 * 100;
          aprArr = [apr, "%", daysSinceStart];

          // APY
          const apy = (Math.pow(1 + apr / 100 / 365, 365) - 1) * 100;
          apyArr = [apy, "%"];

          // GREEKS
          deltaArr = [chartPoint.delta, baseName];
          gammaArr = [chartPoint.gamma, baseName];
          thetaArr = [chartPoint.theta, baseName];
          vegaArr = [chartPoint.vega, baseName];

          // check if loaded
          openPriceLoaded = isFinite(openPriceInBase) && (isShort ? isFinite(collateralPrice) : true);

          const expirationTime = parseInt(position.expirationTime);
          const now = parseInt(Date.now() / 1000);
          const diffExpAndNow = expirationTime - now;
          const daysAndHoursLeft = diffExpAndNow / (24 * 60 * 60);

          if (daysAndHoursLeft < 1) {
            const hoursLeft = Math.round(diffExpAndNow / 3600);
            timeToExp = hoursLeft > 1 ? hoursLeft + "h" : "expired";
          } else {
            timeToExp = Math.round(daysAndHoursLeft) + "d";
          }

          const timestamp = parseInt(position.timestamp);
          const diffTimestampAndNow = now - timestamp;
          const daysAndHoursBefore = diffTimestampAndNow / (24 * 60 * 60);

          if (daysAndHoursBefore < 1) {
            const hoursBefore = Math.round(diffTimestampAndNow / 3600);
            timePassedFromTransaction = hoursBefore + "h";
          } else {
            timePassedFromTransaction = Math.round(daysAndHoursBefore) + "d";
          }

          // if trade happened recently, don't show APY
          if (Math.abs(now - timestamp) < 60 * 60) {
            aprArr = "";
            apyArr = "";
          }
        }
        return {
          id: position,
          token: token,
          createdAt: timePassedFromTransaction,
          size: position.type === "long" ? position.balance : position.balance * -1,
          open: openPriceLoaded ? openBasePriceArr : "",
          mark: markBasePriceArr,
          collateral: openPriceLoaded ? collateralArr : "",
          value: openPriceLoaded ? valueArr : "",
          timeToExp,
          pnl: openPriceLoaded ? pnlArr : "",
          roi: openPriceLoaded ? roiArr : "",
          apr: openPriceLoaded ? aprArr : "",
          apy: openPriceLoaded ? apyArr : "",
          delta: openPriceLoaded ? deltaArr : "",
          gamma: openPriceLoaded ? gammaArr : "",
          theta: openPriceLoaded ? thetaArr : "",
          vega: openPriceLoaded ? vegaArr : ""
        };
      });

      // mapping can introduce undefined elements, so we filter them out
      return mappedPositions.filter(pos => pos !== undefined);
    }

    return [];
  };

  const onMouseEnterColumnHeader = e => {
    if (!tooltipIsVisible) {
      setTooltipIsVisible(true);
      setTooltipContent(TOOLTIPS_TEXT[e.currentTarget.getAttribute("name")]);
      setTooltipTarget(e.currentTarget.id);
    }
  };

  const onMouseLeaveColumnHeader = e => {
    if (!cursorRangeCheck(tooltipTarget, e.clientX, e.clientY)) {
      setTooltipIsVisible(false);
      setTooltipContent("");
      setTooltipTarget("");
      document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipLeftPosition", -500 + "px");
    }
  };

  const getTableColumns = () => {
    const tableColumns = [
      {
        Header: () => (
          <div className="alignLeft">
            <span id="my-positions-token" name="myPositionToken" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Token
            </span>
          </div>
        ),
        accessor: "token",
        align: "right",
        minWidth: 60,
        maxWidth: 5 * 60,
        sortable: false,
        Footer: () => (
          <span className="floatLeft">
            <strong>Total</strong>
          </span>
        )
      },
      {
        Header: () => (
          <div>
            <span id="pool-positions-size" name="myPositionsOpened" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Opened
            </span>
          </div>
        ),
        accessor: "createdAt",
        align: "right",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: false,
        show: isChecked.createdAtTrade,
        Cell: row => <span className="floatRight lowercase">{row.value} ago</span>
      },
      {
        Header: () => (
          <div>
            <span id="my-positions-time-to-exp" name="myPositionsTimeToExp" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Time to exp
            </span>
          </div>
        ),
        accessor: "timeToExp",
        minWidth: 40,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        show: isChecked.timeToExpTrade,
        Cell: row => {
          return <span className="floatRight lowercase">{row.row.timeToExp}</span>;
        },
        sortMethod: (a, b) => {
          return b[0] - a[0];
        }
      },

      {
        Header: () => (
          <div>
            <span id="my-positions-size" name="myPositionsSize" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              QTY
            </span>
          </div>
        ),
        accessor: "size",
        align: "right",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: true,
        sortMethod: (a, b) => {
          return b - a;
        },
        Cell: row => {
          return <span className="floatRight monospace">{formatValue(row.value, 2)}</span>;
        }
      },
      {
        Header: () => (
          <div>
            <span id="my-positions-open" name="myPositionsOpen" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Open Price
            </span>
          </div>
        ),
        accessor: "open",
        minWidth: 30,
        maxWidth: 5 * 30,
        filterable: false,
        sortable: false,
        Cell: row => <span className="floatRight monospace">{printPriceFromString(row.value)}</span>
      },
      {
        Header: () => (
          <div>
            <span id="my-positions-mark" name="myPositionsMark" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Mark Price
            </span>
          </div>
        ),
        accessor: "mark",
        minWidth: 30,
        maxWidth: 5 * 30,
        filterable: false,
        sortable: false,
        Cell: row => <span className="floatRight monospace">{printPriceFromString(row.value)}</span>
      },
      {
        Header: () => (
          <div>
            <span id="my-positions-collateral" name="myPositionsCollateral" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Collateral
            </span>
          </div>
        ),
        accessor: "collateral",
        minWidth: 40,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        Cell: row => {
          return (
            <span className="floatRight">
              {row.value.length === 2 ? row.value && formatValue(row.value[0], 2) + " " + row.value[1] : row.value && formatValue(row.value[0], 0)}
            </span>
          );
        }
      },
      {
        Header: () => (
          <div>
            <span id="my-positions-value" name="myPositionsValue" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              Option Value
            </span>
          </div>
        ),
        accessor: "value",
        minWidth: 40,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: true,
        Cell: row => {
          return <span className="floatRight monospace">{row.value.length >= 2 ? printPriceFromNumber(row.value[0]) : ""}</span>;
        },
        sortMethod: (a, b) => {
          return b[0] - a[0];
        },
        Footer: rows => {
          const valueFooter = getValueFooter(rows);
          return (
            <span className="floatRight monospace">
              <strong>{rows.data.length > 0 && rows.data[0].value.length >= 2 ? printPriceFromNumber(valueFooter) : ""}</strong>
            </span>
          );
        }
      },
      {
        Header: () => (
          <div>
            <span id="my-positions-pnl" name="myPositionsPnl" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              P/L
            </span>
          </div>
        ),
        accessor: "pnl",
        minWidth: 40,
        maxWidth: 3 * 40,
        filterable: false,
        sortable: true,
        show: isChecked.pnlTrade,
        Cell: row => (
          <span className="floatRight monospace">
            {row.value.length === 2
              ? row.value[0] === 0 || Math.abs(row.value[0]).toFixed(2) === "0.00"
                ? printPriceFromString(0)
                : printPriceFromString(row.value[0].toFixed(2))
              : ""}
          </span>
        ),
        sortMethod: (a, b) => {
          return b[0] - a[0];
        },
        Footer: rows => (
          <span className="floatRight monospace">
            <strong>
              {rows.data.length > 0 && rows.data[0].pnl.length === 2
                ? getPNLFooter(rows) === 0 || Math.abs(getPNLFooter(rows)).toFixed(2) === "0.00" //check if pnl footer value is 0 or 0.00 or -0.00001 ect. then it will write 0 in table footer
                  ? printPriceFromString(0)
                  : printPriceFromString(getPNLFooter(rows).toFixed(2))
                : ""}
            </strong>
          </span>
        )
      },
      {
        Header: () => (
          <div>
            <span id="my-positions-roi" name="myPositionsRoi" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              ROI
            </span>
          </div>
        ),
        accessor: "roi",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: true,
        show: isChecked.roiTrade,
        Cell: row => (
          <span className="floatRight monospace">
            {row.value.length === 2
              ? row.value[0] === 0 || Math.abs(row.value[0]).toFixed(2) === "0.00"
                ? 0 + "%"
                : limitValue(row.value[0], 100000, 2) + "%"
              : limitValue(row.value[0], 100000, 2)}
          </span>
        ),
        sortMethod: (a, b) => {
          return b[0] - a[0];
        },
        Footer: rows => (
          <span className="floatRight monospace">
            <strong>
              {rows.data.length > 0 && rows.data[0].pnl.length === 2
                ? getROIFooter(rows) === 0 || Math.abs(getROIFooter(rows)).toFixed(2) === "0.00"
                  ? 0 + "%"
                  : limitValue(getROIFooter(rows), 100000, 2) + "%"
                : ""}
            </strong>
          </span>
        )
      },
      {
        Header: () => (
          <div>
            <span id="my-positions-apr" name="myPositionsApr" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              APR
            </span>
          </div>
        ),
        accessor: "apr",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: true,
        show: isChecked.aprTrade,
        Cell: row => (
          <span className="floatRight monospace">
            {row.value.length >= 2
              ? row.value[0] === 0 || Math.abs(row.value[0]).toFixed(2) === "0.00"
                ? 0 + "%"
                : limitValue(row.value[0], 100000, 2) + "%"
              : row.value !== ""
              ? limitValue(row.value[0], 100000, 2)
              : ""}
          </span>
        ),
        sortMethod: (a, b) => {
          return b[0] - a[0];
        },
        Footer: rows => (
          <span className="floatRight monospace">
            <strong>
              {rows.data.length > 0 && rows.data[0].apr.length >= 2
                ? getAPRFooter(rows) === 0 || Math.abs(getAPRFooter(rows)).toFixed(2) === "0.00"
                  ? 0 + "%"
                  : limitValue(getAPRFooter(rows), 100000, 2) + "%"
                : ""}
            </strong>
          </span>
        )
      },
      {
        Header: () => (
          <div>
            <span id="my-positions-apy" name="myPositionsApy" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              APY
            </span>
          </div>
        ),
        accessor: "apy",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: true,
        show: isChecked.apyTrade,
        Cell: row => (
          <span className="floatRight monospace">
            {row.value.length === 2
              ? row.value[0] === 0 || Math.abs(row.value[0]).toFixed(2) === "0.00"
                ? 0 + "%"
                : limitValue(row.value[0], 100000, 2) + "%"
              : row.value !== ""
              ? limitValue(row.value[0], 100000, 2)
              : ""}
          </span>
        ),
        sortMethod: (a, b) => {
          return b[0] - a[0];
        },
        Footer: rows => (
          <span className="floatRight monospace">
            <strong>
              {rows.data.length > 0 && rows.data[0].apr.length >= 2
                ? getAPYFooter(rows) === 0 || Math.abs(getAPYFooter(rows)).toFixed(2) === "0.00"
                  ? 0 + "%"
                  : limitValue(getAPYFooter(rows), 100000, 2) + "%"
                : ""}
            </strong>
          </span>
        )
      },
      {
        Header: () => (
          <div>
            <span id="my-positions-delta" name="myPositionsDelta" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              &Delta; delta
            </span>
          </div>
        ),
        accessor: "delta",
        minWidth: 30,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: true,
        show: isChecked.totalDeltaTrade,
        sortMethod: (a, b) => {
          return b[0] - a[0];
        },
        Cell: row => {
          return <span className="floatRight monospace">{row.value.length === 2 ? formatValue(row.value[0], 2) + "" : row.value[0]}</span>;
        },
        Footer: rows => {
          const deltaFooter = getDeltaFooter(rows);
          return (
            <span className="floatRight monospace">
              <strong>
                {rows.data.length > 0 && rows.data[0].delta.length === 2
                  ? deltaFooter === 0
                    ? formatValue(deltaFooter, 0) + ""
                    : formatValue(deltaFooter, 2) + ""
                  : ""}
              </strong>
            </span>
          );
        }
      },
      {
        Header: () => (
          <div
            id="my-positions-gamma"
            style={{ textAlign: "center" }}
            name="myPositionsGamma"
            onMouseEnter={onMouseEnterColumnHeader}
            onMouseLeave={onMouseLeaveColumnHeader}
          >
            <span className="lowercase">&gamma;</span>
            <span> gamma</span>
          </div>
        ),
        accessor: "gamma",
        minWidth: 30,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: false,
        show: isChecked.totalGammaTrade,
        Cell: row => {
          return <span className="floatRight monospace">{row.value.length === 2 ? formatValue(row.value[0], 2) + "" : row.value[0]}</span>;
        },
        Footer: rows => {
          const gammaFooter = getGammaFooter(rows);
          return (
            <span className="floatRight monospace">
              <strong>
                {rows.data.length > 0 && rows.data[0].gamma.length === 2
                  ? gammaFooter === 0
                    ? formatValue(gammaFooter, 0) + ""
                    : formatValue(gammaFooter, 2) + ""
                  : ""}
              </strong>
            </span>
          );
        }
      },
      {
        Header: () => (
          <div>
            <span id="my-positions-theta" name="myPositionsTheta" onMouseEnter={onMouseEnterColumnHeader} onMouseLeave={onMouseLeaveColumnHeader}>
              &Theta; theta
            </span>
          </div>
        ),
        accessor: "theta",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: false,
        show: isChecked.totalThetaTrade,
        Cell: row => <span className="floatRight monospace">{row.value.length === 2 ? formatValue(row.value[0], 2) + "" : row.value[0]}</span>,
        Footer: rows => {
          const thetaFooter = getThetaFooter(rows);
          return (
            <span className="floatRight monospace">
              <strong>
                {rows.data.length > 0 && rows.data[0].theta.length === 2
                  ? thetaFooter === 0
                    ? formatValue(thetaFooter, 0) + ""
                    : formatValue(thetaFooter, 2) + ""
                  : ""}
              </strong>
            </span>
          );
        }
      },
      {
        Header: () => (
          <div
            id="my-positions-vega"
            style={{ width: "fit-content", marginLeft: "auto" }}
            name="myPositionsVega"
            onMouseEnter={onMouseEnterColumnHeader}
            onMouseLeave={onMouseLeaveColumnHeader}
          >
            V VEGA
          </div>
        ),
        accessor: "vega",
        minWidth: 30,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: false,
        show: isChecked.totalVegaTrade,
        Cell: row => <span className="floatRight monospace">{row.value.length === 2 ? formatValue(row.value[0], 2) + "" : row.value[0]}</span>,
        Footer: rows => {
          const vegaFooter = getVegaFooter(rows);
          return (
            <span className="floatRight monospace">
              <strong>
                {rows.data.length > 0 && rows.data[0].vega.length === 2
                  ? vegaFooter === 0
                    ? formatValue(vegaFooter, 0) + ""
                    : formatValue(vegaFooter, 2) + ""
                  : ""}
              </strong>
            </span>
          );
        }
      }
    ];

    return tableColumns;
  };

  const filterTableData = () => {
    const resetFilters = document.querySelector(".-resetFiltersContainer");

    if (resetFilters) {
      if (searchParamsAreDefault) {
        if (!resetFilters.className.includes("imgAndResetFiltersSpanDisabled")) {
          resetFilters.classList.add("imgAndResetFiltersSpanDisabled");
          resetFilters.children[1].classList.add("resetFiltersTextDisabled");
          resetFilters.children[0].className = "resetImgDisabled";
        }
      } else {
        if (resetFilters.className.includes("imgAndResetFiltersSpanDisabled")) {
          resetFilters.classList.remove("imgAndResetFiltersSpanDisabled");
          resetFilters.children[1].classList.remove("resetFiltersTextDisabled");
          resetFilters.children[0].className = "resetImg";
        }
      }
    }

    let filteredData = [];
    let allData = getTableData();
    allData.forEach(d => {
      const isFuturePosition = !d.token.endsWith("C") && !d.token.endsWith("P");

      const callPutFuture = isFuturePosition ? "Futures" : d.id.isCall ? "Calls" : "Puts";

      const longShort = d.id.type === "long" ? "Longs" : "Shorts";

      const expirationInSeconds = d.id.expirationTime;
      const expirationDate = new Date(expirationInSeconds * 1000);

      const day = expirationDate.getDate();
      const monthNumber = expirationDate.getMonth();
      const month = toMonthName(monthNumber + 1)
        .slice(0, 3)
        .toUpperCase();
      const year = expirationDate.getFullYear();

      const formattedExpirationDate = `${day} ${month} ${String(year).slice(2)}`;
      const strikePrice = String(parseInt(d.id.strikePrice));

      if (searchCallPutParam !== "Options & Futures" && searchCallPutParam !== callPutFuture) {
        return;
      }

      if (searchLongShortParam !== "Longs & Shorts" && searchLongShortParam !== longShort) {
        return;
      }

      if (expirationTimesSearchParam !== "All Expirations" && !expirationTimesSelected.get(formattedExpirationDate)) {
        return;
      }

      if (strikesSearchParam !== "All Strikes" && !strikesSelected.get(strikePrice)) {
        return;
      }

      filteredData.push(d);
    });
    return filteredData;
  };

  const handleShowHidePanel = (lsId, bodyId, arrowDownId, arrowUpId) => {
    const headerActions = document.getElementById("headerActionsTpp");

    if (localStorage.getItem(lsId) === "true") {
      localStorage.setItem(lsId, false);
      headerActions.style.justifyContent = "flex-end";
    } else if (localStorage.getItem(lsId) === "false") {
      localStorage.setItem(lsId, true);
      headerActions.style.justifyContent = "space-between";
    }
  };

  const onRowPositionClicked = (rowInfo, closeAllFilterDropmenus) => {
    closeAllFilterDropmenus();
    const displayName = rowInfo.original.token;
    const token = displayName.split(" ")[0];
    const position = openedTradePositions.find(p => token === p.shortName);
    const market = position && markets.find(e => e.marketId === position.marketId);
    document.querySelector("body").classList.toggle("scrollDisabled");
    setIsPositionModalOpen(true);
    setSelectedPositions([position]);
    setSelectedPositionMarket(market);
  };

  const printTable = closeAllFilterDropmenus => {
    if (web3AccountLoaded && arePositionsLoaded() && openedTradePositions.length > 0) {
      return (
        <div ref={tableWrapper}>
          <ReactTable
            className="-highlight"
            data={filterTableData()}
            //filterable
            columns={getTableColumns()}
            defaultPageSize={5}
            getTrProps={(state, rowInfo, column) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: e => {
                    e.stopPropagation();
                    onRowPositionClicked(rowInfo, closeAllFilterDropmenus);
                  },
                  style: {
                    cursor: "pointer"
                  }
                };
              }
              return {};
            }}
            getTfootTrProps={(state, rowInfo, column, instance) => {
              return {
                onClick: e => {
                  e.stopPropagation();
                  closeAllFilterDropmenus();
                  // todo: v1 don't split, use 2 values for first column
                  const tokens = state.data.map(p => p.token.split(" ")[0]);
                  const selectedPositions = openedTradePositions.filter(p => tokens.includes(p.shortName));

                  if (selectedPositions.length === 0) {
                    return;
                  } else if (selectedPositions.length === 1) {
                    const position = selectedPositions[0];
                    const market = position && markets.find(e => e.marketId === position.marketId);
                    setSelectedPositionMarket(market);
                  }
                  document.querySelector("body").classList.toggle("scrollDisabled");
                  setIsPositionModalOpen(true);
                  setSelectedPositions(selectedPositions);
                },
                style: {
                  cursor: "pointer"
                }
              };
            }}
          />
        </div>
      );
    }
  };

  const printEmptyTable = text => {
    if (web3AccountLoaded && arePositionsLoaded()) {
      if (openedTradePositions.length === 0) {
        return (
          <Row style={{ height: "200px" }}>
            <Col className="mb-1 emptyTable" md="12">
              <span className="emptyTableMessage">{text}</span>
            </Col>
          </Row>
        );
      }
    }
  };

  const toggleTradingModal = () => {
    document.querySelector("body").classList.toggle("scrollDisabled");
    setiIsTradingModalOpen(!isTradingModalOpen);
  };

  const getMaxLongAmount = (market, isBuy) => {
    const marketDepth = calculateMarketDepth(market, underlyingTokenPrice.normalized);
    if (isBuy) {
      return marketDepth.plusFive;
    } else {
      return marketDepth.minusFive;
    }
  };

  const handleClick = (event, isCogPositionsModalOpened, setCogPositionsModalOpen) => {
    const modalIsOpen = !isCogPositionsModalOpened;
    setCogPositionsModalOpen(modalIsOpen);
    if (modalIsOpen) {
      document.querySelector("body").classList.toggle("scrollDisabled");
      let { top } = event.currentTarget.getBoundingClientRect();
      const { width } = event.currentTarget.closest(".card-header").getBoundingClientRect();
      const right = (window.innerWidth - width) / 2;
      const height = window.innerHeight;
      const { bottom: cogBottom } = event.target.getBoundingClientRect();
      cogBottom + 197 > height ? (top -= 300) : (top -= 38);
      setIsCogModalOpen(!isCogModalOpen);
      setCogModalPositionTop(top);
      setCogModalPositionRight(right);
      document.getElementById("totalCog").style.transform = "rotate(45deg)";
    }
  };

  const togglePositionModal = () => {
    document.querySelector("body").classList.toggle("scrollDisabled");
    setIsPositionModalOpen(!isPositionModalOpen);
  };

  const checkHandler = e => {
    localStorage.setItem(e.target.name + "Trade", !isChecked[e.target.name + "Trade"]);
    setIsChecked({ ...isChecked, [e.target.name + "Trade"]: !isChecked[e.target.name + "Trade"] });
  };

  const resetTppSwitches = () => {
    localStorage.setItem("timeToExpTrade", false);
    localStorage.setItem("createdAtTrade", true);
    localStorage.setItem("pnlTrade", true);
    localStorage.setItem("roiTrade", false);
    localStorage.setItem("aprTrade", false);
    localStorage.setItem("apyTrade", false);
    localStorage.setItem("totalGammaTrade", false);
    localStorage.setItem("totalVegaTrade", false);
    localStorage.setItem("totalThetaTrade", false);
    localStorage.setItem("totalDeltaTrade", true);

    setIsChecked({
      timeToExpTrade: false,
      createdAtTrade: true,
      pnlTrade: true,
      roiTrade: false,
      aprTrade: false,
      apyTrade: false,
      totalGammaTrade: false,
      totalVegaTrade: false,
      totalThetaTrade: false,
      totalDeltaTrade: true
    });
  };

  const isDefault =
    isChecked.pnlTrade &&
    isChecked.timeToExpTrade === false &&
    isChecked.createdAtTrade &&
    isChecked.roiTrade === false &&
    isChecked.aprTrade === false &&
    isChecked.apyTrade === false &&
    isChecked.totalGammaTrade === false &&
    isChecked.totalDeltaTrade &&
    isChecked.totalVegaTrade === false &&
    isChecked.totalThetaTrade === false;

  return (
    <>
      <FilterContext.Consumer>
        {({ closeAllFilterDropmenus, isCogPositionsModalOpened, setCogPositionsModalOpen }) => {
          const isCheckedProp = {
            timeToExp: isChecked.timeToExpTrade,
            createdAt: isChecked.createdAtTrade,
            pnl: isChecked.pnlTrade,
            roi: isChecked.roiTrade,
            apr: isChecked.aprTrade,
            apy: isChecked.apyTrade,
            totalGamma: isChecked.totalGammaTrade,
            totalVega: isChecked.totalVegaTrade,
            totalTheta: isChecked.totalThetaTrade,
            totalDelta: isChecked.totalDeltaTrade
          };

          closeAllFilterDropmenusRef = closeAllFilterDropmenus;

          return (
            <>
              <FiltersPositions
                parent={tableWrapper.current}
                searchLongShortLpParam={searchLongShortParam}
                setSearchLongShortLpParamState={setSearchLongShortParam}
                searchCallPutParam={searchCallPutParam}
                setSearchCallPutParamState={setSearchCallPutParam}
                expirationTimesSearchParam={expirationTimesSearchParam}
                expirationTimesSelected={expirationTimesSelected}
                setExpirationTimesSearchParam={setExpirationTimesSearchParam}
                setExpirationTimesSelected={setExpirationTimesSelected}
                strikesSearchParam={strikesSearchParam}
                strikesSelected={strikesSelected}
                setStrikesSearchParam={setStrikesSearchParam}
                setStrikesSelected={setStrikesSelected}
                searchParamsAreDefault={searchParamsAreDefault}
                resetFiltersToDefault={resetFiltersToDefault}
              />
              {isTradingModalOpen ? (
                <TradeModal
                  isOpen={isTradingModalOpen}
                  toggle={toggleTradingModal}
                  market={selectedPositionMarket}
                  getMaxLongAmount={getMaxLongAmount}
                  size={selectedPositions && selectedPositions[0].balance}
                  rawSize={selectedPositions && selectedPositions[0].rawBalance}
                  isLong={selectedPositions && selectedPositions[0].type === "long"}
                  isCall={selectedPositions && selectedPositions[0].isCall}
                  marketName={selectedPositions && selectedPositions[0].shortName}
                  needsToBeClosed={needsToBeClosed}
                  setNeedsToBeClosed={setNeedsToBeClosed}
                />
              ) : null}
              {isPositionModalOpen ? (
                <PositionModal
                  isOpen={isPositionModalOpen}
                  toggle={togglePositionModal}
                  toggleTradingModal={toggleTradingModal}
                  setNeedsToBeClosed={setNeedsToBeClosed}
                  selectedPositions={selectedPositions}
                  onRowPositionClicked={onRowPositionClicked}
                  closeAllFilterDropmenus={closeAllFilterDropmenus}
                />
              ) : null}
              <div className="content" style={{ paddingTop: "0px" }}>
                <Row>
                  <Col className="mb-1" md="12">
                    <Card
                      id={CARD_TITLE.toLowerCase()
                        .split(" ")
                        .join("_")}
                    >
                      <CardHeader
                        className="handle"
                        onDoubleClick={e => {
                          e.stopPropagation();
                          closeAllFilterDropmenus();
                          setPanelIsDisplayed(panelIsDisplayed => !panelIsDisplayed);
                          handleShowHidePanel("isTppDisplayed", "tpp", "tppArrowDown", "tppArrowUp");
                        }}
                      >
                        <CardTitle tag="h4">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              height: "100%",
                              alignSelf: "center",
                              alignItems: "center"
                            }}
                          >
                            <span className="headerTitle">{CARD_TITLE}</span>
                            <div id="headerActionsTpp" className="headerCogArrowContainer">
                              <div
                                id="totalCog"
                                className={panelIsDisplayed ? "cogHeaderWrapper" : "hidden cogHeaderWrapper"}
                                onClick={e => {
                                  e.stopPropagation();
                                  closeAllFilterDropmenus();
                                  handleClick(e, isCogPositionsModalOpened, setCogPositionsModalOpen);
                                }}
                              >
                                <img id={idPrefix + "table-settings"} style={{ margin: "auto" }} className="cog" />
                                <MyTooltip key={idPrefix + "table-settings"} target={idPrefix + "table-settings"} optionalOffset={15}>
                                  Table settings
                                </MyTooltip>
                              </div>

                              {panelIsDisplayed && (
                                <>
                                  <div
                                    id="tppArrowUp"
                                    className="arrowHeaderWrapper"
                                    onClick={e => {
                                      e.stopPropagation();
                                      closeAllFilterDropmenus();
                                      setPanelIsDisplayed(false);
                                      handleShowHidePanel("isTppDisplayed", "tpp", "tppArrowDown", "tppArrowUp");
                                      setTooltipContent("Show panel");
                                    }}
                                    onMouseEnter={e => {
                                      if (!tooltipIsVisible) {
                                        setTooltipIsVisible(true);
                                        setTooltipContent("Hide panel");
                                        setTooltipTarget(e.currentTarget.id);
                                      }
                                    }}
                                    onMouseLeave={onMouseLeaveColumnHeader}
                                  >
                                    <img style={{ margin: "auto" }} className="arrowUpIcon" />
                                  </div>
                                </>
                              )}

                              {!panelIsDisplayed && (
                                <>
                                  <div
                                    id="tppArrowDown"
                                    className="arrowHeaderWrapper"
                                    onClick={e => {
                                      e.stopPropagation();
                                      closeAllFilterDropmenus();
                                      setPanelIsDisplayed(true);
                                      handleShowHidePanel("isTppDisplayed", "tpp", "tppArrowDown", "tppArrowUp");
                                      setTooltipContent("Hide panel");
                                    }}
                                    onMouseEnter={e => {
                                      if (!tooltipIsVisible) {
                                        setTooltipIsVisible(true);
                                        setTooltipContent("Show panel");
                                        setTooltipTarget(e.currentTarget.id);
                                      }
                                    }}
                                    onMouseLeave={onMouseLeaveColumnHeader}
                                  >
                                    <img style={{ margin: "auto" }} className="arrowDownIcon" />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </CardTitle>
                      </CardHeader>
                      <CardBody id="tpp" className={panelIsDisplayed ? "" : "displayNone"}>
                        {printTable(closeAllFilterDropmenus)}
                        {printEmptyTable(EMPTY_TABLE_TEXT)}
                        {printLoadingIcon(web3AccountLoaded, arePositionsLoaded())}
                        <ConnectPanel />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {isCogPositionsModalOpened && (
                  <CogPositionsModal
                    cogModalPositionTop={cogModalPositionTop}
                    cogModalPositionRight={cogModalPositionRight}
                    isChecked={isCheckedProp}
                    isDefault={isDefault}
                    checkHandler={checkHandler}
                    resetSwitches={resetTppSwitches}
                    setCogPositionsModalOpen={setCogPositionsModalOpen}
                  />
                )}
              </div>
            </>
          );
        }}
      </FilterContext.Consumer>
      {tooltipIsVisible && (
        <MyTooltip2 target={tooltipTarget} setTooltipIsVisible={setTooltipIsVisible} setTooltipContent={setTooltipContent} setTooltipTarget={setTooltipTarget}>
          {tooltipContent}
        </MyTooltip2>
      )}
    </>
  );
};

export default TradePositionsPanel;
