/* eslint-disable no-undef */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createPortal } from "react-dom";
import DropdownMenuFilterContainer from "./DropdownMenuFilterContainer.jsx";
import { callPutsSearchParams, longShortsSearchParams } from "./TradePositionsPanel.jsx";
import DropdownMenuMultipleSelectionContainer from "./DropdownMenuMultipleSelectionContainer.jsx";
import { FilterContext } from "../../layouts/market/MainRouter.jsx";

class FiltersPositions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      top: "0",
      left: "0",
      width: "0"
    };

    this.resetImg = React.createRef();
  }

  dropmenuFilterOpenHandler = (e, dropmenuType, dropmenuOpened, setDropmenuOpened, closeAllFilterDropmenus, itemsLength) => {
    const img = e.currentTarget.querySelector("img");
    closeAllFilterDropmenus(img);
    img.classList.toggle("transformArrow");

    const newDropmenuOpened = !dropmenuOpened;

    if (newDropmenuOpened) {
      let { top, left, width, bottom } = e.currentTarget.getBoundingClientRect();

      bottom + 45 * Math.min(itemsLength, 5) > window.innerHeight ? (top -= 45 * Math.min(itemsLength, 5)) : (top += 30);

      this.setState({
        top: top + "px",
        left: left + "px",
        width: width + "px"
      });
      document.body.classList.add("scrollDisabled");
    } else {
      document.body.classList.remove("scrollDisabled");
    }
    setDropmenuOpened(dropmenuType, newDropmenuOpened);
  };

  render() {
    if (!this.props.parent) return null;

    const filterParamsAreDefault = this.props.searchParamsAreDefault;

    return createPortal(
      <FilterContext.Consumer>
        {({
          longShortDropmenuOpened,
          callPutDropmenuOpened,
          expirationTimesDropmenuOpened,
          strikePricesDropmenuOpened,
          setDropmenuOpened,
          closeAllFilterDropmenus
        }) => {
          const isTradePage = window.location.pathname.includes("trade");

          let dropdownMenuFilterContainerData = [];
          let dropdownMenuFilterContainerSearchParam = "";
          let dropdownMenuFilterContainerType = "";
          let dropdownMenuFilterContainerSetSearchParam = () => {};
          let dropdownMenuFilterContainerCloseDropmenu = () => {};
          if (longShortDropmenuOpened && isTradePage) {
            dropdownMenuFilterContainerData = longShortsSearchParams;
            dropdownMenuFilterContainerSearchParam = this.props.searchLongShortLpParam;
            dropdownMenuFilterContainerType = "longShortContainer";
            dropdownMenuFilterContainerSetSearchParam = this.props.setSearchLongShortLpParamState;
            dropdownMenuFilterContainerCloseDropmenu = () => setDropmenuOpened("longShortDropmenuOpened", false);
          } else if (callPutDropmenuOpened) {
            dropdownMenuFilterContainerData = callPutsSearchParams;
            dropdownMenuFilterContainerSearchParam = this.props.searchCallPutParam;
            dropdownMenuFilterContainerType = "callPutContainer";
            dropdownMenuFilterContainerSetSearchParam = this.props.setSearchCallPutParamState;
            dropdownMenuFilterContainerCloseDropmenu = () => setDropmenuOpened("callPutDropmenuOpened", false);
          }

          let dropdownMenuMultipleSelectionFirstOptionValue = "";
          let dropdownMenuMultipleSelectionContainerType = "";
          let dropdownMenuMultipleSelectionData = new Map();
          let dropdownMenuMultipleSelectionSetData = () => {};
          let dropdownMenuMultipleSelectionSetSearchParam = () => {};

          if (longShortDropmenuOpened && !isTradePage) {
            dropdownMenuMultipleSelectionFirstOptionValue = "Longs & Shorts & LPs";
            dropdownMenuMultipleSelectionContainerType = "longShortContainer";
            dropdownMenuMultipleSelectionData = this.props.longsShortsLpsSelected;
            dropdownMenuMultipleSelectionSetData = this.props.setLongsShortsLpsSelected;
            dropdownMenuMultipleSelectionSetSearchParam = this.props.setSearchLongShortLpParamState;
          } else if (expirationTimesDropmenuOpened) {
            dropdownMenuMultipleSelectionFirstOptionValue = "All Expirations";
            dropdownMenuMultipleSelectionContainerType = "expirationContainer";
            dropdownMenuMultipleSelectionData = this.props.expirationTimesSelected;
            dropdownMenuMultipleSelectionSetData = this.props.setExpirationTimesSelected;
            dropdownMenuMultipleSelectionSetSearchParam = this.props.setExpirationTimesSearchParam;
          } else if (strikePricesDropmenuOpened) {
            dropdownMenuMultipleSelectionFirstOptionValue = "All Strikes";
            dropdownMenuMultipleSelectionContainerType = "strikeContainer";
            dropdownMenuMultipleSelectionData = this.props.strikesSelected;
            dropdownMenuMultipleSelectionSetData = this.props.setStrikesSelected;
            dropdownMenuMultipleSelectionSetSearchParam = this.props.setStrikesSearchParam;
          }

          return (
            <>
              <div className="searchFilterPositionsContainer">
                <div
                  key="expirationContainer"
                  className="bottomSelect -expirationContainer"
                  onClick={e => {
                    e.stopPropagation();
                    this.dropmenuFilterOpenHandler(
                      e,
                      "expirationTimesDropmenuOpened",
                      expirationTimesDropmenuOpened,
                      setDropmenuOpened,
                      closeAllFilterDropmenus,
                      this.props.expirationTimesSelected ? this.props.expirationTimesSelected.size : 0
                    );
                  }}
                >
                  <span>{this.props.expirationTimesSearchParam}</span>
                  <img />
                </div>
                <div
                  key="strikeContainer"
                  className="bottomSelect -strikeContainer"
                  onClick={e => {
                    e.stopPropagation();
                    this.dropmenuFilterOpenHandler(
                      e,
                      "strikePricesDropmenuOpened",
                      strikePricesDropmenuOpened,
                      setDropmenuOpened,
                      closeAllFilterDropmenus,
                      this.props.strikesSelected ? this.props.strikesSelected.size : 0
                    );
                  }}
                >
                  <span>{this.props.strikesSearchParam}</span>
                  <img />
                </div>
                <div
                  key="longShortContainer"
                  className="bottomSelect -longShortContainer"
                  onClick={e => {
                    e.stopPropagation();
                    this.dropmenuFilterOpenHandler(
                      e,
                      "longShortDropmenuOpened",
                      longShortDropmenuOpened,
                      setDropmenuOpened,
                      closeAllFilterDropmenus,
                      isTradePage ? longShortsSearchParams.length - 1 : this.props.longsShortsLpsSelected ? this.props.longsShortsLpsSelected.size : 0
                    );
                  }}
                >
                  <span>{this.props.searchLongShortLpParam}</span>
                  <img />
                </div>
                <div
                  key="callPutContainer"
                  className="bottomSelect -callPutContainer"
                  onClick={e => {
                    e.stopPropagation();
                    this.dropmenuFilterOpenHandler(
                      e,
                      "callPutDropmenuOpened",
                      callPutDropmenuOpened,
                      setDropmenuOpened,
                      closeAllFilterDropmenus,
                      callPutsSearchParams.length - 1
                    );
                  }}
                >
                  <span>{this.props.searchCallPutParam}</span>
                  <img />
                </div>
                <div
                  key="resetFiltersContainer"
                  className={`bottomSelect -resetFiltersContainer${filterParamsAreDefault ? " imgAndResetFiltersSpanDisabled" : ""}`}
                  onClick={e => {
                    e.stopPropagation();
                    closeAllFilterDropmenus();
                    if (e.currentTarget.className.includes("imgAndResetFiltersSpanDisabled")) {
                      return;
                    }
                    this.props.resetFiltersToDefault();
                  }}
                  onMouseEnter={e => {
                    this.resetImg.current.classList.add("resetImgHover");
                  }}
                  onMouseLeave={e => {
                    this.resetImg.current.classList.remove("resetImgHover");
                  }}
                >
                  <img ref={this.resetImg} className={`${filterParamsAreDefault ? "resetImgDisabled" : "resetImg"}`} />
                  <span className={`${filterParamsAreDefault ? "resetFiltersTextDisabled" : ""}`}>Reset Filters</span>
                </div>
              </div>
              {((longShortDropmenuOpened && isTradePage) || callPutDropmenuOpened) && (
                <DropdownMenuFilterContainer
                  key={dropdownMenuFilterContainerType}
                  containerType={dropdownMenuFilterContainerType}
                  data={dropdownMenuFilterContainerData}
                  searchParam={dropdownMenuFilterContainerSearchParam}
                  setSearchParam={dropdownMenuFilterContainerSetSearchParam}
                  top={this.state.top}
                  left={this.state.left}
                  width={this.state.width}
                  closeDropmenu={dropdownMenuFilterContainerCloseDropmenu}
                />
              )}
              {((longShortDropmenuOpened && !isTradePage) || expirationTimesDropmenuOpened || strikePricesDropmenuOpened) && (
                <DropdownMenuMultipleSelectionContainer
                  key={dropdownMenuMultipleSelectionContainerType}
                  firstOptionValue={dropdownMenuMultipleSelectionFirstOptionValue}
                  containerType={dropdownMenuMultipleSelectionContainerType}
                  data={dropdownMenuMultipleSelectionData}
                  setData={dropdownMenuMultipleSelectionSetData}
                  setSelectValue={dropdownMenuMultipleSelectionSetSearchParam}
                  top={this.state.top}
                  left={this.state.left}
                  width={this.state.width}
                  closeDropmenu={dropdownMenuFilterContainerCloseDropmenu}
                />
              )}
            </>
          );
        }}
      </FilterContext.Consumer>,
      this.props.parent.querySelector(".pagination-bottom")
    );
  }
}

FiltersPositions.propTypes = {
  parent: PropTypes.object,
  strikesSelected: PropTypes.object,
  resetFiltersToDefault: PropTypes.func,
  strikesSearchParam: PropTypes.string,
  expirationTimesSearchParam: PropTypes.string,
  expirationTimesSelected: PropTypes.object,
  searchCallPutParam: PropTypes.string,
  searchLongShortLpParam: PropTypes.string,
  longsShortsLpsSelected: PropTypes.object,
  setExpirationTimesSearchParam: PropTypes.func,
  setLongsShortsLpsSelected: PropTypes.func,
  setSearchCallPutParamState: PropTypes.func,
  setSearchLongShortLpParamState: PropTypes.func,
  searchParamsAreDefault: PropTypes.func,
  setStrikesSelected: PropTypes.func,
  setStrikesSearchParam: PropTypes.func,
  setExpirationTimesSelected: PropTypes.func
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(FiltersPositions);
