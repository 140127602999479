import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearAllNotifications, removeConfirmedTransactionNotification } from "../../store/actions/actions.js";
import { web3AccountSelector } from "../../store/selectors/web3Selectors.js";
import { getDateString } from "../../store/utils/positionsManagerUtils";

const NotificationsFrame = ({ setNotificationsFrameOpen, notifications, networkName }) => {
  const account = useSelector(web3AccountSelector);

  useEffect(() => {
    document.onkeydown = onKeyPress;

    return () => {
      document.onkeydown = null;
    };
  }, []);

  const onKeyPress = e => {
    if (e && e.key === "Escape") {
      setNotificationsFrameOpen(false);
    }
  };

  const connectButton = document.getElementById("connect-button");
  const bell = document.getElementById("notification-bell");
  const { right } = bell.getBoundingClientRect();
  const { bottom } = connectButton.getBoundingClientRect();
  const top = bottom + "px";
  const rightPos = window.innerWidth - right + "px";

  const dispatch = useDispatch();

  const isClearButtonVisible = () => {
    return notifications.length > 0 && notifications.some(n => n.status === "confirmed");
  };

  const clearConfirmedNotifications = e => {
    e.stopPropagation();
    if (notifications.length > 0) {
      dispatch(clearAllNotifications(account));
      setNotificationsFrameOpen(false);
    }
  };

  const onTransactionHashClick = hash => {
    // todo: this doesn't work for base or arbitrum
    const subdomain = networkName === "Sepolia" ? "sepolia." : ""; //check if networkName is prop or state
    window.open("https://" + subdomain + "etherscan.io/tx/" + account, "_blank");
    setNotificationsFrameOpen(false);
  };

  return createPortal(
    <div className="notificationsFrameWrapper" style={{ top: top, right: rightPos }}>
      <div className="notificationFrameHeader">
        <span className="notificationText">NOTIFICATIONS</span>

        <button aria-hidden data-dismiss="modal" type="button" className="closeNotificationsFrameButton">
          <i className="tim-icons icon-simple-remove" />
        </button>
      </div>
      <div className="notificationsContainer">
        {notifications.length > 0 &&
          notifications.map(n => {
            const status = n.status === "pending" ? "Pending" : n.status === "failed" ? "Failed" : "Confirmed";

            const printedTx = n.tx.slice(0, 4) + "..." + n.tx.slice(-4);

            return (
              <div className="notificationItem" key={n.tx}>
                <div className="statusTextContainer">
                  {n.isExternal ? (
                    <span className="statusText">New Message</span>
                  ) : (
                    <>
                      <span className="statusText">Transaction </span>
                      {n.status === "failed" ? (
                        <span className={"statusText redText"}>&nbsp;{"Failed"}</span>
                      ) : (
                        <span className={`statusText${n.status === "pending" ? " yellowText" : " greenText"}`}>&nbsp;{status}</span>
                      )}
                      {n.status === "pending" && <img className="threeDotsGif" />}
                    </>
                  )}
                </div>
                <div className="notificationItemMainDataContainer">
                  <span className="notificationItemMainDataText">{n.quantity}</span>
                  <span className="notificationItemMainDataText blueText" onClick={() => onTransactionHashClick(n.tx)}>
                    TX: {printedTx}
                  </span>
                  <span className="notificationItemMainDataText">{getDateString(new Date(n.timestamp * 1000))}</span>
                </div>
              </div>
            );
          })}
      </div>
      {isClearButtonVisible ? (
        <div className="notificationsClearContainer">
          <button className="notificationsClearButton" disabled={notifications.length == 0} onClick={e => clearConfirmedNotifications(e)}>
            clear
          </button>
        </div>
      ) : null}
    </div>,
    document.getElementById("root")
  );
};

export default NotificationsFrame;
