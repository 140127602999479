/* eslint-disable no-undef */
import {
  borrowEventAdded,
  confirmTransactionNotification,
  loadingLPPositionTokenAccountBalance,
  lpPositionTokenAccountBalanceLoaded,
  lpTokenAddedOrUpdated,
  lpTradeAdded,
  marginAccountDataLoaded,
  marginPoolDataLoaded,
  marketUpdated,
  positionTokenAccountBalanceLoaded,
  tokenAccountBalanceLoaded,
  tokenWalletBalanceLoaded,
  tradeAdded
} from "../actions/actions.js";
import { toBn } from "evm-bn";
import { Contract } from "@ethersproject/contracts";
import { BigNumber } from "@ethersproject/bignumber";
import { formatUnits } from "@ethersproject/units";
import { getTokenNames } from "./marketsInteractions";
import { handleNewError } from "./errorInteractions";
import {
  ding,
  getNonce,
  getNotificationData,
  hasTxFailed,
  onConfirmed,
  onError,
  onFailed,
  onPending,
  toPreciseString,
  toShortAddress
} from "../../utils/utils.js";
import { store } from "../configureStore";
import { getLiquidityForLongs, toSqrtPrice } from "../../utils/MarketHelper";
import murmurhash from "murmurhash-js";
import { batch } from "react-redux";
import { getEventsStreamCached } from "./interactionsManager.js";
import { getMuttableMarketAttributes } from "../utils/marketsUtils.js";
import { loadMarginAccountData2, loadMarginPoolData2 } from "../utils/marginUtils.js";
import { reloadMarketTokensBalances2 } from "../utils/tokensUtils.js";

const DEFAULT_MARKET_BORROW_FACTOR = (1e18).toString();

export const loadTrades = async (
  networkData,
  positionsManager,
  lpManager,
  settlementManager,
  userBalances,
  userAddress,
  nonExpiredMarketIds,
  marketsImmutable
) => {
  let filterValue = {};
  if (userAddress != null) {
    filterValue.user = userAddress;
  } else {
    filterValue.marketId = nonExpiredMarketIds;
  }

  const [lpManagerAllEvents, settlementManagerAllEvents, positionsManagerAllEvents, userBalancesAllEvents] = await Promise.all([
    getEventsStreamCached(networkData, lpManager, "LPManager", "allEvents", { filter: filterValue }),
    getEventsStreamCached(networkData, settlementManager, "SettlementManager", "allEvents", { filter: filterValue }),
    getEventsStreamCached(networkData, positionsManager, "PositionsManager", "allEvents", { filter: filterValue }),
    getEventsStreamCached(networkData, userBalances, "UserBalances", "allEvents", { filter: filterValue })
  ]);

  const closedLongPositionsByLPManagerEvents = lpManagerAllEvents.filter(event => event.event == "ClosedLongPosition");
  const closedShortPositionsByLPManagerEvents = lpManagerAllEvents.filter(event => event.event == "ClosedShortPosition");
  const closedLongPositionsBySettlementManagerEvents = settlementManagerAllEvents.filter(event => event.event == "ClosedLongPosition");
  const closedShortPositionsBySettlementManagerEvents = settlementManagerAllEvents.filter(event => event.event == "ClosedShortPosition");
  const openedLongPositionsEvents = positionsManagerAllEvents.filter(event => event.event == "OpenedLongPosition");
  const closedLongPositionsEvents = positionsManagerAllEvents.filter(event => event.event == "ClosedLongPosition");
  const openedShortPositionsEvents = positionsManagerAllEvents.filter(event => event.event == "OpenedShortPosition");
  const closedShortPositionsEvents = positionsManagerAllEvents.filter(event => event.event == "ClosedShortPosition");
  const openedMarketMakerPositionsEvents = lpManagerAllEvents.filter(event => event.event == "OpenedMarketMakerPosition");
  const closedMarketMakerPositionsEvents = lpManagerAllEvents.filter(event => event.event == "ClosedMarketMakerPosition");
  const closedMarketMakerPositionsBySettlementManagerEvents = settlementManagerAllEvents.filter(event => event.event == "ClosedMarketMakerPosition");
  const liquidatedMarketMakerPositionsEvents = lpManagerAllEvents.filter(event => event.event == "LiquidatedMarketMakerPosition");
  const increaseReservesEvents = lpManagerAllEvents.filter(event => event.event == "IncreaseReserves");
  const decreaseReservesEvents = lpManagerAllEvents.filter(event => event.event == "DecreaseReserves");

  const longPositionLiquidatedEvents = userBalancesAllEvents.filter(event => event.event == "LongPositionLiquidated");
  const shortPositionLiquidatedEvents = userBalancesAllEvents.filter(event => event.event == "ShortPositionLiquidated");
  const LPPositionLiquidatedEvents = userBalancesAllEvents.filter(event => event.event == "LPPositionLiquidated");
  const accountLiquidatedEvents = userBalancesAllEvents.filter(event => event.event == "AccountLiquidated");

  let longShortTrades = [
    openedLongPositionsEvents.map(event => {
      const trade = event.returnValues;
      const longReceived = trade.longReceived.toString();
      if (longReceived !== "0") {
        const baseSent = trade.baseSent.toString();
        return {
          marketId: trade.marketId.toString(),
          user: trade.user,
          type: "long",
          isBuy: true,
          size: longReceived,
          baseAmount: baseSent / 1e18,
          basePrice: parseFloat(baseSent / 1e18 / (longReceived / 1e18)),
          collPrice: 0,
          timestamp: trade.timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.raw.data)
        };
      }
    }),
    closedLongPositionsEvents.map(event => {
      const trade = event.returnValues;
      const longSent = trade.longSent.toString();
      if (longSent !== "0") {
        const baseReceived = trade.baseReceived.toString();
        const collReceived = trade.collReceived.toString();
        return {
          marketId: trade.marketId.toString(),
          user: trade.user,
          type: "long",
          isBuy: false,
          size: longSent,
          baseAmount: baseReceived / 1e18,
          basePrice: parseFloat(baseReceived / 1e18 / (longSent / 1e18)),
          collPrice: parseFloat(collReceived / 1e18 / (longSent / 1e18)),
          interest: trade.interest.toString(),
          timestamp: trade.timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.raw.data)
        };
      }
    }),
    closedLongPositionsByLPManagerEvents.map(event => {
      const trade = event.returnValues;
      const longSent = trade.longSent.toString();
      if (longSent !== "0") {
        const baseReceived = trade.baseReceived.toString();
        const collReceived = trade.collReceived.toString();
        return {
          marketId: trade.marketId.toString(),
          user: trade.user,
          type: "long",
          isBuy: false,
          size: longSent,
          baseAmount: baseReceived / 1e18,
          basePrice: parseFloat(baseReceived / 1e18 / (longSent / 1e18)),
          collPrice: parseFloat(collReceived / 1e18 / (longSent / 1e18)),
          interest: trade.interest.toString(),
          timestamp: trade.timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.raw.data)
        };
      }
    }),
    closedLongPositionsBySettlementManagerEvents.map(event => {
      // todo: v1 3 close events go into 1 function
      const trade = event.returnValues;
      const longSent = trade.longSent.toString();
      if (longSent !== "0") {
        const baseReceived = trade.baseReceived.toString();
        const collReceived = trade.collReceived.toString();
        return {
          marketId: trade.marketId.toString(),
          user: trade.user,
          type: "long",
          isBuy: false,
          size: longSent,
          baseAmount: baseReceived / 1e18,
          basePrice: parseFloat(baseReceived / 1e18 / (longSent / 1e18)),
          collPrice: parseFloat(collReceived / 1e18 / (longSent / 1e18)),
          interest: trade.interest.toString(),
          timestamp: trade.timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.raw.data)
        };
      }
    }),
    openedShortPositionsEvents.map(event => {
      const trade = event.returnValues;
      const shortReceived = trade.shortReceived.toString();
      if (shortReceived !== "0") {
        const baseReceived = trade.baseReceived.toString();
        const collSent = trade.collSent.toString();
        return {
          marketId: trade.marketId.toString(),
          user: trade.user,
          type: "short",
          isBuy: false,
          size: shortReceived,
          baseAmount: baseReceived / 1e18,
          basePrice: parseFloat(baseReceived / 1e18 / (shortReceived / 1e18)),
          collPrice: parseFloat(collSent / 1e18 / (shortReceived / 1e18)),
          timestamp: trade.timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.raw.data)
        };
      }
    }),
    closedShortPositionsEvents.map(event => {
      const trade = event.returnValues;
      const shortSent = trade.shortSent.toString();
      if (shortSent !== "0") {
        const baseSent = trade.baseSent.toString();
        const collReceived = trade.collReceived.toString();

        return {
          marketId: trade.marketId.toString(),
          user: trade.user,
          type: "short",
          isBuy: true,
          size: shortSent,
          baseAmount: baseSent / 1e18,
          basePrice: parseFloat(baseSent / 1e18 / (shortSent / 1e18)),
          collPrice: parseFloat(collReceived / 1e18 / (shortSent / 1e18)),
          interest: trade.interest.toString(),
          timestamp: trade.timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.raw.data)
        };
      }
    }),
    closedShortPositionsByLPManagerEvents.map(event => {
      const trade = event.returnValues;
      const shortSent = trade.shortSent.toString();
      if (shortSent !== "0") {
        const baseSent = trade.baseSent.toString();
        const collReceived = trade.collReceived.toString();

        return {
          marketId: trade.marketId.toString(),
          user: trade.user,
          type: "short",
          isBuy: true,
          size: shortSent,
          baseAmount: baseSent / 1e18,
          basePrice: parseFloat(baseSent / 1e18 / (shortSent / 1e18)),
          collPrice: parseFloat(collReceived / 1e18 / (shortSent / 1e18)),
          interest: trade.interest.toString(),
          timestamp: trade.timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.raw.data)
        };
      }
    }),
    closedShortPositionsBySettlementManagerEvents.map(event => {
      const trade = event.returnValues;
      const shortSent = trade.shortSent.toString();
      if (shortSent !== "0") {
        const baseSent = trade.baseSent.toString();
        const collReceived = trade.collReceived.toString();
        return {
          marketId: trade.marketId.toString(),
          user: trade.user,
          type: "short",
          isBuy: true,
          size: shortSent,
          baseAmount: baseSent / 1e18,
          basePrice: parseFloat(baseSent / 1e18 / (shortSent / 1e18)),
          collPrice: parseFloat(collReceived / 1e18 / (shortSent / 1e18)),
          interest: trade.interest.toString(),
          timestamp: trade.timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.raw.data)
        };
      }
    }),
    closedMarketMakerPositionsEvents.map(event => {
      return getTradeEventOnLPPositionClose(event, marketsImmutable);
    }),
    liquidatedMarketMakerPositionsEvents.map(event => {
      // long or short from lp position liquidation
      return getTradeEventOnLPPositionClose(event, marketsImmutable);
    })
  ].flat(2);
  longShortTrades = longShortTrades.filter(lst => lst !== undefined);

  let lpTrades = [
    openedMarketMakerPositionsEvents.map(event => {
      // TODO: v2 use bs parameters from event to calculate longPriceInBase (much cheaper than smart contract doing it)
      // const underPrice = trade.underPrice.toString() / 1e18;
      // const volatility = trade.volatility.toString() / 1e18;
      // const rate = trade.rate.toString() / 1e18;
      // get expiration time and strike from market

      const trade = event.returnValues;

      // todo: v1 this is a patch, for some reason liqReceived is 0, user opened 69.5% - 75.9%, probably below 80%
      const liqReceived = trade.liqReceived.toString();
      if (liqReceived !== "0") {
        const baseSent = trade.baseSent.toString();
        const collSent = trade.collSent.toString();
        const shortSent = trade.shortSent.toString();
        return {
          marketId: trade.marketId.toString(),
          user: trade.user,
          positionId: trade.positionId.toString(),
          isBuy: true,
          size: liqReceived,
          baseAmount: baseSent / 1e18,
          basePrice: parseFloat(baseSent / 1e18 / (liqReceived / 1e18)),
          collPrice: parseFloat(collSent / 1e18 / (liqReceived / 1e18)),
          shortPrice: parseFloat(shortSent / 1e18 / (liqReceived / 1e18)),
          optionAmount: shortSent,
          longPriceInBase: trade.longPriceInBase,
          lower: trade.lower,
          upper: trade.upper,
          timestamp: trade.timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.raw.data)
        };
      }
    }),
    closedMarketMakerPositionsEvents.map(event => {
      return getLPEventOnLPPositionClose(event);
    }),
    closedMarketMakerPositionsBySettlementManagerEvents.map(event => {
      return getLPEventOnLPPositionClose(event);
    }),
    liquidatedMarketMakerPositionsEvents.map(event => {
      return getLPEventOnLPPositionClose(event, true);
    }),
    increaseReservesEvents.map(event => {
      const trade = event.returnValues;
      return {
        marketId: trade.marketId.toString(),
        user: trade.user,
        positionId: trade.positionId.toString(),
        isBuy: true,
        size: 0,
        baseAmount: trade.baseSent.toString() / 1e18,
        basePrice: trade.baseSent.toString() / 1e18,
        collPrice: 0,
        shortPrice: 0,
        optionAmount: 0,
        longPriceInBase: 0,
        timestamp: trade.timestamp.toString(),
        signature: event.transactionHash + murmurhash(event.raw.data)
      };
    }),
    decreaseReservesEvents.map(event => {
      const trade = event.returnValues;
      return {
        marketId: trade.marketId.toString(),
        user: trade.user,
        positionId: trade.positionId.toString(),
        isBuy: false,
        size: 0,
        baseAmount: trade.baseReceived.toString() / 1e18,
        basePrice: trade.baseReceived.toString() / 1e18,
        collPrice: 0,
        shortPrice: 0,
        optionAmount: 0,
        longPriceInBase: 0,
        timestamp: trade.timestamp.toString(),
        signature: event.transactionHash + murmurhash(event.raw.data)
      };
    })
  ].flat(2);
  lpTrades = lpTrades.filter(lpt => lpt !== undefined);

  let longShortTradesFromLiquidation = [
    longPositionLiquidatedEvents.map(event => {
      // opened long
      const trade = event.returnValues;
      const longSent = trade.longSent.toString();
      if (longSent !== "0") {
        const baseReceived = trade.baseReceived.toString();
        return {
          marketId: trade.marketId.toString(),
          user: trade.liquidatorAccount,
          type: "long",
          isBuy: true,
          size: longSent,
          baseAmount: baseReceived / 1e18,
          basePrice: parseFloat(baseReceived / 1e18 / (longSent / 1e18)),
          collPrice: 0,
          timestamp: trade.timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.raw.data),
          isLiquidation: true
        };
      }
    }),
    longPositionLiquidatedEvents.map(event => {
      // closed long
      const trade = event.returnValues;
      const longSent = trade.longSent.toString();
      if (longSent !== "0") {
        const baseReceived = trade.baseReceived.toString();
        return {
          marketId: trade.marketId.toString(),
          user: trade.liquidatedAccount,
          type: "long",
          isBuy: false,
          size: longSent,
          baseAmount: baseReceived / 1e18,
          basePrice: parseFloat(baseReceived / 1e18 / (longSent / 1e18)),
          collPrice: 0,
          timestamp: trade.timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.raw.data),
          isLiquidation: true
        };
      }
    }),
    shortPositionLiquidatedEvents.map(event => {
      // console.log("liquidator opened short position:", event.returnValues);
      // opened short
      const trade = event.returnValues;
      const shortSent = trade.shortSent.toString();
      if (shortSent !== "0") {
        const baseSent = trade.baseSent.toString();
        const collReceived = trade.collReceived.toString();
        return {
          marketId: trade.marketId.toString(),
          user: trade.liquidatorAccount,
          type: "short",
          isBuy: false,
          size: shortSent,
          baseAmount: baseSent / 1e18,
          basePrice: parseFloat(baseSent / 1e18 / (shortSent / 1e18)),
          collPrice: parseFloat(collReceived / 1e18 / (shortSent / 1e18)),
          timestamp: trade.timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.raw.data),
          isLiquidation: true
        };
      }
    }),
    shortPositionLiquidatedEvents.map(event => {
      // console.log("liquidatee closed short position:", event.returnValues);
      // closed short
      const trade = event.returnValues;
      const shortSent = trade.shortSent.toString();
      if (shortSent !== "0") {
        const baseSent = trade.baseSent.toString();
        const collReceived = trade.collReceived.toString();
        return {
          marketId: trade.marketId.toString(),
          user: trade.liquidatedAccount,
          type: "short",
          isBuy: true,
          size: shortSent,
          baseAmount: baseSent / 1e18,
          basePrice: parseFloat(baseSent / 1e18 / (shortSent / 1e18)),
          collPrice: parseFloat(collReceived / 1e18 / (shortSent / 1e18)),
          timestamp: trade.timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.raw.data),
          isLiquidation: true
        };
      }
    })
  ].flat();
  longShortTradesFromLiquidation = longShortTradesFromLiquidation.filter(lstfl => lstfl !== undefined);

  let lpTradesFromLPPositionLiquidation = [
    LPPositionLiquidatedEvents.map(event => {
      // TODO: v2 use bs parameters from event to calculate longPriceInBase (much cheaper than smart contract doing it)
      // const underPrice = trade.underPrice.toString() / 1e18;
      // const volatility = trade.volatility.toString() / 1e18;
      // const rate = trade.rate.toString() / 1e18;
      // get expiration time and strike from market

      const trade = event.returnValues;
      const liqSent = trade.liqSent.toString();
      if (liqSent !== "0") {
        const baseReceived = trade.baseReceived.toString();
        const collReceived = trade.collReceived.toString();
        const shortReceived = trade.shortReceived.toString();
        return {
          marketId: trade.marketId.toString(),
          user: trade.liquidatorAccount,
          positionId: trade.positionId.toString(),
          isBuy: true,
          size: liqSent,
          baseAmount: baseReceived / 1e18,
          basePrice: parseFloat(baseReceived / 1e18 / (liqSent / 1e18)),
          collPrice: parseFloat(collReceived / 1e18 / (liqSent / 1e18)),
          shortPrice: parseFloat(shortReceived / 1e18 / (liqSent / 1e18)),
          optionAmount: trade.shortReceived.toString(), // v1 optionAmount not correct, what is even this?
          longPriceInBase: trade.longPriceInBase.toString(),
          timestamp: trade.timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.raw.data),
          isLiquidation: true
        };
      }
    }),
    LPPositionLiquidatedEvents.map(event => {
      const trade = event.returnValues;
      const liqSent = trade.liqSent.toString();
      if (liqSent !== "0") {
        const baseReceived = trade.baseReceived.toString();
        const collReceived = trade.collReceived.toString();
        const shortReceived = trade.shortReceived.toString();

        return {
          marketId: trade.marketId.toString(),
          user: trade.liquidatedAccount,
          positionId: trade.positionId.toString(),
          isBuy: false,
          size: liqSent,
          baseAmount: trade.baseReceived.toString() / 1e18,
          basePrice: parseFloat(baseReceived / 1e18 / (liqSent / 1e18)),
          collPrice: parseFloat(collReceived / 1e18 / (liqSent / 1e18)),
          shortPrice: parseFloat(shortReceived / 1e18 / (liqSent / 1e18)),
          optionAmount: trade.shortReceived.toString(),
          longPriceInBase: trade.longPriceInBase.toString(),
          timestamp: trade.timestamp.toString(),
          signature: event.transactionHash + murmurhash(event.raw.data),
          isLiquidation: true
        };
      }
    })
  ].flat();
  lpTradesFromLPPositionLiquidation = lpTradesFromLPPositionLiquidation.filter(lptfl => lptfl !== undefined);

  const penaltyRewardTradesFromAccountLiquidation = [
    accountLiquidatedEvents.map(event => {
      const trade = event.returnValues;
      // console.log("Penalty: ", trade.penaltyInBase.toString() / 1e18);
      return {
        user: trade.liquidatedAccount,
        isBuy: false, // false is penalty, true is reward
        baseAmount: trade.penaltyInBase.toString() / 1e18,
        timestamp: trade.timestamp.toString(),
        signature: event.transactionHash + murmurhash(event.raw.data)
      };
    }),
    accountLiquidatedEvents.map(event => {
      const trade = event.returnValues;
      // console.log("Reward: ", trade.penaltyInBase.toString() / 1e18);
      return {
        user: trade.liquidatorAccount,
        isBuy: true, // false is penalty, true is reward
        baseAmount: trade.penaltyInBase.toString() / 1e18,
        timestamp: trade.timestamp.toString(),
        signature: event.transactionHash + murmurhash(event.raw.data)
      };
    })
  ].flat();

  // add lp trades from liquidation to lp trades
  longShortTrades = [...longShortTrades, ...longShortTradesFromLiquidation];
  lpTrades = [...lpTrades, ...lpTradesFromLPPositionLiquidation];
  const liquidationTrades = [...penaltyRewardTradesFromAccountLiquidation];
  return {
    longShortTrades,
    lpTrades,
    liquidationTrades
  };
};

const getBaseAmountAndBasePriceForLPPosition = (trade, isLPLiquidation = false) => {
  let baseAmount = BigNumber.from(trade.baseReceived.toString());
  let basePrice = toBn(trade.baseReceived.toString(), 18).div(BigNumber.from(trade.liqSent.toString()));
  let penalty = BigNumber.from((isLPLiquidation ? 300 * 1e18 : 0).toString());

  // todo: review: why are both longsUpdated and shortsUpdated multiplied wth longPriceInBase?
  // if shorts are received by closing lp position, adjust lp position
  if (trade.shortsUpdated != 0) {
    let baseAmountForShorts = BigNumber.from(trade.shortsUpdated.toString())
      .mul(BigNumber.from(trade.longPriceInBase.toString()))
      .div(BigNumber.from((1e18).toString()));
    baseAmount = baseAmount.sub(baseAmountForShorts).sub(penalty);
    basePrice = toBn(baseAmount.toString(), 18).div(BigNumber.from(trade.liqSent.toString()));
  }

  // if longs are received by closing lp position, adjust lp position
  if (trade.longsUpdated != 0) {
    let baseAmountForLongs = BigNumber.from(trade.longsUpdated.toString())
      .mul(BigNumber.from(trade.longPriceInBase.toString()))
      .div(BigNumber.from((1e18).toString()));
    baseAmount = baseAmount.add(baseAmountForLongs).sub(penalty);
    basePrice = toBn(baseAmount.toString(), 18).div(BigNumber.from(trade.liqSent.toString()));
  }

  return { baseAmount, basePrice };
};

// called for lp close or lp liquidation
export const getLPEventOnLPPositionClose = (event, isLPLiquidation = false) => {
  const trade = event.returnValues;

  if (trade.liqSent.toString() !== "0") {
    const baseArgs = getBaseAmountAndBasePriceForLPPosition(trade, isLPLiquidation);
    // const baseArgs2 = getBaseAmountAndBasePriceForLPPosition(trade, isLPLiquidation);

    const lpTrades = [
      {
        marketId: trade.marketId.toString(),
        user: trade.user,
        liquidator: trade.liquidator,
        positionId: trade.positionId.toString(),
        isBuy: false,
        size: trade.liqSent.toString(),
        baseAmount: baseArgs.baseAmount.toString() / 1e18, //TODO: this is temporary solution, we should update getBaseAmountAndBasePriceForLPPosition not to use BN
        basePrice: baseArgs.basePrice.toString() / 1e18,
        collPrice: parseFloat(trade.collReceived / 1e18 / (trade.liqSent / 1e18)),
        shortPrice: parseFloat(trade.shortReceived / 1e18 / (trade.liqSent / 1e18)),
        optionAmount: trade.shortReceived.toString(),
        longPriceInBase: trade.longPriceInBase,
        interest: trade.interest.toString(),
        timestamp: trade.timestamp.toString(),
        signature: event.transactionHash + murmurhash(event.raw.data)
      }
    ];

    // if lp position is liquidated, add two more events for penalty and reward
    if (isLPLiquidation) {
      const penaltyEvent = getPenaltyOrRewardEventOnLPPositionLiquidation(event, true);
      const rewardEvent = getPenaltyOrRewardEventOnLPPositionLiquidation(event, false);
      lpTrades.push(penaltyEvent);
      lpTrades.push(rewardEvent);
    }

    return lpTrades;
  }
};

const getPenaltyOrRewardEventOnLPPositionLiquidation = (event, isPenalty) => {
  const trade = event.hasOwnProperty("returnValues") ? event.returnValues : event.args;
  return {
    marketId: trade.marketId.toString(),
    user: isPenalty ? trade.user : trade.liquidator,
    positionId: trade.positionId.toString(),
    isBuy: isPenalty,
    type: "long",
    isPenaltyOrReward: true,
    size: 0,
    baseAmount: "300", // todo: v1 magic number
    basePrice: "300",
    collPrice: 0,
    shortPrice: 0,
    optionAmount: 0,
    longPriceInBase: 0,
    timestamp: trade.timestamp.toString(),
    signature: event.transactionHash + murmurhash(event.hasOwnProperty("raw") ? event.raw.data : event.data)
  };
};

export const getTradeEventOnLPPositionClose = (event, marketsImmutable) => {
  const trade = event.returnValues;
  const market = marketsImmutable.find(m => m.marketId.toString() === trade.marketId.toString());
  const strikePrice = formatUnits(market.strikePrice.toString(), 18);

  const shortsUpdated = trade.shortsUpdated.toString();
  if (trade.shortsUpdated != 0) {
    return {
      marketId: trade.marketId.toString(),
      user: trade.user,
      type: "short",
      isBuy: trade.shortsUpdated < 0,
      size: Math.abs(trade.shortsUpdated), // todo: v1 does Math.abs works with large numbers?
      baseAmount:
        BigNumber.from(shortsUpdated)
          .mul(BigNumber.from(trade.longPriceInBase.toString()))
          .div(BigNumber.from((1e18).toString()))
          .toString() / 1e18,
      basePrice: trade.longPriceInBase.toString() / 1e18,
      collPrice: market.isCall ? 1 : parseFloat(strikePrice),
      interest: trade.interest.toString(),
      timestamp: trade.timestamp.toString(),
      signature: event.transactionHash + murmurhash(event.raw.data)
    };
  }

  if (trade.longsUpdated != 0) {
    return {
      marketId: trade.marketId.toString(),
      user: trade.user,
      type: "long",
      isBuy: trade.longsUpdated > 0,
      size: Math.abs(trade.longsUpdated).toString(),
      baseAmount:
        BigNumber.from(trade.longsUpdated.toString())
          .mul(BigNumber.from(trade.longPriceInBase.toString()))
          .div(BigNumber.from((1e18).toString()))
          .toString() / 1e18,
      basePrice: trade.longPriceInBase.toString() / 1e18,
      collPrice: 0,
      timestamp: trade.timestamp.toString(),
      signature: event.transactionHash + murmurhash(event.raw.data)
    };
  }
};

export const subscribeToPositionsManagerEvents = async (
  provider,
  riskFreeRate,
  web3,
  positionsManager,
  lpManager,
  settlementManager,
  userAccount,
  marginPool,
  dispatch
) => {
  try {
    const positionsManagerAbi = [
      "event OpenedLongPosition(uint40 indexed marketId, address indexed user, uint128 baseSent, uint128 longReceived, uint32 timestamp)",
      "event ClosedLongPosition(uint40 indexed marketId, address indexed user, uint128 longSent, uint128 baseReceived, uint128 collReceived, uint128 interest, uint32 timestamp)",
      "event OpenedShortPosition(uint40 indexed marketId, address indexed user, uint128 collSent, uint128 baseReceived, uint128 shortReceived, uint32 timestamp)",
      "event ClosedShortPosition(uint40 indexed marketId, address indexed user, uint128 shortSent, uint128 baseSent, uint128 collReceived, uint128 interest, uint32 timestamp)"
    ];
    const lpManagerAbi = [
      "event ClosedLongPosition(uint40 indexed marketId, address indexed user, uint128 longSent, uint128 baseReceived, uint128 collReceived, uint128 interest, uint32 timestamp)",
      "event ClosedShortPosition(uint40 indexed marketId, address indexed user, uint128 shortSent, uint128 baseSent, uint128 collReceived, uint128 interest, uint32 timestamp)",

      "event OpenedMarketMakerPosition(uint40 indexed marketId, address indexed user, uint40 positionId, uint128 baseSent, uint128 collSent, uint128 liqReceived, uint128 shortSent, uint128 longPriceInBase, uint24 lower, uint24 upper, uint32 timestamp)",
      "event ClosedMarketMakerPosition(uint40 indexed marketId, address indexed user, uint40 positionId, uint128 liqSent, uint128 baseReceived, uint128 collReceived, uint128 shortReceived, int128 longsUpdated, int128 shortsUpdated, uint128 longPriceInBase, uint128 interest, uint32 timestamp)",

      "event LiquidatedMarketMakerPosition(uint40 indexed marketId, address indexed user, uint40 positionId, uint128 liqSent, uint128 baseReceived, uint128 collReceived, uint128 shortReceived, int128 longsUpdated, int128 shortsUpdated, uint128 longPriceInBase, uint128 interest, address liquidator, uint32 timestamp)",
      "event IncreaseReserves(uint40 indexed marketId, address indexed user, uint40 positionId, uint128 baseSent, uint32 timestamp)",
      "event DecreaseReserves(uint40 indexed marketId, address indexed user, uint40 positionId, uint128 baseReceived, uint32 timestamp)",
      "event ClosedMultiplePositions(bool areLPPositionsClosed, uint32 timestamp)"
    ];
    const positionsManagerContract = new Contract(positionsManager.options.address, positionsManagerAbi, provider);
    const lpManagerContract = new Contract(lpManager.options.address, lpManagerAbi, provider);
    const settlementManagerContract = new Contract(settlementManager.options.address, lpManagerAbi, provider); // using lpManagerAbi because it has all events we need

    settlementManagerContract.on("ClosedMultiplePositions", async (areLPPositionsClosed, timestamp, event) => {
      let notificationText = "Your positions are closed";
      if (areLPPositionsClosed) {
        notificationText = "Your LP positions are closed";
      }
      const notification = getNotificationData(notificationText, event.transactionHash, timestamp, "confirmed", -1);
      dispatch(confirmTransactionNotification(notification, userAccount));
      ding();
    });

    positionsManagerContract.on("OpenedLongPosition", async (marketId, user, baseSent, longReceived, timestamp, event) => {
      console.log("START onOpenLongPosition event handler");
      // check if received event already exists
      let { trades, tokenPairs, lpPositionTokens, contracts, marketsImmutable, tokens } = store.getState();
      const underPriceBN = tokenPairs.selectedTokenPair.avgUnderPrice;

      if (trades.loaded) {
        const existingTrade = trades.data.find(trade => trade.signature === event.transactionHash + murmurhash(event.data));
        if (existingTrade) {
          return;
        }
      }
      // check if for some reason longReceived is 0 (to avoid division by 0)
      if (longReceived.toString() === "0") return;
      // handle event created by any user
      const { loadHelper, userBalances } = contracts.data;
      const marketImmutableData = marketsImmutable.data.find(market => market.marketId === marketId.toString());
      const mutableMarketAttributes = await getMuttableMarketAttributes(loadHelper, userBalances, marketId.toString(), marketImmutableData, riskFreeRate);
      // if some user bought long on market where userAccount has lp position, then update lp position balance
      const positionTokens = lpPositionTokens.data.filter(t => t.accountBalance != "0" && marketId.toString() === t.marketId.toString());
      const positionIds = [];
      const positionDatas = [];
      const healths = [];
      for (let positionToken of positionTokens) {
        // loadAccountLPBalance(loadHelper, marketId.toString(), positionToken.positionId, userAccount, underPriceBN, dispatch);
        positionIds.push(positionToken.positionId);
        let health = 0;
        console.log("RPC method: loadHelper.getLPPositionData");
        const data = await loadHelper.methods.getLPPositionData(marketId.toString(), positionToken.positionId, userAccount, underPriceBN).call();
        if (data.liquidity.toString() !== "0") {
          health = parseFloat(formatUnits(data.health.toString(), 18));
        }
        positionDatas.push(data);
        healths.push(health);
      }
      const trade = {
        marketId: marketId.toString(),
        user: user,
        type: "long",
        isBuy: true,
        baseAmount: baseSent.toString() / 1e18,
        size: longReceived.toString(),
        basePrice: parseFloat(baseSent / 1e18 / (longReceived / 1e18)),
        collPrice: 0,
        timestamp: timestamp.toString(),
        signature: event.transactionHash + murmurhash(event.data)
      };
      const marginData = await loadMarginPoolData2(loadHelper, marginPool, underPriceBN, tokens);
      console.log("RPC method: loadHelper.getAssetAddresses");
      const marketImmutableParams = await loadHelper.methods.getAssetAddresses().call();
      const baseTokenAddress = marketImmutableParams.baseTokenAddress;
      const underTokenAddress = marketImmutableParams.underTokenAddress;
      let [
        tokenAccountBalanceBaseToken,
        assetWalletBalanceBaseToken,
        tokenAccountBalanceUnderToken,
        assetWalletBalanceUnderToken,
        longPositionTokenAccountBalance,
        shortPositionTokenAccountBalance,
        lpPositionTokenAccountBalance
      ] = [null, null, null, null, null, null, null];
      let marginAccountData = null;
      if (userAccount.toString() === user.toString()) {
        [
          tokenAccountBalanceBaseToken,
          assetWalletBalanceBaseToken,
          tokenAccountBalanceUnderToken,
          assetWalletBalanceUnderToken,
          longPositionTokenAccountBalance,
          shortPositionTokenAccountBalance,
          lpPositionTokenAccountBalance
        ] = await reloadMarketTokensBalances2(web3, marketId.toString(), userBalances, loadHelper, userAccount.toString(), underPriceBN);
        marginAccountData = await loadMarginAccountData2(userAccount, loadHelper, marginPool, userBalances, underPriceBN, tokens);
      }

      let startTime = new Date().getTime();
      batch(() => {
        dispatch(marketUpdated(mutableMarketAttributes));
        for (let i = 0; i < positionIds.length; i++) {
          dispatch(
            lpPositionTokenAccountBalanceLoaded(
              marketId.toString(),
              positionIds[i],
              positionDatas[i].liquidity.toString(),
              positionDatas[i].longBalance.toString(),
              positionDatas[i].baseBalance.toString(),
              positionDatas[i].feeBalance.toString(),
              parseInt(positionDatas[i].lower),
              parseInt(positionDatas[i].upper),
              positionDatas[i].sizeInLongs.toString(),
              positionDatas[i].shortBalance.toString(),
              healths[i]
            )
          );
        }
        dispatch(marginPoolDataLoaded(marginData));
        if (userAccount.toString() === user.toString()) {
          dispatch(tokenAccountBalanceLoaded(baseTokenAddress, tokenAccountBalanceBaseToken));
          dispatch(tokenWalletBalanceLoaded(baseTokenAddress, assetWalletBalanceBaseToken));
          dispatch(tokenAccountBalanceLoaded(underTokenAddress, tokenAccountBalanceUnderToken));
          dispatch(tokenWalletBalanceLoaded(underTokenAddress, assetWalletBalanceUnderToken));
          dispatch(positionTokenAccountBalanceLoaded(marketId.toString(), longPositionTokenAccountBalance));
          dispatch(positionTokenAccountBalanceLoaded((parseInt(marketId) + 1).toString(), shortPositionTokenAccountBalance));
          dispatch(marginAccountDataLoaded(marginAccountData));
        }
        dispatch(tradeAdded(trade));
      });
      let endTime = new Date().getTime();
      console.log("END onOpenedLongPosition batch, took:", endTime - startTime, "mS");
    });

    positionsManagerContract.on("ClosedLongPosition", async (marketId, user, longSent, baseReceived, collReceived, interest, timestamp, event) => {
      onCloseLongPosition(marketId.toString(), user, longSent, baseReceived, collReceived, interest, timestamp, event);
    });

    lpManagerContract.on("ClosedLongPosition", async (marketId, user, longSent, baseReceived, collReceived, interest, timestamp, event) => {
      onCloseLongPosition(marketId.toString(), user, longSent, baseReceived, collReceived, interest, timestamp, event);
    });

    settlementManagerContract.on("ClosedLongPosition", async (marketId, user, longSent, baseReceived, collReceived, interest, timestamp, event) => {
      onCloseLongPosition(marketId.toString(), user, longSent, baseReceived, collReceived, interest, timestamp, event);
    });

    positionsManagerContract.on("OpenedShortPosition", async (marketId, user, collSent, baseReceived, shortReceived, timestamp, event) => {
      // check if received event already exists
      let { trades, tokenPairs, lpPositionTokens, contracts, marketsImmutable, tokens } = store.getState();
      const underPriceBN = tokenPairs.selectedTokenPair.avgUnderPrice;

      if (trades.loaded) {
        const existingTrade = trades.data.find(trade => trade.signature === event.transactionHash + murmurhash(event.data));
        if (existingTrade) {
          return;
        }
      }
      // check if for some reason shortReceived is 0 (to avoid division by 0)
      if (shortReceived.toString() === "0") return;

      // handle event created by any user
      const { loadHelper, userBalances } = contracts.data;
      const marketImmutableData = marketsImmutable.data.find(market => market.marketId === marketId.toString());
      const mutableMarketAttributes = await getMuttableMarketAttributes(loadHelper, userBalances, marketId.toString(), marketImmutableData, riskFreeRate);

      // if some user bought long on market where userAccount has lp position, then update lp position balance
      const positionTokens = lpPositionTokens.data.filter(t => t.accountBalance != "0" && marketId.toString() === t.marketId.toString());
      const positionIds = [];
      const positionDatas = [];
      const healths = [];
      for (let positionToken of positionTokens) {
        positionIds.push(positionToken.positionId);
        let health = 0;
        const data = await loadHelper.methods.getLPPositionData(marketId.toString(), positionToken.positionId, userAccount, underPriceBN).call();
        if (data.liquidity.toString() !== "0") {
          health = parseFloat(formatUnits(data.health.toString(), 18));
        }
        positionDatas.push(data);
        healths.push(health);
      }
      const trade = {
        marketId: marketId.toString(),
        user: user,
        type: "short",
        isBuy: false,
        baseAmount: baseReceived.toString() / 1e18,
        size: shortReceived.toString(),
        basePrice: parseFloat(baseReceived / 1e18 / (shortReceived / 1e18)),
        collPrice: parseFloat(collSent / 1e18 / (shortReceived / 1e18)),
        timestamp: timestamp.toString(),
        signature: event.transactionHash + murmurhash(event.data)
      };

      const marginData = await loadMarginPoolData2(loadHelper, marginPool, underPriceBN, tokens);
      const marketImmutableParams = await loadHelper.methods.getAssetAddresses().call();
      const baseTokenAddress = marketImmutableParams.baseTokenAddress;
      const underTokenAddress = marketImmutableParams.underTokenAddress;
      let [
        tokenAccountBalanceBaseToken,
        assetWalletBalanceBaseToken,
        tokenAccountBalanceUnderToken,
        assetWalletBalanceUnderToken,
        longPositionTokenAccountBalance,
        shortPositionTokenAccountBalance,
        lpPositionTokenAccountBalance
      ] = [null, null, null, null, null, null, null];
      let marginAccountData = null;
      if (userAccount.toString() === user.toString()) {
        [
          tokenAccountBalanceBaseToken,
          assetWalletBalanceBaseToken,
          tokenAccountBalanceUnderToken,
          assetWalletBalanceUnderToken,
          longPositionTokenAccountBalance,
          shortPositionTokenAccountBalance,
          lpPositionTokenAccountBalance
        ] = await reloadMarketTokensBalances2(web3, marketId.toString(), userBalances, loadHelper, userAccount.toString(), underPriceBN);
        marginAccountData = await loadMarginAccountData2(userAccount, loadHelper, marginPool, userBalances, underPriceBN, tokens);
      }

      batch(() => {
        dispatch(marketUpdated(mutableMarketAttributes));
        for (let i = 0; i < positionIds.length; i++) {
          dispatch(
            lpPositionTokenAccountBalanceLoaded(
              marketId.toString(),
              positionIds[i],
              positionDatas[i].liquidity.toString(),
              positionDatas[i].longBalance.toString(),
              positionDatas[i].baseBalance.toString(),
              positionDatas[i].feeBalance.toString(),
              parseInt(positionDatas[i].lower),
              parseInt(positionDatas[i].upper),
              positionDatas[i].sizeInLongs.toString(),
              positionDatas[i].shortBalance.toString(),
              healths[i]
            )
          );
        }
        dispatch(marginPoolDataLoaded(marginData));
        if (userAccount.toString() === user.toString()) {
          dispatch(tokenAccountBalanceLoaded(baseTokenAddress, tokenAccountBalanceBaseToken));
          dispatch(tokenWalletBalanceLoaded(baseTokenAddress, assetWalletBalanceBaseToken));
          dispatch(tokenAccountBalanceLoaded(underTokenAddress, tokenAccountBalanceUnderToken));
          dispatch(tokenWalletBalanceLoaded(underTokenAddress, assetWalletBalanceUnderToken));
          dispatch(positionTokenAccountBalanceLoaded(marketId.toString(), longPositionTokenAccountBalance));
          dispatch(positionTokenAccountBalanceLoaded((parseInt(marketId) + 1).toString(), shortPositionTokenAccountBalance));
          dispatch(marginAccountDataLoaded(marginAccountData));
        }
        dispatch(tradeAdded(trade));
      });
    });

    positionsManagerContract.on("ClosedShortPosition", async (marketId, user, shortSent, baseSent, collReceived, interest, timestamp, event) => {
      onCloseShortPosition(marketId.toString(), user, shortSent, baseSent, collReceived, timestamp, interest, event);
    });

    lpManagerContract.on("ClosedShortPosition", async (marketId, user, shortSent, baseSent, collReceived, interest, timestamp, event) => {
      onCloseShortPosition(marketId.toString(), user, shortSent, baseSent, collReceived, timestamp, interest, event);
    });

    settlementManagerContract.on("ClosedShortPosition", async (marketId, user, shortSent, baseSent, collReceived, interest, timestamp, event) => {
      onCloseShortPosition(marketId.toString(), user, shortSent, baseSent, collReceived, timestamp, interest, event);
    });

    lpManagerContract.on(
      "OpenedMarketMakerPosition",
      async (marketId, user, positionId, baseSent, collSent, liqReceived, shortSent, longPriceInBase, lower, upper, timestamp, event) => {
        // check if received event already exists
        let { lpTrades, tokenPairs, contracts, marketsImmutable, tokens } = store.getState();
        const underPriceBN = tokenPairs.selectedTokenPair.avgUnderPrice;

        if (lpTrades.loaded) {
          const existingTrade = lpTrades.data.find(trade => trade.signature === event.transactionHash + murmurhash(event.data));
          if (existingTrade) {
            return;
          }
        }

        // check if for some reason liqReceived is 0 (to avoid division by 0)
        if (liqReceived.toString() === "0") return;
        // handle event created by any user
        const { loadHelper, userBalances } = contracts.data;
        const marketImmutableData = marketsImmutable.data.find(market => market.marketId === marketId.toString());

        // check if there is a market in state (happens when bot is creating markets fast, we receive event before MarketCreated event)
        if (!marketImmutableData) return;

        let { isCall, strikePrice, expirationTime } = marketImmutableData;
        const normalizedStrikePrice = parseFloat(formatUnits(strikePrice.toString(), 18));
        const attr = getTokenNames(isCall, normalizedStrikePrice, expirationTime);

        const name = "LP" + positionId + attr.longTokenName.substring(4, attr.longTokenName.length);
        const symbol = "LP" + positionId + attr.longTokenName.substring(4, attr.longTokenName.length);
        const splits = name.split("-");
        let shortName = "";
        const isFuture = marketImmutableData.isFuture;
        if (!isFuture) {
          shortName = "LP" + positionId + "-" + splits[1] + "-" + splits[2] + "-" + splits[3] + "-" + splits[4];
        } else {
          shortName = "LP" + positionId + "-" + splits[1] + "-" + splits[2] + "-" + splits[3];
        }

        const [mutableMarketAttributes, marginPoolData] = await Promise.all([
          getMuttableMarketAttributes(loadHelper, userBalances, marketId, marketImmutableData, riskFreeRate),
          loadMarginPoolData2(loadHelper, marginPool, underPriceBN, tokens)
        ]);
        const marketImmutableParams = await loadHelper.methods.getAssetAddresses().call();
        const baseTokenAddress = marketImmutableParams.baseTokenAddress;
        const underTokenAddress = marketImmutableParams.underTokenAddress;

        let [
          tokenAccountBalanceBaseToken,
          assetWalletBalanceBaseToken,
          tokenAccountBalanceUnderToken,
          assetWalletBalanceUnderToken,
          longPositionTokenAccountBalance,
          shortPositionTokenAccountBalance,
          lpPositionTokenAccountBalance
        ] = [null, null, null, null, null, null, null];

        let marginAccountData = null;
        if (userAccount === user) {
          [
            tokenAccountBalanceBaseToken,
            assetWalletBalanceBaseToken,
            tokenAccountBalanceUnderToken,
            assetWalletBalanceUnderToken,
            longPositionTokenAccountBalance,
            shortPositionTokenAccountBalance,
            lpPositionTokenAccountBalance
          ] = await reloadMarketTokensBalances2(web3, marketId.toString(), userBalances, loadHelper, userAccount, underPriceBN, positionId);
          marginAccountData = await loadMarginAccountData2(userAccount, loadHelper, marginPool, userBalances, underPriceBN, tokens);
        }

        batch(() => {
          dispatch(marketUpdated(mutableMarketAttributes));
          dispatch(
            lpTokenAddedOrUpdated({
              marketId: marketId.toString(),
              positionId: positionId.toString(),
              name,
              shortName,
              symbol,
              accountBalance: 0
            })
          );
          dispatch(marginPoolDataLoaded(marginPoolData));
          dispatch(
            lpTradeAdded({
              marketId: marketId.toString(),
              user: user,
              positionId: positionId.toString(),
              isBuy: true,
              baseAmount: baseSent.toString() / 1e18,
              size: liqReceived.toString(),
              basePrice: parseFloat(baseSent / 1e18 / (liqReceived / 1e18)),
              collPrice: parseFloat(collSent / 1e18 / (liqReceived / 1e18)),
              shortPrice: parseFloat(shortSent / 1e18 / (liqReceived / 1e18)),
              optionAmount: shortSent.toString(),
              longPriceInBase: longPriceInBase.toString(),
              lower: lower,
              upper: upper,
              timestamp: timestamp.toString(),
              signature: event.transactionHash + murmurhash(event.data)
            })
          );
          if (userAccount === user) {
            dispatch(tokenAccountBalanceLoaded(baseTokenAddress, tokenAccountBalanceBaseToken));
            dispatch(tokenWalletBalanceLoaded(baseTokenAddress, assetWalletBalanceBaseToken));
            dispatch(tokenAccountBalanceLoaded(underTokenAddress, tokenAccountBalanceUnderToken));
            dispatch(tokenWalletBalanceLoaded(underTokenAddress, assetWalletBalanceUnderToken));
            dispatch(positionTokenAccountBalanceLoaded(marketId.toString(), longPositionTokenAccountBalance));
            dispatch(positionTokenAccountBalanceLoaded((parseInt(marketId) + 1).toString(), shortPositionTokenAccountBalance));
            dispatch(lpPositionTokenAccountBalanceLoaded(...Object.values(lpPositionTokenAccountBalance)));
            dispatch(marginAccountDataLoaded(marginAccountData));
          }
        });
      }
    );

    lpManagerContract.on(
      "ClosedMarketMakerPosition",
      async (
        marketId,
        user,
        positionId,
        liqSent,
        baseReceived,
        collReceived,
        shortReceived,
        longsUpdated,
        shortsUpdated,
        longPriceInBase,
        interest,
        timestamp,
        event
      ) => {
        onCloseMarketMakerPosition(
          marketId.toString(),
          user,
          positionId,
          liqSent,
          baseReceived,
          collReceived,
          shortReceived,
          longsUpdated,
          shortsUpdated,
          longPriceInBase,
          interest,
          timestamp,
          event
        );
      }
    );

    settlementManagerContract.on(
      "ClosedMarketMakerPosition",
      async (
        marketId,
        user,
        positionId,
        liqSent,
        baseReceived,
        collReceived,
        shortReceived,
        longsUpdated,
        shortsUpdated,
        longPriceInBase,
        interest,
        timestamp,
        event
      ) => {
        onCloseMarketMakerPosition(
          marketId.toString(),
          user,
          positionId,
          liqSent,
          baseReceived,
          collReceived,
          shortReceived,
          longsUpdated,
          shortsUpdated,
          longPriceInBase,
          interest,
          timestamp,
          event
        );
      }
    );

    lpManagerContract.on("IncreaseReserves", async (marketId, user, positionId, baseSent, timestamp, event) => {
      // check if received event already exists
      let { lpTrades, tokenPairs, contracts, marketsImmutable, tokens } = store.getState();
      const underPriceBN = tokenPairs.selectedTokenPair.avgUnderPrice;
      if (lpTrades.loaded) {
        const existingTrade = lpTrades.data.find(trade => trade.signature === event.transactionHash + murmurhash(event.data));
        if (existingTrade) {
          return;
        }
      }

      const { loadHelper, userBalances } = contracts.data;
      const marketImmutableData = marketsImmutable.data.find(market => market.marketId === marketId.toString());
      // check if there is a market in state (happens when bot is creating markets fast, we receive event before MarketCreated event)
      if (!marketImmutableData) return;

      let { isCall, strikePrice, expirationTime } = marketImmutableData;
      const normalizedStrikePrice = parseFloat(formatUnits(strikePrice.toString(), 18));
      const attr = getTokenNames(isCall, normalizedStrikePrice, expirationTime);

      const name = "LP" + positionId + attr.longTokenName.substring(4, attr.longTokenName.length);
      const symbol = "LP" + positionId + attr.longTokenName.substring(4, attr.longTokenName.length);
      const splits = name.split("-");
      let shortName = "";
      const isFuture = marketImmutableData.isFuture;
      if (!isFuture) {
        shortName = "LP" + positionId + "-" + splits[1] + "-" + splits[2] + "-" + splits[3] + "-" + splits[4];
      } else {
        shortName = "LP" + positionId + "-" + splits[1] + "-" + splits[2] + "-" + splits[3];
      }

      const [mutableMarketAttributes, marginPoolData] = await Promise.all([
        getMuttableMarketAttributes(loadHelper, userBalances, marketId, marketImmutableData, riskFreeRate),
        loadMarginPoolData2(loadHelper, marginPool, underPriceBN, tokens)
      ]);
      const marketImmutableParams = await loadHelper.methods.getAssetAddresses().call();
      const baseTokenAddress = marketImmutableParams.baseTokenAddress;
      const underTokenAddress = marketImmutableParams.underTokenAddress;

      let [
        tokenAccountBalanceBaseToken,
        assetWalletBalanceBaseToken,
        tokenAccountBalanceUnderToken,
        assetWalletBalanceUnderToken,
        longPositionTokenAccountBalance,
        shortPositionTokenAccountBalance,
        lpPositionTokenAccountBalance
      ] = [null, null, null, null, null, null, null];

      let marginAccountData = null;
      if (userAccount === user) {
        [
          tokenAccountBalanceBaseToken,
          assetWalletBalanceBaseToken,
          tokenAccountBalanceUnderToken,
          assetWalletBalanceUnderToken,
          longPositionTokenAccountBalance,
          shortPositionTokenAccountBalance,
          lpPositionTokenAccountBalance
        ] = await reloadMarketTokensBalances2(web3, marketId.toString(), userBalances, loadHelper, userAccount, underPriceBN, positionId);
        marginAccountData = await loadMarginAccountData2(userAccount, loadHelper, marginPool, userBalances, underPriceBN, tokens);
      }

      batch(() => {
        dispatch(marketUpdated(mutableMarketAttributes));
        dispatch(
          lpTokenAddedOrUpdated({
            marketId: marketId.toString(),
            positionId: positionId.toString(),
            name,
            shortName,
            symbol,
            accountBalance: 0
          })
        );
        dispatch(marginPoolDataLoaded(marginPoolData));
        dispatch(
          lpTradeAdded({
            marketId: marketId.toString(),
            user: userAccount,
            positionId: positionId.toString(),
            isBuy: true,
            size: 0,
            baseAmount: baseSent.toString() / 1e18,
            basePrice: baseSent.toString() / 1e18,
            collPrice: 0,
            shortPrice: 0,
            optionAmount: 0,
            longPriceInBase: 0,
            timestamp: timestamp.toString(),
            signature: event.transactionHash + murmurhash(event.data)
          })
        );
        if (userAccount === user) {
          dispatch(tokenAccountBalanceLoaded(baseTokenAddress, tokenAccountBalanceBaseToken));
          dispatch(tokenWalletBalanceLoaded(baseTokenAddress, assetWalletBalanceBaseToken));
          dispatch(tokenAccountBalanceLoaded(underTokenAddress, tokenAccountBalanceUnderToken));
          dispatch(tokenWalletBalanceLoaded(underTokenAddress, assetWalletBalanceUnderToken));
          dispatch(positionTokenAccountBalanceLoaded(marketId.toString(), longPositionTokenAccountBalance));
          dispatch(positionTokenAccountBalanceLoaded((parseInt(marketId) + 1).toString(), shortPositionTokenAccountBalance));
          dispatch(lpPositionTokenAccountBalanceLoaded(...Object.values(lpPositionTokenAccountBalance)));
          dispatch(marginAccountDataLoaded(marginAccountData));
        }
      });
    });

    lpManagerContract.on("DecreaseReserves", async (marketId, user, positionId, baseReceived, timestamp, event) => {
      // check if received event already exists
      let { lpTrades, tokenPairs, contracts, marketsImmutable, tokens } = store.getState();
      const underPriceBN = tokenPairs.selectedTokenPair.avgUnderPrice;

      if (lpTrades.loaded) {
        const existingTrade = lpTrades.data.find(trade => trade.signature === event.transactionHash + murmurhash(event.data));
        if (existingTrade) {
          return;
        }
      }

      const { loadHelper, userBalances } = contracts.data;
      const marketImmutableData = marketsImmutable.data.find(market => market.marketId === marketId.toString());
      // check if there is a market in state (happens when bot is creating markets fast, we receive event before MarketCreated event)
      if (!marketImmutableData) return;

      let { isCall, strikePrice, expirationTime } = marketImmutableData;
      const normalizedStrikePrice = parseFloat(formatUnits(strikePrice.toString(), 18));
      const attr = getTokenNames(isCall, normalizedStrikePrice, expirationTime);

      const name = "LP" + positionId + attr.longTokenName.substring(4, attr.longTokenName.length);
      const symbol = "LP" + positionId + attr.longTokenName.substring(4, attr.longTokenName.length);
      const splits = name.split("-");
      let shortName = "";
      const isFuture = marketImmutableData.isFuture;
      if (!isFuture) {
        shortName = "LP" + positionId + "-" + splits[1] + "-" + splits[2] + "-" + splits[3] + "-" + splits[4];
      } else {
        shortName = "LP" + positionId + "-" + splits[1] + "-" + splits[2] + "-" + splits[3];
      }

      const [mutableMarketAttributes, marginPoolData] = await Promise.all([
        getMuttableMarketAttributes(loadHelper, userBalances, marketId, marketImmutableData, riskFreeRate),
        loadMarginPoolData2(loadHelper, marginPool, underPriceBN, tokens)
      ]);
      const marketImmutableParams = await loadHelper.methods.getAssetAddresses().call();
      const baseTokenAddress = marketImmutableParams.baseTokenAddress;
      const underTokenAddress = marketImmutableParams.underTokenAddress;

      let [
        tokenAccountBalanceBaseToken,
        assetWalletBalanceBaseToken,
        tokenAccountBalanceUnderToken,
        assetWalletBalanceUnderToken,
        longPositionTokenAccountBalance,
        shortPositionTokenAccountBalance,
        lpPositionTokenAccountBalance
      ] = [null, null, null, null, null, null, null];

      let marginAccountData = null;
      if (userAccount === user) {
        [
          tokenAccountBalanceBaseToken,
          assetWalletBalanceBaseToken,
          tokenAccountBalanceUnderToken,
          assetWalletBalanceUnderToken,
          longPositionTokenAccountBalance,
          shortPositionTokenAccountBalance,
          lpPositionTokenAccountBalance
        ] = await reloadMarketTokensBalances2(web3, marketId.toString(), userBalances, loadHelper, userAccount, underPriceBN, positionId);
        marginAccountData = await loadMarginAccountData2(userAccount, loadHelper, marginPool, userBalances, underPriceBN, tokens);
      }

      batch(() => {
        dispatch(marketUpdated(mutableMarketAttributes));
        dispatch(
          lpTokenAddedOrUpdated({
            marketId: marketId.toString(),
            positionId: positionId.toString(),
            name,
            shortName,
            symbol,
            accountBalance: 0
          })
        );
        dispatch(marginPoolDataLoaded(marginPoolData));
        dispatch(
          lpTradeAdded({
            marketId: marketId.toString(),
            user: userAccount,
            positionId: positionId.toString(),
            isBuy: false,
            size: 0,
            baseAmount: baseReceived.toString() / 1e18,
            basePrice: baseReceived.toString() / 1e18,
            collPrice: 0,
            shortPrice: 0,
            optionAmount: 0,
            longPriceInBase: 0,
            timestamp: timestamp.toString(),
            signature: event.transactionHash + murmurhash(event.data)
          })
        );
        if (userAccount === user) {
          dispatch(tokenAccountBalanceLoaded(baseTokenAddress, tokenAccountBalanceBaseToken));
          dispatch(tokenWalletBalanceLoaded(baseTokenAddress, assetWalletBalanceBaseToken));
          dispatch(tokenAccountBalanceLoaded(underTokenAddress, tokenAccountBalanceUnderToken));
          dispatch(tokenWalletBalanceLoaded(underTokenAddress, assetWalletBalanceUnderToken));
          dispatch(positionTokenAccountBalanceLoaded(marketId.toString(), longPositionTokenAccountBalance));
          dispatch(positionTokenAccountBalanceLoaded((parseInt(marketId) + 1).toString(), shortPositionTokenAccountBalance));
          dispatch(lpPositionTokenAccountBalanceLoaded(...Object.values(lpPositionTokenAccountBalance)));
          dispatch(marginAccountDataLoaded(marginAccountData));
        }
      });
    });

    lpManagerContract.on(
      "LiquidatedMarketMakerPosition",
      async (
        marketId,
        user,
        positionId,
        liqSent,
        baseReceived,
        collReceived,
        shortReceived,
        longsUpdated,
        shortsUpdated,
        longPriceInBase,
        interest,
        liquidator,
        timestamp,
        event
      ) => {
        // check if received event already exists
        let { lpTrades, tokenPairs, marginEvents, contracts, marketsImmutable, tokens } = store.getState();
        // const underPriceBN = tokenPairs.selectedTokenPair.underlyingTokenPrice;
        const underPriceBN = tokenPairs.selectedTokenPair.avgUnderPrice;

        if (lpTrades.loaded) {
          const existingTrade = lpTrades.data.find(trade => trade.signature === event.transactionHash + murmurhash(event.data));
          if (existingTrade) {
            return;
          }
        }

        // check if for some reason liqSent is 0 (to avoid division by 0)
        if (liqSent.toString() === "0") return;

        const { loadHelper, userBalances } = contracts.data;
        const marketImmutableData = marketsImmutable.data.find(market => market.marketId === marketId.toString());
        // check if there is a market in state (happens when bot is creating markets fast, we receive event before MarketCreated event)
        if (!marketImmutableData) return;

        let { isCall, strikePrice, expirationTime } = marketImmutableData;
        const normalizedStrikePrice = parseFloat(formatUnits(strikePrice.toString(), 18));
        const attr = getTokenNames(isCall, normalizedStrikePrice, expirationTime);

        const [mutableMarketAttributes, marginPoolData] = await Promise.all([
          getMuttableMarketAttributes(loadHelper, userBalances, marketId, marketImmutableData, riskFreeRate),
          loadMarginPoolData2(loadHelper, marginPool, underPriceBN, tokens)
        ]);

        const name = "LP" + positionId + attr.longTokenName.substring(4, attr.longTokenName.length);
        const symbol = "LP" + positionId + attr.longTokenName.substring(4, attr.longTokenName.length);
        const splits = name.split("-");
        let shortName = "";
        const isFuture = marketImmutableData.isFuture;
        if (!isFuture) {
          shortName = "LP" + positionId + "-" + splits[1] + "-" + splits[2] + "-" + splits[3] + "-" + splits[4];
        } else {
          shortName = "LP" + positionId + "-" + splits[1] + "-" + splits[2] + "-" + splits[3];
        }

        const marketImmutableParams = await loadHelper.methods.getAssetAddresses().call();
        const baseTokenAddress = marketImmutableParams.baseTokenAddress;
        const underTokenAddress = marketImmutableParams.underTokenAddress;

        let [
          tokenAccountBalanceBaseToken,
          assetWalletBalanceBaseToken,
          tokenAccountBalanceUnderToken,
          assetWalletBalanceUnderToken,
          longPositionTokenAccountBalance,
          shortPositionTokenAccountBalance,
          lpPositionTokenAccountBalance
        ] = [null, null, null, null, null, null, null];

        let marginAccountData = null;
        if (userAccount === liquidator) {
          [
            tokenAccountBalanceBaseToken,
            assetWalletBalanceBaseToken,
            tokenAccountBalanceUnderToken,
            assetWalletBalanceUnderToken,
            longPositionTokenAccountBalance,
            shortPositionTokenAccountBalance,
            lpPositionTokenAccountBalance
          ] = await reloadMarketTokensBalances2(web3, marketId.toString(), userBalances, loadHelper, liquidator, underPriceBN, positionId);
          marginAccountData = await loadMarginAccountData2(liquidator, loadHelper, marginPool, userBalances, underPriceBN, tokens);
        }

        batch(() => {
          dispatch(marketUpdated(mutableMarketAttributes));
          dispatch(
            lpTokenAddedOrUpdated({
              marketId: marketId.toString(),
              positionId: positionId.toString(),
              name,
              shortName,
              symbol,
              accountBalance: 0
            })
          );
          dispatch(marginPoolDataLoaded(marginPoolData));
          dispatch(
            lpTradeAdded({
              marketId: marketId.toString(),
              user: user,
              liquidator: liquidator,
              positionId: positionId.toString(),
              isBuy: false,
              baseAmount: baseReceived.toString() / 1e18,
              size: liqSent.toString(),
              basePrice: parseFloat(baseReceived / 1e18 / (liqSent / 1e18)),
              collPrice: parseFloat(collReceived / 1e18 / (liqSent / 1e18)),
              shortPrice: parseFloat(shortReceived / 1e18 / (liqSent / 1e18)),
              longPriceInBase: longPriceInBase.toString(),
              optionAmount: shortReceived.toString(),
              timestamp: timestamp.toString(),
              signature: event.transactionHash + murmurhash(event.data)
            })
          );
          // in case my LP position was liquidated, and I was left with some short tokens, those short token borrows correspond to borrows
          // done when opening the LP position, the only difference is the amount, which is remaining shorts that I am left with
          if (marginEvents && marginEvents.borrowEvents && marginEvents.borrowEvents.length > 0) {
            if (shortsUpdated > 0) {
              const borrowEvent = marginEvents.borrowEvents.find(
                event => event.borrower === user && event.marketId == marketId && event.positionId == positionId
              );
              if (borrowEvent) {
                dispatch(
                  borrowEventAdded({
                    borrower: borrowEvent.borrower,
                    assetAddress: borrowEvent.assetAddress,
                    amount: shortsUpdated.toString(),
                    marketId: borrowEvent.marketId,
                    borrowIndex: borrowEvent.borrowIndex,
                    isLPPosition: false,
                    positionId: 0,
                    timestamp: borrowEvent.timestamp
                  })
                );
              }
            }
          }
          if (userAccount === liquidator) {
            dispatch(tokenAccountBalanceLoaded(baseTokenAddress, tokenAccountBalanceBaseToken));
            dispatch(tokenWalletBalanceLoaded(baseTokenAddress, assetWalletBalanceBaseToken));
            dispatch(tokenAccountBalanceLoaded(underTokenAddress, tokenAccountBalanceUnderToken));
            dispatch(tokenWalletBalanceLoaded(underTokenAddress, assetWalletBalanceUnderToken));
            dispatch(positionTokenAccountBalanceLoaded(marketId.toString(), longPositionTokenAccountBalance));
            dispatch(positionTokenAccountBalanceLoaded((parseInt(marketId) + 1).toString(), shortPositionTokenAccountBalance));
            dispatch(lpPositionTokenAccountBalanceLoaded(...Object.values(lpPositionTokenAccountBalance)));
            dispatch(marginAccountDataLoaded(marginAccountData));
          } else if (userAccount === user) {
            const penaltyEventTrade = getPenaltyOrRewardEventOnLPPositionLiquidation(event, true);
            dispatch(lpTradeAdded(penaltyEventTrade));
            const shortAccount = toShortAddress(liquidator);
            const notification = {
              quantity: `Your position is liquidated by ${shortAccount}`,
              tx: event.transactionHash,
              timestamp: parseInt(Date.now() / 1000),
              status: "confirmed",
              nonce: -1
            };
            dispatch(confirmTransactionNotification(notification, userAccount));
            ding();
          }
        });
      }
    );

    const onCloseLongPosition = async (marketId, user, longSent, baseReceived, collReceived, interest, timestamp, event) => {
      // check if received event already exists
      let { trades, tokenPairs, lpPositionTokens, tokens } = store.getState();
      const underPriceBN = tokenPairs.selectedTokenPair.underlyingTokenPrice;
      const eventSignature = event.transactionHash + murmurhash(event.data);

      if (trades.loaded) {
        const existingTrade = trades.data.find(trade => trade.signature === eventSignature);
        if (existingTrade) {
          return;
        }
      }

      // check if for some reason longSent is 0 (to avoid division by 0)
      if (longSent.toString() === "0") return;

      const { loadHelper, userBalances } = store.getState().contracts.data;
      const { marketsImmutable } = store.getState();
      const marketImmutableData = marketsImmutable.data.find(market => market.marketId === marketId.toString());
      // check if there is a market in state (happens when bot is creating markets fast, we receive event before MarketCreated event)
      if (!marketImmutableData) return;

      const mutableMarketAttributes = await getMuttableMarketAttributes(loadHelper, userBalances, marketId, marketImmutableData, riskFreeRate);

      // if some user bought long on market where userAccount has lp position, then update lp position balance
      const positionTokens = lpPositionTokens.data.filter(t => t.accountBalance != "0" && marketId.toString() === t.marketId.toString());
      const positionIds = [];
      const positionDatas = [];
      const healths = [];
      for (let positionToken of positionTokens) {
        positionIds.push(positionToken.positionId);
        let health = 0;

        console.log("RPC method: loadHelper.getLPPositionData");
        const data = await loadHelper.methods.getLPPositionData(marketId, positionToken.positionId, userAccount, underPriceBN).call();
        if (data.liquidity.toString() !== "0") {
          health = parseFloat(formatUnits(data.health.toString(), 18));
        }

        positionDatas.push(data);
        healths.push(health);
      }

      const trade = {
        marketId: marketId.toString(),
        user: user,
        type: "long",
        isBuy: false,
        baseAmount: baseReceived.toString() / 1e18,
        size: longSent.toString(),
        basePrice: parseFloat(baseReceived / 1e18 / (longSent / 1e18)),
        collPrice: parseFloat(collReceived / 1e18 / (longSent / 1e18)),
        interest: interest,
        timestamp: timestamp.toString(),
        signature: eventSignature
      };

      const marginData = await loadMarginPoolData2(loadHelper, marginPool, underPriceBN, tokens);
      console.log("RPC method: loadHelper.getAssetAddresses");
      const marketImmutableParams = await loadHelper.methods.getAssetAddresses().call();
      const baseTokenAddress = marketImmutableParams.baseTokenAddress;
      const underTokenAddress = marketImmutableParams.underTokenAddress;

      let [
        tokenAccountBalanceBaseToken,
        assetWalletBalanceBaseToken,
        tokenAccountBalanceUnderToken,
        assetWalletBalanceUnderToken,
        longPositionTokenAccountBalance,
        shortPositionTokenAccountBalance,
        lpPositionTokenAccountBalance
      ] = [null, null, null, null, null, null, null];

      let marginAccountData = null;
      if (userAccount === user) {
        [
          tokenAccountBalanceBaseToken,
          assetWalletBalanceBaseToken,
          tokenAccountBalanceUnderToken,
          assetWalletBalanceUnderToken,
          longPositionTokenAccountBalance,
          shortPositionTokenAccountBalance,
          lpPositionTokenAccountBalance
        ] = await reloadMarketTokensBalances2(web3, marketId.toString(), userBalances, loadHelper, userAccount, underPriceBN);
        marginAccountData = await loadMarginAccountData2(userAccount, loadHelper, marginPool, userBalances, underPriceBN, tokens);
      }
      batch(() => {
        dispatch(marketUpdated(mutableMarketAttributes));
        for (let i = 0; i < positionIds.length; i++) {
          dispatch(
            lpPositionTokenAccountBalanceLoaded(
              marketId.toString(),
              positionIds[i],
              positionDatas[i].liquidity.toString(),
              positionDatas[i].longBalance.toString(),
              positionDatas[i].baseBalance.toString(),
              positionDatas[i].feeBalance.toString(),
              parseInt(positionDatas[i].lower),
              parseInt(positionDatas[i].upper),
              positionDatas[i].sizeInLongs.toString(),
              positionDatas[i].shortBalance.toString(),
              healths[i]
            )
          );
        }
        dispatch(marginPoolDataLoaded(marginData));
        if (userAccount === user) {
          dispatch(tokenAccountBalanceLoaded(baseTokenAddress, tokenAccountBalanceBaseToken));
          dispatch(tokenWalletBalanceLoaded(baseTokenAddress, assetWalletBalanceBaseToken));
          dispatch(tokenAccountBalanceLoaded(underTokenAddress, tokenAccountBalanceUnderToken));
          dispatch(tokenWalletBalanceLoaded(underTokenAddress, assetWalletBalanceUnderToken));
          dispatch(positionTokenAccountBalanceLoaded(marketId, longPositionTokenAccountBalance));
          dispatch(positionTokenAccountBalanceLoaded((parseInt(marketId) + 1).toString(), shortPositionTokenAccountBalance));
          dispatch(marginAccountDataLoaded(marginAccountData));
        }
        dispatch(tradeAdded(trade));
      });
    };

    const onCloseShortPosition = async (marketId, user, shortSent, baseSent, collReceived, timestamp, interest, event) => {
      // check if received event already exists
      let { trades, tokenPairs, lpPositionTokens, contracts, marketsImmutable, tokens } = store.getState();
      const underPriceBN = tokenPairs.selectedTokenPair.underlyingTokenPrice;

      if (trades.loaded) {
        const existingTrade = trades.data.find(trade => trade.signature === event.transactionHash + murmurhash(event.data));
        if (existingTrade) {
          return;
        }
      }

      // check if for some reason shortSent is 0 (to avoid division by 0)
      if (shortSent.toString() === "0") return;

      // handle event created by any user
      const { loadHelper, userBalances } = contracts.data;
      const marketImmutableData = marketsImmutable.data.find(market => market.marketId === marketId.toString());
      // check if there is a market in state (happens when bot is creating markets fast, we receive event before MarketCreated event)
      if (!marketImmutableData) return;

      const mutableMarketAttributes = await getMuttableMarketAttributes(loadHelper, userBalances, marketId, marketImmutableData, riskFreeRate);

      // if some user bought long on market where userAccount has lp position, then update lp position balance
      const positionTokens = lpPositionTokens.data.filter(t => t.accountBalance != "0" && marketId.toString() === t.marketId.toString());
      const positionIds = [];
      const positionDatas = [];
      const healths = [];
      for (let positionToken of positionTokens) {
        positionIds.push(positionToken.positionId);
        let health = 0;

        const data = await loadHelper.methods.getLPPositionData(marketId, positionToken.positionId, userAccount, underPriceBN).call();
        if (data.liquidity.toString() !== "0") {
          health = parseFloat(formatUnits(data.health.toString(), 18));
        }

        positionDatas.push(data);
        healths.push(health);
      }

      // store trades
      const trade = {
        marketId: marketId.toString(),
        user: user,
        type: "short",
        isBuy: true,
        baseAmount: baseSent.toString() / 1e18,
        size: shortSent.toString(),
        basePrice: parseFloat(baseSent / 1e18 / (shortSent / 1e18)),
        collPrice: parseFloat(collReceived / 1e18 / (shortSent / 1e18)),
        interest: interest,
        timestamp: timestamp.toString(),
        signature: event.transactionHash + murmurhash(event.data)
      };

      const marginData = await loadMarginPoolData2(loadHelper, marginPool, underPriceBN, tokens);

      const marketImmutableParams = await loadHelper.methods.getAssetAddresses().call();
      const baseTokenAddress = marketImmutableParams.baseTokenAddress;
      const underTokenAddress = marketImmutableParams.underTokenAddress;

      let [
        tokenAccountBalanceBaseToken,
        assetWalletBalanceBaseToken,
        tokenAccountBalanceUnderToken,
        assetWalletBalanceUnderToken,
        longPositionTokenAccountBalance,
        shortPositionTokenAccountBalance,
        lpPositionTokenAccountBalance
      ] = [null, null, null, null, null, null, null];

      let marginAccountData = null;
      if (userAccount === user) {
        [
          tokenAccountBalanceBaseToken,
          assetWalletBalanceBaseToken,
          tokenAccountBalanceUnderToken,
          assetWalletBalanceUnderToken,
          longPositionTokenAccountBalance,
          shortPositionTokenAccountBalance,
          lpPositionTokenAccountBalance
        ] = await reloadMarketTokensBalances2(web3, marketId.toString(), userBalances, loadHelper, userAccount, underPriceBN);
        marginAccountData = await loadMarginAccountData2(userAccount, loadHelper, marginPool, userBalances, underPriceBN, tokens);
      }

      batch(() => {
        dispatch(marketUpdated(mutableMarketAttributes));
        for (let i = 0; i < positionIds.length; i++) {
          dispatch(loadingLPPositionTokenAccountBalance(marketId, positionIds[i]));
          dispatch(
            lpPositionTokenAccountBalanceLoaded(
              marketId.toString(),
              positionIds[i],
              positionDatas[i].liquidity.toString(),
              positionDatas[i].longBalance.toString(),
              positionDatas[i].baseBalance.toString(),
              positionDatas[i].feeBalance.toString(),
              parseInt(positionDatas[i].lower),
              parseInt(positionDatas[i].upper),
              positionDatas[i].sizeInLongs.toString(),
              positionDatas[i].shortBalance.toString(),
              healths[i]
            )
          );
        }
        dispatch(marginPoolDataLoaded(marginData));
        if (userAccount === user) {
          dispatch(tokenAccountBalanceLoaded(baseTokenAddress, tokenAccountBalanceBaseToken));
          dispatch(tokenWalletBalanceLoaded(baseTokenAddress, assetWalletBalanceBaseToken));
          dispatch(tokenAccountBalanceLoaded(underTokenAddress, tokenAccountBalanceUnderToken));
          dispatch(tokenWalletBalanceLoaded(underTokenAddress, assetWalletBalanceUnderToken));
          dispatch(positionTokenAccountBalanceLoaded(marketId, longPositionTokenAccountBalance));
          dispatch(positionTokenAccountBalanceLoaded((parseInt(marketId) + 1).toString(), shortPositionTokenAccountBalance));
          dispatch(marginAccountDataLoaded(marginAccountData));
        }
        dispatch(tradeAdded(trade));
      });
    };

    const onCloseMarketMakerPosition = async (
      marketId,
      user,
      positionId,
      liqSent,
      baseReceived,
      collReceived,
      shortReceived,
      longsUpdated,
      shortsUpdated,
      longPriceInBase,
      interest,
      timestamp,
      event
    ) => {
      console.log("Timestamp in onCloseMarketMakerPosition", timestamp.toString());
      // check if received event already exists
      let { lpTrades, tokenPairs, contracts, marketsImmutable, tokens } = store.getState();
      const underPriceBN = tokenPairs.selectedTokenPair.avgUnderPrice;

      if (lpTrades.loaded) {
        const existingTrade = lpTrades.data.find(trade => trade.signature === event.transactionHash + murmurhash(event.data));
        if (existingTrade) {
          return;
        }
      }

      // check if for some reason liqSent is 0 (to avoid division by 0)
      if (liqSent.toString() === "0") return;

      // handle event created by any user
      const { loadHelper, userBalances } = contracts.data;
      const marketImmutableData = marketsImmutable.data.find(market => market.marketId === marketId.toString());
      // check if there is a market in state (happens when bot is creating markets fast, we receive event before MarketCreated event)
      if (!marketImmutableData) return;

      let { isCall, strikePrice, expirationTime } = marketImmutableData;
      const normalizedStrikePrice = parseFloat(formatUnits(strikePrice.toString(), 18));

      const mutableMarketAttributes = await getMuttableMarketAttributes(loadHelper, userBalances, marketId, marketImmutableData, riskFreeRate);

      const { longTokenName } = getTokenNames(isCall, normalizedStrikePrice, expirationTime);

      const name = "LP" + positionId + longTokenName.substring(4, longTokenName.length);
      const symbol = "LP" + positionId + longTokenName.substring(4, longTokenName.length);
      const splits = name.split("-");
      let shortName = "";
      const isFuture = marketImmutableData.isFuture;
      if (!isFuture) {
        shortName = "LP" + positionId + "-" + splits[1] + "-" + splits[2] + "-" + splits[3] + "-" + splits[4];
      } else {
        shortName = "LP" + positionId + "-" + splits[1] + "-" + splits[2] + "-" + splits[3];
      }

      const marginData = await loadMarginPoolData2(loadHelper, marginPool, underPriceBN, tokens);

      const marketImmutableParams = await loadHelper.methods.getAssetAddresses().call();
      const baseTokenAddress = marketImmutableParams.baseTokenAddress;
      const underTokenAddress = marketImmutableParams.underTokenAddress;

      let [
        tokenAccountBalanceBaseToken,
        assetWalletBalanceBaseToken,
        tokenAccountBalanceUnderToken,
        assetWalletBalanceUnderToken,
        longPositionTokenAccountBalance,
        shortPositionTokenAccountBalance,
        lpPositionTokenAccountBalance
      ] = [null, null, null, null, null, null, null];

      let marginAccountData = null;
      if (userAccount === user) {
        [
          tokenAccountBalanceBaseToken,
          assetWalletBalanceBaseToken,
          tokenAccountBalanceUnderToken,
          assetWalletBalanceUnderToken,
          longPositionTokenAccountBalance,
          shortPositionTokenAccountBalance,
          lpPositionTokenAccountBalance
        ] = await reloadMarketTokensBalances2(web3, marketId.toString(), userBalances, loadHelper, userAccount, underPriceBN, positionId);
        marginAccountData = await loadMarginAccountData2(userAccount, loadHelper, marginPool, userBalances, underPriceBN, tokens);
      }

      batch(() => {
        dispatch(marketUpdated(mutableMarketAttributes));
        dispatch(
          lpTokenAddedOrUpdated({
            marketId: marketId.toString(),
            positionId: positionId.toString(),
            name,
            shortName,
            symbol,
            accountBalance: 0
          })
        );
        dispatch(marginPoolDataLoaded(marginData));
        dispatch(
          lpTradeAdded({
            marketId: marketId.toString(),
            user: user,
            positionId: positionId.toString(),
            isBuy: false,
            baseAmount: baseReceived.toString() / 1e18,
            size: liqSent.toString(),
            basePrice: parseFloat(baseReceived / 1e18 / (liqSent / 1e18)),
            collPrice: parseFloat(collReceived / 1e18 / (liqSent / 1e18)),
            shortPrice: parseFloat(shortReceived / 1e18 / (liqSent / 1e18)),
            longPriceInBase: longPriceInBase.toString(),
            optionAmount: shortReceived.toString(),
            interest: interest.toString(),
            timestamp: timestamp.toString(),
            signature: event.transactionHash + murmurhash(event.data)
          })
        );
        if (longsUpdated != 0) {
          dispatch(
            tradeAdded({
              marketId: marketId.toString(),
              user: user,
              type: "long",
              isBuy: longsUpdated > 0,
              size: Math.abs(longsUpdated),
              baseAmount:
                BigNumber.from(longsUpdated.toString())
                  .mul(BigNumber.from(longPriceInBase.toString()))
                  .div(BigNumber.from((1e18).toString()))
                  .toString() / 1e18,
              basePrice: longPriceInBase.toString() / 1e18,
              collPrice: 0,
              timestamp: timestamp.toString(),
              signature: event.transactionHash + murmurhash(event.data)
            })
          );
        }
        if (shortsUpdated != 0) {
          dispatch(
            tradeAdded({
              marketId: marketId.toString(),
              user: user,
              type: "short",
              isBuy: shortsUpdated < 0,
              size: Math.abs(shortsUpdated), // todo: v1 does Math.abs works with large numbers?
              baseAmount:
                BigNumber.from(shortsUpdated.toString())
                  .mul(BigNumber.from(longPriceInBase.toString()))
                  .div(BigNumber.from((1e18).toString()))
                  .toString() / 1e18,
              basePrice: longPriceInBase.toString() / 1e18,
              collPrice: isCall ? 1 : normalizedStrikePrice,
              timestamp: timestamp.toString(),
              signature: event.transactionHash + murmurhash(event.data)
            })
          );
        }
        if (userAccount === user) {
          dispatch(tokenAccountBalanceLoaded(baseTokenAddress, tokenAccountBalanceBaseToken));
          dispatch(tokenWalletBalanceLoaded(baseTokenAddress, assetWalletBalanceBaseToken));
          dispatch(tokenAccountBalanceLoaded(underTokenAddress, tokenAccountBalanceUnderToken));
          dispatch(tokenWalletBalanceLoaded(underTokenAddress, assetWalletBalanceUnderToken));
          dispatch(positionTokenAccountBalanceLoaded(marketId, longPositionTokenAccountBalance));
          dispatch(positionTokenAccountBalanceLoaded((parseInt(marketId) + 1).toString(), shortPositionTokenAccountBalance));
          dispatch(lpPositionTokenAccountBalanceLoaded(...Object.values(lpPositionTokenAccountBalance)));
          dispatch(marginAccountDataLoaded(marginAccountData));
        }
      });
    };
  } catch (e) {
    console.log(e);
    handleNewError({}, "Error while loading data. Please reset your account and reload the application!", 1, dispatch, true);
  }
};

const getBuySellNotificationText = (quantityOptionBN, quantityFutureBN, isBuy, isCall, isFuture = false) => {
  const quantityOption = parseFloat(formatUnits(quantityOptionBN.toString(), 18));
  const quantityFuture = parseFloat(formatUnits(quantityFutureBN.toString(), 18));

  const buySellOptionStr = isBuy ? "Buy" : "Sell";

  if (isFuture) {
    const futureOrFutures = quantityOption === 1 ? "future" : "futures";
    return buySellOptionStr + " " + quantityOption + " " + futureOrFutures;
  }

  // option string
  let callPut = isCall ? "call" : "put";
  if (quantityOption !== 1) {
    callPut = callPut + "s";
  }
  const quantityOptionStr = `${buySellOptionStr} ${quantityOption.toLocaleString()} ${callPut}`;

  if (quantityFuture === 0) {
    return quantityOptionStr;
  }

  // future string
  const buySellFutureStr = (isBuy && isCall) || (!isBuy && !isCall) ? "sell" : "buy";
  const futureOrFutures = quantityFuture === 1 ? "future" : "futures";
  const quantityFutureStr = `${buySellFutureStr} ${quantityFuture.toLocaleString()} ${futureOrFutures}`;

  return quantityOptionStr + ", " + quantityFutureStr;
};

// OPEN/CLOSE POSITIONS
export const buyLong = async (
  web3,
  positionsManager,
  marketId,
  maxBaseSold,
  exactLongBought,
  userAccount,
  deadline,
  isCall,
  isFuture,
  closeModal,
  dispatch
) => {
  try {

    const isFuture = Number(marketId) > 100000000000;
    const notificationText = getBuySellNotificationText(exactLongBought, 0, true, isCall, isFuture);
    const txNonce = await getNonce(web3, userAccount);
    const contractMethod = positionsManager.methods.openLongPosition(marketId, maxBaseSold, exactLongBought, deadline);
    const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    let isConfirmed = false;
    console.log("limitGas:", limitGas);
    await contractMethod
      .send({ from: userAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    // todo: v2 4001 is for MetaMask, we should examine for each
    // wallet provider what error code is when user rejects transaction
    onError(e, "There was an error while opening long position. Please try again!", dispatch);
  }
};

export const buyLongWithDeltaHedge = async (
  web3,
  positionsManager,
  optionMarketId,
  futureMarketId,
  optionExactLongBought,
  optionMaxBaseSold,
  futureExactAmount,
  futureLimitBaseAmount,
  marketBorrowFactor,
  userAccount,
  deadline,
  isCall,
  closeModal,
  dispatch
) => {
  try {
    const notificationText = getBuySellNotificationText(optionExactLongBought, futureExactAmount, true, isCall);
    const txNonce = await getNonce(web3, userAccount);
    const contractMethod = positionsManager.methods.openLongPositionWithDeltaHedge(
      optionMarketId,
      futureMarketId,
      optionExactLongBought,
      optionMaxBaseSold,
      futureExactAmount,
      futureLimitBaseAmount,
      marketBorrowFactor.toString(),
      deadline
    );
    const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    console.log("limitGas:", limitGas);
    let isConfirmed = false;
    await contractMethod
      .send({ from: userAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    // todo: v2 4001 is for MetaMask, we should examine for each
    // wallet provider what error code is when user rejects transaction
    onError(e, "There was an error while opening long position with delta hedge. Please try again!", dispatch);
  }
};

export const sellLong = async (
  web3,
  positionsManager,
  marketId,
  minBaseBought,
  exactLongSold,
  userAccount,
  deadline,
  isCall,
  closeModal,
  dispatch,
  marketBorrowFactor = DEFAULT_MARKET_BORROW_FACTOR
) => {
  try {
    const isFuture = Number(marketId) > 100000000000;
    const notificationText = getBuySellNotificationText(exactLongSold, 0, false, isCall, isFuture);
    const txNonce = await getNonce(web3, userAccount);

    // // TODO: ductaped fix for marketBorrowFactor, because it's not working properly
    // // (factor was 0.994 when user tried to buy 50 futures while he had 1000 short)
    // if (isFuture) {
    //   marketBorrowFactor = (1.01 * 10 ** 18).toString();
    // }

    const contractMethod = positionsManager.methods.closeLongPosition(marketId, exactLongSold, minBaseBought, deadline, marketBorrowFactor);
    const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    console.log("limitGas:", limitGas);
    let isConfirmed = false;
    await contractMethod
      .send({ from: userAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    onError(e, "There was an error while selling options. Please try again!", dispatch);
  }
};

export const buyShort = async (
  web3,
  strikePrice,
  positionsManager,
  marketId,
  exactCollateralSold,
  minBaseBought,
  userAccount,
  deadline,
  isCall,
  closeModal,
  dispatch
) => {
  try {
    deadline = "4000000000";
    const isFuture = Number(marketId) > 100000000000;
    const quantity = parseFloat(formatUnits(exactCollateralSold.toString(), 18)) / (isCall ? 1 : strikePrice);
    const notificationText = getBuySellNotificationText(toBn(quantity.toString(), 18).toString(), 0, false, isCall, isFuture);
    const txNonce = await getNonce(web3, userAccount);
    const contractMethod = positionsManager.methods.openShortPosition(marketId, exactCollateralSold.toString(), minBaseBought, deadline);
    const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    console.log("limitGas:", limitGas);
    let isConfirmed = false;
    await contractMethod
      .send({ from: userAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    onError(e, "There was an error while selling options. Please try again!", dispatch);
  }
};

export const buyShortWithDeltaHedge = async (
  web3,
  positionsManager,
  optionMarketId,
  futureMarketId,
  optionExactLongSold,
  optionMinBaseBought,
  futureExactAmount,
  futureLimitBaseAmount,
  marketBorrowFactor,
  userAccount,
  deadline,
  isCall,
  closeModal,
  dispatch
) => {
  try {
    const quantity = parseFloat(formatUnits(optionExactLongSold.toString(), 18));
    const notificationText = getBuySellNotificationText(toBn(quantity.toString(), 18).toString(), futureExactAmount, false, isCall);
    const txNonce = await getNonce(web3, userAccount);
    const contractMethod = positionsManager.methods.openShortPositionWithDeltaHedge(
      optionMarketId,
      futureMarketId,
      optionExactLongSold.toString(),
      optionMinBaseBought,
      futureExactAmount,
      futureLimitBaseAmount,
      marketBorrowFactor.toString(),
      deadline
    );
    const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    console.log("limitGas:", limitGas);
    let isConfirmed = false;
    await contractMethod
      .send({ from: userAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    onError(e, "There was an error while opening short position with delta hedge. Please try again!", dispatch);
  }
};

export const sellShort = async (
  web3,
  positionsManager,
  marketId,
  exactShortSold,
  maxBaseSold,
  userAccount,
  marketBorrowFactor,
  deadline,
  isCall,
  closeModal,
  dispatch
) => {
  try {
    const isFuture = Number(marketId) > 100000000000;
    const notificationText = getBuySellNotificationText(exactShortSold, 0, true, isCall, isFuture);
    const txNonce = await getNonce(web3, userAccount);
    if (!marketBorrowFactor) marketBorrowFactor = (10 ** 18).toString();
    deadline = "4000000000";
    marketBorrowFactor = "994000000000000000";
    console.log("MBF: ", marketBorrowFactor.toString());

    // // TODO: ductaped fix for marketBorrowFactor, because it's not working properly
    // // (factor was 0.994 when user tried to buy 50 futures while he had 1000 short)
    // if (isFuture) {
    //   marketBorrowFactor = (1.01 * 10 ** 18).toString();
    // }

    // console.log(marketId, exactShortSold.toString() / 1e18, maxBaseSold.toString() / 1e18, marketBorrowFactor.toString() / 1e18, deadline);
    // console.log("price per future:", (maxBaseSold.toString() / 1e18) / (exactShortSold.toString() / 1e18));
    const contractMethod = positionsManager.methods.closeShortPosition(marketId, exactShortSold, maxBaseSold, marketBorrowFactor.toString(), deadline);
    const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    console.log("limitGas:", limitGas);
    let isConfirmed = false;
    await contractMethod
      .send({ from: userAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    console.log(e);
    onError(e, "There was an error while closing short position. Please try again!", dispatch);
  }
};

export const closeShortOpenLongPosition = async (
  web3,
  positionsManager,
  marketId,
  exactShortSold,
  shortPosMaxBaseSold,
  exactLongBought,
  longPosMaxBaseSold,
  marketBorrowFactor,
  userAccount,
  deadline,
  isCall,
  closeModal,
  dispatch
) => {
  try {
    const isFuture = Number(marketId) > 100000000000;
    const quantity = parseFloat(formatUnits(exactShortSold.toString(), 18)) + parseFloat(formatUnits(exactLongBought.toString(), 18));
    const notificationText = getBuySellNotificationText(toBn(quantity.toString(), 18).toString(), 0, true, isCall, isFuture);
    const txNonce = await getNonce(web3, userAccount);
    if (!marketBorrowFactor) marketBorrowFactor = (1e18).toString();
    const contractMethod = positionsManager.methods.closeShortOpenLongPosition(
      marketId,
      exactShortSold,
      shortPosMaxBaseSold,
      exactLongBought,
      longPosMaxBaseSold,
      marketBorrowFactor.toString(),
      deadline
    );
    const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    console.log("limitGas:", limitGas);
    let isConfirmed = false;
    await contractMethod
      .send({ from: userAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    onError(e, "There was an error while closing short and opening long position. Please try again!", dispatch);
  }
};

export const closeLongOpenShortPosition = async (
  web3,
  positionsManager,
  marketId,
  exactLongSold,
  longPosMinBaseBought,
  exactShortBought,
  shortPosMinBaseBought,
  marketBorrowFactor,
  userAccount,
  deadline,
  isCall,
  closeModal,
  dispatch
) => {
  try {
    const isFuture = Number(marketId) > 100000000000;
    const quantity = parseFloat(formatUnits(exactLongSold.toString(), 18)) + parseFloat(formatUnits(exactShortBought.toString(), 18));
    const notificationText = getBuySellNotificationText(toBn(quantity.toString(), 18).toString(), 0, false, isCall, isFuture);
    const txNonce = await getNonce(web3, userAccount);
    if (!marketBorrowFactor) marketBorrowFactor = (1e18).toString();
    const contractMethod = positionsManager.methods.closeLongOpenShortPosition(
      marketId,
      exactLongSold,
      longPosMinBaseBought,
      exactShortBought,
      shortPosMinBaseBought,
      marketBorrowFactor.toString(),
      deadline
    );
    const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    console.log("limitGas:", limitGas);
    let isConfirmed = false;
    await contractMethod
      .send({ from: userAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    onError(e, "There was an error while selling options. Please try again!", dispatch);
  }
};

export const openMarketMakerPosition = async (
  web3,
  userAccount,
  lpManager,
  marketId,
  lowerOld,
  lower,
  upperOld,
  upper,
  current,
  currentForText,
  exactBaseSent,
  sizeInLongs,
  deadline,
  closeModal,
  dispatch
) => {
  try {
    // todo: move to TradeModalPoolTab
    const lowerSqrtPrice = toSqrtPrice(1.0001 ** lower).toString();
    const upperSqrtPrice = toSqrtPrice(1.0001 ** upper).toString();
    const sizeInLongsBN = toBn(toPreciseString(sizeInLongs, 18), 18).toString();
    const liquidityBN = getLiquidityForLongs(lowerSqrtPrice, upperSqrtPrice, sizeInLongsBN);

    // const optionsRangeText = current >= lower && current <= upper ? "Options in range" : "Options out of range";
    const optionsRangeText = currentForText >= lower && currentForText <= upper ? "Options in range" : "Options out of range";

    const notificationText = `Add ${sizeInLongs} ${optionsRangeText}`;
    const txNonce = await getNonce(web3, userAccount);
    const contractMethod = lpManager.methods.openMarketMakerPosition(
      marketId,
      lowerOld,
      lower,
      upperOld,
      upper,
      current,
      liquidityBN.toString(),
      exactBaseSent,
      deadline
    );
    const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    console.log("limitGas:", limitGas);
    let isConfirmed = false;
    await contractMethod
      .send({ from: userAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    onError(e, "There was an error while adding liquidity. Please try again!", dispatch);
  }
};

export const closeMarketMakerPosition = async (
  web3,
  userAccount,
  lpManager,
  marketId,
  positionId,
  size,
  exactLiquidity,
  marketBorrowFactor,
  deadline,
  closeModal,
  dispatch
) => {
  try {
    const notificationText = `Remove  ${size} Options in range`;
    const txNonce = await getNonce(web3, userAccount);
    if (!marketBorrowFactor) marketBorrowFactor = (1e18).toString();
    const contractMethod = lpManager.methods.closeMarketMakerPosition(marketId, positionId, exactLiquidity, marketBorrowFactor.toString(), deadline);
    const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    console.log("limitGas:", limitGas);
    let isConfirmed = false;
    await contractMethod
      .send({ from: userAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    onError(e, "There was an error while removing liquidity. Please try again!", dispatch);
  }
};

export const increaseReserves = async (web3, userAccount, lpManager, marketId, positionId, exactBase, closeModal, dispatch) => {
  try {
    const notificationText = `Increase ${parseFloat(formatUnits(exactBase, 18))}`;
    const txNonce = await getNonce(web3, userAccount);
    const contractMethod = lpManager.methods.increaseReserves(marketId, positionId, exactBase);
    const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 2); // 20% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    console.log("limitGas:", limitGas);
    let isConfirmed = false;
    await contractMethod
      .send({ from: userAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    onError(e, "There was an error while increasing reserves. Please try again!", dispatch);
  }
};

export const decreaseReserves = async (web3, userAccount, lpManager, marketId, positionId, maxBase, closeModal, dispatch) => {
  try {
    const notificationText = `Decrease ${parseFloat(formatUnits(maxBase, 18))}`;
    const txNonce = await getNonce(web3, userAccount);
    const contractMethod = lpManager.methods.decreaseReserves(marketId, positionId, maxBase);
    const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    console.log("limitGas:", limitGas);
    let isConfirmed = false;
    await contractMethod
      .send({ from: userAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    onError(e, "There was an error while decreasing reserves. Please try again!", dispatch);
  }
};

export const liquidatePosition = async (web3, lpManager, owner, marketId, tokenId, liquidatorAccount, marketBorrowFactor, dispatch) => {
  try {
    const notificationText = `Liquidate LP position from ${toShortAddress(owner)}`;
    const txNonce = await getNonce(web3, liquidatorAccount);
    const contractMethod = lpManager.methods.liquidateMarketMakerPosition(owner, marketId, tokenId);
    const limitGas = Math.round((await contractMethod.estimateGas({ from: liquidatorAccount })) * 1.2); // 20% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    console.log("limitGas:", limitGas);
    let isConfirmed = false;
    await contractMethod
      .send({ from: liquidatorAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, liquidatorAccount, notificationText, txNonce + 1, timestamp, null, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, liquidatorAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, liquidatorAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, liquidatorAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    onError(e, "There was an error while trying to liquidate position. Please try again!", dispatch);
  }
};

export const closeAccountPositions = async (
  web3,
  lpManager,
  positionMarkets,
  isLong,
  lpMarkets,
  lpIds,
  marketBorrowFactors,
  lpBorrowFactors,
  userAccount,
  deadline,
  closeModal,
  dispatch
) => {
  try {
    const notificationText = "Close multiple positions";
    const txNonce = await getNonce(web3, userAccount);
    // fix to string and remove nulls
    // todo: why are there nulls
    marketBorrowFactors = marketBorrowFactors.map(borrowFactor => {
      if (!borrowFactor) borrowFactor = (1e18).toString();
      return borrowFactor.toString();
    });
    lpBorrowFactors = lpBorrowFactors.map(borrowFactor => {
      return borrowFactor.toString();
    });

    const contractMethod = lpManager.methods.closeAccountPositions(positionMarkets, isLong, marketBorrowFactors, lpMarkets, lpIds, lpBorrowFactors, deadline);
    const limitGas = Math.round((await contractMethod.estimateGas({ from: userAccount })) * 1.2); // 20% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    console.log("limitGas:", limitGas);
    let isConfirmed = false;
    await contractMethod
      .send({ from: userAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, userAccount, notificationText, txNonce + 1, timestamp, closeModal, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, userAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    onError(e, "There was an error while trying to close multiple positions. Please try again!", dispatch);
  }
};

// liquidate
export const liquidateAccount = async (web3, lpManager, liquidatedAccount, liquidatorAccount, dispatch) => {
  try {
    let shortLiquidatorAccount = toShortAddress(liquidatorAccount);
    let shortLiquidatedAccount = toShortAddress(liquidatedAccount);
    const notificationText = `Liquidating ${shortLiquidatedAccount} account by ${shortLiquidatorAccount} account`;
    const txNonce = await getNonce(web3, liquidatorAccount);
    const contractMethod = lpManager.methods.liquidateAccount(liquidatedAccount);
    const limitGas = Math.round((await contractMethod.estimateGas({ from: liquidatorAccount })) * 1.2); // 20% extra gas
    const timestamp = parseInt((new Date().getTime() / 1000).toFixed(0));
    console.log("limitGas:", limitGas);
    let isConfirmed = false;
    await contractMethod
      .send({ from: liquidatorAccount, gas: limitGas })
      .on("transactionHash", async function(hash) {
        onPending(hash, liquidatorAccount, notificationText, txNonce + 1, timestamp, null, dispatch);
        const hasFailed = await hasTxFailed(web3, hash);
        if (hasFailed) {
          onFailed(hash, liquidatorAccount, notificationText, txNonce + 1, timestamp, dispatch);
        }
      })
      .on("confirmation", function(confirmationNumber, receipt) {
        if (!isConfirmed) {
          if (receipt.status) {
            onConfirmed(receipt.transactionHash, liquidatorAccount, notificationText, txNonce + 1, timestamp, dispatch);
          } else {
            onFailed(receipt.transactionHash, liquidatorAccount, notificationText, txNonce + 1, timestamp, dispatch);
          }
          isConfirmed = true;
        }
      });
  } catch (e) {
    onError(e, "There was an error while trying to liquidate account. Please try again!", dispatch);
  }
};
