import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router";
import { expirationTimesSelector, selectedExpirationTimeSelector } from "../../store/selectors/dateUtilsSelectors";
import { selectedTokenPairSelector } from "../../store/selectors/tokensSelectors";
import { web3AccountLoadedSelector } from "../../store/selectors/web3Selectors";
import * as actions from "../../store/actions/actions.js";
import { marketsSelector } from "../../store/selectors/marketsSelectors";
import { FilterContext } from "../../layouts/market/MainRouter.jsx";

const MarketsSelect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const web3AccountLoaded = useSelector(web3AccountLoadedSelector);
  const expirationTimes = useSelector(expirationTimesSelector);
  const selectedExpirationTime = useSelector(selectedExpirationTimeSelector);
  const selectedTokenPair = useSelector(selectedTokenPairSelector);
  const markets = useSelector(marketsSelector);

  const match = useMatch("/:param1/:param2/:param3");

  useEffect(() => {
    if (expirationTimes.length > 0) {
      // const match = getParam1Param2Param3Match(this.props.location.pathname);
      if (match && (match.params.param1 === "trade" || match.params.param1 === "pool") && match.params.param3) {
        let urlTime = findExpirationTime(match.params.param3);
        if (urlTime != null) {
          // if url time different than selected
          if (urlTime.urlFormat !== selectedExpirationTime.urlFormat) {
            dispatch(actions.expirationTimeSelected(urlTime));
          }
        } else {
          // time not found in expirationTimes, set time to all
          navigate("daily");
        }
      }
    }
  }, [expirationTimes, selectedExpirationTime, markets]);

  const findExpirationTime = useCallback(
    time => {
      if (time === "all") {
        return { timestamp: "all", sidebarFormat: "All Expirations", urlFormat: "all" };
      }

      if (time === "daily") {
        return { timestamp: "daily", sidebarFormat: "Daily Expirations", urlFormat: "daily" };
      }

      const expirationTime = expirationTimes.find(expirationTime => {
        return expirationTime.urlFormat === time;
      });

      if (expirationTime && createdMarketsForExpiration(expirationTime.timestamp).length > 0) {
        return expirationTime;
      }
      return null;
    },
    [expirationTimes]
  );

  const getTokenPairPathPart = useCallback(() => {
    if (selectedTokenPair) {
      return selectedTokenPair.underlyingTokenSymbol.toLowerCase() + "-" + selectedTokenPair.baseTokenSymbol.toLowerCase();
    }
    return "";
  }, [selectedTokenPair]);

  const createdMarketsForExpiration = expirationTime => {
    const createdMarkets = markets.filter(m => m.expirationTime == expirationTime);
    return createdMarkets;
  };

  const displayExpirationDates = useCallback(
    (param1, setSelectedExpirationTime) => {
      if ((web3AccountLoaded, expirationTimes)) {
        return expirationTimes.map((expirationTime, index) => {
          const urlPathElements = window.location.pathname.split("/");
          const expTimeUrl = urlPathElements[urlPathElements.length - 1];

          if (createdMarketsForExpiration(expirationTime.timestamp).length > 0) {
            return (
              <button
                key={expirationTime.timestamp}
                className={expTimeUrl == expirationTime.urlFormat ? "marketSelectItemSelected" : "marketSelectItemNotSelected"}
                onClick={e => {
                  e.stopPropagation();
                  dispatch(actions.expirationTimeSelected(expirationTime));
                  navigate("/" + param1 + "/" + getTokenPairPathPart() + "/" + expirationTime.urlFormat);
                  setSelectedExpirationTime(expirationTime);
                }}
                onDoubleClick={e => e.stopPropagation()}
              >
                {expirationTime.sidebarFormatWithoutYear}
              </button>
            );
          }
        });
      }
    },
    [web3AccountLoaded, expirationTimes, markets]
  );

  const param1 = match != null ? match.params.param1 : "trade";

  return (
    <FilterContext.Consumer>
      {({ setSelectedExpirationTime }) => {
        return (
          <div className="marketsSelectContainer">
            <button
              className={window.location.pathname.includes("daily") ? "marketSelectItemSelected" : "marketSelectItemNotSelected"}
              onClick={e => {
                e.stopPropagation();
                dispatch(actions.expirationTimeSelected({ timestamp: "daily", sidebarFormat: "Daily Expirations", urlFormat: "daily" }));
                navigate("/" + param1 + "/" + getTokenPairPathPart() + "/daily");
                setSelectedExpirationTime({ timestamp: "daily", sidebarFormat: "Daily Expirations", urlFormat: "daily" });
              }}
              onDoubleClick={e => e.stopPropagation()}
            >
              DAILY
            </button>
            {web3AccountLoaded && expirationTimes.length > 0 && (
              <button
                className={window.location.pathname.includes("all") ? "marketSelectItemSelected" : "marketSelectItemNotSelected"}
                onClick={e => {
                  e.stopPropagation();
                  dispatch(actions.expirationTimeSelected({ timestamp: "all", sidebarFormat: "All Expirations", urlFormat: "all" }));
                  navigate("/" + param1 + "/" + getTokenPairPathPart() + "/all");
                  setSelectedExpirationTime({ timestamp: "all", sidebarFormat: "All Expirations", urlFormat: "all" });
                }}
                onDoubleClick={e => e.stopPropagation()}
              >
                ALL
              </button>
            )}
            {displayExpirationDates(param1, setSelectedExpirationTime)}
          </div>
        );
      }}
    </FilterContext.Consumer>
  );
};

export default MarketsSelect;
