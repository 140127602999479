import { addError, removeError, removeErrors } from "../actions/actions.js";
import { v4 as uuidv4 } from "uuid";

export const handleNewError = async (err, message, severity, dispatch, isBreaking = false) => {
  try {
    let error = JSON.parse(JSON.stringify(err));
    let errorMessage = error["reason"] ? error["reason"] : message;
    dispatch(addError({ id: uuidv4(), message: errorMessage, severity: severity, isBreaking: isBreaking }));
  } catch (e) {
    dispatch(addError({ id: uuidv4(), message: message, severity: severity, isBreaking: isBreaking }));
  }
};

export const handleErrorRemoval = async (errorId, dispatch) => {
  dispatch(removeError(errorId));
};

export const clearErrors = dispatch => {
  dispatch(removeErrors());
};
