import * as types from "../actionTypes.js";

export const tokenPairs = (state = { loaded: false }, action) => {
  switch (action.type) {
    case types.TOKEN_PAIRS_LOADED:
      return { ...state, loaded: true, data: action.tokenPairs };
    case types.TOKEN_PAIR_SELECTED:
      return { ...state, selectedTokenPair: action.tokenPair };
    case types.TOKEN_PAIR_ADDED:
      if (
        state.data.find(
          tokenPair =>
            tokenPair.baseTokenAddress === action.newTokenPair.baseTokenAddress &&
            tokenPair.underlyingTokenAddress === action.newTokenPair.underlyingTokenAddress
        )
      ) {
        return state;
      } else {
        return {
          ...state,
          data: [...state.data, action.newTokenPair]
        };
      }
    case types.UNDERLYING_TOKEN_PRICE_LOADED:
      return {
        ...state,
        data: state.data.map(tokenPair => {
          if (
            tokenPair.baseTokenAddress === action.tokenPair.baseTokenAddress &&
            tokenPair.underlyingTokenAddress === action.tokenPair.underlyingTokenAddress
          ) {
            let uptrend, avgUptrend;

            // spot price
            let previousUnderlyingTokenPrice = tokenPair.underlyingTokenPrice ? tokenPair.underlyingTokenPrice : action.underlyingPrice;
            if (tokenPair.uptrend != null && previousUnderlyingTokenPrice) {
              if (previousUnderlyingTokenPrice.toString() === action.underlyingPrice.toString()) {
                uptrend = tokenPair.uptrend;
              } else {
                uptrend = action.underlyingPrice > previousUnderlyingTokenPrice;
              }
            } else {
              uptrend = true;
            }

            // twap price
            let prevAvgUnderPrice = tokenPair.avgUnderPrice ? tokenPair.avgUnderPrice : action.avgUnderlyingPrice;
            if (tokenPair.avgUptrend != null && prevAvgUnderPrice) {
              if (prevAvgUnderPrice.toString() === action.avgUnderlyingPrice.toString()) {
                avgUptrend = tokenPair.avgUptrend;
              } else {
                avgUptrend = action.avgUnderlyingPrice > prevAvgUnderPrice;
              }
            } else {
              avgUptrend = true;
            }

            return {
              ...tokenPair,
              underlyingTokenPrice: action.underlyingPrice,
              previousUnderlyingTokenPrice: previousUnderlyingTokenPrice,
              uptrend: uptrend,
              avgUnderPrice: action.avgUnderlyingPrice,
              prevAvgUnderPrice: prevAvgUnderPrice,
              avgUptrend: avgUptrend
            };
          }
          return tokenPair;
        })
      };
    default:
      return state;
  }
};

// export const positionTokenBalances = (state = {}, action) => {
export const positionTokens = (state = { loaded: false, data: [] }, action) => {
  switch (action.type) {
    // case types.LOADING_POSITION_TOKENS:
    //   return { ...state, loaded: false };
    case types.POSITION_TOKENS_LOADED:
      return { ...state, loaded: true, data: action.positionTokens };
    case types.POSITION_TOKEN_ADDED:
      if (state.data.find(token => token.tokenId === action.newPositionToken.tokenId)) {
        return state;
      } else {
        return {
          ...state,
          data: [...state.data, action.newPositionToken]
        };
      }
    case types.POSITION_TOKEN_ACCOUNT_BALANCE_LOADED:
      return {
        ...state,
        data: state.data.map(token => {
          if (token.tokenId === action.tokenId) {
            return { ...token, accountBalance: action.balance };
          }
          return token;
        })
      };
    case types.POSITION_TOKEN_ACCOUNT_BALANCES_LOADED:
      return {
        ...state,
        data: state.data.map(token => {
          const item = action.addressBalances.find(item => item.tokenId === token.tokenId);
          return { ...token, accountBalance: item ? item.balance : "" };
        })
      };
    case types.LOADING_POSITION_TOKEN_ACCOUNT_BALANCE:
      return {
        ...state,
        data: state.data.map(token => {
          if (token.tokenId === action.tokenId) {
            return { ...token, accountBalance: null };
          }
          return token;
        })
      };
    default:
      return state;
  }
};

export const lpPositionTokens = (state = { loaded: false, data: [] }, action) => {
  switch (action.type) {
    case types.LP_POSITION_TOKENS_LOADED:
      return { ...state, loaded: true };
    case types.LP_POSITION_TOKENS_DATA:
      return { ...state, data: action.lpPositionTokens };
    case types.LOADING_LP_POSITION_TOKEN_ACCOUNT_BALANCE:
      return {
        ...state,
        data: state.data.map(token => {
          if (token.marketId === action.marketId && token.positionId.toString() === action.tokenId.toString()) {
            return { ...token, accountBalanceLoaded: false };
          }
          return token;
        })
      };
    case types.LP_POSITION_TOKEN_ACCOUNT_BALANCE_LOADED:
      return {
        ...state,
        data: state.data.map(token => {
          if (token.marketId === action.marketId && token.positionId.toString() === action.tokenId.toString()) {
            return {
              ...token,
              accountBalanceLoaded: true,
              accountBalance: action.lpBalance,
              longBalance: action.longBalance,
              baseBalance: action.baseBalance,
              feeBalance: action.feeBalance,
              lower: action.lower,
              upper: action.upper,
              sizeInLongs: action.sizeInLongs,
              shortBalance: action.shortBalance,
              health: action.health
            };
          }
          return token;
        })
      };
    case types.LP_POSITION_TOKEN_ACCOUNT_BALANCES_LOADED:
      return {
        ...state,
        data: state.data.map(token => {
          let newToken = action.lpPositionBalances.find(
            b => b.marketId.toString() === token.marketId.toString() && b.tokenId.toString() === token.positionId.toString()
          );
          if (newToken !== undefined) {
            return {
              ...token,
              accountBalanceLoaded: true,
              accountBalance: newToken.liquidity,
              longBalance: newToken.longBalance,
              baseBalance: newToken.baseBalance,
              feeBalance: newToken.feeBalance,
              lower: newToken.lower,
              upper: newToken.upper,
              sizeInLongs: newToken.sizeInLongs,
              shortBalance: newToken.shortBalance,
              health: newToken.health
            };
          } else {
            return {
              ...token
            };
          }
        })
      };
    case types.LP_TOKEN_ADDED_OR_UPDATED:
      if (state.data.find(token => token.marketId === action.lpToken.marketId && token.positionId.toString() === action.lpToken.positionId.toString())) {
        return {
          ...state,
          data: state.data.map(d => {
            if (d.marketId === action.lpToken.marketId && d.positionId.toString() === action.lpToken.positionId.toString()) {
              return { ...d, ...action.lpToken };
            }
            return d;
          })
        };
      } else {
        return {
          ...state,
          data: [...state.data, action.lpToken]
        };
      }
    default:
      return state;
  }
};

export const tokens = (state = { loaded: false, data: [] }, action) => {
  switch (action.type) {
    case types.TOKENS_LOADED:
      return { ...state, loaded: true, data: action.tokens };
    case types.TOKEN_ADDED:
      if (state.data.find(asset => asset.address === action.newToken.address)) {
        return state;
      } else {
        return {
          ...state,
          data: [...state.data, action.newToken]
        };
      }
    case types.TOKEN_ACCOUNT_BALANCE_LOADED:
      return {
        ...state,
        data: state.data.map(asset => {
          if (asset.address === action.assetAddress) {
            return { ...asset, accountBalance: action.tokenBalance };
          }
          return asset;
        })
      };
    case types.TOKEN_WALLET_BALANCE_LOADED:
      return {
        ...state,
        data: state.data.map(asset => {
          if (asset.address === action.assetAddress) {
            return { ...asset, walletBalance: action.tokenBalance, isWeth: action.isWeth };
          }
          return asset;
        })
      };
    case types.LOADING_TOKEN_ACCOUNT_BALANCE:
      return {
        ...state,
        data: state.data.map(asset => {
          if (asset.address === action.assetAddress) {
            return { ...asset, accountBalance: null };
          }
          return asset;
        })
      };
    case types.LOADING_TOKEN_WALLET_BALANCE:
      return {
        ...state,
        data: state.data.map(asset => {
          if (asset.address === action.assetAddress) {
            return { ...asset, walletBalance: null };
          }
          return asset;
        })
      };
    default:
      return state;
  }
};
