import { getMainContractAddress, isSupportedNetwork, loadContracts } from "./contractsInteractions";
import { loadTrades, subscribeToPositionsManagerEvents } from "./positionsManagerInteractions";
import {
  allContractsLoaded,
  tradesLoaded,
  lpTradesLoaded,
  marketsLoaded,
  marketsSettled,
  liquidationTradesLoaded,
  insuranceFundLoaded,
  insurerBalanceLoaded
} from "../actions/actions.js";
import { loadLatestMarkets, loadMarkets, periodicallyUpdateMarketBasePrices, subscribeToMarketFactoryEvents } from "./marketsInteractions";
import { loadExpirationTimes } from "./dateUtilsInteractions";
import { loadAllTokenPairs, loadPositionTokens, loadLPPositionTokens, updateUnderlyingMarketPrice } from "./tokensInteractions";
import { loadDepositAndWithdrawalEvents, loadSettleEvents, subscribeToUserBalancesEvents } from "./balancesInteractions";
import { loadMarginAccountData, loadMarginPoolData, loadMarginPoolEvents, subscribeToMarginPoolEvents } from "./marginInteractions";
import { Web3Provider, JsonRpcProvider } from "@ethersproject/providers";
import { batch } from "react-redux";
import {
  loadInsuranceFundBalances,
  loadInsuranceFundEvents,
  loadInsurerBalance,
  subscribeToInsuranceFundEvents,
  subscribeToInsuranceFundLossesCoveredEvents
} from "./insuranceFundInteractions";
import { addStores, setDBName } from "./interactionsManager.js";
import {
  ARBITRUM_SEPOLIA_FIRST_BLOCK,
  ARBITRUM_SEPOLIA_NETWORK_ID,
  BASE_SEPOLIA_FIRST_BLOCK,
  BASE_SEPOLIA_NETWORK_ID,
  BASE_MAINNET_NETWORK_ID,
  BASE_MAINNET_FIRST_BLOCK,
  SEPOLIA_FIRST_BLOCK,
  SEPOLIA_NETWORK_ID
} from "../../config.js";

export const init = async (web3, web3read, web3Events, web3Subs, networkId, userAddress, dispatch) => {
  // TODO: v2 zasto se negde kao provider koristi web3 a negde provider?
  console.log("Init called in rootInteractions. Main timer started.");
  let startTime = new Date();
  localStorage.setItem("ttl", Date.now());
  const provider = new Web3Provider(window.ethereum);

  // check if on right network
  const supportedNetwork = await isSupportedNetwork(networkId, dispatch);
  if (!supportedNetwork) {
    return;
  }

  let web3ForRead = web3;
  const isEthereumSepolia = networkId == SEPOLIA_NETWORK_ID;
  const isArbitrumSepolia = networkId == ARBITRUM_SEPOLIA_NETWORK_ID;
  const isBaseSepolia = networkId == BASE_SEPOLIA_NETWORK_ID;
  const isBase = networkId == BASE_MAINNET_NETWORK_ID;

  let providerForEvents = provider;
  if (isArbitrumSepolia) {
    web3ForRead = web3read;
    //const jsonRpcProvider = new JsonRpcProvider("https://sepolia-rollup.arbitrum.io/rpc");
    // const jsonRpcProvider = new JsonRpcProvider("https://wider-icy-film.arbitrum-sepolia.quiknode.pro/75e9e2fe02e527394ea37f48fb854ea7343f57ed/");
    // const jsonRpcProvider = new JsonRpcProvider("https://arbitrum-sepolia.core.chainstack.com/2b730c29520dfd9e8de3f9e8d1b53284");
    providerForEvents = new JsonRpcProvider(web3Subs.currentProvider.host);
    providerForEvents._pollingInterval = 2000;
    providerForEvents._maxFilterBlockRange = 60;
  }

  if (isEthereumSepolia) {
    console.log("Using RPC endpoint for reading: ", web3read.currentProvider.host);
    web3ForRead = web3read;
  }

  if (isBaseSepolia) {
    console.log("Using RPC endpoint for reading: ", web3read.currentProvider.host);
    web3ForRead = web3read;
  }

  if (isBase) {
    console.log("Using RPC endpoint for reading: ", web3read.currentProvider.host);
    web3ForRead = web3read;
  }

  const lastBlock = await web3ForRead.eth.getBlockNumber();
  let firstBlock = 0;
  if (isEthereumSepolia) {
    firstBlock = SEPOLIA_FIRST_BLOCK;
  } else if (isArbitrumSepolia) {
    firstBlock = ARBITRUM_SEPOLIA_FIRST_BLOCK;
  } else if (isBaseSepolia) {
    firstBlock = BASE_SEPOLIA_FIRST_BLOCK;
  } else if (isBase) {
    firstBlock = BASE_MAINNET_FIRST_BLOCK;
  }
  const networkData = { firstBlock, lastBlock };

  // once we have gammaContractsRegistry, we can use it's address as db name, so that we can cashe on multiple networks
  const dbName = getMainContractAddress(networkId);
  setDBName(dbName);
  await addStores([
    "MarketFactory-MarketCreated",
    "Settlements-Settlement",
    "LPManager-allEvents",
    "SettlementManager-allEvents",
    "PositionsManager-allEvents",
    "UserBalances-allEvents",
    "MarginPool-allEvents",
    "UserBalancesVault-allEvents",
    "InsuranceFund-allEvents",
    "GammaContractsRegistry-getMultiple"
  ]);

  const { contracts, contractsRead } = await loadContracts(web3, web3ForRead, networkId, dispatch);

  console.log("Contracts loaded in: " + (new Date() - startTime) + " ms");
  dispatch(allContractsLoaded(contracts));
  let {
    gammaContractsRegistry,
    marketFactory,
    dateUtils,
    userBalances,
    tokenRegistry,
    positionsManager,
    marginPool,
    loadHelper,
    lpManager,
    settlementManager,
    userBalancesVault,
    settlements,
    wethToken,
    usdToken,
    insuranceFund
  } = contracts;

  // NOTE: use read loadHelper because it doesn't use events (for faster loading)
  loadHelper = contractsRead.loadHelper;

  const gammaContractsRegistryAddress = localStorage.getItem("gammaContractsRegistryAddress");
  if (gammaContractsRegistryAddress) {
    if (gammaContractsRegistryAddress !== gammaContractsRegistry._address) {
      localStorage.setItem("gammaContractsRegistryAddress", gammaContractsRegistry._address);
      localStorage.setItem("notifications", "{}");
    }
  } else {
    localStorage.setItem("gammaContractsRegistryAddress", gammaContractsRegistry._address);
  }

  // load token pairs
  const tokenPairs = await loadAllTokenPairs(provider, web3ForRead, loadHelper, tokenRegistry, userBalances, wethToken, usdToken, userAddress, dispatch);

  const riskFreeRate = tokenPairs[0].riskFreeRate / 100; // TODO: v1 this is a budzotina

  // todo: use price from loadLatestMarkets
  const underPriceBN = await updateUnderlyingMarketPrice(tokenPairs, loadHelper, dispatch); // returns avg underlying price

  // OLD CODE remove later
  // // eslint-disable-next-line no-undef
  // const [markets, settlementsStream] = await Promise.all([
  //   loadMarkets(networkData, loadHelper, riskFreeRate, marketFactory, underPriceBN, dispatch),
  //   loadSettleEvents(networkData, settlements, dispatch)
  // ]);

  // dispatch(
  //   marketsLoaded(
  //     markets.map(market => market.immutableAttributes),
  //     markets.map(market => market.mutableAttributes)
  //   )
  // );

  // NEW CODE
  const settlementsStream = await loadSettleEvents(networkData, settlements, dispatch);
  const markets = await loadLatestMarkets(networkData, loadHelper, riskFreeRate, marketFactory, underPriceBN, settlementsStream, dispatch);
  const marketsImmutable = markets.map(market => market.immutableAttributes);

  // my trades
  const trades = await loadTrades(networkData, positionsManager, lpManager, settlementManager, userBalances, userAddress, null, marketsImmutable);

  const { longShortTrades, lpTrades, liquidationTrades } = trades;

  batch(() => {
    dispatch(
      marketsLoaded(
        markets.map(market => market.immutableAttributes),
        markets.map(market => market.mutableAttributes)
      )
    );
    dispatch(tradesLoaded(longShortTrades));
    dispatch(lpTradesLoaded(lpTrades));
    dispatch(liquidationTradesLoaded(liquidationTrades));
    dispatch(marketsSettled(settlementsStream));
  });
  // position and lp tokens

  // eslint-disable-next-line no-undef
  await Promise.all([
    loadPositionTokens(
      provider,
      web3ForRead,
      loadHelper,
      userBalances,
      markets.map(m => m.immutableAttributes),
      userAddress,
      dispatch
    ),
    loadLPPositionTokens(networkData, web3ForRead, loadHelper, lpManager, marketsImmutable, userBalances, userAddress, underPriceBN, dispatch),
    loadMarginPoolEvents(networkData, marginPool, userBalances, lpManager, userAddress, dispatch),
    loadMarginPoolData(loadHelper, marginPool, underPriceBN, gammaContractsRegistry, dispatch),
    loadMarginAccountData(userAddress, loadHelper, marginPool, userBalances, underPriceBN, gammaContractsRegistry, dispatch),
    loadExpirationTimes(dateUtils, dispatch),
    loadDepositAndWithdrawalEvents(networkData, userBalancesVault, userAddress, dispatch),
    loadInsuranceFundEvents(networkData, insuranceFund, userAddress, dispatch)
  ]);

  const insuranceFundBalances = await loadInsuranceFundBalances(loadHelper);
  const insurerBalance = await loadInsurerBalance(loadHelper, userAddress);
  batch(() => {
    dispatch(insuranceFundLoaded(insuranceFundBalances));
    dispatch(insurerBalanceLoaded(insurerBalance));
  });

  subscribeToPositionsManagerEvents(
    providerForEvents,
    riskFreeRate,
    web3ForRead,
    positionsManager,
    lpManager,
    settlementManager,
    userAddress,
    marginPool,
    dispatch
  );
  subscribeToMarginPoolEvents(
    providerForEvents,
    web3ForRead,
    loadHelper,
    marginPool,
    underPriceBN,
    userBalances,
    gammaContractsRegistry,
    userAddress,
    dispatch
  );
  subscribeToMarketFactoryEvents(providerForEvents, riskFreeRate, web3ForRead, marketFactory, loadHelper, userBalances, userAddress, dispatch);
  subscribeToUserBalancesEvents(
    providerForEvents,
    web3ForRead,
    networkData,
    userAddress,
    userBalances,
    userBalancesVault,
    settlements,
    tokenRegistry,
    positionsManager,
    marginPool,
    gammaContractsRegistry,
    wethToken,
    usdToken,
    riskFreeRate,
    loadHelper,
    dispatch
  );
  subscribeToInsuranceFundEvents(providerForEvents, userAddress, insuranceFund, loadHelper, dispatch);
  subscribeToInsuranceFundLossesCoveredEvents(providerForEvents, userAddress, insuranceFund, loadHelper, dispatch);
  periodicallyUpdateMarketBasePrices(loadHelper, userBalances, marginPool, userAddress, dispatch);
  console.log("Main timer: " + (new Date() - startTime) + " ms");
};

export const subscribeToNewBlocks = async (web3, exchangeRegistry, positionsManager, userAccount, dispatch) => {
  let subscription = web3.eth
    .subscribe("newBlockHeaders", (error, result) => {
      if (!error) {
        console.log("New block");

        // TODO: v2 for now, just subscribe to new block, and do nothing
        // In production, this will be main method to sync with blockchain updates
        // not 30 seconds fetchers

        return;
      }

      console.error(error);
    })
    .on("connected", subscriptionId => {
      //console.log("Connected:", subscriptionId);
    })
    .on("error", console.error);
};
