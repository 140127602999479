import { get } from "lodash";
import { createSelector } from "reselect";

// contracts loaded
const contractsLoaded = state => get(state, "contracts.loaded");
export const contractsLoadedSelector = createSelector(contractsLoaded, loaded => loaded);

// gamma contracts registry
const gammaContractsRegistry = state => get(state, "contracts.data.gammaContractsRegistry", {});
export const gammaContractsRegistrySelector = createSelector(gammaContractsRegistry, contract => contract);

// uniswap adapter
const uniswapAdapter = state => get(state, "contracts.data.uniswapAdapter", {});
export const uniswapAdapterSelector = createSelector(uniswapAdapter, contract => contract);

// market registry
const marketRegistry = state => get(state, "contracts.data.marketRegistry", {});
export const marketRegistrySelector = createSelector(marketRegistry, contract => contract);

// market factory
const marketFactory = state => get(state, "contracts.data.marketFactory", {});
export const marketFactorySelector = createSelector(marketFactory, contract => contract);

// vol range market singleton
const volRangeMarketSingleton = state => get(state, "contracts.data.volRangeMarketSingleton", {});
export const volRangeMarketSingletonSelector = createSelector(volRangeMarketSingleton, contract => contract);

// date utils
const dateUtils = state => get(state, "contracts.data.dateUtils", {});
export const dateUtilsSelector = createSelector(dateUtils, contract => contract);

// price utils
const priceUtils = state => get(state, "contracts.data.priceUtils", {});
export const priceUtilsSelector = createSelector(priceUtils, contract => contract);

// dydx math
const dydxMath = state => get(state, "contracts.data.dydxMath", {});
export const dydxMathSelector = createSelector(dydxMath, contract => contract);

// user balances
const userBalances = state => get(state, "contracts.data.userBalances", {});
export const userBalancesSelector = createSelector(userBalances, contract => contract);

const userBalancesVault = state => get(state, "contracts.data.userBalancesVault", {});
export const userBalancesVaultSelector = createSelector(userBalancesVault, contract => contract);

// settlements
const settlements = state => get(state, "contracts.data.settlements", {});
export const settlementsSelector = createSelector(settlements, contract => contract);

// positions manager
const positionsManager = state => get(state, "contracts.data.positionsManager", {});
export const positionsManagerSelector = createSelector(positionsManager, contract => contract);

// lp manager
const lpManager = state => get(state, "contracts.data.lpManager", {});
export const lpManagerSelector = createSelector(lpManager, contract => contract);

// token registry
const tokenRegistry = state => get(state, "contracts.data.tokenRegistry", {});
export const tokenRegistrySelector = createSelector(tokenRegistry, contract => contract);

// position token registry
const positionTokenRegistry = state => get(state, "contracts.data.positionTokenRegistry", {});
export const positionTokenRegistrySelector = createSelector(positionTokenRegistry, contract => contract);

// position token registry
const marginPool = state => get(state, "contracts.data.marginPool", {});
export const marginPoolSelector = createSelector(marginPool, contract => contract);

// interest rate model
const interestRateModel = state => get(state, "contracts.data.interestRateModel", {});
export const interestRateModelSelector = createSelector(interestRateModel, contract => contract);

// load helper
const loadHelper = state => get(state, "contracts.data.loadHelper", {});
export const loadHelperSelector = createSelector(loadHelper, contract => contract);

// insurance fund
const insuranceFund = state => get(state, "contracts.data.insuranceFund", {});
export const insuranceFundSelector = createSelector(insuranceFund, contract => contract);
