import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { avgUnderlyingTokenPriceSelector, tokenBalancesSelector, tokenPairsLoadedSelector, tokensLoadedSelector } from "../../store/selectors/tokensSelectors";
import { formatValue, printPriceFromNumber } from "../../utils/utils.js";
import { marginAccountDataLoadedSelector, marginAccountDataSelector } from "../../store/selectors/marginSelectors";

class BorrowHoverPanel extends React.Component {
  getAccountBorrowBalance = assetSymbol => {
    if (this.props.marginAccountDataLoaded) {
      return this.props.marginAccountData.collateralTokens.find(data => data.symbol === assetSymbol).normalizedBorrowedAmount;
    }
    return 0;
  };

  render() {
    if (!this.props.marginAccountDataLoaded || !this.props.tokenPairsLoaded) return null;

    const style = { top: this.props.top, left: this.props.left };

    const debtETH = Math.abs(this.getAccountBorrowBalance("ETH"));
    const debtUSD = Math.abs(this.getAccountBorrowBalance("USD"));

    const debtUSDprintable = printPriceFromNumber(debtUSD, 2);
    const underlyingTokenPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    const debtETHinUSD = debtETH * underlyingTokenPrice;
    const debtETHinUSDPrintable = printPriceFromNumber(debtETHinUSD, 2);

    const total = debtETHinUSD + debtUSD;
    const totalPrintable = printPriceFromNumber(total, 2);

    return (
      <div
        className="borrowHoverPanelContainer"
        style={style}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <table className="borrowHoverPanelTable">
          <tr>
            <td className="borrowHoverPanelTableItem">USD</td>
            <td className="borrowHoverPanelTableItem">{formatValue(debtUSD, 2)}</td>
            <td className="borrowHoverPanelTableItem">{debtUSDprintable}</td>
          </tr>
          <tr>
            <td className="borrowHoverPanelTableItem">ETH</td>
            <td className="borrowHoverPanelTableItem">{formatValue(debtETH, 2)}</td>
            <td className="borrowHoverPanelTableItem">{debtETHinUSDPrintable}</td>
          </tr>
          <tr>
            <td className="borrowHoverPanelTableItem">Total</td>
            <td className="borrowHoverPanelTableItem" />
            <td className="borrowHoverPanelTableItem">{totalPrintable}</td>
          </tr>
        </table>
      </div>
    );
  }
}

BorrowHoverPanel.propTypes = {
  tokensLoaded: PropTypes.bool,
  tokens: PropTypes.array,
  tokenPairsLoaded: PropTypes.bool,
  marginAccountDataLoaded: PropTypes.bool,
  marginAccountData: PropTypes.object,
  underlyingTokenPrice: PropTypes.object,
  top: PropTypes.object,
  left: PropTypes.object
};

function mapStateToProps(state) {
  return {
    tokensLoaded: tokensLoadedSelector(state),
    tokens: tokenBalancesSelector(state),
    tokenPairsLoaded: tokenPairsLoadedSelector(state),
    marginAccountDataLoaded: marginAccountDataLoadedSelector(state),
    marginAccountData: marginAccountDataSelector(state),
    underlyingTokenPrice: avgUnderlyingTokenPriceSelector(state)
  };
}

export default connect(mapStateToProps)(BorrowHoverPanel);
