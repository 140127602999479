import React from "react";
import PropTypes from "prop-types";

class MySwitch extends React.Component {
  constructor(props) {
    super(props);
  }

  handleKeyDown = e => {
    if (e.keyCode !== 32 || e.keyCode === 27) return;

    e.preventDefault();
    this.props.onChange(!this.props.checked);
  };

  render() {
    return (
      <div className={"toggle-switch small-switch"}>
        <input
          type="checkbox"
          name={this.props.name}
          className="toggle-switch-checkbox"
          checked={this.props.checked}
          onChange={e => this.props.onChange(e)}
          disabled={this.props.disabled}
          id={this.props.id}
        />
        <label className="toggle-switch-label" tabIndex={this.props.disabled ? -1 : 1} onKeyDown={e => this.handleKeyDown(e)} htmlFor={this.props.id}>
          <span className={this.props.disabled ? "toggle-switch-inner toggle-switch-disabled" : "toggle-switch-inner"} tabIndex={-1} />
          {this.props.isAllNew ? (
            <span className={"toggle-switch-switch" + (this.props.disabled ? " toggle-switch-disabled" : "")} tabIndex={-1} />
          ) : (
            <span
              className={"toggle-switch-switch" + (this.props.disabled ? " toggle-switch-disabled" : "") + (!this.props.checked ? " noChecked" : "")}
              tabIndex={-1}
            />
          )}
        </label>
      </div>
    );
  }
}

MySwitch.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.object,
  id: PropTypes.object,
  isAllNew: PropTypes.bool
};

export default MySwitch;
