import { Promise } from "bluebird";

export const loadMarginPoolData2 = async (loadHelper, marginPool, underPriceBN, tokens) => {
  try {
    // use state to get token addresses
    const collateralTokens = tokens.data.map(t => t.address);
    const referenceTokenAddress = tokens.data.find(t => t.symbol === "USD").address;

    const marginData = await Promise.map(collateralTokens, async (token, i) => {
      console.log("RPC method: loadHelper.loadMarginPoolData");
      const result = await loadHelper.methods.loadMarginPoolData(marginPool._address, token).call(); // TODO: this can be optimized - send array, return array
      let price;
      if (token.toString() === referenceTokenAddress.toString()) {
        price = 10 ** 18;
      } else {
        price = underPriceBN;
      }
      return {
        assetAddress: token,
        borrowedAmount: result.totalBorrowedAmount,
        currentSupply: result.availableUnderlyingAmount,
        interestRate: result.interestRate,
        price
      };
    });
    return marginData;
    // dispatch(marginPoolDataLoaded(marginData));
  } catch (e) {
    console.log(e);
    // handleNewError({}, "Error while fetching margin pool data. Please reload the application!", 1, dispatch);
  }
};

export const loadMarginAccountData2 = async (account, loadHelper, marginPool, userBalances, underPriceBN, tokens) => {
  try {
    const referenceTokenAddress = tokens.data.find(t => t.symbol === "USD").address;

    console.log("RPC method: loadHelper.loadMarginAccountData problem");
    const result = await loadHelper.methods.loadMarginAccountData(marginPool._address, userBalances._address, account, underPriceBN).call();
    let collateralTokens = result.collateralTokens;
    collateralTokens = await Promise.map(collateralTokens, async (token, i) => {
      let price;
      if (token.collateralToken.toString() === referenceTokenAddress.toString()) {
        price = 10 ** 18;
      } else {
        price = underPriceBN;
      }
      return {
        assetAddress: token.collateralToken,
        borrowedAmount: token.borrowedAmount,
        lendProceeds: token.lendProceeds,
        price
      };
    });
    const marginAccountData = {
      collateralTokens,
      health: result.health,
      isLiquidatable: result.isLiquidatable,
      liquidationThreshold: result.liquidationThreshold,
      recoveryThreshold: result.recoveryThreshold,
      totalLiquidity: result.totalLiquidity,
      totalDebt: result.totalDebt
    };
    return marginAccountData;
    // dispatch(marginAccountDataLoaded(marginAccountData));
  } catch (e) {
    console.log(e);
    // todo: see how errors are handled outside, should not be dispatched from here
    // handleNewError({}, "Error while fetching margin account data. Please reload the application!", 1, dispatch);
  }
};
