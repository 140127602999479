import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, FormGroup, Input } from "reactstrap";
import { NavLink } from "react-router-dom";
import MyModal from "./utilities/MyModal.jsx";
import { web3AccountLoadedSelector, web3AccountSelector, web3LoadedSelector, web3LoadingSelector, web3Selector } from "../../store/selectors/web3Selectors";
import {
  healthClassName,
  printTotalApr,
  defaultInputValue,
  removeCommaLocales,
  isHex,
  isPositiveNumber,
  displayLoadingIconOld,
  formatValue,
  inputDecimals,
  isSmallDifference,
  replaceCharacter,
  getReadableAccountHealth,
  printPriceFromString,
  printPriceFromNumber
} from "../../utils/utils.js";
import jazzicon from "@metamask/jazzicon";
import parse from "html-react-parser";
import { insuranceFundSelector, marginPoolSelector, positionsManagerSelector, userBalancesSelector } from "../../store/selectors/contractsSelectors";
import { selectedTokenPairSelector, tokenBalancesSelector, tokensLoadedSelector, avgUnderlyingTokenPriceSelector } from "../../store/selectors/tokensSelectors";
import { getAPR, deposit, redeem } from "../../store/interactions/marginInteractions.js";
import {
  marginAccountDataSelector,
  marginPoolDataLoadedSelector,
  marginPoolDataSelector,
  marginAccountDataLoadedSelector,
  lendPremiumsAndBorrowBasesSelector
} from "../../store/selectors/marginSelectors.js";
import TotalLiquidityLegend from "./TotalLiquidityLegend.jsx";
import MySwitch from "./MySwitch.jsx";
import { formatUnits } from "@ethersproject/units";
import { redeemFromMarginPool, transferToMarginPool } from "../../store/interactions/balancesInteractions.js";
import { fromBn, toBn } from "evm-bn";
import MyTooltip from "./MyTooltip.jsx";
import ReactGA from "react-ga4";
import { insuranceFundLoadedSelector, insuranceFundWithUnclaimedSelector } from "../../store/selectors/insuranceFundSelector.js";
let lastSelectedInputTimestamp = null;
let lastClickedModalOverlay = null;

class LendLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      buttonTextClicked: "",
      amount: "1",
      originalAmount: "1", //amount which is sent to smart contract
      insuranceFundAmount: "",
      inputClass: "",
      inputErrorMessage: "",
      totalLiquidityLegendOpen: false,
      totalLiquidityLegendRight: 0,
      totalLiquidityLegendTop: 0,
      lendOrBorrow: "",
      apr: null,
      token: "",
      fromWallet: false,
      shouldRepayEntireDebt: false,
      step: null,
      idPrefix: "lend-page-",
      minUtilRatio: 0.85
    };
    this.modalActionButton = React.createRef(null);
    this.totalLiquidityInfoRef = React.createRef(null);
    this.lendModalInputRef = React.createRef(null);
    this.onAmountChange = this.onAmountChange.bind(this);
    this.modalActionButtonClicked = this.modalActionButtonClicked.bind(this);
    this.printAccountBorrowValue = this.printAccountBorrowValue.bind(this);
    this.printAccountLendValue = this.printAccountLendValue.bind(this);
    this.printAccountBorrowBalance = this.printAccountBorrowBalance.bind(this);
    this.printAccountLendBalance = this.printAccountLendBalance.bind(this);
    this.printAccountPremium = this.printAccountPremium.bind(this);
    this.printAccountBaseDebt = this.printAccountBaseDebt.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  async componentDidMount() {
    if (this.totalLiquidityInfoRef.current) {
      this.totalLiquidityInfoRef.current.addEventListener("mouseenter", this.totalLiquidityMouseEnterHandler);
      this.totalLiquidityInfoRef.current.addEventListener("mouseleave", this.totalLiquidityMouseLeaveHandler);
    }

    if (this.state.modalIsOpen) {
      document.onkeydown = this.onKeyPress;
    }

    if (this.lendModalInputRef.current) {
      this.lendModalInputRef.current.addEventListener("paste", this.paste);
    }

    if (this.props.tokensLoaded) {
      const tokenETH = this.props.tokens.find(t => t.symbol === "ETH");
      const tokenUSD = this.props.tokens.find(t => t.symbol === "USD");
      if (
        tokenETH.accountBalance == "0" &&
        tokenETH.walletBalance == "0" &&
        tokenUSD.accountBalance == "0" &&
        tokenUSD.walletBalance == "0" &&
        (this.props.web3.currentProvider.networkVersion === "7933" || this.props.web3.currentProvider.networkVersion === "11155111")
      ) {
        window.location.href = "/portfolio";
      }
    }

    console.log("Navigate to: " + window.location.pathname); // eslint-disable-line no-console
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Lend" });
  }

  async componentDidUpdate(prevProps, prevState) {
    let tokenETH;
    let tokenUSD;
    if (this.props.tokensLoaded) {
      tokenETH = this.props.tokens.find(t => t.symbol === "ETH");
      tokenUSD = this.props.tokens.find(t => t.symbol === "USD");
      if (
        tokenETH.accountBalance == "0" &&
        tokenETH.walletBalance == "0" &&
        tokenUSD.accountBalance == "0" &&
        tokenUSD.walletBalance == "0" &&
        (this.props.web3.currentProvider.networkVersion === "7933" || this.props.web3.currentProvider.networkVersion === "11155111")
      ) {
        window.location.href = "/portfolio";
      }
    }

    let amount = this.state.originalAmount;
    let shouldRepayEntireDebt = this.state.shouldRepayEntireDebt;
    if (amount === "") {
      amount = defaultInputValue.toString();
    } else if (this.state.inputClass === "has-danger") {
      amount = this.calculateMax().maxValue;
      shouldRepayEntireDebt = true;
    }

    if (
      (prevState.modalIsOpen !== this.state.modalIsOpen && this.state.modalIsOpen) ||
      prevState.amount !== this.state.amount ||
      prevState.fromWallet !== this.state.fromWallet ||
      (this.state.inputClass === "has-danger" && this.state.inputClass !== prevState.inputClass && this.state.inputErrorMessage !== prevState.inputErrorMessage)
    ) {
      if (this.getNormalizedAvailableSupply() && this.getNormalizedBorrowedAmount()) {
        const apr = getAPR(
          this.getNormalizedAvailableSupply(),
          this.getNormalizedBorrowedAmount(),
          Number(amount),
          this.state.buttonTextClicked,
          this.props.dispatch,
          shouldRepayEntireDebt
        );
        this.setState({ apr });
      }

      if (this.lendModalInputRef.current) {
        this.lendModalInputRef.current.addEventListener("paste", this.paste);
      }
    }

    if (this.state.modalIsOpen !== prevState.modalIsOpen && this.lendModalInputRef.current) {
      this.lendModalInputRef.current.select();
    }

    if (this.state.modalIsOpen) {
      document.onkeydown = this.onKeyPress;
    }

    if (this.state.modalIsOpen !== prevState.modalIsOpen) {
      this.setState({ fromWallet: false });
    }

    if (this.state.fromWallet !== prevState.fromWallet && this.lendModalInputRef.current && this.state.buttonTextClicked === "Lend") {
      // max value on lend from wallet, 1 on lend from account
      if (!this.state.fromWallet) {
        this.setState({ inputClass: "", amount: "1", originalAmount: "1" });
        this.lendModalInputRef.current.value = "1";
      } else {
        this.onMaxButtonClicked();
      }
      this.lendModalInputRef.current.select();
    }

    if (this.state.fromWallet !== prevState.fromWallet || this.state.modalIsOpen !== prevState.modalIsOpen) {
      this.setState({ step: null });
    }

    if (
      // always max value on repay and redeem
      (prevState.buttonTextClicked !== this.state.buttonTextClicked &&
        (this.state.buttonTextClicked === "Repay" || this.state.buttonTextClicked === "Redeem")) ||
      (this.state.buttonTextClicked === "Repay" && !this.state.fromWallet && prevState.fromWallet)
    ) {
      this.onMaxButtonClicked();
      this.lendModalInputRef.current.select();
    }

    if (this.state.modalIsOpen === false && prevState.modalIsOpen === true) {
      this.setState({ shouldRepayEntireDebt: false });
    }

    if (
      ((this.state.modalIsOpen !== prevState.modalIsOpen && this.state.modalIsOpen) || this.state.fromWallet !== prevState.fromWallet) &&
      this.lendModalInputRef.current
    ) {
      if (this.state.fromWallet) {
        if (this.state.token === "USD") {
          if (tokenUSD.normalizedWalletBalance < 1) {
            this.setState({ amount: tokenUSD.normalizedWalletBalance2Decimals, originalAmount: fromBn(tokenUSD.walletBalance) });
            this.lendModalInputRef.current.value = tokenUSD.normalizedWalletBalance2Decimals;
          }
        } else {
          if (tokenETH.normalizedWalletBalance < 1) {
            this.setState({ amount: "0", originalAmount: "0" });
            this.lendModalInputRef.current.value = "0";
          }
        }
      } else {
        if (this.state.token === "USD" && this.state.buttonTextClicked !== "Redeem") {
          if (tokenUSD.normalizedAccountBalance < 1) {
            this.setState({ amount: tokenUSD.normalizedAccountBalance2Decimals, originalAmount: fromBn(tokenUSD.accountBalance) });
            this.lendModalInputRef.current.value = tokenUSD.normalizedAccountBalance2Decimals;
          }
        } else if (this.state.token === "ETH" && this.state.buttonTextClicked !== "Redeem") {
          if (tokenETH.normalizedAccountBalance < 1) {
            this.setState({ amount: "0", originalAmount: "0" });
            this.lendModalInputRef.current.value = "0";
          }
        }
      }
      this.lendModalInputRef.current.select();
      this.onAmountChange();
    }
  }

  componentWillUnmount() {
    if (this.totalLiquidityInfoRef.current) {
      this.totalLiquidityInfoRef.current.removeEventListener("mouseenter", this.totalLiquidityMouseEnterHandler);
      this.totalLiquidityInfoRef.current.removeEventListener("mouseleave", this.totalLiquidityMouseLeaveHandler);
    }

    document.onkeydown = null;
  }

  paste = e => {
    e.stopPropagation();
    e.preventDefault();

    const selection = window.getSelection().toString();

    const clipboardData = e.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData("Text");
    pastedData = removeCommaLocales(pastedData);
    let newPastedData;
    if (selection !== "") {
      newPastedData = inputDecimals(
        replaceCharacter(
          this.lendModalInputRef.current.value,
          this.lendModalInputRef.current.selectionStart,
          this.lendModalInputRef.current.selectionEnd - 1,
          pastedData
        ),
        this.state.token
      );
    } else {
      newPastedData = inputDecimals(this.lendModalInputRef.current.value + pastedData, this.state.token);
    }
    if (newPastedData !== undefined && newPastedData !== null && !isHex(newPastedData)) {
      if (selection !== "") {
        this.lendModalInputRef.current.value = replaceCharacter(
          this.lendModalInputRef.current.value,
          this.lendModalInputRef.current.selectionStart,
          this.lendModalInputRef.current.selectionEnd - 1,
          pastedData
        );
      } else {
        this.lendModalInputRef.current.value = this.lendModalInputRef.current.value + pastedData;
      }
    }
    this.onAmountChange();
  };

  onKeyPress = e => {
    if (e.key === "Escape") {
      this.closeModal();
    }
  };

  setStep = value => {
    this.setState({ step: value });
  };

  totalLiquidityMouseEnterHandler = e => {
    const { top, right } = e.currentTarget.getBoundingClientRect();
    this.setState({ totalLiquidityLegendOpen: true, totalLiquidityLegendRight: window.innerWidth - right, totalLiquidityLegendTop: top + 20 });
  };

  totalLiquidityMouseLeaveHandler = e => {
    this.setState({ totalLiquidityLegendOpen: false, totalLiquidityLegendRight: 0, totalLiquidityLegendTop: 0 });
  };

  closeModal = () => {
    document.querySelector("body").classList.remove("scrollDisabled");
    this.setState({ modalIsOpen: false, buttonTextClicked: "" });

    console.log("Navigate to: " + window.location.pathname); // eslint-disable-line no-console
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Lend" });
  };

  openModal = (e, lendOrBorrow, token) => {
    let buttonTextClicked = e.target.textContent.trim().toLowerCase();
    buttonTextClicked = buttonTextClicked[0].toUpperCase() + buttonTextClicked.slice(1);
    document.querySelector("body").classList.toggle("scrollDisabled");
    this.setState({
      amount: "1",
      inputClass: "has-success",
      buttonTextClicked,
      modalIsOpen: true,
      lendOrBorrow: lendOrBorrow,
      token: token
    });

    const chunk = buttonTextClicked;
    console.log("Navigate to: " + window.location.pathname + "/" + chunk.toLowerCase()); // eslint-disable-line no-console
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + "/" + chunk, title: chunk + " Modal" });
  };

  onAmountChange = e => {
    //TODO: try/catch block as temporary prevention for large number entered in input because when using toBn it will crash
    try {
      if (this.lendModalInputRef.current) {
        const inputString = this.lendModalInputRef.current.value.trim();

        const value = inputDecimals(inputString, this.state.token);
        if (value === undefined || value === null) {
          return;
        }

        this.setState({ amount: inputString });

        if (value === "") {
          this.setState({ inputClass: "", inputErrorMessage: "" });
          return;
        }

        if (!isPositiveNumber(value)) {
          this.setState({
            inputClass: "has-danger",
            inputErrorMessage: "Enter a positive number"
          });
          return;
        }

        const token = this.props.tokens.find(t => t.symbol === this.state.token);

        let amountSplit = value.split(".");
        const newAmountSplit = amountSplit.length === 1 || amountSplit[1] === "" ? amountSplit[0] : amountSplit[0] + "." + amountSplit[1];

        let accountBalance = token && fromBn(token.accountBalance);
        let walletBalance = token && fromBn(token.walletBalance18Decimals);
        if (this.state.fromWallet && this.state.buttonTextClicked !== "Redeem") {
          if (walletBalance <= 0 || Number(newAmountSplit) > Number(walletBalance)) {
            this.setState({ inputClass: "has-danger", inputErrorMessage: "You don't have enough funds" });
            return;
          }
        } else if (!this.state.fromWallet && this.state.buttonTextClicked !== "Redeem") {
          if (accountBalance <= 0 || Number(newAmountSplit) > Number(accountBalance)) {
            this.setState({ inputClass: "has-danger", inputErrorMessage: "You don't have enough funds" });
            return;
          }
        }

        if (this.state.buttonTextClicked === "Redeem") {
          const currentSupply = this.props.marginPoolData.tokensData.find(data => data.symbol === this.state.token).normalizedCurrentSupply;
          const readableCurrentSupply = this.props.marginPoolData.tokensData.find(data => data.symbol === this.state.token).readableLendSupply;
          this.setState({ shouldRepayEntireDebt: false });
          const lendBalance = this.props.marginAccountData.collateralTokens.find(data => data.symbol === this.state.token).lendProceeds;

          if (isSmallDifference(Number(fromBn(lendBalance)), Number(newAmountSplit), this.state.token)) {
            this.setState({ shouldRepayEntireDebt: true });
          }
          if (
            Number(fromBn(lendBalance)) < Number(value) &&
            !isSmallDifference(Number(fromBn(lendBalance)), Number(newAmountSplit), this.state.token) &&
            this.state.inputErrorMessage !== "You don't have enough funds"
          ) {
            this.setState({
              inputClass: "has-danger",
              inputErrorMessage: "Can't redeem more than " + Number(fromBn(lendBalance)).toFixed(this.state.token === "USD" ? 2 : 4)
            });
            return;
          }

          if (newAmountSplit > currentSupply) {
            this.setState({ inputClass: "has-danger", inputErrorMessage: "Current supply is " + readableCurrentSupply });
            return;
          }
        }

        const excessLiquidity = this.props.marginAccountData && this.props.web3AccountLoaded && this.props.marginAccountData.normalizedExcessLiquidity;

        const amount = token && fromBn(token.accountBalance);

        if (this.state.buttonTextClicked === "Repay") {
          this.setState({ shouldRepayEntireDebt: false });

          const funds = this.state.fromWallet ? fromBn(token.walletBalance18Decimals) : fromBn(token.accountBalance);
          const debt = fromBn(this.props.marginAccountData.collateralTokens.find(t => t.symbol === this.state.token).borrowedAmount);
          const supply = this.props.marginPoolData.tokensData.find(t => t.symbol === this.state.token).normalizedBorrowedAmount;

          const excessLiquidityInEth = excessLiquidity / this.props.underlyingTokenPrice.normalized;

          let min = this.state.token === "USD" ? fromBn(toBn(excessLiquidity.toString(), 18)) : excessLiquidityInEth;
          if (toBn(funds.toString(), 18).lt(toBn(min.toString(), 18))) {
            min = funds;
          }
          if (toBn(debt.toString(), 18).lt(toBn(min.toString(), 18))) {
            min = debt;
          }
          if (supply < min) {
            min = supply;
          }
          let maxValue = min;

          if (this.state.fromWallet) {
            maxValue = debt;
          }
          if (Number(newAmountSplit) > Number(maxValue) && !isSmallDifference(Number(newAmountSplit), Number(maxValue), this.state.token)) {
            this.setState({
              inputClass: "has-danger",
              inputErrorMessage: "Can't repay more than " + Number(maxValue).toFixed(this.state.token === "USD" ? 2 : 4)
            });
            return;
          }

          if (isSmallDifference(Number(newAmountSplit), Number(maxValue), this.state.token)) {
            this.setState({ shouldRepayEntireDebt: true });
          }
        }

        if (!this.state.fromWallet) {
          if (this.state.token === "USD") {
            if (
              excessLiquidity < Number(amount) &&
              Number(newAmountSplit) > excessLiquidity &&
              !isSmallDifference(excessLiquidity, Number(newAmountSplit), this.state.token)
            ) {
              this.setState({
                inputClass: "has-danger",
                inputErrorMessage:
                  "Your excess liquidity is " +
                  printPriceFromString(
                    Number(excessLiquidity)
                      .toFixed(this.state.token === "USD" ? 2 : 4)
                      .toString()
                  )
              });
              return;
            }
          } else if (this.state.token === "ETH") {
            if (
              toBn(excessLiquidity.toString(), 18).lt(toBn(amount.toString(), 18)) &&
              toBn((Number(this.state.amount) * Number(this.props.underlyingTokenPrice.normalized)).toString(), 18).gt(toBn(excessLiquidity.toString(), 18)) &&
              !isSmallDifference(Number(this.state.amount) * Number(this.props.underlyingTokenPrice.normalized), excessLiquidity, this.state.token)
            ) {
              this.setState({
                inputClass: "has-danger",
                inputErrorMessage:
                  "Your excess liquidity is " +
                  printPriceFromString(
                    Number(excessLiquidity)
                      .toFixed(this.state.token === "USD" ? 2 : 4)
                      .toString()
                  )
              });
              return;
            }
          }
        }
        const { maxOriginal } = this.calculateMax();

        if (this.state.buttonTextClicked === "Lend") {
          this.setState({ shouldRepayEntireDebt: false });
        }

        if (isSmallDifference(newAmountSplit, maxOriginal, this.state.token)) {
          this.setState({ originalAmount: fromBn(toBn(maxOriginal.toString(), 18)) });
        } else {
          this.setState({ originalAmount: fromBn(toBn(newAmountSplit.toString(), 18)) });
        }

        this.setState({ inputClass: "has-success", inputErrorMessage: "" });
      }
    } catch (e) {
      console.log(e);
    }
  };

  modalActionButtonClicked = e => {
    e.stopPropagation();
    if (isNaN(this.state.amount)) return;
    let assetAddress;

    if (this.state.lendOrBorrow === "lend") {
      if (this.state.token === "ETH") {
        assetAddress = this.props.selectedTokenPair.underlyingTokenAddress;
      } else if (this.state.token === "USD") {
        assetAddress = this.props.selectedTokenPair.baseTokenAddress;
      }
    } else if (this.state.lendOrBorrow === "borrow") {
      if (this.state.token === "ETH") {
        assetAddress = this.props.selectedTokenPair.underlyingTokenAddress;
      } else if (this.state.token === "USD") {
        assetAddress = this.props.selectedTokenPair.baseTokenAddress;
      }
    }
    const amount = this.props.web3.utils.toWei(this.state.originalAmount.toString());
    if (this.state.buttonTextClicked === "Lend") {
      if (this.state.fromWallet) {
        deposit(
          this.props.web3,
          this.props.marginPool,
          assetAddress,
          amount,
          this.props.web3Account,
          this.state.token,
          this.closeModal,
          true,
          this.state.shouldRepayEntireDebt,
          this.setStep,
          this.props.dispatch
        );
      } else {
        transferToMarginPool(
          this.props.web3,
          this.props.userBalances,
          assetAddress,
          amount,
          this.props.web3Account,
          this.state.token,
          this.closeModal,
          this.state.shouldRepayEntireDebt,
          this.props.dispatch
        );
      }
    }

    if (this.state.buttonTextClicked === "Redeem") {
      if (this.state.fromWallet) {
        redeem(
          this.props.web3,
          this.props.marginPool,
          assetAddress,
          amount,
          this.props.web3Account,
          this.state.token,
          this.closeModal,
          this.state.shouldRepayEntireDebt,
          this.props.dispatch
        );
      } else {
        redeemFromMarginPool(
          this.props.web3,
          this.props.userBalances,
          assetAddress,
          amount,
          this.props.web3Account,
          this.state.token,
          this.closeModal,
          this.state.shouldRepayEntireDebt,
          this.props.dispatch
        );
      }
    }

    if (this.state.buttonTextClicked === "Repay") {
      if (this.state.fromWallet) {
        deposit(
          this.props.web3,
          this.props.marginPool,
          assetAddress,
          amount,
          this.props.web3Account,
          this.state.token,
          this.closeModal,
          false,
          this.state.shouldRepayEntireDebt,
          this.setStep,
          this.props.dispatch
        );
      } else {
        transferToMarginPool(
          this.props.web3,
          this.props.userBalances,
          assetAddress,
          amount,
          this.props.web3Account,
          this.state.token,
          this.closeModal,
          this.state.shouldRepayEntireDebt,
          this.props.dispatch
        );
      }
    }
  };

  printInterestRate = (token, isLend) => {
    let tokensData;
    if (this.props.marginPoolDataLoaded) {
      if (token === "ETH") {
        tokensData = this.props.marginPoolData.tokensData.find(data => data.symbol === "ETH");
      } else if (token === "USD") {
        tokensData = this.props.marginPoolData.tokensData.find(data => data.symbol === "USD");
      }
      return isLend ? tokensData.readableLendingInterestRate : "-" + tokensData.readableBorrowInterestRate;
    }
    return displayLoadingIconOld();
  };

  getCurrentAssetTotalSupply = token => {
    if (this.props.marginPoolDataLoaded) {
      if (token === "ETH") {
        return this.props.marginPoolData.tokensData.find(data => data.symbol === "ETH").normalizedCurrentSupply;
      } else if (token === "USD") {
        return this.props.marginPoolData.tokensData.find(data => data.symbol === "USD").normalizedCurrentSupply;
      }
    }
    return 0;
  };

  printCurrentAssetTotalSupply = token => {
    if (this.props.marginPoolDataLoaded) {
      if (token === "ETH") {
        return this.props.marginPoolData.tokensData.find(data => data.symbol === "ETH").readableLendSupply;
      } else if (token === "USD") {
        return this.props.marginPoolData.tokensData.find(data => data.symbol === "USD").readableLendSupply;
      }
    }
    return displayLoadingIconOld();
  };

  getNormalizedAvailableSupply = () => {
    if (this.props.marginPoolDataLoaded) {
      if (this.state.token === "ETH") {
        const normalizedCurrentSupply = this.getCurrentAssetTotalSupply("ETH");
        const normalizedBorrowedAmount = this.getNormalizedBorrowedAmount("ETH");
        return normalizedCurrentSupply - normalizedBorrowedAmount;
      } else if (this.state.token === "USD") {
        const normalizedCurrentSupply = this.getCurrentAssetTotalSupply("USD");
        const normalizedBorrowedAmount = this.getNormalizedBorrowedAmount("USD");
        return normalizedCurrentSupply - normalizedBorrowedAmount;
      }
    }
  };

  getNormalizedBorrowedAmount = () => {
    if (this.props.marginPoolDataLoaded) {
      if (this.state.token === "ETH") {
        return this.props.marginPoolData.tokensData.find(data => data.symbol === "ETH").normalizedBorrowedAmount;
      } else if (this.state.token === "USD") {
        return this.props.marginPoolData.tokensData.find(data => data.symbol === "USD").normalizedBorrowedAmount;
      }
    }
  };

  printBorrowAmount = token => {
    if (this.props.marginPoolDataLoaded) {
      if (token === "ETH") {
        return this.props.marginPoolData.tokensData.find(data => data.symbol === "ETH").readableBorrowedAmount;
      } else if (token === "USD") {
        return this.props.marginPoolData.tokensData.find(data => data.symbol === "USD").readableBorrowedAmount;
      }
    }
    return displayLoadingIconOld();
  };

  printTotalSupply = () => {
    if (this.props.marginPoolDataLoaded) {
      return "$" + this.props.marginPoolData.readableTotalCurrentSupply;
    }
    return displayLoadingIconOld();
  };

  printTotalBorrowAmount = () => {
    if (this.props.marginPoolDataLoaded) {
      return "$" + this.props.marginPoolData.readableTotalBorrowedAmount;
    }
    return displayLoadingIconOld();
  };

  printTotalBorrowInterestRate = () => {
    if (this.props.marginPoolDataLoaded) {
      return "(+ " + this.props.marginPoolData.readableTotalBorrowInterestRate + ")";
    }
    return "";
  };

  printTotalLendingInterestRate = () => {
    if (this.props.marginPoolDataLoaded) {
      return "(+ " + this.props.marginPoolData.readableTotalLendingInterestRate + ")";
    }
    return "";
  };

  getAccountLendBalance = assetSymbol => {
    if (this.props.marginAccountDataLoaded) {
      return this.props.marginAccountData.collateralTokens.find(data => data.symbol === assetSymbol).normalizedLendProceeds;
    }
    return 0;
  };

  printAccountLendBalance = assetSymbol => {
    if (this.props.marginAccountDataLoaded) {
      const balance = this.getAccountLendBalance(assetSymbol);
      if (balance > 0) {
        return this.props.marginAccountData.collateralTokens.find(data => data.symbol === assetSymbol).readableLendProceeds;
      } else {
        return null;
      }
    }
    return displayLoadingIconOld();
  };

  getAccountBorrowBalance = assetSymbol => {
    if (this.props.marginAccountDataLoaded) {
      return this.props.marginAccountData.collateralTokens.find(data => data.symbol === assetSymbol).normalizedBorrowedAmount;
    }
    return 0;
  };

  printAccountBorrowBalance = assetSymbol => {
    if (this.props.marginAccountDataLoaded) {
      const balance = this.getAccountBorrowBalance(assetSymbol);
      if (balance < 0 && !isSmallDifference(balance, 0, assetSymbol)) {
        const readableBorrowedAmount = this.props.marginAccountData.collateralTokens.find(data => data.symbol === assetSymbol).readableBorrowedAmount;
        return readableBorrowedAmount;
      } else {
        return null;
      }
    }
    return displayLoadingIconOld();
  };

  printAccountLendValue = assetSymbol => {
    if (this.props.marginAccountDataLoaded) {
      const value = this.props.marginAccountData.collateralTokens.find(data => data.symbol === assetSymbol).normalizedValueInReferenceToken;
      if (value > 0) {
        return printPriceFromString(this.props.marginAccountData.collateralTokens.find(data => data.symbol === assetSymbol).readableValueInReferenceToken);
      } else {
        return null;
      }
    }
    return displayLoadingIconOld();
  };

  printAccountBorrowValue = assetSymbol => {
    if (this.props.marginAccountDataLoaded) {
      const value = this.props.marginAccountData.collateralTokens.find(data => data.symbol === assetSymbol).normalizedDebtInReferenceToken;
      if (value < 0 && !isSmallDifference(value, 0, "USD")) {
        return printPriceFromString(this.props.marginAccountData.collateralTokens.find(data => data.symbol === assetSymbol).readableDebtInReferenceToken);
      } else {
        return null;
      }
    }
    return displayLoadingIconOld();
  };

  printAccountPremium = assetSymbol => {
    if (this.props.marginAccountDataLoaded) {
      if (this.props.lendPremiumsAndBorrowBases) {
        const value = this.props.lendPremiumsAndBorrowBases.get(assetSymbol);
        if (value && value.readablePremium !== "0") {
          return value.readablePremium;
        }
      }
      return "";
    }
    return displayLoadingIconOld();
  };

  printAccountBaseDebt = assetSymbol => {
    if (this.props.marginAccountDataLoaded) {
      if (this.props.lendPremiumsAndBorrowBases) {
        const value = this.props.lendPremiumsAndBorrowBases.get(assetSymbol);
        if (value && value.readableBase !== "0") {
          return value.readableBase;
        }
      }
      return "";
    }
    return displayLoadingIconOld();
  };

  printInsuranceFundAmount = () => {
    if (this.props.insuranceFundLoaded) {
      return printPriceFromNumber(this.props.insuranceFundWithUnclaimed);
    }

    return displayLoadingIconOld();
  };

  printPoolShare = (poolShare, shouldBeDisabledColor = false) => {
    if (this.state.lendOrBorrow === "lend") {
      return (
        <div className="poolShareContainer">
          <span id={this.state.idPrefix + "pool-share"} className={`portfolioLendSmallLine${shouldBeDisabledColor ? " disabledTextColor" : ""}`}>Pool share</span>
          <span className={`portfolioLendBigLine${shouldBeDisabledColor ? " disabledTextColor" : ""}`}>{poolShare}</span>
          <MyTooltip key={this.state.idPrefix + "pool-share"} target={this.state.idPrefix + "pool-share"} optionalOffset={15}>
            New share in the margin pool which is consisted of all lenders. <br /> <br />
            Pool share can change over time as liquidity is added/removed from the margin pool.
          </MyTooltip>
        </div>
      );
    } else {
      return <div />;
    }
  };

  calculatePoolShare = value => {
    const lendTotalSupply = this.getCurrentAssetTotalSupply(this.state.token);
    const currentLendBalance = this.getAccountLendBalance(this.state.token);

    let newTotalAmount;
    let newUserAmount;
    if (this.state.buttonTextClicked.toUpperCase() === "LEND") {
      // lend case
      newTotalAmount = parseFloat(lendTotalSupply) + parseFloat(value);
      newUserAmount = parseFloat(value) + parseFloat(currentLendBalance);
    } else {
      // redeem case
      newTotalAmount = parseFloat(lendTotalSupply) - parseFloat(value);
      newUserAmount = parseFloat(currentLendBalance) - parseFloat(value);
    }
    let poolShare = (newUserAmount / newTotalAmount) * 100;
    if (poolShare < 0.01) {
      poolShare = "<" + 0.01 + "%";
    } else {
      poolShare = poolShare.toFixed(2) + "%";
    }

    return poolShare;
  };

  calculateAccountBalance = (value, shouldSetStates, shouldBeDisabledColor = false) => {
    if (this.props.tokensLoaded && this.state.modalIsOpen) {
      const token = this.props.tokens.find(t => t.symbol === this.state.token);
      let amount = null;
      if (!this.state.fromWallet && token.accountBalance) {
        amount = token && fromBn(token.accountBalance);
        const excessLiquidity = this.props.marginAccountData && this.props.web3AccountLoaded && this.props.marginAccountData.normalizedExcessLiquidity;
        let newAmount = Number(amount) - Number(value);
        if (newAmount >= 0) {
          amount = newAmount;
        } else if (isSmallDifference(Number(amount), Number(value), this.state.token)) {
          amount = 0;
        } else {
          if (this.state.inputClass !== "has-danger" && excessLiquidity > Number(amount) && shouldSetStates && this.state.buttonTextClicked !== "Redeem") {
            this.setState({ inputClass: "has-danger", inputErrorMessage: "You don't have enough funds" });
          }
          amount = 0;
        }
      }
      const tooltipText = this.state.buttonTextClicked.toLowerCase() + "ing";
      return (
        <div className="leverageContainer">
          <span id={this.state.idPrefix + "account-bal"} className={`portfolioLendSmallLine${shouldBeDisabledColor ? " disabledTextColor" : ""}`}>Account balance</span>
          <span className={`portfolioLendBigLine${shouldBeDisabledColor ? " disabledTextColor" : ""}`}>{token && formatValue(Number(amount), 2)}</span>
          <MyTooltip key={this.state.idPrefix + "account-bal"} target={this.state.idPrefix + "account-bal"} optionalOffset={15}>
            New account balance after {tooltipText}.
          </MyTooltip>
        </div>
      );
    }
  };

  calculateWalletBalance = (value, shouldSetStates, shouldBeDisabledColor = false) => {
    if (this.props.tokensLoaded && this.state.modalIsOpen) {
      const token = this.props.tokens.find(t => t.symbol === this.state.token);
      let amount = null;
      if (this.state.fromWallet && token.walletBalance18Decimals) {
        amount = token && fromBn(token.walletBalance18Decimals);
        let newAmount = Number(amount) - Number(value);
        if (newAmount >= 0) {
          amount = newAmount;
        } else if (isSmallDifference(Number(amount), Number(value), this.state.token)) {
          amount = 0;
        } else {
          if (this.state.inputClass !== "has-danger" && shouldSetStates && this.state.buttonTextClicked !== "Redeem") {
            this.setState({ inputClass: "has-danger", inputErrorMessage: "You don't have enough funds" });
          }
          amount = 0;
        }
      }
      const tooltipText = this.state.buttonTextClicked.toLowerCase() + "ing";
      return (
        <div className="leverageContainer">
          <span id={this.state.idPrefix + "wallet-bal"} className={`portfolioLendSmallLine${shouldBeDisabledColor ? " disabledTextColor" : ""}`}>Wallet balance</span>
          <span className={`portfolioLendBigLine${shouldBeDisabledColor ? " disabledTextColor" : ""}`}>{token && formatValue(Number(amount), 2, true)}</span>
          <MyTooltip key={this.state.idPrefix + "wallet-bal"} target={this.state.idPrefix + "wallet-bal"} optionalOffset={15}>
            New wallet balance after {tooltipText}.
          </MyTooltip>
        </div>
      );
    }
  };

  calculateLendProceeds = (value, shouldBeDisabledColor = false) => {
    const lendProceeds = this.props.marginAccountDataLoaded && this.state.token && this.getAccountLendBalance(this.state.token);

    let newLendProceeds = lendProceeds;
    const debt = this.props.marginAccountDataLoaded && this.state.token && this.getAccountBorrowBalance(this.state.token);
    if (this.state.buttonTextClicked === "Repay") {
      const DebtDifference = lendProceeds + debt + Number(value);
      newLendProceeds = lendProceeds + debt + Number(value) < 0 ? 0 : DebtDifference;
    } else if (this.state.buttonTextClicked === "Lend") {
      newLendProceeds = lendProceeds + Number(value);
    } else if (this.state.buttonTextClicked === "Redeem") {
      newLendProceeds = lendProceeds - Number(value);
    }
    const tooltipText = this.state.buttonTextClicked.toLowerCase() + "ing";
    return (
      <div className="leverageContainer">
        <span id={this.state.idPrefix + "lend-proc"} className={`portfolioLendSmallLine${shouldBeDisabledColor ? " disabledTextColor" : ""}`}>Lend proceeds</span>
        <span className={`portfolioLendBigLine${shouldBeDisabledColor ? " disabledTextColor" : ""}`}>{formatValue(Number(newLendProceeds), 2)}</span>
        <MyTooltip key={this.state.idPrefix + "lend-proc"} target={this.state.idPrefix + "lend-proc"} optionalOffset={15}>
          New lend proceeds after {tooltipText}.
        </MyTooltip>
      </div>
    );
  };

  getNewHealth = (value, shouldBeDisabledColor = false) => {
    let health;
    const totalDebt = this.props.marginAccountData && this.props.marginAccountData.debt;
    const totalLiquidity = this.props.marginAccountData && parseFloat(formatUnits(this.props.marginAccountData.totalLiquidity, 18));

    const amount = this.state.token === "ETH" ? Number(value) * Number(this.props.underlyingTokenPrice.normalized) : Number(value);

    if (!this.state.fromWallet) {
      health = (Number(totalLiquidity) - amount) / totalDebt;
    } else {
      health = Number(totalLiquidity) / totalDebt;
    }
    const readableHealth = getReadableAccountHealth(totalDebt, health);

    const recoveryThreshold =
      this.props.marginAccountData && this.props.web3AccountLoaded ? parseFloat(fromBn(this.props.marginAccountData.recoveryThreshold)) * 100 : 0;

    const liquidationThreshold =
      this.props.marginAccountData && this.props.web3AccountLoaded ? parseFloat(fromBn(this.props.marginAccountData.liquidationThreshold)) * 100 : 0;
    const healthColorClass = !shouldBeDisabledColor
      ? healthClassName(readableHealth, health * 100, recoveryThreshold, liquidationThreshold)
      : " disabledTextColor";
    const tooltipText = this.state.buttonTextClicked.toLowerCase() + "ing";

    return (
      <div className="leverageContainer">
        <span id={this.state.idPrefix + "modal-health"} className={`portfolioLendSmallLine${shouldBeDisabledColor ? " disabledTextColor" : ""}`}>Health</span>
        <span className={`portfolioLendBigLine ${healthColorClass}`}>{readableHealth}</span>
        <MyTooltip key={this.state.idPrefix + "modal-health"} target={this.state.idPrefix + "modal-health"} optionalOffset={15}>
          New account health after {tooltipText}. <br /> <br />
          Health is the ratio betweeen total account liquidity (including debt) and debt.
        </MyTooltip>
      </div>
    );
  };

  clearInput = e => {
    e.stopPropagation();
    this.setState({ amount: "", inputClass: "" });
    this.lendModalInputRef.current.focus();
  };

  calculateMax = () => {
    let maxValue;
    let maxOriginal;
    const token = this.props.tokens.find(t => t.symbol === this.state.token);
    const excessLiquidity = this.props.marginAccountData && this.props.web3AccountLoaded && this.props.marginAccountData.normalizedExcessLiquidity;
    const excessLiquidityInEth = excessLiquidity / this.props.underlyingTokenPrice.normalized;
    let entireDebt = false;
    if (this.state.buttonTextClicked === "Redeem") {
      const lendingPoolSupply = this.props.marginPoolData.tokensData.find(data => data.symbol === this.state.token).normalizedCurrentSupply;
      const lendingPoolBorrowed = this.props.marginPoolData.tokensData.find(data => data.symbol === this.state.token).normalizedBorrowedAmount;
      const lendProceeds = this.props.marginAccountData.collateralTokens.find(data => data.symbol === this.state.token).normalizedLendProceeds;
      // todo: again, if user wants to withdraw $1M, but by the time the tx is confirmed,
      // he has $1m + $1, then he won't be able to withdraw everything in 1 tx.
      // contract should accept $1B as a value, and give the user $1M + $1
      const maxRedeemAmount = Math.max(0, lendingPoolSupply - (lendingPoolBorrowed / this.state.minUtilRatio) * 1.01);
      const maxToRedeem = Math.min(maxRedeemAmount, lendProceeds, lendingPoolSupply);
      console.log("max:", maxToRedeem)

      maxOriginal = maxToRedeem;
      maxValue = maxOriginal.toFixed(this.state.token === "USD" ? 2 : 4);
      // todo: remove entireDebt, why is this here?? used for calculating new APR,
      // should be able to calculate without it
      entireDebt = !(lendProceeds > lendingPoolSupply);

      // OLD CODE
      // const currentSupply = fromBn(this.props.marginPoolData.tokensData.find(data => data.symbol === this.state.token).currentSupply);
      // const balance = this.props.marginAccountData.collateralTokens.find(data => data.symbol === this.state.token).lendProceeds;
      // if (toBn(fromBn(balance), 18).gt(toBn(currentSupply, 18)) || isSmallDifference(balance, currentSupply, this.state.token)) {
      //   console.log(1);
      //   maxValue = Number(currentSupply).toFixed(this.state.token === "USD" ? 2 : 4);
      //   maxOriginal = currentSupply;
      // } else {
      //   console.log(2)
      //   maxValue = Number(fromBn(balance)).toFixed(this.state.token === "USD" ? 2 : 4);
      //   maxOriginal = fromBn(balance);
      //   entireDebt = true;
      // }
    } else if (this.state.buttonTextClicked === "Lend") {
      if (this.state.fromWallet) {
        maxValue =
          this.state.token === "USD" ? Number(token.normalizedWalletBalance2Decimals).toFixed(2) : Number(token.normalizedWalletBalance4Decimals).toFixed(4);
        maxOriginal = fromBn(token.walletBalance18Decimals);
      } else {
        if (this.state.token === "USD") {
          maxValue = excessLiquidity < token.normalizedAccountBalance ? excessLiquidity.toFixed(2) : Number(token.normalizedAccountBalance2Decimals).toFixed(2);
          maxOriginal = toBn(excessLiquidity.toString(), 18).lt(toBn(token.normalizedAccountBalance.toString(), 18))
            ? fromBn(toBn(excessLiquidity.toString(), 18))
            : fromBn(token.accountBalance);
        } else {
          maxValue =
            excessLiquidityInEth < token.normalizedAccountBalance
              ? excessLiquidityInEth.toFixed(4)
              : Number(token.normalizedAccountBalance4Decimals).toFixed(4);
          maxOriginal = toBn(excessLiquidityInEth.toString(), 18).lt(toBn(token.normalizedAccountBalance.toString(), 18))
            ? fromBn(toBn(excessLiquidityInEth.toString(), 18))
            : fromBn(token.accountBalance);
        }
      }
    } else if (this.state.buttonTextClicked === "Repay") {
      const funds = this.state.fromWallet ? fromBn(token.walletBalance18Decimals) : fromBn(token.accountBalance);
      const debt = fromBn(this.props.marginAccountData.collateralTokens.find(t => t.symbol === this.state.token).borrowedAmount);
      const supply = this.props.marginPoolData.tokensData.find(t => t.symbol === this.state.token).normalizedBorrowedAmount;

      let min = this.state.token === "USD" ? fromBn(toBn(excessLiquidity.toString(), 18)) : excessLiquidityInEth;
      if (toBn(funds.toString(), 18).lt(toBn(min.toString(), 18)) || isSmallDifference(min, funds, this.state.token)) {
        min = funds;
      }
      if (toBn(debt.toString(), 18).lt(toBn(min.toString(), 18)) || isSmallDifference(min, debt, this.state.token)) {
        min = debt;
        entireDebt = true;
      }
      if (supply < min) {
        min = supply;
      }
      maxOriginal = min;
      maxValue = Number(min).toFixed(this.state.token === "USD" ? 2 : 4);

      if (this.state.fromWallet) {
        maxOriginal = debt;
        maxValue = Number(debt).toFixed(this.state.token === "USD" ? 2 : 4);
        entireDebt = true;
      }
    }

    return { maxValue, entireDebt, maxOriginal };
  };

  onMaxButtonClicked = () => {
    const { maxValue, entireDebt, maxOriginal } = this.calculateMax();
    this.lendModalInputRef.current.value = maxValue;
    this.onAmountChange();
    this.setState({ originalAmount: maxOriginal });
    this.setState({ shouldRepayEntireDebt: entireDebt });
  };

  disabledMaxButton = () => {
    const token = this.props.tokens.find(t => t.symbol === this.state.token);
    if (token) {
      if (this.state.fromWallet && this.state.buttonTextClicked !== "Redeem") {
        if (Number(token.normalizedWalletBalance) === 0) {
          return true;
        } else {
          return false;
        }
      } else if (!this.state.fromWallet && this.state.buttonTextClicked !== "Redeem") {
        if (Number(token.normalizedAccountBalance) === 0) {
          return true;
        }
      }
    }

    return false;
  };

  render() {
    const buttonText = this.state.buttonTextClicked.toLowerCase();

    const totalSupply = this.printTotalSupply();
    const totalBorrow = this.printTotalBorrowAmount();
    const totalSupplyInterestRate = this.printTotalLendingInterestRate();
    const totalBorrowInterestRate = this.printTotalBorrowInterestRate();

    let modalHeaderText = "";

    if (this.state.token === "ETH") {
      modalHeaderText = "ETH";
    } else if (this.state.token === "USD") {
      modalHeaderText = "USDC";
    }

    const readableTotalAPR = printTotalApr(
      this.props.marginAccountDataLoaded,
      this.props.marginPoolDataLoaded,
      this.props.marginAccountData,
      this.props.marginPoolData
    );

    const aprClassName = typeof readableTotalAPR === "string" ? (readableTotalAPR.includes("-") ? "redAprColor" : "greenAprColor") : "";

    const balanceETH = this.getAccountLendBalance("ETH");
    const balanceUSD = this.getAccountLendBalance("USD");

    const debtETH = this.getAccountBorrowBalance("ETH");
    const debtUSD = this.getAccountBorrowBalance("USD");

    const shouldPrintRegular = this.state.amount !== "" && this.state.inputClass !== "has-danger";

    let printableAccountBalance;
    let printableWalletBalance;
    let printableLendProceeds;
    let printableHealth;
    let printablePoolShare;
    if (this.state.modalIsOpen) {
      if (shouldPrintRegular) {
        printableAccountBalance = this.calculateAccountBalance(this.state.amount.trim(), true);
        printableWalletBalance = this.calculateWalletBalance(this.state.amount.trim(), true);
        printableLendProceeds = this.calculateLendProceeds(this.state.amount.trim());
        printableHealth = this.getNewHealth(this.state.amount.trim());
        printablePoolShare = this.printPoolShare(this.calculatePoolShare(this.state.amount.trim()));
      } else {
        this.calculateAccountBalance(this.state.amount.trim(), true);
        let value;
        if (this.state.inputClass === "has-danger") {
          const { maxValue } = this.calculateMax();
          value = maxValue.toString();
        } else {
          value = defaultInputValue.toString();
        }
        printableAccountBalance = this.calculateAccountBalance(value, false, true);
        printableWalletBalance = this.calculateWalletBalance(value, false, true);
        printableLendProceeds = this.calculateLendProceeds(value, true);
        printableHealth = this.getNewHealth(value, true);
        printablePoolShare = this.printPoolShare(this.calculatePoolShare(value), true);
      }
    }

    const disabledMaxButton = this.disabledMaxButton();

    const isLendOrReedem = this.state.buttonTextClicked === "Lend" || this.state.buttonTextClicked === "Redeem";
    const action = this.state.buttonTextClicked;
    const isLend = action === "Lend";
    const isRedeem = action === "Redeem";
    const isRepay = action === "Repay";
    const switchAccountText = action + (isLend || isRepay ? " from your account" : " to your account");
    const switchWalletText = action + (isLend || isRepay ? " from your wallet" : " to your wallet");
    const readableApr = this.state.apr && (this.state.apr.normalizedAPR == 0 ? "0" : "-" + this.state.apr.readableAPR);
    const insuranceFundAmount = this.printInsuranceFundAmount();
    return (
      <>
        {this.state.totalLiquidityLegendOpen && (
          <TotalLiquidityLegend
            open={this.state.totalLiquidityLegendOpen}
            toggle={this.totalLiquidityMouseLeaveHandler}
            right={this.state.totalLiquidityLegendRight}
            top={this.state.totalLiquidityLegendTop}
          />
        )}
        <MyModal
          isOpen={this.state.modalIsOpen}
          toggle={() => {
            lastClickedModalOverlay = Date.now();
            const diff = lastClickedModalOverlay - lastSelectedInputTimestamp;
            if (diff < 500) {
              lastClickedModalOverlay = null;
              lastSelectedInputTimestamp = null;
              return;
            }
            this.closeModal();
          }}
        >
          <div
            className="marginModalFrame"
            style={{ marginTop: this.state.marginTopOfModal }}
            onClick={e => e.stopPropagation()}
            onKeyDown={this.onKeyPress}
            onMouseDown={e => {
              lastSelectedInputTimestamp = Date.now();
            }}
          >
            <div className="headerOfMarginModal">
              <span className="typeOfMarginModal">
                {action} {modalHeaderText}
              </span>
              <div className="accountWalletSwitchContainer">
                <span id={this.state.idPrefix + "switch-account"} className={this.state.fromWallet ? "notActiveSpanSwitch" : ""}>Account</span>
                <MyTooltip key={this.state.idPrefix + "switch-account"} target={this.state.idPrefix + "switch-account"} optionalOffset={15}>
                  {switchAccountText}
                </MyTooltip>
                <MySwitch
                  id="lendSwitch"
                  checked={this.state.fromWallet}
                  onChange={() => {
                    this.lendModalInputRef.current.focus();
                    this.setState({ fromWallet: !this.state.fromWallet });
                  }}
                  isAllNew
                />
                <span id={this.state.idPrefix + "switch-wallet"} className={!this.state.fromWallet ? "notActiveSpanSwitch" : ""}>Wallet</span>
                <MyTooltip key={this.state.idPrefix + "switch-wallet"} target={this.state.idPrefix + "switch-wallet"} optionalOffset={15}>
                  {switchWalletText}
                </MyTooltip>
              </div>
              <button
                aria-hidden
                data-dismiss="modal"
                type="button"
                className="closeModal"
                onClick={e => {
                  e.stopPropagation();
                  this.closeModal();
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
            </div>
            <div className="inputAmountAndSettingsErrorMessageContainer">
              <div className="inputAmountAndSettingsContainer">
                <Form>
                  <FormGroup>
                    <Input
                      id={"lendModalInput"}
                      type="text"
                      autoComplete="off"
                      placeholder={`Amount of [USD/ETH] to ${buttonText} ${buttonText === "redeem" || buttonText === "borrow" ? "from" : "to"} the margin pool`}
                      className={`inputText${this.state.inputClass === "has-danger" ? " inputDanger" : ""}${
                        this.state.inputClass === "has-success" ? " inputSuccess" : ""
                      }`}
                      onChange={e => {
                        this.onAmountChange();
                      }}
                      autoFocus
                      value={this.state.amount}
                      innerRef={this.lendModalInputRef}
                    />
                    {this.state.inputClass === "has-danger" ? <img className="clearInputError" onClick={this.clearInput} /> : null}
                    {this.state.inputClass === "has-success" ? <img className="validInput" /> : null}
                  </FormGroup>
                </Form>
                <button
                  id={"lendModalMaxButton"}
                  className={`maxButton ${disabledMaxButton ? "maxButtonDisabled" : ""}`}
                  onClick={e => {
                    e.stopPropagation();
                    this.onMaxButtonClicked();
                  }}
                  disabled={disabledMaxButton}
                  style={{
                    lineHeight: "40px"
                  }}
                >
                  max
                </button>
                <MyTooltip target={"lendModalMaxButton"}>Max amount to {action.toLowerCase()}.</MyTooltip>
              </div>
              <div className="errorMessageDepositWithdrawLendContainer">
                <span className="errorMessageDepositWithdrawLendSpan">{this.state.inputClass === "has-danger" ? this.state.inputErrorMessage : ""}</span>
              </div>
            </div>
            <div className="marginNumberInformation">
              <div className="leverageContainer">
                <span id={this.state.idPrefix + "modal-rate"} className={`portfolioLendSmallLine${!shouldPrintRegular ? " disabledTextColor" : ""}`}>{isLendOrReedem ? "Lend" : "Borrow"} rate</span>
                <MyTooltip key={this.state.idPrefix + "modal-rate"} target={this.state.idPrefix + "modal-rate"} optionalOffset={15}>
                  New {isLendOrReedem ? "lend" : "borrow"} rate after {action.toLowerCase() + "ing"}. <br /> <br />
                  Rate can change over time as liquidity is added/removed from the margin pool.
                </MyTooltip>
                <span
                  className={`portfolioLendBigLine${!shouldPrintRegular ? " disabledTextColor" : ""}`}
                  style={{
                    color: isRepay && readableApr != "0" ? "#ee1b41" : isRepay && readableApr == "0" ? "white" : "#8fce51"
                  }}
                >
                  {this.state.apr ? (isRepay ? readableApr : this.state.apr.readableAPR) : ""}
                </span>
              </div>
              {!this.state.fromWallet && printableAccountBalance}
              {this.state.fromWallet && printableWalletBalance}
              {!isRepay && printableLendProceeds}
              {printablePoolShare}
              {!isRedeem && printableHealth}
              {this.state.step === 1 && (
                <div className="withdrawDepositDataInformationItem">
                  <span className="portfolioLendBigLine">Approval Required (step 1 of 2)</span>
                </div>
              )}
              {this.state.step === 2 && (
                <div className="withdrawDepositDataInformationItem">
                  <span className="approvingDepositMessage">Approving deposit</span>
                  <img className="approvingRequest" />
                </div>
              )}
              {this.state.step === 3 && (
                <div className="withdrawDepositDataInformationItem">
                  <span className="portfolioLendBigLine">Transaction Confirmation Required (step 2 of 2)</span>
                </div>
              )}
            </div>
            <button
              id="lendPageModalActionButton"
              ref={this.modalActionButton}
              disabled={this.state.inputClass !== "has-success" || this.state.step}
              onClick={this.modalActionButtonClicked}
              className="marginModalActionButton"
            >
              {action}
            </button>
          </div>
        </MyModal>
        <div className="content" style={{ paddingTop: "0px" }}>
          <div className="poolAccountContainer">
            <div className="poolContainer">
              <span className="poolAccountTitle">POOL</span>
              <div className="lendBorrowContainer">
                <span className="lendBorrowTitle">Lend</span>
                <table className="lendLayoutTable">
                  <tr>
                    <th id={this.state.idPrefix + "lend-col-token"} className="tableFirstColumn">
                      TOKEN
                    </th>
                    <MyTooltip key={this.state.idPrefix + "lend-col-token"} target={this.state.idPrefix + "lend-col-token"} optionalOffset={15}>
                      Name of the token
                    </MyTooltip>
                    <th id={this.state.idPrefix + "lend-col-supply"}>SUPPLIED</th>
                    <MyTooltip key={this.state.idPrefix + "lend-col-supply"} target={this.state.idPrefix + "lend-col-supply"} optionalOffset={15}>
                      Total amount of tokens supplied to the margin pool.
                    </MyTooltip>
                    <th id={this.state.idPrefix + "lend-col-rate"}>LEND RATE</th>
                    <MyTooltip key={this.state.idPrefix + "lend-col-rate"} target={this.state.idPrefix + "lend-col-rate"} optionalOffset={15}>
                      Interest rate lenders are receiving.
                    </MyTooltip>
                  </tr>
                  <tr>
                    <td className="tableFirstColumn">USDC</td>
                    <td className="monospace">{this.props.web3AccountLoaded ? this.printCurrentAssetTotalSupply("USD") : ""}</td>
                    <td className="aprGreen monospace">{this.props.web3AccountLoaded ? this.printInterestRate("USD", true) : ""}</td>
                  </tr>
                  <tr>
                    <td className="tableFirstColumn">ETH</td>
                    <td className="monospace">{this.props.web3AccountLoaded ? this.printCurrentAssetTotalSupply("ETH") : ""}</td>
                    <td className="aprGreen monospace">{this.props.web3AccountLoaded ? this.printInterestRate("ETH", true) : ""}</td>
                  </tr>
                </table>
                <div className="lendBorrowFooter">
                  <span className="totalSupplyText">Total Supplied</span>
                  <span className="totalSupplyValue monospace">{this.props.web3AccountLoaded ? totalSupply : ""}</span>
                  <span className="totalSupplyText monospace">{this.props.web3AccountLoaded ? totalSupplyInterestRate : ""}</span>
                </div>
              </div>
              <div className="lendBorrowContainer">
                <span className="lendBorrowTitle">Borrow</span>
                <table className="lendLayoutTable">
                  <tr>
                    <th id={this.state.idPrefix + "borrow-col-token"} className="tableFirstColumn">
                      TOKEN
                    </th>
                    <MyTooltip key={this.state.idPrefix + "borrow-col-token"} target={this.state.idPrefix + "borrow-col-token"} optionalOffset={15}>
                      Name of the token
                    </MyTooltip>
                    <th id={this.state.idPrefix + "borrow-col-borrowed"}>BORROWED</th>
                    <MyTooltip key={this.state.idPrefix + "borrow-col-borrowed"} target={this.state.idPrefix + "borrow-col-borrowed"} optionalOffset={15}>
                      Total amount of tokens borrowed from the margin pool.
                    </MyTooltip>
                    <th id={this.state.idPrefix + "borrow-col-rate"}>BORROW RATE</th>
                    <MyTooltip key={this.state.idPrefix + "borrow-col-rate"} target={this.state.idPrefix + "borrow-col-rate"} optionalOffset={15}>
                      Interest rate borrowers are paying.
                    </MyTooltip>
                  </tr>
                  <tr>
                    <td className="tableFirstColumn">USDC</td>
                    <td className="monospace">{this.props.web3AccountLoaded ? this.printBorrowAmount("USD") : ""}</td>
                    <td className="aprRed monospace">{this.props.web3AccountLoaded ? this.printInterestRate("USD", false) : ""}</td>
                  </tr>
                  <tr>
                    <td className="tableFirstColumn">ETH</td>
                    <td className="monospace">{this.props.web3AccountLoaded ? this.printBorrowAmount("ETH") : ""}</td>
                    <td className="aprRed monospace">{this.props.web3AccountLoaded ? this.printInterestRate("ETH", false) : ""}</td>
                  </tr>
                </table>
                <div className="lendBorrowFooter">
                  <span className="totalSupplyText">Total Borrowed</span>
                  <span className="totalSupplyValue monospace">{this.props.web3AccountLoaded ? totalBorrow : ""}</span>
                  <span className="totalSupplyText monospace">{this.props.web3AccountLoaded ? totalBorrowInterestRate : ""}</span>
                </div>
              </div>
            </div>
            <div className="accountContainer">
              <div className="accountHeaderAndHealth">
                <span className="poolAccountTitle">ACCOUNT</span>
                <div className="accountHealthAndValue">
                  <span id={this.state.idPrefix + "net-rate"} className="accountHealth">
                    NET RATE
                  </span>
                  <MyTooltip key={this.state.idPrefix + "net-rate"} target={this.state.idPrefix + "net-rate"} optionalOffset={15}>
                    Net rate is interest rate you are receiving by utilizing margin pool. <br /> <br />
                    Positive net rate means you are earning interest, while negative net rate means you are paying interest. <br />
                  </MyTooltip>
                  <span className={`healthValue monospace ${aprClassName}`}>{this.props.web3AccountLoaded ? readableTotalAPR : ""}</span>
                </div>
              </div>
              <div className="lendBorrowContainer">
                <div className="accountEmptyHeading" />
                <table className="lendLayoutTable">
                  <tr>
                    <th id={this.state.idPrefix + "lend-col-principal"} className="principalField">
                      PRINCIPAL
                    </th>
                    <MyTooltip id={this.state.idPrefix + "lend-col-principal"} target={this.state.idPrefix + "lend-col-principal"} optionalOffset={15}>
                      The amount of tokens you deposited to the margin pool on which you are receiving interest.
                    </MyTooltip>
                    <th id={this.state.idPrefix + "lend-col-proceeds"}>PROCEEDS</th>
                    <MyTooltip id={this.state.idPrefix + "lend-col-proceeds"} target={this.state.idPrefix + "lend-col-proceeds"} optionalOffset={15}>
                      The sum of principal and interest accrued, expressed in lended token.
                    </MyTooltip>
                    <th id={this.state.idPrefix + "lend-col-value"}>VALUE</th>
                    <MyTooltip key={this.state.idPrefix + "lend-col-value"} target={this.state.idPrefix + "lend-col-value"} optionalOffset={15}>
                      The sum of principal and interest accrued, expressed in $ USD.
                    </MyTooltip>
                    <th className="actionsHeader">ACTIONS</th>
                  </tr>
                  <tr>
                    <td className="monospace principalField">{this.props.web3AccountLoaded ? this.printAccountPremium("USD") : ""}</td>
                    <td className="monospace">{this.props.web3AccountLoaded ? this.printAccountLendBalance("USD") : ""}</td>
                    <td className="monospace">{this.props.web3AccountLoaded ? this.printAccountLendValue("USD") : ""}</td>
                    <td className="lendTableActionsTd">
                      <div className="LendTableActions">
                        <button
                          className={`lendTableActionButton ${
                            action.toLowerCase().trim() === "lend" && this.state.token === "USD" ? "lendBorrowActionButtonClicked" : ""
                          }`}
                          onClick={e => {
                            e.stopPropagation();
                            this.openModal(e, "lend", "USD");
                          }}
                          disabled={!this.props.web3AccountLoaded || (debtUSD < 0 && !isSmallDifference(debtUSD, 0, "USD")) || !this.props.marginAccountData}
                        >
                          LEND
                        </button>
                        <button
                          className={`lendTableActionButton ${
                            action.toLowerCase().trim() === "redeem" && this.state.token === "USD" ? "lendBorrowActionButtonClicked" : ""
                          }`}
                          disabled={!this.props.web3AccountLoaded || Number(balanceUSD.toFixed(2)) <= 0}
                          onClick={e => {
                            e.stopPropagation();
                            this.openModal(e, "lend", "USD");
                          }}
                        >
                          REDEEM
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="monospace principalField">{this.props.web3AccountLoaded ? this.printAccountPremium("ETH") : ""}</td>
                    <td className="monospace">{this.props.web3AccountLoaded ? this.printAccountLendBalance("ETH") : ""}</td>
                    <td className="monospace">{this.props.web3AccountLoaded ? this.printAccountLendValue("ETH") : ""}</td>
                    <td className="lendTableActionsTd">
                      <div className="LendTableActions">
                        <button
                          className={`lendTableActionButton ${
                            action.toLowerCase().trim() === "lend" && this.state.token === "ETH" ? "lendBorrowActionButtonClicked" : ""
                          }`}
                          onClick={e => {
                            e.stopPropagation();
                            this.openModal(e, "lend", "ETH");
                          }}
                          disabled={!this.props.web3AccountLoaded || (debtETH < 0 && !isSmallDifference(debtETH, 0, "ETH")) || !this.props.marginAccountData}
                        >
                          LEND
                        </button>
                        <button
                          className={`lendTableActionButton ${
                            action.toLowerCase().trim() === "redeem" && this.state.token === "ETH" ? "lendBorrowActionButtonClicked" : ""
                          }`}
                          disabled={!this.props.web3AccountLoaded || Number(balanceETH.toFixed(2)) <= 0}
                          onClick={e => {
                            e.stopPropagation();
                            this.openModal(e, "lend", "ETH");
                          }}
                        >
                          REDEEM
                        </button>
                      </div>
                    </td>
                  </tr>
                </table>
                <div className="insuranceFundFooter">
                  <span className="totalSupplyText">Lenders protected with </span>
                  <span className="totalSupplyValue monospace">{insuranceFundAmount}</span>
                  <span className="totalSupplyText">from</span>
                  <NavLink to={"/insurance"}>Insurance Fund</NavLink>
                </div>
              </div>
              <div className="lendBorrowContainer">
                <div className="accountEmptyHeading" />
                <table className="lendLayoutTable">
                  <tr>
                    <th id={this.state.idPrefix + "borrow-col-principal"} className="principalField">
                      PRINCIPAL
                    </th>
                    <MyTooltip id={this.state.idPrefix + "borrow-col-principal"} target={this.state.idPrefix + "borrow-col-principal"} optionalOffset={15}>
                      The amount of tokens you borrowed from the margin pool on which you are paying interest.
                    </MyTooltip>
                    <th id={this.state.idPrefix + "borrow-col-debt"}>DEBT</th>
                    <MyTooltip id={this.state.idPrefix + "borrow-col-debt"} target={this.state.idPrefix + "borrow-col-debt"} optionalOffset={15}>
                      The sum of principal and interest accrued, expressed in borrowed token.
                    </MyTooltip>
                    <th id={this.state.idPrefix + "borrow-col-value"}>VALUE</th>
                    <MyTooltip key={this.state.idPrefix + "borrow-col-value"} target={this.state.idPrefix + "borrow-col-value"} optionalOffset={15}>
                      The sum of principal and interest accrued, expressed in $ USD.
                    </MyTooltip>
                    <th className="actionsHeader">ACTIONS</th>
                  </tr>
                  <tr>
                    <td className="monospace principalField">{this.props.web3AccountLoaded ? this.printAccountBaseDebt("USD") : ""}</td>
                    <td className="monospace">{this.props.web3AccountLoaded ? this.printAccountBorrowBalance("USD") : ""}</td>
                    <td className="monospace">{this.props.web3AccountLoaded ? this.printAccountBorrowValue("USD") : ""}</td>
                    <td className="lendTableActionsTd">
                      <div className="LendTableActions" style={{ justifyContent: "right" }}>
                        <button className="lendTableActionButton hidden">HIDDEN</button>
                        <button
                          className={`lendTableActionButton ${
                            action.toLowerCase().trim() === "repay" && this.state.token === "USD" ? "lendBorrowActionButtonClicked" : ""
                          }`}
                          disabled={!this.props.web3AccountLoaded || debtUSD >= 0 || isSmallDifference(debtUSD, 0, "USD")}
                          onClick={e => {
                            e.stopPropagation();
                            this.openModal(e, "borrow", "USD");
                          }}
                        >
                          REPAY
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="monospace principalField">{this.props.web3AccountLoaded ? this.printAccountBaseDebt("ETH") : ""}</td>
                    <td className="monospace">{this.props.web3AccountLoaded ? this.printAccountBorrowBalance("ETH") : ""}</td>
                    <td className="monospace">{this.props.web3AccountLoaded ? this.printAccountBorrowValue("ETH") : ""}</td>
                    <td className="lendTableActionsTd">
                      <div className="LendTableActions" style={{ justifyContent: "right" }}>
                        <button className="lendTableActionButton hidden">HIDDEN</button>
                        <button
                          className={`lendTableActionButton ${
                            action.toLowerCase().trim() === "repay" && this.state.token === "ETH" ? "lendBorrowActionButtonClicked" : ""
                          }`}
                          disabled={!this.props.web3AccountLoaded || debtETH >= 0 || isSmallDifference(debtETH, 0, "ETH")}
                          onClick={e => {
                            e.stopPropagation();
                            this.openModal(e, "borrow", "ETH");
                          }}
                        >
                          REPAY
                        </button>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

LendLayout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  web3: PropTypes.object,
  web3Loading: PropTypes.bool.isRequired,
  web3Account: PropTypes.string,
  web3AccountLoaded: PropTypes.bool,
  positionsManager: PropTypes.object,
  selectedTokenPair: PropTypes.object,
  marginPool: PropTypes.object,
  marginPoolDataLoaded: PropTypes.bool,
  marginPoolData: PropTypes.object,
  marginAccountData: PropTypes.object,
  tokens: PropTypes.object,
  underlyingTokenPrice: PropTypes.object,
  marginAccountDataLoaded: PropTypes.bool,
  tokensLoaded: PropTypes.bool,
  userBalances: PropTypes.object,
  lendPremiumsAndBorrowBases: PropTypes.object,
  insuranceFundLoaded: PropTypes.bool,
  insuranceFundWithUnclaimed: PropTypes.number,
  insuranceFundContract: PropTypes.object
};

function mapStateToProps(state) {
  return {
    web3: web3Selector(state),
    web3Loading: web3LoadingSelector(state),
    web3Loaded: web3LoadedSelector(state),
    web3Account: web3AccountSelector(state),
    web3AccountLoaded: web3AccountLoadedSelector(state),
    positionsManager: positionsManagerSelector(state),
    selectedTokenPair: selectedTokenPairSelector(state),
    marginPool: marginPoolSelector(state),
    marginPoolDataLoaded: marginPoolDataLoadedSelector(state),
    marginPoolData: marginPoolDataSelector(state),
    marginAccountData: marginAccountDataSelector(state),
    marginAccountDataLoaded: marginAccountDataLoadedSelector(state),
    tokensLoaded: tokensLoadedSelector(state),
    tokens: tokenBalancesSelector(state),
    underlyingTokenPrice: avgUnderlyingTokenPriceSelector(state),
    userBalances: userBalancesSelector(state),
    lendPremiumsAndBorrowBases: lendPremiumsAndBorrowBasesSelector(state),
    insuranceFundLoaded: insuranceFundLoadedSelector(state),
    insuranceFundWithUnclaimed: insuranceFundWithUnclaimedSelector(state),
    insuranceFundContract: insuranceFundSelector(state)
  };
}

export default connect(mapStateToProps)(LendLayout);
