// NOTE: don't use this method, use openDatabase instead
// Open (or create) a database
export async function openDB(dbName, storeName) {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(dbName);

    // if (!db.objectStoreNames.contains(storeName)) {
    //   console.log("does not exist");
    //   return resolve([]);
    // }

    request.onerror = () => {
      reject("Failed to open database");
    };

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onupgradeneeded = event => {
      const db = event.target.result;
      console.log("onupgradeneeded");
      // Create an object store
      const store = db.createObjectStore(storeName, { keyPath: "id", autoIncrement: false });
      // Define store's schema here
      // store.createIndex('idIndex', 'id', { unique: false });
    };
  });
}

// Add data to the database
export async function addToDB(db, storeName, data) {
  // eslint-disable-next-line no-undef
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], "readwrite");
    const store = transaction.objectStore(storeName);
    const request = store.put(data);

    request.onerror = () => {
      reject("Failed to add data");
    };

    request.onsuccess = () => {
      resolve("Data added successfully");
    };
  });
}

// Get data from the database
export async function getData(db, storeName) {
  //const db = await openDB();
  try {
    // eslint-disable-next-line no-undef
    return new Promise((resolve, reject) => {
      if (!db.objectStoreNames.contains(storeName)) {
        return resolve([]);
      }

      const transaction = db.transaction([storeName], "readonly");
      const store = transaction.objectStore(storeName);
      const request = store.getAll();

      request.onerror = () => {
        reject("Failed to retrieve data");
      };

      request.onsuccess = () => {
        resolve(request.result);
      };
    });
  } catch (_e) {
    return [];
  }
}

// NEW CODE
export async function openDatabase(dbName) {
  // eslint-disable-next-line no-undef
  return new Promise((resolve, reject) => {
    let request = indexedDB.open(dbName);

    request.onsuccess = function(event) {
      resolve(event.target.result);
    };

    request.onerror = function(event) {
      reject(`Error opening database: ${event.target.errorCode}`);
    };
  });
}

async function upgradeDatabase(dbName, newStoreName, newVersion) {
  // eslint-disable-next-line no-undef
  return new Promise((resolve, reject) => {
    let request = indexedDB.open(dbName, newVersion);

    request.onupgradeneeded = function(event) {
      let db = event.target.result;

      // Create the new object store
      if (!db.objectStoreNames.contains(newStoreName)) {
        db.createObjectStore(newStoreName, { keyPath: "id" });
        console.log(`Object store "${newStoreName}" created.`);
      }
    };

    request.onsuccess = function(event) {
      let db = event.target.result;
      db.close();
      console.log(`Database upgraded to version ${newVersion}`);
      resolve(`Database upgraded to version ${newVersion}`);
    };

    request.onerror = function(event) {
      reject(`Error upgrading database: ${event.target.errorCode}`);
    };
  });
}

export async function addStoreIfNeeded(dbName, newStoreName) {
  return openDatabase(dbName).then(async db => {
    if (!db.objectStoreNames.contains(newStoreName)) {
      let newVersion = db.version + 1;
      db.close();
      // eslint-disable-next-line no-undef
      return Promise.resolve(upgradeDatabase(dbName, newStoreName, newVersion));
      // return result;
    } else {
      // eslint-disable-next-line no-undef
      return Promise.resolve(`Object store "${newStoreName}" already exists.`);
    }
  });
}

export const getEventsStreamTest = async (contract, eventName, filter, toBlock) => {
  const dbName = "0x1234ga312"; // todo: use a better name
  // open the database with event name
  const db = await openDB(dbName, eventName);

  // measure time to get data from the database
  const t0 = performance.now();
  const deposits = await getData(db, eventName);
  const t1 = performance.now();
  console.log("Call to get data from the database took " + (t1 - t0) + " milliseconds.");
  console.log(deposits);

  const eventsStream = [];
  const events = await contract.getPastEvents(eventName, {
    filter: filter,
    fromBlock: 0,
    toBlock: toBlock
  });
  console.log(events);
  // await addToDB(db, eventName, events);
  // await addToDB(db, eventName, events);
  // await addToDB(db, eventName, events);
  // await addToDB(db, eventName, events);
  // await addToDB(db, eventName, events);
  // await addToDB(db, eventName, events);
  // await addToDB(db, eventName, events);

  return eventsStream;
};
