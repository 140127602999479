import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Form, Input, FormGroup } from "reactstrap";
import {
  defaultInputValue,
  formatValue,
  getReadableAccountHealth,
  healthClassName,
  inputDecimals,
  isHex,
  isPositiveNumber,
  isSmallDifference,
  removeCommaLocales,
  replaceCharacter
} from "../../utils/utils.js";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import MyModal from "./utilities/MyModal.jsx";
import { useSelector } from "react-redux";
import { marginAccountDataSelector } from "../../store/selectors/marginSelectors";
import { web3AccountLoadedSelector } from "../../store/selectors/web3Selectors";
import { avgUnderlyingTokenPriceSelector } from "../../store/selectors/tokensSelectors";
import { formatUnits } from "@ethersproject/units";
import { fromBn, toBn } from "evm-bn";
import ReactGA from "react-ga4";
import MyTooltip from "./MyTooltip.jsx";

let lastSelectedInputTimestamp = null;
let lastClickedModalOverlay = null;

const DepositFundsModal = props => {
  const [inputClass, setInputClass] = useState(() => {
    if (props.token.symbol === "ETH") {
      if (props.token.normalizedWalletBalance < 1 && props.token.normalizedWalletBalance > 0) {
        return "has-warning";
      }
    }
    return "has-success";
  });
  const [depositInputValue, setDepositInputValue] = useState(() => {
    if (props.token.symbol === "ETH") {
      if (props.token.normalizedWalletBalance > 1 && props.token.normalizedWalletBalance > 0 && !props.token.isWeth) {
        return (props.token.normalizedWalletBalance - 1).toFixed(4);
      }
      return props.token.normalizedWalletBalance4Decimals.toFixed(4);
    }
    return props.token.normalizedWalletBalance2Decimals.toFixed(2);
  });
  const [depositAmount, setDepositAmount] = useState(() => {
    if (props.token.symbol === "ETH") {
      if (props.token.normalizedWalletBalance > 1 && props.token.normalizedWalletBalance > 0 && !props.token.isWeth) {
        return fromBn(toBn(fromBn(props.token.walletBalance18Decimals), 18).sub(toBn("1", 18)));
      }
    }
    return fromBn(props.token.walletBalance18Decimals);
  });
  const [depositInputErrorMessage, setDepositInputErrorMessage] = useState(() => {
    if (props.token.symbol === "ETH") {
      if (props.token.normalizedWalletBalance < 1 && props.token.normalizedWalletBalance > 0) {
        // return "Your wallet balance is less than 1 ETH, please input amount to deposit";
        return "Not recommended to deposit max ETH amount, transaction fees could not be paid.";
      }
    }
    return "";
  });
  const [step, setStep] = useState(null);

  const marginAccountData = useSelector(marginAccountDataSelector);
  const web3AccountLoaded = useSelector(web3AccountLoadedSelector);
  const underlyingTokenPrice = useSelector(avgUnderlyingTokenPriceSelector);

  const depositInputValueRef = useRef(null);
  const depositButtonRef = useRef(null);
  const idPrefix = "deposit-funds-modal-";

  useEffect(() => {
    const modalDialog = document.querySelector(".modal-dialog");

    if (modalDialog) {
      $(".modal-black").draggable({
        handle: ".modal-header"
      });
    }

    depositInputValueRef.current.addEventListener("paste", paste);
    depositInputValueRef.current.select();

    const chunk = "deposit-modal";
    console.log("Navigate to: " + window.location.pathname + "/" + chunk); // eslint-disable-line no-console
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + "/" + chunk, title: "Deposit Modal" });

    return () => {
      document.onkeydown = null;
    };
  }, []);

  useEffect(() => {
    document.onkeydown = onKeyPress;
  }, [depositInputValue]);

  const paste = e => {
    e.stopPropagation();
    e.preventDefault();

    const selection = window.getSelection().toString();

    const clipboardData = e.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData("Text");
    pastedData = removeCommaLocales(pastedData);
    let newPastedData;
    if (selection !== "") {
      newPastedData = inputDecimals(
        replaceCharacter(
          depositInputValueRef.current.value,
          depositInputValueRef.current.selectionStart,
          depositInputValueRef.current.selectionEnd - 1,
          pastedData
        ),
        props.token.symbol
      );
    } else {
      newPastedData = inputDecimals(depositInputValueRef.current.value + pastedData, props.token.symbol);
    }
    if (newPastedData !== undefined && newPastedData !== null && !isHex(newPastedData)) {
      if (selection !== "") {
        depositInputValueRef.current.value = replaceCharacter(
          depositInputValueRef.current.value,
          depositInputValueRef.current.selectionStart,
          depositInputValueRef.current.selectionEnd - 1,
          pastedData
        );
      } else {
        depositInputValueRef.current.value = depositInputValueRef.current.value + pastedData;
      }
    }
    onInputChange();
  };

  const depositFunds = () => {
    props.depositFunds(depositAmount, setStep);
  };

  const onInputChange = () => {
    const inputString = depositInputValueRef.current.value.trim();

    const value = inputDecimals(inputString, props.token.symbol);
    if (value === undefined || value === null) {
      return;
    }
    setDepositInputValue(inputString);

    if (value === "") {
      setInputClass("");
      return;
    }

    if (!isPositiveNumber(value)) {
      setInputClass("has-danger");
      setDepositInputErrorMessage("Enter a positive number");
      return;
    }

    if (
      Number(value) > Number(props.token.normalizedWalletBalance) &&
      !isSmallDifference(Number(value), props.token.normalizedWalletBalance, props.token.symbol)
    ) {
      setInputClass("has-danger");
      setDepositInputErrorMessage(
        "Can't deposit more than " +
          formatValue(props.token.normalizedWalletBalance, props.token.symbol === "USD" ? 2 : 4) +
          ". Click max button to withdraw maximum allowed amount."
      );
      return;
    }

    let amountSplit = value.split(".");
    const newAmountSplit = amountSplit.length === 1 || amountSplit[1] === "" ? amountSplit[0] : amountSplit[0] + "." + amountSplit[1];
    let newAmount = fromBn(toBn(newAmountSplit.toString(), 18));
    const maxDepositAmount =
      props.token.symbol === "ETH" && props.token.normalizedWalletBalance > 1 && !props.token.isWeth
        ? fromBn(toBn(fromBn(props.token.walletBalance18Decimals), 18).sub(toBn("1", 18)))
        : fromBn(props.token.walletBalance18Decimals);
    if (isSmallDifference(maxDepositAmount, newAmount, props.token.symbol)) {
      setDepositAmount(maxDepositAmount);
    } else {
      setDepositAmount(newAmount);
    }
    setInputClass("has-success");
  };

  const increaseDepositAmount = () => {
    if (depositInputValue === "") {
      setDepositInputValue(1);
      onInputChange();
      return;
    }

    if (Math.abs(depositInputValue - props.token.normalizedWalletBalance) >= 1) {
      // if it is not max value
      if (depositInputValueRef.current.value.split(".").length > 1) {
        setDepositInputValue(Math.ceil(Number(depositInputValue)));
        depositInputValueRef.current.value = Math.ceil(Number(depositInputValue));
        onInputChange();
      } else {
        setDepositInputValue(depositInputValue + 1);
        depositInputValueRef.current.value = Number(depositInputValue) + 1;
        onInputChange();
      }
    }
  };

  const decreaseDepositAmount = () => {
    if (depositInputValue !== "" && depositInputValue != 1 && depositInputValue != 0) {
      if (depositInputValueRef.current.value.split(".").length > 1) {
        setDepositInputValue(Math.floor(Number(depositInputValue)));
        depositInputValueRef.current.value = Math.floor(Number(depositInputValue));
        onInputChange();
      } else {
        setDepositInputValue(Number(depositInputValue) - 1);
        depositInputValueRef.current.value = Number(depositInputValue) - 1;
        onInputChange();
      }
    }
  };

  const clearInput = () => {
    setInputClass("");
    setDepositInputValue("");
    depositInputValueRef.current.focus();
  };

  const onMaxButtonClicked = () => {
    const maxDepositAmount =
      props.token.symbol === "ETH" && props.token.normalizedWalletBalance > 1 && !props.token.isWeth
        ? fromBn(toBn(fromBn(props.token.walletBalance18Decimals), 18).sub(toBn("1", 18)))
        : fromBn(props.token.walletBalance18Decimals);
    const maxDepositInputValue =
      props.token.symbol === "ETH"
        ? props.token.normalizedWalletBalance > 1 && !props.token.isWeth
          ? (props.token.normalizedWalletBalance - 1).toFixed(4)
          : props.token.normalizedWalletBalance4Decimals.toFixed(4)
        : props.token.normalizedWalletBalance2Decimals.toFixed(2);
    setDepositInputValue(maxDepositInputValue);
    setDepositAmount(maxDepositAmount);
    setInputClass("has-success");
    setDepositInputErrorMessage("");
  };

  const onKeyPress = e => {
    if (e && e.key === "Escape") {
      props.toggle();
    }
  };

  // const printHealth = () => {
  const getNewHealth = inputValue => {
    const totalDebt = marginAccountData && marginAccountData.debt;
    const totalLiquidity = marginAccountData && parseFloat(formatUnits(marginAccountData.totalLiquidity, 18));

    let value;
    if (props.token.symbol === "USD") {
      value = inputValue;
    } else if (props.token.symbol === "ETH") {
      value = inputValue * Number(underlyingTokenPrice.normalized);
    }

    const health = (Number(totalLiquidity) + Number(value)) / totalDebt;

    const readableHealth = getReadableAccountHealth(totalDebt, health);

    const recoveryThreshold = marginAccountData && web3AccountLoaded ? parseFloat(fromBn(marginAccountData.recoveryThreshold)) * 100 : 0;
    const liquidationThreshold = marginAccountData && web3AccountLoaded ? parseFloat(fromBn(marginAccountData.liquidationThreshold)) * 100 : 0;
    const healthColorClass =
      depositInputValue !== "" && inputClass !== "has-danger"
        ? healthClassName(readableHealth, health * 100, recoveryThreshold, liquidationThreshold)
        : " disabledTextColor";

    return { healthColorClass, readableHealth };
  };

  const calculateAccountBalance = value => {
    const amount = props.token && fromBn(props.token.accountBalance);
    const newAmount = Number(amount) + Number(value);

    return newAmount;
  };

  const calculateWalletBalance = (value, shouldSetStates) => {
    let amount = props.token && fromBn(props.token.walletBalance18Decimals);
    let newAmount = Number(amount) - Number(value);
    if (newAmount > 0) {
      amount = newAmount;
    } else if (isSmallDifference(Number(amount), Number(value), props.token.symbol)) {
      amount = 0;
    } else {
      if (inputClass !== "has-danger" && shouldSetStates) {
        setInputClass("has-danger");
        setDepositInputErrorMessage("You don't have enough funds.  Click max button to deposit maximum allowed amount.");
      }
      amount = 0;
    }

    return Number(amount);
  };

  const onDepositClickHandler = async e => {
    e.stopPropagation();
    depositFunds();
  };

  let printableAccountBalance;
  let printableWalletBalance;
  const max = props.token.normalizedWalletBalance;
  if (depositInputValue && inputClass !== "has-danger") {
    printableAccountBalance = calculateAccountBalance(depositInputValue);
    printableWalletBalance = calculateWalletBalance(depositInputValue, true);
  } else if (depositInputValue === "") {
    calculateAccountBalance(depositInputValue);
    calculateWalletBalance(depositInputValue, true);
    printableAccountBalance = calculateAccountBalance(String(defaultInputValue));
    printableWalletBalance = calculateWalletBalance(String(defaultInputValue), false);
  } else if (inputClass === "has-danger") {
    calculateAccountBalance(depositInputValue);
    calculateWalletBalance(depositInputValue, true);
    printableAccountBalance = calculateAccountBalance(String(max));
    printableWalletBalance = calculateWalletBalance(String(max), false);
  } else {
    printableAccountBalance = props.token.printableAccountBalance;
    printableWalletBalance = props.token.printableWalletBalance;
  }

  const { healthColorClass, readableHealth } = inputClass !== "has-danger" ? getNewHealth(depositInputValue) : getNewHealth(max);
  const shouldBeDisabled = depositInputValue === "" || inputClass === "has-danger";

  const tokenSymbol = props.token.symbol === "USD" ? "USDC" : props.token.symbol;

  return (
    <MyModal
      isOpen={props.isOpen}
      toggle={e => {
        lastClickedModalOverlay = Date.now();
        const diff = lastClickedModalOverlay - lastSelectedInputTimestamp;
        if (diff < 500) {
          lastClickedModalOverlay = null;
          lastSelectedInputTimestamp = null;
          return;
        }
        props.toggle();
      }}
    >
      <div
        className="depositWithdrawModalFrame"
        onClick={e => {
          e.stopPropagation();
        }}
        onMouseDown={e => {
          lastSelectedInputTimestamp = Date.now();
        }}
      >
        <div className="headerOfPortfolioModal">
          <span className="portfolioModalHeaderText">Deposit {tokenSymbol}</span>
          <button aria-hidden data-dismiss="modal" type="button" className="closeModal" onClick={() => props.toggle(true)}>
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>

        <div className="inputAmountAndSettingsErrorMessageContainer">
          <div className="inputAmountAndSettingsContainer">
            <Form>
              <FormGroup>
                <Input
                  id="depositModalInput"
                  type="text"
                  autoComplete="off"
                  placeholder="Deposit amount, decimals allowed... (e.g., 100.50)"
                  className={`inputText${inputClass === "has-danger" ? " inputDanger" : ""}${inputClass === "has-success" ? " inputSuccess" : ""}${
                    inputClass === "has-warning" ? " inputWarning" : ""
                  }`}
                  onChange={e => {
                    onInputChange();
                  }}
                  value={depositInputValue}
                  autoFocus
                  innerRef={depositInputValueRef}
                />
                {inputClass === "has-danger" ? <img className="clearInputError" onClick={clearInput} /> : null}
                {inputClass === "has-success" ? <img className="validInput" /> : null}
                {inputClass === "has-warning" ? <img className="warningInput" /> : null}
                <div className="increaseDecreaseInputButtons">
                  <img className="increaseInputButton" onClick={increaseDepositAmount} />
                  <img className="decreaseInputButton" onClick={decreaseDepositAmount} />
                </div>
              </FormGroup>
            </Form>
            <button id="depositModalMaxButton" className="maxButton" onClick={onMaxButtonClicked}>
              max
            </button>
            <MyTooltip key={"depositModalMaxButton"} target={"depositModalMaxButton"}>
              Max amount to deposit into account.
            </MyTooltip>
          </div>
          <div className="errorMessageDepositWithdrawLendContainer">
            <span className={inputClass === "has-warning" ? "warningMessageDepositWithdrawLendSpan" : "errorMessageDepositWithdrawLendSpan"}>
              {inputClass === "has-danger" || inputClass === "has-warning" ? depositInputErrorMessage : ""}
            </span>
          </div>
        </div>

        <div className="withdrawDepositDataInformationContainer">
          <div className="withdrawDepositDataInformationItem">
            <span id={idPrefix + "account-balance"} className={`portfolioLendSmallLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              Account balance
            </span>
            <MyTooltip key={idPrefix + "account-balance"} target={idPrefix + "account-balance"} optionalOffset={15}>
              New account balance after depositing {tokenSymbol}.
            </MyTooltip>
            <span className={`portfolioLendBigLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              {props.isPositionToken && props.token.type === "short" && printableAccountBalance !== "0.00"
                ? "-" + formatValue(printableAccountBalance, 2)
                : formatValue(printableAccountBalance, 2) + " " + tokenSymbol}
            </span>
          </div>
          <div className="withdrawDepositDataInformationItem">
            <span id={idPrefix + "wallet-balance"} className={`portfolioLendSmallLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              Wallet balance
            </span>
            <MyTooltip key={idPrefix + "wallet-balance"} target={idPrefix + "wallet-balance"} optionalOffset={15}>
              New wallet balance after depositing {tokenSymbol}.
            </MyTooltip>
            <span className={`portfolioLendBigLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              {props.isPositionToken && props.token.type === "short" && printableWalletBalance !== "0.00"
                ? "-" + formatValue(printableWalletBalance, 2)
                : formatValue(printableWalletBalance, 2) + " " + tokenSymbol}
            </span>
          </div>
          {/* {printHealth()} */}
          <div className="withdrawDepositDataInformationItem">
            <span id={idPrefix + "health"} className={`portfolioLendSmallLine${shouldBeDisabled ? " disabledTextColor" : ""}`}>
              Account health
            </span>
            <MyTooltip key={idPrefix + "health"} target={idPrefix + "health"} optionalOffset={15}>
              New account health after depositing {tokenSymbol}. <br /> <br />
              Health is increased after depositing funds.
            </MyTooltip>
            <span className={`portfolioLendBigLine ${healthColorClass}`}>{readableHealth}</span>
          </div>
          {step === 1 && (
            <div className="withdrawDepositDataInformationItem">
              <span className="portfolioLendBigLine">Approval Required (step 1 of 2)</span>
            </div>
          )}
          {step === 2 && (
            <div className="withdrawDepositDataInformationItem">
              <span className="approvingDepositMessage">Approving deposit</span>
              <img className="approvingRequest" />
            </div>
          )}
          {step === 3 && (
            <div className="withdrawDepositDataInformationItem">
              <span className="portfolioLendBigLine">Transaction Confirmation Required (step 2 of 2)</span>
            </div>
          )}
        </div>

        <button id="depositButton" className="withdrawDepositButton" onClick={onDepositClickHandler} disabled={shouldBeDisabled || step} ref={depositButtonRef}>
          deposit
        </button>
      </div>
    </MyModal>
  );
};

DepositFundsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  token: PropTypes.object.isRequired,
  depositFunds: PropTypes.func.isRequired,
  isPositionToken: PropTypes.bool
};

export default DepositFundsModal;
