/* eslint-disable no-undef */
import React, { useState, useEffect, useCallback } from "react";
import { createPortal } from "react-dom";
import { Card, CardBody } from "reactstrap";

const DropdownMenuExpTimeHistorySelectionContainer = ({
  data,
  containerType,
  firstOptionValue,
  secondOptionValue,
  thirdOptionValue,
  setData,
  setSelectValue,
  top,
  left,
  width
}) => {
  const [copiedDataFromProp, setCopiedDataFromProp] = useState(data);

  useEffect(() => {
    setCopiedDataFromProp(data);
  }, []);

  const onRowClickEventHandler = useCallback(
    e => {
      e.stopPropagation();
      const select = document.querySelector(`.-${containerType} > span`);

      const key = e.currentTarget.textContent;
      const options = Array.from(document.querySelectorAll(`.${containerType} .singleOption`));
      const [firstOption, secondOption, thirdOption] = options.slice(0, 3);

      if (
        (key === firstOptionValue && firstOption.classList.contains("selectedOption")) ||
        (key === secondOptionValue && secondOption.classList.contains("selectedOption")) ||
        (key === thirdOptionValue && thirdOption.classList.contains("selectedOption"))
      ) {
        return;
      }
      const newData = new Map(copiedDataFromProp);

      if (
        (key === firstOptionValue && !firstOption.classList.contains("selectedOption")) ||
        (key === secondOptionValue && !secondOption.classList.contains("selectedOption")) ||
        (key === thirdOptionValue && !thirdOption.classList.contains("selectedOption"))
      ) {
        newData.set(key, true);
        let choosenOptionValue = firstOptionValue;
        let choosenOption = firstOption;
        if (key === secondOptionValue) {
          choosenOptionValue = secondOptionValue;
          choosenOption = secondOption;
        }
        if (key === thirdOptionValue) {
          choosenOptionValue = thirdOptionValue;
          choosenOption = thirdOption;
        }
        Array.from(document.querySelectorAll(`.${containerType} .singleOption`)).forEach(el => {
          el.classList.remove("selectedOption");
        });
        choosenOption.classList.add("selectedOption");
        for (let k of newData.keys()) {
          if (k === choosenOptionValue) {
            continue;
          }
          newData.set(k, false);
        }
        setData(newData);
        setSelectValue(choosenOptionValue);
        select.textContent = choosenOptionValue;
        setCopiedDataFromProp(newData);
        return;
      }
      if (firstOption.classList.contains("selectedOption")) {
        newData.set(firstOptionValue, false);
        firstOption.classList.remove("selectedOption");
      } else if (secondOption.classList.contains("selectedOption")) {
        newData.set(secondOptionValue, false);
        secondOption.classList.remove("selectedOption");
      } else if (thirdOption.classList.contains("selectedOption")) {
        newData.set(thirdOptionValue, false);
        thirdOption.classList.remove("selectedOption");
      }

      const selectedOption = options.find(o => o.textContent.trim() === key);
      const optionAlreadySelected = selectedOption.classList.contains("selectedOption");
      if (optionAlreadySelected) {
        const numOfSelected = options.filter(o => o.classList.contains("selectedOption")).length;
        if (numOfSelected === 1) return;
      }
      newData.set(key, !copiedDataFromProp.get(key));
      e.currentTarget.classList.toggle("selectedOption");
      let selectedValue;
      if ([...newData.values()].filter(selected => selected).length > 1) {
        selectedValue = "Custom";
      } else {
        selectedValue = [...newData.keys()].find(k => newData.get(k));
      }
      setSelectValue(selectedValue);
      select.textContent = selectedValue;
      setData(newData);
      setCopiedDataFromProp(newData);
    },
    [copiedDataFromProp, containerType, firstOptionValue]
  );

  return createPortal(
    <div className={`${containerType}`} style={{ top: top, left: left, width: width }}>
      <Card>
        <CardBody>
          <table className="table">
            <tbody>
              {Array.from(copiedDataFromProp.keys()).map(element => {
                return (
                  <tr key={element}>
                    <td className={`singleOption${copiedDataFromProp.get(element) ? " selectedOption" : ""}`} onClick={onRowClickEventHandler}>
                      <span>{element}</span>
                      {copiedDataFromProp.get(element) ? <img className="selectedOptionMark" alt="selected" /> : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </div>,
    document.getElementById("root")
  );
};

export default DropdownMenuExpTimeHistorySelectionContainer;
