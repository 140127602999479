// WEB3
export const LOADING_WEB3 = "LOADING_WEB3";
export const WEB3_LOADED = "WEB3_LOADED";
export const WEB3_UNLOADED = "WEB3_UNLOADED";
export const WEB3_ACCOUNT_LOADED = "WEB3_ACCOUNT_LOADED";
export const WEB3_ACCOUNT_UNLOADED = "WEB3_ACCOUNT_UNLOADED";
export const CURRENT_TIMESTAMP_LOADED = "CURRENT_TIMESTAMP_LOADED";

// CONTRACTS
// TODO: v2 remove unused actions
export const ALL_CONTRACTS_LOADED = "ALL_CONTRACTS_LOADED";
export const MARKETS_LOADED = "MARKETS_LOADED";
export const MARKETS_IMMUTABLE_ATTRIBUTES_LOADED = "MARKETS_IMMUTABLE_ATTRIBUTES_LOADED";
export const MARKETS_MUTABLE_ATTRIBUTES_LOADED = "MARKETS_MUTABLE_ATTRIBUTES_LOADED";
export const MARKET_UPDATED = "MARKET_UPDATED";
export const MARKET_PRICES_UPDATED = "MARKET_PRICES_UPDATED";
export const MARKET_CREATED = "MARKET_CREATED";
export const EXCHANGE_SETTLER_LOADED = "EXCHANGE_SETTLER_LOADED";

export const TOKEN_PAIRS_LOADED = "TOKEN_PAIRS_LOADED";
export const TOKEN_PAIR_SELECTED = "TOKEN_PAIR_SELECTED";
export const EXPIRATION_TIMES_LOADED = "EXPIRATION_TIMES_LOADED";
export const EXPIRATION_TIME_SELECTED = "EXPIRATION_TIME_SELECTED";

export const LOADING_POSITION_TOKENS = "LOADING_POSITION_TOKENS";
export const POSITION_TOKENS_LOADED = "POSITION_TOKENS_LOADED";
export const LP_POSITION_TOKENS_LOADED = "LP_POSITION_TOKENS_LOADED";
export const LP_POSITION_TOKENS_DATA = "LP_POSITION_TOKENS_DATA";
export const LP_TOKEN_ADDED_OR_UPDATED = "LP_TOKEN_ADDED_OR_UPDATED";
export const LOADING_POSITION_TOKEN_ACCOUNT_BALANCE = "LOADING_POSITION_TOKEN_ACCOUNT_BALANCE";
export const POSITION_TOKEN_ACCOUNT_BALANCE_LOADED = "POSITION_TOKEN_ACCOUNT_BALANCE_LOADED";
export const POSITION_TOKEN_ACCOUNT_BALANCES_LOADED = "POSITION_TOKEN_ACCOUNT_BALANCES_LOADED";
export const LOADING_LP_POSITION_TOKEN_ACCOUNT_BALANCE = "LOADING_LP_POSITION_TOKEN_ACCOUNT_BALANCE";
export const LP_POSITION_TOKEN_ACCOUNT_BALANCE_LOADED = "LP_POSITION_TOKEN_ACCOUNT_BALANCE_LOADED";
export const LP_POSITION_TOKEN_ACCOUNT_BALANCES_LOADED = "LP_POSITION_TOKEN_ACCOUNT_BALANCES_LOADED";
export const LOADING_POSITION_TOKEN_WALLET_BALANCE = "LOADING_POSITION_TOKEN_WALLET_BALANCE";
export const POSITION_TOKEN_NAME_AND_SYMBOL_LOADED = "POSITION_TOKEN_NAME_AND_SYMBOL_LOADED";
export const TOKEN_PAIR_ADDED = "TOKEN_PAIR_ADDED";
export const TOKEN_ADDED = "TOKEN_ADDED";
export const POSITION_TOKEN_ADDED = "POSITION_TOKEN_ADDED";
export const UNDERLYING_TOKEN_PRICE_LOADED = "UNDERLYING_TOKEN_PRICE_LOADED";

// BALANCES
export const TOKENS_LOADED = "TOKENS_LOADED";
export const TOKEN_ACCOUNT_BALANCE_LOADED = "TOKEN_ACCOUNT_BALANCE_LOADED";
export const TOKEN_WALLET_BALANCE_LOADED = "TOKEN_WALLET_BALANCE_LOADED";
export const LOADING_TOKEN_ACCOUNT_BALANCE = "LOADING_TOKEN_ACCOUNT_BALANCE";
export const LOADING_TOKEN_WALLET_BALANCE = "LOADING_TOKEN_WALLET_BALANCE";

// USER TRADES
export const LOADING_TRADES = "LOADING_TRADES";
export const TRADES_LOADED = "TRADES_LOADED";
export const LOADING_LP_TRADES = "LOADING_LP_TRADES";
export const LP_TRADES_LOADED = "LP_TRADES_LOADED";
export const LIQUIDATION_TRADES_LOADED = "LIQUIDATION_TRADES_LOADED";
export const TRADE_ADDED = "TRADE_ADDED";
export const LP_TRADE_ADDED = "LP_TRADE_ADDED";
export const LIQUIDATION_TRADE_ADDED = "LIQUIDATION_TRADE_ADDED";

// HISTORICAL DATA
export const LOADING_HISTORICAL_DATA = "LOADING_HISTORICAL_DATA";
export const HISTORICAL_DATA_LOADED = "HISTORICAL_DATA_LOADED";
export const HISTORICAL_DATA_UPDATED = "HISTORICAL_DATA_UPDATED";

// IS SETTLED
export const IS_SETTLED_UPDATED = "IS_SETTLED_UPDATED";
export const MARKETS_SETTLED = "MARKETS_SETTLED";
export const MARKET_SETTLED = "MARKET_SETTLED";

// ERRORS
export const ERROR_ADDED = "ERROR_ADDED";
export const ERROR_REMOVED = "ERROR_REMOVED";
export const ERRORS_REMOVED = "ERRORS_REMOVED";

// NOTIFICATIONS
export const NOTIFICATION_ADDED = "NOTIFICATION_ADDED";
export const NOTIFICATION_REMOVED = "NOTIFICATION_REMOVED";
export const NOTIFICATIONS_REMOVED = "NOTIFICATIONS_REMOVED";

//---------------------------------------------------------------
export const ADD_PENDING_TRANSACTION_NOTIFICATION = "ADD_PENDING_TRANSACTION_NOTIFICATION";
export const CONFIRM_TRANSACTION_NOTIFICATION = "CONFIRM_TRANSACTION_NOTIFICATION";
export const CONFIRM_LIQUIDATED_ACCOUNT_NOTIFICATION = "CONFIRM_LIQUIDATED_ACCOUNT_NOTIFICATION";
export const ADD_FAILED_TRANSACTION_NOTIFICATION = "ADD_FAILED_TRANSACTION_NOTIFICATION";
export const REMOVE_CONFIRMED_TRANSACTION_NOTIFICATION = "REMOVE_CONFIRMED_TRANSACTION_NOTIFICATION";
export const CLEAR_ALL_NOTIFICATIONS = "CLEAR_ALL_NOTIFICATIONS";
export const REMOVE_SETTLEMENT_PENDING_NOTIFICATION = "REMOVE_SETTLEMENT_PENDING_NOTIFICATION";

// MARGIN
export const MARGIN_POOL_DATA_LOADED = "MARGIN_POOL_DATA_LOADED";
export const MARGIN_ACCOUNT_DATA_LOADED = "MARGIN_ACCOUNT_DATA_LOADED";
export const MARGIN_EVENTS_LOADED = "MARGIN_EVENTS_LOADED";
export const LEND_EVENT_ADDED = "LEND_EVENT_ADDED";
export const REDEEM_EVENT_ADDED = "REDEEM_EVENT_ADDED";
export const BORROW_EVENT_ADDED = "BORROW_EVENT_ADDED";
export const REPAID_EVENT_ADDED = "REPAID_EVENT_ADDED";

// MARGIN ACCOUNT DEPOSITS AND WITHDRAWALS
export const DEPOSITS_AND_WITHDRAWALS_LOADED = "DEPOSITS_AND_WITHDRAWALS_LOADED";
export const DEPOSIT_OR_WITHDRAWAL_ADDED = "DEPOSIT_OR_WITHDRAWAL_ADDED";

// INSURANCE FUND DEPOSITS AND WITHDRAWALS
export const INSURANCE_FUND_DEPOSITS_AND_WITHDRAWALS_LOADED = "INSURANCE_FUND_DEPOSITS_AND_WITHDRAWALS_LOADED";
export const INSURANCE_FUND_DEPOSIT_OR_WITHDRAWAL_ADDED = "INSURANCE_FUND_DEPOSIT_OR_WITHDRAWAL_ADDED";

export const INSURANCE_FUND_LOSSES_COVERED_LOADED = "INSURANCE_FUND_LOSSES_COVERED_LOADED";
export const INSURANCE_FUND_LOSS_COVERED_ADDED = "INSURANCE_FUND_LOSS_COVERED_ADDED";

//INSURANCE FUND
export const INSURANCE_FUND_LOADED = "INSURANCE_FUND_LOADED";
export const INSURER_BALANCE_LOADED = "INSURER_BALANCE_LOADED";
