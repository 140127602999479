import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import MySwitch from "./MySwitch.jsx";

const CogMarketsModal = ({ cogModalPositionTop, cogModalPositionRight, isChecked, isDefault, checkHandler, resetSwitches, setCogMarketsModalOpen, isPool }) => {
  const resetFiltersImgRef = useRef();

  useEffect(() => {
    document.onkeydown = onKeyPress;

    return () => {
      document.onkeydown = null;
    };
  }, []);

  const onKeyPress = e => {
    if (e && e.key === "Escape") {
      setCogMarketsModalOpen(false);
      document.querySelector("body").classList.toggle("scrollDisabled");
    }
  };

  const onResetFiltersMouseEnter = e => {
    resetFiltersImgRef.current.classList.add("resetImgHover");
  };

  const onResetFiltersMouseLeave = e => {
    resetFiltersImgRef.current.classList.remove("resetImgHover");
  };

  return createPortal(
    <div
      className="modalSwitchesFrame"
      style={{
        top: cogModalPositionTop + 80,
        right: cogModalPositionRight
      }}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <Container>
        <Row>
          <Col>
            <MySwitch id="open" name="open" disabled={false} checked={isChecked.open} onChange={checkHandler} />
            <span className="popoverLabels">Open</span>
          </Col>
          <Col>
            <MySwitch id="delta" name="delta" disabled={false} checked={isChecked.delta} onChange={checkHandler} />
            <span className="popoverLabels">&Delta; DELTA</span>
          </Col>
        </Row>

        <Row>
          <Col>
            <MySwitch id="vol" name="vol" disabled={false} checked={isChecked.vol} onChange={checkHandler} />
            <span className="popoverLabels">Vol</span>
          </Col>
          <Col>
            <MySwitch id="gamma" name="gamma" disabled={false} checked={isChecked.gamma} onChange={checkHandler} />
            <span className="popoverLabels">&gamma; GAMMA</span>
          </Col>
        </Row>

        <Row>
          <Col>
            <MySwitch id="IV" name="IV" disabled={false} checked={isChecked.IV} onChange={checkHandler} />
            <span className="popoverLabels">IV</span>
          </Col>
          <Col>
            <MySwitch id="theta" name="theta" disabled={false} checked={isChecked.theta} onChange={checkHandler} />
            <span className="popoverLabels">&Theta; THETA</span>
          </Col>
        </Row>

        <Row>
          {isPool ? (
            <Col>
              <MySwitch id="lots" name="lots" disabled={false} checked={isChecked.lots} onChange={checkHandler} />
              <span className="popoverLabels">QTY</span>
            </Col>
          ) : (
            <Col>
              <MySwitch id="lots" name="lots" disabled={false} checked={isChecked.lots} onChange={checkHandler} />
              <span className="popoverLabels">QTY</span>
            </Col>
          )}
          <Col>
            <MySwitch id="vega" name="vega" disabled={false} checked={isChecked.vega} onChange={checkHandler} />
            <span className="popoverLabels">V VEGA</span>
          </Col>
        </Row>

        <Row>
          <div className="resetFlex">
            <div
              className={`imgAndResetFiltersSpan${isDefault ? " imgAndResetFiltersSpanDisabled" : ""}`}
              onClick={() => !isDefault && resetSwitches()}
              onMouseEnter={onResetFiltersMouseEnter}
              onMouseLeave={onResetFiltersMouseLeave}
            >
              <img ref={resetFiltersImgRef} className={`resetImg${isDefault ? " resetImgDisabled" : ""}`} alt="market settings" />
              <span className={`resetFiltersText${isDefault ? " resetFiltersTextDisabled" : ""}`}>Reset Columns</span>
            </div>
          </div>
        </Row>
      </Container>
    </div>,
    document.getElementById("root")
  );
};

export default CogMarketsModal;
