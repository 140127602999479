import { getOptionPrice, getFee, toPreciseString } from "./utils.mjs";
import moment from "moment";
import pkg from "@ethersproject/bignumber";
const { BigNumber } = pkg;

// import { BigNumber } from "@ethersproject/bignumber";
// import { getOptionPrice, getFee, toPreciseString } from "./utils.js";
// import moment from "moment";

export const TWO_POW_96 = BigNumber.from(2).pow(96);
export const FUTURE_SPACING = 10;
export const OPTION_SPACING = 32;
export const MIN_OPTION_PRICE = 0.01;

// FUNCTIONS FOR MARKET HELPER
export class MarketHelper {
  constructor(min, belowTickIndex, currentSqrtPrice, expirationTime, strikePrice, isCall, rate) {
    this.min = min;
    this.currentLiquidity = 0;
    this.belowTickIndex = belowTickIndex;
    this.currentSqrtPrice = currentSqrtPrice;
    this.ticks = [{ index: this.min, liquidity: 0 }];
    this.expirationTime = expirationTime;
    this.strikePrice = strikePrice;
    this.isCall = isCall;
    this.rate = rate;
    this.minFee = 0.003;
    this.maxFee = 0.5;
    this.steepness = 0.001;
  }
}

//methods that was previously inside market helper, this is because we pass markets objects from main thread to web worker and we can't have methods inside objects because it's not serializabled
export function reset(marketHelper) {
  marketHelper.ticks = [{ index: marketHelper.min, liquidity: 0 }];
}

// insert a tick in the linked list; return what the previous tick was
// if thick already exists just return it
export function insert(marketHelper, tick) {
  let old = marketHelper.ticks[0];
  let i = 0;
  const ticksLength = marketHelper.ticks.length;
  while (++i < ticksLength) {
    if (marketHelper.ticks[i].index < tick.index) {
      old = marketHelper.ticks[i];
    }
  }
  if (!marketHelper.ticks.find(existing => existing.index === tick.index)) {
    marketHelper.ticks.push(tick);
    marketHelper.ticks = marketHelper.ticks.sort((a, b) => a.index - b.index);
  } else {
    // special case for first tick which is reset to 0 liquidity
    if (tick.index === 0) {
      marketHelper.ticks[0] = tick;
    }
  }
  return old;
}

export function setTicks(marketHelper, lowerIndex, upperIndex) {
  let lowerOld = insert(marketHelper, { index: lowerIndex, liquidity: 0 });
  let upperOld = insert(marketHelper, { index: upperIndex, liquidity: 0 });
  let limits = {
    lower: lowerIndex,
    upper: upperIndex,
    lowerOld: lowerOld.index,
    upperOld: upperOld.index
  };

  return limits;
}

// FUNCTIONS UNIVERSAL - BOTH FOR OPTIONS AND FUTURES

// args are BigNumber
export function divRoundingUp(value, denominator) {
  const result = value.div(denominator);
  const remainder = value.mod(denominator);
  if (remainder.eq(0)) {
    return result;
  }

  return result.add(1);
}

// off-chain calculation of longs in range (like in dydxMathLib.sol)
export function getDx(liquidity, priceLower, priceUpper) {
  const liquidityBN = BigNumber.from(toPreciseString(liquidity * 10 ** 18));
  const sqrtPriceLower = toSqrtPrice(priceLower);
  const sqrtPriceUpper = toSqrtPrice(priceUpper);

  let returnValue = divRoundingUp(
    liquidityBN
      .mul("0x1000000000000000000000000")
      .mul(sqrtPriceUpper.sub(sqrtPriceLower))
      .div(sqrtPriceUpper),
    sqrtPriceLower
  );

  return returnValue.toString() / 10 ** 18;
}

// args are large number strings
export function getLongsForLiquidity(sqrtPriceLower, sqrtPriceUpper, sqrtPriceCurrent, liquidity) {
  const sqrtPriceLowerBN = BigNumber.from(sqrtPriceLower);
  const sqrtPriceUpperBN = BigNumber.from(sqrtPriceUpper);
  const sqrtPriceCurrentBN = BigNumber.from(sqrtPriceCurrent);
  const liquidityBN = BigNumber.from(liquidity);

  let returnValue = "0";

  // if current price is less than lower, return longs in [lower, upper]
  if (sqrtPriceCurrentBN.lte(sqrtPriceLowerBN)) {
    returnValue = divRoundingUp(
      liquidityBN
        .mul("0x1000000000000000000000000")
        .mul(sqrtPriceUpperBN.sub(sqrtPriceLowerBN))
        .div(sqrtPriceUpperBN),
      sqrtPriceLowerBN
    );
  } else if (sqrtPriceCurrentBN.lt(sqrtPriceUpperBN)) {
    // if current price is less than lower, return longs in [current, upper]
    returnValue = divRoundingUp(
      liquidityBN
        .mul("0x1000000000000000000000000")
        .mul(sqrtPriceUpperBN.sub(sqrtPriceCurrentBN))
        .div(sqrtPriceUpperBN),
      sqrtPriceCurrentBN
    );
  }

  return returnValue.toString();
}

// args are large number strings
export function getLiquidityForLongs(sqrtPriceLower, sqrtPriceUpper, longAmount) {
  const sqrtPriceLowerBN = BigNumber.from(sqrtPriceLower);
  const sqrtPriceUpperBN = BigNumber.from(sqrtPriceUpper);

  // prevent division by zero
  if (sqrtPriceUpperBN.sub(sqrtPriceLowerBN).toString() === "0") {
    return "0";
  }

  const longAmountBN = BigNumber.from(longAmount);
  let returnValue = divRoundingUp(
    longAmountBN.mul(sqrtPriceUpperBN.mul(sqrtPriceLowerBN).div("0x1000000000000000000000000")),
    sqrtPriceUpperBN.sub(sqrtPriceLowerBN)
  );

  return returnValue.toString();
}

// args are large number strings
export function getRequiredBase(sqrtPriceLower, sqrtPriceUpper, sqrtPriceCurrent, liquidity, market, underPrice, rate) {
  const priceLower = fromSqrtPrice(sqrtPriceLower);
  const priceUpper = fromSqrtPrice(sqrtPriceUpper);
  const currentPrice = fromSqrtPrice(sqrtPriceCurrent);

  let helper = new MarketHelper(0, 0, Math.sqrt(currentPrice), market.expirationTime, market.strikePrice, market.isCall, rate);
  helper.currentLiquidity = liquidity / 10 ** 18;
  insert(helper, { index: 64, liquidity: liquidity / 10 ** 18 });
  insert(helper, { index: 69024, liquidity: liquidity / 10 ** 18 });

  const multiplier = market.requiredThreshold - 1;
  const penaltyBase = market.penaltyBase;

  // current price is below range - lp is only selling longs, he only needs extraBase
  if (currentPrice <= priceLower) {
    const rangeBase = getExactBaseInSubrange(helper, Math.sqrt(priceLower), Math.sqrt(priceUpper), liquidity / 10 ** 18, underPrice, moment().unix());
    const extraBase = rangeBase * multiplier + penaltyBase;
    return extraBase;
  }

  // current price is in range - lp is buying and selling longs, he needs exact base for [lower, current] + extra
  if (currentPrice < priceUpper) {
    const baseInLowerRange = getExactBaseInSubrange(helper, Math.sqrt(priceLower), Math.sqrt(currentPrice), liquidity / 10 ** 18, underPrice, moment().unix());
    const baseInUpperRange = getExactBaseInSubrange(helper, Math.sqrt(currentPrice), Math.sqrt(priceUpper), liquidity / 10 ** 18, underPrice, moment().unix());
    const rangeBase = baseInLowerRange + baseInUpperRange;
    const extraBase = rangeBase * multiplier + penaltyBase;
    return baseInLowerRange + extraBase;
  }

  // current price is above range - lp is buying longs in [lower, upper]
  const rangeBase = getExactBaseInSubrange(helper, Math.sqrt(priceLower), Math.sqrt(priceUpper), liquidity / 10 ** 18, underPrice, moment().unix());
  const extraBase = rangeBase * multiplier + penaltyBase;
  return rangeBase + extraBase;
}

export function getTickAtPrice(sqrtPrice) {
  return Math.floor(Math.log(fromSqrtPrice(sqrtPrice)) / Math.log(1.0001)).toString();
}

export function toSqrtPrice(price) {
  return TWO_POW_96.mul((Math.sqrt(price).toFixed(9) * 1000000000).toFixed(0)).div(1000000000);
}

export function fromSqrtPrice(sqrtPrice) {
  return (sqrtPrice.toString() / TWO_POW_96) ** 2;
}

// usage example: getBaseLog(1.0001, 30) = 34014 => 1.0001 ** 34014 = 30
export function getBaseLog(x, y) {
  return Math.round(Math.log(y) / Math.log(x));
}

export function getCurrentTick(volATM, strikePrice, wethPrice) {
  let volatility;
  if (strikePrice > wethPrice) {
    volatility = volATM * Math.sqrt(strikePrice / wethPrice);
  } else {
    volatility = volATM * Math.sqrt(wethPrice / strikePrice);
  }

  return getBaseLog(1.0001, volatility);
}

export function isMarketEmpty(marketHelper) {
  // special case when not initialized
  if (marketHelper.ticks.length === 1 && marketHelper.ticks[0].liquidity === 0) {
    return true;
  }

  return marketHelper.ticks.length === 2 && marketHelper.ticks[0].liquidity === 0 && marketHelper.ticks[1].liquidity === 0;
}

// FUNCTIONS FOR OPTIONS

export function getVolatilityWhenBuyingOptions(marketHelper) {
  // if there is liquidity, return current price
  if (marketHelper.currentLiquidity > 0) {
    return marketHelper.currentSqrtPrice ** 2;
  }

  // if there is no liquidity, find first
  const currentVolatility = marketHelper.currentSqrtPrice ** 2;
  const ticksLength = marketHelper.ticks.length;
  for (let i = 0; i < ticksLength; i++) {
    const tickVolatility = 1.0001 ** marketHelper.ticks[i].index;
    if (tickVolatility >= currentVolatility) {
      return tickVolatility;
    }
  }

  return 0;
}

export function getVolatilityWhenSellingOptions(marketHelper) {
  // if there is liquidity, return current price
  if (marketHelper.currentLiquidity > 0) {
    return marketHelper.currentSqrtPrice ** 2;
  }

  // if there is no liquidity, find first
  const currentVolatility = marketHelper.currentSqrtPrice ** 2;
  const ticksLength = marketHelper.ticks.length;
  for (let i = ticksLength - 1; i >= 0; i--) {
    const tickVolatility = 1.0001 ** marketHelper.ticks[i].index;
    if (tickVolatility <= currentVolatility) {
      return tickVolatility;
    }
  }

  return 0;
}

export function getInputAmountWhenBuyingOptionsUpToTargetImpact(market, targetImpactPercent, underPrice) {
  // handle empty market
  if (isMarketEmpty(market.marketHelper)) {
    return 0;
  }

  // get max amount of longs that market is selling
  const maxLongs = getMaxLongAmountWhenBuyingOptions(market.marketHelper) * 0.999999999;
  const impactlessOptionPrice = parseFloat(market.openLongPrice.toString());

  // handle when option price is extremely cheap (less than $0.01, we don't care about impact)
  if (impactlessOptionPrice < MIN_OPTION_PRICE) {
    return maxLongs;
  }

  let inputAmount = maxLongs / 2;
  let step = maxLongs / 2;
  for (let j = 0; j < 40; j++) {
    const baseAmount = getBaseAmountWhenBuyingOptions(market.marketHelper, inputAmount, underPrice);
    const optionPrice = baseAmount / inputAmount;
    const priceImpact = ((optionPrice - impactlessOptionPrice) / impactlessOptionPrice) * 100;

    // check if close enough
    if (Math.abs(priceImpact - targetImpactPercent) < 0.001) {
      break;
    }

    step = step / 2;
    inputAmount += step * (priceImpact > targetImpactPercent ? -1 : 1);
  }

  return Math.min(maxLongs, inputAmount);
}

export function getInputAmountWhenSellingOptionsDownToTargetImpact(market, targetImpactPercent, underPrice) {
  // handle empty market
  if (isMarketEmpty(market.marketHelper)) {
    return 0;
  }

  // get max amount of longs that market is buying
  const maxLongs = getMaxLongAmountWhenSellingOptions(market.marketHelper) * 0.999999999;
  const impactlessOptionPrice = parseFloat(market.closeLongPrice.toString());

  // handle when option price is extremely cheap (less than $0.01, we don't care about impact)
  if (impactlessOptionPrice < MIN_OPTION_PRICE) {
    return maxLongs;
  }

  let inputAmount = maxLongs / 2;
  let step = maxLongs / 2;
  for (let j = 0; j < 40; j++) {
    const baseAmount = getBaseAmountWhenSellingOptions(market.marketHelper, inputAmount, underPrice);
    const optionPrice = baseAmount / inputAmount;
    const priceImpact = ((impactlessOptionPrice - optionPrice) / impactlessOptionPrice) * 100;
    // check if close enough
    if (Math.abs(priceImpact - targetImpactPercent) < 0.001) {
      break;
    }

    step = step / 2;
    inputAmount += step * (priceImpact > targetImpactPercent ? -1 : 1);
  }

  return Math.min(maxLongs, inputAmount);
}

export function getInputAmountWhenBuyingOptionsUpToBaseBalance(market, baseBalance, underPrice) {
  // get max amount of longs that market is selling
  const maxLongs = getMaxLongAmountWhenBuyingOptions(market.marketHelper) * 0.999999999;

  let inputAmount = maxLongs / 2;
  let step = maxLongs / 2;
  for (let j = 0; j < 40; j++) {
    const baseAmount = getBaseAmountWhenBuyingOptions(market.marketHelper, inputAmount, underPrice);

    // check if close enough
    if (baseAmount <= baseBalance && baseBalance - baseAmount < 0.001) {
      break;
    }

    step = step / 2;
    inputAmount += step * (baseAmount > baseBalance ? -1 : 1);
  }

  if (inputAmount < 0.000001) {
    inputAmount = 0;
  }

  return Math.min(maxLongs, inputAmount);
}

export function getBaseAmountWhenBuyingOptions(marketHelper, longsToBuy, underPrice, ignoreFees = false) {
  // cache
  const cache = {
    currentSqrtPrice: marketHelper.currentSqrtPrice,
    currentLiquidity: marketHelper.currentLiquidity,
    nextTickToCross: getNextTickForOptionsMarket(marketHelper, marketHelper.belowTickIndex, true)
  };

  let optionPrice1 = 0;
  let exact1WithFees = 0;
  // NOTE: just in case, to prevent infinite loop
  for (let i = 0; i < 100; i++) {
    let exactBase = 0;
    const nextSqrtPrice = Math.sqrt(1.0001 ** cache.nextTickToCross.index);
    const maxLongs = (cache.currentLiquidity * (nextSqrtPrice - cache.currentSqrtPrice)) / nextSqrtPrice / cache.currentSqrtPrice;

    if (longsToBuy <= maxLongs) {
      const newPrice = (cache.currentLiquidity * cache.currentSqrtPrice) / (cache.currentLiquidity - cache.currentSqrtPrice * longsToBuy);
      exactBase = getExactBaseInSubrange(marketHelper, cache.currentSqrtPrice, newPrice, cache.currentLiquidity, underPrice, moment().unix());
      optionPrice1 = exactBase / longsToBuy;

      cache.currentSqrtPrice = newPrice;
      longsToBuy = 0;
    } else {
      exactBase = getExactBaseInSubrange(marketHelper, cache.currentSqrtPrice, nextSqrtPrice, cache.currentLiquidity, underPrice, moment().unix());
      optionPrice1 = maxLongs > 0 ? exactBase / maxLongs : 0;

      cache.currentSqrtPrice = nextSqrtPrice;
      longsToBuy -= maxLongs;
    }

    // add to exactBase with fees
    let fee = 0;
    if (!ignoreFees) {
      fee = getFee(underPrice, optionPrice1, marketHelper.minFee, marketHelper.maxFee, marketHelper.steepness);
    }
    exact1WithFees += exactBase * (1 + fee);

    // check if needs to break
    if (longsToBuy === 0 || cache.nextTickToCross.index === 69088) return exact1WithFees;

    // cross to next tick
    crossTick(marketHelper, cache, true, false);
  }

  return exact1WithFees;
}

export function getBaseAmountWhenSellingOptions(marketHelper, longsToSell, underPrice, ignoreFees = false) {
  // cache
  const cache = {
    currentSqrtPrice: marketHelper.currentSqrtPrice,
    currentLiquidity: marketHelper.currentLiquidity,
    nextTickToCross: marketHelper.ticks.find(existing => existing.index === marketHelper.belowTickIndex)
  };

  let optionPrice1 = 0;
  let exact1WithFees = 0;
  // NOTE: just in case, to prevent infinite loop
  for (let i = 0; i < 100; i++) {
    let exactBase = 0;
    const nextSqrtPrice = Math.sqrt(1.0001 ** cache.nextTickToCross.index);
    const maxLongs = (cache.currentLiquidity * (cache.currentSqrtPrice - nextSqrtPrice)) / nextSqrtPrice / cache.currentSqrtPrice;

    if (longsToSell <= maxLongs) {
      const newPrice = (cache.currentLiquidity * cache.currentSqrtPrice) / (cache.currentLiquidity + cache.currentSqrtPrice * longsToSell);
      exactBase = getExactBaseInSubrange(marketHelper, newPrice, cache.currentSqrtPrice, cache.currentLiquidity, underPrice, moment().unix());
      optionPrice1 = exactBase / longsToSell;

      cache.currentSqrtPrice = newPrice;
      longsToSell = 0;
    } else {
      exactBase = getExactBaseInSubrange(marketHelper, nextSqrtPrice, cache.currentSqrtPrice, cache.currentLiquidity, underPrice, moment().unix());
      optionPrice1 = maxLongs > 0 ? exactBase / maxLongs : 0;

      cache.currentSqrtPrice = nextSqrtPrice;
      longsToSell -= maxLongs;
    }

    // add to exactBase with fees
    let fee = 0;
    if (!ignoreFees) {
      fee = getFee(underPrice, optionPrice1, marketHelper.minFee, marketHelper.maxFee, marketHelper.steepness);
    }
    exact1WithFees += exactBase * (1 - fee);

    // check if needs to break
    if (longsToSell === 0 || cache.nextTickToCross.index === 0) return exact1WithFees;

    // cross to next tick
    crossTick(marketHelper, cache, true, true);
  }

  return exact1WithFees;
}

// volatility price
export function getPriceWhenBuyingOptions(marketHelper, longsToBuy) {
  // handle empty market
  if (isMarketEmpty(marketHelper)) {
    return 1.0001 ** 69088;
  }

  // handle when longs to buy is 0
  if (longsToBuy === 0) {
    return marketHelper.currentSqrtPrice ** 2;
  }

  // cache
  const cache = {
    currentSqrtPrice: marketHelper.currentSqrtPrice,
    currentLiquidity: marketHelper.currentLiquidity,
    nextTickToCross: getNextTickForOptionsMarket(marketHelper, marketHelper.belowTickIndex, true)
  };

  for (let i = 0; i < 100; i++) {
    const nextSqrtPrice = Math.sqrt(1.0001 ** cache.nextTickToCross.index);
    const maxLongs = (cache.currentLiquidity * (nextSqrtPrice - cache.currentSqrtPrice)) / nextSqrtPrice / cache.currentSqrtPrice;

    if (longsToBuy <= maxLongs) {
      const newPrice = (cache.currentLiquidity * cache.currentSqrtPrice) / (cache.currentLiquidity - cache.currentSqrtPrice * longsToBuy);
      cache.currentSqrtPrice = newPrice;
      longsToBuy = 0;
    } else {
      cache.currentSqrtPrice = nextSqrtPrice;
      longsToBuy -= maxLongs;
    }

    // check if needs to break
    if (longsToBuy === 0) break;

    // cross to next tick
    crossTick(marketHelper, cache, true, false);
  }

  return cache.currentSqrtPrice ** 2;
}

export function getPriceWhenSellingOptions(marketHelper, longsToSell) {
  // handle empty market
  if (isMarketEmpty(marketHelper)) {
    return 1;
  }

  // handle when longs to sell is 0
  if (longsToSell === 0) {
    return marketHelper.currentSqrtPrice ** 2;
  }

  // cache
  const cache = {
    currentSqrtPrice: marketHelper.currentSqrtPrice,
    currentLiquidity: marketHelper.currentLiquidity,
    nextTickToCross: marketHelper.ticks.find(existing => existing.index === marketHelper.belowTickIndex)
  };

  for (let i = 0; i < 100; i++) {
    const nextSqrtPrice = Math.sqrt(1.0001 ** cache.nextTickToCross.index);
    const maxLongs = (cache.currentLiquidity * (cache.currentSqrtPrice - nextSqrtPrice)) / nextSqrtPrice / cache.currentSqrtPrice;

    if (longsToSell <= maxLongs) {
      const newPrice = (cache.currentLiquidity * cache.currentSqrtPrice) / (cache.currentLiquidity + cache.currentSqrtPrice * longsToSell);
      cache.currentSqrtPrice = newPrice;
      longsToSell = 0;
    } else {
      cache.currentSqrtPrice = nextSqrtPrice;
      longsToSell -= maxLongs;
    }

    // check if needs to break
    if (longsToSell === 0) break;

    // cross to next tick
    crossTick(marketHelper, cache, true, true);
  }

  return cache.currentSqrtPrice ** 2;
}

// max amount that user can buy from market, a.k.a. options supply on market
export function getMaxLongAmountWhenBuyingOptions(marketHelper) {
  // cache
  let currentSqrtPrice = marketHelper.currentSqrtPrice;
  let currentLiquidity = marketHelper.currentLiquidity;
  let nextTickToCross = getNextTickForOptionsMarket(marketHelper, marketHelper.belowTickIndex, true);

  let totalLongs = 0;

  // go through a ticks list
  const ticksLength = marketHelper.ticks.length;
  for (let i = 0; i < ticksLength; i++) {
    // add max longs in current range
    const nextSqrtPrice = Math.sqrt(1.0001 ** nextTickToCross.index);
    const maxLongs = (currentLiquidity * (nextSqrtPrice - currentSqrtPrice)) / nextSqrtPrice / currentSqrtPrice;
    if (maxLongs > 0.000001) {
      totalLongs += maxLongs;
    }

    // cross to next tick
    currentSqrtPrice = nextSqrtPrice;
    const returnObject = crossFunc(marketHelper, nextTickToCross, currentLiquidity, OPTION_SPACING, false);
    currentLiquidity = returnObject.currentLiquidity;
    nextTickToCross = returnObject.nextTickToCross;
  }

  return totalLongs;
}

export function getMaxLongAmountWhenSellingOptions(marketHelper) {
  // cache
  let currentSqrtPrice = marketHelper.currentSqrtPrice;
  let currentLiquidity = marketHelper.currentLiquidity;
  let nextTickToCross = marketHelper.ticks.find(existing => existing.index === marketHelper.belowTickIndex);

  // go through a ticks list
  let totalLongs = 0;
  const ticksLength = marketHelper.ticks.length;
  for (let i = 0; i < ticksLength; i++) {
    const nextSqrtPrice = Math.sqrt(1.0001 ** nextTickToCross.index);
    const maxLongs = (currentLiquidity * (currentSqrtPrice - nextSqrtPrice)) / nextSqrtPrice / currentSqrtPrice;
    if (maxLongs > 0.000001) {
      totalLongs += maxLongs;
    }

    // cross to next tick
    currentSqrtPrice = nextSqrtPrice;
    const returnObject = crossFunc(marketHelper, nextTickToCross, currentLiquidity, OPTION_SPACING, true);
    currentLiquidity = returnObject.currentLiquidity;
    nextTickToCross = returnObject.nextTickToCross;
  }

  return totalLongs;
}

// calculates longs in range
export function getExactLongsInSubrange(lowerPrice, upperPrice, subrangeLiquidity) {
  // calculate step count in non-sqrt domain
  let maxLongsSum = 0;
  const lowerSquaredPrice = lowerPrice ** 2;
  const upperSquaredPrice = upperPrice ** 2;
  const stepCount = Math.ceil((upperSquaredPrice - lowerSquaredPrice) / 10);

  let range = upperPrice - lowerPrice;
  let lastLowerPrice = lowerPrice;
  for (let i = 0; i < stepCount; i++) {
    const upperSubrangePrice = lowerPrice + (range * (i + 1)) / stepCount;
    const maxLongs = (subrangeLiquidity * (upperSubrangePrice - lastLowerPrice)) / upperSubrangePrice / lastLowerPrice;
    maxLongsSum += maxLongs;
    lastLowerPrice = upperSubrangePrice;
  }

  return maxLongsSum;
}

// calculates base in range
export function getExactBaseInSubrange(marketHelper, lowerPrice, upperPrice, subrangeLiquidity, underPrice, currentTime) {
  // calculate step count in non-sqrt domain
  let exactBase = 0;
  const lowerSquaredPrice = lowerPrice ** 2;
  const upperSquaredPrice = upperPrice ** 2;
  const stepCount = Math.ceil((upperSquaredPrice - lowerSquaredPrice) / 10);

  let range = upperPrice - lowerPrice;
  let lastLowerPrice = lowerPrice;
  for (let i = 0; i < stepCount; i++) {
    const upperSubrangePrice = lowerPrice + (range * (i + 1)) / stepCount;
    const midPrice2 = (lastLowerPrice + upperSubrangePrice) / 2;
    const optionPrice1 = getOptionPrice(
      marketHelper.rate,
      marketHelper.expirationTime,
      marketHelper.strikePrice,
      marketHelper.isCall,
      underPrice,
      midPrice2 ** 2,
      currentTime
    );
    const maxLongs = (subrangeLiquidity * (upperSubrangePrice - lastLowerPrice)) / upperSubrangePrice / lastLowerPrice;
    exactBase += maxLongs * optionPrice1;
    lastLowerPrice = upperSubrangePrice;
  }

  return exactBase;
}

// all prices are sqrt prices
export function getPositionHealth(marketHelper, lowerPrice, upperPrice, currentPrice, subrangeLiquidity, underPrice, baseReserves) {
  // get minimum base required to cover position range, nevermind where current price is
  const rangeBase = getExactBaseInSubrange(marketHelper, lowerPrice, upperPrice, subrangeLiquidity, underPrice, moment().unix());

  // current price is below range - lp is holding longs only in range
  if (currentPrice <= lowerPrice) {
    return (baseReserves + rangeBase) / rangeBase;
  }

  // current price is in range - lp is buying and selling longs,
  // he needs exact base for [lower, current] + extra
  if (currentPrice < upperPrice) {
    //  he needs this much base to buy all the longs down to lowerPrice
    const subrangeBase = getExactBaseInSubrange(marketHelper, lowerPrice, currentPrice, subrangeLiquidity, underPrice, moment().unix());
    return (baseReserves + (rangeBase - subrangeBase)) / rangeBase;
  }

  // current price is above range - lp is holding base only in range
  return baseReserves / rangeBase;
}

export function isPriceInGap(marketHelper) {
  let left = getVolatilityWhenSellingOptions(marketHelper);
  let right = getVolatilityWhenBuyingOptions(marketHelper);
  return left !== right;
}

export function getLimits(lowerPrice, upperPrice, helper, tickSpacing) {
  // default is max range (1x leverage)
  const lowerTick = Math.max(0, getTickAtPrice(toSqrtPrice(lowerPrice)));
  const upperTick = Math.min(69088, getTickAtPrice(toSqrtPrice(upperPrice)));

  let lower = getNearestValidEvenTick(lowerTick, tickSpacing);
  let upper = getNearestValidEvenTick(upperTick, tickSpacing) + Number(tickSpacing);
  let lowerOld = insert(helper, { index: lower, liquidity: 0 });
  let upperOld = insert(helper, { index: upper, liquidity: 0 });

  return { lowerOld: lowerOld.index, lower: lower, upperOld: upperOld.index, upper: upper };
}

function getNextTickForOptionsMarket(marketHelper, belowTickIndex, isBuy) {
  const ticksLength = marketHelper.ticks.length;
  for (let i = 0; i < ticksLength; i++) {
    if (marketHelper.ticks[i].index === belowTickIndex) {
      if (isBuy) {
        return marketHelper.ticks[Math.min(marketHelper.ticks.length - 1, i + 1)];
      } else {
        return marketHelper.ticks[Math.max(0, i - 1)];
      }
    }
  }
}

function crossFunc(marketHelper, nextTickToCross, currentLiquidity, tickSpacing, isSell) {
  if (isSell) {
    if ((nextTickToCross.index / tickSpacing) % 2 === 0) {
      currentLiquidity -= nextTickToCross.liquidity;
    } else {
      currentLiquidity += nextTickToCross.liquidity;
    }
    nextTickToCross = getNextTickForOptionsMarket(marketHelper, nextTickToCross.index, false);
  } else {
    if ((nextTickToCross.index / tickSpacing) % 2 === 0) {
      currentLiquidity += nextTickToCross.liquidity;
    } else {
      currentLiquidity -= nextTickToCross.liquidity;
    }
    nextTickToCross = getNextTickForOptionsMarket(marketHelper, nextTickToCross.index, true);
  }

  return { currentLiquidity, nextTickToCross };
}

// FUNCTIONS FOR FUTURES

export function getInputAmountWhenBuyingFuturesUpToTargetImpact(market, targetImpactPercent, underPrice) {
  // get max amount of longs that market is selling
  const maxLongs = getMaxLongAmountWhenBuyingFutures(market.marketHelper) * 0.999999999;
  const impactlessPrice = parseFloat(market.openLongPrice.toString());

  let inputAmount = maxLongs / 2;
  let step = maxLongs / 2;
  // 60 steps, because spot market is much larger than options market
  for (let j = 0; j < 60; j++) {
    const baseAmount = getBaseAmountWhenBuyingFutures(market.marketHelper, inputAmount, underPrice);
    const optionPrice = baseAmount / inputAmount;
    const priceImpact = ((optionPrice - impactlessPrice) / impactlessPrice) * 100;

    // check if close enough
    if (Math.abs(priceImpact - targetImpactPercent) < 0.001) {
      break;
    }

    step = step / 2;
    inputAmount += step * (priceImpact > targetImpactPercent ? -1 : 1);
  }

  return Math.min(maxLongs, inputAmount);
}

export function getInputAmountWhenSellingFuturesDownToTargetImpact(market, targetImpactPercent, underPrice) {
  // get max amount of longs that market is buying
  const maxLongs = getMaxLongAmountWhenSellingFutures(market.marketHelper, market.isFuture) * 0.999999999;
  const impactlessPrice = parseFloat(market.closeLongPrice.toString());

  let inputAmount = maxLongs / 2;
  let step = maxLongs / 2;
  // 60 steps, because spot market is much larger than options market
  for (let j = 0; j < 60; j++) {
    const baseAmount = getBaseAmountWhenSellingFutures(market.marketHelper, inputAmount, underPrice);
    const optionPrice = baseAmount / inputAmount;
    const priceImpact = ((impactlessPrice - optionPrice) / impactlessPrice) * 100;
    // check if close enough
    if (Math.abs(priceImpact - targetImpactPercent) < 0.001) {
      break;
    }

    step = step / 2;
    inputAmount += step * (priceImpact > targetImpactPercent ? -1 : 1);
  }

  return Math.min(maxLongs, inputAmount);
}

// max amount that user can buy from futures market, a.k.a. futures supply on market
export function getMaxLongAmountWhenBuyingFutures(marketHelper) {
  let currentSqrtPrice = marketHelper.currentSqrtPrice;
  let currentLiquidity = marketHelper.currentLiquidity;
  let nextTickToCross = getNextTickForOptionsMarket(marketHelper, marketHelper.belowTickIndex, true);

  let totalLongs = 0;

  // go through a ticks list
  const ticksLength = marketHelper.ticks.length;
  for (let i = 0; i < ticksLength; i++) {
    // add max longs in current range
    const nextSqrtPrice = Math.sqrt(1.0001 ** nextTickToCross.index);
    const maxLongs = (currentLiquidity * (nextSqrtPrice - currentSqrtPrice)) / nextSqrtPrice / currentSqrtPrice;
    if (maxLongs > 0.000001) {
      totalLongs += maxLongs;
    }

    // cross to next tick
    currentSqrtPrice = nextSqrtPrice;
    const returnObject = crossFunc(marketHelper, nextTickToCross, currentLiquidity, FUTURE_SPACING, false);
    currentLiquidity = returnObject.currentLiquidity;
    nextTickToCross = returnObject.nextTickToCross;
  }

  // cap to 1 trillion
  return Math.min(1000000000000, totalLongs);
}

export function getMaxLongAmountWhenSellingFutures(marketHelper) {
  let currentSqrtPrice = marketHelper.currentSqrtPrice;
  let currentLiquidity = marketHelper.currentLiquidity;
  let nextTickToCross = marketHelper.ticks.find(existing => existing.index === marketHelper.belowTickIndex);

  // go through a ticks list
  let totalLongs = 0;
  const ticksLength = marketHelper.ticks.length;
  for (let i = 0; i < ticksLength; i++) {
    const nextSqrtPrice = Math.sqrt(1.0001 ** nextTickToCross.index);
    const maxLongs = (currentLiquidity * (currentSqrtPrice - nextSqrtPrice)) / nextSqrtPrice / currentSqrtPrice;
    if (maxLongs > 0.000001) {
      totalLongs += maxLongs;
    }

    // cross to next tick
    currentSqrtPrice = nextSqrtPrice;
    const returnObject = crossFunc(marketHelper, nextTickToCross, currentLiquidity, FUTURE_SPACING, true);
    currentLiquidity = returnObject.currentLiquidity;
    nextTickToCross = returnObject.nextTickToCross;
  }

  // cap to 1 trillion
  return Math.min(1000000000000, totalLongs);
}

export function getBaseAmountWhenBuyingFutures(marketHelper, longsToBuy, underPrice, ignoreFees = false) {
  // cache
  const cache = {
    currentSqrtPrice: marketHelper.currentSqrtPrice,
    currentLiquidity: marketHelper.currentLiquidity,
    nextTickToCross: getNextTickForOptionsMarket(marketHelper, marketHelper.belowTickIndex, true)
  };
  // let currentSqrtPrice = marketHelper.currentSqrtPrice;
  // let currentLiquidity = marketHelper.currentLiquidity;
  // let nextTickToCross = getNextTickForOptionsMarket(marketHelper, marketHelper.belowTickIndex, true);

  let exact1WithFees = 0;
  for (let i = 0; i < 100; i++) {
    let exactBase = 0;
    const nextSqrtPrice = Math.sqrt(1.0001 ** cache.nextTickToCross.index);
    const maxLongs = (cache.currentLiquidity * (nextSqrtPrice - cache.currentSqrtPrice)) / nextSqrtPrice / cache.currentSqrtPrice;

    if (longsToBuy <= maxLongs) {
      const newPrice = (cache.currentLiquidity * cache.currentSqrtPrice) / (cache.currentLiquidity - cache.currentSqrtPrice * longsToBuy);
      const avgPrice = (newPrice ** 2 + cache.currentSqrtPrice ** 2) / 2;
      exactBase = longsToBuy * avgPrice;

      cache.currentSqrtPrice = newPrice;
      longsToBuy = 0;
    } else {
      const avgPrice = (nextSqrtPrice ** 2 + cache.currentSqrtPrice ** 2) / 2;
      exactBase = maxLongs * avgPrice;

      cache.currentSqrtPrice = nextSqrtPrice;
      longsToBuy -= maxLongs;
    }

    // add to exactBase with fees
    const fee = ignoreFees ? 0 : 0.3 / 100; // 0.3%
    exact1WithFees += exactBase * (1 + fee);

    // check if needs to break
    if (longsToBuy === 0 || cache.nextTickToCross.index === 887270) return exact1WithFees;

    // cross to next tick
    crossTick(marketHelper, cache, false, false);
  }

  return exact1WithFees;
}

export function getBaseAmountWhenSellingFutures(marketHelper, longsToSell, underPrice, ignoreFees = false) {
  // cache
  const cache = {
    currentSqrtPrice: marketHelper.currentSqrtPrice,
    currentLiquidity: marketHelper.currentLiquidity,
    nextTickToCross: marketHelper.ticks.find(existing => existing.index === marketHelper.belowTickIndex)
  };

  let exact1WithFees = 0;
  for (let i = 0; i < 100; i++) {
    let exactBase = 0;
    const nextSqrtPrice = Math.sqrt(1.0001 ** cache.nextTickToCross.index);
    const maxLongs = (cache.currentLiquidity * (cache.currentSqrtPrice - nextSqrtPrice)) / nextSqrtPrice / cache.currentSqrtPrice;

    if (longsToSell <= maxLongs) {
      const newPrice = (cache.currentLiquidity * cache.currentSqrtPrice) / (cache.currentLiquidity + cache.currentSqrtPrice * longsToSell);
      const avgPrice = (newPrice ** 2 + cache.currentSqrtPrice ** 2) / 2;
      exactBase = longsToSell * avgPrice;

      cache.currentSqrtPrice = newPrice;
      longsToSell = 0;
    } else {
      const avgPrice = (nextSqrtPrice ** 2 + cache.currentSqrtPrice ** 2) / 2;
      exactBase = maxLongs * avgPrice;

      cache.currentSqrtPrice = nextSqrtPrice;
      longsToSell -= maxLongs;
    }

    // add to exactBase with fees
    const fee = ignoreFees ? 0 : 0.3 / 100; // 0.3%
    exact1WithFees += exactBase * (1 - fee);

    // check if needs to break
    if (longsToSell === 0 || cache.nextTickToCross.index === -887260) return exact1WithFees;

    // cross to next tick
    crossTick(marketHelper, cache, false, true);
  }

  return exact1WithFees;
}

// FUNCTIONS FOR BOTH OPTIONS AND FUTURES

function crossTick(helper, cache, isOption, isSell) {
  const tickSpacing = isOption ? OPTION_SPACING : FUTURE_SPACING;
  // cross to next tick
  const returnObject = crossFunc(helper, cache.nextTickToCross, cache.currentLiquidity, tickSpacing, isSell);
  cache.currentLiquidity = returnObject.currentLiquidity;
  cache.nextTickToCross = returnObject.nextTickToCross;

  if (cache.currentLiquidity === 0) {
    // we step into a zone that has liquidity - or we reach the end of the linked list.
    cache.currentSqrtPrice = Math.sqrt(1.0001 ** cache.nextTickToCross.index);
    const returnObject = crossFunc(helper, cache.nextTickToCross, cache.currentLiquidity, tickSpacing, isSell);
    cache.currentLiquidity = returnObject.currentLiquidity;
    cache.nextTickToCross = returnObject.nextTickToCross;
  }
}

// note: doesn't work when tickAtPrice is 0
export function getNearestValidEvenTick(tickAtPrice, tickSpacing) {
  const nearestValidTick = tickAtPrice - (tickAtPrice % tickSpacing);
  return (nearestValidTick / tickSpacing) % 2 === 0 ? Number(nearestValidTick) : Number(nearestValidTick) + Number(tickSpacing);
}

export function getOraclePriceMaxBuyAmount(maxOptionsBeingSoldOnMarket, market) {
  const limitOraclePrice = market.priceOracle.upperPriceInVol;

  let inputAmount = maxOptionsBeingSoldOnMarket / 2;
  let step = maxOptionsBeingSoldOnMarket / 2;
  for (let j = 0; j < 40; j++) {
    const priceAfterBuy = getPriceWhenBuyingOptions(market.marketHelper, inputAmount);

    // check if close enough
    if (priceAfterBuy < limitOraclePrice && limitOraclePrice - priceAfterBuy < 0.001) {
      break;
    }

    step = step / 2;
    inputAmount += step * (priceAfterBuy < limitOraclePrice ? 1 : -1);
  }
  const oraclePriceMaxAmount = inputAmount;

  return oraclePriceMaxAmount;
}

export function getOraclePriceMaxSellAmount(maxOptionsBeingBoughtOnMarket, market) {
  const limitOraclePrice = market.priceOracle.lowerPriceInVol;

  let inputAmount = maxOptionsBeingBoughtOnMarket / 2;
  let step = maxOptionsBeingBoughtOnMarket / 2;
  for (let j = 0; j < 40; j++) {
    const priceAfterSell = getPriceWhenSellingOptions(market.marketHelper, inputAmount);

    // check if close enough
    if (priceAfterSell > limitOraclePrice && priceAfterSell - limitOraclePrice < 0.001) {
      break;
    }

    step = step / 2;
    inputAmount += step * (priceAfterSell > limitOraclePrice ? 1 : -1);
  }
  const oraclePriceMaxAmount = inputAmount;

  return oraclePriceMaxAmount;
}
// returns volatility from market if not empty, or user set volatility
export const getVolatility = (market, currentTick) => {
  let volatility;

  const marketEmpty = isMarketEmpty(market.marketHelper);
  const priceInGap = isPriceInGap(market.marketHelper);

  if (marketEmpty || priceInGap) {
    volatility = 1.0001 ** currentTick;
  } else {
    volatility = Math.round(parseFloat(market.longPriceInVol));
  }
  return volatility;
};

export const getOptionsInRangeFromLongsOnMarket = (market, currentTick, longsOnMarket, addTabMinPriceTickIndex, addTabMaxPriceTickIndex, web3) => {
  const currentPrice = Number(getVolatility(market, currentTick));
  const lowerPrice = 1.0001 ** addTabMinPriceTickIndex;
  const upperPrice = 1.0001 ** addTabMaxPriceTickIndex;

  // if user is adding liquidity in range at or above current price [69%, 92%] or [82%, 92%] @80%
  if (currentPrice < upperPrice) {
    const lowerSqrtPrice = toSqrtPrice(lowerPrice).toString();
    const upperSqrtPrice = toSqrtPrice(upperPrice).toString();
    const currentSqrtPrice = toSqrtPrice(currentPrice);
    const longsOnMarketBN = web3.utils.toWei(toPreciseString(longsOnMarket));

    const liquidityBN = getLiquidityForLongs(currentSqrtPrice, upperSqrtPrice, longsOnMarketBN);
    const actualLongsBN = getLongsForLiquidity(lowerSqrtPrice, upperSqrtPrice, lowerSqrtPrice, liquidityBN);
    return actualLongsBN / 10 ** 18;
  }
  // if user is adding liquidity in range below current price, [69%, 78%] @80% he only needs cash
  // so how much can he actually buy options? Right now 1000
  return 1000;
};

//-------- START OF OPTIONS EVALUATION FUNCTIONS --------//
// evaluates multiple options of the same expiration and volatility, but different strikes
// export function evaluateOptionUsingLookup() {
//   const underPrice = 100;

//   const volatilityRange = [0.01, 10]; // 1% to 1000%
//   const expirationRange = [0.0001, 5]; // <1h to 5 years
//   const rateRange = [0, 0.2];
//   let { lookup, strikes, devs } = generateLookupTable(expirationRange, volatilityRange, rateRange, underPrice);
//   console.log(lookup);

//   let runs = 1;

//   let maxError = 0;
//   let avgError = 0;
//   let nonZeroCount = 0;
//   //let maxErrorOptions;
//   let maxErrorExpirationsYears;
//   let maxErrorVolatility;
//   let maxErrorBSPrice;
//   let maxErrorBinomialPrice;
//   let maxErrorRate;
//   //const maxDeviations = 2;
//   let strikePrice = 120;

//   const expirationsYears = [1.5 / 12 /*, 2 / 12, 3 / 12, 1 / 2, 0.8*/];
//   const volatilities = [0.2 /*, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9*/];
//   const rates = [0]; //[0.001, 0.01, 0.02, 0.03, 0.0487, 0.06, 0.08, 0.1, 0.2];

//   for (let expirationYears of expirationsYears) {
//     console.log("Working on days:", expirationYears * 365);
//     for (let volatility of volatilities) {
//       console.log("Working on volatility:", volatility * 100);
//       for (let rate of rates) {
//         let stdDev = volatility * Math.sqrt(expirationYears);
//         // const minStrike = underPrice / (1 + maxDeviations * stdDev);
//         // const maxStrike = underPrice * (1 + maxDeviations * stdDev);
//         // console.log("Max strikes:", minStrike.toFixed(0), maxStrike.toFixed(0));
//         // const range1 = getBinomialRange(underPrice, expirationYears, volatility, steps1);
//         // console.log("Binomial range:", range1.lower.toFixed(0), range1.upper.toFixed(0));
//         for (let i = 0; i < runs; i++) {
//           const evalPrice = getValueFromLookupTable(lookup, strikes, devs, strikePrice, stdDev);
//           const bsPrice = blackScholesCall(100, strikePrice, expirationYears, 0, volatility);
//           console.log("Total premium (fast):", evalPrice);
//           console.log("Total premium (slow):", bsPrice);
//           if (bsPrice !== 0) {
//             nonZeroCount++;
//             const absError = (Math.abs(evalPrice - bsPrice) / bsPrice) * 100;
//             console.log("Error:", absError, "%");
//             if (absError > maxError) {
//               //maxErrorOptions = [...options];
//               maxErrorExpirationsYears = expirationYears;
//               maxErrorVolatility = volatility;
//               maxErrorBSPrice = bsPrice;
//               maxErrorBinomialPrice = evalPrice;
//               maxErrorRate = rate;
//             }
//             maxError = Math.max(maxError, absError);
//             avgError += absError;
//           }
//         }
//       }
//     }
//   }

//   console.log("avgError:", avgError / nonZeroCount, "%");
//   console.log("maxError:", maxError, "%");
//   console.log("maxError expiration:", maxErrorExpirationsYears * 365);
//   console.log("maxError volatility:", maxErrorVolatility);
//   console.log("maxError bsPrice:", maxErrorBSPrice);
//   console.log("maxError binomialPrice:", maxErrorBinomialPrice);
//   console.log("maxError rate", maxErrorRate);
// }

// function generateLookupTable(expirationsYears, volatilities, rates, underPrice) {
//   const strikeCount = 2000;
//   const devSegmentCount = 18 + 1;
//   const devSegmentPoints = 10;
//   // const devCount = devSegmentCount * devSegmentPoints;

//   const maxVol = volatilities[volatilities.length - 1];
//   const maxExpiration = expirationsYears[expirationsYears.length - 1];
//   const maxDev = maxVol * Math.sqrt(maxExpiration);
//   console.log("maxDev", maxDev);
//   const maxStrike = underPrice * (3 * (1 + maxDev));
//   const minStrike = underPrice / (3 * (1 + maxDev));
//   console.log("minStrike", minStrike);
//   console.log("maxStrike", maxStrike);

//   const strikes = [];
//   const strikeStep = (maxStrike - minStrike) / (strikeCount - 1);
//   for (let i = 0; i < strikeCount; i++) {
//     strikes.push(minStrike + strikeStep * i);
//   }
//   console.log("strikes", strikes);

//   const minVol = volatilities[0];
//   const minExpiration = expirationsYears[0];
//   const minDev = minVol * Math.sqrt(minExpiration);
//   console.log("minDev", minDev);
//   const devs = [];
//   for (let i = 0; i < devSegmentCount; i++) {
//     let currentDev = minDev * 2 ** i;
//     let nextDev = minDev * 2 ** (i + 1);
//     devs.push(currentDev);

//     //console.log(nextDev)
//     //console.log(((nextDev - minDev) * 3) / devSegmentPoints)
//     for (let j = 1; j < devSegmentPoints; j++) {
//       //console.log(minDev * 2 ** i + ((nextDev - minDev) * j) / devSegmentPoints)
//       devs.push(currentDev + ((nextDev - currentDev) * j) / devSegmentPoints);
//     }
//   }

//   // const devStep = (maxDev - minDev) / (devCount - 1);
//   // for (let i = 0; i < devCount; i++) {
//   //   devs.push(minDev + devStep * i);
//   // }
//   console.log("devs", devs);

//   const lookup = [];
//   // calculate BlackScholes prices for all combinations of strikes and sigmas, assuming rate is 0
//   for (let i = 0; i < strikes.length; i++) {
//     const row = [];
//     for (let j = 0; j < devs.length; j++) {
//       const strike = strikes[i];
//       const deviation = devs[j];
//       // split deviation to time and volatility
//       const bsPrice = blackScholesCall(underPrice, strike, 1, 0, deviation);
//       //console.log(underPrice, strike, 1, 0, deviation);
//       //console.log("bsPrice", bsPrice);
//       row.push(bsPrice);
//     }
//     lookup.push(row);
//   }

//   return { lookup, strikes, devs };
// }

// function getValueFromLookupTable(lookup, strikes, devs, strike, deviation) {
//   let strikeLowerIndex = findIndexWithHighestClosestValue(strikes, strike);
//   let devLowerIndex = findIndexWithHighestClosestValue(devs, deviation);
//   //console.log("strikeLowerIndex", strikeLowerIndex);
//   //console.log("devLowerIndex", devLowerIndex);

//   let strikeUpperIndex = strikeLowerIndex + 1;
//   let devUpperIndex = devLowerIndex + 1;

//   let upperLeft = lookup[strikeLowerIndex][devLowerIndex];
//   let upperRight = lookup[strikeLowerIndex][devUpperIndex];
//   let lowerLeft = lookup[strikeUpperIndex][devLowerIndex];
//   let lowerRight = lookup[strikeUpperIndex][devUpperIndex];

//   console.log("upperLeft", upperLeft);
//   console.log("upperRight", upperRight);
//   console.log("lowerLeft", lowerLeft);
//   console.log("lowerRight", lowerRight);

//   let ratioStrike = (strike - strikes[strikeLowerIndex]) / (strikes[strikeUpperIndex] - strikes[strikeLowerIndex]);
//   let ratioDev = (deviation - devs[devLowerIndex]) / (devs[devUpperIndex] - devs[devLowerIndex]);
//   console.log("ratioStrike", ratioStrike);
//   console.log("ratioDev", ratioDev);

//   let upperCenter = upperLeft + (upperRight - upperLeft) * ratioDev;
//   let lowerCenter = lowerLeft + (lowerRight - lowerLeft) * ratioDev;

//   console.log("upperCenter", upperCenter);
//   console.log("lowerCenter", lowerCenter);

//   let center = upperCenter - (upperCenter - lowerCenter) * ratioStrike;

//   console.log("center", center);

//   // 2nd method
//   const leftCenter = upperLeft - (upperLeft - lowerLeft) * ratioStrike;
//   const rightCenter = upperRight - (upperRight - lowerRight) * ratioStrike;
//   console.log("leftCenter", leftCenter);
//   console.log("rightCenter", rightCenter);

//   let center2 = leftCenter + (rightCenter - leftCenter) * ratioDev;
//   console.log("center2", center2);

//   return center;
// }
// function findIndexWithHighestClosestValue(array, a) {
//   if (!Array.isArray(array) || array.length === 0) {
//     throw new Error("Input array must have at least one element.");
//   }

//   let closestIndex = 0;
//   //let highestValue = array[0];

//   for (let i = 1; i < array.length; i++) {
//     if (array[i] > a) {
//       return closestIndex;
//     }
//     closestIndex = i;
//   }

//   return closestIndex;
// }

// evaluates multiple options of the same expiration and volatility, but different strikes
// export function evaluateMultipleOptions() {
//   const underPrice = 30000;
//   const expirationsYears = [1 / 365, 2 / 365, 3 / 365, 5 / 365, 7 / 365, 14 / 365, 1 / 12, 2 / 12, 3 / 12, 1 / 2, 1, 2, 3];
//   const volatilities = [0.05, 0.075, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
//   const rates = [0.001, 0.01, 0.02, 0.03, 0.0487, 0.06, 0.08, 0.1, 0.2];
//   const steps1 = 29;
//   let runs = 2000;

//   let maxError = 0;
//   let avgError = 0;
//   let nonZeroCount = 0;
//   let maxErrorOptions;
//   let maxErrorExpirationsYears;
//   let maxErrorVolatility;
//   let maxErrorBSPrice;
//   let maxErrorBinomialPrice;
//   let maxErrorRate;
//   const maxDeviations = 2;

//   for (let expirationYears of expirationsYears) {
//     console.log("Working on days:", expirationYears * 365);
//     for (let volatility of volatilities) {
//       for (let rate of rates) {
//         let stdDev = volatility * Math.sqrt(expirationYears);
//         const minStrike = underPrice / (1 + maxDeviations * stdDev);
//         const maxStrike = underPrice * (1 + maxDeviations * stdDev);
//         // console.log("Max strikes:", minStrike.toFixed(0), maxStrike.toFixed(0));
//         // const range1 = getBinomialRange(underPrice, expirationYears, volatility, steps1);
//         // console.log("Binomial range:", range1.lower.toFixed(0), range1.upper.toFixed(0));
//         for (let i = 0; i < runs; i++) {
//           const options = generateOptions(minStrike, maxStrike);
//           const binomialPrice1 = binomialMultipleOptionPricing(underPrice, expirationYears, rate, volatility, options, steps1);
//           //const binomialPrice2 = binomialMultipleOptionPricing(underPrice, expirationYears, rate, volatility, options, steps2);
//           // 1 day to 3 years
//           // 49 steps, 1.966 => avg error: 1.60%, max error: 3.55%
//           // 49 steps, 1.962 => avg error: 1.79%, max error: 3.36%
//           // 49 steps, 1.960 => avg error: 1.89%, max error: 3.16%
//           // 49 steps, 1.958 => avg error: 1.99%, max error: 3.06% - best
//           // 49 steps, 1.956 => avg error: 2.09%, max error: 3.06%
//           // 47 steps, 1.958 => avg error: 1.98%, max error: 2.90%
//           // 47 steps, 1.956 => avg error: 2.08%, max error: 2.80% - best
//           // 47 steps, 1.954 => avg error: 2.18%, max error: 2.81%
//           // 29 steps, 1.956 => avg error: 2.12%, max error: 5.34%
//           // 29 steps, 1.940 => avg error: 2.87%, max error: 4.56%
//           // 29 steps, 1.930 => avg error: 3.36%, max error: 4.38% - best
//           // 29 steps, 1.928 => avg error: 3.46%, max error: 4.49%
//           // 29 steps, 1.925 => avg error: 3.61%, max error: 4.65%
//           // 27 steps, 2.000 => avg error: 0.57%, max error: 8.44%
//           // 27 steps, 1.990 => avg error: 0.82%, max error: 7.98%
//           // 27 steps, 1.982 => avg error: 1.08%, max error: 7.61%
//           // 27 steps, 1.980 => avg error: 1.15%, max error: 7.51%
//           // 27 steps, 1.978 => avg error: 1.22%, max error: 7.41% - best
//           // 27 steps, 1.970 => avg error: 1.53%, max error: 7.84%
//           // 27 steps, 1.960 => avg error: 1.96%, max error: 8.33%
//           // 27 steps, 1.940 => avg error: 2.88%, max error: 9.47%
//           // 25 steps, 1.930 => avg error: 3.38%, max error: 38.93%
//           // 25 steps, 1.900 => avg error: 4.92%, max error: 38.03%
//           // 23 steps, 1.900 => avg error: 4.91%, max error: 178.03%
//           // 23 steps, 2.000 => avg error: 4.91%, max error: 164.03%
//           // 23 steps, 2.100 => avg error: 5.13%, max error: 149.03%

//           // not using 2 binomials, but 1
//           // 29 steps, 1.800 => avg error:     %, max error: 12.95%
//           // 29 steps, 1.900 => avg error:     %, max error: 7.05%
//           // 29 steps, 1.910 => avg error:     %, max error: 6.44%
//           // 29 steps, 1.920 => avg error: 3.92%, max error: 5.89%
//           // 29 steps, 1.925 => avg error: 3.72%, max error: 5.61%
//           // 29 steps, 1.928 => avg error: 3.57%, max error: 5.45%
//           // 29 steps, 1.929 => avg error: 3.52%, max error: 5.39%
//           // 29 steps, 1.930 => avg error: 3.47%, max error: 5.34%
//           // 29 steps, 1.932 => avg error: 3.38%, max error: 5.23% - best
//           // 29 steps, 1.934 => avg error: 3.28%, max error: 5.33%
//           // 29 steps, 2.000 => avg error: 0.82%, max error: 8.46%
//           // 29 steps, 2.100 => avg error:     %, max error: 12.82%
//           const binomialPrice = (binomialPrice1 + binomialPrice1) / 1.933;
//           const bsPrice = blackScholesMultipleOptionPricing(underPrice, expirationYears, rate, volatility, options);
//           //console.log("Total premium (fast):", binomialPrice);
//           //console.log("Total premium (slow):", bsPrice);
//           if (bsPrice !== 0) {
//             nonZeroCount++;
//             const absError = (Math.abs(binomialPrice - bsPrice) / bsPrice) * 100;
//             if (absError > maxError) {
//               maxErrorOptions = [...options];
//               maxErrorExpirationsYears = expirationYears;
//               maxErrorVolatility = volatility;
//               maxErrorBSPrice = bsPrice;
//               maxErrorBinomialPrice = binomialPrice;
//               maxErrorRate = rate;
//             }
//             maxError = Math.max(maxError, absError);
//             avgError += absError;
//           }
//         }
//       }
//     }
//   }

//   console.log("avgError:", avgError / nonZeroCount, "%");
//   console.log("maxError:", maxError, "%");
//   console.log(maxErrorOptions);
//   console.log("maxError expiration:", maxErrorExpirationsYears * 365);
//   console.log("maxError volatility:", maxErrorVolatility);
//   console.log("maxError bsPrice:", maxErrorBSPrice);
//   console.log("maxError binomialPrice:", maxErrorBinomialPrice);
//   console.log("maxError rate", maxErrorRate);
// }

// function generateOptions(minStrike, maxStrike) {
//   const options = [];
//   for (let i = 0; i < 1; i++) {
//     const strike = Math.floor(minStrike + Math.random() * (maxStrike - minStrike)); // between 0 and 200
//     const quantity = Math.floor(Math.random() * 100); // between 0 and 100
//     const option = {
//       quantity: quantity,
//       strikePrice: strike,
//       isCall: true
//     };

//     options.push(option);
//   }

//   return options;

//   // let option1 = {
//   //   quantity: 2,
//   //   strikePrice: 100,
//   //   isCall: true
//   // };
//   // let option2 = {
//   //   quantity: -1,
//   //   strikePrice: 110,
//   //   isCall: true
//   // };

//   // return [option1, option2];
// }

// export function blackScholesMultipleOptionPricing(S, T, r, sigma, options) {
//   let totalPremium = 0;
//   for (let option of options) {
//     const premium = blackScholesCall(S, option.strikePrice, T, r, sigma) * option.quantity;
//     totalPremium += premium;
//   }

//   return totalPremium;
// }

// function getBinomialRange(S, T, sigma, steps) {
//   const dt = T / steps;
//   const u = Math.exp(sigma * Math.sqrt(dt));
//   const d = 1 / u;

//   // Initialize arrays to store stock prices and option values
//   let stockPrices = new Array(steps + 1);

//   // Calculate stock prices at each step
//   for (let i = 0; i <= steps; i++) {
//     stockPrices[i] = S * Math.pow(u, steps - i) * Math.pow(d, i);
//   }

//   return { upper: stockPrices[0], lower: stockPrices[stockPrices.length - 1] };
// }

// function binomialMultipleOptionPricing(S, T, r, sigma, options, steps) {
//   const dt = T / steps;
//   const discountFactor = Math.exp(-r * dt);
//   const u = Math.exp(sigma * Math.sqrt(dt));
//   const d = 1 / u;
//   const p = (Math.exp(r * dt) - d) / (u - d);
//   // console.log("discountFactor", discountFactor);
//   //console.log("u", u);
//   //console.log("d", d);
//   //console.log("p", p);

//   // Initialize arrays to store stock prices and option values
//   let stockPrices = new Array(steps + 1);
//   let optionValues = new Array(steps + 1);

//   // Calculate stock prices at each step
//   for (let i = 0; i <= steps; i++) {
//     stockPrices[i] = S * Math.pow(u, steps - i) * Math.pow(d, i);
//     if (optionValues[i] === undefined) {
//       optionValues[i] = 0;
//     }
//     for (let option of options) {
//       const X = option.strikePrice;
//       const premiumPaid = Math.max(0, option.isCall ? stockPrices[i] - X : X - stockPrices[i]) * option.quantity; // if negative, then received
//       //console.log(premiumPaid)
//       optionValues[i] += premiumPaid;
//     }
//     // const premiumPaid = Math.max(0, options[0].isCall ? stockPrices[i] - X : X - stockPrices[i]) * options[0].quantity; // if negative, then received
//     // optionValues[i] += premiumPaid;
//   }
//   //console.log(stockPrices)
//   //console.log(optionValues)

//   // Calculate option values at each time step, working backward through the tree
//   for (let step = steps - 1; step >= 0; step--) {
//     for (let i = 0; i <= step; i++) {
//       optionValues[i] = (p * optionValues[i] + (1 - p) * optionValues[i + 1]) * discountFactor;
//     }
//   }
//   return optionValues[0];
// }

// export function volatilityToPrice() {
//   const S = 100; // Current stock price
//   const X = 100; // Strike price
//   const T = 0.25; // Time to expiration in years
//   const r = 0.05; // Risk-free interest rate (3%)

//   const dataPoints = 21;
//   const stepSize = 1 / (dataPoints - 1);

//   let points = "Volatility (x)\tOption Price (y)\n";
//   for (let i = 0; i < dataPoints; i++) {
//     const volatility = i * stepSize;
//     const optionPrice = blackScholesCall(S, X, T, r, volatility);
//     points += `${volatility}\t${optionPrice}\n`;
//   }

//   console.log(points);
// }

// export function evaluate() {
//   const underPrice = 100;
//   const expirationYears = 0.0833333; //0.16027397260274;
//   const volatility = 0.434;
//   const rate = 0.03; //0.0487

//   //const { lowerPrice, upperPrice } = getLowerUpperPrice(underPrice, expirationYears, volatility);
//   const { lowerPrice, upperPrice } = getEqualProbabilityPrices(underPrice, expirationYears, volatility, rate);
//   binomialEvaluation(lowerPrice, underPrice, upperPrice, expirationYears, rate);

//   // Example usage:
//   const S = 100; // Current stock price
//   const X = 100; // Strike price
//   const T = 1; // Time to expiration in months
//   const r = 0.03; // Risk-free interest rate (3%)
//   const sigma = 0.434; // Volatility (43.4%)
//   const steps = 10; // Number of steps in the binomial tree
//   const optionType = "call"; // Option type: 'call' or 'put'

//   const optionPrice = binomialOptionPricing(S, X, T, r, sigma, steps, optionType);
//   console.log("Option Price:", optionPrice);
// }

// export function getLowerUpperPrice(underPrice, expirationYears, volatility) {
//   //volatility is too BigNumber, so I need to use 50% probability, which is how much sigma? around 0.7 sigma
//   // this is ok for 0.798 volatility multiplier
//   // the question remains how to get multiplier
//   // 0.34641012
//   let volatilityNormalized = volatility * 0.79818; // there must be one value from distribution where everything works
//   console.log("volatilityNormalized", volatilityNormalized);

//   // Future price = under * (1 + risk * years) = 1600 * (1 + 0.0487 * 0,16027) = 1601.25
//   const futurePrice = underPrice; //underPrice * (1 + rate * expirationYears);
//   console.log("futurePrice:", futurePrice.toFixed(2));

//   // Upper price = 1601.25 * (1 + vol * sqrt(years)) = 1600 * (1 + 0.4 * sqrt(0.16027)) = 1601.25* (1 + 0.4 * 0.400337) =1857.66
//   // Lower price = 1601.25 / (1 + vol * sqrt(years)) = 1600 / 1.1601348 = 1380.23
//   const upperPrice = futurePrice * (1 + volatilityNormalized * Math.sqrt(expirationYears));
//   const lowerPrice = futurePrice * (1 - volatilityNormalized * Math.sqrt(expirationYears));
//   console.log("lowerPrice:", lowerPrice.toFixed(4), "upperPrice:", upperPrice.toFixed(4));

//   return { lowerPrice, upperPrice };
// }

// export function getEqualProbabilityPrices(underPrice, expirationYears, volatility, rate) {
//   //volatility is too BigNumber, so I need to use 50% probability, which is how much sigma? around 0.7 sigma
//   // this is ok for 0.798 volatility multiplier
//   // the question remains how to get multiplier
//   let volatilityNormalized = volatility * 0.79818; // there must be one value from distribution where everything works
//   console.log("volatilityNormalized", volatilityNormalized);

//   // Future price = under * (1 + risk * years) = 1600 * (1 + 0.0487 * 0,16027) = 1601.25
//   const futurePrice = underPrice * (1 + rate * expirationYears);
//   console.log("futurePrice:", futurePrice.toFixed(2));

//   // Upper price = 1601.25 * (1 + vol * sqrt(years)) = 1600 * (1 + 0.4 * sqrt(0.16027)) = 1601.25* (1 + 0.4 * 0.400337) =1857.66
//   // Lower price = 1601.25 / (1 + vol * sqrt(years)) = 1600 / 1.1601348 = 1380.23
//   const upperPrice = futurePrice * (1 + volatilityNormalized * Math.sqrt(expirationYears));
//   const lowerPrice = futurePrice / (1 + volatilityNormalized * Math.sqrt(expirationYears));
//   console.log("lowerPrice:", lowerPrice.toFixed(4), "upperPrice:", upperPrice.toFixed(4));

//   return { lowerPrice, upperPrice };
// }

// export function binomialEvaluation(lowerPrice, underPrice, upperPrice, expirationYears, rate) {
//   // Formula to find stocks bought
//   // x = 257.66 / (1857.66 - 1380.23) = 0.5396812098
//   const profitUpside = upperPrice - underPrice;
//   console.log("profitUpside:", profitUpside.toFixed(2));
//   const underSize = profitUpside / (upperPrice - lowerPrice);
//   console.log("Stocks bought", underSize);

//   // So,
//   // Portfolio upper = 0.5396812098 * 1857.66 - 257.86 = 744.68
//   // Portfolio lower = 0.5396812098 * 1380.23 = 689.58 = 744.88
//   const portfolioUpper = underSize * upperPrice - profitUpside;
//   const portfolioLower = underSize * lowerPrice;
//   console.log("portfolioUpper", portfolioUpper);
//   console.log("portfolioLower", portfolioLower);

//   // Invested in stocks = 0.5396812098 * 1600 = 863.49
//   const invested = underSize * underPrice;
//   console.log("invested", invested);

//   // 863.49- 744.78 * e ^ (-rate * T) = 863.49 - 738.99 = 124.5
//   const callATMPrice = invested - portfolioUpper * Math.exp(-rate * expirationYears);
//   console.log("callATMPrice", callATMPrice);

//   return callATMPrice;
// }

// export function getOTMCallPrice(upperPrice, lowerPrice, callATMPrice, underPrice, strikePrice, expirationYears, volatility, rate) {
//   // now lets go further to see if we can get put valuation

//   // we know the stock price, 100 call price, now we need 120 put price

//   console.log("PART 2 ----------------");

//   // const underSize2 = 0.25; //0.125;
//   // const putSize = 0.25;
//   // const putStrike = 120;

//   // const optionsValueLower = 0 - putSize * (putStrike - lowerPrice);
//   // const optionsValueUpper = underPrice - upperPrice - putSize * (putStrike - upperPrice);

//   // console.log("optionsValueLower", optionsValueLower);
//   // console.log("optionsValueUpper", optionsValueUpper);

//   // const portfolioUpper2 = underSize2 * upperPrice + optionsValueUpper;
//   // const portfolioLower2 = underSize2 * lowerPrice + optionsValueLower;
//   // console.log("portfolioUpper2", portfolioUpper2);
//   // console.log("portfolioLower2", portfolioLower2);

//   // const totalInvested = underPrice * underSize2; // $25
//   // console.log("totalInvested", totalInvested);

//   // const investedInOptions = totalInvested - portfolioUpper2 * Math.exp(-rate * expirationYears);
//   // console.log("investedInOptions", investedInOptions)

//   // const putOptionSpent = investedInOptions - optionValue;
//   // console.log("putOptionSpent", putOptionSpent);

//   // const putOptionValue = putOptionSpent / putSize;
//   // console.log("putOptionPrice", putOptionValue);

//   // console.log("I get put price with volatility 0%")

//   // // try this
//   // console.log(totalInvested * Math.exp(-rate * expirationYears));

//   const underSize2 = 3; // buy 3 underlyings
//   const callSize = 4; // sell 4 ATM calls
//   const putSize = 1; // buy 1 OTM puts
//   const callStrike = 100;
//   const putStrike = 120;

//   // call is wortless, and put is (120 - 90)
//   const optionsValueLower = 0 + putSize * (putStrike - lowerPrice);
//   const optionsValueUpper = -callSize * (upperPrice - callStrike) + putSize * (putStrike - upperPrice);

//   console.log("optionsValueLower", optionsValueLower);
//   console.log("optionsValueUpper", optionsValueUpper);

//   console.log("underValueLower:", underSize2 * lowerPrice);
//   console.log("underValueUpper:", underSize2 * upperPrice);
//   const portfolioLower2 = underSize2 * lowerPrice + optionsValueLower;
//   const portfolioUpper2 = underSize2 * upperPrice + optionsValueUpper;

//   console.log("portfolioLower2", portfolioLower2);
//   console.log("portfolioUpper2", portfolioUpper2);

//   const totalInvested = underPrice * underSize2; // $25
//   console.log("totalInvested", totalInvested);

//   const investedInOptions = totalInvested - portfolioUpper2 * Math.exp(-rate * expirationYears);
//   console.log("investedInOptions", investedInOptions);

//   const putOptionSpent = investedInOptions - callSize * callATMPrice;
//   console.log("putOptionSpent", putOptionSpent);

//   const putOptionValue = putOptionSpent / putSize;
//   console.log("putOptionPrice", putOptionValue);

//   console.log("I get put price with volatility 0%");

//   // try this
//   console.log(totalInvested * Math.exp(-rate * expirationYears));
// }
// function binomialOptionPricing(S, X, T, r, sigma, steps, optionType) {
//   steps = 10; // 5 and 6 steps give 5.12, and 5.11 is correct answer
//   // Convert time to years
//   T = T / 12;
//   console.log("==================");
//   // Calculate parameters
//   const dt = T / steps;
//   const discountFactor = Math.exp(-r * dt);
//   const u = Math.exp(sigma * Math.sqrt(dt));
//   const d = 1 / u;
//   const p = (Math.exp(r * dt) - d) / (u - d);
//   console.log("discountFactor", discountFactor);
//   console.log("u", u);
//   console.log("d", d);
//   console.log("p", p);

//   // Initialize arrays to store stock prices and option values
//   let stockPrices = new Array(steps + 1);
//   let optionValues = new Array(steps + 1);

//   // Calculate stock prices at each step
//   for (let i = 0; i <= steps; i++) {
//     stockPrices[i] = S * Math.pow(u, steps - i) * Math.pow(d, i);
//     optionValues[i] = Math.max(0, optionType === "call" ? stockPrices[i] - X : X - stockPrices[i]);
//   }
//   console.log(stockPrices);
//   console.log(optionValues);

//   // Calculate option values at each time step, working backward through the tree
//   for (let step = steps - 1; step >= 0; step--) {
//     for (let i = 0; i <= step; i++) {
//       optionValues[i] = (p * optionValues[i] + (1 - p) * optionValues[i + 1]) * discountFactor;
//     }
//     console.log("step", step);
//     console.log(optionValues);
//   }

//   console.log(sigma * Math.sqrt(T));
//   console.log(sigma * Math.sqrt(2 * T));
//   console.log(sigma * Math.sqrt(3 * T));
//   console.log(Math.exp(sigma * Math.sqrt(T))); // 1.1334714559375116, upper price: 13.3471 = 1 stddev, option: 4.9935
//   console.log(Math.exp(sigma * Math.sqrt(2 * T))); // 1.1938456772243993, upper price: 19.3845 = 1 stddev, option: 7.0573
//   console.log(Math.exp(sigma * Math.sqrt(3 * T))); // 1.2423441021377641, upper price: 24.2344 = 1 stddev, option: 8.6377
//   // ratio(1, 2) = 1.452311380834644
//   // ratio(1, 3) = 1.815705284293966

//   // price2 / price1 = 1.413297286472414
//   // price3 / price1 = 1.729788725342946

//   // The final option value at time t=0 is the option price
//   return optionValues[0];

//   // approximation
//   // I know that for 30.4 days, 43.4 vol, and rate 0% option is valued at 4.9935
//   // for 60.8 days, $7.0573, thats 1.413297286472414 times more
//   // 0.177179758061317
//   // Math.exp(0.434 * Math.sqrt())1.133471455937512
// }

// function blackScholesCall(S, X, T, r, sigma) {
//   const d1 = (Math.log(S / X) + (r + 0.5 * sigma * sigma) * T) / (sigma * Math.sqrt(T));
//   const d2 = d1 - sigma * Math.sqrt(T);
//   const callPrice = S * normCDF(d1) - X * Math.exp(-r * T) * normCDF(d2);
//   return callPrice;
// }
// function normCDF(x) {
//   const a1 = 0.31938153;
//   const a2 = -0.356563782;
//   const a3 = 1.781477937;
//   const a4 = -1.821255978;
//   const a5 = 1.330274429;
//   const pi = Math.PI;
//   const k = 1.0 / (1.0 + 0.2316419 * Math.abs(x));
//   const cdf =
//     1.0 - (1.0 / Math.sqrt(2 * pi)) * Math.exp((-x * x) / 2.0) * (a1 * k + a2 * k * k + a3 * Math.pow(k, 3) + a4 * Math.pow(k, 4) + a5 * Math.pow(k, 5));
//   return x < 0 ? 1.0 - cdf : cdf;
// }
