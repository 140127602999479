import { get } from "lodash";

// user trades
export const tradesLoaded = state => get(state, "trades.loaded");

export const trades = state => get(state, "trades.data", []);

export const lpTradesLoaded = state => get(state, "lpTrades.loaded");

export const lpTrades = state => get(state, "lpTrades.data", []);

export const liquidationTradesLoaded = state => get(state, "liquidationTrades.loaded");

export const liquidationTrades = state => get(state, "liquidationTrades.data", []);
