/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { selectedTokenPairSelector, avgUnderlyingTokenPriceSelector } from "../../store/selectors/tokensSelectors";
import { calculateMinMaxDomainXVol, getHighestDaysToExpiry, getHighestDaysToExpiryRounded, getPeriodsInDay } from "../../utils/chartsUtils";
import MyTooltip from "./MyTooltip.jsx";
import GreeksTable from "./GreeksTable.jsx";

class SlidersPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idPrefix: "sliders-panel-"
    };

    this.isInitialized = this.isInitialized.bind(this);
    this.onDaysToExpirySliderChange = this.onDaysToExpirySliderChange.bind(this);
    this.onDaysToExpiryButtonClick = this.onDaysToExpiryButtonClick.bind(this);
    this.onVolatilitySliderChange = this.onVolatilitySliderChange.bind(this);
    this.onVolatilityButtonClick = this.onVolatilityButtonClick.bind(this);
  }

  isVolatilityUpdated = prevProps => {
    if (this.isInitialized() && this.props.selectedPositions.length === prevProps.selectedPositions.length) {
      for (let i = 0; i < this.props.selectedPositions.length; i++) {
        const market = this.props.markets.find(market => {
          return market.marketId === this.props.selectedPositions[i].marketId;
        });
        const prevMarket = prevProps.markets.find(market => {
          return market.marketId === prevProps.selectedPositions[i].marketId;
        });

        if (market.longPriceInVol !== prevMarket.longPriceInVol) {
          return true;
        }
      }
    }

    return false;
  };

  isInitialized = () => {
    return this.props.selectedUnderPrice !== null && this.props.selectedDaysToExpiry !== null && this.props.selectedVolatility !== null;
  };

  onDaysToExpirySliderChange = e => {
    const timestampNow = new Date();
    const periodsInDay = getPeriodsInDay(this.props.selectedPositions, this.props.markets);
    const dayPeriodsToExpiration = Math.round(getHighestDaysToExpiry(this.props.selectedPositions, this.props.markets, timestampNow) * periodsInDay);
    const selectedPeriodsInDayToExpiry = dayPeriodsToExpiration - e.target.value;
    const underPrice = this.props.selectedUnderPrice;

    const selectedDays = selectedPeriodsInDayToExpiry / periodsInDay;
    this.props.calculateChartDataCallback(underPrice, selectedDays, this.props.selectedVolatility, null, this.props.selectedPositions);
  };

  onDaysToExpiryButtonClick = e => {
    e.stopPropagation();

    if (this.props.disabled) return;

    const isIncrease = e.target.classList.value === "increaseButton";
    const periodsInDay = getPeriodsInDay(this.props.selectedPositions, this.props.markets);
    const targetValue = this.props.selectedDaysToExpiry * periodsInDay + (isIncrease ? 1 : -1);
    const daysToExpirySlider = document.getElementById("daysToExpirySliderPosition");
    const timestampNow = new Date();
    const dayPeriodsToExpiration = Math.round(getHighestDaysToExpiry(this.props.selectedPositions, this.props.markets, timestampNow) * periodsInDay);

    if (dayPeriodsToExpiration - targetValue <= daysToExpirySlider.max && dayPeriodsToExpiration - targetValue >= daysToExpirySlider.min) {
      const selectedDays = targetValue / periodsInDay;
      this.props.calculateChartDataCallback(this.props.selectedUnderPrice, selectedDays, this.props.selectedVolatility, null, this.props.selectedPositions);
    }
  };

  onVolatilitySliderChange = e => {
    const selectedUnderPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    this.props.calculateChartDataCallback(selectedUnderPrice, this.props.selectedDaysToExpiry, e.target.value, null, this.props.selectedPositions);
  };

  onVolatilityButtonClick = e => {
    e.stopPropagation();

    if (this.props.disabled) return;

    const isIncrease = e.target.classList.value === "increaseButton";
    const targetValue = Number(this.props.selectedVolatility) + (isIncrease ? 1 : -1);
    const impliedVolatilitySlider = document.getElementById("impliedVolatilitySliderPosition");
    if (targetValue <= impliedVolatilitySlider.max && targetValue >= impliedVolatilitySlider.min) {
      const selectedUnderPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
      this.props.calculateChartDataCallback(selectedUnderPrice, this.props.selectedDaysToExpiry, targetValue, null, this.props.selectedPositions);
    }
  };

  isDefaultDaysToExpiry = () => {
    const market = this.props.markets.find(market => {
      return market.marketId === this.props.selectedPositions[0].marketId;
    });

    const daysToExpiry = getHighestDaysToExpiryRounded(this.props.selectedPositions, this.props.markets);

    return this.props.selectedDaysToExpiry === daysToExpiry;
  };

  // will work with this.props if nothing is passed
  isDefaultVolatility = (props = this.props) => {
    const market = props.markets.find(market => {
      return market.marketId === props.selectedPositions[0].marketId;
    });

    const isSingle = props.selectedPositions.length === 1;

    if (isSingle) {
      const checkDefault = props.selectedVolatility === Math.round(parseFloat(market.longPriceInVol));
      return checkDefault;
    } else {
      const checkDefault = props.selectedVolatility === 0;
      return checkDefault;
    }
  };

  onResetDaysToExpiry = () => {
    const daysToExpiry = getHighestDaysToExpiryRounded(this.props.selectedPositions, this.props.markets);
    this.props.calculateChartDataCallback(this.props.selectedUnderPrice, daysToExpiry, this.props.selectedVolatility, null, this.props.selectedPositions);
  };

  onResetVolatility = () => {
    const market = this.props.markets.find(market => {
      return market.marketId === this.props.selectedPositions[0].marketId;
    });
    let volatility = Math.round(parseFloat(market.longPriceInVol));

    const isSingle = this.props.selectedPositions.length === 1;
    const isSingleLP = this.props.selectedPositions.length === 1 && this.props.selectedPositions[0].type === "lp";

    if (isSingleLP) {
      this.props.calculateChartDataCallback(this.props.selectedUnderPrice, this.props.selectedDaysToExpiry, volatility, null, this.props.selectedPositions);
    } else {
      // volatility is relative (starts from 0) if there are multiple positions
      volatility = isSingle ? volatility : 0;
      this.props.calculateChartDataCallback(this.props.selectedUnderPrice, this.props.selectedDaysToExpiry, volatility, null, this.props.selectedPositions);
    }
  };

  printTimeToExpiration = () => {
    const periodsInDay = getPeriodsInDay(this.props.selectedPositions, this.props.markets);
    const hoursInPeriod = 24 / periodsInDay;
    const totalPeriods = this.props.selectedDaysToExpiry * periodsInDay;
    const days = Math.floor(totalPeriods / periodsInDay);
    const hours = (totalPeriods - days * periodsInDay) * hoursInPeriod;

    return { days, hours };
  };

  render() {
    if (this.props.selectedPositions.length === 0) {
      return null;
    }

    const { days, hours } = this.printTimeToExpiration();
    const timeToExpirationStr = days > 0 ? `${days}d ${hours}h` : `${hours}h`;

    const timestampNow = new Date();
    const periodsInDay = getPeriodsInDay(this.props.selectedPositions, this.props.markets);
    const periodsInDayToExpiry = Math.round(getHighestDaysToExpiry(this.props.selectedPositions, this.props.markets, timestampNow) * periodsInDay);
    const isSingle = this.props.selectedPositions.length === 1;

    // get volatility slider range
    let domainVol = calculateMinMaxDomainXVol(this.props.selectedPositions, this.props.markets);
    const volatilitySliderMin = isSingle ? domainVol.minDomainX : Math.round(domainVol.minDomainX - domainVol.avgX);
    const volatilitySliderMax = isSingle ? domainVol.maxDomainX : Math.round(domainVol.maxDomainX - domainVol.avgX);

    return (
      <div className="slidersGreeksContainer">
        <div className="slidersContainer">
          <div className="sliderLabelValueContainer">
            <span id={this.state.idPrefix + "volatility"} className="sliderLabel">
              Volatility {!isSingle ? "(relative)" : ""}
            </span>
            <MyTooltip key={this.state.idPrefix + "volatility"} target={this.state.idPrefix + "volatility"} optionalOffset={15}>
              Simulates the change of implied volatility on the market.
            </MyTooltip>
            <div className="sliderValueContainer">
              <input
                id="impliedVolatilitySliderPosition"
                type="range"
                className="inputRange"
                value={this.props.selectedVolatility}
                min={volatilitySliderMin}
                max={volatilitySliderMax}
                onChange={this.onVolatilitySliderChange}
                disabled={this.props.disabled}
              />
              <div className="impliedVolatilityInputAndButtons">
                <span>{(!isSingle && this.props.selectedVolatility > 0 ? "+" : "") + this.props.selectedVolatility}%</span>
                <div className="increaseDecreaseImpliedVolatilityButtons">
                  <img className={`${this.props.disabled ? "increaseButtonDisabled" : "increaseButton"}`} onClick={this.onVolatilityButtonClick} />
                  <img className={`${this.props.disabled ? "decreaseButtonDisabled" : "decreaseButton"}`} onClick={this.onVolatilityButtonClick} />
                </div>
              </div>
              <div
                className={`imgAndResetFiltersSpan${this.isDefaultVolatility() ? " imgAndResetFiltersSpanDisabled" : ""}`}
                onClick={e => {
                  e.stopPropagation();
                  !this.isDefaultVolatility() && !this.props.disabled && this.onResetVolatility();
                }}
              >
                <img
                  id={this.state.idPrefix + "reset-volatility"}
                  className={`resetImg${this.isDefaultVolatility() || this.props.disabled ? " resetImgDisabled" : ""}`}
                />
                <MyTooltip key={this.state.idPrefix + "reset-volatility"} target={this.state.idPrefix + "reset-volatility"} optionalOffset={15}>
                  Resets Volatility slider to current implied volatility.
                </MyTooltip>
              </div>
            </div>
          </div>

          <div className="sliderLabelValueContainer">
            <span id={this.state.idPrefix + "time-to-expiration"} className="sliderLabel">
              Time to expiration
            </span>
            <MyTooltip key={this.state.idPrefix + "time-to-expiration"} target={this.state.idPrefix + "time-to-expiration"} optionalOffset={15}>
              Simulates the change of time to expiration.
            </MyTooltip>
            <div className="sliderValueContainer">
              <input
                id="daysToExpirySliderPosition"
                type="range"
                className="inputRange"
                value={periodsInDayToExpiry - this.props.selectedDaysToExpiry * periodsInDay}
                min={0}
                max={periodsInDayToExpiry}
                onChange={this.onDaysToExpirySliderChange}
                disabled={this.props.disabled}
              />
              <div className="daysToExpiryInputAndButtons">
                <span>{timeToExpirationStr}</span>
                <div className="increaseDecreaseDaysToExpiryButtons">
                  <img className={`${this.props.disabled ? "increaseButtonDisabled" : "increaseButton"}`} onClick={this.onDaysToExpiryButtonClick} />
                  <img className={`${this.props.disabled ? "decreaseButtonDisabled" : "decreaseButton"}`} onClick={this.onDaysToExpiryButtonClick} />
                </div>
              </div>
              <div
                className={`imgAndResetFiltersSpan${this.isDefaultDaysToExpiry() || this.props.disabled ? " imgAndResetFiltersSpanDisabled" : ""}`}
                onClick={e => {
                  e.stopPropagation();
                  !this.isDefaultDaysToExpiry() && !this.props.disabled && this.onResetDaysToExpiry();
                }}
              >
                <img
                  id={this.state.idPrefix + "reset-time-to-expiration"}
                  className={`resetImg${this.isDefaultDaysToExpiry() || this.props.disabled ? " resetImgDisabled" : ""}`}
                />
                <MyTooltip key={this.state.idPrefix + "reset-time-to-expiration"} target={this.state.idPrefix + "reset-time-to-expiration"} optionalOffset={15}>
                  Resets Time to expiration slider to current time to expiration (now).
                </MyTooltip>
              </div>
            </div>
          </div>
        </div>
        <GreeksTable
          key={"GreeksTable"}
          idPrefix={this.state.idPrefix}
          delta={this.props.delta}
          gamma={this.props.gamma}
          theta={this.props.theta}
          vega={this.props.vega}
          shouldBeDisabled={this.props.disabled}
        />
      </div>
    );
  }
}

SlidersPanel.propTypes = {
  selectedPositions: PropTypes.array,
  markets: PropTypes.array,
  underlyingTokenPrice: PropTypes.object,
  selectedTokenPair: PropTypes.object,
  selectedUnderPrice: PropTypes.object,
  selectedDaysToExpiry: PropTypes.object,
  selectedVolatility: PropTypes.object,
  calculateChartDataCallback: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  buyTabInputClass: PropTypes.string,
  sellTabInputClass: PropTypes.string,
  tabs: PropTypes.string,
  disabled: PropTypes.bool,
  delta: PropTypes.object,
  gamma: PropTypes.object,
  theta: PropTypes.object,
  vega: PropTypes.object,
  displayGreeksTable: PropTypes.func
};

function mapStateToProps(state) {
  return {
    underlyingTokenPrice: avgUnderlyingTokenPriceSelector(state),
    selectedTokenPair: selectedTokenPairSelector(state)
  };
}

export default connect(mapStateToProps)(SlidersPanel);
