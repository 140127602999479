/* eslint-disable no-console */
import { lte } from "lodash";
import { addStoreIfNeeded, addToDB, getData, openDatabase } from "./storageInteractions";

//export const eventsCacheMap = new Map();
let dbName = "0x123"; // default

export const setDBName = name => {
  dbName = name;
};

export const addStores = async stores => {
  const startTime = new Date().getTime();
  for (const store of stores) {
    await addStoreIfNeeded(dbName, store);
  }
  console.log("Stores added in: " + (new Date() - startTime) + " ms");
};

export const getEventsStreamCached = async (networkData, contract, contractName, eventName) => {
  const startTime = new Date().getTime();
  // todo: implement filter for RPC and cache
  const eventsStream = [];

  let firstBlock = networkData.firstBlock;
  const lastBlockNumber = networkData.lastBlock;

  // step 1 - get events from db
  const storeName = contractName + "-" + eventName;
  const displayName = contractName + "." + eventName;
  const db = await openDatabase(dbName);
  const dbTable = await getData(db, storeName);
  if (dbTable.length > 0) {
    const eventsCache = dbTable[0];
    firstBlock = eventsCache.lastBlock + 1;
    eventsStream.push(...eventsCache.events);
    console.log("Looked up in cache and found:", eventsStream.length, "events");
  }

  // step 2 - fetch new events that are not stored
  const limit = firstBlock > 0 ? 10000 : 1e12; // trilion blocks
  const requestCount = lastBlockNumber !== firstBlock ? Math.max(0, Math.ceil((lastBlockNumber - firstBlock) / limit)) : 1;
  const rpcStream = [];
  if (requestCount > 0) {
    console.log("RPC events (cached) calling, from block ", firstBlock, "to", lastBlockNumber, "...");
    for (let i = 0; i < requestCount; i++) {
      const fromBlock = firstBlock + i * limit;
      let toBlock = firstBlock + (i + 1) * limit - 1;
      if (toBlock > lastBlockNumber) {
        toBlock = lastBlockNumber;
      }
      const streamBatch = await contract.getPastEvents(eventName, {
        fromBlock: fromBlock,
        toBlock: toBlock // Math.max(lastBlockNumber, toBlock)
      });
      rpcStream.push(...streamBatch);
      // await this.sleep(10);
    }
    eventsStream.push(...rpcStream);
    console.log("RPC events (cached):", displayName + ":", rpcStream.length, "events,", new Date().getTime() - startTime, "mS");
  }

  // step 3 - update db
  await addToDB(db, storeName, { events: eventsStream, lastBlock: lastBlockNumber, id: 1 }); // id 1 makes sure it's updated, not added row

  const endTime = new Date().getTime();
  // eslint-disable-next-line prettier/prettier
  console.log("Fetched past events (cached):", displayName, "and found:", rpcStream.length, "events in", requestCount, "requests, in", endTime - startTime, "mS");

  return eventsStream;
};

export const getEventsStream = async (contract, eventName, filter) => {
  let firstBlock = 12377600;
  const startTime = new Date().getTime();
  const eventsStream = await contract.getPastEvents(eventName, {
    filter: filter,
    fromBlock: firstBlock,
    toBlock: "latest"
  });

  const endTime = new Date().getTime();
  console.log("RPC events:", eventName + ":", eventsStream.length, "events,", endTime - startTime, "mS");

  return eventsStream;
};

export const getMethodResultCached = async (contractMethod, contractName, methodName) => {
  const startTime = new Date().getTime();

  const resultStream = [];

  // step 1 - get events from db or RPC
  const storeName = contractName + "-" + methodName;
  const displayName = contractName + "." + methodName;
  const db = await openDatabase(dbName);
  const dbTable = await getData(db, storeName);
  if (dbTable.length > 0) {
    const resultCache = dbTable[0];
    resultStream.push(...resultCache.result);
    console.log("Looked up in cache and found:", resultStream.length, "items");
  } else {
    const result = await contractMethod.call();
    resultStream.push(...result);
    console.log("RPC method (cached):", displayName + ":", result.length, "items,", new Date().getTime() - startTime, "mS");
  }

  // step 2 - update db
  await addToDB(db, storeName, { result: resultStream, id: 1 }); // id 1 makes sure it's updated, not added row

  const endTime = new Date().getTime();
  console.log("Fetched method result (cached):", displayName, "and found:", resultStream.length, "items in", endTime - startTime, "mS");

  return resultStream;
};
