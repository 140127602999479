/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-undef */
/* eslint-disable react/no-multi-comp */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { marketsLoadedSelector, marketsSelector } from "../../store/selectors/marketsSelectors";
import CardHeader from "reactstrap/lib/CardHeader";
import Card from "reactstrap/lib/Card";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import { formatValue, toShortAddress, printPriceFromString, cursorRangeCheck } from "../../utils/utils.js";
import {
  openedLPPositionBalancesSelector,
  openedPositionBalancesSelector,
  selectedTokenPairSelector,
  tokenBalancesSelector,
  tokensLoadedSelector,
  avgUnderlyingTokenPriceSelector
} from "../../store/selectors/tokensSelectors";
import { openedOthersLPPositionsSelector, openedOthersTradePositionsSelector } from "../../store/selectors/positionsManagerSelectors";
import { web3AccountLoadedSelector, web3AccountSelector, web3Selector } from "../../store/selectors/web3Selectors";
import {
  marginPoolSelector,
  userBalancesSelector,
  lpManagerSelector,
  loadHelperSelector,
  volRangeMarketSingletonSelector
} from "../../store/selectors/contractsSelectors";
import { Promise } from "bluebird";
import { getLPPositionsLiquidationData, getTotalAndDebtForAccount } from "../../store/interactions/liquidationsInteractions";
import {
  marginAccountDataLoadedSelector,
  marginAccountDataSelector,
  marginPoolDataSelector,
  positionsBorrowFactorsSelector
} from "../../store/selectors/marginSelectors";
import { getPositionLiquidationPrice, getLiquidationPrice, getChartPoint, getHighestDaysToExpiryRounded } from "../../utils/chartsUtils";
import { liquidatePosition, liquidateAccount } from "../../store/interactions/positionsManagerInteractions";
import PositionModal from "./PositionModal.jsx";
import ReactGA from "react-ga4";
import MyTooltip2 from "./MyTooltip2.jsx";
import { formatUnits } from "@ethersproject/units";
import { printEmptyTable, printLoadingIcon } from "./utilities/TradePanels/PrintUtility.jsx";
import { fromBn, toBn } from "evm-bn";

const EMPTY_POSITIONS_TABLE_TEXT = "No critical positions";
const EMPTY_ACCOUNTS_TABLE_TEXT = "No critical accounts";

class Liquidations extends React.Component {
  constructor(props) {
    super();
    this.state = {
      accountTableData: [],
      accountTableDataLoadedTime: 0,
      positionTableData: [],
      positionTableDataLoadedTime: 0,
      loadingData: false,
      isPositionModalOpen: false,
      selectedAccountPositions: [],
      profit: null,
      health: null,
      selectedAccount: null,
      isLiquidation: true, // todo: v2 remove

      idPrefix: "liquidations-",

      tooltipIsVisible: false,
      tooltipTarget: "",
      tooltipContent: ""
    };
  }

  async componentDidMount() {
    console.log("Navigate to: " + window.location.pathname); // eslint-disable-line no-console
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Liquidations" });

    // fetch data if user clicked on liquidations link
    if (this.arePropsLoaded()) {
      await this.fetchBlockchainData();
    }
  }

  async componentDidUpdate(prevProps) {
    const startTime = new Date().getTime();

    await this.fetchBlockchainData(prevProps);

    // eslint-disable-next-line prettier/prettier, no-console
    console.log("LOADING componentDidUpdate finish time:", new Date().getTime() - startTime, "mS");
  }

  arePropsLoaded = () => {
    return (
      this.props.tokensLoaded &&
      this.props.underlyingTokenPrice &&
      this.props.marginAccountDataLoaded &&
      this.props.marginPoolData &&
      this.props.positionsBorrowFactors &&
      this.props.marketsLoaded
    );
  };

  fetchBlockchainData = async prevProps => {
    let { accounts, positionsData } = this.getAccountsAndPositionsData();

    // break early if no need to fetch blockchain data, do nothing
    if (!this.needsToFetchBlockchainData(prevProps, accounts)) {
      return;
    }

    // break early if already loading data
    if (this.state.loadingData) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log("LOADING account and positions liquidation data, accounts: ", accounts.length);
    this.setState({
      loadingData: true
    });

    let accountsData = null;
    const localUnderPrice = { ...this.props.underlyingTokenPrice };
    const underPrice = parseFloat(localUnderPrice.normalized);

    accountsData = await Promise.map(accounts, async account => {
      return getTotalAndDebtForAccount(this.props.loadHelper, this.props.marginPool, this.props.userBalances, localUnderPrice.raw, account);
    });

    const marketMap = new Map();
    positionsData.forEach(position => {
      if (!marketMap.get(position.marketId)) {
        let market = this.props.markets.find(market => market.marketId === position.marketId);
        marketMap.set(position.marketId, market);
      }
    });

    const array2 = await getLPPositionsLiquidationData(this.props.loadHelper, positionsData, marketMap, this.props.underlyingTokenPrice.raw);
    const positionsDataUpdated = array2.map((item, index) => {
      const health = parseFloat(formatUnits(item.health.toString(), 18));
      const position = positionsData[index];
      const market = marketMap.get(position.marketId);
      position.lower = parseInt(item.lower);
      position.upper = parseInt(item.upper);
      let liquidationPrice = 0;

      if (health < market.requiredThreshold) {
        const rate = this.props.selectedTokenPair.riskFreeRate / 100;
        liquidationPrice = getPositionLiquidationPrice(position, market, underPrice, parseFloat(market.longPriceInVol), rate, market.liquidationThreshold);
      }
      return {
        ...position,
        health: health,
        liquidationPrice: liquidationPrice
      };
    });

    // sort by health increasing
    positionsDataUpdated.sort((a, b) => a.health - b.health);

    // finally, set state
    const now = Math.round(Date.now() / 1000);
    this.setState({
      accountTableData: accountsData,
      accountTableDataLoadedTime: now,
      positionTableData: positionsDataUpdated,
      positionTableDataLoadedTime: now,
      loadingData: false
    });
  };

  getPositionsDataLength = openedLPPositions => {
    let propsPositionsCount = 0;
    openedLPPositions.data.forEach((value, _key) => {
      if (value !== null) {
        propsPositionsCount += value.length;
      }
    });

    return propsPositionsCount;
  };

  needsToFetchBlockchainData = (prevProps, accounts) => {
    // fetch if called from componentDidMount
    if (prevProps === undefined) {
      return true;
    }

    // no fetching if props not loaded, or no accounts
    if (!this.props.tokensLoaded || !this.props.underlyingTokenPrice || accounts.length === 0) {
      return false;
    }

    // needs fetch if accounts are updated
    if (accounts.length !== this.state.accountTableData.length) {
      return true;
    }

    // needs fetch if positions are updated (added or removed)
    const propsPositionsCount = this.getPositionsDataLength(this.props.openedOthersLPPositions);
    const prevPropsPositionsCount = this.getPositionsDataLength(prevProps.openedOthersLPPositions);
    if (propsPositionsCount !== prevPropsPositionsCount) {
      return true;
    }

    // needs fetch if price is updated and enough time passed
    const now = Math.round(Date.now() / 1000);
    if (
      (prevProps.underlyingTokenPrice !== this.props.underlyingTokenPrice ||
        prevProps.openedOthersTradePositions.size !== this.props.openedOthersTradePositions.size ||
        prevProps.openedOthersLPPositions.data.size !== this.props.openedOthersLPPositions.data.size) &&
      Math.abs(now - this.state.accountTableDataLoadedTime) > 60 // fetch every 60 secs if under price changed
    ) {
      return true;
    }

    return false;
  };

  getAccountsAndPositionsData = () => {
    let accounts = [];
    let positionsData = [];
    if (this.props.openedOthersTradePositions.size > 0) {
      accounts.push(...this.props.openedOthersTradePositions.keys());
    }

    let nullCount = 0;
    if (this.props.openedOthersLPPositions.data.size > 0) {
      accounts.push(...this.props.openedOthersLPPositions.data.keys());

      this.props.openedOthersLPPositions.data.forEach((value, key) => {
        if (value !== null) {
          for (let position of value) {
            const market = this.props.markets.find(m => m.marketId === position.marketId);
            if (position !== null && position !== undefined && position.balance > 0 && market && !market.isSettled) {
              positionsData.push({
                ...position,
                owner: key
              });
            }
          }
        } else {
          nullCount++;
        }
      });
      if (nullCount > 0) {
        positionsData = [];
      }
    }
    accounts = [...new Set(accounts)];
    accounts = accounts.filter(account => account);

    return { accounts, positionsData };
  };

  setTooltipIsVisible = visible => {
    this.setState({ tooltipIsVisible: visible });
  };

  setTooltipTarget = target => {
    this.setState({ tooltipTarget: target });
  };

  setTooltipContent = content => {
    this.setState({ tooltipContent: content });
  };

  onMouseEnterColumnHeader = (e, text) => {
    if (!this.state.tooltipIsVisible) {
      this.setState({ tooltipIsVisible: true, tooltipContent: text, tooltipTarget: e.currentTarget.id });
    }
  };

  onMouseLeaveColumnHeader = e => {
    if (!cursorRangeCheck(this.state.tooltipTarget, e.clientX, e.clientY)) {
      this.setState({ tooltipIsVisible: false, tooltipContent: "", tooltipTarget: "" });
      document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", -500 + "px");

      document.querySelector(":root").style.setProperty("--tooltipTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipLeftPosition", -500 + "px");
    }
  };

  getAccountsTableColumns = () => {
    const recoveryThreshold = this.props.marginAccountData ? parseFloat(fromBn(this.props.marginAccountData.recoveryThreshold)) * 100 : 0;
    const liquidationThreshold = this.props.marginAccountData ? parseFloat(fromBn(this.props.marginAccountData.liquidationThreshold)) * 100 : 0;

    let notEnoughtFunds = false;
    if (this.props.tokensLoaded) {
      const tokenETH = this.props.tokens.find(t => t.symbol === "ETH");
      const tokenUSD = this.props.tokens.find(t => t.symbol === "USD");
      if (tokenETH.accountBalance == "0" && tokenETH.walletBalance == "0" && tokenUSD.accountBalance == "0" && tokenUSD.walletBalance == "0") {
        notEnoughtFunds = true;
      }
    }

    const tableColumns = [
      {
        Header: () => (
          <div className="alignLeft">
            <span id="account" onMouseEnter={e => this.onMouseEnterColumnHeader(e, <>Address of the user</>)} onMouseLeave={this.onMouseLeaveColumnHeader}>
              ACCOUNT
            </span>
          </div>
        ),
        accessor: "account",
        align: "right",
        minWidth: 45,
        filterable: false,
        sortable: false,
        Cell: row => <span>{toShortAddress(row.value)}</span>
      },
      {
        Header: () => (
          <div>
            <span
              id="accountDebt"
              onMouseEnter={e => this.onMouseEnterColumnHeader(e, <>Account debt in $ USD.</>)}
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              DEBT
            </span>
          </div>
        ),
        accessor: "debt",
        align: "right",
        minWidth: 25,
        filterable: false,
        sortable: false,
        Cell: row => <span style={{ float: "right" }}>${formatValue(row.value, 2)}</span>
      },
      {
        Header: () => (
          <div>
            <span
              style={{ float: "right" }}
              id="accountDelta"
              onMouseEnter={e => this.onMouseEnterColumnHeader(e, <>Total Delta (&Delta;) of all positions in the account.</>)}
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              &Delta; DELTA
            </span>
          </div>
        ),
        accessor: "delta",
        minWidth: 30,
        // maxWidth: 5 * 30,
        filterable: false,
        sortable: false,
        Cell: row => <span style={{ float: "right" }}>{formatValue(row.value, 2)}</span>
      },
      {
        Header: () => (
          <div>
            <span
              style={{ textAlign: "right" }}
              id="marginLock"
              onMouseEnter={e =>
                this.onMouseEnterColumnHeader(
                  e,
                  <>
                    Amount required to be margin locked to liquidate the account. <br /> <br />
                    Liquidator's excess liquidity is reduced by this amount.
                  </>
                )
              }
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              Margin Lock
            </span>
          </div>
        ),
        accessor: "minCost",
        minWidth: 30,
        // maxWidth: 5 * 30,
        filterable: false,
        sortable: false,
        Cell: row => {
          return <span style={{ float: "right" }}>${formatValue(row.value, 0)}</span>;
        }
      },
      {
        Header: () => (
          <div>
            <span
              style={{ textAlign: "right" }}
              id="accountProfit"
              onMouseEnter={e =>
                this.onMouseEnterColumnHeader(
                  e,
                  <>
                    Profit in $ USD for liquidating the account. <br /> <br /> Profit is taken from liquidated account, and is always 10% of account debt.{" "}
                    <br /> <br />
                    Learn more: <br />
                    <a href="https://doc.gammaoptions.com/docs/fundamentals/margin/account-liquidation" target="_blank" rel="noreferrer">
                      Account Liquidation
                    </a>
                  </>
                )
              }
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              PROFIT
            </span>
          </div>
        ),
        accessor: "profit",
        minWidth: 40,
        // maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        Cell: row => <span style={{ float: "right" }}>${formatValue(row.value, 0)}</span>
      },
      {
        Header: () => (
          <div>
            <span
              id="accountLiqPrice"
              onMouseEnter={e => this.onMouseEnterColumnHeader(e, <>Liquidation price is ETH price after which the account can be liquidated. </>)}
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              LIQ PRICE
            </span>
          </div>
        ),
        accessor: "liqPrice",
        minWidth: 40,
        maxWidth: 150,
        filterable: false,
        sortable: false,
        Cell: row => <span style={{ float: "right" }}>{row.value}</span>
      },
      {
        Header: () => (
          <div>
            <span
              id="accountHealth"
              onMouseEnter={e =>
                this.onMouseEnterColumnHeader(
                  e,
                  <>
                    Health is the ratio betweeen total account liquidity (including debt) and debt. <br /> <br /> As soon as health drops below 125%, the
                    account can be liquidated.
                  </>
                )
              }
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              HEALTH
            </span>
          </div>
        ),
        accessor: "health",
        minWidth: 40,
        maxWidth: 150,
        filterable: false,
        sortable: false,
        Cell: row => {
          const healthForColor = parseFloat(row.value);

          const healthColorClass = this.props.marginAccountData
            ? row.value.trim().includes("Infinity") || healthForColor > recoveryThreshold
              ? "greenHealthColor"
              : healthForColor < liquidationThreshold
              ? "redHealthColor"
              : "yellowHealthColor"
            : "";

          const health = row.value.trim().includes("Infinity") ? "max" : row.value + "%";

          return (
            <span style={{ float: "right", textTransform: "lowercase" }} className={`${healthColorClass}`}>
              {health}
            </span>
          );
        }
      },
      {
        Header: () => (
          <div>
            <span style={{ display: "block", margin: "auto", width: "fit-content" }}>ACTION</span>
          </div>
        ),
        accessor: "action",
        minWidth: 40,
        maxWidth: 150,
        filterable: false,
        sortable: false,
        Cell: row => {
          const healthForColor = parseFloat(row.original.health);

          const healthColorClass = this.props.marginAccountData
            ? row.original.health.trim().includes("Infinity") || healthForColor > recoveryThreshold
              ? "greenHealthColor"
              : healthForColor < liquidationThreshold
              ? "redHealthColor"
              : "yellowHealthColor"
            : "";
          const account = row.original.account;

          return (
            <button
              disabled={healthColorClass !== "redHealthColor" || notEnoughtFunds}
              className="liquidationButton"
              onClick={e => {
                e.stopPropagation();
                this.liquidateAccount(account);
              }}
            >
              LIQUIDATE
            </button>
          );
        }
      }
    ];
    return tableColumns;
  };

  getPositionsTableColumns = () => {
    const recoveryThreshold = 200;
    const liquidationThreshold = this.props.marginAccountData ? parseFloat(fromBn(this.props.marginAccountData.liquidationThreshold)) * 100 : 0;

    const tableColumns = [
      {
        Header: () => (
          <div className="alignLeft">
            <span
              id="name"
              onMouseEnter={e => this.onMouseEnterColumnHeader(e, <>Unique LP position identifier</>)}
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              LP POSITION
            </span>
          </div>
        ),
        accessor: "name",
        align: "right",
        minWidth: 45,
        filterable: false,
        sortable: false,
        Cell: row => <span>{row.value}</span>
      },
      {
        Header: () => (
          <div>
            <span
              style={{ textAlign: "right" }}
              id="positionProfit"
              onMouseEnter={e =>
                this.onMouseEnterColumnHeader(
                  e,
                  <>
                    Profit in $ USD for liquidating the LP position. <br /> <br /> Profit is taken from liquidated LP position, and is fixed at $150. <br />{" "}
                    <br />
                    Learn more: <br />
                    <a href="https://doc.gammaoptions.com/docs/fundamentals/markets/lp-position-risks#lp-position-liquidation" target="_blank" rel="noreferrer">
                      LP Position Liquidation
                    </a>
                    <br />
                    <a href="https://doc.gammaoptions.com/docs/fundamentals/markets/lp-position-health" target="_blank" rel="noreferrer">
                      LP Position Health
                    </a>
                  </>
                )
              }
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              PROFIT
            </span>
          </div>
        ),
        accessor: "profit",
        minWidth: 40,
        // maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        Cell: row => <span style={{ float: "right" }}>${formatValue(row.value, 0)}</span>
      },
      {
        Header: () => (
          <div>
            <span
              id="positionLiqPrice"
              onMouseEnter={e => this.onMouseEnterColumnHeader(e, <>Liquidation price is ETH price after which the LP position can be liquidated. </>)}
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              LIQ PRICE
            </span>
          </div>
        ),
        accessor: "liquidationPrice",
        minWidth: 40,
        maxWidth: 150,
        filterable: false,
        sortable: false,
        Cell: row => <span style={{ float: "right" }}>{row.value}</span>
      },
      {
        Header: () => (
          <div>
            <span
              id="positionHealth"
              onMouseEnter={e =>
                this.onMouseEnterColumnHeader(
                  e,
                  <>
                    LP Health represents the ratio between cash reserves in the LP position and actual cash needed to buy options in the range. <br /> <br /> As
                    soon as LP Health drops below 125%, the LP position can be liquidated.
                  </>
                )
              }
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              LP HEALTH
            </span>
          </div>
        ),
        accessor: "health",
        minWidth: 40,
        maxWidth: 150,
        filterable: false,
        sortable: false,
        Cell: row => {
          const healthForColor = parseFloat(row.value);

          const healthColorClass = this.props.marginAccountData
            ? row.value.trim().includes("Infinity") || healthForColor > recoveryThreshold
              ? "greenHealthColor"
              : healthForColor < liquidationThreshold
              ? "redHealthColor"
              : "yellowHealthColor"
            : "";

          const health = row.value.trim().includes("Infinity") ? "max" : row.value + "%";

          return (
            <span style={{ float: "right", textTransform: "lowercase" }} className={`${healthColorClass}`}>
              {health}
            </span>
          );
        }
      },
      {
        Header: () => (
          <div>
            <span style={{ display: "block", margin: "auto", width: "fit-content" }}>ACTION</span>
          </div>
        ),
        accessor: "action",
        minWidth: 40,
        maxWidth: 150,
        filterable: false,
        sortable: false,
        Cell: row => {
          const healthForColor = parseFloat(row.original.health);

          const healthColorClass = this.props.marginAccountData
            ? row.original.health.trim().includes("Infinity") || healthForColor > recoveryThreshold
              ? "greenHealthColor"
              : healthForColor < liquidationThreshold
              ? "redHealthColor"
              : "yellowHealthColor"
            : "";
          const marketId = row.original.marketId;
          const positionId = row.original.positionId;
          const owner = row.original.owner;

          let notEnoughtFunds = false;
          if (this.props.tokensLoaded) {
            const tokenETH = this.props.tokens.find(t => t.symbol === "ETH");
            const tokenUSD = this.props.tokens.find(t => t.symbol === "USD");
            if (tokenETH.accountBalance == "0" && tokenETH.walletBalance == "0" && tokenUSD.accountBalance == "0" && tokenUSD.walletBalance == "0") {
              notEnoughtFunds = true;
            }
          }

          return (
            <button
              disabled={healthColorClass !== "redHealthColor" || notEnoughtFunds}
              className="liquidationButton"
              onClick={e => {
                e.stopPropagation();
                this.liquidatePosition(owner, marketId, positionId);
              }}
            >
              LIQUIDATE
            </button>
          );
        }
      }
    ];
    return tableColumns;
  };

  liquidateAccount = account => {
    liquidateAccount(this.props.web3, this.props.lpManager, account, this.props.web3Account, this.props.dispatch);
  };

  liquidatePosition = (owner, marketId, positionId) => {
    let marketBorrowFactor = this.props.positionsBorrowFactors.get(marketId + true + positionId);
    liquidatePosition(this.props.web3, this.props.lpManager, owner, marketId, positionId, this.props.web3Account, marketBorrowFactor, this.props.dispatch);
  };

  getAccountsTableData = () => {
    if (this.props.underlyingTokenPrice && this.state.accountTableData && this.props.marginAccountData && this.props.marginPoolData) {
      const recoveryThreshold = this.props.marginAccountData ? parseFloat(fromBn(this.props.marginAccountData.recoveryThreshold)) * 100 : 0;

      return this.state.accountTableData
        .map(td => {
          const profit = td.debt * 0.05;
          const health = ((td.total / td.debt) * 100).toFixed(2);
          const minCost = td.debt * 0.15;

          const longShortPositions = this.props.openedOthersTradePositions.get(td.account);
          const lpPositions = this.props.openedOthersLPPositions.data.get(td.account);

          const allPositions = [];
          longShortPositions && allPositions.push(...longShortPositions);
          lpPositions && allPositions.push(...lpPositions);

          if (allPositions.length > 0) {
            const market = this.props.markets.find(market => {
              return market.marketId === allPositions[0].marketId;
            });

            const daysToExpiration = getHighestDaysToExpiryRounded(allPositions, this.props.markets);
            const volatility = allPositions.length === 1 ? Math.round(parseFloat(market.longPriceInVol)) : 0;
            const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);

            const baseBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "USD").normalizedBorrowInterestRate;
            const underBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "ETH").normalizedBorrowInterestRate;
            const borrowRates = { baseBorrowRate, underBorrowRate };
            const chartPoint = getChartPoint(
              allPositions,
              this.props.markets,
              underPrice,
              daysToExpiration,
              parseFloat(volatility),
              this.props.selectedTokenPair.riskFreeRate / 100,
              borrowRates
            );
            const { delta } = chartPoint;

            // liquidation price
            const assetsBase = td.usdBalance;
            const assetsUnder = td.ethBalance;
            const debtBase = td.borrowedDebtBaseAmount;
            const debtUnder = td.borrowedDebtEthAmount;

            const liqPrices = getLiquidationPrice(
              allPositions,
              this.props.markets,
              underPrice,
              daysToExpiration,
              volatility,
              this.props.selectedTokenPair.riskFreeRate / 100,
              assetsBase,
              assetsUnder,
              debtBase,
              debtUnder,
              borrowRates
            );

            // find closest liquidation price to underPrice
            const liqPrice =
              Math.abs(liqPrices.upperLiqPrice - underPrice) < Math.abs(liqPrices.lowerLiqPrice - underPrice)
                ? liqPrices.upperLiqPrice
                : liqPrices.lowerLiqPrice;

            return {
              ...td,
              delta,
              minCost,
              profit,
              liqPrice: liqPrice.toFixed(2),
              health
            };
          }
        })
        .filter(d => {
          if (!d || d.health.toLowerCase().includes("infinity")) {
            return false;
          }
          return parseFloat(d.health) < recoveryThreshold + 10;
        });
    }

    return [];
  };

  getPositionsTableData = () => {
    if (this.props.underlyingTokenPrice && this.state.accountTableData && this.props.marginAccountData) {
      const liquidationThreshold = 150; // TODO: v1 slaven use recovery threshold + 10

      return this.state.positionTableData
        .map(td => {
          return {
            ...td,
            profit: 150,
            liquidationPrice: td.liquidationPrice ? printPriceFromString(td.liquidationPrice.toFixed(2)) : "...",
            health: (td.health * 100).toFixed(2)
          };
        })
        .filter(d => {
          if (d.health.toLowerCase().includes("infinity")) {
            return false;
          }
          return parseFloat(d.health) < liquidationThreshold;
        });
    }

    return [];
  };

  togglePositionModal = () => {
    document.querySelector("body").classList.toggle("scrollDisabled");
    this.setState({ isPositionModalOpen: !this.state.isPositionModalOpen });
  };

  getAccountPositions = data => {
    const account = data.account;
    const tradePositions = this.props.openedOthersTradePositions.get(account) || [];
    const lpPositions = this.props.openedOthersLPPositions.data.get(account) || [];
    this.setState({ selectedAccountPositions: [...tradePositions, ...lpPositions] });
  };

  onRowPositionClicked = rowInfo => {
    document.querySelector("body").classList.toggle("scrollDisabled");
    const token = rowInfo.original.token;
    let position = [...this.props.openedOthersTradePositions.values()][0].find(p => token === p.shortName);
    !position && (position = [...this.props.openedOthersLPPositions.data.values()][0].find(p => token === p.shortName));

    this.setState({
      isPositionModalOpen: true,
      selectedAccountPositions: [position],
      profit: null,
      health: null,
      selectedAccount: null,
      isLiquidation: false
    });
  };

  render() {
    const positionsData = this.getPositionsTableData();
    const accountData = this.getAccountsTableData();

    return (
      <>
        {this.state.isPositionModalOpen ? (
          <PositionModal
            isOpen={this.state.isPositionModalOpen}
            toggle={this.togglePositionModal}
            selectedPositions={this.state.selectedAccountPositions}
            isLiquidation={this.state.isLiquidation}
            profit={this.state.profit}
            health={this.state.health}
            fromLiquidations
            liquidateAccount={this.liquidateAccount}
            accountToLiquidate={this.state.selectedAccount}
            onRowPositionClicked={this.onRowPositionClicked}
          />
        ) : null}
        <div className="content" style={{ paddingTop: "10px" }}>
          <Row>
            <Col className="mb-1" md="12">
              <Card>
                <CardHeader
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <CardTitle tag="h4">
                    <div className="historyHeaderContainer">
                      <span className="headerTitle">ACCOUNT LIQUIDATIONS</span>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody id="liquidations">
                  {printLoadingIcon(this.props.web3AccountLoaded, !this.state.loadingData)}
                  {!this.state.loadingData && accountData.length > 0 && (
                    <ReactTable
                      className="-highlight"
                      data={accountData}
                      columns={this.getAccountsTableColumns()}
                      defaultPageSize={5}
                      //TODO: uncomment when position modal logic is fixed for account that gets liquidated by liquidator
                    />
                  )}
                  {printEmptyTable(
                    this.props.web3AccountLoaded,
                    this.state.accountTableDataLoadedTime > 0 && !this.state.loadingData,
                    accountData,
                    EMPTY_ACCOUNTS_TABLE_TEXT
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="mb-1" md="12">
              <Card>
                <CardHeader
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <CardTitle tag="h4">
                    <div className="historyHeaderContainer">
                      <span className="headerTitle">POSITION LIQUIDATIONS</span>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody id="liquidations">
                  {printLoadingIcon(this.props.web3AccountLoaded, !this.state.loadingData)}
                  {this.state.positionTableDataLoadedTime > 0 && !this.state.loadingData && positionsData.length > 0 && (
                    <ReactTable className="-highlight" data={positionsData} columns={this.getPositionsTableColumns()} defaultPageSize={5} />
                  )}
                  {printEmptyTable(
                    this.props.web3AccountLoaded,
                    this.state.positionTableDataLoadedTime > 0 && !this.state.loadingData,
                    positionsData,
                    EMPTY_POSITIONS_TABLE_TEXT
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {this.state.tooltipIsVisible && (
          <MyTooltip2
            target={this.state.tooltipTarget}
            setTooltipIsVisible={this.setTooltipIsVisible}
            setTooltipContent={this.setTooltipContent}
            setTooltipTarget={this.setTooltipTarget}
          >
            {this.state.tooltipContent}
          </MyTooltip2>
        )}
      </>
    );
  }
}

Liquidations.propTypes = {
  volRangeMarketSingleton: PropTypes.object,
  markets: PropTypes.array,
  marketsLoaded: PropTypes.bool,
  openedPositionBalances: PropTypes.array,
  openedLpPositionBalances: PropTypes.array,
  openedOthersTradePositions: PropTypes.array,
  openedOthersLPPositions: PropTypes.array,
  web3: PropTypes.object,
  web3AccountLoaded: PropTypes.bool,
  marginPool: PropTypes.object,
  userBalances: PropTypes.object,
  lpManager: PropTypes.object,
  marginAccountData: PropTypes.object,
  marginAccountDataLoaded: PropTypes.bool,
  underlyingTokenPrice: PropTypes.object,
  selectedTokenPair: PropTypes.object,
  web3Account: PropTypes.string,
  tokensLoaded: PropTypes.bool,
  tokens: PropTypes.array,
  loadHelper: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  positionsBorrowFactors: PropTypes.object,
  marginPoolData: PropTypes.object
};

function mapStateToProps(state) {
  return {
    volRangeMarketSingleton: volRangeMarketSingletonSelector(state),
    markets: marketsSelector(state),
    marketsLoaded: marketsLoadedSelector(state),
    openedPositionBalances: openedPositionBalancesSelector(state),
    openedLpPositionBalances: openedLPPositionBalancesSelector(state),
    openedOthersTradePositions: openedOthersTradePositionsSelector(state),
    openedOthersLPPositions: openedOthersLPPositionsSelector(state),
    web3: web3Selector(state),
    web3AccountLoaded: web3AccountLoadedSelector(state),
    marginPool: marginPoolSelector(state),
    userBalances: userBalancesSelector(state),
    lpManager: lpManagerSelector(state),
    marginAccountData: marginAccountDataSelector(state),
    marginAccountDataLoaded: marginAccountDataLoadedSelector(state),
    underlyingTokenPrice: avgUnderlyingTokenPriceSelector(state),
    selectedTokenPair: selectedTokenPairSelector(state),
    web3Account: web3AccountSelector(state),
    tokensLoaded: tokensLoadedSelector(state),
    tokens: tokenBalancesSelector(state),
    loadHelper: loadHelperSelector(state),
    positionsBorrowFactors: positionsBorrowFactorsSelector(state),
    marginPoolData: marginPoolDataSelector(state)
  };
}
export default connect(mapStateToProps)(Liquidations);
