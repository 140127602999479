import React from "react";
import { createPortal } from "react-dom";
import { connect } from "react-redux";
import { Card, CardBody } from "reactstrap";
import PropTypes from "prop-types";

class TokenSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return createPortal(
      <div className="tokensSelectContainer" style={{ top: this.props.top, left: this.props.left, width: this.props.width }}>
        <Card>
          <CardBody>
            <table className="table">
              <tbody>
                {this.props.positionsForSelectedmarket &&
                  this.props.positionsForSelectedmarket.map(position => {
                    return (
                      <tr
                        key={position.shortName}
                        onClick={e => {
                          this.props.selectToken(position);
                        }}
                      >
                        <td className="tokenOption">{position.shortName}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </div>,
      document.getElementById("root")
    );
  }
}

TokenSelect.propTypes = {
  top: PropTypes.object,
  left: PropTypes.object,
  width: PropTypes.object,
  positionsForSelectedmarket: PropTypes.object,
  selectToken: PropTypes.func
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(TokenSelect);
