import { get } from "lodash";
import { createSelector } from "reselect";
import { tokens, tokensLoaded, selectedTokenPair } from "./tokensSelectors";
import { formatUnits } from "@ethersproject/units";
import { getDateString } from "../utils/positionsManagerUtils";
import { web3account, web3accountLoaded } from "./web3Selectors";

const insuranceFundLoaded = state => get(state, "insuranceFund.loaded", false);
export const insuranceFundData = state => get(state, "insuranceFund.data", {});

export const insuranceFundLoadedSelector = createSelector(insuranceFundLoaded, loaded => loaded);

export const insuranceFundWithUnclaimedSelector = createSelector(insuranceFundData, data => {
  const { insuranceFundBalance, unclaimedInsuranceFundBalance } = data;
  return insuranceFundBalance + unclaimedInsuranceFundBalance;
});

export const insurerBalanceWithUnclaimedSelector = createSelector(insuranceFundData, data => {
  const { insurerBalance, unclaimedInsurerBalance } = data;
  return insurerBalance + unclaimedInsurerBalance;
});

// InsuranceFund deposits and withdrawals
export const insuranceFundDepositsAndWithdrawalsLoaded = state => get(state, "insuranceFundDepositsAndWithdrawals.loaded");
export const insuranceFundDepositsAndWithdrawalsLoadedSelector = createSelector(insuranceFundDepositsAndWithdrawalsLoaded, loaded => loaded);

export const insuranceFundDepositsAndWithdrawalsData = state => get(state, "insuranceFundDepositsAndWithdrawals.data");
export const insuranceFundDepositsAndWithdrawalsSelector = createSelector(
  insuranceFundDepositsAndWithdrawalsData,
  insuranceFundDepositsAndWithdrawalsLoaded,
  tokensLoaded,
  tokens,
  selectedTokenPair,
  (insuranceFundDepositsAndWithdrawalsData, insuranceFundDepositsAndWithdrawalsLoaded, tokensLoaded, tokens, selectedTokenPair) => {
    if (insuranceFundDepositsAndWithdrawalsLoaded && tokensLoaded && selectedTokenPair && selectedTokenPair.underlyingTokenPrice) {
      const normalizedUnderlyingPrice = formatUnits(selectedTokenPair.underlyingTokenPrice, 18);
      return insuranceFundDepositsAndWithdrawalsData.map(daw => {
        const normalizedAmount = parseFloat(formatUnits(daw.amount.toString(), 18));
        const printableAmount = parseFloat(normalizedAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        const underlyingTokenAddress = tokens.find(token => token.symbol === "ETH").address;
        const normalizedValue =
          daw.token === underlyingTokenAddress ? parseFloat(normalizedAmount) * parseFloat(normalizedUnderlyingPrice) : parseFloat(normalizedAmount);
        const printableValue = parseFloat(normalizedValue).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });

        const dateString = getDateString(new Date(daw.timestamp * 1000));

        return {
          ...daw,
          normalizedAmount,
          printableAmount,
          normalizedValue,
          printableValue,
          tokenSymbol: "USD",
          readableTimestamp: dateString
        };
      });
    }
    return [];
  }
);

// InsuranceFund losses covered
export const insuranceFundLossesCoveredLoaded = state => get(state, "insuranceFundLossesCovered.loaded");
export const insuranceFundLossesCoveredLoadedSelector = createSelector(insuranceFundLossesCoveredLoaded, loaded => loaded);

export const insuranceFundLossesCoveredData = state => get(state, "insuranceFundLossesCovered.data");
export const insuranceFundLossesCoveredSelector = createSelector(
  insuranceFundLossesCoveredData,
  insuranceFundLossesCoveredLoaded,
  tokensLoaded,
  tokens,
  selectedTokenPair,
  (insuranceFundLossesCoveredData, insuranceFundLossesCoveredLoaded, tokensLoaded, tokens, selectedTokenPair) => {
    if (insuranceFundLossesCoveredLoaded && tokensLoaded && selectedTokenPair && selectedTokenPair.underlyingTokenPrice) {
      const normalizedUnderlyingPrice = formatUnits(selectedTokenPair.underlyingTokenPrice, 18);
      return insuranceFundLossesCoveredData.map(daw => {
        const normalizedAmount = parseFloat(formatUnits(daw.amount.toString(), 18));
        const printableAmount = parseFloat(normalizedAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        const underlyingTokenAddress = tokens.find(token => token.symbol === "ETH").address;
        const normalizedValue =
          daw.token === underlyingTokenAddress ? parseFloat(normalizedAmount) * parseFloat(normalizedUnderlyingPrice) : parseFloat(normalizedAmount);
        const printableValue = parseFloat(normalizedValue).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });

        const dateString = getDateString(new Date(daw.timestamp * 1000));

        return {
          ...daw,
          normalizedAmount,
          printableAmount,
          normalizedValue,
          printableValue,
          tokenSymbol: "USD",
          readableTimestamp: dateString
        };
      });
    }
    return [];
  }
);

export const insurerDataLoadedSelector = createSelector(
  insuranceFundDepositsAndWithdrawalsLoaded,
  insuranceFundLoaded,
  (insuranceFundDepositsAndWithdrawalsLoaded, insuranceFundLoaded) => {
    return insuranceFundDepositsAndWithdrawalsLoaded && insuranceFundLoaded;
  }
);
// returns insurer principal, proceeds, and pnl
export const insurerDataSelector = createSelector(
  insuranceFundDepositsAndWithdrawalsData,
  insuranceFundDepositsAndWithdrawalsLoaded,
  insuranceFundLoaded,
  insuranceFundData,
  web3account,
  web3accountLoaded,
  (
    insuranceFundDepositsAndWithdrawalsData,
    insuranceFundDepositsAndWithdrawalsLoaded,
    insuranceFundLoaded,
    insuranceFundData,
    web3account,
    web3accountLoaded
  ) => {
    if (insuranceFundDepositsAndWithdrawalsLoaded && insuranceFundLoaded && web3accountLoaded) {
      let balance = 0;

      // filter for user
      insuranceFundDepositsAndWithdrawalsData = insuranceFundDepositsAndWithdrawalsData.filter(daw => daw.user === web3account);

      // sort deposits and withdrawals by timestamp, oldest first
      insuranceFundDepositsAndWithdrawalsData.sort((a, b) => {
        return parseInt(a.timestamp) - parseInt(b.timestamp);
      });

      insuranceFundDepositsAndWithdrawalsData.forEach(daw => {
        balance += (daw.amount / 1e18) * (daw.isDeposit ? 1 : -1);
        balance = Math.max(0, balance);
      });

      const { insurerBalance, unclaimedInsurerBalance } = insuranceFundData;
      const proceeds = insurerBalance + unclaimedInsurerBalance;
      const pnl = proceeds - balance;

      return {
        principal: balance,
        proceeds: proceeds,
        pnl: pnl
      };
    }

    return {
      principal: 0,
      proceeds: 0,
      pnl: 0
    };
  }
);
