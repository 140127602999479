import { formatUnits } from "@ethersproject/units";
import { get, groupBy, union } from "lodash";
import { createSelector } from "reselect";
import { formatValue, printPriceFromNumber } from "../../utils/utils.js";
import { tokens } from "./tokensSelectors";
import { BigNumber } from "@ethersproject/bignumber";
import { web3account } from "./web3Selectors";

export const marginPoolDataLoaded = state => get(state, "marginPool.loaded", false);
export const marginPoolDataLoadedSelector = createSelector(marginPoolDataLoaded, loaded => loaded);
const BORROW_INDEX_MANTISSA = 10 ** 12;
const MANTISSA = 10 ** 18;

export const marginPoolData = state => get(state, "marginPool.data", []);
export const marginPoolDataSelector = createSelector([marginPoolData, tokens, marginPoolDataLoaded], (marginPoolData, tokens, marginPoolDataLoaded) => {
  let startTime = new Date().getTime();
  let totalCurrentSupply = 0;
  let totalBorrowedAmount = 0;
  let totalLendingInterestRate = 0;
  let totalBorrowInterestRate = 0;
  if (marginPoolDataLoaded) {
    const tokensData = marginPoolData.map(data => {
      // symbol
      const symbol = tokens.find(asset => asset.address === data.assetAddress).symbol;
      // borrowed amount
      const normalizedBorrowedAmount = parseFloat(formatUnits(data.borrowedAmount, 18));
      const readableBorrowedAmount = Number(normalizedBorrowedAmount).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      // lend supply
      let normalizedCurrentSupply = parseFloat(formatUnits(data.currentSupply, 18));
      normalizedCurrentSupply += normalizedBorrowedAmount;
      const readableLendSupply = Number(normalizedCurrentSupply).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      // borrow interest rate
      const normalizedBorrowInterestRate = readableBorrowedAmount === "0" ? 0 : parseFloat(formatUnits(data.interestRate, 18));
      const readableBorrowInterestRate =
        Number(normalizedBorrowInterestRate * 100).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + "%";
      // lending interest rate
      const normalizedLendingInterestRate =
        readableBorrowedAmount === "0" ? 0 : (normalizedBorrowInterestRate * normalizedBorrowedAmount) / normalizedCurrentSupply;
      const readableLendingInterestRate =
        Number(normalizedLendingInterestRate * 100).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + "%";
      // total amounts
      const currentSupply = parseFloat(formatUnits(data.price.toString(), 18)) * normalizedCurrentSupply;
      const borrowedAmount = parseFloat(formatUnits(data.price.toString(), 18)) * normalizedBorrowedAmount;
      totalCurrentSupply += currentSupply;
      totalBorrowedAmount += borrowedAmount;
      // total interest rates
      totalLendingInterestRate += normalizedLendingInterestRate * currentSupply;
      totalBorrowInterestRate += normalizedBorrowInterestRate * borrowedAmount;

      return {
        ...data,
        symbol,
        normalizedCurrentSupply,
        normalizedBorrowedAmount,
        normalizedBorrowInterestRate,
        normalizedLendingInterestRate,
        readableBorrowInterestRate,
        readableLendingInterestRate,
        readableLendSupply,
        readableBorrowedAmount
      };
    });
    totalLendingInterestRate /= totalCurrentSupply;
    totalBorrowInterestRate /= totalBorrowedAmount;
    const readableTotalLendingInterestRate = Number((totalLendingInterestRate * 100).toFixed(2)).toLocaleString() + "%";
    const readableTotalBorrowInterestRate = Number((totalBorrowInterestRate * 100).toFixed(2)).toLocaleString() + "%";
    const readableTotalCurrentSupply = (+totalCurrentSupply.toFixed(2)).toLocaleString();
    const readableTotalBorrowedAmount = (+totalBorrowedAmount.toFixed(2)).toLocaleString();
    let endTime = new Date().getTime();
    // console.log("Selector: marginPoolDataSelector took ", endTime - startTime, "ms");
    return { tokensData, readableTotalBorrowedAmount, readableTotalCurrentSupply, readableTotalLendingInterestRate, readableTotalBorrowInterestRate };
  }
  return null;
});

export const marginAccountDataLoaded = state => get(state, "marginAccount.loaded", false);
export const marginAccountDataLoadedSelector = createSelector(marginAccountDataLoaded, loaded => loaded);

const marginAccountData = state => get(state, "marginAccount.data", {});
export const marginAccountDataSelector = createSelector(
  [marginAccountData, tokens, marginAccountDataLoaded],
  (marginAccountData, tokens, marginAccountDataLoaded) => {
    if (marginAccountDataLoaded) {
      let startTime = new Date().getTime();
      const decoratedMarginAccountData = { ...marginAccountData };
      decoratedMarginAccountData.collateralTokens = decoratedMarginAccountData.collateralTokens.map(data => {
        // symbol
        let symbol = "";
        const token = tokens.find(asset => asset.address === data.assetAddress);
        if (token) {
          symbol = token.symbol;
        }
        // debt
        let normalizedBorrowedAmount = -parseFloat(formatUnits(data.borrowedAmount.toString(), 18));
        normalizedBorrowedAmount === 0 && (normalizedBorrowedAmount = 0);
        // const readableBorrowedAmount = Number(normalizedBorrowedAmount.toFixed(2)).toLocaleString();
        const readableBorrowedAmount = formatValue(normalizedBorrowedAmount, 2);
        // debt in reference token
        const normalizedDebtInReferenceToken = parseFloat(formatUnits(data.price.toString(), 18)) * normalizedBorrowedAmount;
        let readableDebtInReferenceToken = formatValue(normalizedDebtInReferenceToken, 2);
        if (readableDebtInReferenceToken === "-0") {
          readableDebtInReferenceToken = "0";
        }
        // lend proceeds
        const normalizedLendProceeds = parseFloat(formatUnits(data.lendProceeds.toString(), 18));
        const readableLendProceeds = formatValue(normalizedLendProceeds, 2);
        // balance in reference token
        const normalizedValueInReferenceToken = parseFloat(formatUnits(data.price.toString(), 18)) * normalizedLendProceeds;
        let readableValueInReferenceToken = formatValue(normalizedValueInReferenceToken, 2);
        if (readableValueInReferenceToken === "-0") {
          readableValueInReferenceToken = "0";
        }

        return {
          ...data,
          symbol,
          normalizedBorrowedAmount,
          readableBorrowedAmount,
          normalizedDebtInReferenceToken,
          readableDebtInReferenceToken,
          normalizedLendProceeds,
          readableLendProceeds,
          normalizedValueInReferenceToken,
          readableValueInReferenceToken
        };
      });

      // total debt
      const normalizedTotalDebt = parseFloat(formatUnits(marginAccountData.totalDebt, 18));
      // const readableTotalDebt = Number(normalizedTotalDebt.toFixed(2)).toLocaleString();
      const readableTotalDebt = printPriceFromNumber(normalizedTotalDebt);

      const debt = normalizedTotalDebt;
      // health
      const normalizedHealth = parseFloat(formatUnits(marginAccountData.health, 18));
      const readableHealth =
        normalizedTotalDebt !== 0 ? (normalizedHealth < 10 ? Number((normalizedHealth * 100).toFixed(2)).toLocaleString() + "%" : "excellent") : "excellent";
      // liquidation threshold
      const normalizedLiquidationThreshold = parseFloat(formatUnits(marginAccountData.liquidationThreshold, 18));
      const readableLiquidationThreshold = Number((normalizedLiquidationThreshold * 100).toFixed(2)).toLocaleString() + "%";
      // liquidation threshold
      const normalizedRecoveryThreshold = parseFloat(formatUnits(marginAccountData.recoveryThreshold, 18));
      const readableRecoveryThreshold = Number((normalizedRecoveryThreshold * 100).toFixed(2)).toLocaleString() + "%";
      // net liquidity
      const normalizedTotalLiquidity = parseFloat(formatUnits(marginAccountData.totalLiquidity, 18));
      const normalizedNetLiquidity = normalizedTotalLiquidity - normalizedTotalDebt;
      // const readableNetLiquidity = Number(normalizedNetLiquidity.toFixed(2)).toLocaleString();
      const readableNetLiquidity = printPriceFromNumber(normalizedNetLiquidity);

      // excess liquidity
      const normalizedLiquidityAtRecovery = normalizedTotalDebt * normalizedRecoveryThreshold;
      const normalizedLiquidityAtLiquidation = normalizedTotalDebt * normalizedLiquidationThreshold;
      const normalizedExcessLiquidity = Math.max(0, normalizedTotalLiquidity - normalizedLiquidityAtRecovery);
      // const readableExcessLiquidity = Number(normalizedExcessLiquidity.toFixed(2)).toLocaleString();
      const readableExcessLiquidity = printPriceFromNumber(normalizedExcessLiquidity);

      let endTime = new Date().getTime();
      // console.log("Selector: marginAccountDataSelector took ", endTime - startTime, "ms");
      return {
        ...decoratedMarginAccountData,
        normalizedTotalLiquidity,
        readableHealth,
        normalizedHealth: normalizedHealth * 100,
        readableLiquidationThreshold,
        readableRecoveryThreshold,
        readableTotalDebt,
        normalizedLiquidityAtRecovery,
        normalizedLiquidityAtLiquidation,
        readableExcessLiquidity,
        normalizedExcessLiquidity,
        normalizedNetLiquidity,
        readableNetLiquidity,
        debt
      };
    }
    return null;
  }
);

const lendEventsData = state => get(state, "marginEvents.lendEvents", []);
const redeemEventsData = state => get(state, "marginEvents.redeemEvents", []);
const borrowEventsData = state => get(state, "marginEvents.borrowEvents", []);
const repaidEventsData = state => get(state, "marginEvents.repaidEvents", []);

export const lendPremiumsAndBorrowBasesSelector = createSelector(
  [lendEventsData, redeemEventsData, borrowEventsData, repaidEventsData, tokens, web3account],
  (lendEventsData, redeemEventsData, borrowEventsData, repaidEventsData, tokens, account) => {
    let collateralTokens = getCollateralTokens(redeemEventsData, lendEventsData, repaidEventsData, borrowEventsData);
    // eslint-disable-next-line no-undef
    let premiumsAndBases = new Map();
    let bnZero = BigNumber.from("0");
    borrowEventsData = borrowEventsData.filter(data => data.borrower === account);
    collateralTokens.forEach(token => {
      let redeemEvents = redeemEventsData.filter(event => event.assetAddress === token);
      let repaidEvents = repaidEventsData.filter(event => event.assetAddress === token);
      let lendEvents = lendEventsData.filter(event => event.assetAddress === token);
      let borrowEvents = borrowEventsData.filter(event => event.assetAddress === token);
      let premium = bnZero;
      let lastRedeemOrRepaidTimestamp = bnZero;
      redeemEvents.forEach(redeem => {
        let currentTimestamp = BigNumber.from(redeem.timestamp.toString());
        if (currentTimestamp.eq(lastRedeemOrRepaidTimestamp)) {
          if (BigNumber.from(redeem.premium.toString()).lt(premium.abs())) {
            lastRedeemOrRepaidTimestamp = currentTimestamp;
            premium = BigNumber.from(redeem.premium);
          }
        }
        if (currentTimestamp.gt(lastRedeemOrRepaidTimestamp)) {
          lastRedeemOrRepaidTimestamp = currentTimestamp;
          premium = BigNumber.from(redeem.premium);
        }
      });
      repaidEvents.forEach(repaid => {
        let currentTimestamp = BigNumber.from(repaid.timestamp.toString());
        if (currentTimestamp.eq(lastRedeemOrRepaidTimestamp)) {
          if (BigNumber.from(repaid.base.toString()).lt(premium.abs())) {
            lastRedeemOrRepaidTimestamp = currentTimestamp;
            premium = BigNumber.from("-" + repaid.base);
          }
        }
        if (currentTimestamp.gt(lastRedeemOrRepaidTimestamp)) {
          lastRedeemOrRepaidTimestamp = currentTimestamp;
          premium = BigNumber.from("-" + repaid.base);
        }
      });
      lendEvents = lendEvents.filter(lend => {
        return BigNumber.from(lend.timestamp.toString()).gte(lastRedeemOrRepaidTimestamp);
      });
      lendEvents.forEach(lend => {
        premium = premium.add(BigNumber.from(lend.amount));
      });
      borrowEvents = borrowEvents.filter(borrow => {
        return BigNumber.from(borrow.timestamp.toString()).gte(lastRedeemOrRepaidTimestamp);
      });
      borrowEvents.forEach(borrow => {
        premium = premium.sub(BigNumber.from(borrow.amount));
      });
      const rawPremium = premium.gt(bnZero) ? premium.toString() : "0";
      const normalizedPremium = parseFloat(formatUnits(rawPremium.toString(), 18));
      const readablePremium = formatValue(normalizedPremium, 2);
      let rawBase = premium.lt(bnZero) ? premium.toString() : "0";
      const normalizedBase = parseFloat(formatUnits(rawBase.toString(), 18));
      const readableBase = formatValue(normalizedBase, 2);
      let matchingToken = tokens.find(tn => tn.address === token);
      let symbol = "";
      if (matchingToken) {
        symbol = matchingToken.symbol;
      }
      premiumsAndBases.set(symbol, {
        rawPremium: rawPremium,
        readablePremium,
        rawBase: rawBase,
        readableBase
      });
    });
    return premiumsAndBases;
  }
);

export const positionsBorrowFactorsSelector = createSelector([borrowEventsData], borrowEventsData => {
  let bnZero = BigNumber.from("0");
  // eslint-disable-next-line no-undef
  let borrowIndicesPerMarkets = new Map();
  let marketBorrowPositions = groupBy(borrowEventsData, borrowEvent => `"${borrowEvent.marketId}${borrowEvent.isLPPosition}${borrowEvent.positionId}"`);
  let keys = Object.keys(marketBorrowPositions);
  keys.forEach(key => {
    let borrowFactor = bnZero;
    let totalAmount = bnZero;
    let market = marketBorrowPositions[key][0].marketId;
    let isLPPosition = marketBorrowPositions[key][0].isLPPosition;
    let positionId = marketBorrowPositions[key][0].positionId;
    marketBorrowPositions[key].forEach(borrowPosition => {
      let currentBorrowFactor = BigNumber.from(borrowPosition.amount.toString())
        .mul(BigNumber.from(BORROW_INDEX_MANTISSA.toString()))
        .div(BigNumber.from(borrowPosition.borrowIndex.toString()));
      borrowFactor = borrowFactor.add(currentBorrowFactor);
      totalAmount = totalAmount.add(BigNumber.from(borrowPosition.amount.toString()));
    });
    if (totalAmount > 0) {
      borrowFactor = borrowFactor.mul(BigNumber.from(MANTISSA.toString())).div(totalAmount);
      borrowIndicesPerMarkets.set(market + isLPPosition + positionId, borrowFactor);
    } else {
      borrowIndicesPerMarkets.set(market + isLPPosition + positionId, bnZero);
    }
  });
  return borrowIndicesPerMarkets;
});

const getCollateralTokens = (redeemEventsData, lendEventsData, repaidEventsData, borrowEventsData) => {
  let redeemCollateralTokens = Object.keys(groupBy(redeemEventsData, "assetAddress"));
  let lendCollateralTokens = Object.keys(groupBy(lendEventsData, "assetAddress"));
  let repaidCollateralTokens = Object.keys(groupBy(repaidEventsData, "assetAddress"));
  let borrowCollateralTokens = Object.keys(groupBy(borrowEventsData, "assetAddress"));
  return union(redeemCollateralTokens, lendCollateralTokens, repaidCollateralTokens, borrowCollateralTokens);
};
