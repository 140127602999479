import * as types from "../actionTypes.js";

export const errors = (state = [], action) => {
  switch (action.type) {
    case types.ERROR_ADDED:
      return [...state, action.error];
    case types.ERROR_REMOVED:
      return state.filter(error => error.id !== action.errorId);
    case types.ERRORS_REMOVED:
      return [];
    default:
      return state;
  }
};
