import * as types from "../actionTypes.js";

export const trades = (state = { loaded: false, data: [] }, action) => {
  switch (action.type) {
    // case types.LOADING_TRADES:
    //   return { ...state, loaded: false };
    case types.TRADES_LOADED:
      return { ...state, loaded: true, data: action.trades };
    case types.TRADE_ADDED:
      // NOTE: never use unionWith, it's slow, it takes 10000x more time than find
      // heres the code to prove it with 8000 trades (after 2 weeks)
      // if (true) {
      //   let startTime1 = new Date().getTime();
      //   const sampleData = unionWith(state.data, [action.newTrade], isEqual);
      //   let endTime1 = new Date().getTime();
      //   console.log("Time taken to unionWith: ", endTime1 - startTime1);

      //   let startTime2 = new Date().getTime();
      //   if (!state.data.find(trade => trade.signature === action.newTrade.signature)) {
      //     const sampleData = [...state.data, action.newTrade];
      //   }
      //   let endTime2 = new Date().getTime();
      //   console.log("Time taken to find: ", endTime2 - startTime2);
      // }
      // return { ...state, data: unionWith(state.data, [action.newTrade], isEqual) };
      if (state.data.find(trade => trade.signature === action.newTrade.signature)) {
        return state;
      } else {
        return { ...state, data: [...state.data, action.newTrade] };
      }
    default:
      return state;
  }
};

export const lpTrades = (state = { loaded: false, data: [] }, action) => {
  switch (action.type) {
    // case types.LOADING_LP_TRADES:
    //   return { ...state, loaded: false };
    case types.LP_TRADES_LOADED:
      return { ...state, loaded: true, data: action.trades };
    case types.LP_TRADE_ADDED:
      if (state.data.find(trade => trade.signature === action.newTrade.signature)) {
        return state;
      } else {
        return { ...state, data: [...state.data, action.newTrade] };
      }
    default:
      return state;
  }
};

// account liquidation trades (penalties and rewards)
export const liquidationTrades = (state = { loaded: false, data: [] }, action) => {
  switch (action.type) {
    case types.LIQUIDATION_TRADES_LOADED:
      return { ...state, loaded: true, data: action.trades };
    case types.LIQUIDATION_TRADE_ADDED:
      if (state.data.find(trade => trade.signature === action.newTrade.signature)) {
        return state;
      } else {
        return { ...state, data: [...state.data, action.newTrade] };
      }
    default:
      return state;
  }
};
