import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import MyModal from "./utilities/MyModal.jsx";

class UnlockWalletModal extends React.Component {
  render() {
    return (
      <MyModal isOpen={this.props.isOpen} isUnclosable>
        <div
          id="unlockWalletModal"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <div className="unlockWalletModalContent">
            <span className="unlockWalletText">Please unlock your wallet</span>
            <FontAwesomeIcon icon={faUnlockAlt} color="white" size="4x" />
            <span className="unlockWalletText">You may need to click the extension.</span>
          </div>
        </div>
      </MyModal>
    );
  }
}

UnlockWalletModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default UnlockWalletModal;
