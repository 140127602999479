import { faCopy, faExternalLinkAlt, faUnlink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jazzicon from "@metamask/jazzicon";
import parse from "html-react-parser";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Button } from "reactstrap";
import MyModal from "./utilities/MyModal.jsx";

const AccountModal = ({ isConnectModalOpened, toggle, account, networkName, disconnect, connect, web3Modal, setConnectModalOpen }) => {
  useEffect(() => {
    const modalDialog = document.querySelector(".modal-dialog");

    if (modalDialog) {
      $(".modal-black").draggable({
        handle: ".modal-header"
      });
    }
    document.onkeydown = onKeyPress;

    return () => {
      document.onkeydown = null;
    };
  }, []);

  const onKeyPress = e => {
    if (e && e.key === "Escape") {
      toggle();
    }
  };

  const onViewInExplorerClick = () => {
    const subdomain = networkName === "Sepolia" ? "sepolia." : ""; //check if networkName is prop or state
    window.open("https://" + subdomain + "etherscan.io/address/" + account, "_blank");
    setConnectModalOpen(false);
    document.querySelector("body").classList.toggle("scrollDisabled");
  };

  const onDisconnectClick = () => {
    setConnectModalOpen(false);
    disconnect();
    connect(web3Modal, false);
    document.querySelector("body").classList.toggle("scrollDisabled");
  };

  const copyToClipboard = () => {
    let dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use textarea. setAttribute('value', value), which works with "input" does not work with "textarea".
    dummy.value = account.toString();
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  const jazzIconSmall = () => {
    const addr = account && account.slice(2, 10);
    const seed = parseInt(addr, 16);
    let icon = jazzicon(32, seed);

    // with html parser
    return parse(icon.outerHTML);
  };

  return (
    <MyModal isOpen={isConnectModalOpened} toggle={() => toggle(isConnectModalOpened, setConnectModalOpen)}>
      <div
        className="accountModal"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div className="accountModalHeader">
          <button aria-hidden data-dismiss="modal" type="button" className="closeModal" onClick={() => toggle()}>
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
        <div className="accountCloseButtonContainer">
          <span className="accountModalText">Connected using Metamask</span>
        </div>
        {/* <span style={{position: 'absolute', top: '0px', right: '0px', color: 'red'}}>x</span> */}
        <div className="accountModalAddress">
          {account ? jazzIconSmall : null}
          {account ? <span>{account}</span> : null}
        </div>
        <div className="accountModalActionsContainer">
          <Button id={"view-on-explorer"} className="btn-simple accountActionButton" size="sm" onClick={() => copyToClipboard()}>
            <FontAwesomeIcon icon={faCopy} />
            {" Copy Address"}
          </Button>

          <Button id={"copy" + account} className="btn-simple accountActionButton" size="sm" onClick={() => onViewInExplorerClick()}>
            <FontAwesomeIcon icon={faExternalLinkAlt} />
            {" View on Explorer"}
          </Button>

          <Button id="change-provider" className="btn-simple accountActionButton" onClick={() => onDisconnectClick()}>
            <FontAwesomeIcon icon={faUnlink} />
            {" Disconnect"}
          </Button>
        </div>
      </div>
    </MyModal>
  );
};

export default AccountModal;

AccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  providerName: PropTypes.string.isRequired,
  accountLoaded: PropTypes.bool,
  isConnectModalOpened: PropTypes.bool,
  account: PropTypes.string,
  networkName: PropTypes.string.isRequired,
  disconnect: PropTypes.func.isRequired,
  connect: PropTypes.func.isRequired,
  web3Modal: PropTypes.object,
  setConnectModalOpen: PropTypes.func
};
