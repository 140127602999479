import * as types from "../actionTypes.js";

export const dateUtils = (state = { loaded: false }, action) => {
  switch (action.type) {
    case types.EXPIRATION_TIMES_LOADED:
      return { ...state, loaded: true, expirationTimes: { data: action.expirationTimes } };
    case types.EXPIRATION_TIME_SELECTED:
      return { ...state, selectedExpirationTime: action.expirationTime };
    default:
      return state;
  }
};
