import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers/rootReducer";

const middleware = [];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(preloadedState) {
  return createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(...middleware)));
}

export const store = configureStore();
