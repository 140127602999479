import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MainRouter from "./layouts/market/MainRouter.jsx";
import "./assets/css/nucleo-icons.css";
import "./assets/scss/black-dashboard-pro-react.scss";
import "./assets/demo/demo.css";
import "react-notification-alert/dist/animate.css";
import ReactGA from "react-ga4";

class App extends Component {
  constructor() {
    super();
    this.state = {
      metamaskInstalled: false
    };
  }

  componentDidMount() {
    ReactGA.initialize("G-5FVTMMLVKN");
    window.Intercom("boot", {
      app_id: "sif8dxay"
    });
  }

  render() {
    return (
      <>
        <MainRouter />
      </>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default connect()(App);
