import React, { createContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BlueNavbar from "../../components/Navbars/BlueNavbar.jsx";
import TradeLayout from "../../components/BlueDexCustom/TradeLayout.jsx";
import PoolLayout from "../../components/BlueDexCustom/PoolLayout.jsx";
import LendLayout from "../../components/BlueDexCustom/LendLayout.jsx";
import ErrorBoundary from "../../components/Error/ErrorBoundary.jsx";
import BreakingErrorModal from "../../components/BlueDexCustom/BreakingErrorModal.jsx";
import { BrowserRouter as Router, Route, Navigate, Routes } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import { hasErrorsSelector, errorsSelector } from "../../store/selectors/errorsSelectors";
import { handleErrorRemoval, clearErrors } from "../../store/interactions/errorInteractions";
import { hasNotificationsSelector, notificationsSelector } from "../../store/selectors/notificationsSelectors";
import { handleNotificationRemoval } from "../../store/interactions/notificationInteractions";
import CustomFooter from "../../components/BlueDexCustom/CustomFooter.jsx";
import Portfolio from "../../components/BlueDexCustom/Portfolio.jsx";
import Liquidations from "../../components/BlueDexCustom/Liquidations.jsx";
import SettlementsPage from "../../components/BlueDexCustom/SettlementsPage.jsx";
import InsuranceFund from "../../components/BlueDexCustom/InsuranceFund.jsx";

let ps;

export const FilterContext = createContext();
export const ChartContext = createContext();

class MainRouter extends React.Component {
  constructor() {
    super();
    this.state = {
      showBreakingErrorModal: false,
      breakingErrorMessage: "",
      defaultUrl: "/trade/eth-usd/daily",

      longShortDropmenuOpened: false,
      callPutDropmenuOpened: false,
      expirationTimesDropmenuOpened: false,

      expirationTimesHistoryDropmenuOpened: false,

      strikePricesDropmenuOpened: false,
      isCogPositionsModalOpened: false,
      isCogMarketsModalOpened: false,
      isConnectModalOpened: false,

      notificationsFrameOpen: false,
      underlyingChartIsInFocus: false,

      selectedExpirationTime: { timestamp: "daily", sidebarFormat: "Daily Expirations", urlFormat: "daily" }
    };
    this.mainPanelRef = React.createRef();
    this.errorRef = React.createRef();
    this.notificationRef = React.createRef();
    this.displayErrorNotifications = this.displayErrorNotifications.bind(this);
    this.displayErrorBoundaryNotification = this.displayErrorBoundaryNotification.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.displayNotifications = this.displayNotifications.bind(this);
  }

  componentDidMount() {
    document.body.classList.toggle("sidebar-mini");
    document.body.addEventListener("keydown", e => e.key === "Enter" && e.preventDefault());
    document.documentElement.classList.remove("nav-open");
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.mainPanelRef.current);
    }
    window.addEventListener("scroll", this.showNavbarButton);

    const mailLinkContainer = document.querySelector(".mailLinkContainer");
    const telegramLinkContainer = document.querySelector(".telegramLinkContainer");
    const twitterLinkContainer = document.querySelector(".twitterLinkContainer");
    const discordLinkContainer = document.querySelector(".discordLinkContainer");

    mailLinkContainer.addEventListener("mouseenter", e => {
      mailLinkContainer.children[0].classList.add("mailImgHover");
      mailLinkContainer.children[1].classList.add("footerLinkHover");
    });

    mailLinkContainer.addEventListener("mouseleave", e => {
      mailLinkContainer.children[0].classList.remove("mailImgHover");
      mailLinkContainer.children[1].classList.remove("footerLinkHover");
    });

    telegramLinkContainer.addEventListener("mouseenter", e => {
      telegramLinkContainer.children[0].classList.add("telegramImgHover");
      telegramLinkContainer.children[1].classList.add("footerLinkHover");
    });

    telegramLinkContainer.addEventListener("mouseleave", e => {
      telegramLinkContainer.children[0].classList.remove("telegramImgHover");
      telegramLinkContainer.children[1].classList.remove("footerLinkHover");
    });

    twitterLinkContainer.addEventListener("mouseenter", e => {
      twitterLinkContainer.children[0].classList.add("twitterImgHover");
      twitterLinkContainer.children[1].classList.add("footerLinkHover");
    });

    twitterLinkContainer.addEventListener("mouseleave", e => {
      twitterLinkContainer.children[0].classList.remove("twitterImgHover");
      twitterLinkContainer.children[1].classList.remove("footerLinkHover");
    });

    discordLinkContainer.addEventListener("mouseenter", e => {
      discordLinkContainer.children[0].classList.add("discordImgHover");
      discordLinkContainer.children[1].classList.add("footerLinkHover");
    });

    discordLinkContainer.addEventListener("mouseleave", e => {
      discordLinkContainer.children[0].classList.remove("discordImgHover");
      discordLinkContainer.children[1].classList.remove("footerLinkHover");
    });
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
    window.removeEventListener("scroll", this.showNavbarButton);
  }

  displayErrorNotifications = () => {
    this.props.errors.forEach(err => {
      let options = {
        place: "tr",
        message: err.severity > 1 ? err.message : err.message.toUpperCase(),
        type: "danger",
        icon: "tim-icons icon-alert-circle-exc",
        autoDismiss: err.severity > 1 ? 7 : -1
      };
      this.errorRef.current.notificationAlert(options);
      handleErrorRemoval(err.id, this.props.dispatch);
    });
  };

  displayErrorBoundaryNotification = () => {
    this.setState({
      showBreakingErrorModal: true,
      breakingErrorMessage: "Please reload the application."
    });
  };

  handleErrors = () => {
    const breakingError = this.props.errors.find(err => err.isBreaking);
    if (breakingError) {
      this.setState({
        showBreakingErrorModal: true,
        breakingErrorMessage: breakingError.message
      });
      clearErrors(this.props.dispatch);
    } else {
      this.displayErrorNotifications();
    }
  };

  displayNotifications = () => {
    this.props.notifications.forEach(note => {
      let options = {
        place: "tr",
        message: note.message,
        type: "info",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7
      };
      this.notificationRef.current.notificationAlert(options);
      handleNotificationRemoval(note.id, this.props.dispatch);
    });
  };

  setDropmenuOpened = (dropmenuType, opened) => {
    this.setState({ [dropmenuType]: opened });
  };

  setCogPositionsModalOpen = opened => {
    this.setState({ isCogPositionsModalOpened: opened });
  };

  setCogMarketsModalOpen = opened => {
    this.setState({ isCogMarketsModalOpened: opened });
  };

  setConnectModalOpen = opened => {
    this.setState({ isConnectModalOpened: opened });
  };

  setNotificationsFrameOpen = opened => {
    this.setState({ notificationsFrameOpen: opened });
  };

  setUnderlyingChartIsInFocus = focused => {
    this.setState({ underlyingChartIsInFocus: focused });
  };

  setSelectedExpirationTime = expirationTime => {
    this.setState({ selectedExpirationTime: expirationTime });
  };

  closeAllFilterDropmenus = (clickedFilterDropmenuArrow = null) => {
    if (!window.location.href.includes("lend") && !window.location.href.includes("portfolio")) {
      document.body.classList.remove("scrollDisabled");
    }

    const chartWidgetOverlay = document.querySelector(".chartWidgetOverlay");
    if (chartWidgetOverlay) chartWidgetOverlay.classList.remove("displayNone");

    // Array.from(document.getElementsByClassName("transformArrow")).forEach(el => el.classList.remove("transformArrow"));
    Array.from(document.getElementsByClassName("transformArrow")).forEach(el => {
      if (el !== clickedFilterDropmenuArrow) {
        el.classList.remove("transformArrow");
      }
    });
    this.state.longShortDropmenuOpened && this.setState({ longShortDropmenuOpened: false });
    this.state.callPutDropmenuOpened && this.setState({ callPutDropmenuOpened: false });
    this.state.expirationTimesDropmenuOpened && this.setState({ expirationTimesDropmenuOpened: false });
    this.state.expirationTimesHistoryDropmenuOpened && this.setState({ expirationTimesHistoryDropmenuOpened: false });
    this.state.strikePricesDropmenuOpened && this.setState({ strikePricesDropmenuOpened: false });
    this.state.isCogPositionsModalOpened && this.setState({ isCogPositionsModalOpened: false });
    this.state.isCogMarketsModalOpened && this.setState({ isCogMarketsModalOpened: false });
    this.state.isConnectModalOpened && this.setState({ isConnectModalOpened: false });
    this.state.notificationsFrameOpen && this.setState({ notificationsFrameOpen: false });
    this.state.underlyingChartIsInFocus && this.setState({ underlyingChartIsInFocus: false });
  };

  render() {
    const filterContextState = {
      longShortDropmenuOpened: this.state.longShortDropmenuOpened,
      callPutDropmenuOpened: this.state.callPutDropmenuOpened,
      expirationTimesDropmenuOpened: this.state.expirationTimesDropmenuOpened,

      expirationTimesHistoryDropmenuOpened: this.state.expirationTimesHistoryDropmenuOpened,

      strikePricesDropmenuOpened: this.state.strikePricesDropmenuOpened,
      isCogPositionsModalOpened: this.state.isCogPositionsModalOpened,
      isCogMarketsModalOpened: this.state.isCogMarketsModalOpened,
      isConnectModalOpened: this.state.isConnectModalOpened,
      notificationsFrameOpen: this.state.notificationsFrameOpen,
      underlyingChartIsInFocus: this.state.underlyingChartIsInFocus,
      selectedExpirationTime: this.state.selectedExpirationTime,
      setDropmenuOpened: this.setDropmenuOpened,
      closeAllFilterDropmenus: this.closeAllFilterDropmenus,
      setCogPositionsModalOpen: this.setCogPositionsModalOpen,
      setCogMarketsModalOpen: this.setCogMarketsModalOpen,
      setConnectModalOpen: this.setConnectModalOpen,
      setNotificationsFrameOpen: this.setNotificationsFrameOpen,
      setUnderlyingChartIsInFocus: this.setUnderlyingChartIsInFocus,
      setSelectedExpirationTime: this.setSelectedExpirationTime
    };

    return (
      <ErrorBoundary handleError={this.displayErrorBoundaryNotification}>
        <Router>
          <FilterContext.Provider value={filterContextState}>
            <div
              className="wrapper"
              onClick={e => {
                e.stopPropagation();
                filterContextState.closeAllFilterDropmenus();
              }}
            >
              <BreakingErrorModal isOpen={this.state.showBreakingErrorModal} message={this.state.breakingErrorMessage} />
              <div className="rna-container" onClick={e => e.stopPropagation()}>
                <NotificationAlert ref={this.errorRef} />
                <NotificationAlert ref={this.notificationRef} />
              </div>
              {this.props.hasErrors ? this.handleErrors() : <div />}
              {this.props.hasNotifications ? this.displayNotifications() : <div />}
              <div className="main-panel" ref={this.mainPanelRef} data="blue">
                <BlueNavbar />
                <Routes>
                  <Route strict exact path="trade/:pair/:time" element={<TradeLayout />} />
                  <Route strict exact path="/pool/:pair/:time" element={<PoolLayout />} />
                  <Route strict exact path="/portfolio" element={<Portfolio />} />
                  <Route strict exact path="/lend" element={<LendLayout />} />
                  <Route strict exact path="/liquidations" element={<Liquidations />} />
                  <Route strict exact path="/settlements" element={<SettlementsPage />} />
                  <Route strict exact path="/insurance" element={<InsuranceFund />} />
                  <Route path="/*" element={<Navigate to={this.state.defaultUrl} replace />} />
                </Routes>
                <CustomFooter />
              </div>
            </div>
          </FilterContext.Provider>
        </Router>
      </ErrorBoundary>
    );
  }
}

MainRouter.propTypes = {
  hasErrors: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  notifications: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasNotifications: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    hasErrors: hasErrorsSelector(state),
    errors: errorsSelector(state),
    hasNotifications: hasNotificationsSelector(state),
    notifications: notificationsSelector(state)
  };
}

export default connect(mapStateToProps)(MainRouter);
