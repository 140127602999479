import { handleNewError } from "./errorInteractions";
import { expirationTimesLoaded } from "../actions/actions.js";
import { setIntervalAsync } from "set-interval-async/fixed";

let expirationTimesGlobal, periodicalExpirationTimesUpdater;
const TEN_MINUTES = 600000;

export const loadExpirationTimes = async (dateUtils, dispatch) => {
  try {
    console.log("RPC method: getExpirationTimes");
    let expirationTimes = await dateUtils.methods.getExpirationTimes(true).call();
    expirationTimes = expirationTimes.filter(time => time > 0);
    dispatch(expirationTimesLoaded(expirationTimes));

    expirationTimesGlobal = JSON.parse(JSON.stringify(expirationTimes));
    periodicallyUpdateExpirationTimes(dateUtils, dispatch);
  } catch (e) {
    handleNewError({}, "Error while fetching expiration times. Please reload the application!", 1, dispatch);
  }
};

export const periodicallyUpdateExpirationTimes = async (dateUtils, dispatch) => {
  try {
    // start fetching periodically if not already started
    if (periodicalExpirationTimesUpdater == null) {
      periodicalExpirationTimesUpdater = setIntervalAsync(async () => {
        // update expiration times if enough time passed
        console.log("RPC method: getExpirationTimes");
        let newExpirationTimes = await dateUtils.methods.getExpirationTimes(true).call();
        newExpirationTimes = newExpirationTimes.filter(time => time > 0);
        let plainExpirationTimesGlobal = expirationTimesGlobal.map(expirationTime => expirationTime.timestamp);
        if (JSON.stringify(plainExpirationTimesGlobal) !== JSON.stringify(newExpirationTimes)) {
          expirationTimesGlobal = JSON.parse(JSON.stringify(newExpirationTimes));
          dispatch(expirationTimesLoaded(newExpirationTimes));
        }
      }, TEN_MINUTES); // check new expiration times every 10 minutes
    }
  } catch (e) {
    handleNewError(e, "There was an error win periodical fetcher!", 2, dispatch);
  }
};
