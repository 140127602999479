const getParamFooter = (rows, param) => {
  let total = 0;
  for (let row of rows.data) {
    total += row[param][0];
  }
  return total;
};

export const getValueFooter = rows => {
  return getParamFooter(rows, "value");
};

export const getDeltaFooter = rows => {
  return getParamFooter(rows, "delta");
};

export const getGammaFooter = rows => {
  return getParamFooter(rows, "gamma");
};

export const getThetaFooter = rows => {
  return getParamFooter(rows, "theta");
};

export const getVegaFooter = rows => {
  return getParamFooter(rows, "vega");
};

export const getPNLFooter = rows => {
  return getParamFooter(rows, "pnl");
};

export const getROIFooter = rows => {
  let invested = 0;
  for (let row of rows.data) {
    if (row.token != null) {
      invested += row.value[2]; // value on open
    }
  }
  if (invested > 0) {
    let totalPNL = getPNLFooter(rows);
    return (totalPNL / invested) * 100;
  }

  return 0;
};

export const getAPRFooter = rows => {
  // find position that is most recent
  let daysSinceStartMin = 1000000000;
  for (let row of rows.data) {
    daysSinceStartMin = Math.min(daysSinceStartMin, row.apr[2]);
  }

  // calculate total profit and open value
  let totalProfit = 0;
  let totalOpenValue = 0;
  for (let row of rows.data) {
    const profit = row.pnl[0];
    const daysSinceStart = row.apr[2];
    const youngestProfit = profit * (daysSinceStartMin / daysSinceStart);
    totalProfit += youngestProfit;
    totalOpenValue += row.value[2];
  }

  // at the end, calculate apr using most recent position's days since start
  return (totalProfit / totalOpenValue / daysSinceStartMin) * 365 * 100;
};

export const getAPYFooter = rows => {
  const apr = getAPRFooter(rows);
  return (Math.pow(1 + apr / 100 / 365, 365) - 1) * 100;
};
