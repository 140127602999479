import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectedTokenPairSelector, avgUnderlyingTokenPriceSelector, tokenBalancesSelector } from "../../store/selectors/tokensSelectors";
import { marketsSelector } from "../../store/selectors/marketsSelectors";
import { openedLPPositionsSelector, openedTradePositionsSelector } from "../../store/selectors/positionsManagerSelectors";
import {
  marginAccountDataLoadedSelector,
  marginAccountDataSelector,
  marginPoolDataLoadedSelector,
  marginPoolDataSelector
} from "../../store/selectors/marginSelectors";
import { healthClassName, printApr, printPriceFromString } from "../../utils/utils.js";
import { getHighestDaysToExpiryRounded, getLiquidationPrice } from "../../utils/chartsUtils";
import { interestRateModelSelector } from "../../store/selectors/contractsSelectors";
import { fromBn } from "evm-bn";

class TotalLiquidityLegend extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.marginPoolDataLoaded &&
      this.props.marginAccountDataLoaded &&
      printApr(this.props.marginAccountDataLoaded, this.props.marginPoolDataLoaded, this.props.marginAccountData, this.props.marginPoolData);
  }

  componentDidUpdate() {
    this.props.marginPoolDataLoaded &&
      this.props.marginAccountDataLoaded &&
      printApr(this.props.marginAccountDataLoaded, this.props.marginPoolDataLoaded, this.props.marginAccountData, this.props.marginPoolData);
  }

  hasPosition = () => {
    return (
      (this.props.openedTradePositions && this.props.openedTradePositions.length > 0) ||
      (this.props.openedLPPositions && this.props.openedLPPositions.length > 0)
    );
  };

  render() {
    const style = { top: this.props.top };
    this.props.left ? (style.left = this.props.left) : (style.right = this.props.right);

    if (!this.props.marginAccountDataLoaded) {
      return null;
    }

    const health = this.props.marginAccountData.normalizedHealth;
    const recoveryThreshold = this.props.marginAccountData ? parseFloat(fromBn(this.props.marginAccountData.recoveryThreshold)) * 100 : 0;
    const liquidationThreshold = this.props.marginAccountData ? parseFloat(fromBn(this.props.marginAccountData.liquidationThreshold)) * 100 : 0;

    const healthColorClass = this.props.marginAccountDataLoaded
      ? healthClassName(this.props.marginAccountData.readableHealth, health, recoveryThreshold, liquidationThreshold)
      : "";

    // liquidation price
    let liqPriceStr = "-";

    if (this.hasPosition() && this.props.underlyingTokenPrice) {
      const assetsBase = this.props.tokenBalances.find(t => t.symbol === "USD").normalizedAccountBalance;
      const assetsUnder = this.props.tokenBalances.find(t => t.symbol === "ETH").normalizedAccountBalance;
      //const total = this.props.marginAccountData.normalizedTotalLiquidity;
      // TODO: v1 check next 2 lines with Kosta (move math where we can test it)
      const debtBase = -this.props.marginAccountData.collateralTokens.find(data => data.symbol === "USD").normalizedBorrowedAmount;
      const debtUnder = -this.props.marginAccountData.collateralTokens.find(data => data.symbol === "ETH").normalizedBorrowedAmount;
      const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
      const tradePositions = this.props.openedTradePositions ? this.props.openedTradePositions : [];
      const lpPositions = this.props.openedLPPositions ? this.props.openedLPPositions : [];
      const allPositions = [...tradePositions, ...lpPositions];
      const market = this.props.markets.find(market => {
        return market.marketId === allPositions[0].marketId;
      });
      const daysToExpiration = getHighestDaysToExpiryRounded(allPositions, this.props.markets);
      const volatility = allPositions.length === 1 ? Math.round(parseFloat(market.longPriceInVol)) : 0;

      // liquidation price
      const baseBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "USD").normalizedBorrowInterestRate;
      const underBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "ETH").normalizedBorrowInterestRate;
      const borrowRates = { baseBorrowRate, underBorrowRate };
      const liqPrices = getLiquidationPrice(
        allPositions,
        this.props.markets,
        underPrice,
        daysToExpiration,
        parseFloat(volatility),
        this.props.selectedTokenPair.riskFreeRate / 100,
        assetsBase,
        assetsUnder,
        debtBase,
        debtUnder,
        borrowRates
      );

      // NOTE: there can be 2 liquidation prices, lower and upper

      // format lower liq price
      if (liqPrices.lowerLiqPrice > 1) {
        liqPriceStr = printPriceFromString(liqPrices.lowerLiqPrice.toFixed(2));
      }

      // if there is upper liq price that is on chart, add it to the string
      if (liqPrices.maxX - liqPrices.upperLiqPrice > 1) {
        if (liqPriceStr !== "-") {
          liqPriceStr += ", " + printPriceFromString(liqPrices.upperLiqPrice.toFixed(2));
        } else {
          liqPriceStr = printPriceFromString(liqPrices.upperLiqPrice.toFixed(2));
        }
      }
    }

    const apr = printApr(this.props.marginAccountDataLoaded, this.props.marginPoolDataLoaded, this.props.marginAccountData, this.props.marginPoolData);
    const aprClassName = typeof apr === "string" ? (apr.includes("-") ? "redAprColor" : "greenAprColor") : "";

    return (
      <div
        className="healthLegendModalContainer"
        style={style}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div className="portfolioLegendInformationContainer">
          <div className="portfolioLegendInformation">
            <span className="portfolioLegendInformationLabel">Net liquidity</span>
            <span className="portfolioLegendInformationValue">{this.props.marginAccountData.readableNetLiquidity}</span>
          </div>
          <div className="portfolioLegendInformation">
            <span className="portfolioLegendInformationLabel">Excess liquidity</span>
            <span className="portfolioLegendInformationValue">{this.props.marginAccountData.readableExcessLiquidity}</span>
          </div>
          <div className="portfolioLegendInformation">
            <span className="portfolioLegendInformationLabel">Liquidation price</span>
            <span className="portfolioLegendInformationValue">{liqPriceStr}</span>
          </div>
          <div className="portfolioLegendInformation">
            <span className="portfolioLegendInformationLabel">Borrow rate</span>
            <span className={`portfolioLegendInformationValue ${aprClassName}`}>{apr}</span>
          </div>
          <div className="portfolioLegendInformation">
            <span className="portfolioLegendInformationLabel">Health</span>
            <span className={`portfolioLegendInformationValue ${healthColorClass}`}>{this.props.marginAccountData.readableHealth}</span>
          </div>
        </div>

        <div className="healthLegendItemsContainer">
          <div className="healthLegendItem">
            <img className="greenHealthIcon" />
            <span className="healthLegendItemText">
              above {this.props.marginAccountData.readableRecoveryThreshold}, can perform all actions that don’t get the account into the yellow
            </span>
          </div>
          <div className="healthLegendItem">
            <img className="yellowHealthIcon" />
            <span className="healthLegendItemText">
              between {this.props.marginAccountData.readableLiquidationThreshold} and {this.props.marginAccountData.readableRecoveryThreshold}, can only perform
              actions that increase the account health
            </span>
          </div>
          <div className="healthLegendItem">
            <img className="redHealthIcon" />
            <span className="healthLegendItemText">below {this.props.marginAccountData.readableLiquidationThreshold}, eligible for liquidation</span>
          </div>
        </div>
      </div>
    );
  }
}

TotalLiquidityLegend.propTypes = {
  markets: PropTypes.array,
  openedTradePositions: PropTypes.array,
  openedLPPositions: PropTypes.array,
  marginAccountData: PropTypes.object,
  marginAccountDataLoaded: PropTypes.bool,
  interestRateModel: PropTypes.object,
  marginPoolData: PropTypes.object,
  marginPoolDataLoaded: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  top: PropTypes.object,
  left: PropTypes.object,
  right: PropTypes.object,
  underlyingTokenPrice: PropTypes.object,
  selectedTokenPair: PropTypes.object,
  tokenBalances: PropTypes.array
};

function mapStateToProps(state) {
  return {
    markets: marketsSelector(state),
    openedTradePositions: openedTradePositionsSelector(state),
    openedLPPositions: openedLPPositionsSelector(state),
    marginAccountData: marginAccountDataSelector(state),
    marginPoolData: marginPoolDataSelector(state),
    marginAccountDataLoaded: marginAccountDataLoadedSelector(state),
    marginPoolDataLoaded: marginPoolDataLoadedSelector(state),
    underlyingTokenPrice: avgUnderlyingTokenPriceSelector(state),
    selectedTokenPair: selectedTokenPairSelector(state),
    interestRateModel: interestRateModelSelector(state),
    tokenBalances: tokenBalancesSelector(state)
  };
}

export default connect(mapStateToProps)(TotalLiquidityLegend);
