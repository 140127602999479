import "jquery-ui-dist/jquery-ui";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadStrikePrices } from "../../store/interactions/strikePricesInteractions";
import { expirationTimesLoadedSelector, expirationTimesSelector } from "../../store/selectors/dateUtilsSelectors";
import { contractsLoadedSelector, marketFactorySelector, priceUtilsSelector } from "../../store/selectors/contractsSelectors";
import { selectedTokenPairSelector, avgUnderlyingTokenPriceSelector } from "../../store/selectors/tokensSelectors";
import { web3AccountSelector, web3Selector } from "../../store/selectors/web3Selectors";
import NewMarketDropdownMenu from "./NewMarketDropdownMenu.jsx";
import MyModal from "./utilities/MyModal.jsx";
import { marketsLoadedSelector, marketsSelector } from "../../store/selectors/marketsSelectors";
import { createMultipleMarkets } from "../../store/interactions/marketsInteractions";
import { toBn } from "evm-bn";
import PropTypes from "prop-types";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { findClosestMarketsToExpirationTime, findVolForClosestStrikePrice, formatValue, getFutureUnderlyingPrice, SECONDS_IN_YEAR } from "../../utils/utils.js";
import { cloneDeep } from "lodash";
import moment from "moment";
import greeks from "greeks";

const CreateMultipleMarketsModal = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();

  const [selectedPair, setSelectedPair] = useState("ETH");

  const [selectedExpirationTimeObject, setSelectedExpirationTimeObject] = useState(null);
  const [selectedExpirationTimeString, setSelectedExpirationTimeString] = useState("");
  const [strikePrices, setStrikePrices] = useState([]);
  const [expirationTimesDropmenuOpened, setExpirationTimesDropmenuOpened] = useState(false);
  const [strikePricesDropmenuOpened, setStrikePricesDropmenuOpened] = useState(false);
  const [top, setTop] = useState("0");
  const [left, setLeft] = useState("0");
  const [width, setWidth] = useState("0");
  const [selectedMarketsToCreate, setSelectedMarketsToCreate] = useState(new Map());

  const expirationTimesLoaded = useSelector(expirationTimesLoadedSelector);
  const expirationTimes = useSelector(expirationTimesSelector);
  const selectedTokenPair = useSelector(selectedTokenPairSelector);
  const underlyingTokenPrice = useSelector(avgUnderlyingTokenPriceSelector);
  const priceUtils = useSelector(priceUtilsSelector);
  const markets = useSelector(marketsSelector);
  const marketsLoaded = useSelector(marketsLoadedSelector);
  const contractsLoaded = useSelector(contractsLoadedSelector);
  const account = useSelector(web3AccountSelector);
  const marketFactory = useSelector(marketFactorySelector);
  const web3 = useSelector(web3Selector);

  useEffect(() => {
    document.onkeydown = onKeyPress;

    return () => {
      document.onkeydown = null;
    };
  }, []);

  useEffect(() => {
    if (expirationTimesLoaded) {
      setSelectedExpirationTimeString(expirationTimes[0].sidebarFormat);
      setSelectedExpirationTimeObject(expirationTimes[0]);
    }
  }, [expirationTimesLoaded, expirationTimes]);

  useEffect(() => {
    if (selectedExpirationTimeObject) {
      prepareStrikePrices().then(strikePricesForExpiration => {
        setStrikePrices(strikePricesForExpiration);
      });
    }
  }, [selectedExpirationTimeString, selectedExpirationTimeObject]);

  useEffect(() => {
    const underPrice = parseFloat(underlyingTokenPrice.normalized);
    const useEffectLogic = async () => {
      if (marketsLoaded && selectedExpirationTimeObject && strikePrices) {
        const selectedMarketsToCreateMap = new Map();
        let leftoverDeltas = [];

        const riskFreeRate = selectedTokenPair.riskFreeRate;
        const expTime = selectedExpirationTimeObject.timestamp;

        const allSettled = closestMarkets.every(market => market.isSettled);

        let iv;
        if (allSettled) {
          iv = 50;
        } else {
          iv = findVolForClosestStrikePrice(closestMarkets, underPrice);
        }
        strikePrices.forEach(sp => {
          let callMarket = markets.find(
            market => market.isCall && market.strikePrice == sp.normalized && market.expirationTime == selectedExpirationTimeObject.timestamp
          );
          let putMarket = markets.find(
            market => !market.isCall && market.strikePrice == sp.normalized && market.expirationTime == selectedExpirationTimeObject.timestamp
          );
          const strikePrice = Number(sp.printable);

          let currentTime = moment().unix();
          const timeToExpirationInYears = (expTime - currentTime) / SECONDS_IN_YEAR;

          let deltaCall = greeks.getDelta(underPrice, strikePrice, timeToExpirationInYears, iv, riskFreeRate / 100, "call");
          let deltaPut = greeks.getDelta(underPrice, strikePrice, timeToExpirationInYears, iv, riskFreeRate / 100, "put");

          if (!callMarket) {
            if (strikePrice == 0 || (deltaCall >= 0.03 && deltaCall <= 0.67)) {
              selectedMarketsToCreateMap.set(`call ${strikePrice}`, true);
              leftoverDeltas.push({ mapValue: `call ${strikePrice}`, delta: Math.abs(deltaCall) });
            } else {
              selectedMarketsToCreateMap.set(`call ${strikePrice}`, false);
            }
          }

          if (!putMarket) {
            if (Math.abs(deltaPut) >= 0.03 && Math.abs(deltaPut) <= 0.67) {
              selectedMarketsToCreateMap.set(`put ${strikePrice}`, true);
              leftoverDeltas.push({ mapValue: `put ${strikePrice}`, delta: Math.abs(deltaPut) });
            } else {
              selectedMarketsToCreateMap.set(`put ${strikePrice}`, false);
            }
          }
        });

        // if more than 16 markets, remove markets with lowest delta
        leftoverDeltas = leftoverDeltas.sort((a, b) => a.delta - b.delta);
        if (leftoverDeltas.length > 16) {
          leftoverDeltas = leftoverDeltas.slice(0, leftoverDeltas.length - 16);
        } else {
          leftoverDeltas = [];
        }
        leftoverDeltas.forEach(delta => {
          selectedMarketsToCreateMap.set(delta.mapValue, false);
        });

        setSelectedMarketsToCreate(selectedMarketsToCreateMap);
      }
    };
    useEffectLogic();

    const createMarketsTableBody = document.getElementById("createMarketsTable").children[1];
    let elementClosestIdx = -1;
    let smallestDiff = Infinity;
    Array.from(createMarketsTableBody.children).forEach((el, idx) => {
      const currentStrikePrice = Number(el.children[2].textContent.trim());
      const diff = Math.abs(currentStrikePrice - underPrice);
      if (elementClosestIdx === -1 || diff < smallestDiff) {
        smallestDiff = diff;
        elementClosestIdx = idx;
      }
    });
    const numOfPositionsToScroll = elementClosestIdx - 4;
    createMarketsTableBody.scrollTo(0, numOfPositionsToScroll * 42); //42px is size of one row
  }, [marketsLoaded, selectedExpirationTimeObject, strikePrices]);

  const onKeyPress = e => {
    if (e && e.key === "Escape") {
      toggle();
    }
  };

  const findMarket = useCallback(
    (baseTokenAddress, underlyingTokenAddress, strikePrice) => {
      if (selectedPair) {
        return markets.find(market => {
          return (
            market.baseTokenAddress === baseTokenAddress &&
            market.underlyingTokenAddress === underlyingTokenAddress &&
            market.expirationTime == selectedExpirationTimeObject.timestamp &&
            market.strikePrice.toString() === strikePrice.raw
          );
        });
      }

      return null;
    },
    [selectedPair, markets, selectedExpirationTimeObject]
  );

  const prepareStrikePrices = useCallback(async () => {
    let strikePrices = [];
    const baseTokenAddress = selectedTokenPair.baseTokenAddress;
    const underlyingTokenAddress = selectedTokenPair.underlyingTokenAddress;

    // todo: v2 load StrikePrices returns 3 values in array that are repeated for 28 AUG (today is 6 AUG)
    if (contractsLoaded && baseTokenAddress && underlyingTokenAddress && selectedExpirationTimeObject && selectedExpirationTimeObject.timestamp) {
      strikePrices = await loadStrikePrices(priceUtils, baseTokenAddress, underlyingTokenAddress, selectedExpirationTimeObject.timestamp, dispatch);

      if (!strikePrices) return [];

      // filter out existing markets with liquidity
      strikePrices = strikePrices.filter(strikePrice => {
        let market = findMarket(baseTokenAddress, underlyingTokenAddress, strikePrice);

        return !market;
      });
    }

    return strikePrices;
  }, [selectedTokenPair, selectedExpirationTimeObject, priceUtils, contractsLoaded]);

  const closeModal = useCallback(() => {
    toggle();
  }, [toggle]);

  const setNewExpirationTime = useCallback(
    expirationTime => {
      setSelectedExpirationTimeString(expirationTime);
      setSelectedExpirationTimeObject(expirationTimes.find(et => et.sidebarFormat === expirationTime));
    },
    [expirationTimes]
  );

  const createMultipleMarketsHandler = e => {
    e.stopPropagation();
    const isCalls = [];
    const strikePrices = [];
    for (let [key, selected] of selectedMarketsToCreate) {
      if (selected) {
        let [type, strikePrice] = key.split(" ");
        isCalls.push(type === "call");
        // handle 0 strike price (future)
        strikePrice = strikePrice === "0" ? "0.000001" : strikePrice;
        strikePrices.push(toBn(strikePrice, 18).toString());
      }
    }

    createMultipleMarkets(
      web3,
      account,
      marketFactory,
      isCalls,
      strikePrices,
      selectedExpirationTimeObject.timestamp,
      selectedTokenPair.baseTokenAddress,
      selectedTokenPair.underlyingTokenAddress,
      closeModal,
      dispatch
    );
  };
  const displayExpirationTimes = useCallback(e => {
    e.stopPropagation();
    const { top, left, width } = e.currentTarget.getBoundingClientRect();
    setTop(top + 32 + "px");
    setLeft(left + "px");
    setWidth(width + "px");
    setExpirationTimesDropmenuOpened(true);
    setStrikePricesDropmenuOpened(false);
  }, []);

  const closeExpirationTimesDropmenu = useCallback(() => {
    setExpirationTimesDropmenuOpened(false);
  }, []);

  const closeDropmenusIfTheyAreOpened = useCallback(() => {
    expirationTimesDropmenuOpened && setExpirationTimesDropmenuOpened(false);
    strikePricesDropmenuOpened && setStrikePricesDropmenuOpened(false);
  }, [expirationTimesDropmenuOpened, strikePricesDropmenuOpened]);

  const closestMarkets = useMemo(() => {
    if (marketsLoaded && selectedExpirationTimeObject) {
      const expTime = Number(selectedExpirationTimeObject.timestamp);
      return findClosestMarketsToExpirationTime(markets, expTime);
    }
    return [];
  }, [marketsLoaded, selectedExpirationTimeObject]);

  const onClickCheckboxHandler = e => {
    e.stopPropagation();
    const id = e.target.id;
    const [type, strikePrice] = id.split(" ");
    const mapKey = type + " " + strikePrice;
    setSelectedMarketsToCreate(prevMap => {
      const newMap = new Map(cloneDeep([...prevMap]));
      newMap.set(mapKey, !prevMap.get(mapKey));
      return newMap;
    });
  };

  const printTableRows = () => {
    if (!selectedExpirationTimeObject) return null;

    const underPrice = parseFloat(underlyingTokenPrice.normalized);
    const riskFreeRate = selectedTokenPair.riskFreeRate;
    const expTime = selectedExpirationTimeObject.timestamp;

    const allSettled = closestMarkets.every(market => market.isSettled);

    let iv;
    if (allSettled) {
      iv = 50;
    } else {
      iv = findVolForClosestStrikePrice(closestMarkets, underPrice);
    }

    let futurePrice = getFutureUnderlyingPrice(underPrice, riskFreeRate, expTime);
    return strikePrices.map(sp => {
      const isFuture = sp.normalized === "0.000001";

      let callMarket = markets.find(market => market.isCall && market.strikePrice == sp.normalized && market.expirationTime == expTime);
      let putMarket = markets.find(market => !market.isCall && market.strikePrice == sp.normalized && market.expirationTime == expTime);

      const printableStrikePrice = !isFuture ? sp.printable : "FUTURE";

      const strikePrice = Number(sp.printable);
      const shouldHaveStrikeBorderOnCall = futurePrice - Number(strikePrice) > 0;

      const callMarketAlreadyExists = !!callMarket;
      const putMarketAlreadyExists = !!putMarket || strikePrice < 0.0001;

      const callId = "call " + strikePrice;
      const putId = "put " + strikePrice;

      const callChecked = callMarketAlreadyExists || selectedMarketsToCreate.get(callId);
      const putChecked = putMarketAlreadyExists || selectedMarketsToCreate.get(putId);

      let currentTime = moment().unix();
      const timeToExpirationInYears = (expTime - currentTime) / SECONDS_IN_YEAR;

      let deltaCall = greeks.getDelta(underPrice, strikePrice, timeToExpirationInYears, iv, riskFreeRate / 100, "call");
      let deltaPut = greeks.getDelta(underPrice, strikePrice, timeToExpirationInYears, iv, riskFreeRate / 100, "put");

      deltaCall = formatValue(deltaCall, 2);
      deltaPut = formatValue(deltaPut, 2);

      const callDisabled = callMarketAlreadyExists || (getSelectedMarketsCount() === 16 && !callChecked);
      const putDisabled = putMarketAlreadyExists || (getSelectedMarketsCount() === 16 && !putChecked);

      return (
        <tr key={expTime + printableStrikePrice} className="market-row" style={{ display: "flex" }}>
          <td style={{ textAlign: "right", width: "20%", fontFamily: "Roboto, sans-serif, monospace", padding: "8px 5%" }}>{deltaCall}</td>
          <td
            className={`${shouldHaveStrikeBorderOnCall ? "marketColumnMultipleMarketsModalHasMarketRowColorCall" : "marketColumnMultipleMarketsModal"}`}
            style={{ width: "20%" }}
          >
            <FormGroup check>
              <Label check>
                <Input id={callId} name="calls" type="checkbox" checked={callChecked} disabled={callDisabled} onClick={onClickCheckboxHandler} />
                <span className={`form-check-sign${callDisabled ? " form-check-sign-disabled" : ""}`}>
                  <span className="check" />
                </span>
              </Label>
            </FormGroup>
          </td>
          <td
            className={`strikeColumnMultipleMarketsModal${shouldHaveStrikeBorderOnCall ? " strikeCallHasMarketBorder" : " strikePutHasMarketBorder"}`}
            style={{ width: "20%" }}
          >
            {printableStrikePrice}
          </td>
          {!isFuture && (
            <>
              <td
                className={`${!shouldHaveStrikeBorderOnCall ? "marketColumnMultipleMarketsModalHasMarketRowColorPut" : "marketColumnMultipleMarketsModal"}`}
                style={{ width: "20%" }}
              >
                <FormGroup check>
                  <Label check>
                    <Input id={putId} name="puts" type="checkbox" checked={putChecked} disabled={putDisabled} onClick={onClickCheckboxHandler} />
                    <span className={`form-check-sign${putDisabled ? " form-check-sign-disabled" : ""}`}>
                      <span className="check" />
                    </span>
                  </Label>
                </FormGroup>
              </td>
              <td style={{ textAlign: "right", width: "20%", fontFamily: "Roboto, sans-serif, monospace", padding: "8px 5%" }}>{deltaPut}</td>
            </>
          )}
        </tr>
      );
    });
  };

  const printMarketsTableSwitches = () => {
    return (
      <div className="marketsTableSwitchesContainer">
        <Row style={{ justifyContent: "center" }}>
          <div className="marketsTableSwitchesColumnTitle">
            <span>CALLS</span>
          </div>
          <div className="marketsTableSwitchesStrikeColumnTitle">
            <span>STRIKE</span>
          </div>
          <div className="marketsTableSwitchesColumnTitle">
            <span>PUTS</span>
          </div>
        </Row>
        <Row>
          <Col md="12" className="zeroPadding">
            <table id="createMarketsTable" className="table" style={{ width: "100%", tableLayout: "fixed", borderCollapse: "collapse" }}>
              <thead style={{ display: "block" }}>
                <tr style={{ display: "flex" }}>
                  <th scope="col" className="columnMultipleMarketsHeading" style={{ textAlign: "right", padding: "0 5%" }}>
                    &Delta; DELTA
                  </th>
                  <th scope="col" className="columnMultipleMarketsHeading" style={{ textAlign: "center", padding: "unset" }}>
                    CREATED
                  </th>
                  <th scope="col" className="strikeMultipleMarketsHeading" />
                  <th scope="col" className="columnMultipleMarketsHeading" style={{ textAlign: "center", padding: "unset" }}>
                    CREATED
                  </th>
                  <th scope="col" className="columnMultipleMarketsHeading" style={{ textAlign: "right", padding: "0 5%" }}>
                    &Delta; DELTA
                  </th>
                </tr>
              </thead>
              <tbody className="createMarketsModalTableBody" style={{ fontSize: "0.75rem", display: "block", maxHeight: "430px", overflowY: "auto" }}>
                {printTableRows()}
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    );
  };

  let createMulMarketsButtonShouldBeDisabled = true;
  for (let [, selected] of selectedMarketsToCreate) {
    if (selected) {
      createMulMarketsButtonShouldBeDisabled = false;
      break;
    }
  }

  const getSelectedMarketsCount = () => {
    const values = Array.from(selectedMarketsToCreate, ([key, value]) => value);
    let count = values.filter(value => value === true).length;
    return count;
  };

  return (
    <>
      {expirationTimesDropmenuOpened && (
        <NewMarketDropdownMenu
          containerType="expirationDateSelectedOption"
          data={expirationTimes.map(et => et.sidebarFormat)}
          searchParam={selectedExpirationTimeString}
          setSearchParam={setNewExpirationTime}
          top={top}
          left={left}
          width={width}
          closeDropmenu={closeExpirationTimesDropmenu}
        />
      )}

      <MyModal id="createMultipleMarketsModal" isOpen={isOpen} toggle={closeModal}>
        <div
          className="createMultipleMarketsModalContainer"
          onClick={e => {
            e.stopPropagation();
            closeDropmenusIfTheyAreOpened();
          }}
        >
          <div className="newMarketHeader">
            <span className="newMarketHeaderTitle">CREATE MARKETS</span>
            <button aria-hidden data-dismiss="modal" type="button" className="closeModal" onClick={() => toggle()}>
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>

          <div className="createMarketFormConntainer">
            <div className="createMarketFormLabelInputItem">
              <label className="createMarketFormLabel">Underlying/Base</label>
              <span className="underlyingBaseValue">ETH/USD</span>
            </div>
            <div className="createMarketFormLabelInputItem">
              <label className="createMarketFormLabel">Expiration Date</label>
              <div className="expirationDateStrikePriceSelect" onClick={displayExpirationTimes}>
                <span id="expirationDateSelectedOption" className="expirationDateStrikePriceSelectedOption">
                  {selectedExpirationTimeString}
                </span>
                <img className="createMarketModalArrowDownIcon" />
              </div>
            </div>
          </div>

          {printMarketsTableSwitches()}

          <button disabled={createMulMarketsButtonShouldBeDisabled} className="createMarketButton" onClick={createMultipleMarketsHandler}>
            CREATE MARKETS ({getSelectedMarketsCount() === 16 ? "16 MAX" : getSelectedMarketsCount()})
          </button>
        </div>
      </MyModal>
    </>
  );
};

CreateMultipleMarketsModal.propTypes = {
  // should option be displayed
  isOpen: PropTypes.bool,
  toggle: PropTypes.func
};

export default CreateMultipleMarketsModal;
