import * as types from "../actionTypes.js";

export const marketsImmutable = (state = { loaded: false, data: [] }, action) => {
  switch (action.type) {
    case types.MARKETS_LOADED:
      return { ...state, loaded: true, data: action.immutableAttributes };
    case types.MARKET_CREATED:
      if (state.data.find(market => market.marketId === action.newImmutableAttributes.marketId)) {
        return state;
      } else {
        return {
          ...state,
          data: [...state.data, action.newImmutableAttributes]
        };
      }
    default:
      return state;
  }
};

export const marketsMutable = (state = { loaded: false, data: [] }, action) => {
  switch (action.type) {
    case types.MARKETS_LOADED:
      return { ...state, loaded: true, data: action.mutableAttributes };
    case types.MARKET_CREATED:
      if (state.data.find(market => market.marketId === action.newMutableAttributes.marketId)) {
        return state;
      } else {
        return {
          ...state,
          data: [...state.data, action.newMutableAttributes]
        };
      }
    case types.MARKET_UPDATED:
      return {
        ...state,
        data: state.data.map(market => {
          if (market.marketId === action.mutableAttributes.marketId) {
            return {
              ...market,
              ...action.mutableAttributes
            };
          }
          return market;
        })
      };
    case types.MARKET_PRICES_UPDATED:
      return {
        ...state,
        data: state.data.map(market => {
          let updatedMarket = action.updatedMarkets.find(m => m.marketId === market.marketId);
          return {
            ...market,
            ...updatedMarket
          };
        })
      };
    default:
      return state;
  }
};
