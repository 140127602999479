import React from "react";
import { createPortal } from "react-dom";
import { connect } from "react-redux";
import { Card, CardBody } from "reactstrap";
import PropTypes from "prop-types";

class FutureRangeSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return createPortal(
      <div className="tokensSelectContainer" style={{ top: this.props.top, left: this.props.left, width: this.props.width }}>
        <Card>
          <CardBody>
            <table className="table">
              <tbody>
                {this.props.futureRanges &&
                  this.props.futureRanges
                    .filter(range => range.type !== this.props.selectedFutureRange.type)
                    .map(range => {
                      return (
                        <tr
                          key={range.type}
                          onClick={e => {
                            this.props.selectRange(range.type);
                          }}
                        >
                          <td className="tokenOption">{range.text}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </div>,
      document.getElementById("root")
    );
  }
}

FutureRangeSelect.propTypes = {
  top: PropTypes.object,
  left: PropTypes.object,
  width: PropTypes.object,
  futureRanges: PropTypes.object,
  selectedFutureRange: PropTypes.object,
  selectRange: PropTypes.func
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(FutureRangeSelect);
