/* eslint-disable prettier/prettier */
import { combineReducers } from "redux";
import { web3 } from "./web3Reducers";
import { contracts } from "./contractsReducers";
import { trades, lpTrades, liquidationTrades } from "./positionsManagerReducer";
import { dateUtils } from "./dateUtilsReducers";
import { tokenPairs, tokens, positionTokens, lpPositionTokens } from "./tokensReducers";
import { errors } from "./errorReducers";
// import { notifications } from "./notificationReducers";
import { marketsImmutable, marketsMutable } from "./marketsReducers";
import { settlements, depositsAndWithdrawals } from "./balancesReducers";
import { marginPool, marginAccount, marginEvents } from "./marginReducers";
import { newNotifications } from "./newNotificationReducer";
import { insuranceFund, insuranceFundDepositsAndWithdrawals, insuranceFundLossesCovered } from "./insuranceFundReducers";

const rootReducer = combineReducers({
  contracts,
  // platform data
  tokenPairs,

  marketsImmutable,
  marketsMutable,

  // user and platfrom data
  tokens, // all external tokens on the platform
  positionTokens, // long and short position tokens on the platform
  lpPositionTokens, // lp position tokens on the platform

  // user data
  trades, // only user long and short trades
  lpTrades, // only user lp trades
  liquidationTrades, // only user liquidation trades (penalty/reward for account liquidation)
  depositsAndWithdrawals,
  insuranceFundDepositsAndWithdrawals,

  // errors and notifications
  errors,
  // notifications,
  newNotifications,

  // contracts and web3
  web3,
  dateUtils,
  settlements,
  marginPool,
  marginAccount,
  marginEvents,

  // insurance fund
  insuranceFund,
  insuranceFundLossesCovered
});

export default rootReducer;
