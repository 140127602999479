import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";
import Web3Modal from "web3modal";
import Fortmatic from "fortmatic";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { displayLoadingIconSmall, toShortAddress } from "../../utils/utils.js";
import UnlockWalletModal from "./UnlockWalletModal.jsx";
import { loadWeb3, loadWeb3Account, unloadWeb3Account, subscribeToChainChange } from "../../store/interactions/web3Interactions";
import { web3LoadingSelector, web3LoadedSelector, web3AccountSelector, web3AccountLoadedSelector } from "../../store/selectors/web3Selectors";
import { setIntervalAsync, clearIntervalAsync } from "set-interval-async/fixed";
import { handleNewError } from "../../store/interactions/errorInteractions";
import jazzicon from "@metamask/jazzicon";
import parse from "html-react-parser";
import AccountModal from "./AccountModal.jsx";
import { init } from "../../store/interactions/rootInteractions";
import { FilterContext } from "../../layouts/market/MainRouter.jsx";
import MyTooltip from "./MyTooltip.jsx";
import PropTypes from "prop-types";
import { currentTimestampLoaded } from "../../store/actions/actions.js";
import { ARBITRUM_SEPOLIA_NETWORK_ID, BASE_SEPOLIA_NETWORK_ID, SEPOLIA_NETWORK_ID, BASE_MAINNET_NETWORK_ID } from "../../config.js";

export let providerToExport = null;

const ConnectButton = ({ setNotifications, setNetworkNameForEtherscan }) => {
  const [web3Modal, setWeb3Modal] = useState(null);
  const [providerName, setProviderName] = useState("");
  const [networkName, setNetworkName] = useState("");
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [isUnlockWalletModalOpen, setIsUnlockWalletModalOpen] = useState(false);
  const [periodicalChecker, setPeriodicalChecker] = useState(null);
  const [connectingToWeb3, setConnectingToWeb3] = useState(false);

  const web3Loading = useSelector(web3LoadingSelector);
  const web3Loaded = useSelector(web3LoadedSelector);
  const web3Account = useSelector(web3AccountSelector);
  const web3AccountLoaded = useSelector(web3AccountLoadedSelector);
  const web3AccountLoadedRef = useRef(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // NOTE: this is a hack because web3AccountLoaded not visible
    // in setIntervalAsync (and even not visible in setInterval)
    web3AccountLoadedRef.current = web3AccountLoaded;
  }, [web3AccountLoaded]);

  useEffect(() => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: "3844e118f17c465f9c3cd83175632660"
        }
      },
      fortmatic: {
        package: Fortmatic,
        options: {
          key: "pk_test_94E02AE62935C297"
        }
      }
    };

    let web3Modal = new Web3Modal({
      cacheProvider: true, // optional
      providerOptions, // required
      disableInjectedProvider: false // optional. For MetaMask / Brave / Opera.
    });

    // if user already connected, use that provider
    if (web3Modal.cachedProvider) {
      connectToProvider(web3Modal, true);
    }

    setWeb3Modal(web3Modal);
  }, []);

  const subscribeToEvents = provider => {
    // Subscribe to accounts change
    provider.on("accountsChanged", accounts => {
      // NOTE: if user locks Metamask, accounts[0] is undefined
      onAccountChanged(accounts[0], provider);
    });

    // Subscribe to provider disconnection
    provider.on("disconnect", () => {
      // console.log("Disconnected");
    });

    provider.on("connect", () => {
      // console.log("on connect");
    });
  };

  const connectToProvider = async (web3Modal, checkWalletUnlock) => {
    let provider;
    try {
      provider = await web3Modal.connect();
      providerToExport = provider;
      await refreshAccountData(provider, web3Modal);
      subscribeToEvents(provider);
    } catch (e) {
      // NOTE: web3Modal throws error when user closes wallet
      // selection modal, so this is the only way to catch that event
      if (checkWalletUnlock && e !== "Modal closed by user") {
        periodicallyCheckWalletUnlock(web3Modal);
        setIsUnlockWalletModalOpen(true);
      }
    }
  };

  const periodicallyCheckWalletUnlock = async web3Modal => {
    try {
      // start fetching periodically if not already started
      if (periodicalChecker === null) {
        // console.log("Starting UnlockWalletChecker..");
        let periodicalChecker = setIntervalAsync(async () => {
          //console.log("periodicallyCheckWalletUnlock", web3AccountLoadedGlobal, web3AccountLoaded, web3Account)
          // update prices for all registered token pairs
          // console.log("UnlockWalletChecker: checking..");
          if (!web3AccountLoadedRef.current) {
            try {
              let provider = await web3Modal.connect();
              await refreshAccountData(provider, web3Modal);
              subscribeToEvents(provider);
            } catch (e) {
              // eslint-disable-next-line no-console
              console.log(e);
            }
          } else {
            await clearIntervalAsync(periodicalChecker);
            setPeriodicalChecker(null);
          }
        }, 1000);
        setPeriodicalChecker(periodicalChecker);
      }
    } catch (e) {
      console.log(e);
      handleNewError(e, "There was an error while checking wallet unlock!", 2, dispatch);
    }
  };

  const onConnectClick = async (e, isConnectModalOpened, setConnectModalOpen) => {
    if (!web3AccountLoaded) {
      connectToProvider(web3Modal, true);
      document.querySelector("body").classList.toggle("scrollDisabled");
    } else {
      const modalIsOpen = !isConnectModalOpened;
      setConnectModalOpen(modalIsOpen);
      const right = window.innerWidth - e.target.getBoundingClientRect().right + "px";
      document.querySelector(":root").style.setProperty("--account-modal-right-position", right);
    }
  };

  const disconnectFromProvider = async () => {
    web3Modal.clearCachedProvider();
    await unloadWeb3Account(dispatch);
    setIsAccountModalOpen(false);
    setIsUnlockWalletModalOpen(false);
    setConnectingToWeb3(false);
    setNotifications([]);
  };

  const getNetworkName = chainID => {
    if (chainID === 1) {
      return "Mainnet";
    }

    if (chainID === 42) {
      return "Kovan";
    }

    if (chainID === 1337) {
      return "localhost";
    }

    if (chainID === SEPOLIA_NETWORK_ID) {
      return "Sepolia";
    }

    if (chainID === ARBITRUM_SEPOLIA_NETWORK_ID) {
      return "Arbitrum Sepolia";
    }

    if (chainID === BASE_SEPOLIA_NETWORK_ID) {
      return "Base Sepolia";
    }

    if (chainID === BASE_MAINNET_NETWORK_ID) {
      return "Base";
    }

    return "";
  };

  const onAccountChanged = async (account, provider) => {
    if (account && provider) {
      const web3 = new Web3(provider);
      const networkId = await web3.eth.net.getId();
      const { web3read, web3Events, web3Subs } = await loadWeb3(web3, networkId, dispatch);
      const userAccount = await loadWeb3Account(web3, dispatch);
      init(web3, web3read, web3Events, web3Subs, networkId, userAccount, dispatch);
      setIsUnlockWalletModalOpen(false);
    } else {
      await unloadWeb3Account(dispatch);
    }
    window.location.reload();
  };

  const refreshAccountData = async (provider, web3Modal) => {
    setConnectingToWeb3(true);
    // load web3 and stuff
    const web3 = new Web3(provider);
    const networkId = await web3.eth.net.getId();
    const { web3read, web3Events, web3Subs } = loadWeb3(web3, networkId, dispatch);
    const chainId = await web3.eth.getChainId();
    const networkName = getNetworkName(chainId);
    const providerName = web3Modal.providerController.injectedProvider.name;

    // load account from web3
    const userAccount = await loadWeb3Account(web3, dispatch);
    init(web3, web3read, web3Events, web3Subs, networkId, userAccount, dispatch);

    subscribeToChainChange();

    // update state after fetching blockchain data
    setProviderName(providerName);
    setNetworkName(networkName);
    setNetworkNameForEtherscan(networkName);
    setIsUnlockWalletModalOpen(false);
    setConnectingToWeb3(false);

    // load current timestamp but don't subscribe to it
    dispatch(currentTimestampLoaded((await web3.eth.getBlock("latest")).timestamp));
  };

  const toggleAccountModal = (setConnectModalOpen, isConnectModalOpened) => {
    document.querySelector("body").classList.toggle("scrollDisabled");
    setConnectModalOpen(!isConnectModalOpened);
  };

  const toggleUnlockWalletModal = () => {
    setIsUnlockWalletModalOpen(!isUnlockWalletModalOpen);
  };

  const printJazzIconSmall = account => {
    const addr = account.slice(2, 10);
    const seed = parseInt(addr, 16);
    let icon = jazzicon(16, seed);

    // with html parser
    return parse(icon.outerHTML);
  };

  const printButtonText = () => {
    if (web3Loading || connectingToWeb3 || (web3Loaded && !web3AccountLoaded)) {
      return displayLoadingIconSmall();
    }

    if (web3AccountLoaded) {
      return (
        <div>
          {printJazzIconSmall(web3Account)}
          &nbsp;&nbsp;&nbsp;
          {toShortAddress(web3Account)}
        </div>
      );
    } else {
      return (
        <div>
          <img className="connectButtonImg" /> &nbsp; Connect
        </div>
      );
    }
  };

  const tooltip = web3AccountLoaded ? "Manage connected account" : "Connect your Ethereum wallet";

  return (
    <FilterContext.Consumer>
      {({ closeAllFilterDropmenus, isConnectModalOpened, setConnectModalOpen }) => {
        return (
          <div>
            {isConnectModalOpened ? (
              <AccountModal
                isOpen={isAccountModalOpen}
                isConnectModalOpened={isConnectModalOpened}
                toggle={() => toggleAccountModal(setConnectModalOpen, isConnectModalOpened)}
                providerName={providerName}
                accountLoaded={web3AccountLoaded}
                account={web3Account}
                networkName={networkName}
                disconnect={disconnectFromProvider}
                connect={connectToProvider}
                web3Modal={web3Modal}
                setConnectModalOpen={setConnectModalOpen}
              />
            ) : null}
            <UnlockWalletModal isOpen={isUnlockWalletModalOpen} toggle={toggleUnlockWalletModal} />
            <button
              id="connect-button"
              className="connectButton"
              onClick={e => {
                e.stopPropagation();
                closeAllFilterDropmenus();
                document.querySelector("body").classList.add("scrollDisabled");
                onConnectClick(e, isConnectModalOpened, setConnectModalOpen);
              }}
            >
              {printButtonText()}
            </button>
            <MyTooltip key="connect-button" target="connect-button">
              {tooltip}
            </MyTooltip>
          </div>
        );
      }}
    </FilterContext.Consumer>
  );
};

ConnectButton.propTypes = {
  setNotifications: PropTypes.func,
  setNetworkNameForEtherscan: PropTypes.func
};

export default ConnectButton;
