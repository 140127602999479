export const getRollingAPR = (daws, trades, lpTrades, liquidationTrades, lossesCovered, underPrice) => {
  console.log("getRollingAPR start");

  let avgApr = 0;

  try {
    const step = 7;
    const minPeriod = 86400; // a day
    const period = minPeriod * step; // 7 days
    const endTime = Math.floor(Date.now() / 1000);
    const startTime = endTime - period;

    // filter out all daws and trades that out of period
    const dawsInPeriod = daws.filter(daw => parseInt(daw.timestamp) >= startTime);
    const tradesInPeriod = trades.filter(trade => parseInt(trade.timestamp) >= startTime);
    const lpLiquidationsInPeriod = lpTrades.filter(trade => parseInt(trade.timestamp) >= startTime && trade.liquidator);
    const accountLiquidationsInPeriod = liquidationTrades.filter(trade => parseInt(trade.timestamp) >= startTime && trade.isBuy);
    const lossesCoveredInPeriod = lossesCovered.filter(trade => parseInt(trade.timestamp) >= startTime);

    // console.log("lpTrades", lpTrades);
    // console.log("accountLiquidationsInPeriod", accountLiquidationsInPeriod);
    // console.log("lossesCoveredInPeriod", lossesCoveredInPeriod);

    // console.log("daws", daws, "dawsInPeriod", dawsInPeriod);
    // console.log("trades", trades, "tradesInPeriod", tradesInPeriod);

    // calculate start TVL
    let startTVL = 0.001;
    const dawsBeforePeriod = daws.filter(daw => parseInt(daw.timestamp) < startTime);
    for (const daw of dawsBeforePeriod) {
      startTVL += daw.normalizedAmount * (daw.isDeposit ? 1 : -1);
    }

    // console.log("startTVL", startTVL);

    // main loop
    let lastTVL = startTVL;
    const aprs = [];
    for (let i = 0; i < step; i++) {
      const currStartTime = startTime + i * minPeriod;
      const currEndTime = currStartTime + minPeriod;

      const dawsInCurrentPeriod = dawsInPeriod.filter(daw => parseInt(daw.timestamp) >= currStartTime && parseInt(daw.timestamp) < currEndTime);
      const tradesInCurrentPeriod = tradesInPeriod.filter(t => parseInt(t.timestamp) >= currStartTime && parseInt(t.timestamp) < currEndTime);
      const lpLiquidationsInCurrentPeriod = lpLiquidationsInPeriod.filter(t => parseInt(t.timestamp) >= currStartTime && parseInt(t.timestamp) < currEndTime);
      const accountLiquidationsInCurrentPeriod = accountLiquidationsInPeriod.filter(
        t => parseInt(t.timestamp) >= currStartTime && parseInt(t.timestamp) < currEndTime
      );
      const losesCoveredInCurrentPeriod = lossesCoveredInPeriod.filter(t => parseInt(t.timestamp) >= currStartTime && parseInt(t.timestamp) < currEndTime);


      // sum up all daws in current period
      for (const daw of dawsInCurrentPeriod) {
        lastTVL += daw.normalizedAmount * (daw.isDeposit ? 1 : -1);
      }

      let profit = 0;

      // sum up all profits for option and futures trades in current period
      for (const trade of tradesInCurrentPeriod) {
        const isFuture = trade.marketId > 100000000000;
        if (isFuture) {
          // 0.25% fee and half of it goes to InsuranceFund
          profit += trade.size * trade.basePrice * 0.0025 * 0.5;
        } else {
          // option fee and 1/10 of it goes to InsuranceFund
          // todo: underPrice is current underPrice, so it's not accurate
          profit += trade.size * underPrice * 0.001 * 0.1;
        }
      }

      // sum up all profits from lp liquidations in current period
      profit += 150 * lpLiquidationsInCurrentPeriod.length;

      // sum up all profits for account liquidations in current period
      for (const trade of accountLiquidationsInCurrentPeriod) {
        // todo: not always 5% fee, could be less?? not sure
        profit += trade.baseAmount * (5 / 110);
      }

      // sum up all covered loses in current period
      for (const loss of losesCoveredInCurrentPeriod) {
        profit -= loss.normalizedAmount;
      }

      console.log("    day:", i + 1, "profit", profit.toFixed(2), "TVL:", lastTVL.toFixed(2));

      const apr = (profit / lastTVL) * 365;
      aprs.push(apr * 100);
    }

    console.log("aprs", aprs);

    // calculate avg apr
    avgApr = aprs.reduce((acc, apr) => acc + apr, 0) / aprs.length;
    console.log("avgApr", avgApr.toFixed(2) + "%");
  } catch (e) {
    console.log(e);
  }

  return avgApr;
};
