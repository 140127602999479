import * as types from "../actionTypes.js";

export const contracts = (state = { loaded: false, data: {} }, action) => {
  switch (action.type) {
    case types.ALL_CONTRACTS_LOADED:
      return { ...state, loaded: true, data: action.contracts };
    default:
      return state;
  }
};
