import React from "react";
import { useNavigate } from "react-router";

const CustomFooter = () => {
  const navigate = useNavigate();

  const addBaseSepoliaNetwork = async e => {
    // try switching to existing network (Sepolia is in wallet already)
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x14A34" }] // 84532
      });
      return;
    } catch (error) {
      console.log("There was an error switching to Base Sepolia testnet in your wallet. Trying to add Base Sepolia testnet..");
    }

    // if that doesn't work, try adding new network
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x14A34", // 84532
            rpcUrls: ["https://base-sepolia-rpc.publicnode.com"],
            chainName: "Base Sepolia",
            nativeCurrency: {
              name: "ETH",
              symbol: "ETH",
              decimals: 18
            },
            blockExplorerUrls: ["https://base-sepolia.blockscout.com/"]
          }
        ]
      });
    } catch (error) {
      console.log("There was an error adding Base Sepolia testnet in your wallet.");
      console.log(error);
    }
  };

  const onFooterClickHandler = e => {
    e.preventDefault();
    const urlElements = e.target.href.split("/");
    const pageToNavigate = urlElements[urlElements.length - 1];
    window.scrollTo({ top: 0 });
    navigate(`/${pageToNavigate}`);
  };

  return (
    <>
      <div className="borderFooterGray" />
      <div className="borderFooterBlack" />
      <div id="footer">
        <div className="companyInfo">
          <span className="weAre">We Are</span>
          <span className="gammaOptionsLtd">GAMMA OPTIONS LTD</span>
          <span className="britishVirginIslands">British Virgin Islands</span>
        </div>
        <div className="linksContainer">
          <div className="linkContainer">
            <span className="groupLinkTitle">SOCIALS</span>

            <div className="mailLinkContainer">
              <img className="mailImg" />
              <a href="https://blog.gammaoptions.com/p/unlock-early-access-to-private-launch" target="_blank" className="footerLink" rel="noreferrer">
                Subscribe to Updates
              </a>
            </div>

            <div className="mailLinkContainer">
              <img className="mailImg" />
              <a className="footerLink">connect@gammaoptions.com</a>
            </div>

            <div className="linksToSocialMedia">
              <div className="twitterLinkContainer">
                <img className="twitterImg" />
                <a href="https://twitter.com/GammaOptions" target="_blank" className="footerLink" rel="noreferrer">
                  Twitter
                </a>
              </div>
              <div className="telegramLinkContainer">
                <img className="telegramImg" />
                <a className="footerLink">Telegram</a>
              </div>

              <div className="discordLinkContainer">
                <img className="discordImg" />
                <a className="footerLink">Discord</a>
              </div>
            </div>
          </div>
          <div className="linkContainer">
            <span className="groupLinkTitle">SERVICES</span>
            <a className="footerLink" onClick={onFooterClickHandler} href="/trade">
              Trade
            </a>
            <a className="footerLink" onClick={onFooterClickHandler} href="/pool/eth-usd/daily">
              Pool
            </a>
            <a className="footerLink" onClick={onFooterClickHandler} href="/portfolio">
              Deposit & Withdraw
            </a>
            <a className="footerLink" onClick={onFooterClickHandler} href="/lend">
              Lend
            </a>
            <a className="footerLink" onClick={onFooterClickHandler} href="/insurance">
              Insurance
            </a>
            <a className="footerLink" onClick={onFooterClickHandler} href="/liquidations">
              Liquidations
            </a>
            <a className="footerLink" onClick={onFooterClickHandler} href="/settlements">
              Settlements
            </a>
            <div className="discordLinkContainer">
              <a className="footerLink" onClick={addBaseSepoliaNetwork}>
                Connect to Base Sepolia
              </a>
            </div>
          </div>
          <div className="linkContainer">
            <span className="groupLinkTitle">DOCS</span>
            <a href="https://doc.gammaoptions.com/docs/" target="_blank" className="footerLink" rel="noreferrer">
              Documentation
            </a>
            <a className="footerLink">White Paper</a>
          </div>
          <div className="linkContainer">
            <span className="groupLinkTitle">TERMS</span>
            <a href="https://gammaoptions.com/terms-and-conditions" target="_blank" className="footerLink" rel="noreferrer">
              Terms & Conditions
            </a>
            <a href="https://gammaoptions.com/privacy-policy" target="_blank" className="footerLink" rel="noreferrer">
              Privacy Policy
            </a>
          </div>
        </div>
        <div className="copyRightsContainer">
          <div className="borderFooterGray" />
          <div className="borderFooterBlack" />
          <div className="copyRights">2024 &copy; By Gamma Options Ltd., v0.9.21.0</div>
        </div>
      </div>
    </>
  );
};

export default CustomFooter;
