import { formatUnits } from "@ethersproject/units";
import { Promise } from "bluebird";

const sleep = ms => new Promise(r => setTimeout(r, ms));

export const getTotalAndDebtForAccount = async (loadHelper, marginPool, userBalances, underPriceBN, account) => {
  // NEW CODE
  console.log("RPC method: loadAccountsLiquidationData");
  const result = await loadHelper.methods.loadAccountsLiquidationData(marginPool._address, userBalances._address, [account], underPriceBN).call();

  const totalDebt = parseFloat(formatUnits(result[0].totalDebt, 18));
  const netValue = parseFloat(formatUnits(result[0].totalLiquidity, 18));
  const borrowedDebtEthAmount = parseFloat(formatUnits(result[0].borrowedUnder, 18));
  const borrowedDebtBaseAmount = parseFloat(formatUnits(result[0].borrowedBase, 18));
  const usdBalance = parseFloat(formatUnits(result[0].baseBalance, 18));
  const ethBalance = parseFloat(formatUnits(result[0].underBalance, 18));

  return { account, debt: totalDebt, total: netValue, borrowedDebtEthAmount, borrowedDebtBaseAmount, usdBalance, ethBalance };
};

export const getLPPositionsLiquidationData = async (loadHelper, positionsData, marketMap, underPriceBN) => {
  // NOTE: batching is not faster on localhost ganache, but should be tested on live (less requests)
  let positionsBatch = [];
  const chunkSize = 20; // around 8 million gas, don't increase
  for (let j = 0; j < positionsData.length; j += chunkSize) {
    positionsBatch.push(positionsData.slice(j, j + chunkSize));
  }

  let array4 = [];
  for (let i = 0; i < positionsBatch.length; i++) {
    const positionBatch = positionsBatch[i];
    let marketIdsBatch = [];
    for (let j = 0; j < positionBatch.length; j++) {
      const market = marketMap.get(positionBatch[j].marketId);
      marketIdsBatch.push(market.marketId);
    }
    let positionIdsBatch = positionBatch.map(batch => batch.positionId);

    console.log("RPC method: loadPositionsLiquidationData");
    let result = [];
    try {
      result = await loadHelper.methods.loadPositionsLiquidationData(marketIdsBatch, positionIdsBatch, underPriceBN).call();
      console.log("RPC method: finished loadPositionsLiquidationData");
      await sleep(100);
    } catch (e) {
      console.log("Error in loadPositionsLiquidationData", e);
    }

    const resultBatch = result.map(res => {
      return { health: res.health, lower: res.lower, upper: res.upper };
    });
    array4.push(...resultBatch);
  }

  return array4;
};
