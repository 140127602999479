/* eslint-disable import/no-named-as-default-member */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectedTokenPairSelector } from "../../store/selectors/tokensSelectors";
import ReactGA from "react-ga4";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import TradeModalTradeTab from "./TradeModalTradeTab.jsx";
import MyModal from "./utilities/MyModal.jsx";
import { formatExpirationTime, getUnderlyingPriceAtExpiredStr } from "../../utils/utils.js";
import { currentTimestampSelector } from "../../store/selectors/web3Selectors";
import { settlementDataSelector } from "../../store/selectors/balancesSelectors";

let lastSelectedInputTimestamp = null;
let lastClickedModalOverlay = null;

const TradeModal = ({
  isOpen,
  toggle,
  market,
  futureMarket,
  getMaxLongAmount,
  size,
  rawSize,
  isLong,
  marketName: marketNameProp,
  bidColumnDataClicked,
  needsToBeClosed,
  setNeedsToBeClosed,
  fromMarkets
}) => {
  const [marketName, setMarketName] = useState("");
  const [tabs, setTabs] = useState(bidColumnDataClicked ? "sell" : "buy");
  const [cogChartOptionsOpenBuy, setCogChartOptionsOpenBuy] = useState(false);
  const [cogChartOptionsOpenSell, setCogChartOptionsOpenSell] = useState(false);

  const selectedTokenPair = useSelector(selectedTokenPairSelector);
  const currentTimestamp = useSelector(currentTimestampSelector);
  const settlements = useSelector(settlementDataSelector);

  useEffect(() => {
    const modalDialog = document.querySelector(".modal-dialog");

    if (modalDialog) {
      $(".modal-black").draggable({
        handle: ".modal-header"
      });
    }

    let chunk = "buy";
    if (isLong && !fromMarkets) {
      setTabs("buy");
    } else if (!isLong && !fromMarkets) {
      setTabs("sell");
      chunk = "sell";
    }
    if (isLong && needsToBeClosed) {
      setTabs("sell");
      chunk = "sell";
    } else if (!isLong && needsToBeClosed) {
      setTabs("buy");
    }

    console.log("Navigate to: " + window.location.pathname + "/" + chunk); // eslint-disable-line no-console
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + "/" + chunk, title: "Trade Modal" });
  }, []);

  useEffect(() => {
    const newMarketName = getMarketName();
    setMarketName(!marketNameProp ? newMarketName : marketNameProp);
  });

  const getMarketName = () => {
    if (market) {
      const marketIsExpired = market.isSettled || Number(market.expirationTime) < currentTimestamp;

      let expiredText = " (EXPIRED)";
      if (market.isSettled) {
        const underlyingPriceAtExpired = getUnderlyingPriceAtExpiredStr(market, settlements);
        expiredText = " (EXPIRED $" + underlyingPriceAtExpired + ")";
      }

      if (market.isFuture) {
        return (
          (selectedTokenPair ? selectedTokenPair.underlyingTokenSymbol : "Loading...") +
          "-" +
          (market.readableExpirationTime ? formatExpirationTime(market.readableExpirationTime) : "Loading...") + 
          "-FUTURE" +
          (marketIsExpired ? expiredText : "")
        );
      }
      return (
        (selectedTokenPair ? selectedTokenPair.underlyingTokenSymbol : "Loading...") +
        "-" +
        (market.readableExpirationTime ? formatExpirationTime(market.readableExpirationTime) : "Loading...") +
        "-" +
        market.printableStrikePrice +
        "-" +
        (market.isCall ? "C" : "P") +
        (marketIsExpired ? expiredText : "")
      );
    }
    return null;
  };

  const changeActiveTab = (e, tabName) => {
    e.preventDefault();
    setTabs(tabName);
    cogChartOptionsOpenBuy && setCogChartOptionsOpenBuy(false);
    cogChartOptionsOpenSell && setCogChartOptionsOpenSell(false);

    const chunk = tabName;
    console.log("Navigate to: " + window.location.pathname + "/" + chunk); // eslint-disable-line no-console
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + "/" + chunk, title: "Trade Modal" });
  };

  const marketIsExpired = market.isSettled || Number(market.expirationTime) < currentTimestamp;

  return (
    <MyModal
      isOpen={isOpen}
      toggle={e => {
        lastClickedModalOverlay = Date.now();
        const diff = lastClickedModalOverlay - lastSelectedInputTimestamp;
        if (diff < 500) {
          lastClickedModalOverlay = null;
          lastSelectedInputTimestamp = null;
          return;
        }
        toggle();
      }}
    >
      <div
        className="modalFrame"
        onClick={e => {
          e.stopPropagation();
        }}
        onMouseDown={e => {
          lastSelectedInputTimestamp = Date.now();
        }}
      >
        <div className="tradeModalHeader">
          <span className="tokenSpan">{marketName}</span>
          <div className="navButtons">
            <button id="buyTabButton" onClick={e => changeActiveTab(e, "buy")} disabled={marketIsExpired} className={tabs === "buy" ? "activeNavLink" : ""}>
              <a href="#buy">Buy</a>
            </button>
            <button id="sellTabButton" onClick={e => changeActiveTab(e, "sell")} disabled={marketIsExpired} className={tabs === "sell" ? "activeNavLink" : ""}>
              <a href="#sell">Sell</a>
            </button>
          </div>
          <button aria-hidden data-dismiss="modal" type="button" className="closeModal" onClick={() => toggle()}>
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
        <TradeModalTradeTab
          market={market}
          futureMarket={futureMarket}
          marketName={marketName}
          getMaxLongAmount={getMaxLongAmount}
          tabs={tabs}
          size={size}
          rawSize={rawSize}
          isLong={isLong}
          needsToBeClosed={needsToBeClosed}
          setNeedsToBeClosed={setNeedsToBeClosed}
          toggle={toggle}
          isTradePage
          cogChartOptionsOpenBuy={cogChartOptionsOpenBuy}
          setCogChartOptionsOpenBuy={setCogChartOptionsOpenBuy}
          cogChartOptionsOpenSell={cogChartOptionsOpenSell}
          setCogChartOptionsOpenSell={setCogChartOptionsOpenSell}
          isOpen={isOpen}
        />
      </div>
    </MyModal>
  );
};

TradeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  market: PropTypes.object.isRequired,
  futureMarket: PropTypes.object,
  selectedTokenPair: PropTypes.object,
  underlyingTokenPrice: PropTypes.object,
  getMaxLongAmount: PropTypes.func.isRequired,
  marketName: PropTypes.object,
  size: PropTypes.object,
  rawSize: PropTypes.string,
  isLong: PropTypes.bool,
  isCall: PropTypes.bool,
  bidColumnDataClicked: PropTypes.bool,
  needsToBeClosed: PropTypes.bool,
  setNeedsToBeClosed: PropTypes.func,
  fromMarkets: PropTypes.object
};

export default TradeModal;
