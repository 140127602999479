import { formatUnits } from "@ethersproject/units";
import { decorateStrikePrices } from "../../utils/decorators";
import moment from "moment";
import { getOpenCloseLongFuturePrice, getOpenCloseLongOptionPrice, getOptionPrice } from "../../utils/utils.js";
import { MarketHelper, fromSqrtPrice, getMaxLongAmountWhenSellingOptions } from "../../utils/MarketHelper";

// export const isMarketsLoaded = (marketsMutableAttributesLoaded, marketsImmutableAttributesLoaded, tradesLoaded, lpTradesLoaded) => {
export const isMarketsLoaded = (marketsMutableAttributesLoaded, marketsImmutableAttributesLoaded) => {
  // return marketsMutableAttributesLoaded && marketsImmutableAttributesLoaded && tradesLoaded && lpTradesLoaded;
  return marketsMutableAttributesLoaded && marketsImmutableAttributesLoaded;
};

export const getMarkets = (
  marketsMutableAttributesData,
  marketsImmutableAttributesData,
  marketsMutableAttributesLoaded,
  marketsImmutableAttributesLoaded,
  nonExpired
) => {
  let startTime = new Date().getTime();
  if (!isMarketsLoaded(marketsMutableAttributesLoaded, marketsImmutableAttributesLoaded)) {
    return [];
  }

  // filter first if needed (for performance reasons)
  let filteredMarketsMutableAttributesData = nonExpired ? [] : marketsMutableAttributesData;
  let filteredMarketsImmutableAttributesData = nonExpired ? [] : marketsImmutableAttributesData;
  if (nonExpired) {
    for (let i = 0; i < marketsMutableAttributesData.length; i++) {
      if (!marketsMutableAttributesData[i].isSettled) {
        filteredMarketsMutableAttributesData.push(marketsMutableAttributesData[i]);
        filteredMarketsImmutableAttributesData.push(marketsImmutableAttributesData[i]);
      }
    }
  }

  // handle immutable attributes
  filteredMarketsImmutableAttributesData = filteredMarketsImmutableAttributesData.map(market => {
    const decoratedStrikePrice = decorateStrikePrices(market.strikePrice, market.baseTokenDecimals);
    const readable = moment(new Date(market.expirationTime * 1000)).format("DD MMM YYYY");
    // const readable = new Date(market.expirationTime * 1000).toLocaleDateString("en-GB", {
    //   day: "numeric",
    //   month: "short",
    //   year: "numeric"
    // });
    // NOTE: for some reason, month is Sept not Sep,
    // check console on: https://jsfiddle.net/wo1ck5Ls/
    const readableFixed = readable.replace("Sept", "Sep");
    return {
      ...market,
      rawStrikePrice: decoratedStrikePrice ? decoratedStrikePrice.raw : null,
      normalizedStrikePrice: decoratedStrikePrice ? decoratedStrikePrice.normalized : null,
      printableStrikePrice: decoratedStrikePrice ? decoratedStrikePrice.printable : null,
      readableExpirationTime: readableFixed
    };
  });

  // handle mutable attributes
  let immutableAttributes;
  let returnData = filteredMarketsMutableAttributesData.map((market, i) => {
    immutableAttributes = filteredMarketsImmutableAttributesData[i];
    return {
      ...immutableAttributes,
      ...market,
      strikePrice: parseFloat(formatUnits(immutableAttributes.strikePrice.toString(), immutableAttributes.baseTokenDecimals)),
      // minFee: parseFloat(formatUnits(market.minFee.toString(), 6)),
      // maxFee: parseFloat(formatUnits(market.maxFee.toString(), 6)),
      // steepness: parseFloat(formatUnits(market.steepness.toString(), 6)),
      // openInterest: parseFloat(formatUnits(market.openInterest.toString(), 18)),
      // requiredThreshold: parseFloat(formatUnits(market.requiredThreshold.toString(), 2)),
      // liquidationThreshold: parseFloat(formatUnits(market.liquidationThreshold.toString(), 2)),
      // penaltyBase: parseFloat(formatUnits(market.penaltyBase.toString(), 18)),
      // liquidityInCurrentTick: parseFloat(formatUnits(market.liquidityInCurrentTick.toString(), 18)),
      minFee: market.minFee.toString() / 1e6,
      maxFee: market.maxFee.toString() / 1e6,
      steepness: market.steepness.toString() / 1e6,
      openInterest: market.openInterest.toString() / 1e18,
      requiredThreshold: market.requiredThreshold.toString() / 1e2,
      liquidationThreshold: market.liquidationThreshold.toString() / 1e2,
      penaltyBase: market.penaltyBase.toString() / 1e18,
      liquidityInCurrentTick: market.liquidityInCurrentTick.toString() / 1e18,
      isSettled: market.isSettled
    };
  });

  let endTime = new Date().getTime();
  console.log("Selector: getMarkets took ", !!nonExpired, endTime - startTime, "ms");
  return returnData;
};

function filterTradesByMarkets(trades, markets) {
  // Create a set of marketIds from the markets list for fast lookup
  // eslint-disable-next-line no-undef
  const marketIdsSet = new Set(markets.map(market => market.marketId));

  // Filter trades that have a marketId in the marketIdsSet
  const filteredTrades = trades.filter(trade => marketIdsSet.has(trade.marketId));

  return filteredTrades;
}

export const getMarketsHistoricalData = (
  marketsImmutableAttributesData,
  tradesData,
  lpTradesData,
  marketsImmutableAttributesLoaded,
  tradesLoaded,
  lpTradesLoaded
) => {
  let startTime = new Date().getTime();
  if (!(marketsImmutableAttributesLoaded && tradesLoaded && lpTradesLoaded)) {
    return new Map();
  }
  const marketsHistoricalData = new Map();

  // filter out settled markets
  let currentTime = new Date().getTime();
  const marketsData = marketsImmutableAttributesData.filter(m => m.expirationTime * 1000 > currentTime);

  // filter out trades and lpTrades that are not in the markets
  const filteredTrades = filterTradesByMarkets(tradesData, marketsData);
  filteredTrades.sort((a, b) => {
    return a.timestamp - b.timestamp;
  });
  const filteredLPTrades = filterTradesByMarkets(lpTradesData, marketsData);
  filteredLPTrades.sort((a, b) => {
    return a.timestamp - b.timestamp;
  });

  // handle mutable attributes
  marketsData.forEach((market, i) => {
    const trades = filteredTrades
      .filter(td => td && td.marketId === market.marketId)
      .map(td => {
        const { timestamp, isBuy, size, baseAmount } = td;
        return {
          timestamp,
          isBuy,
          type: td.type,
          optionAmount: size.toString() / 1e18,
          baseAmount: baseAmount
        };
      });
    const lpTrades = filteredLPTrades
      .filter(td => td && td.marketId === market.marketId)
      .map(td => {
        const { timestamp, isBuy, optionAmount, baseAmount } = td;
        return {
          timestamp,
          isBuy,
          optionAmount: optionAmount.toString() / 1e18,
          baseAmount: baseAmount
        };
      });

    marketsHistoricalData.set(market.marketId, {
      trades,
      lpTrades
    });
  });
  let endTime = new Date().getTime();
  console.log("Selector: getMarketsHistoricalData took ", endTime - startTime, "ms");
  return marketsHistoricalData;
};

export const getMuttableMarketAttributes = async (loadHelper, userBalances, marketId, immutableMarketAttributes, riskFreeRate) => {
  let { isCall, strikePrice, expirationTime } = immutableMarketAttributes;
  const timestampNow = Math.round(new Date().getTime() / 1000);
  console.log("RPC method: loadHelper.getMarketData");
  const result = await loadHelper.methods.getMarketData([marketId], timestampNow).call();
  const marketSingletonData = result[0];
  const marketData = result[1];
  const isFuture = strikePrice.toString() === (10 ** 12).toString();
  const normalizedStrikePrice = parseFloat(formatUnits(strikePrice.toString(), 18));
  const underPrice = parseFloat(formatUnits(marketSingletonData.underPriceInBase.toString(), 18));
  const longPriceInVol = parseFloat(formatUnits(marketData[0].longPriceInVol.toString(), 18));
  const longPriceInBase = isFuture ? underPrice : getOptionPrice(riskFreeRate, expirationTime, normalizedStrikePrice, isCall, underPrice, longPriceInVol);

  // calculate open and close prices
  let { openLongPrice, closeLongPrice } = isFuture
    ? getOpenCloseLongFuturePrice(longPriceInBase, expirationTime)
    : getOpenCloseLongOptionPrice(
        longPriceInBase,
        underPrice,
        expirationTime,
        marketSingletonData.maxFee,
        marketSingletonData.minFee,
        marketSingletonData.steepness
      );

  let helper = new MarketHelper(0, parseInt(marketData[0].belowTick), Math.sqrt(longPriceInVol), expirationTime, normalizedStrikePrice, isCall, riskFreeRate);
  if (isFuture) {
    // delete zero tick if future
    helper.ticks = [];
    helper.currentSqrtPrice = Math.sqrt(underPrice);
  }
  helper.ticks = marketData[0].ticks.map(tick => {
    return { index: Number(tick.index), liquidity: parseFloat(formatUnits(tick.liquidity.toString(), 18)) };
  });

  const currentLiquidity = parseFloat(formatUnits(marketData[0].liquidity.toString(), isFuture ? 12 : 18));
  helper.currentLiquidity = currentLiquidity;

  const lowerOraclePriceInVol = fromSqrtPrice(marketData[0].oracleData.lowerLimitSqrtPrice);
  const upperOraclePriceInVol = fromSqrtPrice(marketData[0].oracleData.upperLimitSqrtPrice);
  const currentOraclePriceInVol = lowerOraclePriceInVol * Math.sqrt(upperOraclePriceInVol / lowerOraclePriceInVol);
  const oracle = {
    lowerPriceInVol: lowerOraclePriceInVol,
    currentPriceInVol: currentOraclePriceInVol,
    upperPriceInVol: upperOraclePriceInVol
  };

  const requiredThreshold = marketSingletonData.requiredThresholdOption;
  const liquidationThreshold = marketSingletonData.liquidationThresholdOption;
  const optionsPoolBalance = getMaxLongAmountWhenSellingOptions(helper).toString();
  const openInterest = marketData[0].openInterest;

  const mutableMarketAttributes = {
    marketId: marketId.toString(),
    longPriceInVol: longPriceInVol.toString(),
    longPrice: longPriceInBase.toString(),
    openLongPrice: openLongPrice.toString(),
    closeLongPrice: closeLongPrice.toString(),
    openShortPrice: [closeLongPrice.toString(), isCall ? 1e18 : strikePrice],
    closeShortPrice: [openLongPrice.toString(), isCall ? 1e18 : strikePrice],
    // historicalData: { loaded: false, data: [] },
    isSettled: marketData[0].isSettled,
    openInterest: openInterest,
    optionsPoolBalance: optionsPoolBalance,
    minFee: marketSingletonData.minFee.toString(),
    maxFee: marketSingletonData.maxFee.toString(),
    steepness: marketSingletonData.steepness.toString(),
    requiredThreshold: requiredThreshold.toString(),
    liquidationThreshold: liquidationThreshold.toString(),
    penaltyBase: marketSingletonData.penaltyBase.toString(),
    isEmpty: false,
    marketHelper: helper,
    liquidityInCurrentTick: marketData[0].liquidity,
    priceOracle: oracle
  };
  return mutableMarketAttributes;
};
