/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-multi-comp */
/* eslint-disable no-undef */
import { formatUnits } from "@ethersproject/units";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { openedLPPositionsSelector, openedTradePositionsSelector } from "../../store/selectors/positionsManagerSelectors";
import { selectedTokenPairSelector, avgUnderlyingTokenPriceSelector } from "../../store/selectors/tokensSelectors";
import { currentTimestampSelector, web3AccountLoadedSelector, web3AccountSelector, web3Selector } from "../../store/selectors/web3Selectors";
import {
  calculateMarketDepth,
  cursorRangeCheck,
  formatValue,
  getReadableLPHealth,
  getUnderlyingPriceAtExpiredStr,
  healthClassName,
  limitValue,
  printPriceFromString,
  printPriceFromNumber,
  SECONDS_IN_YEAR
} from "../../utils/utils.js";
import ConnectPanel from "./ConnectPanel.jsx";
import PoolModal from "./PoolModal.jsx";
import PositionModal from "./PositionModal.jsx";
import { checkTradeLiqPositions } from "./utilities/TradePanels/CheckUtility";
import {
  getPNLFooter,
  getROIFooter,
  getValueFooter,
  getDeltaFooter,
  getGammaFooter,
  getThetaFooter,
  getVegaFooter,
  getAPRFooter,
  getAPYFooter
} from "./utilities/TradePanels/FooterUtility";
import { printEmptyTable, printLoadingIcon } from "./utilities/TradePanels/PrintUtility.jsx";
import { marketsLoadedSelector, marketsSelector } from "../../store/selectors/marketsSelectors";
import { FilterContext } from "../../layouts/market/MainRouter.jsx";
import FiltersPositions from "./FiltersPositions.jsx";
import CogPositionsModal from "./CogPositionsModal.jsx";
import { getChartPoint, getHighestDaysToExpiryRounded } from "../../utils/chartsUtils";
import MyTooltip2 from "./MyTooltip2.jsx";
import MyTooltip from "./MyTooltip.jsx";
import TradeModal from "./TradeModal.jsx";
import { settlementDataSelector } from "../../store/selectors/balancesSelectors";
import { marginPoolDataSelector } from "../../store/selectors/marginSelectors.js";
import { marginPoolDataLoaded } from "../../store/actions/actions.mjs";

const EMPTY_TABLE_TEXT = "No LP positions currently open in your account";
const CARD_TITLE = "POOL POSITIONS";

export const callPutsSearchParams = ["Options & Futures", "Calls", "Puts", "Futures"];
export const longShortsLpsSearchParams = ["Longs & Shorts & LPs", "Longs", "Shorts", "LPs"];

const TOOLTIPS_TEXT = {
  poolPositionsToken: <>Name of the position token.</>,
  poolPositionsCreatedAt: "Time passed since the opening.",
  poolPositionsTimeToExp: "Time to expiration.",
  poolPositionsSize: (
    <>
      Current size of the position in options. <br /> <br /> Positive quantity means position is long option, negative quantity means position is short option.
    </>
  ),
  poolPositionsLots: "Maximum size of the LP position in options.",
  poolPositionsOpenPrice: (
    <>
      The average price of the option on the opening. <br /> <br /> If there were multiple trades that created this position, then open price is average option
      price at the time of trading.
    </>
  ),
  poolPositionsMarkPrice: (
    <>
      Current mid-point option price on the market. <br /> <br /> Used for P/L, ROI, APR, APY, as well as Greeks calculations.
    </>
  ),
  poolPositionsCollateral: "The total collateral locked for opening of the position.",
  poolPositionsValue: (
    <>
      Cash reserves paid for opening LP position, or total premium paid for opening long or short position. <br /> <br />
      The total row shows Net Option Premium which is the sum of all premiums paid and received.
    </>
  ),
  poolPositionsHealth: (
    <>
      LP Health represents the ratio between cash reserves in the LP position and actual cash needed to buy options in the range. <br /> <br />
      Can be increased by adding more cash reserves to the LP position. <br /> <br />
      If LP position's health declines below 125% for options, or 105% for futures, it can be forcefully closed and owner will be charged with $300 penalty.
    </>
  ),
  poolPositionsStatus: (
    <>
      If LP position is "In range", it earns trading fees. <br /> <br />
      Otherwise, it's inactive and doesn't earn trading fees.
    </>
  ),
  poolPositionsPnl: (
    <>
      Profit/Loss (P/L) of the position since the opening. <br /> <br />
      Formula used: <br />
      P/L = QTY x (MARK - OPEN)
    </>
  ),
  poolPositionsRoi: "Return on investment (ROI) of the position since the opening.",
  poolPositionsApr: "Annual percentage rate (APR) of the position since the opening.",
  poolPositionsApy: "Annual percentage yield (APY) of the position since the opening.",
  poolPositionsDelta: <>Delta (&Delta;) of the position represents the rate of change of the position's price relative to underlying price change.</>,
  poolPositionsGamma: <>Gamma (&gamma;) of the position represents the rate of change of the Delta (&Delta;) relative to underlying price change.</>,
  poolPositionsTheta: (
    <>Theta (&Theta;) of the position represents the rate of change of the position's price relative to time change, also known as time decay.</>
  ),
  poolPositionsVega: <>Vega (V) of the position represents the rate of change of the position's price relative to implied volatility (IV) change.</>
};

let closeAllFilterDropmenusRef = null;

class PoolPositionsPanel extends React.Component {
  constructor() {
    super();

    !localStorage.getItem("isPppDisplayed") && localStorage.setItem("isPppDisplayed", true);

    // TODO: UI move code for storage to new utility component
    !localStorage.getItem("timeToExpPool") && localStorage.setItem("timeToExpPool", false);
    !localStorage.getItem("createdAtPool") && localStorage.setItem("createdAtPool", true);

    !localStorage.getItem("pnlPool") && localStorage.setItem("pnlPool", true);
    !localStorage.getItem("roiPool") && localStorage.setItem("roiPool", false);
    !localStorage.getItem("aprPool") && localStorage.setItem("aprPool", false);
    !localStorage.getItem("apyPool") && localStorage.setItem("apyPool", false);

    !localStorage.getItem("totalGammaPool") && localStorage.setItem("totalGammaPool", false);
    !localStorage.getItem("totalVegaPool") && localStorage.setItem("totalVegaPool", false);
    !localStorage.getItem("totalThetaPool") && localStorage.setItem("totalThetaPool", false);
    !localStorage.getItem("totalDeltaPool") && localStorage.setItem("totalDeltaPool", true);

    !localStorage.getItem("healthPool") && localStorage.setItem("healthPool", true);
    !localStorage.getItem("statusPool") && localStorage.setItem("statusPool", true);

    this.arePositionsLoaded = this.arePositionsLoaded.bind(this);
    this.getTableColumns = this.getTableColumns.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.sortPositions = this.sortPositions.bind(this);
    this.onClosePositionClick = this.onClosePositionClick.bind(this);
    this.checkHandler = this.checkHandler.bind(this);
    this.printPoolTable = this.printPoolTable.bind(this);
    this.setNeedsToBeClosed = this.setNeedsToBeClosed.bind(this);

    this.state = {
      isTradingModalOpen: false,
      market: null,
      marketDepths: [],
      size: null,
      isLong: null,
      isCall: null,
      isLiq: null,
      isShort: null,
      longShortLiq: null,

      searchCallPutParam: "Options & Futures",
      searchLongShortLpParam: "LPs",
      expirationTimesSearchParam: "All Expirations",
      strikesSearchParam: "All Strikes",
      expirationTimesSelected: new Map(),
      strikesSelected: new Map(),
      longsShortsLpsSelected: new Map(),

      isCogModalOpen: false,
      cogModalPositionTop: "0px",
      cogModalPositionRight: "0px",
      isPositionModalOpen: false,
      selectedPositions: null,
      selectedPositionMarket: null,
      needsToBeClosed: null,
      panelIsDisplayed: localStorage.getItem("isPppDisplayed") === "true",

      isChecked: {
        timeToExpPool: localStorage.getItem("timeToExpPool") !== "false",
        createdAtPool: localStorage.getItem("createdAtPool") !== "false",

        pnlPool: localStorage.getItem("pnlPool") !== "false",
        roiPool: localStorage.getItem("roiPool") !== "false",
        aprPool: localStorage.getItem("aprPool") !== "false",
        apyPool: localStorage.getItem("apyPool") !== "false",

        totalGammaPool: localStorage.getItem("totalGammaPool") !== "false",
        totalVegaPool: localStorage.getItem("totalVegaPool") !== "false",
        totalThetaPool: localStorage.getItem("totalThetaPool") !== "false",
        totalDeltaPool: localStorage.getItem("totalDeltaPool") !== "false",

        healthPool: localStorage.getItem("healthPool") !== "false",
        statusPool: localStorage.getItem("statusPool") !== "false"
      },

      tooltipIsVisible: false,
      tooltipTarget: "",
      tooltipContent: "",
      idPrefix: "pool-positions-panel-"
    };

    this.tableWrapper = React.createRef(null);
  }

  componentDidMount() {
    this.initializeFilterOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    let newPositionWasAdded = false;
    if (prevProps.openedTradePositions && this.props.openedTradePositions) {
      newPositionWasAdded = prevProps.openedTradePositions.length !== this.props.openedTradePositions.length;
    }
    if (
      (this.props.openedTradePositions &&
        this.props.openedTradePositions.hasOwnProperty("length") &&
        this.props.openedLPPositions &&
        this.props.openedLPPositions.hasOwnProperty("length") &&
        (prevProps.openedTradePositions !== this.props.openedTradePositions || prevProps.openedLPPositions !== this.props.openedLPPositions)) ||
      newPositionWasAdded
    ) {
      this.initializeFilterOptions();
    }

    // update state.selectedPositions if props changed (for example health, value, pnl changes)
    if (
      prevProps.openedLPPositions &&
      this.props.openedLPPositions &&
      (prevProps.openedTradePositions !== this.props.openedTradePositions || prevProps.openedLPPositions !== this.props.openedLPPositions) &&
      this.state.selectedPositions
    ) {
      const updatedStatePositions = [];
      for (const statePosition of this.state.selectedPositions) {
        // first, if position is trade position
        if (statePosition.type !== "lp" && this.props.openedTradePositions) {
          const tradePosition = this.props.openedTradePositions.find(p => p.name === statePosition.name);
          if (tradePosition) {
            updatedStatePositions.push(tradePosition);
          }
        } else {
          const lpPosition = this.props.openedLPPositions.find(
            p => p.marketId === statePosition.marketId && p.positionId.toString() === statePosition.positionId.toString()
          );
          if (lpPosition) {
            updatedStatePositions.push(lpPosition);
          }
        }
      }

      if (JSON.stringify(updatedStatePositions) !== JSON.stringify(this.state.selectedPositions)) {
        this.setState({
          selectedPositions: updatedStatePositions
        });
      }
    }

    const tokenFromPortfolio = localStorage.getItem("tokenFromPortfolio");
    if (tokenFromPortfolio && closeAllFilterDropmenusRef) {
      localStorage.removeItem("tokenFromPortfolio");

      const rowInfo = {
        original: {
          token: tokenFromPortfolio
        }
      };

      this.onRowPositionClicked(rowInfo, closeAllFilterDropmenusRef);
    }
  }

  initializeFilterOptions = () => {
    let expirationTimesSelected = new Map(this.state.expirationTimesSelected);
    const expirationTimestampsMap = new Map();
    if (expirationTimesSelected.size === 0) {
      expirationTimesSelected.set("All Expirations", true);
    }
    let strikesSelected = new Map(this.state.strikesSelected);
    if (strikesSelected.size === 0) {
      strikesSelected.set("All Strikes", true);
    }

    const longsShortsLpsSelected = new Map(this.state.longsShortsLpsSelected);

    if (longsShortsLpsSelected.size === 0) {
      longsShortsLpsSelected.set("Longs & Shorts & LPs", false);
      longsShortsLpsSelected.set("Longs", false);
      longsShortsLpsSelected.set("Shorts", false);
      longsShortsLpsSelected.set("LPs", true);
      this.setState({ longsShortsLpsSelected });
    }

    const strikeSet = new Set();
    const expirationTimesSet = new Set();
    this.props.openedTradePositions &&
      this.props.openedTradePositions.forEach(p => {
        const expirationInSeconds = p.expirationTime;
        const expirationDate = new Date(expirationInSeconds * 1000);

        const day = expirationDate.getDate();

        const monthNumber = expirationDate.getMonth();
        const month = this.toMonthName(monthNumber + 1)
          .slice(0, 3)
          .toUpperCase();
        const year = expirationDate.getFullYear();

        const formattedExpirationDate = `${day} ${month} ${String(year).slice(2)}`;
        if (!expirationTimesSelected.has(formattedExpirationDate)) {
          expirationTimesSelected.set(formattedExpirationDate, false);
        }
        if (!expirationTimestampsMap.has(Number(expirationInSeconds))) {
          expirationTimestampsMap.set(Number(expirationInSeconds), formattedExpirationDate);
        }
        const strikePrice = String(parseInt(p.strikePrice));
        if (!strikesSelected.has(strikePrice) && strikePrice != 0) {
          strikesSelected.set(strikePrice, false);
        }

        !expirationTimesSet.has(formattedExpirationDate) && expirationTimesSet.add(formattedExpirationDate);
        !strikeSet.has(strikePrice) && strikeSet.add(strikePrice);
      });

    this.props.openedLPPositions &&
      this.props.openedLPPositions.forEach(p => {
        const expirationInSeconds = p.expirationTime;
        const expirationDate = new Date(expirationInSeconds * 1000);

        const day = expirationDate.getDate();

        const monthNumber = expirationDate.getMonth();
        const month = this.toMonthName(monthNumber + 1)
          .slice(0, 3)
          .toUpperCase();
        const year = expirationDate.getFullYear();

        const formattedExpirationDate = `${day} ${month} ${String(year).slice(2)}`;
        if (!expirationTimesSelected.has(formattedExpirationDate)) {
          expirationTimesSelected.set(formattedExpirationDate, false);
        }
        if (!expirationTimestampsMap.has(Number(expirationInSeconds))) {
          expirationTimestampsMap.set(Number(expirationInSeconds), formattedExpirationDate);
        }

        const strikePrice = String(parseInt(p.strikePrice));
        if (!strikesSelected.has(strikePrice)) {
          strikesSelected.set(strikePrice, false);
        }

        !expirationTimesSet.has(formattedExpirationDate) && expirationTimesSet.add(formattedExpirationDate);
        !strikeSet.has(strikePrice) && strikeSet.add(strikePrice);
      });
    const expirationTimestampsSorted = [...expirationTimestampsMap.keys()].sort();
    const expirationTimesSelectedSorted = new Map();
    expirationTimesSelectedSorted.set("All Expirations", expirationTimesSelected.get("All Expirations"));
    expirationTimestampsSorted.forEach(timestamp => {
      const formattedExpirationDate = expirationTimestampsMap.get(timestamp);
      expirationTimesSelectedSorted.set(formattedExpirationDate, expirationTimesSelected.get(formattedExpirationDate));
    });

    expirationTimesSelected = new Map([...expirationTimesSelectedSorted.entries()].filter(ss => ss[0] === "All Expirations" || expirationTimesSet.has(ss[0])));

    strikesSelected = new Map([...strikesSelected.entries()].filter(ss => ss[0] === "All Strikes" || strikeSet.has(ss[0])));
    strikesSelected = new Map([...strikesSelected.entries()].sort((a, b) => a[0] - b[0]));
    this.setState({ expirationTimesSelected, strikesSelected });
  };

  setTooltipIsVisible = visible => {
    this.setState({ tooltipIsVisible: visible });
  };

  setTooltipTarget = target => {
    this.setState({ tooltipTarget: target });
  };

  setTooltipContent = content => {
    this.setState({ tooltipContent: content });
  };

  areDefaultSearchParams = () => {
    return (
      this.state.searchCallPutParam === "Options & Futures" &&
      this.state.searchLongShortLpParam === "LPs" &&
      this.state.expirationTimesSearchParam === "All Expirations" &&
      this.state.strikesSearchParam === "All Strikes"
    );
  };

  toMonthName = monthNumber => {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long"
    });
  };

  setSearchCallPutParamState = newSearchCallPutParam => {
    this.setState({ searchCallPutParam: newSearchCallPutParam });
  };

  setSearchLongShortLpParamState = newSearchLongShortLpParam => {
    this.setState({ searchLongShortLpParam: newSearchLongShortLpParam });
  };

  setExpirationTimesSearchParam = newExpirationTimesSearchParam => {
    this.setState({ expirationTimesSearchParam: newExpirationTimesSearchParam });
  };

  setStrikesSearchParam = newStrikesSearchParam => {
    this.setState({ strikesSearchParam: newStrikesSearchParam });
  };

  setExpirationTimesSelected = newExpirationTimesSelected => {
    this.setState({ expirationTimesSelected: newExpirationTimesSelected });
  };

  setStrikesSelected = newStrikesSelected => {
    this.setState({ strikesSelected: newStrikesSelected });
  };

  setLongsShortsLpsSelected = newLongsShortsLpsSelected => {
    this.setState({ longsShortsLpsSelected: newLongsShortsLpsSelected });
  };

  allTokensAreValid = () => {
    return this.props.openedTradePositions.every(p => p.name !== "...");
  };

  setPanelIsDisplayed = displayed => {
    this.setState({ panelIsDisplayed: displayed });
  };

  resetFiltersToDefault = () => {
    const newLongsShortsLpsSelected = new Map();
    newLongsShortsLpsSelected.set("Longs & Shorts & LPs", false);
    newLongsShortsLpsSelected.set("Longs", false);
    newLongsShortsLpsSelected.set("Shorts", false);
    newLongsShortsLpsSelected.set("LPs", true);

    const newStrikesSelected = new Map();
    newStrikesSelected.set("All Strikes", true);
    for (const [key, value] of this.state.strikesSelected.entries()) {
      if (key === "All Strikes") {
        continue;
      }
      newStrikesSelected.set(key, false);
    }

    const newExpirationTimesSelected = new Map();
    newExpirationTimesSelected.set("All Expirations", true);
    for (const [key, value] of this.state.expirationTimesSelected.entries()) {
      if (key === "All Expirations") {
        continue;
      }
      newExpirationTimesSelected.set(key, false);
    }

    this.setState({
      searchCallPutParam: "Options & Futures",
      searchLongShortLpParam: "LPs",
      expirationTimesSearchParam: "All Expirations",
      strikesSearchParam: "All Strikes",
      longsShortsLpsSelected: newLongsShortsLpsSelected,
      expirationTimesSelected: newExpirationTimesSelected,
      strikesSelected: newStrikesSelected
    });
  };

  arePositionsLoaded = () => {
    if (this.props.openedTradePositions === null || this.props.openedLPPositions === null) {
      return false;
    }
    return checkTradeLiqPositions(this.props.openedTradePositions, this.props.openedLPPositions);
  };

  getTableData = () => {
    // TODO: v2 it would be cleaner and testable if this mapping was done through selector
    if (this.arePositionsLoaded() && this.props.web3AccountLoaded && this.props.marketsLoaded && this.props.marginPoolDataLoaded) {
      let sortablePositions = [...this.props.openedTradePositions, ...this.props.openedLPPositions];
      this.sortPositions(sortablePositions);

      const mappedPositions = sortablePositions.map((position, index) => {
        let baseName = "";
        let collName = "";
        let openBasePriceArr = "...";
        let collateralArr = "";
        let markBasePriceArr = "...";
        let valueArr = "";
        let healthArr = "";
        let pnlArr = "";
        let roiArr = "";
        let aprArr = "";
        let apyArr = "";
        let deltaArr = "";
        let gammaArr = "";
        let thetaArr = "";
        let vegaArr = "";
        let openPriceLoaded = false;
        let underlyingPrice = 0;
        let token = "";
        let timeToExp = "";
        let timePassedFromTransaction = "";
        let maxLot = "";
        let lot = "";
        let status = "";

        if (position.name !== "...") {
          const isLong = position.type === "long";
          const isShort = position.type === "short";
          const isLongOrShort = isLong || isShort;
          const isLP = position.type === "lp";
          baseName = position.tokenPair.baseTokenSymbol;
          collName = position.isCall ? position.tokenPair.underlyingTokenSymbol : position.tokenPair.baseTokenSymbol;
          underlyingPrice = parseFloat(formatUnits(position.tokenPair.avgUnderPrice.toString(), 18));
          const market = this.props.markets.find(market => market.marketId === position.marketId);
          const longPriceInBase = parseFloat(market.longPrice.toString());
          const strikePrice = parseFloat(market.normalizedStrikePrice);
          const isCall = market.isCall;
          token = position.shortName;
          const isSettled = market.isSettled;

          let selectedDaysToExpiry = getHighestDaysToExpiryRounded([position], [market]);

          const marketIsExpired = isSettled || Number(market.expirationTime) < this.props.currentTimestamp;

          // if settled, use 0 days, and underPrice at settlement
          if (marketIsExpired) {
            selectedDaysToExpiry = 0;
            const settlement = this.props.settlements.find(s => s.expirationTime === market.expirationTime);
            if (settlement) {
              underlyingPrice = parseFloat(formatUnits(settlement.actualUnderlyingPrice.toString(), 18));
            }
          }

          // calculation are always done in chartUtils
          const baseBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "USD").normalizedBorrowInterestRate;
          const underBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "ETH").normalizedBorrowInterestRate;
          const borrowRates = { baseBorrowRate, underBorrowRate };
          const chartPoint = getChartPoint(
            [position],
            this.props.markets,
            underlyingPrice,
            selectedDaysToExpiry,
            parseFloat(market.longPriceInVol),
            this.props.selectedTokenPair.riskFreeRate / 100,
            borrowRates
          );

          let expiredText = " (EXPIRED)";
          if (isSettled) {
            const underlyingPriceAtExpired = getUnderlyingPriceAtExpiredStr(market, this.props.settlements);
            expiredText = " (EXPIRED $" + underlyingPriceAtExpired + ")";
          }

          token += marketIsExpired ? expiredText : "";

          // qty
          lot = chartPoint.lots;

          // max qty
          if (position.type === "lp") {
            const rightLots = position.openLiqPrice[1] * position.balance;
            const leftLots = position.sizeInLongs - rightLots;
            const leftLotsPrintable = formatValue(leftLots, 1);
            const rightLotsPrintable = formatValue(rightLots, 1);
            maxLot = leftLotsPrintable + "/-" + rightLotsPrintable;
          }

          // total premium (what user paid for opening position)
          let openValue = chartPoint.value - chartPoint.pnl;
          const optionValue = chartPoint.optionValue;
          valueArr = [optionValue, baseName, openValue];

          // locked collateral in base
          const collateralInBase = isCall ? underlyingPrice : strikePrice;

          // open price
          const openPriceInBase = isLongOrShort ? position.openBasePrice : position.openLiqPrice[0] * (position.balance / position.sizeInLongs);
          openBasePriceArr = isLongOrShort ? [position.openBasePrice, baseName] : "";

          // mark price
          let markBasePrice = chartPoint.markPrice;
          if (isSettled) {
            markBasePrice = optionValue / chartPoint.lots;
          }
          markBasePriceArr = [markBasePrice, baseName];

          // collateral
          const collateralPrice = position.openCollPrice;
          const isFuture = market.isFuture;
          if (isShort) {
            collateralArr = [position.balance * position.openCollPrice, collName];
          } else if (isLP) {
            collateralArr = [position.balance * position.openLiqPrice[2] * (isCall ? 1 : strikePrice), collName];
          } else if (isFuture) {
            collateralArr = [position.totalBaseCost, "USD"];
          }

          // PnL
          const profit = chartPoint.pnl;
          pnlArr = [profit, baseName];

          // ROI
          const roi = (profit / openValue) * 100;
          roiArr = [roi, underlyingPrice];

          // APR
          const daysSinceStart = (Math.round(new Date().getTime() / 1000) - position.openTime) / (60 * 60 * 24);
          const apr = (profit / openValue / daysSinceStart) * 365 * 100;
          aprArr = [apr, "%", daysSinceStart];

          // APY
          const apy = (Math.pow(1 + apr / 100 / 365, 365) - 1) * 100;
          apyArr = [apy, "%"];

          // GREEKS
          deltaArr = [chartPoint.delta, baseName];
          gammaArr = [chartPoint.gamma, baseName];
          thetaArr = [chartPoint.theta, baseName];
          vegaArr = [chartPoint.vega, baseName];

          // HEALTH
          if (position.type === "lp") {
            const readableHealth = getReadableLPHealth(position.health);
            const sortableHealth = parseFloat(position.health);
            const recoveryThreshold = market.requiredThreshold * 100;
            const liquidationThreshold = market.liquidationThreshold * 100;

            const color = healthClassName(readableHealth, position.health * 100, recoveryThreshold, liquidationThreshold);
            healthArr = [readableHealth, color, sortableHealth];
          }

          // STATUS
          const minPriceInVol = 1.0001 ** position.lower;
          const maxPriceInVol = 1.0001 ** position.upper;
          const currentPriceInVol = parseFloat(market.longPriceInVol);
          if (isLP) {
            if (currentPriceInVol > minPriceInVol && currentPriceInVol < maxPriceInVol) {
              status = ["In range", "green-text"];
            } else {
              status = ["Out of range", "red-text"];
            }
          }

          // check if loaded
          openPriceLoaded = isFinite(openPriceInBase) && (isShort ? isFinite(collateralPrice) : true);

          const expirationTime = parseInt(position.expirationTime);
          const now = parseInt(Date.now() / 1000);
          const diffExpAndNow = expirationTime - now;
          const daysAndHoursLeft = diffExpAndNow / (24 * 60 * 60);

          if (daysAndHoursLeft < 1) {
            const hoursLeft = Math.round(diffExpAndNow / 3600);
            timeToExp = hoursLeft > 1 ? hoursLeft + "h" : "expired";
          } else {
            timeToExp = Math.round(daysAndHoursLeft) + "d";
          }

          const timestamp = parseInt(position.timestamp);
          const diffTimestampAndNow = now - timestamp;
          const daysAndHoursBefore = diffTimestampAndNow / (24 * 60 * 60);

          if (daysAndHoursBefore < 1) {
            const hoursBefore = Math.round(diffTimestampAndNow / 3600);
            timePassedFromTransaction = hoursBefore + "h";
          } else {
            timePassedFromTransaction = Math.round(daysAndHoursBefore) + "d";
          }

          // if trade happened recently, don't show APY
          if (Math.abs(now - timestamp) < 60 * 60) {
            aprArr = "";
            apyArr = "";
          }
        }

        return {
          id: position,
          token: token,
          createdAt: timePassedFromTransaction,
          size: lot, // this is lot in table
          lot: maxLot, // this is max lot in table
          open: openPriceLoaded ? openBasePriceArr : "",
          mark: markBasePriceArr,
          collateral: openPriceLoaded ? collateralArr : "",
          health: openPriceLoaded ? healthArr : "",
          value: openPriceLoaded ? valueArr : "",
          timeToExp,
          pnl: openPriceLoaded ? pnlArr : "",
          roi: openPriceLoaded ? roiArr : "",
          apr: openPriceLoaded ? aprArr : "",
          apy: openPriceLoaded ? apyArr : "",
          delta: openPriceLoaded ? deltaArr : "",
          gamma: openPriceLoaded ? gammaArr : "",
          theta: openPriceLoaded ? thetaArr : "",
          vega: openPriceLoaded ? vegaArr : "",
          status: status
        };
      });

      // mapping can introduce undefined elements, so we filter them out
      return mappedPositions.filter(pos => pos !== undefined);
    }

    return [];
  };

  sortPositions = positions => {
    positions.sort((a, b) => {
      if (parseInt(a.expirationTime) !== parseInt(b.expirationTime)) {
        return parseInt(a.expirationTime) - parseInt(b.expirationTime);
      } else if (a.strikePrice !== b.strikePrice) {
        return a.strikePrice - b.strikePrice;
      } else if (a.isCall !== b.isCall) {
        return (b.isCall ? 1 : 0) - (a.isCall ? 1 : 0);
      } else {
        if (a.name !== "..." && b.name !== "...") {
          return a.type.length - b.type.length;
        }
      }
      return 0;
    });
  };

  filterTableData = () => {
    const allSearchParamsAreDefault = this.areDefaultSearchParams();
    const resetFilters = document.querySelector(".-resetFiltersContainer");

    if (resetFilters) {
      if (allSearchParamsAreDefault) {
        if (!resetFilters.className.includes("imgAndResetFiltersSpanDisabled")) {
          resetFilters.classList.add("imgAndResetFiltersSpanDisabled");
          resetFilters.children[1].classList.add("resetFiltersTextDisabled");
          resetFilters.children[0].className = "resetImgDisabled";
        }
      } else {
        if (resetFilters.className.includes("imgAndResetFiltersSpanDisabled")) {
          resetFilters.classList.remove("imgAndResetFiltersSpanDisabled");
          resetFilters.children[1].classList.remove("resetFiltersTextDisabled");
          resetFilters.children[0].className = "resetImg";
        }
      }
    }

    let filteredData = [];
    let allData = this.getTableData();
    allData.forEach(d => {
      const isFuturePosition = !d.token.endsWith("C") && !d.token.endsWith("P");

      const callPutFuture = isFuturePosition ? "Futures" : d.id.isCall ? "Calls" : "Puts";

      const longShortLp = d.id.type === "long" ? "Longs" : d.id.type === "short" ? "Shorts" : "LPs";

      const expirationInSeconds = d.id.expirationTime;
      const expirationDate = new Date(expirationInSeconds * 1000);

      const day = expirationDate.getDate();
      const monthNumber = expirationDate.getMonth();
      const month = this.toMonthName(monthNumber + 1)
        .slice(0, 3)
        .toUpperCase();
      const year = expirationDate.getFullYear();
      const formattedExpirationDate = `${day} ${month} ${String(year).slice(2)}`;

      const strikePrice = String(parseInt(d.id.strikePrice));

      if (this.state.searchCallPutParam !== "Options & Futures" && this.state.searchCallPutParam !== callPutFuture) {
        return;
      }

      if (this.state.searchLongShortLpParam !== "Longs & Shorts & LPs" && !this.state.longsShortsLpsSelected.get(longShortLp)) {
        return;
      }

      if (this.state.expirationTimesSearchParam !== "All Expirations" && !this.state.expirationTimesSelected.get(formattedExpirationDate)) {
        return;
      }

      if (this.state.strikesSearchParam !== "All Strikes" && !this.state.strikesSelected.get(strikePrice)) {
        return;
      }

      filteredData.push(d);
    });

    return filteredData;
  };

  onClosePositionClick = position => {
    let longShortLiq = "LONG";
    if (position.name.startsWith("SHORT")) {
      longShortLiq = "SHORT";
    } else if (position.name.startsWith("LP")) {
      longShortLiq = "LP";
    }

    let market = this.props.markets.find(market => {
      return market.marketId === position.marketId;
    });

    this.setState({ longShortLiq, isTradingModalOpen: true, size: position.balance, isCall: market.isCall, market });
  };

  onMouseEnterColumnHeader = e => {
    if (!this.state.tooltipIsVisible) {
      this.setState({ tooltipIsVisible: true, tooltipContent: TOOLTIPS_TEXT[e.currentTarget.getAttribute("name")], tooltipTarget: e.currentTarget.id });
    }
  };

  onMouseLeaveColumnHeader = e => {
    if (!cursorRangeCheck(this.state.tooltipTarget, e.clientX, e.clientY)) {
      this.setState({ tooltipIsVisible: false, tooltipContent: "", tooltipTarget: "" });
      document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", -500 + "px");

      document.querySelector(":root").style.setProperty("--tooltipTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipLeftPosition", -500 + "px");
    }
  };

  getTableColumns = () => {
    const tableColumns = [
      {
        Header: () => (
          <div className="alignLeft">
            <span id="pool-positions-token" name="poolPositionsToken" onMouseEnter={this.onMouseEnterColumnHeader} onMouseLeave={this.onMouseLeaveColumnHeader}>
              Token
            </span>
          </div>
        ),
        accessor: "token",
        align: "right",
        minWidth: 70,
        maxWidth: 5 * 70,
        filterable: false,
        sortable: false,
        Footer: () => (
          <span className="floatLeft">
            <strong>Total</strong>
          </span>
        )
      },
      {
        Header: () => (
          <div>
            <span
              id="pool-positions-createdAt"
              name="poolPositionsCreatedAt"
              onMouseEnter={this.onMouseEnterColumnHeader}
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              Opened
            </span>
          </div>
        ),
        accessor: "createdAt",
        align: "right",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: false,
        show: this.state.isChecked.createdAtPool,
        Cell: row => <span className="floatRight lowercase">{row.value} ago</span>
      },
      {
        Header: () => (
          <div>
            <span
              id="my-positions-time-to-exp"
              name="poolPositionsTimeToExp"
              onMouseEnter={this.onMouseEnterColumnHeader}
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              Time to exp
            </span>
          </div>
        ),
        accessor: "timeToExp",
        minWidth: 40,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        show: this.state.isChecked.timeToExpPool,
        Cell: row => {
          return <span className="floatRight lowercase">{row.row.timeToExp}</span>;
        },
        sortMethod: (a, b) => {
          return b[0] - a[0];
        }
      },

      {
        Header: () => (
          <div>
            <span id="pool-positions-size" name="poolPositionsSize" onMouseEnter={this.onMouseEnterColumnHeader} onMouseLeave={this.onMouseLeaveColumnHeader}>
              QTY
            </span>
          </div>
        ),
        accessor: "size",
        align: "right",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: true,
        sortMethod: (a, b) => {
          return b[0] - a[0];
        },
        Cell: row => <span className="floatRight monospace">{row.value && formatValue(row.value, 2)}</span>
      },
      {
        Header: () => (
          <div>
            <span id="pool-positions-lot" name="poolPositionsLots" onMouseEnter={this.onMouseEnterColumnHeader} onMouseLeave={this.onMouseLeaveColumnHeader}>
              Max QTY
            </span>
          </div>
        ),
        accessor: "lot",
        align: "right",
        minWidth: 25,
        maxWidth: 5 * 25,
        filterable: false,
        sortable: false,
        Cell: row => <span className="floatRight">{row.value}</span>
      },
      {
        Header: () => (
          <div>
            <span
              id="pool-positions-openPrice"
              name="poolPositionsOpenPrice"
              onMouseEnter={this.onMouseEnterColumnHeader}
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              Open Price
            </span>
          </div>
        ),
        accessor: "open",
        minWidth: 30,
        maxWidth: 5 * 30,
        filterable: false,
        sortable: false,
        Cell: row => {
          return (
            <span className="floatRight monospace">
              {row.value.length === 2 ? row.value && printPriceFromNumber(row.value[0]) : row.value ? printPriceFromNumber(row.value) : ""}
            </span>
          );
        }
      },
      {
        Header: () => (
          <div>
            <span
              id="pool-positions-markPrice"
              name="poolPositionsMarkPrice"
              onMouseEnter={this.onMouseEnterColumnHeader}
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              Mark Price
            </span>
          </div>
        ),
        accessor: "mark",
        minWidth: 30,
        maxWidth: 5 * 30,
        filterable: false,
        sortable: false,
        Cell: row => {
          return (
            <span className="floatRight monospace">
              {row.value.length === 2 ? row.value && printPriceFromNumber(row.value[0]) : row.value && printPriceFromString(row.value[0])}
            </span>
          );
        }
      },
      {
        Header: () => (
          <div>
            <span
              id="pool-positions-collateral"
              name="poolPositionsCollateral"
              onMouseEnter={this.onMouseEnterColumnHeader}
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              Collateral
            </span>
          </div>
        ),
        accessor: "collateral",
        minWidth: 40,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: false,
        Cell: row => {
          return (
            <span className="floatRight">
              {row.value.length === 2 ? row.value && formatValue(row.value[0], 2) + " " + row.value[1] : row.value && row.value[0]}
            </span>
          );
        }
      },
      {
        Header: () => (
          <div>
            <span
              id="pool-positions-health"
              name="poolPositionsHealth"
              onMouseEnter={this.onMouseEnterColumnHeader}
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              LP Health
            </span>
          </div>
        ),
        accessor: "health",
        minWidth: 45,
        maxWidth: 5 * 30,
        filterable: false,
        sortable: true,
        show: this.state.isChecked.healthPool,
        sortMethod: (a, b) => {
          return b[2] - a[2];
        },
        Cell: row => {
          return <span className={`floatRight monospace ${row.value[1]}`}>{row.value.length === 3 ? row.value[0].toLowerCase() : ""}</span>;
        }
      },
      {
        Header: () => (
          <div>
            <span
              id="pool-positions-status"
              name="poolPositionsStatus"
              onMouseEnter={this.onMouseEnterColumnHeader}
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              LP Status
            </span>
          </div>
        ),
        accessor: "status",
        minWidth: 45,
        maxWidth: 5 * 30,
        filterable: false,
        sortable: true,
        show: this.state.isChecked.statusPool,
        Cell: row => {
          return (
            <span style={{ textTransform: "none" }} className={`floatRight monospace ${row.value && row.value[1]}`}>
              {row.value && row.value[0]}
            </span>
          );
        },
        sortMethod: (a, b) => {
          if (a[0] == "Out of range") return 1;
          return -1;
        }
      },
      {
        Header: () => (
          <div>
            <span id="pool-positions-value" name="poolPositionsValue" onMouseEnter={this.onMouseEnterColumnHeader} onMouseLeave={this.onMouseLeaveColumnHeader}>
              Option Value
            </span>
          </div>
        ),
        accessor: "value",
        minWidth: 50,
        maxWidth: 5 * 40,
        filterable: false,
        sortable: true,
        Cell: row => {
          return (
            <span className="floatRight monospace">
              {row.value.length >= 2 ? row.value && printPriceFromNumber(row.value[0]) : row.value && printPriceFromString(row.value[0])}
            </span>
          );
        },
        sortMethod: (a, b) => {
          return b[0] - a[0];
        },
        Footer: rows => {
          const valueFooter = getValueFooter(rows);

          return (
            <span className="floatRight">
              <strong>{rows.data.length > 0 && rows.data[0].value.length >= 2 ? printPriceFromNumber(valueFooter) : ""}</strong>
            </span>
          );
        }
      },
      {
        Header: () => (
          <div>
            <span id="pool-positions-pnl" name="poolPositionsPnl" onMouseEnter={this.onMouseEnterColumnHeader} onMouseLeave={this.onMouseLeaveColumnHeader}>
              P/L
            </span>
          </div>
        ),
        accessor: "pnl",
        minWidth: 40,
        maxWidth: 3 * 40,
        filterable: false,
        sortable: true,
        show: this.state.isChecked.pnlPool,
        Cell: row => (
          <span className="floatRight monospace">
            {row.value.length === 2
              ? row.value[0] === 0 || Math.abs(row.value[0]).toFixed(2) === "0.00"
                ? printPriceFromString("0")
                : row.value && printPriceFromString(row.value[0].toFixed(2))
              : row.value && printPriceFromString(row.value[0])}
          </span>
        ),
        sortMethod: (a, b) => {
          return a[0] - b[0];
        },
        Footer: rows => (
          <span className="floatRight monospace">
            <strong>
              {rows.data.length > 0 && rows.data[0].pnl.length === 2
                ? getPNLFooter(rows) === 0 || Math.abs(getPNLFooter(rows)).toFixed(2) === "0.00" //check if pnl footer value is 0 or 0.00 or -0.00001 ect. then it will write 0 in table footer
                  ? printPriceFromString("0")
                  : rows.data && printPriceFromString(getPNLFooter(rows).toFixed(2))
                : ""}
            </strong>
          </span>
        )
      },
      {
        Header: () => (
          <div>
            <span id="pool-positions-roi" name="poolPositionsRoi" onMouseEnter={this.onMouseEnterColumnHeader} onMouseLeave={this.onMouseLeaveColumnHeader}>
              ROI
            </span>
          </div>
        ),
        accessor: "roi",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: true,
        show: this.state.isChecked.roiPool,
        Cell: row => (
          <span className="floatRight monospace">
            {row.value.length === 2
              ? row.value[0] === 0 || Math.abs(row.value[0]).toFixed(2) === "0.00"
                ? 0 + "%"
                : limitValue(row.value[0], 100000, 2) + "%"
              : limitValue(row.value[0], 100000, 2)}
          </span>
        ),
        sortMethod: (a, b) => {
          return b[0] - a[0];
        },
        Footer: rows => (
          <span className="floatRight monospace">
            <strong>
              {rows.data.length > 0 && rows.data[0].pnl.length === 2
                ? getROIFooter(rows) === 0 || Math.abs(getROIFooter(rows)).toFixed(2) === "0.00"
                  ? 0 + "%"
                  : limitValue(getROIFooter(rows), 100000, 2) + "%"
                : ""}
            </strong>
          </span>
        )
      },
      {
        Header: () => (
          <div>
            <span id="pool-positions-apr" name="poolPositionsApr" onMouseEnter={this.onMouseEnterColumnHeader} onMouseLeave={this.onMouseLeaveColumnHeader}>
              APR
            </span>
          </div>
        ),
        accessor: "apr",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: true,
        show: this.state.isChecked.aprPool,
        Cell: row => {
          return (
            <span className="floatRight monospace">
              {row.value.length >= 2
                ? row.value[0] === 0 || Math.abs(row.value[0]).toFixed(2) === "0.00"
                  ? 0 + "%"
                  : limitValue(row.value[0], 100000, 2) + "%"
                : row.value !== ""
                ? limitValue(row.value[0], 100000, 2)
                : ""}
            </span>
          );
        },
        sortMethod: (a, b) => {
          return b[0] - a[0];
        },
        Footer: rows => (
          <span className="floatRight monospace">
            <strong>
              {rows.data.length > 0 && rows.data[0].apr.length >= 2
                ? getAPRFooter(rows) === 0 || Math.abs(getAPRFooter(rows)).toFixed(2) === "0.00"
                  ? 0 + "%"
                  : limitValue(getAPRFooter(rows), 100000, 2) + "%"
                : ""}
            </strong>
          </span>
        )
      },
      {
        Header: () => (
          <div>
            <span id="pool-positions-apy" name="poolPositionsApy" onMouseEnter={this.onMouseEnterColumnHeader} onMouseLeave={this.onMouseLeaveColumnHeader}>
              APY
            </span>
          </div>
        ),
        accessor: "apy",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: true,
        show: this.state.isChecked.apyPool,
        Cell: row => (
          <span className="floatRight monospace">
            {row.value.length === 2
              ? row.value[0] === 0 || Math.abs(row.value[0]).toFixed(2) === "0.00"
                ? 0 + "%"
                : limitValue(row.value[0], 100000, 2) + "%"
              : row.value !== ""
              ? limitValue(row.value[0], 100000, 2)
              : ""}
          </span>
        ),
        sortMethod: (a, b) => {
          return b[0] - a[0];
        },
        Footer: rows => (
          <span className="floatRight monospace">
            <strong>
              {rows.data.length > 0 && rows.data[0].apr.length >= 2
                ? getAPYFooter(rows) === 0 || Math.abs(getAPYFooter(rows)).toFixed(2) === "0.00"
                  ? 0 + "%"
                  : limitValue(getAPYFooter(rows), 100000, 2) + "%"
                : ""}
            </strong>
          </span>
        )
      },
      {
        Header: () => (
          <div>
            <span id="pool-positions-delta" name="poolPositionsDelta" onMouseEnter={this.onMouseEnterColumnHeader} onMouseLeave={this.onMouseLeaveColumnHeader}>
              &Delta; delta
            </span>
          </div>
        ),
        accessor: "delta",
        minWidth: 30,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: true,
        show: this.state.isChecked.totalDeltaPool,
        Cell: row => {
          return <span className="floatRight monospace">{row.value.length === 2 ? row.value && formatValue(row.value[0], 2) + "" : ""}</span>;
        },
        sortMethod: (a, b) => {
          return b[0] - a[0];
        },
        Footer: rows => {
          const deltaFooter = getDeltaFooter(rows);
          return (
            <span className="floatRight monospace">
              <strong>
                {rows.data.length > 0 && rows.data[0].delta.length === 2
                  ? deltaFooter === 0
                    ? rows.data && formatValue(deltaFooter, 0) + ""
                    : rows.data && formatValue(deltaFooter, 2) + ""
                  : ""}
              </strong>
            </span>
          );
        }
      },
      {
        Header: () => (
          <div
            id="pool-positions-gamma"
            style={{ textAlign: "center" }}
            name="poolPositionsGamma"
            onMouseEnter={this.onMouseEnterColumnHeader}
            onMouseLeave={this.onMouseLeaveColumnHeader}
          >
            <span style={{ textTransform: "lowercase" }}>&gamma;</span>
            <span> gamma</span>
          </div>
        ),
        accessor: "gamma",
        minWidth: 30,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: false,
        show: this.state.isChecked.totalGammaPool,
        Cell: row => <span className="floatRight monospace">{row.value.length === 2 ? row.value && formatValue(row.value[0], 2) + "" : ""}</span>,
        Footer: rows => {
          const gammaFooter = getGammaFooter(rows);
          return (
            <span className="floatRight monospace">
              <strong>
                {rows.data.length > 0 && rows.data[0].gamma.length === 2
                  ? gammaFooter === 0
                    ? rows.data && formatValue(gammaFooter, 0) + ""
                    : rows.data && formatValue(gammaFooter, 2) + ""
                  : ""}
              </strong>
            </span>
          );
        }
      },
      {
        Header: () => (
          <div>
            <span id="pool-positions-theta" name="poolPositionsTheta" onMouseEnter={this.onMouseEnterColumnHeader} onMouseLeave={this.onMouseLeaveColumnHeader}>
              &Theta; theta
            </span>
          </div>
        ),
        accessor: "theta",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: false,
        show: this.state.isChecked.totalThetaPool,
        Cell: row => <span className="floatRight monospace">{row.value.length === 2 ? row.value && formatValue(row.value[0], 2) + "" : ""}</span>,
        Footer: rows => {
          const thetaFooter = getThetaFooter(rows);
          return (
            <span className="floatRight monospace">
              <strong>
                {rows.data.length > 0 && rows.data[0].theta.length === 2
                  ? thetaFooter === 0
                    ? rows.data && formatValue(thetaFooter, 0) + ""
                    : rows.data && formatValue(thetaFooter, 2) + ""
                  : ""}
              </strong>
            </span>
          );
        }
      },
      {
        Header: () => (
          <div
            id="pool-positions-vega"
            style={{ width: "fit-content", marginLeft: "auto" }}
            name="poolPositionsVega"
            onMouseEnter={this.onMouseEnterColumnHeader}
            onMouseLeave={this.onMouseLeaveColumnHeader}
          >
            V vega
          </div>
        ),
        accessor: "vega",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: false,
        show: this.state.isChecked.totalVegaPool,
        Cell: row => <span className="floatRight monospace">{row.value.length === 2 ? row.value && formatValue(row.value[0], 2) + "" : ""}</span>,
        Footer: rows => {
          const vegaFooter = getVegaFooter(rows);
          return (
            <span className="floatRight monospace">
              <strong>
                {rows.data.length > 0 && rows.data[0].vega.length === 2
                  ? vegaFooter === 0
                    ? rows.data && formatValue(vegaFooter, 0) + ""
                    : rows.data && formatValue(vegaFooter, 2) + ""
                  : ""}
              </strong>
            </span>
          );
        }
      }
    ];

    return tableColumns;
  };

  onRowPositionClicked = (rowInfo, closeAllFilterDropmenus) => {
    closeAllFilterDropmenus();
    const displayName = rowInfo.original.token;
    const token = displayName.split(" ")[0];
    let position = null;
    let market = null;
    let longShortLiq = "LP";
    if (token.startsWith("LP")) {
      position = this.props.openedLPPositions.find(p => token === p.shortName);
      market = position && this.props.markets.find(e => e.marketId === position.marketId);
    } else {
      position = this.props.openedTradePositions.find(p => token === p.shortName);
      market = position && this.props.markets.find(e => e.marketId === position.marketId);
      if (position && position.name.startsWith("SHORT")) {
        longShortLiq = "SHORT";
      } else if (position && position.name.startsWith("LONG")) {
        longShortLiq = "LONG";
      }
    }
    document.querySelector("body").classList.toggle("scrollDisabled");
    this.setState({
      isPositionModalOpen: true,
      selectedPositions: [position],
      selectedPositionMarket: market,
      longShortLiq: longShortLiq
    });
  };

  printPoolTable = (web3AccountLoaded, loadedMethod, tradesAndLps, tableData, tableColummns, closeAllFilterDropmenus) => {
    if (web3AccountLoaded && loadedMethod && tradesAndLps.length > 0) {
      return (
        <div ref={this.tableWrapper}>
          <ReactTable
            className="-highlight"
            data={tableData}
            //filterable
            columns={tableColummns}
            defaultPageSize={5}
            getTrProps={(state, rowInfo, column) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: e => {
                    e.stopPropagation();
                    this.onRowPositionClicked(rowInfo, closeAllFilterDropmenus);
                  },
                  style: {
                    cursor: "pointer"
                  }
                };
              }
              return {};
            }}
            getTfootTrProps={state => {
              return {
                onClick: e => {
                  e.stopPropagation();
                  closeAllFilterDropmenus();
                  // todo: v2 don't split, use 2 values for first column
                  const tokens = state.data.map(p => p.token.split(" ")[0]);
                  const selectedPositions = this.props.openedTradePositions.filter(p => tokens.includes(p.shortName));
                  const selectedLpPositions = this.props.openedLPPositions.filter(p => tokens.includes(p.shortName));
                  if (selectedPositions.length === 0 && selectedLpPositions.length === 0) {
                    return;
                  } else if (selectedPositions.length === 1 && selectedLpPositions.length === 0) {
                    const position = selectedPositions[0];
                    const market = position && this.props.markets.find(e => e.marketId === position.marketId);
                    this.setState({ selectedPositionMarket: market });
                  } else if (selectedPositions.length === 0 && selectedLpPositions.length === 1) {
                    const position = selectedLpPositions[0];
                    const market = position && this.props.markets.find(e => e.marketId === position.marketId);
                    this.setState({ selectedPositionMarket: market });
                  }
                  document.querySelector("body").classList.toggle("scrollDisabled");
                  this.setState({
                    isPositionModalOpen: true,
                    selectedPositions: [...selectedPositions, ...selectedLpPositions]
                  });
                },
                style: {
                  cursor: "pointer"
                }
              };
            }}
          />
        </div>
      );
    }
  };
  toggleTradingModal = toggleDisabledScroll => {
    document.querySelector("body").classList.toggle("scrollDisabled");
    this.setState({ isTradingModalOpen: !this.state.isTradingModalOpen });
  };

  getMaxLongAmount = (market, isBuy) => {
    const marketDepth = calculateMarketDepth(market, this.props.underlyingTokenPrice.normalized);
    if (isBuy) {
      return marketDepth.plusFive;
    } else {
      return marketDepth.minusFive;
    }
  };

  handleClick = (event, isCogPositionsModalOpened, setCogPositionsModalOpen) => {
    const modalIsOpen = !isCogPositionsModalOpened;
    setCogPositionsModalOpen(modalIsOpen);
    if (modalIsOpen) {
      document.querySelector("body").classList.toggle("scrollDisabled");
      let { top } = event.currentTarget.getBoundingClientRect();
      const { width } = event.currentTarget.closest(".card-header").getBoundingClientRect();
      const right = (window.innerWidth - width) / 2;
      const height = window.innerHeight;
      const { bottom: cogBottom } = event.target.getBoundingClientRect();
      cogBottom + 197 > height ? (top -= 300) : (top -= 38);
      this.setState({ isCogModalOpen: !this.state.isCogModalOpen, cogModalPositionTop: top, cogModalPositionRight: right });
      document.getElementById("totalCog").style.transform = "rotate(45deg)";
    }
  };

  toggleCogModal = toggleDisabledScroll => {
    if (toggleDisabledScroll) {
      document.querySelector("body").classList.toggle("scrollDisabled");
    }
    this.setState({ isCogModalOpen: !this.state.isCogModalOpen });
  };

  checkHandler = e => {
    localStorage.setItem(e.target.name + "Pool", !this.state.isChecked[e.target.name + "Pool"]);
    this.setState({ isChecked: { ...this.state.isChecked, [e.target.name + "Pool"]: !this.state.isChecked[e.target.name + "Pool"] } });
  };

  handleShowHidePanel = (lsId, bodyId, arrowDownId, arrowUpId) => {
    const headerActions = document.getElementById("headerActionsPpp");

    if (localStorage.getItem(lsId) === "true") {
      localStorage.setItem(lsId, false);
      headerActions.style.justifyContent = "flex-end";
    } else if (localStorage.getItem(lsId) === "false") {
      localStorage.setItem(lsId, true);
      headerActions.style.justifyContent = "space-between";
    }
  };

  resetPppSwitches = () => {
    localStorage.setItem("timeToExpPool", false);
    localStorage.setItem("createdAtPool", true);
    localStorage.setItem("pnlPool", true);
    localStorage.setItem("roiPool", false);
    localStorage.setItem("aprPool", false);
    localStorage.setItem("apyPool", false);
    localStorage.setItem("totalGammaPool", false);
    localStorage.setItem("totalVegaPool", false);
    localStorage.setItem("totalThetaPool", false);
    localStorage.setItem("totalDeltaPool", true);
    localStorage.setItem("healthPool", true);
    localStorage.setItem("statusPool", true);

    this.setState({
      isChecked: {
        timeToExpPool: false,
        createdAtPool: true,
        pnlPool: true,
        roiPool: false,
        aprPool: false,
        apyPool: false,
        totalGammaPool: false,
        totalVegaPool: false,
        totalThetaPool: false,
        totalDeltaPool: true,
        healthPool: true,
        statusPool: true
      }
    });
  };

  checkDefault = () => {
    return (
      this.state.isChecked.pnlPool &&
      this.state.isChecked.timeToExpPool === false &&
      this.state.isChecked.createdAtPool &&
      this.state.isChecked.roiPool === false &&
      this.state.isChecked.aprPool === false &&
      this.state.isChecked.apyPool === false &&
      this.state.isChecked.totalGammaPool === false &&
      this.state.isChecked.totalDeltaPool &&
      this.state.isChecked.totalVegaPool === false &&
      this.state.isChecked.totalThetaPool === false &&
      this.state.isChecked.healthPool === true &&
      this.state.isChecked.statusPool === true
    );
  };

  togglePositionModal = toggleDisabledScroll => {
    document.querySelector("body").classList.toggle("scrollDisabled");
    this.setState({ isPositionModalOpen: !this.state.isPositionModalOpen });
  };

  setNeedsToBeClosed = value => {
    this.setState({ needsToBeClosed: value });
  };

  render() {
    const isDefault = this.checkDefault();

    const tradesAndLps = [];
    if (this.props.openedTradePositions && this.props.openedTradePositions.length > 0) {
      tradesAndLps.push(...this.props.openedTradePositions);
    }
    if (this.props.openedLPPositions && this.props.openedLPPositions.length > 0) {
      tradesAndLps.push(...this.props.openedLPPositions);
    }

    const allPositionsAreLoaded = this.arePositionsLoaded();

    return (
      <>
        <FilterContext.Consumer>
          {({ closeAllFilterDropmenus, isCogPositionsModalOpened, setCogPositionsModalOpen }) => {
            const isChecked = {
              timeToExp: this.state.isChecked.timeToExpPool,
              createdAt: this.state.isChecked.createdAtPool,
              pnl: this.state.isChecked.pnlPool,
              roi: this.state.isChecked.roiPool,
              apr: this.state.isChecked.aprPool,
              apy: this.state.isChecked.apyPool,
              totalGamma: this.state.isChecked.totalGammaPool,
              totalVega: this.state.isChecked.totalVegaPool,
              totalTheta: this.state.isChecked.totalThetaPool,
              totalDelta: this.state.isChecked.totalDeltaPool,
              health: this.state.isChecked.healthPool,
              status: this.state.isChecked.statusPool
            };

            closeAllFilterDropmenusRef = closeAllFilterDropmenus;

            return (
              <>
                <FiltersPositions
                  parent={this.tableWrapper.current}
                  searchLongShortLpParam={this.state.searchLongShortLpParam}
                  setSearchLongShortLpParamState={this.setSearchLongShortLpParamState}
                  searchCallPutParam={this.state.searchCallPutParam}
                  setSearchCallPutParamState={this.setSearchCallPutParamState}
                  expirationTimesSearchParam={this.state.expirationTimesSearchParam}
                  expirationTimesSelected={this.state.expirationTimesSelected}
                  setExpirationTimesSearchParam={this.setExpirationTimesSearchParam}
                  setExpirationTimesSelected={this.setExpirationTimesSelected}
                  strikesSearchParam={this.state.strikesSearchParam}
                  strikesSelected={this.state.strikesSelected}
                  setStrikesSearchParam={this.setStrikesSearchParam}
                  setStrikesSelected={this.setStrikesSelected}
                  longsShortsLpsSelected={this.state.longsShortsLpsSelected}
                  setLongsShortsLpsSelected={this.setLongsShortsLpsSelected}
                  searchParamsAreDefault={this.areDefaultSearchParams}
                  resetFiltersToDefault={this.resetFiltersToDefault}
                />

                {this.state.isTradingModalOpen && this.state.selectedPositions[0].type === "lp" ? (
                  <PoolModal
                    isOpen={this.state.isTradingModalOpen}
                    toggle={this.toggleTradingModal}
                    market={this.state.selectedPositionMarket}
                    getMaxLongAmount={this.getMaxLongAmount}
                    size={this.state.selectedPositions && this.state.selectedPositions[0].balance}
                    longShortLiq={this.state.selectedPositions && this.state.selectedPositions[0].type}
                    isCall={this.state.selectedPositions && this.state.selectedPositions[0].isCall}
                    marketName={this.state.selectedPositions && this.state.selectedPositions[0].shortName}
                    needsToBeClosed={this.state.needsToBeClosed}
                    setNeedsToBeClosed={this.setNeedsToBeClosed}
                    selectedPosition={this.state.selectedPositions[0]}
                  />
                ) : null}
                {this.state.isTradingModalOpen && this.state.selectedPositions[0].type != "lp" ? (
                  <TradeModal
                    isOpen={this.state.isTradingModalOpen}
                    toggle={this.toggleTradingModal}
                    market={this.state.selectedPositionMarket}
                    getMaxLongAmount={this.getMaxLongAmount}
                    size={this.state.selectedPositions && this.state.selectedPositions[0].balance}
                    isLong={this.state.selectedPositions && this.state.selectedPositions[0].type === "long"}
                    isCall={this.state.selectedPositions && this.state.selectedPositions[0].isCall}
                    marketName={this.state.selectedPositions && this.state.selectedPositions[0].shortName}
                    needsToBeClosed={this.state.needsToBeClosed}
                    setNeedsToBeClosed={this.setNeedsToBeClosed}
                  />
                ) : null}
                {this.state.isPositionModalOpen ? (
                  <PositionModal
                    isOpen={this.state.isPositionModalOpen}
                    toggle={this.togglePositionModal}
                    toggleTradingModal={this.toggleTradingModal}
                    setNeedsToBeClosed={this.setNeedsToBeClosed}
                    selectedPositions={this.state.selectedPositions}
                    onRowPositionClicked={this.onRowPositionClicked}
                    closeAllFilterDropmenus={closeAllFilterDropmenus}
                  />
                ) : null}
                <div className="content" style={{ paddingTop: "0px" }}>
                  <Row>
                    <Col className="mb-1" md="12">
                      <Card
                        id={CARD_TITLE.toLowerCase()
                          .split(" ")
                          .join("_")}
                      >
                        <CardHeader
                          className="handle"
                          onDoubleClick={e => {
                            e.stopPropagation();
                            closeAllFilterDropmenus();
                            this.setPanelIsDisplayed(!this.state.panelIsDisplayed);
                            this.handleShowHidePanel("isPppDisplayed", "ppp", "pppArrowDown", "pppArrowUp");
                          }}
                        >
                          <CardTitle tag="h4">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                height: "100%",
                                alignSelf: "center",
                                alignItems: "center"
                              }}
                            >
                              <span className="headerTitle">{CARD_TITLE}</span>
                              <div id="headerActionsPpp" className="headerCogArrowContainer">
                                <div
                                  onClick={e => {
                                    e.stopPropagation();
                                    closeAllFilterDropmenus();
                                    this.handleClick(e, isCogPositionsModalOpened, setCogPositionsModalOpen);
                                  }}
                                  id="totalCog"
                                  className={this.state.panelIsDisplayed ? "cogHeaderWrapper" : "cogHeaderWrapper hidden"}
                                >
                                  <img id={this.state.idPrefix + "table-settings"} style={{ margin: "auto" }} className="cog" />
                                  <MyTooltip key={this.state.idPrefix + "table-settings"} target={this.state.idPrefix + "table-settings"} optionalOffset={15}>
                                    Table settings
                                  </MyTooltip>
                                </div>
                                {this.state.panelIsDisplayed && (
                                  <>
                                    <div
                                      id="pppArrowUp"
                                      className="arrowHeaderWrapper"
                                      onClick={e => {
                                        e.stopPropagation();
                                        closeAllFilterDropmenus();
                                        this.setPanelIsDisplayed(false);
                                        this.handleShowHidePanel("isPppDisplayed", "ppp", "pppArrowDown", "pppArrowUp");
                                        this.setState({ tooltipContent: "Show panel" });
                                      }}
                                      onMouseEnter={e => {
                                        if (!this.state.tooltipIsVisible) {
                                          this.setState({ tooltipIsVisible: true, tooltipContent: "Hide panel", tooltipTarget: e.currentTarget.id });
                                        }
                                      }}
                                      onMouseLeave={this.onMouseLeaveColumnHeader}
                                    >
                                      <img className="arrowUpIcon" style={{ margin: "auto" }} />
                                    </div>
                                  </>
                                )}
                                {!this.state.panelIsDisplayed && (
                                  <>
                                    <div
                                      id="pppArrowDown"
                                      className="arrowHeaderWrapper"
                                      onClick={e => {
                                        e.stopPropagation();
                                        closeAllFilterDropmenus();
                                        this.setPanelIsDisplayed(true);
                                        this.handleShowHidePanel("isPppDisplayed", "ppp", "pppArrowDown", "pppArrowUp");
                                        this.setState({ tooltipContent: "Hide panel" });
                                      }}
                                      onMouseEnter={e => {
                                        if (!this.state.tooltipIsVisible) {
                                          this.setState({ tooltipIsVisible: true, tooltipContent: "Show panel", tooltipTarget: e.currentTarget.id });
                                        }
                                      }}
                                      onMouseLeave={this.onMouseLeaveColumnHeader}
                                    >
                                      <img style={{ margin: "auto" }} className="arrowDownIcon" />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </CardTitle>
                        </CardHeader>
                        <CardBody id="ppp" className={this.state.panelIsDisplayed ? "" : "displayNone"}>
                          {this.printPoolTable(
                            this.props.web3AccountLoaded,
                            allPositionsAreLoaded,
                            tradesAndLps,
                            this.filterTableData(),
                            this.getTableColumns(),
                            closeAllFilterDropmenus
                          )}
                          {printEmptyTable(this.props.web3AccountLoaded, allPositionsAreLoaded, tradesAndLps, EMPTY_TABLE_TEXT)}
                          {printLoadingIcon(this.props.web3AccountLoaded, allPositionsAreLoaded)}
                          <ConnectPanel />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  {isCogPositionsModalOpened && (
                    <CogPositionsModal
                      cogModalPositionTop={this.state.cogModalPositionTop}
                      cogModalPositionRight={this.state.cogModalPositionRight}
                      isChecked={isChecked}
                      isDefault={isDefault}
                      checkHandler={this.checkHandler}
                      resetSwitches={this.resetPppSwitches}
                      setCogPositionsModalOpen={setCogPositionsModalOpen}
                    />
                  )}
                </div>
              </>
            );
          }}
        </FilterContext.Consumer>
        {this.state.tooltipIsVisible && (
          <MyTooltip2
            target={this.state.tooltipTarget}
            setTooltipIsVisible={this.setTooltipIsVisible}
            setTooltipContent={this.setTooltipContent}
            setTooltipTarget={this.setTooltipTarget}
          >
            {this.state.tooltipContent}
          </MyTooltip2>
        )}
      </>
    );
  }
}

PoolPositionsPanel.propTypes = {
  openedTradePositions: PropTypes.array,
  openedLPPositions: PropTypes.array,
  web3: PropTypes.object,
  web3Account: PropTypes.string,
  web3AccountLoaded: PropTypes.bool,
  underlyingTokenPrice: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  markets: PropTypes.array,
  selectedTokenPair: PropTypes.object,
  marketsLoaded: PropTypes.bool,
  settlements: PropTypes.array,
  currentTimestamp: PropTypes.number,
  marginPoolData: PropTypes.object,
  marginPoolDataLoaded: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    openedTradePositions: openedTradePositionsSelector(state),
    openedLPPositions: openedLPPositionsSelector(state),
    web3: web3Selector(state),
    web3Account: web3AccountSelector(state),
    web3AccountLoaded: web3AccountLoadedSelector(state),
    underlyingTokenPrice: avgUnderlyingTokenPriceSelector(state),
    markets: marketsSelector(state),
    selectedTokenPair: selectedTokenPairSelector(state),
    marketsLoaded: marketsLoadedSelector(state),
    settlements: settlementDataSelector(state),
    currentTimestamp: currentTimestampSelector(state),
    marginPoolData: marginPoolDataSelector(state),
    marginPoolDataLoaded: marginPoolDataLoaded(state)
  };
}

export default connect(mapStateToProps)(PoolPositionsPanel);
