import React from "react";
import PropTypes from "prop-types";

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    this.props.handleError();
  }

  render() {
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  handleError: PropTypes.func.isRequired
};

export default ErrorBoundary;
