import { formatUnits } from "@ethersproject/units";
import { getLpPositionTokens, getTokenPairsList, reloadMarketTokensBalances2 } from "./tokensUtils";
import { store } from "../configureStore";
import { getMuttableMarketAttributes } from "./marketsUtils";
import { loadMarginAccountData2, loadMarginPoolData2 } from "./marginUtils";

export const getTrades = (
  tradesData,
  tradesLoaded,
  marketsData,
  tokenPairs,
  tokenPairsLoaded,
  positionTokens,
  positionTokensLoaded,
  web3accountDataLoaded,
  web3account
) => {
  let startTime = new Date().getTime();
  if (tradesLoaded && tokenPairsLoaded && positionTokensLoaded && web3accountDataLoaded) {
    // filter trades based on web3account, if undefined, use all trades
    if (web3account) {
      tradesData = tradesData.filter(trade => trade.user === web3account);
    }

    const tradeMarketIds = tradesData.map(trade => trade.marketId);

    // Filter positionTokens based on tradeMarketIds
    let filteredPositionTokens = positionTokens.filter(token => tradeMarketIds.includes(token.marketId));

    const returnData = tradesData.map(trade => {
      // find market with matching address
      let market = marketsData.find(market => {
        return market.marketId === trade.marketId;
      });

      const tokenPairsList = getTokenPairsList(tokenPairs);

      // find token pair for underlying price
      let tokenPair = tokenPairsList.find(tokenPair => {
        return tokenPair.baseTokenAddress === market.baseTokenAddress && tokenPair.underlyingTokenAddress === market.underlyingTokenAddress;
      });
      const dateString = getDateString(new Date(trade.timestamp * 1000));

      // NOTE: this selector took 17 secs with bellow line, and it doesn't do shit
      // filteredPositionTokens = getPositionTokens(filteredPositionTokens); // todo: even this can be removed

      // use position token for name
      let positionToken = filteredPositionTokens.find(positionToken => {
        return positionToken.marketId === trade.marketId;
      });
      let interest = 0;
      if (trade.interest) {
        interest = trade.interest.toString() / 1e18;
      }
      return {
        marketId: trade.marketId.toString(),
        name: positionToken.name,
        type: trade.type,
        isBuy: trade.isBuy,
        size: trade.size.toString() / 1e18,
        // size: trade.size,
        // basePrice: parseFloat(formatUnits(trade.basePrice.toString(), 18)),
        // collPrice: parseFloat(formatUnits(trade.collPrice.toString(), 18)),
        basePrice: trade.basePrice,
        collPrice: trade.collPrice,
        interest: interest,
        timestamp: trade.timestamp.toString(),
        readableTimestamp: dateString,
        tokenPair: tokenPair,
        shortName: positionToken.shortName,
        isLiquidation: trade.isLiquidation ? true : false
      };
    });

    let endTime = new Date().getTime();
    console.log("Selector: getTrades took ", endTime - startTime, "ms");
    return returnData;
  }
  return [];
};

export const getOthersTrades = (
  tradesData,
  tradesLoaded,
  marketsData,
  tokenPairs,
  tokenPairsLoaded,
  positionTokens,
  positionTokensLoaded,
  web3accountDataLoaded,
  web3account
) => {
  let startTime = new Date().getTime();
  // eslint-disable-next-line no-undef
  const longShortTradesGroupByAccount = new Map();

  if (tradesLoaded && tokenPairsLoaded && positionTokensLoaded && web3accountDataLoaded) {
    tradesData = tradesData.filter(trade => trade.user !== web3account);
    tradesData = tradesData.map(trade => {
      // find market with matching address
      let market = marketsData.find(market => {
        return market.marketId === trade.marketId;
      });

      const tokenPairsList = getTokenPairsList(tokenPairs);
      // find token pair for underlying price
      let tokenPair = tokenPairsList.find(tokenPair => {
        return tokenPair.baseTokenAddress === market.baseTokenAddress && tokenPair.underlyingTokenAddress === market.underlyingTokenAddress;
      });
      const dateString = getDateString(new Date(trade.timestamp * 1000));

      // positionTokens = getPositionTokens(positionTokens);

      // use position token for name
      let positionToken = positionTokens.find(positionToken => {
        return positionToken.marketId === trade.marketId;
      });
      return {
        marketId: trade.marketId.toString(),
        name: positionToken.name,
        type: trade.type,
        isBuy: trade.isBuy,
        size: parseFloat(formatUnits(trade.size.toString(), 18)),
        // size: trade.size,
        // basePrice: parseFloat(formatUnits(trade.basePrice.toString(), 18)),
        // collPrice: parseFloat(formatUnits(trade.collPrice.toString(), 18)),
        basePrice: trade.basePrice,
        collPrice: trade.collPrice,
        timestamp: trade.timestamp.toString(),
        readableTimestamp: dateString,
        tokenPair: tokenPair,
        shortName: positionToken.shortName,
        account: trade.user,
        isLiquidation: trade.isLiquidation ? true : false
      };
    });
  }

  tradesData.forEach(lt => {
    if (longShortTradesGroupByAccount.get(lt.account)) {
      longShortTradesGroupByAccount.get(lt.account).push(lt);
    } else {
      longShortTradesGroupByAccount.set(lt.account, [lt]);
    }
  });

  let endTime = new Date().getTime();
  console.log("Selector: getOthersTrades took ", endTime - startTime, "ms");
  return longShortTradesGroupByAccount;
};

export const getLpTrades = (
  lpTrades,
  lpTradesLoaded,
  marketsData,
  tokenPairs,
  tokenPairsLoaded,
  lpPositionTokens,
  lpPositionTokensLoaded,
  web3accountDataLoaded,
  web3account
) => {
  let startTime = new Date().getTime();
  if (lpTrades && lpTradesLoaded && tokenPairsLoaded && lpPositionTokensLoaded && web3accountDataLoaded) {
    lpTrades = lpTrades.filter(trade => trade.user === web3account);
    lpPositionTokens = getLpPositionTokens(lpPositionTokens);
    const tokenPairsList = getTokenPairsList(tokenPairs);

    const returnData = lpTrades.map(trade => {
      // find market with matching address
      let market = marketsData.find(market => {
        return market.marketId === trade.marketId;
      });
      // find token pair for underlying price
      let tokenPair = tokenPairsList.find(tokenPair => {
        return tokenPair.baseTokenAddress === market.baseTokenAddress && tokenPair.underlyingTokenAddress === market.underlyingTokenAddress;
      });
      const dateString = getDateString(new Date(trade.timestamp * 1000));

      // use position token for name
      let positionToken = lpPositionTokens.find(positionToken => {
        return positionToken.marketId === trade.marketId && trade.positionId === positionToken.positionId;
      });
      let interest = 0;
      if (trade.interest) {
        interest = parseFloat(formatUnits(trade.interest.toString(), 18));
      }
      return {
        marketId: trade.marketId.toString(),
        positionId: trade.positionId.toString(),
        name: positionToken ? positionToken.name : "",
        type: "lp",
        isBuy: trade.isBuy,
        isPenaltyOrReward: trade.isPenaltyOrReward ? true : false, // undefined to false
        size: parseFloat(formatUnits(trade.size.toString(), 18)),
        // basePrice: parseFloat(formatUnits(trade.basePrice.toString(), 18)),
        // collPrice: parseFloat(formatUnits(trade.collPrice.toString(), 18)),
        // shortPrice: parseFloat(formatUnits(trade.shortPrice.toString(), 18)),
        // size: trade.size,
        basePrice: trade.basePrice,
        collPrice: trade.collPrice,
        shortPrice: trade.shortPrice,
        // longPriceInBase: parseFloat(formatUnits(trade.longPriceInBase.toString(), 18)),
        longPriceInBase: trade.longPriceInBase,
        interest: interest,
        timestamp: trade.timestamp.toString(),
        readableTimestamp: dateString,
        tokenPair: tokenPair,
        shortName: positionToken ? positionToken.shortName : "",
        liquidator: trade.liquidator ? trade.liquidator : null,
        lower: trade.lower,
        upper: trade.upper,
        isLiquidation: trade.isLiquidation ? true : false
      };
    });

    let endTime = new Date().getTime();
    // console.log("Selector: getLpTrades took ", endTime - startTime, "ms");

    return returnData;
  }
  return null;
};

// todo: finish this once we have liquidation trades
export const getLiquidationTrades = (liquidationTrades, liquidationTradesLoaded, web3accountDataLoaded, web3account) => {
  if (liquidationTradesLoaded && web3accountDataLoaded) {
    const userLiquidationTrades = liquidationTrades.filter(trade => trade.user === web3account);

    return userLiquidationTrades.map(trade => {
      const dateString = getDateString(new Date(trade.timestamp * 1000));
      return {
        ...trade,
        readableTimestamp: dateString,
        type: "account"
      };
    });
  }
  return [];
};

export const getAllLPTrades = (lpTrades, lpTradesLoaded, marketsData, tokenPairs, tokenPairsLoaded, lpPositionTokens, lpPositionTokensLoaded) => {
  if (lpTrades && lpTradesLoaded && tokenPairsLoaded && lpPositionTokensLoaded) {
    lpPositionTokens = getLpPositionTokens(lpPositionTokens);
    const tokenPairsList = getTokenPairsList(tokenPairs);

    return lpTrades.map(trade => {
      // find market with matching address
      let market = marketsData.find(market => {
        return market.marketId === trade.marketId;
      });

      // find token pair for underlying price
      let tokenPair = tokenPairsList.find(tokenPair => {
        return tokenPair.baseTokenAddress === market.baseTokenAddress && tokenPair.underlyingTokenAddress === market.underlyingTokenAddress;
      });
      const dateString = getDateString(new Date(trade.timestamp * 1000));

      // use position token for name
      let positionToken = lpPositionTokens.find(positionToken => {
        return positionToken.marketId === trade.marketId && trade.positionId === positionToken.positionId;
      });

      return {
        marketId: trade.marketId.toString(),
        positionId: trade.positionId.toString(),
        name: positionToken ? positionToken.name : "",
        type: "lp",
        isBuy: trade.isBuy,
        size: parseFloat(formatUnits(trade.size.toString(), 18)),
        // basePrice: parseFloat(formatUnits(trade.basePrice.toString(), 18)),
        // collPrice: parseFloat(formatUnits(trade.collPrice.toString(), 18)),
        // shortPrice: parseFloat(formatUnits(trade.shortPrice.toString(), 18)),
        // longPriceInBase: parseFloat(formatUnits(trade.longPriceInBase.toString(), 18)),
        // size: trade.size,
        basePrice: trade.basePrice,
        collPrice: trade.collPrice,
        shortPrice: trade.shortPrice,
        longPriceInBase: trade.longPriceInBase,
        timestamp: trade.timestamp.toString(),
        readableTimestamp: dateString,
        tokenPair: tokenPair,
        shortName: positionToken ? positionToken.shortName : "",
        liquidator: trade.liquidator ? trade.liquidator : null,
        lower: trade.lower,
        upper: trade.upper,
        isLiquidation: trade.isLiquidation ? true : false
      };
    });
  }
  return null;
};

export const getOthersLpTradesGroupByAccount = (
  lpTrades,
  lpTradesLoaded,
  marketsData,
  tokenPairs,
  tokenPairsLoaded,
  lpPositionTokens,
  lpPositionTokensLoaded,
  web3accountDataLoaded,
  web3account
) => {
  // eslint-disable-next-line no-undef
  const lpTradesGroupByAccount = new Map();

  if (lpTrades && lpTradesLoaded && tokenPairsLoaded && lpPositionTokensLoaded && web3accountDataLoaded) {
    lpTrades = lpTrades.filter(trade => trade.user !== web3account);
    lpPositionTokens = getLpPositionTokens(lpPositionTokens);
    const tokenPairsList = getTokenPairsList(tokenPairs);

    lpTrades = lpTrades.map(trade => {
      // find market with matching address
      let market = marketsData.find(market => {
        return market.marketId === trade.marketId;
      });
      // find token pair for underlying price
      let tokenPair = tokenPairsList.find(tokenPair => {
        return tokenPair.baseTokenAddress === market.baseTokenAddress && tokenPair.underlyingTokenAddress === market.underlyingTokenAddress;
      });
      const dateString = getDateString(new Date(trade.timestamp * 1000));

      // use position token for name
      let positionToken = lpPositionTokens.find(positionToken => {
        return positionToken.marketId === trade.marketId && trade.positionId === positionToken.positionId;
      });

      return {
        marketId: trade.marketId.toString(),
        positionId: trade.positionId.toString(),
        name: positionToken ? positionToken.name : "",
        type: "lp",
        isBuy: trade.isBuy,
        size: parseFloat(formatUnits(trade.size.toString(), 18)),
        // basePrice: parseFloat(formatUnits(trade.basePrice.toString(), 18)),
        // collPrice: parseFloat(formatUnits(trade.collPrice.toString(), 18)),
        // shortPrice: parseFloat(formatUnits(trade.shortPrice.toString(), 18)),
        // longPriceInBase: parseFloat(formatUnits(trade.longPriceInBase.toString(), 18)),
        // size: trade.size,
        basePrice: trade.basePrice,
        collPrice: trade.collPrice,
        shortPrice: trade.shortPrice,
        longPriceInBase: trade.longPriceInBase,
        timestamp: trade.timestamp.toString(),
        readableTimestamp: dateString,
        tokenPair: tokenPair,
        shortName: positionToken ? positionToken.shortName : "",
        account: trade.user,
        isLiquidation: trade.isLiquidation ? true : false
      };
    });
  }

  lpTrades.forEach(lt => {
    if (lpTradesGroupByAccount.get(lt.account)) {
      lpTradesGroupByAccount.get(lt.account).push(lt);
    } else {
      lpTradesGroupByAccount.set(lt.account, [lt]);
    }
  });

  return lpTradesGroupByAccount;
};

export const getLongShortPositionsFromAccount = (positionTokensData, tradesData, marketsData, tokenPairsList) => {
  let startTime = new Date().getTime();
  const allNames = [
    // eslint-disable-next-line no-undef
    ...new Set(
      tradesData.map(element => {
        return element.name;
      })
    )
  ];

  let longShortPositionTokensData = positionTokensData.filter(positionToken => allNames.includes(positionToken.name));

  let returnData = longShortPositionTokensData.map(positionToken => {
    // find market where position token is at
    let market = marketsData.find(market => {
      if (positionToken.type === "long") {
        return market.marketId == positionToken.tokenId;
      } else {
        return market.marketId == (parseInt(positionToken.tokenId) - 1).toString();
      }
    });
    // find token pair for underlying price
    let tokenPair = tokenPairsList.find(tokenPair => {
      return tokenPair.baseTokenAddress === market.baseTokenAddress && tokenPair.underlyingTokenAddress === market.underlyingTokenAddress;
    });
    // get all trades with current position token
    let sameTokenTrades = tradesData.filter(trade => {
      return trade.marketId === market.marketId; // && trade.type === positionToken.type;
    });

    //console.log(sameTokenTrades)

    // calculate cumulative open price for current opened position
    let totalBaseCost = 0,
      totalCollCost = 0,
      totalTokens = 0,
      openTime = 10000000000;
    sameTokenTrades.forEach(trade => {
      totalBaseCost += trade.size * trade.basePrice * (trade.isBuy ? 1 : -1);
      totalCollCost += trade.size * trade.collPrice * (trade.isBuy ? 1 : -1);
      totalTokens += trade.size * (trade.isBuy ? 1 : -1);

      // get opening time
      openTime = Math.min(openTime, parseInt(trade.timestamp));
    });
    // const normalizedOpenLongPrice = parseFloat(market.openLongPrice / 10 ** tokenPair.baseTokenDecimals);
    // const normalizedCloseLongPrice = parseFloat(market.closeLongPrice / 10 ** tokenPair.baseTokenDecimals);
    const normalizedOpenLongPrice = parseFloat(market.openLongPrice.toString());
    const normalizedCloseLongPrice = parseFloat(market.closeLongPrice.toString());
    // const normalizedOpenShortPrice = [
    //   parseFloat(formatUnits(market.openShortPrice[0].toString(), tokenPair.baseTokenDecimals)),
    //   parseFloat(formatUnits(market.openShortPrice[1].toString(), collateralDecimals))
    // ];
    const normalizedOpenShortPrice = [parseFloat(market.openShortPrice[0].toString()), parseFloat(market.openShortPrice[1].toString())];
    // const normalizedCloseShortPrice = [
    //   parseFloat(formatUnits(market.closeShortPrice[0].toString(), tokenPair.baseTokenDecimals)),
    //   parseFloat(formatUnits(market.closeShortPrice[1].toString(), collateralDecimals))
    // ];
    const normalizedCloseShortPrice = [parseFloat(market.closeShortPrice[0].toString()), parseFloat(market.closeShortPrice[1].toString())];
    // const decoratedStrikePrice = decorateStrikePrices(market.strikePrice, market.baseTokenDecimals);

    return {
      name: positionToken.name,
      tokenId: positionToken.tokenId,
      marketId: positionToken.marketId,
      // balance: parseFloat(formatUnits(positionToken.accountBalance.toString(), 18)),
      balance: Math.abs(totalTokens),
      openBasePrice: totalBaseCost / totalTokens,
      openCollPrice: totalCollCost / totalTokens,
      midLongPrice: (normalizedOpenLongPrice + normalizedCloseLongPrice) / 2,
      midShortPrice: [(normalizedOpenShortPrice[0] + normalizedCloseShortPrice[0]) / 2, (normalizedOpenShortPrice[1] + normalizedCloseShortPrice[1]) / 2],
      closeLongPrice: normalizedCloseLongPrice,
      closeShortPrice: [normalizedCloseShortPrice[0], normalizedCloseShortPrice[1]],
      expirationTime: market.expirationTime,
      isCall: market.isCall,
      strikePrice: market.normalizedStrikePrice,
      tokenPair: tokenPair,
      type: totalTokens > 0 ? "long" : "short", //positionToken.type,
      openTime: openTime.toString(),
      shortName: positionToken.shortName
    };
  });

  // filter out positions with very low balance (closed positions)
  // NOTE: balances are calculated from events, so if user opens then closes a position,
  // without filtering it will include position with 0 balance
  returnData = returnData.filter(position => position.balance > 1000000 / 10 ** 18);

  let endTime = new Date().getTime();
  // console.log("Selector: getLongShortPositionsFromAccount took ", endTime - startTime, "ms");

  return returnData;
};

// todo: v1 this method is exactly the same in positionManagerSelector, use code from selector as it's updated
export const getLpPositionsFromAccount = (lpPositionTokensData, lpTradesData, tokenPairsLoaded, marketsData, tokenPairsList) => {
  let startTime = new Date().getTime();
  const allNames = lpTradesData.map(element => {
    return element.name;
  });

  if (lpPositionTokensData && lpPositionTokensData.length > 0 && lpTradesData && lpTradesData.length > 0 && tokenPairsLoaded) {
    lpPositionTokensData = lpPositionTokensData.filter(positionToken => allNames.includes(positionToken.name));

    if (lpPositionTokensData.length === 0) {
      return null;
    }

    // if not all data loaded, return null
    for (let positionToken of lpPositionTokensData) {
      if (!positionToken.accountBalanceLoaded) {
        return null;
      }
    }

    let returnData = lpPositionTokensData.map(positionToken => {
      // find market where nft position token is at
      let market = marketsData.find(market => {
        return market.marketId === positionToken.marketId;
      });

      // find token pair for underlying price
      let tokenPair = tokenPairsList.find(tokenPair => {
        return tokenPair.baseTokenAddress === market.baseTokenAddress && tokenPair.underlyingTokenAddress === market.underlyingTokenAddress;
      });

      // get all trades with current position token
      let sameTokenTrades = lpTradesData.filter(trade => {
        return trade.marketId === market.marketId && trade.positionId.toString() === positionToken.positionId.toString();
      });

      // calculate cumulative open price for current opened position
      let totalBaseCost = 0,
        totalCollCost = 0,
        totalShortCost = 0,
        totalCollCostInBase = 0,
        totalShortCostInBase = 0,
        totalTokens = 0,
        totalOpenInBase = 0,
        openTime = 10000000000;
      //liqBalance = 0;

      sameTokenTrades.sort((a, b) => {
        return parseInt(b.timestamp) - parseInt(a.timestamp);
      });
      sameTokenTrades.forEach(trade => {
        // const normalizedBasePrice = trade.basePrice;
        // const normalizedCollPrice = trade.collPrice;
        // const normalizedShortPrice = trade.shortPrice;
        // const normalizedTradeSize = trade.size;

        //liqBalance += trade.isBuy ? trade.size : -trade.size;

        totalBaseCost += trade.size * trade.basePrice * (trade.isBuy ? 1 : -1);
        totalCollCost += trade.size * trade.collPrice * (trade.isBuy ? 1 : -1);
        totalShortCost += trade.size * trade.shortPrice * (trade.isBuy ? 1 : -1);
        totalTokens += trade.size * (trade.isBuy ? 1 : -1);

        // use different calculation for increase/decrease
        if (trade.size === 0) {
          totalBaseCost += (trade.isBuy ? 1 : -1) * trade.basePrice;
        }

        // calculate cost in base for longs part
        const collCost = trade.size * trade.collPrice * (trade.isBuy ? 1 : -1);
        totalCollCostInBase += collCost * trade.longPriceInBase;

        // calculate cost in base for short part
        const shortCost = trade.size * trade.shortPrice * (trade.isBuy ? 1 : -1);
        totalShortCostInBase += shortCost * tokenPair.normalizedUnderlyingTokenPrice - trade.longPriceInBase; // todo: v1 use under price on open, must be from event

        // opening time
        openTime = Math.min(openTime, parseInt(trade.timestamp));
      });

      totalBaseCost -= market.penaltyBase;

      // old code
      //totalOpenInBase = totalCollCostInBase / (market.isCall ? 1 : parseFloat(market.normalizedStrikePrice)) + totalShortCostInBase + totalBaseCost;
      const baseBalance = totalBaseCost; //parseFloat(formatUnits(positionToken.baseBalance, tokenPair.baseTokenDecimals));
      const feeBalance = 0; //parseFloat(ethers.utils.formatUnits(positionToken.feeBalance, tokenPair.baseTokenDecimals));
      const normalizedLiqPrice = [
        (baseBalance + feeBalance) / totalTokens,
        totalShortCost / totalTokens, // long is the same as short
        totalShortCost / totalTokens //
        // parseFloat(formatUnits(positionToken.longBalance, 18)) / totalTokens,
        // parseFloat(formatUnits(positionToken.shortBalance, 18)) / totalTokens
      ];
      // const normalizedOpenLongPrice = parseFloat(formatUnits(market.openLongPrice.toString(), tokenPair.baseTokenDecimals));
      // const normalizedCloseLongPrice = parseFloat(formatUnits(market.closeLongPrice.toString(), tokenPair.baseTokenDecimals));
      const normalizedOpenLongPrice = parseFloat(market.openLongPrice.toString());
      const normalizedCloseLongPrice = parseFloat(market.closeLongPrice.toString());

      return {
        // immutables
        positionId: positionToken.positionId.toString(),
        type: "lp",
        name: positionToken.name,
        shortName: positionToken.shortName,
        marketId: positionToken.marketId,
        expirationTime: market.expirationTime,
        isCall: market.isCall,
        strikePrice: market.normalizedStrikePrice,
        tokenPair: tokenPair,
        lower: positionToken.lower,
        upper: positionToken.upper,
        openTime: openTime.toString(),

        // now
        balance: totalTokens, //parseFloat(formatUnits(positionToken.accountBalance.toString(), 18)),
        balanceString: totalTokens.toString(), //formatUnits(positionToken.accountBalance.toString(), 18),
        liqPrice: normalizedLiqPrice, // how much base, longs, and shorts is 1 liq token
        sizeInLongs: parseFloat(formatUnits(positionToken.sizeInLongs.toString(), 18)),
        midLongPrice: (normalizedOpenLongPrice + normalizedCloseLongPrice) / 2,
        feeBalance: parseFloat(formatUnits(positionToken.feeBalance.toString(), 18)),

        // open
        openLiqPrice: [totalBaseCost / totalTokens, totalShortCost / totalTokens, totalShortCost / totalTokens]
        //openLiqPrice: [totalBaseCost / totalTokens, 0, 0]
      };
    });

    // filter out positions with very low balance (closed positions)
    // NOTE: balances are calculated from events, so if user opens then closes lp position,
    // without filtering it will include position with 0 balance
    returnData = returnData.filter(position => position.balance > 1000000 / 10 ** 18);

    let endTime = new Date().getTime();
    // console.log("Selector: getLpPositionsFromAccount took ", endTime - startTime, "ms");

    return returnData;
  }
  return null;
};

export const getDateString = date => {
  let dateString =
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2) +
    " " +
    ("0" + date.getHours()).slice(-2) +
    ":" +
    ("0" + date.getMinutes()).slice(-2);

  return dateString;
};

// todo: remove as it's moved to worker
export const handleOnCloseLongPositionEvent = async (
  riskFreeRate,
  web3,
  userAccount,
  marginPool,
  gammaContractsRegistry,
  dispatch,
  marketId,
  user,
  longSent,
  baseReceived,
  timestamp,
  eventSignature
) => {
  // check if received event already exists
  let { trades, tokenPairs, lpPositionTokens, tokens } = store.getState();
  const underPriceBN = tokenPairs.selectedTokenPair.underlyingTokenPrice;

  if (trades.loaded) {
    const existingTrade = trades.data.find(trade => trade.signature === eventSignature);
    if (existingTrade) {
      return;
    }
  }

  // check if for some reason longSent is 0 (to avoid division by 0)
  if (longSent.toString() === "0") return;

  const { loadHelper, userBalances } = store.getState().contracts.data;
  console.log(loadHelper)
  const { marketsImmutable } = store.getState();
  const marketImmutableData = marketsImmutable.data.find(market => market.marketId === marketId.toString());
  // check if there is a market in state (happens when bot is creating markets fast, we receive event before MarketCreated event)
  if (!marketImmutableData) return;

  const mutableMarketAttributes = await getMuttableMarketAttributes(loadHelper, userBalances, marketId, marketImmutableData, riskFreeRate);

  // if some user bought long on market where userAccount has lp position, then update lp position balance
  const positionTokens = lpPositionTokens.data.filter(t => t.accountBalance != "0" && marketId.toString() === t.marketId.toString());
  const positionIds = [];
  const positionDatas = [];
  const healths = [];
  for (let positionToken of positionTokens) {
    // loadAccountLPBalance(loadHelper, marketId.toString(), positionToken.positionId, userAccount, underPriceBN, dispatch);
    positionIds.push(positionToken.positionId);
    let health = 0;

    console.log("RPC method: loadHelper.getLPPositionData");
    const data = await loadHelper.methods.getLPPositionData(marketId, positionToken.positionId, userAccount, underPriceBN).call();
    if (data.liquidity.toString() !== "0") {
      health = parseFloat(formatUnits(data.health.toString(), 18));
    }

    positionDatas.push(data);
    healths.push(health);
  }

  const trade = {
    marketId: marketId.toString(),
    user: user,
    type: "long",
    isBuy: false,
    baseAmount: baseReceived.toString() / 10 ** 18,
    // size: longSent.toString() / 10 ** 18,
    size: longSent.toString(),
    // basePrice: toBn(baseReceived.toString(), 18).div(BigNumber.from(longSent.toString())),
    basePrice: parseFloat(baseReceived / 10 ** 18 / (longSent / 10 ** 18)),
    collPrice: 0,
    timestamp: timestamp.toString(),
    // transactionHash: event.transactionHash,
    signature: eventSignature
  };

  const marginData = await loadMarginPoolData2(loadHelper, marginPool, underPriceBN, tokens);
  console.log("RPC method: loadHelper.getAssetAddresses");
  const marketImmutableParams = await loadHelper.methods.getAssetAddresses().call();
  const baseTokenAddress = marketImmutableParams.baseTokenAddress;
  const underTokenAddress = marketImmutableParams.underTokenAddress;

  let [
    tokenAccountBalanceBaseToken,
    assetWalletBalanceBaseToken,
    tokenAccountBalanceUnderToken,
    assetWalletBalanceUnderToken,
    longPositionTokenAccountBalance,
    shortPositionTokenAccountBalance,
    lpPositionTokenAccountBalance
  ] = [null, null, null, null, null, null, null];

  let marginAccountData = null;
  if (userAccount === user) {
    [
      tokenAccountBalanceBaseToken,
      assetWalletBalanceBaseToken,
      tokenAccountBalanceUnderToken,
      assetWalletBalanceUnderToken,
      longPositionTokenAccountBalance,
      shortPositionTokenAccountBalance,
      lpPositionTokenAccountBalance
    ] = await reloadMarketTokensBalances2(web3, marketId.toString(), userBalances, loadHelper, userAccount, underPriceBN);
    marginAccountData = await loadMarginAccountData2(userAccount, loadHelper, marginPool, userBalances, underPriceBN, tokens);
  }

  return {
    mutableMarketAttributes,
    positionIds,
    positionDatas,
    healths,
    trade,
    marginData,
    baseTokenAddress,
    underTokenAddress,
    tokenAccountBalanceBaseToken,
    assetWalletBalanceBaseToken,
    tokenAccountBalanceUnderToken,
    assetWalletBalanceUnderToken,
    longPositionTokenAccountBalance,
    shortPositionTokenAccountBalance,
    lpPositionTokenAccountBalance,
    marginAccountData
  };
};
