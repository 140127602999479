/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-multi-comp */
import React, { createRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { currentTimestampSelector, web3AccountLoadedSelector, web3AccountSelector, web3Selector } from "../../store/selectors/web3Selectors";
import { lpManagerSelector, positionsManagerSelector } from "../../store/selectors/contractsSelectors";
import PositionsChart from "./charts/PositionsChart.jsx";
import {
  limitValue,
  getRangeDecimals,
  cursorRangeCheck,
  removeCommaLocales,
  formatValue,
  printPriceFromNumber,
  debounce,
  getUnderlyingPriceAtExpiredStr,
  getReadableLPHealth,
  healthClassName
} from "../../utils/utils.js";
import MyModal from "./utilities/MyModal.jsx";
import { closeAccountPositions } from "../../store/interactions/positionsManagerInteractions";
import SlidersPanel from "./SlidersPanel.jsx";
import { selectedTokenPairSelector, avgUnderlyingTokenPriceSelector, tokenBalancesSelector } from "../../store/selectors/tokensSelectors";
import {
  interpolateY,
  interpolateGreeks,
  interpolateValue,
  interpolateLots,
  interpolateOptionValue,
  getChartPoint,
  getHighestDaysToExpiryRounded
} from "../../utils/chartsUtils";
import { marketsLoadedSelector, marketsSelector } from "../../store/selectors/marketsSelectors.js";
import { marginAccountDataSelector, marginPoolDataSelector, positionsBorrowFactorsSelector } from "../../store/selectors/marginSelectors";
import { openedTradePositionsSelector, openedLPPositionsSelector } from "../../store/selectors/positionsManagerSelectors";
import { formatUnits } from "@ethersproject/units";
import ReactTable from "react-table";
import CogChartOptions from "./CogChartOptions.jsx";
import { ChartContext } from "../../layouts/market/MainRouter.jsx";
import ReactGA from "react-ga4";
import Reserves from "./Reserves.jsx";
import MyTooltip from "./MyTooltip.jsx";
import MyTooltip2 from "./MyTooltip2.jsx";

import LiquidationWorker from "./../../workers/liqWorker.worker.js";
import PositionLiquidationWorker from "./../../workers/positionLiqWorker.worker.js";
import ChartDataWorker from "./../../workers/chartData.worker.js";
import { settlementDataSelector } from "../../store/selectors/balancesSelectors";
import { fromBn, toBn } from "evm-bn";

let lastSelectedInputTimestamp = null;
let lastClickedModalOverlay = null;

class PositionModal extends React.Component {
  constructor(props) {
    super(props);

    this.TOOLTIPS_TEXT = {
      positionsTableToken: <>Name of the position token.</>,
      positionsTableSize: (
        <>
          Current size of the position in options. <br /> <br /> Positive quantity means position is long option, negative quantity means position is short
          option.
        </>
      ),
      positionsTablePnl: (
        <>
          Profit/Loss (P/L) of the position since the opening. <br /> <br />
          Formula used: <br />
          P/L = QTY x (MARK - OPEN)
        </>
      )
    };

    this.state = {
      selectedUnderPrice: 0,
      selectedDaysToExpiry: null,
      selectedVolatility: null,
      dailyVolume: null,

      addTabBaseAmount: 0,
      addTabBaseAmountInput: "",
      addTabBaseAmountInputClass: "",
      addTabBaseErrMessage: "",

      cogChartOptionsOpened: false,

      tooltipIsVisible: false,
      tooltipTarget: "",
      tooltipContent: "",
      idPrefix: "position-modal-",

      liqPrices: null,
      lpLiqPrices: null,

      // chart data
      chartData: {
        minDomainX: 1000,
        maxDomainX: 1600,
        minDomainY: 0,
        maxDomainY: 1,
        series1: [],
        series2: [],
        delta: 0,
        gamma: 0,
        theta: 0,
        vega: 0
      },

      standardDeviation: 3
    };

    this.calculateChartData = this.calculateChartData.bind(this);
    this.getPositionsData = this.getPositionsData.bind(this);
    this.displayPNLTable = this.displayPNLTable.bind(this);

    this.selectXCallback = this.selectXCallback.bind(this);
    this.deselectXCallback = this.deselectXCallback.bind(this);
    this.setStandardDeviation = this.setStandardDeviation.bind(this);

    this.chartRef = createRef();
  }

  componentDidMount() {
    // account liquidation worker
    this.liquidationWorker = new LiquidationWorker();
    this.liquidationWorker.onmessage = e => {
      const liqPrices = e.data;
      this.setState({ liqPrices });
    };

    // position liquidation worker
    this.positionLiquidationWorker = new PositionLiquidationWorker();
    this.positionLiquidationWorker.onmessage = e => {
      const liqPrices = e.data;
      this.setState({ lpLiqPrices: liqPrices });
    };

    this.chartDataWorker = new ChartDataWorker();
    this.chartDataWorker.onmessage = e => {
      const chartData = e.data;
      this.setState({ chartData });
    };
    // todo: v2 for now, use first position volatility and expiration, check how it's done
    const market = this.props.markets.find(market => {
      return market.marketId === this.props.selectedPositions[0].marketId;
    });

    const isSingle = this.props.selectedPositions.length === 1;
    const underPrice = Math.round(parseFloat(this.props.underlyingTokenPrice.normalized) * 100) / 100;
    const volatility = isSingle ? Math.round(parseFloat(market.longPriceInVol)) : 0;
    let daysToExpiration = getHighestDaysToExpiryRounded(this.props.selectedPositions, this.props.markets);
    if (isSingle) {
      const marketIsExpired = market.isSettled || Number(market.expirationTime) < this.props.currentTimestamp;
      marketIsExpired && (daysToExpiration = 0);
    }

    if (this.props.selectedPositions && this.props.selectedPositions.length > 0 && this.props.underlyingTokenPrice) {
      this.calculateChartData(underPrice, daysToExpiration, volatility, this.state.standardDeviation);
      this.calculateLiqPrice(daysToExpiration, volatility);
    }

    if (this.props.isOpen) {
      document.onkeydown = this.onKeyPress;
    }

    const chunk = "position-modal";
    console.log("Navigate to: " + window.location.pathname + "/" + chunk); // eslint-disable-line no-console
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + "/" + chunk, title: "Position Modal" });
  }

  componentDidUpdate(prevProps) {
    // we should update modal when market prices are updated
    if (this.props.selectedPositions && this.props.selectedPositions.length > 0 && this.props.underlyingTokenPrice) {
      if (this.state.selectedUnderPrice !== 0 && this.state.selectedDaysToExpiry !== null && this.state.selectedVolatility !== null) {
        const market = this.props.markets.find(market => market.marketId === this.props.selectedPositions[0].marketId);
        const prevMarket = prevProps.markets.find(market => market.marketId === this.props.selectedPositions[0].marketId);
        if (market.longPrice !== prevMarket.longPrice && market.longPriceInVol === prevMarket.longPriceInVol) {
          this.calculateChartData(this.state.selectedUnderPrice, this.state.selectedDaysToExpiry, this.state.selectedVolatility, this.state.standardDeviation);
          this.calculateLiqPrice(this.state.selectedDaysToExpiry, this.state.selectedVolatility);
        }
      }
    }

    if (this.props.isOpen) {
      document.onkeydown = this.onKeyPress;
    }
  }

  componentWillUnmount() {
    document.onkeydown = null;
    this.liquidationWorker.terminate();
    this.positionLiquidationWorker.terminate();
  }

  onMouseEnterColumnHeader = e => {
    if (!this.state.tooltipIsVisible) {
      this.setState({ tooltipIsVisible: true, tooltipContent: this.TOOLTIPS_TEXT[e.currentTarget.getAttribute("name")], tooltipTarget: e.currentTarget.id });
    }
  };

  onMouseLeaveColumnHeader = e => {
    if (!cursorRangeCheck(this.state.tooltipTarget, e.clientX, e.clientY)) {
      this.setState({ tooltipIsVisible: false, tooltipContent: "", tooltipTarget: "" });
      document.querySelector(":root").style.setProperty("--tooltipArrowTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipArrowLeftPosition", -500 + "px");

      document.querySelector(":root").style.setProperty("--tooltipTopPosition", -500 + "px");
      document.querySelector(":root").style.setProperty("--tooltipLeftPosition", -500 + "px");
    }
  };

  setTooltipIsVisible = visible => {
    this.setState({ tooltipIsVisible: visible });
  };

  setTooltipTarget = target => {
    this.setState({ tooltipTarget: target });
  };

  setTooltipContent = content => {
    this.setState({ tooltipContent: content });
  };

  onKeyPress = e => {
    if (e.key === "Escape") {
      this.props.toggle();
    }
  };

  calculateLiqPrice = (selectedDaysToExpiry, selectedVolatility) => {
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    // liquidation price
    const assetsBase = this.props.tokenBalances.find(t => t.symbol === "USD").normalizedAccountBalance;
    const assetsUnder = this.props.tokenBalances.find(t => t.symbol === "ETH").normalizedAccountBalance;
    const debtBase =
      this.props.marginAccountData && -this.props.marginAccountData.collateralTokens.find(data => data.symbol === "USD").normalizedBorrowedAmount;
    const debtUnder =
      this.props.marginAccountData && -this.props.marginAccountData.collateralTokens.find(data => data.symbol === "ETH").normalizedBorrowedAmount;
    const tradePositions = this.props.openedTradePositions ? this.props.openedTradePositions : [];
    const lpPositions = this.props.openedLPPositions ? this.props.openedLPPositions : [];
    const allPositions = [...tradePositions, ...lpPositions];
    const isSingleLP = this.props.selectedPositions.length === 1 && this.props.selectedPositions[0].type === "lp";
    const position = this.props.selectedPositions[0];
    let volatility = parseFloat(selectedVolatility);

    // liquidation prices are always for all positions,
    // so we use relative volatility if there are more than 1 element in allPostions
    if (this.props.selectedPositions.length === 1 && allPositions.length > 1) {
      const market = this.props.markets.find(market => market.marketId === position.marketId);
      volatility = parseFloat(selectedVolatility) - Math.round(parseFloat(market.longPriceInVol));
    }

    const marketsToWorker = this.props.markets.map(market => {
      const marketToWorker = { ...market };
      delete marketToWorker.contract;
      return marketToWorker;
    });

    // account liquidation worker
    const baseBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "USD").normalizedBorrowInterestRate;
    const underBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "ETH").normalizedBorrowInterestRate;
    const borrowRates = { baseBorrowRate, underBorrowRate };
    this.liquidationWorker.postMessage({
      allPositions,
      markets: marketsToWorker,
      underPrice,
      selectedDaysToExpiry,
      allPositionsSelectedVolatility: volatility,
      riskFreeRate: this.props.selectedTokenPair.riskFreeRate / 100,
      assetsBase,
      assetsUnder,
      debtBase,
      debtUnder,
      borrowRates
    });

    // position liquidation worker
    // if single lp, then use that, otherwise find position liq price for all lp positions
    this.positionLiquidationWorker.postMessage({
      allPositions: isSingleLP ? [position] : lpPositions,
      markets: marketsToWorker,
      underPrice,
      riskFreeRate: this.props.selectedTokenPair.riskFreeRate / 100
    });
  };

  debounceCalcChartDataWorker = debounce((underPrice, selectedUnderPrice, selectedDaysToExpiry, selectedVolatility, standardDeviation) => {
    const marketsToWorker = this.props.markets.map(market => {
      const marketToWorker = { ...market };
      delete marketToWorker.contract;
      return marketToWorker;
    });

    const baseBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "USD").normalizedBorrowInterestRate;
    const underBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "ETH").normalizedBorrowInterestRate;
    const borrowRates = { baseBorrowRate, underBorrowRate };
    this.chartDataWorker.postMessage({
      selectedPositions: this.props.selectedPositions,
      markets: marketsToWorker,
      underPrice,
      selectedDaysToExpiry,
      selectedVolatility: parseFloat(selectedVolatility),
      riskFreeRate: this.props.selectedTokenPair.riskFreeRate / 100,
      borrowRates,
      standardDeviation
    });
  }, 200);

  setChartRelatedStatesWithDebounceCalcChartData = (selectedUnderPrice, selectedDaysToExpiry, selectedVolatility, selectedDeviation) => {
    const underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    const standardDeviation = selectedDeviation ? selectedDeviation : this.state.standardDeviation;
    this.setState({
      selectedUnderPrice,
      selectedDaysToExpiry,
      selectedVolatility,
      standardDeviation
    });

    this.debounceCalcChartDataWorker(underPrice, selectedUnderPrice, selectedDaysToExpiry, selectedVolatility, standardDeviation);
  };

  calculateChartData = (selectedUnderPrice, selectedDaysToExpiry, selectedVolatility, selectedDeviation) => {
    let underPrice = parseFloat(this.props.underlyingTokenPrice.normalized);
    const standardDeviation = selectedDeviation ? selectedDeviation : this.state.standardDeviation;
    this.setState({
      selectedUnderPrice,
      selectedDaysToExpiry,
      selectedVolatility,
      standardDeviation
    });

    // chart data
    const marketsToWorker = this.props.markets.map(market => {
      const marketToWorker = { ...market };
      delete marketToWorker.contract;
      return marketToWorker;
    });
    const baseBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "USD").normalizedBorrowInterestRate;
    const underBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "ETH").normalizedBorrowInterestRate;
    const borrowRates = { baseBorrowRate, underBorrowRate };
    this.chartDataWorker.postMessage({
      selectedPositions: this.props.selectedPositions,
      markets: marketsToWorker,
      underPrice,
      selectedDaysToExpiry,
      selectedVolatility: parseFloat(selectedVolatility),
      riskFreeRate: this.props.selectedTokenPair.riskFreeRate / 100,
      standardDeviation,
      borrowRates
    });

    // todo: v2 handle high liquidation price, for example $1000000
  };

  getDaysSinceStart = position => {
    // past - get days since open
    const blockchainTimestamp = new Date(this.props.currentTimestamp * 1000);
    const openDate = new Date(parseInt(position.openTime) * 1000);
    const daysSinceOpen = Math.max(1, (blockchainTimestamp - openDate) / (1000 * 60 * 60 * 24));

    // future - add selected days
    const market = this.props.markets.find(market => market.marketId === position.marketId);
    const timeToExpiry = Math.abs(market.expirationTime * 1000 - blockchainTimestamp);
    const daysToExpiry = timeToExpiry / (1000 * 60 * 60 * 24);
    const daysSinceStart = Math.max(1, daysSinceOpen + Math.max(0, daysToExpiry - this.state.selectedDaysToExpiry));

    return daysSinceStart;
  };

  getPositionsData = positions => {
    let valueOnOpen = 0;
    let valueOnClose = 0;
    let principalProfit = 0;
    let feeProfit = 0;
    let totalProfit = 0;
    let principalROI = 0;
    let feeROI = 0;
    let totalROI = 0;
    let totalAPR = 0;
    let principalAPY = 0;
    let feeAPY = 0;
    let totalAPY = 0;

    const underlyingPrice = this.state.selectedUnderPrice;

    // time
    let daysSinceStart = this.getDaysSinceStart(positions[0]);

    const isSingle = positions.length === 1;
    const isSingleLP = isSingle && positions[0].type === "lp";

    if (!isSingleLP) {
      if (this.state.chartData.series1 && this.state.chartData.series1.length > 0) {
        // PnL and value
        totalProfit = interpolateY(this.state.chartData.series1, underlyingPrice);
        valueOnClose = interpolateValue(this.state.chartData.series1, underlyingPrice);
      }

      valueOnOpen = valueOnClose - totalProfit;
    } else {
      if (this.state.chartData.series1 && this.state.chartData.series1.length > 0) {
        // PnL and value
        totalProfit = interpolateY(this.state.chartData.series1, underlyingPrice);
        valueOnClose = interpolateValue(this.state.chartData.series1, underlyingPrice);
      }

      feeProfit += this.state.chartData.feesProjected;

      valueOnOpen = valueOnClose - totalProfit;
      principalProfit = totalProfit - feeProfit;
    }

    // ROI
    principalROI = ((totalProfit - feeProfit) / valueOnOpen) * 100;
    feeROI = (feeProfit / valueOnOpen) * 100;
    totalROI = (totalProfit / valueOnOpen) * 100;

    // APR
    totalAPR = (totalProfit / valueOnOpen / daysSinceStart) * 365 * 100;

    // APY
    if (Math.abs(principalProfit) > 0.000001) {
      const principalAPR = (principalProfit / valueOnOpen / daysSinceStart) * 365 * 100;
      principalAPY = (Math.pow(1 + principalAPR / 100 / 365, 365) - 1) * 100;
    }
    if (Math.abs(feeProfit) > 0.000001) {
      const feeAPR = (feeProfit / valueOnOpen / daysSinceStart) * 365 * 100;
      feeAPY = (Math.pow(1 + feeAPR / 100 / 365, 365) - 1) * 100;
    }
    if (Math.abs(totalProfit) > 0.000001) {
      const totalAPR = (totalProfit / valueOnOpen / daysSinceStart) * 365 * 100;
      totalAPY = (Math.pow(1 + totalAPR / 100 / 365, 365) - 1) * 100;
    }

    return {
      value: valueOnClose,
      principalProfit: principalProfit,
      feeProfit: feeProfit,
      totalProfit: totalProfit,
      principalROI: principalROI,
      feeROI: feeROI,
      totalROI: totalROI,
      totalAPR: totalAPR,
      principalAPY: principalAPY,
      feeAPY: feeAPY,
      totalAPY: totalAPY
    };
  };

  displayGreeksAndSimplePNLTable = (delta, gamma, theta, vega) => {
    const returnData = this.getPositionsData(this.props.selectedPositions);
    if (returnData == undefined) {
      return null;
    }

    return (
      <>
        <div className="pnlTable">
          <table>
            <thead>
              <tr>
                <th className="table-heading" scope="col" />
                <th className="table-heading" scope="col" style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <span id={this.state.idPrefix + "roi"} className="pnlTableCell">
                    ROI
                  </span>
                  <MyTooltip key={this.state.idPrefix + "roi"} target={this.state.idPrefix + "roi"} optionalOffset={15}>
                    Projected Return on investment (ROI) for multiple LP positions using selected Volatity, Time to expiration, Underlying price, and projected
                    trading volume.
                  </MyTooltip>
                </th>
                <th className="table-heading" scope="col" style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <span id={this.state.idPrefix + "apy"} className="pnlTableCell">
                    APY
                  </span>
                  <MyTooltip key={this.state.idPrefix + "apy"} target={this.state.idPrefix + "apy"} optionalOffset={15}>
                    Projected Annual percentage yield (APY) for multiple LP positions using selected Volatity, Time to expiration, Underlying price, and
                    projected trading volume.
                  </MyTooltip>
                </th>
                <th className="table-heading" scope="col" style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <span id={this.state.idPrefix + "pnl"} className="pnlTableCell">
                    P/L
                  </span>
                  <MyTooltip key={this.state.idPrefix + "pnl"} target={this.state.idPrefix + "pnl"} optionalOffset={15}>
                    Projected Profit/Loss (P/L) for multiple LP positions using selected Volatity, Time to expiration, Underlying price, and projected trading
                    volume.
                  </MyTooltip>
                </th>
              </tr>
            </thead>
            <tbody className="table-heading">
              <tr>
                <td>
                  <span className="pnlTableRowLabel">Total</span>
                </td>
                <td>
                  <span className="pnlTableCell">{limitValue(returnData.totalROI, 10000, 2) + "%"}</span>
                </td>
                <td>
                  <span className="pnlTableCell">{limitValue(returnData.totalAPY, 10000, 2) + "%"}</span>
                </td>
                <td>
                  <span className="pnlTableCell">{printPriceFromNumber(returnData.totalProfit, true)}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };

  displayPNLTable = () => {
    const returnData = this.getPositionsData(this.props.selectedPositions);
    if (returnData == undefined) {
      return null;
    }

    return (
      <div className="pnlTable">
        <table>
          <thead>
            <tr>
              <th className="table-heading" scope="col" />
              <th className="table-heading" scope="col" style={{ textAlign: "center", verticalAlign: "middle" }}>
                <span id={this.state.idPrefix + "roi"} className="pnlTableCell lpTableTh">
                  ROI
                </span>
                <MyTooltip key={this.state.idPrefix + "roi"} target={this.state.idPrefix + "roi"} optionalOffset={15}>
                  Projected Return on investment (ROI) of the LP position using selected Volatity, Time to expiration, Underlying price, and projected trading
                  volume.
                </MyTooltip>
              </th>
              <th className="table-heading" scope="col" style={{ textAlign: "center", verticalAlign: "middle" }}>
                <span id={this.state.idPrefix + "apy"} className="pnlTableCell lpTableTh">
                  APY
                </span>
                <MyTooltip key={this.state.idPrefix + "apy"} target={this.state.idPrefix + "apy"} optionalOffset={15}>
                  Projected Annual percentage yield (APY) of the LP position using selected Volatity, Time to expiration, Underlying price, and projected
                  trading volume.
                </MyTooltip>
              </th>
              <th className="table-heading" scope="col" style={{ textAlign: "center", verticalAlign: "middle" }}>
                <span id={this.state.idPrefix + "pnl"} className="pnlTableCell lpTableTh">
                  P/L
                </span>
                <MyTooltip key={this.state.idPrefix + "pnl"} target={this.state.idPrefix + "pnl"} optionalOffset={15}>
                  Projected Profit/Loss (P/L) of the LP position using selected Volatity, Time to expiration, Underlying price, and projected trading volume.
                </MyTooltip>
              </th>
            </tr>
          </thead>
          <tbody className="table-heading">
            <tr>
              <td>
                <span id={this.state.idPrefix + "pnl-table-principal"} className="pnlTableRowLabel">
                  Principal
                </span>
                <MyTooltip key={this.state.idPrefix + "pnl-table-principal"} target={this.state.idPrefix + "pnl-table-principal"} optionalOffset={15}>
                  Principal is provided liquidity that is traded on the market, and can have profit or loss depending on the market conditions. <br /> <br />
                  There are 3 risks associated with principal:
                  <ul>
                    <li style={{ color: "black" }}>Option risk</li>
                    <li style={{ color: "black" }}>Trading risk</li>
                    <li style={{ color: "black" }}>Impermanent loss</li>
                  </ul>
                  Learn more: <br />
                  <a href="https://gamma-options.gitbook.io/docs/" target="_blank" rel="noreferrer">
                    Docs
                  </a>
                </MyTooltip>
              </td>
              <td>
                <span className="pnlTableCell">{limitValue(returnData.principalROI < 0.01 ? 0 : returnData.principalROI, 10000, 2) + "%"}</span>
              </td>
              <td>
                <span className="pnlTableCell">{limitValue(returnData.principalAPY, 10000, 2) + "%"}</span>
              </td>
              <td>
                <span className="pnlTableCell">{printPriceFromNumber(returnData.principalProfit, true)}</span>
              </td>
            </tr>
            <tr>
              <td>
                {/* rename borrow rate to borrow interest */}
                <span id={this.state.idPrefix + "pnl-table-fees-earned"} className="pnlTableRowLabel">
                  Fees Earned - Borrow Rate
                </span>
                <MyTooltip key={this.state.idPrefix + "pnl-table-fees-earned"} target={this.state.idPrefix + "pnl-table-fees-earned"} optionalOffset={15}>
                  Fees earned are trading fees earned by providing liquidity to the market. Trading fees are accrued in USD, and can only grow. <br /> <br />
                  Borrow Interest is interest paid on borrowed funds that are used as part of the liquidity. Can change over time, and is always negative.{" "}
                  <br /> <br />
                  Learn more: <br />
                  <a href="https://gamma-options.gitbook.io/docs/" target="_blank" rel="noreferrer">
                    Docs
                  </a>
                </MyTooltip>
              </td>
              <td>
                <span className="pnlTableCell">{limitValue(returnData.feeROI, 10000, 2) + "%"}</span>
              </td>
              <td>
                <span className="pnlTableCell">{limitValue(returnData.feeAPY, 10000, 2) + "%"}</span>
              </td>
              <td>
                <span className="pnlTableCell">{printPriceFromNumber(returnData.feeProfit, true)}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span id={this.state.idPrefix + "pnl-table-total"} className="pnlTableRowLabel">
                  Total
                </span>
                <MyTooltip key={this.state.idPrefix + "pnl-table-total"} target={this.state.idPrefix + "pnl-table-total"} optionalOffset={15}>
                  Total is the sum of Principal and Fees earned minus interest paid to borrow. <br /> <br />
                  Learn more: <br />
                  <a href="https://gamma-options.gitbook.io/docs/" target="_blank" rel="noreferrer">
                    Docs
                  </a>
                </MyTooltip>
              </td>
              <td>
                <span className="pnlTableCell">{limitValue(returnData.totalROI < 0.01 ? 0 : returnData.totalROI, 10000, 2) + "%"}</span>
              </td>
              <td>
                <span className="pnlTableCell">{limitValue(returnData.totalAPY, 10000, 2) + "%"}</span>
              </td>
              <td>
                <span className="pnlTableCell">{printPriceFromNumber(returnData.totalProfit, true)}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  closeModal = () => {
    this.props.toggle(true);
  };

  onIncreasePositionClick = e => {
    e.stopPropagation();
    this.props.toggle(true);
    this.props.toggleTradingModal();
    this.props.setNeedsToBeClosed(false);
  };

  onClosePositionClick = e => {
    e.stopPropagation();
    this.props.toggle(true);
    this.props.toggleTradingModal();
    this.props.setNeedsToBeClosed(true);
  };

  setStandardDeviation = newDeviation => {
    const market = this.props.markets.find(market => {
      return market.marketId === this.props.selectedPositions[0].marketId;
    });
    const isSingle = this.props.selectedPositions.length === 1;
    const underPrice = Math.round(parseFloat(this.props.underlyingTokenPrice.normalized) * 100) / 100;
    const volatility = isSingle ? Math.round(parseFloat(market.longPriceInVol)) : 0;
    let daysToExpiration = getHighestDaysToExpiryRounded(this.props.selectedPositions, this.props.markets);
    if (isSingle) {
      const marketIsExpired = market.isSettled || Number(market.expirationTime) < this.props.currentTimestamp;
      marketIsExpired && (daysToExpiration = 0);
    }

    this.setChartRelatedStatesWithDebounceCalcChartData(underPrice, daysToExpiration, volatility, newDeviation);
    this.calculateLiqPrice(daysToExpiration, volatility);
  };

  selectXCallback = x => {
    if (x !== this.state.selectedUnderPrice) {
      this.setState({
        selectedUnderPrice: x
      });
    }
  };

  deselectXCallback = () => {
    this.setState({
      selectedUnderPrice: parseFloat(this.props.underlyingTokenPrice.normalized)
    });
  };

  getPositionsTableData = () => {
    const mappedPositions = this.props.selectedPositions.map(position => {
      let token = position.shortName;
      let size = position.balance;

      const market = this.props.markets.find(market => market.marketId === position.marketId);
      let underlyingPrice = parseFloat(formatUnits(position.tokenPair.underlyingTokenPrice.toString(), 18));
      const isLongOrShort = position.type === "long" || position.type === "short";
      const isShort = position.type === "short";
      let selectedDaysToExpiry = parseInt(Math.abs(Date.now() / 1000 - Number(market.expirationTime))) / (24 * 60 * 60);

      // if settled, use 0 days, and underPrice at settlement
      if (marketIsExpired) {
        selectedDaysToExpiry = 0;
        const settlement = this.props.settlements.find(s => s.expirationTime === market.expirationTime);
        if (settlement) {
          underlyingPrice = parseFloat(formatUnits(settlement.actualUnderlyingPrice.toString(), 18));
        }
      }

      const baseBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "USD").normalizedBorrowInterestRate;
      const underBorrowRate = this.props.marginPoolData.tokensData.find(data => data.symbol === "ETH").normalizedBorrowInterestRate;
      const borrowRates = { baseBorrowRate, underBorrowRate };
      const chartPoint = getChartPoint(
        [position],
        this.props.markets,
        underlyingPrice,
        selectedDaysToExpiry,
        parseFloat(market.longPriceInVol),
        this.props.selectedTokenPair.riskFreeRate / 100,
        borrowRates
      );

      const profit = chartPoint.pnl;

      if (position.type === "short") {
        size *= -1;
      }
      size = formatValue(size, 2);

      if (position.type === "lp") {
        const rightLotsValue = position.openLiqPrice[1] * position.balance;
        const rightLotsPrintable = formatValue(rightLotsValue, 2);
        const leftLotsPrintable = formatValue(position.sizeInLongs - rightLotsValue, 2);
        size = leftLotsPrintable + "/-" + rightLotsPrintable;
      }

      const marketIsExpired = market.isSettled || Number(market.expirationTime) < this.props.currentTimestamp;

      let expiredText = " (EXPIRED)";
      if (market.isSettled) {
        const underlyingPriceAtExpired = getUnderlyingPriceAtExpiredStr(market, this.props.settlements);
        expiredText = " (EXPIRED $" + underlyingPriceAtExpired + ")";
      }
      let name = token;
      token += marketIsExpired ? expiredText : "";
      return {
        name,
        token,
        size,
        pnl: profit,
        isLong: isLongOrShort ? (isShort ? false : true) : false,
        timestamp: Number(position.timestamp)
      };
    });

    mappedPositions.sort((a, b) => b.timestamp - a.timestamp);

    return mappedPositions;
  };

  getPositionsTableColumns = () => {
    return [
      {
        Header: () => (
          <div className="alignLeft">
            <span
              id="positions-table-token"
              name="positionsTableToken"
              onMouseEnter={this.onMouseEnterColumnHeader}
              onMouseLeave={this.onMouseLeaveColumnHeader}
            >
              Token
            </span>
          </div>
        ),
        accessor: "token",
        align: "right",
        minWidth: 40,
        maxWidth: 5 * 85,
        filterable: false,
        sortable: false
      },
      {
        Header: () => (
          <div>
            <span id="positions-table-size" name="positionsTableSize" onMouseEnter={this.onMouseEnterColumnHeader} onMouseLeave={this.onMouseLeaveColumnHeader}>
              Qty
            </span>
          </div>
        ),
        accessor: "size",
        align: "right",
        minWidth: 20,
        maxWidth: 5 * 25,
        filterable: false,
        sortable: false,
        Cell: row => <span style={{ float: "right", textTransform: "lowercase" }}>{row.value}</span>
      },
      {
        Header: () => (
          <div>
            <span id="positions-table-pnl" name="positionsTablePnl" onMouseEnter={this.onMouseEnterColumnHeader} onMouseLeave={this.onMouseLeaveColumnHeader}>
              P/L
            </span>
          </div>
        ),
        accessor: "pnl",
        align: "right",
        minWidth: 20,
        maxWidth: 5 * 20,
        filterable: false,
        sortable: false,
        Cell: row => {
          return <span style={{ float: "right", textTransform: "lowercase" }}>{printPriceFromNumber(row.value)}</span>;
        }
      }
    ];
  };

  printPositionsTable = () => {
    return (
      <div id="positionsModalTable">
        <ReactTable
          className="-highlight"
          data={this.getPositionsTableData()}
          columns={this.getPositionsTableColumns()}
          pageSize={4}
          showPageSizeOptions={false}
          getTrProps={(state, rowInfo, column) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: e => {
                  e.stopPropagation();
                  this.props.toggle(true);
                  this.props.onRowPositionClicked && this.props.onRowPositionClicked(rowInfo, this.props.closeAllFilterDropmenus);
                },
                style: {
                  cursor: "pointer"
                }
              };
            }
            return {};
          }}
        />
      </div>
    );
  };

  toggleSetCogChartOptionsOpen = e => {
    e.stopPropagation();
    this.setState({ cogChartOptionsOpened: !this.state.cogChartOptionsOpened });
  };

  getLPPositionSize = position => {
    const rightLotsValue = position.openLiqPrice[1] * position.balance;
    const rightLotsPrintable = formatValue(rightLotsValue, 2);
    const leftLotsPrintable = formatValue(position.sizeInLongs - rightLotsValue, 2);
    return leftLotsPrintable + "/-" + rightLotsPrintable;
  };

  onLiquidateCloseAllButtonClick = e => {
    e.stopPropagation();
    if (e.currentTarget.textContent === "liquidate") {
      this.props.liquidateAccount(this.props.accountToLiquidate);
    } else {
      const positions = this.getPositionsTableData();

      const positionMarkets = [];
      const isLong = [];
      const lpMarkets = [];
      const lpIds = [];
      const marketBorrowFactors = [];
      const lpBorrowFactors = [];

      positions.forEach(position => {
        let tokenPosition;
        let lpPosition;
        let marketBorrowFactor;
        let lpBorrowFactor;
        if (position.token.includes("LP")) {
          lpPosition = this.props.openedLPPositions.find(lpPosition => lpPosition.shortName === position.name);
          lpBorrowFactor = this.props.positionsBorrowFactors.get(lpPosition.marketId + true + lpPosition.positionId);

          positionMarkets.push(lpPosition.marketId);
          isLong.push(true);
          marketBorrowFactors.push("1");

          positionMarkets.push(lpPosition.marketId);
          isLong.push(false);
          marketBorrowFactors.push(lpBorrowFactor);

          lpMarkets.push(lpPosition.marketId);
          lpIds.push(lpPosition.positionId);
          lpBorrowFactors.push(lpBorrowFactor);
        } else {
          tokenPosition = this.props.openedTradePositions.find(tradePosition => tradePosition.shortName === position.name);
          positionMarkets.push(tokenPosition.marketId);
          isLong.push(position.isLong);
          if (position.isLong) {
            marketBorrowFactors.push("1");
          } else {
            marketBorrowFactor = this.props.positionsBorrowFactors.get(tokenPosition.marketId + false + "0");
            marketBorrowFactors.push(marketBorrowFactor.toString());
          }
        }
      });

      const deadline = (Math.round(new Date().getTime() / 1000) + Math.round(Number(localStorage.getItem("tradeTransactionTimeout")) * 60)).toString();
      closeAccountPositions(
        this.props.web3,
        this.props.lpManager,
        positionMarkets,
        isLong,
        lpMarkets,
        lpIds,
        marketBorrowFactors,
        lpBorrowFactors,
        this.props.web3Account,
        deadline,
        this.closeModal,
        this.props.dispatch
      );
    }
  };

  render() {
    const market = this.props.markets.find(market => {
      return market.marketId === this.props.selectedPositions[0].marketId;
    });
    const isFuture = market.isFuture;
    const daysToExpiry = getHighestDaysToExpiryRounded(this.props.selectedPositions, this.props.markets);
    const marketIsExpired = market.isSettled || Number(market.expirationTime) < this.props.currentTimestamp;

    const isSingle = this.props.selectedPositions.length === 1;
    const isSingleLP = isSingle && this.props.selectedPositions[0].type === "lp";
    const isLong = this.props.selectedPositions[0].type === "long";
    let positionName = "MULTIPLE POSITIONS";
    let healthStr = "";
    let healthColor = "";
    let underlyingPriceAtExpired;
    if (isSingle) {
      const position = this.props.selectedPositions[0];
      positionName = this.props.selectedPositions[0].shortName;
      if (isSingleLP) {
        let minPriceInVol = 1.0001 ** position.lower;
        let maxPriceInVol = 1.0001 ** position.upper;
        const currentPriceInVol = parseFloat(market.longPriceInVol);
        let status = "";
        if (currentPriceInVol > minPriceInVol && currentPriceInVol < maxPriceInVol) {
          status = "In range";
        } else {
          status = "Out of range";
        }

        if (!isFuture) {
          minPriceInVol = minPriceInVol.toFixed(getRangeDecimals(minPriceInVol));
          maxPriceInVol = maxPriceInVol.toFixed(getRangeDecimals(maxPriceInVol));
          positionName += " | " + minPriceInVol + "% - " + maxPriceInVol + "%";
          positionName += " | " + this.getLPPositionSize(position);
          positionName += " | " + status;
        }

        healthStr = getReadableLPHealth(position.health);
        const recoveryThreshold = this.props.marginAccountData && this.props.web3AccountLoaded ? market.requiredThreshold * 100 : 0;
        const liquidationThreshold = this.props.marginAccountData && this.props.web3AccountLoaded ? market.liquidationThreshold * 100 : 0;

        healthColor = healthClassName(healthStr, position.health * 100, recoveryThreshold, liquidationThreshold);
      }

      let expiredText = " (EXPIRED)";
      if (market.isSettled) {
        underlyingPriceAtExpired = getUnderlyingPriceAtExpiredStr(market, this.props.settlements);
        expiredText = " (EXPIRED $" + underlyingPriceAtExpired + ")";
      }
      positionName += marketIsExpired ? expiredText : "";
    }
    const positionSize = isSingleLP
      ? formatValue(interpolateLots(this.state.chartData.series1, !market.isSettled ? this.state.selectedUnderPrice : underlyingPriceAtExpired), 2)
      : formatValue(this.props.selectedPositions[0].balance * (isLong ? 1 : -1), 2);
    const hasLP = this.props.selectedPositions.some(position => position.type === "lp");

    // get pnl and option value from chart data
    const optionValue =
      this.state.selectedDaysToExpiry > 0
        ? interpolateOptionValue(this.state.chartData.series1, !market.isSettled ? this.state.selectedUnderPrice : underlyingPriceAtExpired)
        : 0;
    const pnl = interpolateY(this.state.chartData.series1, !market.isSettled ? this.state.selectedUnderPrice : underlyingPriceAtExpired);

    // get greeks from chart data
    const greeks = interpolateGreeks(this.state.chartData.series1, !market.isSettled ? this.state.selectedUnderPrice : underlyingPriceAtExpired);
    const delta = formatValue(greeks.delta, 2);
    const gamma = formatValue(greeks.gamma, 2);
    const theta = formatValue(greeks.theta, 2);
    const vega = formatValue(greeks.vega, 2);

    const containsFuture = this.props.selectedPositions.some(position => {
      return this.props.markets.find(market => market.marketId === position.marketId).isFuture;
    });

    // if single future = TOTAL PREMIUM
    // if single option = OPTION VALUE
    // if multiple but no future = NET OPTION VALUE
    // if multiple and contains future = NET VALUE
    let secondTileTitle = "OPTION VALUE";
    if (isSingle && isFuture) {
      secondTileTitle = "TOTAL PREMIUM";
    } else if (!isSingle && !containsFuture) {
      secondTileTitle = "NET OPTION VALUE";
    } else if (!isSingle && containsFuture) {
      secondTileTitle = "NET VALUE";
    }

    return (
      <MyModal
        isOpen={this.props.isOpen}
        toggle={e => {
          lastClickedModalOverlay = Date.now();
          const diff = lastClickedModalOverlay - lastSelectedInputTimestamp;
          if (diff < 500) {
            lastClickedModalOverlay = null;
            lastSelectedInputTimestamp = null;
            return;
          }
          this.props.toggle();
        }}
      >
        <div
          className="modalFrame"
          onClick={e => {
            e.stopPropagation();
            this.setState({ cogChartOptionsOpened: false });
          }}
          onMouseDown={e => {
            lastSelectedInputTimestamp = Date.now();
          }}
        >
          <div className="positionModalHeader">
            <span className="positionModalToken">{this.props.isLiquidation ? "LIQUIDATABLE POSITIONS" : positionName}</span>
            <button aria-hidden data-dismiss="modal" type="button" className="positionModalCloseButton" onClick={() => this.props.toggle(true)}>
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
          <div className="modalContent">
            {/* because of the close all button (align sliders with table) */}
            <div style={{ display: "flex", flexDirection: "column", width: "40%", justifyContent: "space-between" }}>
              <div className="positionInformationContainer" style={{ height: isSingle && !this.props.isLiquidation ? "100%" : "90%", width: "100%" }}>
                <div className="positionModalTilesContainer">
                  <div className="positionModalTile">
                    <span id={this.state.idPrefix + "pnl-tile"} className="positionModalTileTitle">
                      {this.props.isLiquidation ? "REWARD" : "P/L"}
                    </span>
                    <MyTooltip key={this.state.idPrefix + "pnl-tile"} target={this.state.idPrefix + "pnl-tile"} optionalOffset={15}>
                      Profit/Loss (P/L) of the position(s) since the opening. <br /> <br />
                      Formula used: <br />
                      P/L = QTY x (MARK - OPEN)
                    </MyTooltip>
                    <span className={`positionModalTileContent${pnl >= 0 || this.props.isLiquidation ? " pnlRoiPositive" : " pnlRoiNegative"}`}>
                      {this.props.isLiquidation ? printPriceFromNumber(this.props.profit) : printPriceFromNumber(pnl)}
                    </span>
                  </div>
                  <div className="positionModalTile">
                    <span className="positionModalTileTitle">{secondTileTitle}</span>
                    <span className="positionModalTileContent">{printPriceFromNumber(optionValue)}</span>
                  </div>
                  {isSingle && !this.props.isLiquidation ? (
                    <div className="positionModalTile">
                      <span id={this.state.idPrefix + "qty-tile"} className={!marketIsExpired ? "positionModalTileTitle" : "positionModalTileTitleDisabled"}>
                        QTY
                      </span>
                      <MyTooltip key={this.state.idPrefix + "qty-tile"} target={this.state.idPrefix + "qty-tile"} optionalOffset={15}>
                        Current size of the position in options. <br /> <br />
                        Positive quantity means position is long option, negative quantity means position is short option.
                      </MyTooltip>
                      <span className={!marketIsExpired ? "positionModalTileContent" : "positionModalTileContentDisabled"}>{`${
                        isSingle ? positionSize : "n/a"
                      }`}</span>
                    </div>
                  ) : null}
                  {isSingle && isSingleLP && !this.props.isLiquidation ? (
                    <div className="positionModalTile">
                      <span
                        id={this.state.idPrefix + "lp-health-tile"}
                        className={!marketIsExpired ? "positionModalTileTitle" : "positionModalTileTitleDisabled"}
                      >
                        {"LP HEALTH"}&nbsp;
                      </span>
                      <MyTooltip key={this.state.idPrefix + "lp-health-tile"} target={this.state.idPrefix + "lp-health-tile"} optionalOffset={15}>
                        LP Health represents the ratio between cash reserves in the LP position and actual cash needed to buy{" "}
                        {!isFuture ? "options" : "futures"} in the range. <br /> <br />
                        Can be increased by adding more cash reserves to the LP position. <br /> <br />
                        If LP position's health declines below 125%, it can be forcefully closed and owner will be charged with $300 penalty.
                      </MyTooltip>

                      <span className={!marketIsExpired ? `positionModalTileContent ${healthColor}` : "positionModalTileContentDisabled"}>
                        {healthStr}&nbsp;
                      </span>
                    </div>
                  ) : null}
                  {isSingle && !isSingleLP && !this.props.isLiquidation ? (
                    <div className="positionModalTile">
                      <span id={this.state.idPrefix + "lp-health-tile"} className="positionModalTileTitle">
                        {" "}
                      </span>
                      <span className="positionModalTileContent"> </span>
                    </div>
                  ) : null}
                </div>
                {!isSingle || this.props.isLiquidation ? this.printPositionsTable() : null}

                {isSingle && this.props.selectedPositions[0].shortName.startsWith("LP") ? (
                  <Reserves disabled={marketIsExpired} closeModal={this.closeModal} selectedPositions={this.props.selectedPositions} market={market} />
                ) : null}

                {isSingle && !this.props.isLiquidation && !this.props.fromLiquidations ? (
                  <div className="increaseCloseButtonsContainer">
                    {this.props.selectedPositions[0].type !== "lp" ? (
                      <button
                        disabled={marketIsExpired}
                        className={`${this.props.selectedPositions[0].type === "short" ? "increaseButtonShort" : "increaseButtonLong"}`}
                        onClick={this.onIncreasePositionClick}
                      >
                        INCREASE
                      </button>
                    ) : null}
                    <button
                      className={`${
                        this.props.selectedPositions[0].type !== "short"
                          ? this.props.selectedPositions[0].type === "lp"
                            ? "closeButtonLp"
                            : "closeButtonLong"
                          : "closeButtonShort"
                      }`}
                      onClick={this.onClosePositionClick}
                      style={{ width: this.props.selectedPositions[0].type === "lp" ? "100%" : "" }}
                    >
                      CLOSE
                    </button>
                  </div>
                ) : null}
              </div>
              {!isSingle || this.props.isLiquidation ? (
                <button
                  disabled={this.props.isLiquidation && Number(removeCommaLocales(this.props.health)) > 115}
                  className="closeAllButton"
                  onClick={this.onLiquidateCloseAllButtonClick}
                >
                  {this.props.isLiquidation ? "LIQUIDATE" : "CLOSE ALL"}
                </button>
              ) : null}
            </div>

            {/* Right half */}
            <div className="chartTableContainer" style={{ position: "relative", height: isSingle && !this.props.isLiquidation ? "100%" : "90%" }}>
              <div
                className="verticalChartLabelContainer"
                style={
                  hasLP
                    ? {
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        height: "300px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        zIndex: "3000",
                        marginTop: !isSingle && hasLP ? "3%" : "5%"
                      }
                    : {}
                }
              >
                <span className="verticalChartLabel">Profit/Loss [USD]</span>
              </div>
              <div className="horizontalChartLabelCogContainer">
                <span id={this.state.idPrefix + "risk-analysis-chart"} className="horizontalChartLabel">
                  Risk Analysis: ETH price
                </span>
                <MyTooltip key={this.state.idPrefix + "risk-analysis-chart"} target={this.state.idPrefix + "risk-analysis-chart"} optionalOffset={15}>
                  Risk analysis chart shows profit/loss of the position(s) relative to ETH price change. <br /> <br />
                  Move mouse over chart to simulate the change of ETH price. <br /> <br />
                </MyTooltip>
                <button id={this.state.idPrefix + "chart-options-button"} className="btn-link settingsCogChart" onClick={this.toggleSetCogChartOptionsOpen} />
                <MyTooltip key={this.state.idPrefix + "chart-options-button"} target={this.state.idPrefix + "chart-options-button"} optionalOffset={15}>
                  Chart options
                </MyTooltip>
              </div>
              <div id="tradePositionPreviewContainer" ref={this.chartRef} style={!isSingle && hasLP ? { marginTop: "3%" } : {}}>
                {this.state.cogChartOptionsOpened && (
                  <CogChartOptions
                    key="cogChartoptions"
                    showOptionNewPosition={false}
                    standardDeviation={this.state.standardDeviation}
                    setStandardDeviation={this.setStandardDeviation}
                  />
                )}
                <ChartContext.Provider value={this.chartRef}>
                  {this.state.chartData.series1.length > 0 && (
                    <PositionsChart
                      disabled={false}
                      isXUnderlying
                      isTradeVolumeMode={false}
                      selectedUnderPrice={parseFloat(this.props.underlyingTokenPrice.normalized)}
                      daysToExpiry={daysToExpiry}
                      currentPriceInVol={Number(market.longPriceInVol)}
                      minDomainX={this.state.chartData.minDomainX}
                      maxDomainX={this.state.chartData.maxDomainX}
                      minDomainY={this.state.chartData.minDomainY}
                      maxDomainY={this.state.chartData.maxDomainY}
                      series1={this.state.chartData.series1}
                      series2={this.state.chartData.series2}
                      selectXCallback={this.selectXCallback}
                      deselectXCallback={this.deselectXCallback}
                      liqPrices={this.state.liqPrices}
                      lpLiqPrices={this.state.lpLiqPrices}
                      deviations={this.state.standardDeviation}
                      hasLP={hasLP}
                    />
                  )}
                </ChartContext.Provider>
              </div>

              <SlidersPanel
                selectedPositions={this.props.selectedPositions}
                markets={this.props.markets}
                selectedUnderPrice={this.state.selectedUnderPrice}
                selectedDaysToExpiry={this.state.selectedDaysToExpiry}
                selectedVolatility={this.state.selectedVolatility}
                calculateChartDataCallback={this.setChartRelatedStatesWithDebounceCalcChartData}
                delta={delta}
                gamma={gamma}
                theta={theta}
                vega={vega}
                disabled={marketIsExpired}
              />

              {hasLP ? (isSingle ? this.displayPNLTable() : this.displayGreeksAndSimplePNLTable(delta, gamma, theta, vega)) : null}
            </div>
          </div>
        </div>
        {this.state.tooltipIsVisible && (
          <MyTooltip2
            target={this.state.tooltipTarget}
            setTooltipIsVisible={this.setTooltipIsVisible}
            setTooltipContent={this.setTooltipContent}
            setTooltipTarget={this.setTooltipTarget}
          >
            {this.state.tooltipContent}
          </MyTooltip2>
        )}
      </MyModal>
    );
  }
}

PositionModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  toggleTradingModal: PropTypes.func,
  setNeedsToBeClosed: PropTypes.func,
  selectedPositions: PropTypes.array,
  web3Account: PropTypes.string,
  web3AccountLoaded: PropTypes.bool,
  positionsManager: PropTypes.object,
  lpManager: PropTypes.object,
  markets: PropTypes.array,
  marketsLoaded: PropTypes.bool,
  underlyingTokenPrice: PropTypes.object,
  selectedTokenPair: PropTypes.object,
  market: PropTypes.object,
  marginAccountData: PropTypes.object,
  openedTradePositions: PropTypes.array,
  openedLPPositions: PropTypes.array,
  tokenBalances: PropTypes.array,
  dispatch: PropTypes.func.isRequired,
  isLiquidation: PropTypes.bool,
  profit: PropTypes.object,
  health: PropTypes.object,
  liquidateAccount: PropTypes.func,
  onRowPositionClicked: PropTypes.func,
  closeAllFilterDropmenus: PropTypes.func,
  accountToLiquidate: PropTypes.object,
  fromLiquidations: PropTypes.bool,
  settlements: PropTypes.array,
  currentTimestamp: PropTypes.number,
  positionsBorrowFactors: PropTypes.object,
  web3: PropTypes.object,
  marginPoolData: PropTypes.object
};

function mapStateToProps(state) {
  return {
    web3: web3Selector(state),
    web3Account: web3AccountSelector(state),
    web3AccountLoaded: web3AccountLoadedSelector(state),
    positionsManager: positionsManagerSelector(state),
    lpManager: lpManagerSelector(state),
    markets: marketsSelector(state),
    marketsLoaded: marketsLoadedSelector(state),
    underlyingTokenPrice: avgUnderlyingTokenPriceSelector(state),
    selectedTokenPair: selectedTokenPairSelector(state),
    marginAccountData: marginAccountDataSelector(state),
    openedTradePositions: openedTradePositionsSelector(state),
    openedLPPositions: openedLPPositionsSelector(state),
    tokenBalances: tokenBalancesSelector(state),
    settlements: settlementDataSelector(state),
    currentTimestamp: currentTimestampSelector(state),
    positionsBorrowFactors: positionsBorrowFactorsSelector(state),
    marginPoolData: marginPoolDataSelector(state)
  };
}

export default connect(mapStateToProps)(PositionModal);
