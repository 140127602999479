import * as types from "../actionTypes.js";

export const marginPool = (state = { loaded: false, data: [] }, action) => {
  switch (action.type) {
    case types.MARGIN_POOL_DATA_LOADED:
      return { ...state, loaded: true, data: action.marginData };
    default:
      return state;
  }
};

export const marginAccount = (state = { loaded: false, data: [] }, action) => {
  switch (action.type) {
    case types.MARGIN_ACCOUNT_DATA_LOADED:
      return { ...state, loaded: true, data: action.marginAccountData };
    default:
      return state;
  }
};

export const marginEvents = (state = { loaded: false }, action) => {
  switch (action.type) {
    case types.MARGIN_EVENTS_LOADED:
      return {
        ...state,
        loaded: true,
        lendEvents: action.lendEvents,
        redeemEvents: action.redeemEvents,
        borrowEvents: action.borrowEvents,
        repaidEvents: action.repaidEvents
      };
    case types.LEND_EVENT_ADDED:
      // return { ...state, lendEvents: unionWith(state.lendEvents, [action.lendEvent], isEqual) };
      if (state.lendEvents.find(event => event.signature === action.lendEvent.signature)) {
        return state;
      } else {
        return { ...state, lendEvents: [...state.lendEvents, action.lendEvent] };
      }
    case types.REDEEM_EVENT_ADDED:
      // return { ...state, redeemEvents: unionWith(state.redeemEvents, [action.redeemEvent], isEqual) };
      if (state.redeemEvents.find(event => event.signature === action.redeemEvent.signature)) {
        return state;
      } else {
        return { ...state, redeemEvents: [...state.redeemEvents, action.redeemEvent] };
      }
    case types.BORROW_EVENT_ADDED:
      // if (true) {
      //   let startTime1 = new Date().getTime();
      //   const sampleData = unionWith(state.borrowEvents, [action.borrowEvent], isEqual);
      //   let endTime1 = new Date().getTime();
      //   console.log("Time taken to unionWith: ", endTime1 - startTime1);

      //   let startTime2 = new Date().getTime();
      //   if (!state.borrowEvents.find(borrow => borrow.signature === action.borrowEvent.signature)) {
      //     const sampleData = [...state.borrowEvents, action.borrowEvent];
      //   }
      //   let endTime2 = new Date().getTime();
      //   console.log("Time taken to find: ", endTime2 - startTime2);
      // }
      // return { ...state, borrowEvents: unionWith(state.borrowEvents, [action.borrowEvent], isEqual) };
      if (state.borrowEvents.find(event => event.signature === action.borrowEvent.signature)) {
        return state;
      } else {
        return { ...state, borrowEvents: [...state.borrowEvents, action.borrowEvent] };
      }
    case types.REPAID_EVENT_ADDED:
      // return { ...state, repaidEvents: unionWith(state.repaidEvents, [action.repaidEvent], isEqual) };
      if (state.repaidEvents.find(event => event.signature === action.repaidEvent.signature)) {
        return state;
      } else {
        return { ...state, repaidEvents: [...state.repaidEvents, action.repaidEvent] };
      }
    default:
      return state;
  }
};
