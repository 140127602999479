import React from "react";
import { Col, Row } from "reactstrap";
import { displayLoadingIcon } from "../../../../utils/utils.js";
import { handleClickArrowDown, handleClickArrowUp } from "../EventHandlers/ArrowUpDown";

export const printLoadingIcon = (web3AccountLoaded, loadedMethod) => {
  if (web3AccountLoaded && !loadedMethod) {
    return (
      <Row key={"loadingIcon"} style={{ height: "200px" }}>
        <Col className="mb-1" style={{ textAlign: "center", color: "#ffffff", opacity: 0.8, alignSelf: "center" }} md="12">
          <h1>{displayLoadingIcon()}</h1>
        </Col>
      </Row>
    );
  }
};

export const printEmptyTable = (web3AccountLoaded, loadedMethod, tableData, text) => {
  if (web3AccountLoaded && loadedMethod && tableData.length === 0) {
    return (
      <Row key={"emptyTable"} style={{ height: "200px" }}>
        <Col className="mb-1 emptyTable" md="12">
          <span className="emptyTableMessage">{text}</span>
        </Col>
      </Row>
    );
  }
};

export const handleShowHidePanel = (lsId, bodyId, arrowDownId, arrowUpId) => {
  if (localStorage.getItem(lsId) === "true") {
    handleClickArrowUp(lsId, bodyId, arrowDownId, arrowUpId);
  } else if (localStorage.getItem(lsId) === "false") {
    handleClickArrowDown(lsId, bodyId, arrowDownId, arrowUpId);
  }
};
